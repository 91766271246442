const missingItemStyle = {
  cursor: 'pointer',
  userSelect: 'all',
  background: 'white',
  display: 'inline-block',
  padding: '5px 20px',
  margin: '5px',
  border: '1px solid rgb(222,222,222)',
  fontSize: '15px',
  borderRadius: '5px'
}

export {missingItemStyle}
