import Utils from '../../modules/utils'

const getUpc = (cadId, callbackFunc) => {
  const url = 'upcs/' + cadId
  Utils.get(url, callbackFunc)
}

const getUpcTotals = (callbackFunc) => {
  const url = 'upc_totals'

  let cadIds = Object.keys(window.upcsTotals).filter(cadId => window.upcsTotals[cadId] == '⏳')

  const data = {cadIds}

  Utils.post(url, data, callbackFunc)
}

const getUpcExcel = (cadIds) => {
  const url = 'upc_excel'

  const data = {cadIds}

  Utils.post(url, data, (err, res) => {
    if (err) {
      alert('There was an error when trying to get UPC excel')
    } else {
      let {url} = res.data
      window.location = url
    }
  })
}

const getUploadedFiles = (callbackFunc) => {
  const url = 'uploaded_upcs_files'
  Utils.get(url, callbackFunc)
}

const exportAll = () => {
  const url = 'upc_export_all'
  Utils.get(url, (err, res) => {
    if (err) {
      alert('There was an error when trying to export all UPC')
    } else {
      let {url} = res.data
      window.location = url
    }
  })
}

export {getUpc, getUpcTotals, getUpcExcel, getUploadedFiles, exportAll}
