import React from 'react'
import {Menu} from 'semantic-ui-react'
import './prints-link.css'

const printsLink = (user) => {
  if (!!user && ['design', 'admin'].includes(user.role)) {
    return (
      <Menu.Item><a className={"prints-link"} href="/#/prints">Prints</a></Menu.Item>
    )
  } else {
    return null
  }
}

export {printsLink}
