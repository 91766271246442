import Utils from "../../../modules/utils"

const downloadPdf = (setLoadingExport) => {
  let data = getData()

  Utils.post('print_export_pdf', data, (err, result) => {
    if (err) {
      alert('There was an error when trying to download pdf')
    } else {
      setLoadingExport(false)
      window.open(result.data.url, '_blank', "height=500,width=800")
    }
  })
}

const downloadExcel = (setLoadingExport) => {
  let data = getData()

  Utils.post('print_export_excel', data, (err, result) => {
    if (err) {
      alert('There was an error when trying to download excel')
    } else {
      setLoadingExport(false)
      window.location = result.data.url
    }
  })
}

const getData = () => {
  return {printIds: Object.keys(window.printIds)}
}

export {downloadPdf, downloadExcel}
