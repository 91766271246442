import $ from 'jquery'
import Utils from "../../modules/utils"

const saveVendorOrder = (history, id, action) => {
  let data = getVendorOrderData()
  callApi(data, history, id, action)
}

const getVendorOrderData = () => {
  let {newVpoPage} = window
  return newVpoPage
}

const callApi = (data, history, id, action) => {
  const isNewPage = id == null
  const url = isNewPage ? 'vendor_orders' : 'vendor_orders/' + id

  const callbackFunc = (err, result) => {
    if (err) {
      console.log('There was an error when trying to save vendor sales order, WILL NOT RETRY')
      // setTimeout(() => {saveVendorOrder(history, id)}, 20000)
    } else {
      if (result.data.success) {
        if (isNewPage) {
          setTimeout(() => {
            history.push('/')
            window.skipAction = true
            history.push('/vendor-orders/new?success=' + result.data.refnumber + (action == null ? '' : '&action=' + action))
            $('body').css('display', 'none')
            window.location.reload()
          }, 0)
        } else {
          if (result.data.refnumber == '') {
            window.location.reload()
          } else {
            setTimeout(() => {
              window.location = '/#/vendor-orders/' + id + '/edit?split_up=' + result.data.refnumber
              $('body').css('display', 'none')
              window.location.reload()
            }, 0)
          }
        }
      }
    }
  }

  if (isNewPage) {
    Utils.post(url, data, callbackFunc)
  } else {
    Utils.put(url, data, callbackFunc)
  }
}

export {saveVendorOrder}
