import React from 'react'
import {Table} from 'semantic-ui-react'

const fabricationTableCell = (row) => {
  return (
    <Table.Cell>{row.fabrication}</Table.Cell>
  )
}

const fabricationValue = (row) => {
  return (
    row.fabrication
  )
}

export {fabricationTableCell, fabricationValue}
