import React from 'react'
import {Button, Icon} from 'semantic-ui-react'

const filtersButton = (showFilters, toggleFilters) => {
  return (
    <div>
      <Button icon basic={!showFilters} primary={showFilters} onClick={toggleFilters} style={{float: 'right'}}>
        <Icon name='filter' />
      </Button>
    </div>
  )
}

export {filtersButton}
