import React, {Component} from 'react'
import {Icon, Popup, Checkbox} from 'semantic-ui-react'
import Utils from './../../../modules/utils'

class InventoryPrintPopup extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
    this.togglePrinted = this.togglePrinted.bind(this)
  }

  togglePrinted() {
    let {id, printed} = this.props.po
    printed = !printed

    Utils.get('toggle_printed/' + id + '?printed=' + printed, (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to toggle printed.')
      } else {
        if (res.data.success) {
          this.props.updatePrinted(id, printed)
        }
      }
    })
  }

  render() {
    let {status, printed} = this.props.po

    return (
      ['Pending', 'QB-Entered'].includes(status)
      ?
      <Popup
        size='mini'
        trigger={
          <span data-tooltip={printed ? "Order is printed 👌" : "Click to edit printed"} data-position="top left" style={{cursor: 'pointer'}}>
            <Icon name="print" style={{color: (printed ? 'rgb(194,194,194)' : 'rgb(63,133,202)'), marginLeft: '10px', fontSize: '18px'}}/>
          </span>
        }
        header="Edit printed"
        content={
          <div style={{width: '250px', marginTop: '20px', marginBottom: '10px', textAlign: 'center'}}>
            <Checkbox toggle label='Order is printed' onChange={this.togglePrinted} checked={printed}/>
          </div>
        }
        on='click'
        position='top left'
      />

      :
      null
    )
  }
}

export default InventoryPrintPopup
