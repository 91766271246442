import React, {Component} from 'react'
import {Message, Button} from 'semantic-ui-react'
import Utils from './../../modules/utils'
import './special-status-logs-message.css'

class AutoCancelHoldLogs extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {

    }
  }

  dismiss() {
    if (window.confirm('Are you sure you want to dismiss auto edit logs?')) {
      Utils.get('dismiss_auto_cancel_hold_logs', (err, res) =>
      {
        window.location.reload()
      })
    }
  }

  render() {
    let {autoCancelHoldLogs} = this.props.user

    if (autoCancelHoldLogs != null && autoCancelHoldLogs.length > 0) {
      const list = autoCancelHoldLogs.map((log) => {
        return log.refnumber + ' — ' + log.styleNumber + ' — quantity changed from ' + log.oldQuantity  + ' to ' + log.newQuantity + ' — ' + Utils.formatSimpleDate(log.createdAt)
      })

      return (
        <div style={{paddingBottom: '40px'}}>
          <Message
            className='special-status-logs-message'
            icon='bolt'
            header='You have holds that were automatically edited'
            list={list}
            onDismiss={this.dismiss}
            color='yellow'
          />
        </div>
      )
    } else {
      return null
    }
  }
}

export default AutoCancelHoldLogs
