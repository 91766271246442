import Utils from "../../modules/utils"

const saveStatus = (status) => {
  const url = 'update_vpo_status'

  let {vpoIds} = window
  let data = {vpoIds, status}

  const callbackFunc = (err, result) => {
    if (err) {
      alert('There was an error when trying to update vpo status')
    } else {
      if (result.data.success) {
        setTimeout(() => alert('The status changes were saved!'), 10)
      }
    }
  }

  Utils.post(url, data, callbackFunc)
}

export {saveStatus}
