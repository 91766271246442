import React from 'react'
import {Popup, Icon} from 'semantic-ui-react'

const upfrontPopup = (po) => {
  return (
    [null, ''].includes(po.upfront)
    ?
    null
    :
    <Popup
      size='mini'
      header={"This order is " + (po.upfront == 'NO' ? 'From Stock' : 'Upfront')}
      position='bottom left'
      trigger={
      <Icon name={po.upfront == 'NO' ? 'hand paper outline' : 'rocket'} style={{color: 'rgb(227,119,55)', marginLeft: '10px', fontSize: '20px'}}/>
    } />
  )
}

export {upfrontPopup}
