import React, {Component} from 'react'
import {Table, Input, Select, Button, Icon, Dropdown} from 'semantic-ui-react'
import {sizesCheckoutOptions} from '../../../getApiEndpoint.js'
import {getSizeRatioOptions} from '../../vendor-orders/options.js'

const addSizeSyle = {position: 'absolute', marginTop: '5px', paddingRight: '10px'}
const removeSizeStyle = {position: 'absolute', marginTop: '5px', marginLeft: '50px', paddingRight: '10px'}

class QuantitySizeColumns extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let sizeRatioOptions = getSizeRatioOptions()
    this.setState({sizeRatioOptions})
  }

  render() {
    let {cadId, quantity, size, sizeRatio, onChangeQuantity, onChangeSize, onChangeSizeRatio, addSize, removeSize} = this.props

    let {sizeRatioOptions} = this.state

    return (
      <React.Fragment>
        <Table.Cell style={{paddingBottom: '40px'}}>
          {
            quantity.map((q, i) => {
              return (
                <div key={i} style={{paddingTop: '2px', paddingBottom: '2px'}}>
                  <Input placeholder='Quantity' style={{width: '150px'}} value={q}
                    onChange={onChangeQuantity.bind(null, cadId, i)} icon='hashtag'
                    iconPosition='left' type="number" min="0" step="1"
                  />
                </div>
              )
            })
          }
        </Table.Cell>
        <Table.Cell style={{paddingBottom: '40px'}}>
          {
            size.map((s, i) => {
              //if (!sizeRatioOptions.map(x => x.key).includes(sizeRatio[i]) && sizeRatio[i] != null) {
              //  sizeRatioOptions.unshift({ key: sizeRatio[i], text: sizeRatio[i], value: sizeRatio[i] })
              //}

              return (
                <div key={i} style={{width: '300px', paddingTop: '2px', paddingBottom: '2px'}}>
                  <div style={{width: '110px', display: 'inline-block'}}>
                    <Dropdown placeholder='Size' fluid selection search options={sizesCheckoutOptions}
                      onChange={onChangeSize.bind(null, cadId, i)} value={s == null && i == 0 ? 'PREPACK' : s}
                    />
                  </div>
                  {
                    s == 'PREPACK' || (s == null && i == 0)
                    ?
                    <div style={{width: '185px', display: 'inline-block', marginLeft: '4px'}}>
                      <Select fluid placeholder='Size Ratio' search options={sizeRatioOptions} onChange={onChangeSizeRatio.bind(null, cadId, i)} value={sizeRatio[i]} style={{fontSize: '80%'}}/>
                    </div>
                    :
                    null
                  }
                </div>
              )
            })
          }
          <Button onClick={addSize.bind(null, cadId)} basic size='mini' style={addSizeSyle}><Icon name='plus'/></Button>
          {
            size.length == 1
            ?
            null
            :
            <Button onClick={removeSize.bind(null, cadId)} negative size='mini' style={removeSizeStyle}><Icon name='minus'/></Button>
          }

        </Table.Cell>
      </React.Fragment>
    )
  }
}

export default QuantitySizeColumns
