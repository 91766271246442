import {isProduction, isDesign, isCustomer, isWarehouse} from './is-role.js'

const getHideableInfo = () => {
  let hideableInfo = [
    "On Hand",
    "ATS",
    "Future Stock",
    "Future ATS",

    "Shipped",
    "PO",
    "Worksheet",
    "Hold",

    "Brand",
    "Division",
    "Category",
    "Sub-Category",
    "Color",
    "Fabrication",
    "Packs",

    "Case Pack",
    "Container #",
    "Country of Origin",
    "Description",

    "Factory",
    "MSRP",
    "Page",
    "Season",

    "Size Breakdown",

    "Warehouse",
    "Wholesale",
    "Ex-Factory",
    "UPC",
    "LDP COST"
  ]

  return hideableInfo
}

const removeFromHideableInfo = (hideableInfo, myCustomerOptions, isGuest, isAdmin, isSales, isShowroom, defaultAccount, salesHistoryAccount) => {
  if (myCustomerOptions != null && myCustomerOptions.fields != null) {
    hideableInfo = hideableInfo.filter(x => myCustomerOptions.fields.includes(x))
  }

  let isGuestWithAccount = isGuest && (defaultAccount != null || salesHistoryAccount != '')

  if (isShowroom || isDesign() || isWarehouse()) {
    if (isGuestWithAccount) {
      //do nothing
    } else {
      removeFromArray(hideableInfo, 'PO')
      removeFromArray(hideableInfo, 'Hold')
      removeFromArray(hideableInfo, 'Worksheet')
    }
    removeFromArray(hideableInfo, 'Wholesale')
    removeFromArray(hideableInfo, 'Container #')
    removeFromArray(hideableInfo, 'Factory')
  }

  if (!isAdmin && !isSales && !isProduction() && !isCustomer()) {
    if (!isWarehouse()) {
      removeFromArray(hideableInfo, 'On Hand')
    }
    removeFromArray(hideableInfo, 'FACTORY-RTS')
    removeFromArray(hideableInfo, 'Shipped')
  }

  if (isWarehouse()) {
    removeFromArray(hideableInfo, 'ATS')
    removeFromArray(hideableInfo, 'Future ATS')
    removeFromArray(hideableInfo, 'Wholesale')
    removeFromArray(hideableInfo, 'MSRP')
    removeFromArray(hideableInfo, 'Country of Origin')
    removeFromArray(hideableInfo, 'Page')
  }

  return hideableInfo
}

const removeFromArray = (arr, valToRemove) => {
  for( var i = 0; i < arr.length; i++){
     if ( arr[i] === valToRemove) {
       arr.splice(i, 1);
     }
  }
}

export {getHideableInfo, removeFromHideableInfo}
