import $ from 'jquery'

const orgText = (orgId) => {
  return orgId == 1 ? '' : '_org'+orgId
}

const getCadsCache = (orgId, callbackFunc, extraParams) => {
  let randomNum = Math.floor(Math.random()*99999999).toString()
  let url = 'https://' + process.env.REACT_APP_S3_BUCKET_NAME + '.s3.amazonaws.com/caches/cads_cache' + orgText(orgId) + '.json?t=' + randomNum

  $.get(url, function(res) {
    let result = JSON.parse(res).data.map(row => row.attributes)
    callbackFunc(result, extraParams)
  })
}

export {getCadsCache, orgText}
