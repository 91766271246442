import React, { Component } from 'react'

class FullscreenImage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let {src, address, showing} = this.props

    return (
      <figure id="fullscreen-image">
        <img src={src} />
        <figcaption>
          {address}<br/><br/>
          <small>Use ← and → arrow keys. &nbsp;&nbsp; Showing: {showing}</small>
        </figcaption>
      </figure>
    )
  }
}

export default FullscreenImage
