import React, {Component} from 'react'
import {Button, Checkbox, Popup} from 'semantic-ui-react'

class VisibleColumnsButton extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <div style={{float: 'right', marginLeft: '-40px', position: 'relative', right: '50px'}} className="refresh-button">
        <Popup
          size='mini'
          trigger={
            <Button size='huge' data-tooltip="Select visible fields" data-position="bottom right" basic icon='eye'/>
          }
          header="Select visible fields"
          content={
            <div style={{width: '350px', marginTop: '20px', marginBottom: '10px', textAlign: 'center'}}>
              <Checkbox toggle label='Image' onChange={this.props.toggleShowImageCheckbox} checked={this.props.showImage}/>
              <Checkbox toggle label='Order details' onChange={this.props.toggleShowDetailsCheckbox} checked={this.props.showDetails} style={{marginLeft:'30px'}}/>

              <div style={{textAlign: 'left', paddingLeft: '35px', paddingTop: '30px'}}>
                <Checkbox toggle label='VPO #' onChange={this.props.toggleVponumberCheckbox} checked={this.props.showVponumber}/>
                <Checkbox toggle label='Ex-Factory' onChange={this.props.toggleExFactoryCheckbox} checked={this.props.showExFactory} style={{marginLeft:'30px'}}/>
              </div>

              <div style={{textAlign: 'left', paddingLeft: '35px', paddingTop: '30px'}}>
                <Checkbox toggle label='Class' onChange={this.props.toggleShippingClassCheckbox} checked={this.props.showShippingClass}/>
              </div>
            </div>
          }
          on='click'
          position='top right'
        />
      </div>
    )
  }
}

export default VisibleColumnsButton
