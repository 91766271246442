import React from 'react'
import {Form} from 'semantic-ui-react'

const printSelect = (printOnChange, value, isDesign) => {
  let {printOptionsAll} = window
  return (
    <div className={isDesign ? 'percent80-width-desktop' : 'percent50-width-desktop'}>
      <Form.Select id="print-select" search fluid label='Print' onChange={printOnChange} options={printOptionsAll} placeholder='Print' value={value} />
    </div>
  )
}

export {printSelect}
