import React from 'react'
import {Popup, Button} from 'semantic-ui-react'
import {updateSelected} from '../card/update-selected'

const buttonStyle = {padding: '14px', marginRight: '10px', position: 'relative', top: '2px'}

const selectAllButton = (selectAllItems) => {
  return (
    <Popup content='Select all prints' position='bottom left'
      trigger={
        <Button basic icon='mouse pointer' onClick={selectAllItems} style={buttonStyle}/>
      }
    />
  )
}

const selectAll = (results) => {
  let allAreSelected = true

  for (let i=0; i<results.length; i++) {
    let {id} = results[i]

    if (!window.printIds[id]) {
      allAreSelected = false
      break
    }
  }

  if (allAreSelected) {
    window.printIds = {}
  } else {
    let printIds = {}

    results.forEach((row, i) => {
      let {id} = row
      printIds[id] = true
    })

    window.printIds = printIds
  }

  updateSelected()
}

export {selectAllButton, selectAll}
