const processSliderValues = (sliderState) => {
  let min = sliderState.values[0]

  if (min == 1) {
    min = 0;
  } else if (min <= 199) {
    min = 1;
  } else if (min <= 399) {
    min = 13;
  } else if (min <= 499) {
    min = 99;
  } else if (min <= 599) {
    min = 199;
  } else if (min <= 699) {
    min = 299;
  } else if (min <= 799) {
    min = 399;
  } else if (min <= 899) {
    min = 499;
  } else if (min <= 999) {
    min = 599;
  } else if (min <= 1599) {
    min = 1199;
  } else if (min <= 2199) {
    min = 1799;
  } else if (min <= 2799) {
    min = 2399;
  } else if (min <= 3999) {
    min = 3599;
  } else if (min <= 4199) {
    min = 3799;
  } else if (min <= 5399) {
    min = 4999;
  } else if (min <= 6599) {
    min = 6199;
  } else if (min <= 7799) {
    min = 7399;
  } else if (min <= 9999) {
    min = 8599;
  } else {
    min = Math.floor(min / 100) * 100 - 1; //17 23 35
  }

  var max = sliderState.values[1];
  if (max == 11000) {
    //do nothing
  } else if (max <= 999 && 12 > min) {
    max = 12;
  } else if (max <= 1199 && 198 > min) {
    max = 198;
  } else if (max <= 1599 && 598 > min) {
    max = 598;
  } else if (max <= 2199 && 1198 > min) {
    max = 1198;
  } else if (max <= 2799 && 1798 > min) {
    max = 1798;
  } else if (max <= 3399 && 2398 > min) {
    max = 2398;
  } else if (max <= 4599 && 3598 > min) {
    max = 3598;
  } else if (max <= 4799 && 3798 > min) {
    max = 3798;
  } else if (max <= 5999 && 4998 > min) {
    max = 4998;
  } else if (max <= 7199 && 6198 > min) {
    max = 6198;
  } else if (max <= 8399 && 7398 > min) {
    max = 7398;
  } else if (max <= 9599 && 8598 > min) {
    max = 8598;
  } //else if (max <= 10799) {
    //max = 9798;
  //}
  else {
    //max = Math.floor(max / 100) * 100 - 2;
    max = 11000;
  }

  return {min, max}
}

const processFutureATSSliderValues = (sliderState) => {
  var min = sliderState.values[0];

  if (min <= -3099) {
    min = -3000
  } else if (min <= -2499) {
    min = -2400
  } else if (min <= -1899) {
    min = -1800
  } else if (min <= -1299) {
    min = -1200
  } else if (min <= -699) {
    min = -600
  } else if (min <= -599) {
    min = -480
  } else if (min <= -499) {
    min = -360
  } else if (min <= -399) {
    min = -240
  } else if (min <= -199) {
    min = -100
  } else if (min <= 1) {
    min = 0;
  } else if (min <= 199) {
    min = 1;
  } else if (min <= 399) {
    min = 13;
  } else if (min <= 499) {
    min = 99;
  } else if (min <= 599) {
    min = 199;
  } else if (min <= 699) {
    min = 299;
  } else if (min <= 799) {
    min = 399;
  } else if (min <= 899) {
    min = 499;
  } else if (min <= 999) {
    min = 599;
  } else if (min <= 1599) {
    min = 1199;
  } else if (min <= 2199) {
    min = 1799;
  } else if (min <= 2799) {
    min = 2399;
  } else if (min <= 3999) {
    min = 3599;
  } else if (min <= 4199) {
    min = 3799;
  } else if (min <= 5399) {
    min = 4999;
  } else if (min <= 6599) {
    min = 6199;
  } else if (min <= 7799) {
    min = 7399;
  } else if (min <= 9999) {
    min = 8599;
  } else {
    min = Math.floor(min / 100) * 100 - 1; //17 23 35
  }

  var max = sliderState.values[1];
  if (max == 11000) {
    //do nothing

  } else if (max <= -1299) {
    max = -1200;
  } else if (max <= -699) {
    max = -600;
  } else if (max <= -599) {
    max = -480;
  } else if (max <= -499) {
    max = -360;
  } else if (max <= -399) {
    max = -240;
  } else if (max <= -199) {
    max = -100;
  } else if (max <= 0) {
    max = -1;

  } else if (max <= 198 && 0 > min) {
    max = 0;

  } else if (max <= 1199 && 198 > min) {
    max = 198;
  } else if (max <= 1599 && 598 > min) {
    max = 598;
  } else if (max <= 2199 && 1198 > min) {
    max = 1198;
  } else if (max <= 2799 && 1798 > min) {
    max = 1798;
  } else if (max <= 3399 && 2398 > min) {
    max = 2398;
  } else if (max <= 4599 && 3598 > min) {
    max = 3598;
  } else if (max <= 4799 && 3798 > min) {
    max = 3798;
  } else if (max <= 5999 && 4998 > min) {
    max = 4998;
  } else if (max <= 7199 && 6198 > min) {
    max = 6198;
  } else if (max <= 8399 && 7398 > min) {
    max = 7398;
  } else if (max <= 9599 && 8598 > min) {
    max = 8598;
  } //else if (max <= 10799) {
    //max = 9798;
  //}
  else {
    //max = Math.floor(max / 100) * 100 - 2;
    max = 11000;
  }

  return {min, max}
}

export {processSliderValues, processFutureATSSliderValues}
