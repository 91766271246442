import {getPrintType} from '../../prints/filter'

const findPrint = (cadId) => {
  let {cadPrints} = window
  return !!cadPrints && !!cadPrints[cadId]
}

const printStyleNumber = (cadId) => {
  let {cadPrints, prints} = window
  let print = prints[cadPrints[cadId]]
  let pText = print.style_number[0] == 'G' ? '' : ' (' + print.pattern + ')'
  return getPrintType(print.style_number) + ': ' + print.style_number + pText
}

export {findPrint, printStyleNumber}
