import React from 'react'
import {hr} from './hr.js'
import Dropzone from 'react-dropzone'
import {Button, Loader} from 'semantic-ui-react'
import $ from 'jquery'


let downloadTotal = 0

const handleAcceptedFiles = (acceptedFiles, folderName, addFilename) => {
  downloadTotal += acceptedFiles.length

  acceptedFiles.forEach((file) => {

    let formData = new FormData()
    let filename = file.name
    formData.append("file", file, file.name)

    $.ajax({
      type: "POST",
      url: process.env.REACT_APP_API_URL + '/api/uploads?folder=' + folderName,
      success: function (data) {
        addFilename(data.filename)

        downloadTotal -= 1

        if (downloadTotal == 0) {
          $('#loader-gif').hide()
        }
      },
      error: function (error) {
        alert('There was an error when trying to upload the file.')
      },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000
    })
  })
}

const files = (folderName, filenames, addFilename, removeFilename) => {
  return (
    <div>
      {
        folderName.includes('property_')
        ?
        null
        :
        <React.Fragment>
          {hr}
          <b style={{marginBottom: '20px'}}>Files</b>
        </React.Fragment>
      }
      <Dropzone onDrop={(acceptedFiles) => {$('#loader-gif').show();handleAcceptedFiles(acceptedFiles, folderName, addFilename);}}>
        {({getRootProps, getInputProps}) => (
          <section>
            <div {...getRootProps()} id="fileDropzone">
              <input {...getInputProps()} />
              <p>Drop files here to upload</p>
            </div>
          </section>
        )}
      </Dropzone>
      <div style={{textAlign: 'right'}}>
        <img id="loader-gif" src='https://erp-rails-usa-production.s3.amazonaws.com/real_estate/loader.gif' style={{float: 'left', height: '30px', display: 'none'}}/>

        <Button basic onClick={() => $('#fileDropzone').click()}>Add files</Button>
      </div>
      <div className="added-files">
        {
          filenames.length == 0
          ?
          <b>You have not added any files</b>
          :
          filenames.map((filename, index) => <div key={index}>{decodeURI(filename.split('/').at(-1))} <button type="button" className="remove-file-button" onClick={() => removeFilename(filename)}>remove</button></div>)
        }

      </div>
    </div>
  )
}

export {files}
