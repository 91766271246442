const filter = (contacts, status, title, distributionChannel, region, division, category, searchString, rep) => {
  let result = contacts.slice()

  let ignoreStatusFilter = isDefaultStatus(status) && searchString.length > 2

  if (status.length && !ignoreStatusFilter) {
    result = result.filter(x => status.includes(x.status))
  }

  if (title.length) {
    result = result.filter(x => title.includes(x.title))
  }

  if (distributionChannel.length) {
    result = result.filter(x => distributionChannel.includes(x.distributionChannel))
  }

  if (region.length) {
    result = result.filter(x => region.includes(x.region))
  }

  if (division.length) {
    result = result.filter(x => division.some(y => x.division.includes(y)))
  }

  if (category.length) {
    result = result.filter(x => category.some(y => x.category.includes(y)))
  }

  //name, company, email, contact id
  if (searchString.length > 2) {
    result = result.filter(x => strMatch(x.name, searchString) || strMatch(x.company, searchString) || strMatch(x.email, searchString) || x.contactId.toLowerCase() == searchString.toLowerCase())
  }

  if (rep.length > 0) {
    result = result.filter(x => rep.some(y => x.userId.includes(y)))
  }

  return result
}

const strMatch = (str1, str2) => {
  return str1.toLowerCase().includes(str2.toLowerCase())
}

const isDefaultStatus = (status) => {
  return (status.length == 2 && status.sort()[0] == 'Active' || status.sort()[1] == 'Pending') || status.length == 0
}

export {filter, isDefaultStatus}
