import React, {Component} from 'react'
import {Table, Icon} from 'semantic-ui-react'
import {getStatusMarkup} from '../purchase-orders/sales-view/get-status-markup.js'
import {removeFlag} from './remove-flag'
import {swapVpoCad} from './swap-vpo-cad'

String.prototype.rjust = function(num, padding){
    if (!padding) padding = ' '
    var s = this.toString()
    if (1 != padding.length) throw new Error('Padding must be one character.')
    if (s.length >= num) return s
    for(var i=0; i<=num-s.length; i++)
      s = padding + s
    return s
}

const getRefnumber = (id) => {
  return 'V' + id.toString().rjust(4, "0")
}

const formatDate = (text) => {
  let parts = text.split('T')[0].split('-')
  if (parts[1][0] == '0') {
    parts[1] = parts[1][1]
  }
  if (parts[2][0] == '0') {
    parts[2] = parts[2][1]
  }
  return parts[1] + '/' + parts[2] + '/' + parts[0]
}

class OrderSummary extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  swap = () => {
    let styleNumber = window.prompt('Enter new style number')
    if (styleNumber == null || styleNumber.length < 5) return

    swapVpoCad(this.props.id, styleNumber, (err, res) => {
      if (err) {
        alert('There was an error when trying to swap vpo cad')
      } else {
        let {errorMessage} = res.data
        if (errorMessage != null) {
          alert(errorMessage)
        } else {
          window.location.reload()
        }
      }
    })
  }

  render() {
    let {factory, createdAt, userEmail, status, edited, editedOn} = window.editVpoPage.orderSummary
    let {styleNumbers} = window.editVpoPage.selectBuyer

    let cadIds = []
    if (window.newVpoPage != null && window.newVpoPage.styleNumbersTextarea != null) {
      cadIds = window.newVpoPage.styleNumbersTextarea.cadIds
    }

    return (
      <div style={{marginBottom: '50px'}}>
        <h4>Order summary</h4>
        <Table id="vpo-order-summary-table" padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>REF #</Table.HeaderCell>
              <Table.HeaderCell>Factory</Table.HeaderCell>
              <Table.HeaderCell>Style #</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{getRefnumber(this.props.id)}</Table.Cell>
              <Table.Cell>{factory}</Table.Cell>
              <Table.Cell>
                {
                  styleNumbers.map(styleNumber => <div>{styleNumber}</div>)
                }
                <button style={{cursor: 'pointer', background: 'rgb(211,73,150)', color: 'white', border: 0, borderRadius: '5px'}} onClick={this.swap}>swap cad</button>
              </Table.Cell>
              <Table.Cell>{formatDate(createdAt)}</Table.Cell>
              <Table.Cell>{userEmail.split('@')[0]}</Table.Cell>
              <Table.Cell>
                {getStatusMarkup(status)}
                {
                  edited
                  ?
                  <div style={{display: 'inline-block', verticalAlign: 'top', textAlign: 'center'}}>
                    <span style={{fontSize: '10px', background: 'yellow', padding: '2px', border: '1px solid lightgrey', borderRadius: '2px', marginLeft: '5px'}}><Icon name='flag'/>EDITED on {formatDate(editedOn)}</span>
                    <br/>
                    <button style={{border: '1px solid red', fontSize: '10px', background: 'red', color: 'white', padding: '0px 3px', cursor: 'pointer', borderRadius: '5px'}} onClick={removeFlag.bind(null, this.props.id)}>remove flag</button>
                  </div>
                  :
                  null
                }
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default OrderSummary
