const getKey = (cad) => {
  let key = cad.id
  if (key == null) {
    key = cad.style_number.toLowerCase().replace(/-/g, '_')
  }
  return key
}

const getCommentKey = (key) => {
  return key.toString().replace(/\s+/g, '-')
}

export {getKey, getCommentKey}
