import React, {Component} from 'react'
import {dateInputStyle} from '../date-inputs.js'

class TicketedDueInput extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div style={{marginBottom: '15px', width: '97%'}}>
        <label for="ticketed-due" style={{fontWeight: 'bold'}}>
          Need tickets by
        </label>
        <input id="ticketed-due" type="date" style={dateInputStyle} />
      </div>
    )
  }
}

export default TicketedDueInput
