const allBoys = [
  'NEWBORN BOY',
  'INFANT BOY',
  'TODDLER BOY',
  'LITTLE BOYS',
  'BIG BOY',
  'BOYS',
]

const allGirls = [
  'NEWBORN GIRL',
  'INFANT GIRL',
  'TODDLER GIRL',
  'JUNIOR GIRLS',
  'GIRLS',
]

const quickFixAllBoysAllGirls = (division) => {
   if (division.includes('ALL BOYS')) {
     division = allBoys
   }

   if (division.includes('ALL GIRLS')) {
     division = allGirls
   }

   if (division.includes('ALL KIDS')) {
     division = allBoys.concat(allGirls).concat(['KIDS UNISEX'])
   }

   return division
}

export {quickFixAllBoysAllGirls, allBoys, allGirls}
