import Utils from '../../modules/utils'

const checkPin = (pin, callbackFunc) => {
  Utils.get('admin_pins/'+pin, (err, res) => {
    if (err) {
      alert('There was an error when trying to check admin pin!')
    } else {
      callbackFunc(res.data.isValid)
    }
  })
}

export default checkPin
