import React, { Component } from 'react'
import {TextArea} from 'semantic-ui-react'
import $ from 'jquery'
import {processText} from '../new-vpo-page/select-cads/process-text.js'

const textareaStyle = {width: '620px', minWidth: '620px', padding: '10px', height: '200px', minHeight: '200px', maxWidth: '870px', border: '1px solid rgb(212,212,212)', lineHeight: '1.4em'}

class LongStyleNumbersTextArea extends Component {

  constructor(props) {
    super(props)
    this.state = {}

    this.textareaOnChange = this.textareaOnChange.bind(this)
  }

  textareaOnChange() {
    let {cadsHash} = this.props
    let styleNumberText = $('#input-long-style-numbers').val()

    if (styleNumberText == '') {
      $('#output-long-style-numbers').val('')
      return
    }

    let styleNumbers = processText(styleNumberText)
    let longStyleNumbers = styleNumbers.map(styleNumber => cadsHash[styleNumber])
    longStyleNumbers = longStyleNumbers.map(x => x == null ? '—' : x)

    $('#output-long-style-numbers').val(longStyleNumbers.join("\n"))
  }

  render() {
    return (
      <div>
        <TextArea id="input-long-style-numbers" style={textareaStyle}
          placeholder="Enter style numbers" onChange={this.textareaOnChange}
        />

        <br/><br/>

        <TextArea id="output-long-style-numbers" style={textareaStyle}
          placeholder="Long style numbers will appear here"
        />
      </div>
    )
  }
}


export default LongStyleNumbersTextArea
