import                            './index.css'
import React, {Component}    from 'react'
import {Form, Grid, Tab}     from 'semantic-ui-react'
import _                     from 'lodash'
import CategoryInput         from './category-input'
import SubcategoryInput      from './subcategory-input'
import GrandSubcategoryInput from './grand-subcategory-input'
import DivisionInput         from './division-input'
import BrandInput            from './brand-input'
import PackInput             from './pack-input'
import SeasonInput           from './season-input'
import ColorInput            from './color-input'
import FabricationInput      from './fabrication-input'
import SizeInput             from './size-input'
import CustomerAPI           from './../../../modules/api/customer_api'
import {Auth0Context}        from './../../../contexts/auth-context'


class CompanySettings extends Component
{
  static contextType = Auth0Context

  constructor(props)
  {
    super(props)
    this.state =
    {
      loading: true,
      company: this.props.company || null
    }
  }

  componentDidMount()
  {
    this.setState({loading: false})
  }

  componentWillUnmount()
  {
    this.updateCompanyStore()
  }

  updateCompanyStore = () =>
  {
    var {setCustomer} = this.context
    setCustomer(Object.assign({}, this.state.company))
  }

  handleSubmit = (data, id, key, cb=null) =>
  {
    var {company} = this.state
    this.setState({loading: true})

    var callback = (error, data) =>
    {
      if (error)
      {
        if (cb) return cb(error)
        else return
      }
      else
      {
        if (id)
        {
          var index = company[key].findIndex(k => k.id === id)
          company[key][index] = data
        }
        else
        {
          company[key].push(data)
        }

        this.setState({ company, loading: false })
        if (cb) cb(null, data)
      }
    }

    switch (key)
    {
      case "brands":
      {
        if (id) CustomerAPI.updateBrand(id, data, callback, company.id)
        else CustomerAPI.createBrand(data, callback, company.id)
        break
      }
      case "divisions":
      {
        if (id) CustomerAPI.updateDivision(id, data, callback, company.id)
        else CustomerAPI.createDivision(data, callback, company.id)
        break
      }
      case "categories":
      {
        if (id) CustomerAPI.updateCategory(id, data, callback, company.id)
        else CustomerAPI.createCategory(data, callback, company.id)
        break
      }
      case "subcategories":
      {
        if (id) CustomerAPI.updateSubCategory(id, data, callback, company.id)
        else CustomerAPI.createSubCategory(data, callback, company.id)
        break
      }
      case "grandSubcategories":
      {
        if (id) CustomerAPI.updateGrandSubCategory(id, data, callback, company.id)
        else CustomerAPI.createGrandSubCategory(data, callback, company.id)
        break
      }
      case "colors":
      {
        if (id) CustomerAPI.updateColor(id, data, callback, company.id)
        else CustomerAPI.createColor(data, callback, company.id)
        break
      }
      case "packs":
      {
        if (id) CustomerAPI.updatePack(id, data, callback, company.id)
        else CustomerAPI.createPack(data, callback, company.id)
        break
      }
      case "seasons":
      {
        if (id) CustomerAPI.updateSeason(id, data, callback, company.id)
        else CustomerAPI.createSeason(data, callback, company.id)
        break
      }
      case "sizes":
      {
        if (id) CustomerAPI.updateSize(id, data, callback, company.id)
        else CustomerAPI.createSize(data, callback, company.id)
        break
      }
      case "fabrications":
      {
        if (id) CustomerAPI.updateFabrication(id, data, callback, company.id)
        else CustomerAPI.createFabrication(data, callback, company.id)
        break
      }
      default :
      {

      }
    }
  }

  handleDestroy = (id, key) =>
  {
    var {company} = this.state
    this.setState({loading: true})

    var callback = (error, data) =>
    {
      if (error)
      {
        this.setState({loading: false})
      }
      else
      {
        _.remove(company[key], (k) => k.id === id)
        this.setState({loading: false})
      }
    }

    switch (key)
    {
      case "brands":
      {
        CustomerAPI.destroyBrand(id, callback, company.id)
        break
      }
      case "divisions":
      {
        CustomerAPI.destroyDivision(id, callback, company.id)
        break
      }
      case "categories":
      {
        CustomerAPI.destroyCategory(id, callback, company.id)
        break
      }
      case "subcategories":
      {
        CustomerAPI.destroySubCategory(id, callback, company.id)
        break
      }
      case "grandSubcategories":
      {
        CustomerAPI.destroyGrandSubCategory(id, callback, company.id)
        break
      }
      case "colors":
      {
        CustomerAPI.destroyColor(id, callback, company.id)
        break
      }
      case "packs":
      {
        CustomerAPI.destroyPack(id, callback, company.id)
        break
      }
      case "seasons":
      {
        CustomerAPI.destroySeason(id, callback, company.id)
        break
      }
      case "sizes":
      {
        CustomerAPI.destroySize(id, callback, company.id)
        break
      }
      case "fabrications":
      {
        CustomerAPI.destroyFabrication(id, callback, company.id)
        break
      }
      default :
      {

      }
    }
  }

  validateUniqness = (name, key) =>
  {
    var {company} = this.state
    var index = company[key].findIndex(k => k && k.name.toUpperCase() === name.toUpperCase())
    return index < 0
  }

  render()
  {
    let {company} = this.state

    const panes = [
      {
        menuItem: 'Categories',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.categories &&
                  [..._.sortBy(company.categories, ['name']), null].map((category, i) => (
                    <CategoryInput
                      key={`__${category ? category.id : 'empty'}_cat`}
                      category={category}
                      subcategories={company.subcategories}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      },
      {
        menuItem: 'Sub-Categories',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.subcategories &&
                  [..._.sortBy(company.subcategories, ['name']), null].map((subcategory, j) => (
                    <SubcategoryInput
                      key={`__sub_${subcategory ? subcategory.id : 'empty'}_cat`}
                      subcategory={subcategory}
                      grandSubcategories={company.grandSubcategories}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      },
      {
        menuItem: 'Sub2',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.grandSubcategories &&
                  [..._.sortBy(company.grandSubcategories, ['name']), null].map((grandSubcategory, j) => (
                    <GrandSubcategoryInput
                      key={`__grand_sub_${grandSubcategory ? grandSubcategory.id : 'empty'}_cat`}
                      grandSubcategory={grandSubcategory}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      },
      {
        menuItem: 'Divisions',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.divisions &&
                  [..._.sortBy(company.divisions, ['name']), null].map((division, i) => (
                    <DivisionInput
                      key={`__${division ? division.id : 'empty'}_division`}
                      division={division}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      },
      {
        menuItem: 'Brands',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.brands &&
                  [..._.sortBy(company.brands, ['name']), null].map((brand, i) => (
                    <BrandInput
                      key={`__${brand ? brand.id : 'empty'}_brand`}
                      brand={brand}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      },
      {
        menuItem: 'Colors',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.colors &&
                  [..._.sortBy(company.colors, ['name']), null].map((color, i) => (
                    <ColorInput
                      key={`__${color ? color.id : 'empty'}_color`}
                      color={color}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      },
      {
        menuItem: 'Fabrications',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.fabrications &&
                  [..._.sortBy(company.fabrications, ['name']), null].map((fabrication, i) => (
                    <FabricationInput
                      key={`__${fabrication ? fabrication.id : 'empty'}_fabrication`}
                      fabrication={fabrication}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      },
      {
        menuItem: 'Packs',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.packs &&
                  [..._.sortBy(company.packs, ['name']), null].map((pack, i) => (
                    <PackInput
                      key={`__${pack ? pack.id : 'empty'}_pack`}
                      pack={pack}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      },
      {
        menuItem: 'Seasons',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.seasons &&
                  [..._.sortBy(company.seasons, ['name']), null].map((season, i) => (
                    <SeasonInput
                      key={`__${season ? season.id : 'empty'}_season`}
                      season={season}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      },
      {
        menuItem: 'Sizes',
        render: () => <Tab.Pane attached={false} className="settings__tab">
            <Form loading={this.state.loading}>
              <Grid>
                {
                  company && company.sizes &&
                  [..._.sortBy(company.sizes, ['name']), null].map((size, i) => (
                    <SizeInput
                      key={`__${size ? size.id : 'empty'}_size`}
                      size={size}
                      handleSubmit={this.handleSubmit}
                      handleDestroy={this.handleDestroy}
                      validate={this.validateUniqness}
                    />
                  ))
                }
              </Grid>
            </Form>
          </Tab.Pane>
      }
    ]

    return <Tab menu={{secondary: true, pointing: true}} panes={panes} />
  }
}

export default CompanySettings
