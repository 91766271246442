const propertiesFavicon = () => {
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
  }
  link.href = 'https://erp-rails-usa-production.s3.amazonaws.com/real_estate/real-estate-favicon.png';
}

export {propertiesFavicon}
