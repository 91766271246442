import React, {Component} from 'react'
import {Dimmer, Loader} from 'semantic-ui-react'
import $ from 'jquery'
import {getArtists} from './components/artists/get.js'
// import ArtistsCards from './components/artists/cards'
import {Auth0Context} from './contexts/auth-context'

class ArtistsPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      artists: []
    }
    this.getArtistsSuccess = this.getArtistsSuccess.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    getArtists(this.getArtistsSuccess)
  }

  getArtistsSuccess(artists) {
    this.setState({artists, loading: false})
    window.artists = artists
  }

  render() {
    let {artists} = this.state

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>

        <h1 style={{marginBottom: '35px', marginTop: '-10px'}}>Artists</h1>

        <div>{JSON.stringify(artists)}</div>

      </div>
    )
  }
}

export default ArtistsPage
