import React from 'react'
import {Table} from 'semantic-ui-react'
import {getStatusColorRGB} from './get-status-markup.js'
import {getIcon} from '../../edit-sales-order/order-summary/item-status-order-summary.js'

const getItemStatus = (po) => {
  return (
    po.itemStatus == null || po.itemStatus.length == 0
    ?
    null
    :
    po.itemStatus
  )
}

const getItemStatusDisplayCell = (po, i) => {
  let isNull = po.itemStatus == null || po.itemStatus.length == 0
  let style = {whiteSpace: 'nowrap'}

  if (!isNull) {
    style['verticalAlign'] = 'top'
  }

  return (
    <Table.Cell key={`item_status-${i}`} style={style}>
      {
        isNull
        ?
        '—'
        :
        po.itemStatus.map((status, j) => {
          let color = getStatusColorRGB(status)
          let style = {fontSize: '12px', borderRadius: '.28571429rem', display: 'inline-block', fontWeight: 'bold', color: 'white', backgroundColor: color, padding: '0 .833em'}
          let content = (status == null ? '—' : status)
          return <div key={`item_status-${i}-${j}`} style={{marginTop: '-1px'}}><span style={style}>{getIcon(status)} {content}</span></div>
        })
      }
    </Table.Cell>
  )
}

export {getItemStatus, getItemStatusDisplayCell}
