import Utils from '../../../modules/utils'

const getVpoStages = (callbackFunc) => {
  const url = 'vpo_stages_text'

  let vpoIds = Object.keys(window.vpoStages).filter(vpoId => window.vpoStages[vpoId] == '⏳')

  const data = {vpoIds}

  Utils.post(url, data, callbackFunc)
}

export {getVpoStages}
