import React, {Component} from 'react'
import {Dimmer, Icon, Loader} from 'semantic-ui-react'
import $ from 'jquery'
import {getPrints} from './components/prints/get'
import PrintsCards from './components/prints/cards'
import Dropzone from 'react-dropzone'
import SearchInput from './components/vendor-orders/search-inputs.js'
import {filterPrints, getFilterTotals} from './components/prints/filter'
import {filtersButton} from './components/prints/filters-button'
import PrintsFilters from './components/prints/filters'
import {sortMapNameOptions} from './components/purchase-orders/sort-map-id-name-options.js'
import {Auth0Context} from './contexts/auth-context'
import {updateSelected} from './components/prints/card/update-selected'
import {exportButton} from './components/prints/export/button'
import {selectAllButton, selectAll} from './components/prints/export/select-all-button'
import {quickFixAllBoysAllGirls} from './reducers/quickFixAllBoysAllGirls'
import {markPrintAsDeleted} from './components/prints/card/delete'
import {getFilterOptions, getFilterOptionsArray} from './components/prints/get-filter-options'
import {colors, patterns, seasons, printTypeOptions} from './components/prints/options'
import {showScrollToTop} from './components/vendor-orders/show-scroll-to-top'
import {sliceAdd} from './components/vendor-orders/slice-add'

const clearFiltersState = {
  searchString: '',
  statuses: [],
  color: [],
  pattern: [],
  season: [],
  printType: [],
  sliceNum: sliceAdd
}

class PrintsPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      prints: [],
      cadTotals: {},
      statuses: [],
      searchString: '',
      filteredPrints: [],
      showFilters: false,
      colorOptions: [],
      patternOptions: [],
      seasonOptions: [],
      color: [],
      pattern: [],
      season: [],
      printType: [],
      numSelected: 0,
      loadingExport: false,
      sliceNum: sliceAdd
    }
    this.getPrintsSuccess = this.getPrintsSuccess.bind(this)
    this.updateSearchString = this.updateSearchString.bind(this)
    this.toggleFilters = this.toggleFilters.bind(this)
    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.setOptions = this.setOptions.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.updateNumSelected = this.updateNumSelected.bind(this)
    this.selectAllPrints = this.selectAllPrints.bind(this)
    this.setLoadingExport = this.setLoadingExport.bind(this)
    this.usingFilters = this.usingFilters.bind(this)
    this.markPrintAsDeleted = this.markPrintAsDeleted.bind(this)
    this.loadMore = this.loadMore.bind(this)
  }

  componentDidMount() {
    window.printIds = {}
    window.scrollTo(0, 0)
    getPrints(this.getPrintsSuccess)
    showScrollToTop()
  }

  getPrintsSuccess(data) {
    let {prints, cadTotals} = data
    window.prints = prints
    this.setState({prints, cadTotals, loading: false})
    this.setOptions(getFilterTotals(prints))
  }

  updateSearchString(value) {
    this.setState({searchString: value})
    this.filterPrints()
  }

  filterPrints() {
    setTimeout(() => {
      let {statuses, filteredPrints} = filterPrints(this.state, this.usingFilters())
      this.setState({statuses, filteredPrints})

      if (this.usingFilters()) {
        this.setOptions(getFilterTotals(filteredPrints))
      } else {
        this.setOptions(getFilterTotals(this.state.prints))
      }
    }, 0)

    setTimeout(updateSelected, 100)
    setTimeout(this.updateNumSelected,200)
  }

  toggleFilters() {
    this.setState({showFilters: !this.state.showFilters})
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}

    if (name == 'division') {
      value = quickFixAllBoysAllGirls(value)
    }

    newState[name] = value
    this.setState(newState)
    this.filterPrints()
  }

  setOptions(filterTotals) {
    let {customer} = this.context
    if (customer == null) {
      setTimeout(() => {this.setOptions(filterTotals)}, 500)
    } else {
      let colorOptions = getFilterOptionsArray(colors, filterTotals['color'])
      let patternOptions = getFilterOptionsArray(patterns, filterTotals['pattern'])
      let seasonOptions = getFilterOptionsArray(seasons, filterTotals['season'])

      this.setState({
        colorOptions, patternOptions, seasonOptions
      })
    }
  }

  clearFilters() {
    this.setState(clearFiltersState)
    $('#vpo-bulk-search').val('')
    this.setOptions(getFilterTotals(this.state.prints))
  }

  updateNumSelected() {
    let {numSelected} = this.state
    let keys = Object.keys(window.printIds)

    if (numSelected != keys.length) {
      this.setState({numSelected: keys.length})
    }
  }

  selectAllPrints() {
    let {filteredPrints, prints} = this.state
    selectAll(this.usingFilters() ? filteredPrints : prints)
    this.updateNumSelected()
  }

  setLoadingExport(loadingExport) {
    this.setState({loadingExport})
  }

  usingFilters() {
    let {searchString, color, pattern, season, printType} = this.state
    return (
      searchString != '' || color.length || pattern.length || season.length || printType.length
    )
  }

  markPrintAsDeleted(id) {
    let {prints, filteredPrints} = this.state
    let {newPrints, newFilteredPrints} = markPrintAsDeleted(id, prints, filteredPrints)
    this.setState({prints: newPrints, filteredPrints: newFilteredPrints})
  }

  scrollToTop() {
    window.scrollTo(0,0)
    $('#scroll-to-top').hide()
  }

  loadMore() {
    this.setState({sliceNum: this.state.sliceNum + sliceAdd})
  }

  render() {
    let {
      loading, prints, cadTotals, statuses, filteredPrints,
      showFilters, color,
      colorOptions, patternOptions, seasonOptions,
      pattern, season, numSelected, loadingExport, sliceNum,
      printType
    } = this.state

    let results = this.usingFilters() ? filteredPrints : prints
    let numResults = 0
    if (results == null) {
      results = []
    } else {
      numResults = results.length
    }

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>
        {loading ?
          <Dimmer active>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
        : null}

        <h1 style={{marginBottom: '35px', marginTop: '-10px'}}>Prints</h1>

        {filtersButton(showFilters, this.toggleFilters)}

        <div style={{marginBottom: '20px'}}>
          {selectAllButton(this.selectAllPrints)}
          {exportButton(numSelected, loadingExport, this.setLoadingExport)}
        </div>

        <PrintsFilters showFilters={showFilters} clearFilters={this.clearFilters}
          fieldOnChange={this.fieldOnChange}
          colorOptions={colorOptions} patternOptions={patternOptions} seasonOptions={seasonOptions}
          color={color} pattern={pattern} season={season}
          usingFilters={this.usingFilters}
          printType={printType} printTypeOptions={printTypeOptions}
          />

        <SearchInput updateSearchString={this.updateSearchString}
          showBulk={true} statuses={statuses}
          placeholder="Search: Style #"
        />

        <PrintsCards prints={results.slice(0, sliceNum)}
          cadTotals={cadTotals} updateNumSelected={this.updateNumSelected}
          markPrintAsDeleted={this.markPrintAsDeleted}
          loadMore={this.loadMore} numResults={numResults} sliceNum={sliceNum}
        />

        {numResults == 0 ?
          <center className="no-notes">No results found</center>
        : null}

        <button id="scroll-to-top" className="ui basic button" onClick={this.scrollToTop}><Icon name='arrow up' /> Back to top</button>
      </div>
    )
  }
}

export default PrintsPage
