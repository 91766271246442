import React from 'react'
import {Select} from 'semantic-ui-react'
import {packsOptions} from './options'


const packsInput = (packs, fieldOnChange) => {
  return (
    <React.Fragment>
      <label>Packs</label><br/>
      <Select search name="packs" style={{width: '300px'}} placeholder='Select a packs'
        options={packsOptions} onChange={fieldOnChange}
        value={packs}
      />
    </React.Fragment>
  )
}

export {packsInput}
