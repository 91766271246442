const updateSelected = () => {
  //reset checkboxes
  let checkboxes = document.getElementsByClassName("vpo-checkbox")
  for (let i=0; i<checkboxes.length; i++) {
    checkboxes.item(i).checked = false
  }

  //check boxes
  let {vpoIds} = window
  let ids = Object.keys(vpoIds)
  ids.forEach((id) => {
    let cadIds = Object.keys(vpoIds[id])
    cadIds.forEach((cadId) => {
      let idText = id+'-'+cadId
      if (id == 'index') {
        idText = cadId
      }

      let elementId = 'vpo-checkbox-'+idText

      if (document.getElementById(elementId) != null) {
        document.getElementById(elementId).checked = true
      }
    })
  })
}

const getNumSelected = () => {
  let {vpoIds} = window
  let ids = Object.keys(vpoIds)
  let numSelected = 0
  ids.forEach((id) => {
    let cadIds = Object.keys(vpoIds[id])
    numSelected += cadIds.length
  })
  return numSelected
}

const selectAllItems = (results) => {
  let allAreSelected = true

  for (let i=0; i<results.length; i++) {
    let row = results[i]
    let id = row.id
    let cadId = row.cad_id

    if (id == null || cadId == null) {
      id = 'index'
      cadId = i
    }

    if (!window.vpoIds[id] || !window.vpoIds[id][cadId]) {
      allAreSelected = false
      break
    }
  }

  if (allAreSelected) {
    window.vpoIds = {}
  } else {
    let vpoIds = {}
    results.forEach((row, i) => {
      let id = row.id
      let cadId = row.cad_id

      if (id == null || cadId == null) {
        id = 'index'
        cadId = i
      }

      if (vpoIds[id] == null) {
        vpoIds[id] = {}
      }
      vpoIds[id][cadId] = true
    })
    window.vpoIds = vpoIds
  }
  updateSelected()
}

export {updateSelected, getNumSelected, selectAllItems}
