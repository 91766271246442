import React, { Component } from 'react'
import $ from 'jquery'
import './UploadCadPage.css'
import { getApiEndpoint, getS3BucketName, getTempApiKey, divisionOptions, brandOptions, categoryOptions, getApplicableSubCategoryOptions, colorOptions } from './getApiEndpoint'
import { Form, Table, Card, Icon, Dropdown, Grid, Button, Input, Checkbox, Menu, Modal, Header, Loader, Dimmer, Popup} from 'semantic-ui-react'
import { connect } from 'react-redux'
import * as cadActions from './actions/cadActions'
import { bindActionCreators } from 'redux'
import Dropzone from 'react-dropzone'
import Utils from './modules/utils'
import {Auth0Context}    from './contexts/auth-context'
import createThumb from './components/upload-cad/create-thumb'
import {createPrint} from './components/prints/create'
import {checkValidPrintStyleCode, isNumber, isLetter, validColorCode} from './components/prints/check-valid-style-code'
import {brand_char_options, category_char_options, division_char_options} from './components/prints/style-code-options'
import {megaBrandsRunStockReportButton} from './components/upload-cad/mega-brands-run-stock-report-button'
import {uploadMessagesStyle} from './components/upload-cad/upload-messages-style'

class UploadCadPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      oldNumber: false,
      secondImage: '',
      division: null,
      brand: null,
      parent_category: null,
      category: null,
      packs: null,
      numFilesUploaded: 0,
      numFilesToUpload: 0
    }

    this.addLogMessage = this.addLogMessage.bind(this)
    this.toggleOldStyleNumber = this.toggleOldStyleNumber.bind(this)
    this.divisionOnChange = this.divisionOnChange.bind(this)
    this.brandOnChange = this.brandOnChange.bind(this)
    this.categoryOnChange = this.categoryOnChange.bind(this)
    this.subCategoryOnChange = this.subCategoryOnChange.bind(this)
    this.packsOnChange = this.packsOnChange.bind(this)
    this.checkIfHaveAllFilters = this.checkIfHaveAllFilters.bind(this)
    this.checkIfReplacingImage = this.checkIfReplacingImage.bind(this)
    this.uploadCad = this.uploadCad.bind(this)
    this.incrementNumFilesUploaded = this.incrementNumFilesUploaded.bind(this)
    this.secondImageOnChange = this.secondImageOnChange.bind(this)
  }

  addLogMessage(message) {
    let newMessages = this.state.messages;

    newMessages.unshift(message);

    this.setState({messages: newMessages});
  }

  updateCadImage(data, user_name, user_id, cad_id, styleNumber) {
    let {secondImage} = this.state

    let data2 = {
      cad_filename: data.cad_filename,
      user_name: user_name,
      user_id: user_id,
      cad_id: cad_id,
      secondImage
    }

    Utils.post('update_cad_image', data2, (err, res) =>
    {
      if (err) {
        console.log('There was an error when trying to update the cad image.')
      } else {
        let imageMessage = 'the image has been updated'
        if (secondImage == 'second_image') {
          imageMessage = 'the second image has been updated'
        } else if (secondImage == 'primary_image') {
          imageMessage = 'the primary image has been updated' + (res.data.replaceSecondImage ? ' and current image assigned to second' : ', the second image was ALREADY taken')
        }
        this.addLogMessage('This style number "' + styleNumber + '" has been found, ' + imageMessage)
        this.incrementNumFilesUploaded()
      }
    })
  }

  createCad(cadData, validStyleCode) {
    let {division, brand, parent_category, category, packs, secondImage} = this.state
    let profile = JSON.parse(sessionStorage.getItem('user'));
    let user_name = (profile.name != null ? profile.name : profile.email)

    let data = {
      cad_filename: cadData.cad_filename,
      user_name: user_name,
      user_id: profile.auth0Uuid,
      valid_style_code: validStyleCode,
      division, brand, parent_category, category, packs,
      secondImage
    }

    Utils.post('create_cad', data, (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to create a new cad.')
      } else {
        let data2 = res.data
        let {alreadyTaken, cadId, styleNumber, autoLinked} = res.data
        if (alreadyTaken) {
          this.updateCadImage(data, user_name, profile.auth0Uuid, cadId, styleNumber)
        } else {
          let imageMessage = ''

          if (secondImage == 'second_image') {
            imageMessage = ', file assigned as second image'
          } else if (secondImage == 'primary_image') {
            imageMessage = ', file assigned as primary image, no current image found'
          }

          let autoLinkedText = autoLinked ? ', a PRINT was auto-linked to CAD' : ''

          this.addLogMessage('A new CAD has successfully been created with style number "' + styleNumber + '"' + imageMessage + autoLinkedText)
          this.incrementNumFilesUploaded()
        }
      }
    })
  }

  incrementNumFilesUploaded() {
    if (this.state.numFilesUploaded+1 == this.state.numFilesToUpload) {
      Utils.get('update_cads_cache', (err, res) =>
      {
        if (err) {
          alert('There was an error when trying to update cads cache.')
        } else {
          console.log('cads cache updated')
        }
      })
    }

    this.setState({numFilesUploaded: this.state.numFilesUploaded+1})
  }

  checkStyleCodeFormat(s) {
    //does it have 2 dashes in it?
    let parts = s.split('-');
    if (parts.length != 3 && parts.length != 4) { //allow 3 dashs for size at the end 0314-300-PWZDE-M
      console.log('does not have 2 or 3 dashes')
      return false;
    }

    //does part1 have 1 or 2 letters then  4 or 5 numbers and maybe end in X
    let part1 = parts[0]
    let part2 = parts[1]
    let part3 = parts[2]
    let lastChar = part1[part1.length-1]
    if (['X','P','R','T'].includes(lastChar)) {
      part1 = part1.substring(0, part1.length - 1)
    }

    //check for M for Mexico Customer
    let mChar = part1[0]
    if (mChar == 'M' && isLetter(part1[1]) && isLetter(part1[2])) {
      part1 = part1.substring(1, part1.length);
    }

    //check for Q first gift with purchase
    let qChar = part1[0];
    if (qChar == 'Q') {
      part1 = part1.substring(1, part1.length);
    }

    //first char must be in division options
    let firstChar = part1[0]
    if (division_char_options.indexOf(firstChar) > -1) {
      part1 = part1.substring(1, part1.length)
    } else {
      console.log('part1 firstChar is not a in division char options')
      return false
    }

    //second char must be in sub-category options or be a number
    let sub_category_char_options = ['A','B','C','D','E','F','G','H','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
    let secondChar = part1[0];
    if (sub_category_char_options.indexOf(secondChar) > -1) {
      part1 = part1.substring(1, part1.length);
    }


    let part1IsANumber = isNumber(part1);
    if (!part1IsANumber) {
      console.log('part1 is not a number')
      return false;
    }


    //swap part 2 and 3?
    let part2IsNumberorTBD = part2 == 'TBD' || isNumber(part2)
    let part3IsNumberorTBD = part3 == 'TBD' || isNumber(part3)
    if (!part2IsNumberorTBD) {
      if (part3IsNumberorTBD) {
        [part3,part2] = [part2,part3]
      }
    }


    if (validColorCode(part2)) {
      //all good
    } else {
      console.log('part 2 is not a valid style code')
      return false
    }

    //part3 must be 2 or 3 in length, OR 4!!!!
    let numLetters = part3.length
    if (numLetters != 2 && numLetters != 3 && numLetters != 4) {
      console.log('part3 not 2 or 3 or 4 in length')
      return false;
    }

    //part3 each char must be in the set
    let packs_char_options = ["A","1","B","2","C","3","D","4","E","5","F","6","G","7","H","8","J","9","K","L","M","N","O","S","P","Q"]

    let brand_char = part3[0]
    let category_char = part3[1]
    let packs_char = part3[2]

    if (numLetters == 2) {
      packs_char = '1'
    }

    if (brand_char_options.indexOf(brand_char) == -1) {
      console.log('brand char not a valid option')
      return false;
    }

    if (category_char_options.indexOf(category_char) == -1) {
      console.log('category char not a valid option')
      return false;
    }

    if (packs_char_options.indexOf(packs_char) == -1) {
      console.log('packs char not a valid option')
      return false;
    }

    //part 4 must be a single size

    if (parts.length == 4) {
      // Monday Mar 27 2023
      // Allow any random 4th section

      // let size_options = [
      //   '0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20',
      //   '21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40',
      //   '41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59','60','XXS',
      //   'XS','S','M','L','XL','2XL','1X','2X','3X','4X','5X','12','14/16','18/20','22/24','26/28','30/32',
      //   '40DD/3X', '40D/2X', '38DD/2X', '38D/1X', '36DD/1X', '38C/XL', '36C/L',
      //   '34B/S', '34C/M', '36B/M', '36C/L', '38C/XL', '36DD/1X', '38D/1X', '38DD/2X', '40D/2X', '40DD/3X', '42D/3X',
      //   'L/XL', 'S/M', 'A', 'B', 'C', 'D'
      // ]; //if part 4 is any of those is acceptable
      // let part4 = parts[3];
      //
      // if (size_options.indexOf(part4) > -1) {
      //   console.log('found part4 in size_options')
      //   return true;
      // }
      //
      // //or can contain 2 numbers and 1 or 2 letter for bra sizes
      // if (part4.length != 3 && part4.length != 4) {
      //   console.log('part4 is not 3 or 4 in length')
      //   return false;
      // }
      //
      // let bra_numbers = ['0','1','2','3','4','5','6','7','8','9'];
      // let bra_letter = ['A','B','C','D','E','F','G'];
      //
      // if (bra_numbers.indexOf(part4[0]) == -1) { console.log('bra size error 1'); return false; }
      // if (bra_numbers.indexOf(part4[1]) == -1) { console.log('bra size error 2'); return false; }
      // if (bra_letter.indexOf(part4[2]) == -1) { console.log('bra size error 3'); return false; }
      // if (part4.length == 4 && bra_letter.indexOf(part4[3]) == -1) { console.log('bra size error 4'); return false; }

    }

    return true;
  }

  toggleOldStyleNumber() {
    this.setState({oldNumber: !this.state.oldNumber});
  }

  checkIfHaveAllFilters() {
    if (!this.state.oldNumber) { return true; }

    if (this.state.division == null) {
      $('#division-select').prop('style','border: 1px solid rgb(41,135,205);');
      alert('Division is required');
      return false;
    }

    if (this.state.brand == null) {
      $('#brand-select').prop('style','border: 1px solid rgb(41,135,205);');
      alert('Brand is required');
      return false;
    }

    if (this.state.parent_category == null) {
      $('#category-select').prop('style','border: 1px solid rgb(41,135,205);');
      alert('Category is required');
      return false;
    }

    if (this.state.category == null) {
      $('#sub-category-select').prop('style','border: 1px solid rgb(41,135,205);');
      alert('Sub-category is required');
      return false;
    }

    if (this.state.packs == null) {
      $('#packs-select').prop('style','border: 1px solid rgb(41,135,205);');
      alert('Packs is required');
      return false;
    }

    return true;
  }

  getStyleNumber(filename) {
    let parts = filename.split('.');
    parts.pop();
    return parts.join('.').flatUpcase();
  }

  checkIfReplacingImage(s, file) {
    let data = {s}
    Utils.post('does_cad_exist', data, (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to check if cad exists.')
      } else {
        //it was not a valid style number and not an admin CSV file
        //were we replacing a file? then can upload
        //or if have oldNumber
        if (res.data.replacingImage || this.state.oldNumber || window.disableOldStyleNumberCheckbox) {
          this.uploadCad(file, false, false)
        } else {
          this.addLogMessage('"' + s + '" is an invalid style code please click "I have an old style number" to manually select filter values!')
          this.incrementNumFilesUploaded()
        }
      }
    })
  }

  handleAcceptedFiles(acceptedFiles) {
    var role = sessionStorage.getItem('currentUserRole')
    let isAdmin = role == 'admin' || role == 'superadmin'
    let profile = JSON.parse(sessionStorage.getItem('user'))
    let user_email = profile.email

    //You must have all filters if oldNumber is selected
    let haveAllFilters = this.checkIfHaveAllFilters()
    if (!haveAllFilters) {return}

    this.setState({numFilesToUpload: acceptedFiles.length, numFilesUploaded: 0})

    for (var i=0; i<acceptedFiles.length; i++) {
      let file = acceptedFiles[i]
      let filename = file.name
      let styleNumber = this.getStyleNumber(filename)
      let isAdminCsvFile = filename.toLowerCase().indexOf('.csv') > -1 && isAdmin
      let isPrint = ['P','G'].includes(styleNumber[0]) && isNumber(styleNumber[1])

      let validStyleCode = (isPrint ? false : this.checkStyleCodeFormat(styleNumber))
      let validPrintStyleCode = (isPrint ? checkValidPrintStyleCode(styleNumber) : false)

      //general file type check
      if (!isAdminCsvFile && filename.toLowerCase().indexOf('.png') == -1 && filename.toLowerCase().indexOf('.jpg') == -1 && filename.toLowerCase().indexOf('.jpeg') == -1) {
        this.addLogMessage('Only allow .jpg and .png file types.  File ' + filename + ' cannot be uploaded!')
        this.incrementNumFilesUploaded()
      // deal with invalid print style code
      } else if (isPrint && !validPrintStyleCode) {
        alert ('"' + styleNumber + '" is an invalid Print style code')
        this.incrementNumFilesUploaded()
      } else if (validStyleCode || isAdminCsvFile || isPrint) {
        this.uploadCad(file, validStyleCode, isAdminCsvFile, isPrint)
      } else {
        this.checkIfReplacingImage(styleNumber, file)
      }
    }
  }

  uploadCad(file, validStyleCode, isAdminCsvFile, isPrint) {
    const {customer} = this.context

    let formData = new FormData()
    let filename = file.name
    formData.append("file", file, file.name)
    formData.append("org_id", customer.id)

    const thumbCallback = () => {
      $.ajax({
        type: "POST",
        url: process.env.REACT_APP_API_URL + '/file-upload-cad',
        success: function (data) {
            if (data.success) {
              if (isAdminCsvFile) {
                this.addLogMessage('The file "' + filename + '" has been added to a new stock report.')
              } else if (isPrint) {
                createPrint(data, this.addLogMessage)
              } else {
                this.createCad(data, validStyleCode)
              }

            } else {
              if (!!data.corrupt_file) {
                this.addLogMessage('The file "' + filename + '" is corrupt.')
              } else {
                alert('There was an error when trying to upload the file "'+filename+'"')
              }

            }

        }.bind(this),
        error: function (error) {
            alert('There was an error when trying to upload the file.')

        }.bind(this),
          async: true,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000
      })
    }

    if (isAdminCsvFile) {
      thumbCallback()
    } else {
      createThumb(file, formData, thumbCallback)
    }
  }

  divisionOnChange(event, {value}) {
    this.setState({division: value});
    $('#division-select').prop('style','');
  }

  brandOnChange(event, {value}) {
    this.setState({brand: value});
    $('#brand-select').prop('style','');
  }

  categoryOnChange(event, {value}) {
    this.setState({parent_category: value});
    $('#category-select').prop('style','');

    const {customer} = this.context
    let category = customer.categories.filter((x) => {return x.name == value})[0]

    if (category.subcategories.length > 0) {
      let applicableSubcategories = category.subcategories
        .map(x => customer.subcategories.find(el => el.id == x))
        .map((x) => {return x.name})

      let value2 = this.state.category
      if (applicableSubcategories.indexOf(value2) == -1) {
        this.setState({category: null});
      }
    }
  }

  subCategoryOnChange(event, {value}) {
    this.setState({category: value});
    $('#sub-category-select').prop('style','');
  }

  packsOnChange(event, {value}) {
    this.setState({packs: value});
    $('#packs-select').prop('style','');
  }

  downloadUploadLogs() {
    Utils.get('cads_upload_logs', (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to download logs.')
      } else {
        if (res.data.success) {
          window.location = res.data.publicUrl
        }
      }
    })
  }

  checkStockReportColumns() {
    Utils.get('stock_report_check_columns', (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to check stock report columns.')
      } else {
        let result = JSON.parse(res.data.columnsFound)
        let fileNames = Object.keys(result).filter(x => result[x] === false)
        if (fileNames.length > 0) {
          alert('ERROR WITH: '+fileNames.join(','))
        } else {
          alert('ALL GOOD.')
        }
      }
    })
  }

  secondImageOnChange(event) {
    this.setState({secondImage: event.currentTarget.value})
  }

  render() {
    let {secondImage} = this.state

    let role = sessionStorage.getItem('currentUserRole')
    let isAdmin = role == 'admin' || role == 'superadmin'

    const {user, customer, setMessage} = this.context
    let categories = customer ? Utils.objectsToOptionsHash([...customer.categories], 'id', 'name') : []
    let subcategories = customer ? Utils.objectsToOptionsHash([...customer.subcategories], 'id', 'name') : []
    let divisions = customer ? Utils.objectsToOptionsHash([...customer.divisions], 'id', 'name') : []
    let brands = customer ? Utils.objectsToOptionsHash([...customer.brands], 'id', 'name') : []
    let packsOptions = customer ? Utils.objectsToOptionsHash([...customer.packs], 'id', 'name') : []

    categories = categories.optionsHashTextAsKey()
    subcategories = subcategories.optionsHashTextAsKey()
    divisions = divisions.optionsHashTextAsKey()
    brands = brands.optionsHashTextAsKey()
    packsOptions = packsOptions.optionsHashTextAsKey()

    //get applicable subcategories
    if (this.state.parent_category != null) {
      let category = customer.categories.filter((x) => {return x.name == this.state.parent_category})[0]
      if (category.subcategories != null && category.subcategories.length > 0) {
        subcategories = category.subcategories
          .map(x => customer.subcategories.find(el => el.id == x))
          .map((x) => {return { key: x.name, text: x.name, value: x.name } })
      }
    }

    let org_ids = process.env.REACT_APP_ORG_IDS_WITH_STYLECODES.split(',').map(Number)
    let disableOldStyleNumberCheckbox
    if (customer != null) {
      disableOldStyleNumberCheckbox = !org_ids.includes(customer.id)
    }

    window.disableOldStyleNumberCheckbox = disableOldStyleNumberCheckbox

    return (
      <div style={{padding: '50px 60px'}}>

        {
          !!customer && customer.id == 2
          ?
          megaBrandsRunStockReportButton()
          :
          null
        }

        <h1 style={{marginTop: '25px', marginBottom: '30px'}}>New cad/print/graphics
          <small style={{fontSize: '65%'}}>
            <Popup content='Download logs' position='bottom right' trigger={<Button basic icon='file excel outline' onClick={this.downloadUploadLogs} style={{float: 'right', padding: '14px', marginLeft: '30px'}}/>} />

            {
              isAdmin
              ?
              <Popup content='Check Stock Report Columns' position='bottom right' trigger={<Button basic icon='columns' onClick={this.checkStockReportColumns} style={{float: 'right', padding: '14px', marginLeft: '30px'}}/>} />
              :
              null
            }

            {disableOldStyleNumberCheckbox ? null :
              <Checkbox style={{float: 'right', marginTop: '13px'}} toggle label='I have an old style number' onChange={this.toggleOldStyleNumber} checked={this.state.oldNumber}/>
            }
            <div style={{display: 'inline-block', width: '300px', float: 'right', marginRight: '70px', position: 'relative', bottom: '30px'}}>
              <b style={{fontSize: '12px', position: 'relative', top: '9px'}}>Second Image</b>
              <select id="secondImageSelect" value={secondImage} onChange={this.secondImageOnChange} aria-label="Second Image" style={{width: '300px'}}>
                  <option value=""></option>
                  <option value="second_image">Upload as Second Image</option>
                  <option value="primary_image">Upload as Primary Image and make current image the Second</option>
              </select>
            </div>
          </small>&nbsp;&nbsp;&nbsp;
        </h1>

        <h3 style={{opacity: 0.6, fontWeight: 500, marginBottom: '25px'}}>The filename will be used as the style number.</h3>
          {this.state.oldNumber || disableOldStyleNumberCheckbox ?
            <Form>
              <Form.Group widths='equal'>
                <Form.Select id="division-select" fluid label='Division' onChange={this.divisionOnChange} options={divisions} placeholder='Division' value={this.state.division} />

                <Form.Select id="brand-select" fluid label='Brand' onChange={this.brandOnChange} options={brands} placeholder='Brand' value={this.state.brand} />

                <Form.Select id="category-select" fluid label='Category' onChange={this.categoryOnChange} options={categories} placeholder='Category' value={this.state.parent_category} />

                <Form.Select id="sub-category-select" fluid label='Sub-category' onChange={this.subCategoryOnChange} options={subcategories} placeholder='Sub-category' value={this.state.category} />

                <Form.Select id="packs-select" fluid label='Packs' onChange={this.packsOnChange} options={packsOptions} placeholder='Packs' value={this.state.packs} />
              </Form.Group>
            </Form>
          : null }

          {this.state.messages.length == 0 ? null : (
            <div style={uploadMessagesStyle}>
              {this.state.messages.map(function(message, index) {
                return (
                  <div key={index}>{message}</div>
                );
              })}
            </div>
          )}

          <Dropzone onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} id="my-dropzone" >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>
      </div>
    );
  }
}

const mapStateToProps = state => ({
 ...state
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(cadActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadCadPage);
