import React       from 'react'
import {
          Message,
          Tab
       }           from 'semantic-ui-react'
import _           from 'lodash'
import DateString  from './../custom-date-string'


class LogMessages extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading: true
    }
  }

  componentDidMount()
  {
    var {titles, logs} = this.props
    if (titles && logs)
    {
      this.setState({ loading: false })
    }
  }

  render()
  {
    var {titles, logs} = this.props
    var panes = []

    const buildPane = (title, logs) =>
    {
      return {
        menuItem: title,
        render  : () => <Tab.Pane style={{maxHeight: '350px', overflow: 'auto'}}>
            {
              logs != null && logs.length > 0 ?
              _.orderBy(logs, ['createdAt'], ['desc']).map((log, i) => {
                return <div key={i} style={{marginBottom: '1em'}}>
                  {
                    Object.entries(log.auditedChanges).map(([key, value], j) => {
                      return (
                        <Message key={`log.id_${key}_${j}`}>
                          <Message.Header>
                            <span>
                              {log.user.name} @
                            </span>
                            <DateString
                              dateString={log.createdAt}
                            />
                          </Message.Header>
                          <p>
                            Changed <i>{_.startCase(key)}</i> from <b>{value[0] || 'empty'}</b> to <b>{value[1].toString()}</b>
                          </p>
                        </Message>
                      )
                    })
                  }
                </div>
              })
              :
              <Message>
                <p> Currently no log messages! </p>
              </Message>
            }
          </Tab.Pane>
      }
    }

    for(var index in titles)
    {
      panes.push(buildPane(titles[index], logs[index]))
    }

    let markup =
      <div>
        {
          !this.state.loading &&
          <Tab
            menu={{secondary: true, pointing: true}}
            panes={panes}
          />
        }
      </div>

    return markup;
  }
}

export default LogMessages
