import React, {Component} from 'react'
import {Accordion, Icon} from 'semantic-ui-react'
import {formatDate} from '../cads/format-date'

class StartShipDateLogs extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      active: false
    }

    this.toggleAccordion = this.toggleAccordion.bind(this)
  }

  toggleAccordion() {
    this.setState({active: !this.state.active})
  }

  render() {
    let {cadId} = this.props
    let {active} = this.state
    let {logs} = (window.editSalesOrderPage == null ? {logs: []} : window.editSalesOrderPage)

    logs = logs.filter(log => log.changed_attribute_name == 'cad_id_'+cadId+'_start_ship')

    return (
      <div style={{fontSize: '10px'}}>
        {
          logs.length == 0
          ?
          null
          :
          <Accordion>
            <Accordion.Title onClick={this.toggleAccordion} style={{fontWeight: 'bold'}}>
              <Icon name='dropdown' />
              Original Ship
            </Accordion.Title>
            <Accordion.Content active={active} style={{marginTop: '-15px'}}>
              <ul style={{paddingLeft: '23px'}}>
                {
                  logs.map((log) => {
                    return (
                      <li style={{whiteSpace: 'nowrap', letterSpacing: '-0.1px'}}>changed from {formatDate(log.old_value)} to {formatDate(log.new_value)}</li>
                    )
                  })
                }
              </ul>
            </Accordion.Content>
          </Accordion>
        }
      </div>
    )
  }
}

export default StartShipDateLogs
