import React, {Component} from 'react'
import {Transition, Grid} from 'semantic-ui-react'
import VendorOrdersDropdown from '../vendor-orders/vendor-orders-dropdown.js'

class PrintsFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    let {
      showFilters, fieldOnChange,
      colorOptions, patternOptions, seasonOptions,
      color, pattern, season, clearFilters, usingFilters,
      printType, printTypeOptions
    } = this.props

    return (
      <Transition visible={showFilters} animation='fade' duration={showFilters ? 1000 : 500}>
        <Grid stackable columns={5} style={{marginBottom: '13px'}}>
          <Grid.Row>
            <VendorOrdersDropdown
              placeholder='Color'
              name='color'
              options={colorOptions}
              onChange={fieldOnChange}
              value={color}
            />

            <VendorOrdersDropdown
              placeholder='Pattern'
              name='pattern'
              options={patternOptions}
              onChange={fieldOnChange}
              value={pattern}
            />

            <VendorOrdersDropdown
              placeholder='Season'
              name='season'
              options={seasonOptions}
              onChange={fieldOnChange}
              value={season}
            />

            <VendorOrdersDropdown
              placeholder='Type'
              name='printType'
              options={printTypeOptions}
              onChange={fieldOnChange}
              value={printType}
            />

            {
              usingFilters()
              ?
              <Grid.Column style={{textAlign: 'right'}}>
                <a onClick={clearFilters} className="cads-reset-filters">Reset filters</a>
              </Grid.Column>
              :
              null
            }

          </Grid.Row>
        </Grid>
      </Transition>
    )
  }
}

export default PrintsFilters
