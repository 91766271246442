import Utils from '../../modules/utils'

const getContacts = (callbackFunc) => {
  Utils.get('contacts', (err, res) =>
  {
    if (err) {
      alert('There was an error when trying to get contacts.')
    } else {
      callbackFunc(res.data.data.map(row => row.attributes))
    }
  })
}

export {getContacts}
