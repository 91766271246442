import React, {Component} from 'react'
import {Dropdown} from 'semantic-ui-react'

class CadDropdown extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {isAuthenticated, user, cadMenu, setCadMenu, Nav} = this.props

    if (isAuthenticated && user) {
      return (
        <span
          onMouseOver={(e) => setCadMenu(true)}
          onMouseOut={(e) => setCadMenu(false)}
          style={{marginLeft: '100px'}}
        >
          <Dropdown
            item
            as={Nav}
            open={cadMenu}
            icon=''
            to="/cads"
            name="cads"
            text="Cads"
            style={{background: 'transparent'}}
          >
            <Dropdown.Menu>
              {
                ['customer', 'warehouse'].includes(user.role)
                ?
                null
                :
                <React.Fragment>
                  <Dropdown.Item
                    as={Nav}
                    to="/cads/new"
                    content="New cad/print/graphics"
                    onClick={(e) => setCadMenu(false)}
                  />
                  <Dropdown.Item
                    as={Nav}
                    to="/cads/generate-style-number"
                    content="Generate style number"
                    onClick={(e) => setCadMenu(false)}
                  />
                  <Dropdown.Item
                    as={Nav}
                    to="/cads/style-codes-table"
                    content="Style codes table"
                    onClick={(e) => setCadMenu(false)}
                  />
                  <Dropdown.Item
                    as={Nav}
                    to="/cads/long-style-numbers"
                    content="Get long style numbers"
                    onClick={(e) => setCadMenu(false)}
                  />
                  <Dropdown.Item
                    as={Nav}
                    to="/cads/upc"
                    content="Import UPC"
                    onClick={(e) => setCadMenu(false)}
                  />
                </React.Fragment>
              }
            </Dropdown.Menu>
          </Dropdown>
        </span>
      )
    } else {
      return null
    }
  }
}

export default CadDropdown
