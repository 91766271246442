import React, { Component } from 'react'
import {getProperty} from './components/properties/get'
import {infoTable} from './components/edit-property/info-table'
import {Transition, Grid, Icon, Popup} from 'semantic-ui-react'
import {fileList} from './components/share-property/file-list'
import {start} from './components/properties/start'
import $ from 'jquery'
import FullscreenImage from './components/share-property/fullscreen-image'

const chevronStyle = {fontSize: '50px', verticalAlign: 'top', marginTop: '35vh', cursor: 'pointer'}
const chevronStyleHide = Object.assign({}, chevronStyle, {opacity: 0.1, pointerEvents: 'none'})

class SharePropertyPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      property: null,
      documents: [],
      images: [],
      imageIndex: 0,
      orgId: null,
      token: null
    }

    this.nextImage = this.nextImage.bind(this)
    this.previousImage = this.previousImage.bind(this)
    this.arrowKeyboardClick = this.arrowKeyboardClick.bind(this)
  }

  componentDidMount() {
    let token = window.location.href.split('token=')[1]

    this.setState({token})

    getProperty(token, this.props.id, (err, res) => {
      if (err) {
        alert('There was an error when trying to get the property.')
      } else if (res.data.expired) {
        alert('Shared link has expired!')
      } else {
        let {property, documents, images, orgId} = res.data
        this.setState({property, documents, images, orgId, loading: false})
        start(orgId, property.address)
      }
    })

    $(document).unbind( "keyup", this.arrowKeyboardClick )
    $(document).keyup(this.arrowKeyboardClick)
  }

  arrowKeyboardClick(e) {
    if (e.keyCode == '37') {
      this.previousImage()
    } else if (e.keyCode == '39') {
      this.nextImage()
    }
  }

  backToAll() {
    let token = window.location.href.split('token=')[1]
    window.location = '/#/share-properties?token=' + token
  }

  nextImage() {
    let {imageIndex, images} = this.state

    if (imageIndex != images.length - 1) {
      this.setState({imageIndex: this.state.imageIndex+1})
    }
  }

  previousImage() {
    let {imageIndex} = this.state

    if (imageIndex != 0) {
      this.setState({imageIndex: imageIndex-1})
    }
  }

  imageOnClick() {
    document.getElementById('fullscreen-image').requestFullscreen()
  }

  render() {
    let {property, documents, images, imageIndex, token} = this.state

    return (
      <Transition visible={property != null} animation='fade' duration={3000}>
        <div style={{background: 'rgb(250,250,250)', minHeight: '100vh', padding: '30px'}}>
          {
            token == null
            ?
            null
            :
            <a onClick={this.backToAll} style={{cursor: 'pointer'}}><Icon name='arrow left'/>Back to all</a>
          }

          <FullscreenImage src={property == null ? null : images[imageIndex].filename}
            address={property == null ? null : property.address}
            showing={(imageIndex+1)+'/'+(images.length)}
          />

          {
            images.length > 0
            ?
            <div style={{textAlign: 'center'}}>
              {
                images.length > 1
                ?
                <Icon className="propertyImageCarouselChevron" name='chevron left' style={imageIndex == 0 ? chevronStyleHide : chevronStyle} onClick={this.previousImage}/>
                :
                null
              }

              <Popup content={'Click for fullscreen mode!'} position='bottom right'
                trigger={
                  <img className="share-property-image" src={property == null ? null : images[imageIndex].filename} style={{height: '76vh'}} onClick={this.imageOnClick} />
                }
              />

              {
                images.length > 1
                ?
                <Icon className="propertyImageCarouselChevron" name='chevron right' style={imageIndex == images.length - 1 ? chevronStyleHide : chevronStyle} onClick={this.nextImage}/>
                :
                null
              }

              <br/>
              {property == null ? null : images[imageIndex].description}
              <br/>
              {
                images.length > 1
                ?
                <span>{imageIndex+1}/{images.length}</span>
                :
                null
              }
            </div>
            :
            null
          }

          <h2 style={{textAlign: 'center'}}>{property == null ? null : property.address}</h2>

          <Grid divided columns={2} style={{maxWidth: '750px', margin: 'auto', marginTop: '100px', marginBottom: '100px'}}>
            <Grid.Row>
              <Grid.Column>
                <h2>Asset Overview</h2>
                <div style={{width: '300px'}}>{property == null ? null : infoTable(property)}</div>
              </Grid.Column>
              <Grid.Column style={{paddingLeft: '50px'}}>
                <h2>Documents</h2>
                {fileList(documents)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Transition>
    )
  }
}

export default SharePropertyPage
