import React from 'react'
import {Table, Image} from 'semantic-ui-react'

const imageTableCell = (row) => {
  if (!!row.cad) {
    let {cad} = row
    let imageUrl
    let s3BucketName = cad.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME
    if (cad.filename == null) {
      imageUrl = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new.png'
    } else if (cad.have_thumb) {
      imageUrl = 'https://s3.amazonaws.com/' + s3BucketName + '/cad_thumb/' + cad.filename.split('/')[0] + '/thumb.jpg'
    } else {
      imageUrl = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + cad.filename
    }

    return <Table.Cell><Image src={imageUrl} size='small'/></Table.Cell>
  } else {
    return <Table.Cell></Table.Cell>
  }
}

export {imageTableCell}
