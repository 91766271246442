import React, {Component} from 'react'
import {costMultipleByOrg} from './cost-multiple-by-org.js'
import CostPopup from '../cost/popup.js'

const mainDivStyle = {position: 'absolute', right: 0, bottom: 0, opacity: 0.6}

class DisplaySalesPrice extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  truncate(num) {
    num *= 100
    num = Math.round(num)
    return num / 100
  }

  render() {
    let {cad, orgId} = this.props
    let {ave_cost, future_ats_ave_cost} = cad

    let cost = Number(ave_cost)
    let futureCost = Number(future_ats_ave_cost)

    if (cost == 0 && futureCost == 0) {
      return (
        <div style={mainDivStyle}>
          <CostPopup cadId={cad.id} orgId={orgId} />
        </div>
      )
    }

    let haveFutureCost = future_ats_ave_cost != null && Number(future_ats_ave_cost) != 0
    let havePastCost = ave_cost != null && Number(ave_cost) != 0

    let useVal = haveFutureCost ? futureCost : havePastCost ? cost : null

    useVal *= costMultipleByOrg(orgId)

    return (
      <div style={mainDivStyle}>
        ${this.truncate(useVal)} <CostPopup cadId={cad.id} orgId={orgId} />
      </div>
    )
  }
}

export default DisplaySalesPrice
