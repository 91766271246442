import                       './index.css'
import React            from 'react'
import {
          Header,
          Grid,
          Icon,
          Form,
          Button,
          Popup,
          Message,
          Dimmer,
          Loader
       }                from 'semantic-ui-react'
import cloneDeep        from 'lodash/cloneDeep'
import BolPodAPI        from './../../modules/api/bol_pod_api'
import PurchaseOrderAPI from './../../modules/api/purchase_order_api'
import DateString       from './../shared/custom-date-string'
import {DatePickerField}from './../ui/date-picker.js'
import Constants        from './../../modules/constants'
import Utils            from './../../modules/utils'


class BolPodPage extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading      : true,
      bolPodEntries: [],
      recentBolPods: []
    }
  }

  defaultEntry =
  {
    ponumber            : '',
    filename            : '',
    shippedDate         : new Date,
    bolPodFileAttributes: null,
    podFile             : React.createRef(),
    validPO             : false
  }

  RECORD_LIMIT = 5

  componentDidMount()
  {
    this.fetchRecentBolPods()
    this.setState({
      loading      : false,
      bolPodEntries: [cloneDeep(this.defaultEntry)]
    })
  }

  fetchRecentBolPods = () =>
  {
    var callback = (error, data) =>
    {
      if (error)
      {
        console.log(error)
      }
      else
      {
        this.setState({ recentBolPods: data.bolPods })
      }
    }

    BolPodAPI.getAll(callback, this.RECORD_LIMIT)
  }

  addMoreRow = (event) =>
  {
    event.preventDefault()
    var {bolPodEntries} = this.state
    bolPodEntries.push(cloneDeep(this.defaultEntry))

    this.setState({ bolPodEntries })
  }

  removeLastRow = (event) =>
  {
    event.preventDefault()
    var {bolPodEntries} = this.state
    bolPodEntries.pop()

    this.setState({ bolPodEntries })
  }

  onChangePOnumber = (event, input, index) =>
  {
    var value                     = Utils.flatUpcase(input.value)
    var {bolPodEntries}           = this.state
    bolPodEntries[index].ponumber = value

    this.setState({ bolPodEntries })

    var callback = (error, data) =>
    {
      if (!error)
      {
        bolPodEntries[index].validPO = data.isValid && !this.checkPOUniqueness(value, index)
        this.setState({ bolPodEntries })
      }
    }

    PurchaseOrderAPI.validatePurchaseorder(value, callback, 'bolpod')
  }

  checkPOUniqueness = (value, index) =>
  {
    var entries = this.state.bolPodEntries
    return entries.find((entry, i) => entry.ponumber == value && i !== index)
  }

  onChangeShippedDate = (event, input, index) =>
  {
    var {bolPodEntries}              = this.state
    bolPodEntries[index].shippedDate = input.value

    this.setState({ bolPodEntries })
  }

  onChangeFilename = (event, input, index) =>
  {
    var {bolPodEntries}           = this.state
    bolPodEntries[index].filename = input.value

    this.setState({ bolPodEntries })
  }

  handleFiles = (event, index) =>
  {
    this.setState({ loading: true })

    var acceptedFiles         = event.target.files
    var numberOfFilesToUpload = acceptedFiles.length
    var numberOfFilesUploaded = 0

    let url = Constants.API.apiEndPoint + 'upload-file?tempKey=' + Constants.API.tempApiKey

    for (var i=0; i<acceptedFiles.length; i++)
    {
      let file     = acceptedFiles[i]
      let formData = new FormData()
      let filename = file.name

      if (Utils.badFileType(file))
      {
        this.addLogMessage('Only allow image, microsoft word, excel, pdf file types.  File "' + filename + '" cannot be uploaded!')
        numberOfFilesUploaded += 1
        if (numberOfFilesUploaded == numberOfFilesToUpload)
        {
          this.setState({ loading: false })
        }
      }
      else
      {
        formData.append("file", file, file.name)

        Utils.uploadFile(url, formData, (error, data) =>
        {
          if (error)
          {
            alert('There was an error when trying to upload the file.')
            numberOfFilesUploaded += 1
            if (numberOfFilesUploaded == numberOfFilesToUpload)
            {
              this.setState({ loading: false })
            }
          }
          else
          {
            if (data.success)
            {
              this.addFile(data, filename, index)
            }
            else
            {
              if (data.corrupt_file)
              {
                this.addLogMessage('The file "' + filename + '" is corrupt.')
              }
              else
              {
                alert('There was an error when trying to upload the file "' + filename + '"')
              }
            }

            numberOfFilesUploaded += 1
            if (numberOfFilesUploaded == numberOfFilesToUpload)
            {
              this.setState({ loading: false })
            }
          }
        })
      }
    }
  }

  addLogMessage = (message) =>
  {
    window.alert(message)
  }

  addFile = (data, filename, index) =>
  {
    var {bolPodEntries}                       = this.state
    bolPodEntries[index].bolPodFileAttributes = { filename: data.filename, url: data.url }
    bolPodEntries[index].filename             = data.filename

    this.setState({ bolPodEntries })
  }

  removeBolPodFile = (event, index) =>
  {
    var {bolPodEntries}                       = this.state
    bolPodEntries[index].bolPodFileAttributes = null
    bolPodEntries[index].filename             = ''

    this.setState({ bolPodEntries })
  }

  onSubmitBolPods = () =>
  {
    this.setState({ loading: true })
    var {bolPodEntries, recentBolPods} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
        this.setState({ loading: false })
      }
      else
      {
        this.setState({
          loading      : false,
          bolPodEntries: [cloneDeep(this.defaultEntry)],
          recentBolPods: [...data.bolPods, ...recentBolPods]
        })
      }
    }

    BolPodAPI.bulkCreate({bolPodEntries: bolPodEntries}, callback)
  }

  isInputsValidated = () =>
  {
    var {bolPodEntries} = this.state
    let valid           = true

    bolPodEntries.forEach((entry) =>
    {
      if (
        entry.shippedDate          == null ||
        entry.bolPodFileAttributes == null ||
        entry.validPO              == false ||
        entry.filename.trim()      == ''
      )
      {
        valid = false
        return;
      }
    })

    return valid
  }

  render()
  {
    var asterik       = <span className='asterik'>&#42;&nbsp;</span>
    var disableSubmit = !this.isInputsValidated()

    var markup =
      <div id='bol-pod-section' style={{padding: '50px 60px'}}>
        <Dimmer
          inverted
          active={this.state.loading}
          style={{position: 'fixed'}}
        >
          <Loader />
        </Dimmer>

        <Header
          as='h1'
          content="BOL / POD entry"
        />
        <Grid columns={3} style={{margin: '20px 0'}}>
          <Grid.Row style={{alignItems: 'flex-end'}}>
            <Grid.Column>
              <Header as='h5'>
                <Header.Content>
                  {asterik}
                  PO Number
                </Header.Content>
              </Header>
            </Grid.Column>

            <Grid.Column>
              <Header as='h5'>
                <Header.Content>
                  {asterik}
                  Shipped Date
                </Header.Content>
                <Header.Subheader>
                  (The status will be changed to Shipped)
                </Header.Subheader>
              </Header>
            </Grid.Column>

            <Grid.Column>
              <Header as='h5'>
                <Header.Content>
                  {asterik}
                  Attach BOL / POD
                  <Icon style={{marginLeft: '5px'}}
                    color='teal'
                    size='small'
                    name='shopping cart'
                  />
                </Header.Content>
                <Header.Subheader>
                  (Accepted file types: image, microsoft word, excel, pdf)
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          {
            this.state.bolPodEntries.map((entry, index) => {
              return <Grid.Row key={index}>
                <Grid.Column className='ponumber-input'>
                  <Form.Input
                    fluid
                    icon={entry.validPO ? 'check' : 'close'}
                    iconPosition='left'
                    placeholder='PO Number'
                    value={entry.ponumber}
                    onChange={(e, input) => this.onChangePOnumber(e, input, index)}
                  />
                </Grid.Column>

                <Grid.Column className="date-picker-col">
                  <DatePickerField
                    minDate={new Date}
                    placeholderText='mm/dd/yyyy'
                    selected={entry.shippedDate ? new Date(entry.shippedDate) : null}
                    onChange={(data, e) => this.onChangeShippedDate(e, {name: 'dueDate', value: data}, index)}
                    customInput={true}
                    isClearable
                  />
                </Grid.Column>

                <Grid.Column>
                  {
                    entry.bolPodFileAttributes ?
                    <Form.Input
                      fluid
                      icon={<Icon name='file alternate' />}
                      iconPosition='left'
                      placeholder='Bol / Pod filename'
                      value={entry.filename}
                      onChange={(e, input) => this.onChangeFilename(e, input, index)}
                      action={<Button icon='close' color='red' onClick={(e) => this.removeBolPodFile(e, index)} />}
                    />
                    :
                    <div>
                      <Button
                        basic
                        content='Choose File'
                        icon='file alternate outline'
                        labelPosition='left'
                        onClick={() => entry.podFile.current.click()}
                      />
                      <input
                        ref={entry.podFile}
                        type="file"
                        hidden
                        onChange={(e) => this.handleFiles(e, index)}
                      />
                    </div>
                  }
                </Grid.Column>
              </Grid.Row>
            })
          }
        </Grid>

        <div style={{padding: '0 1rem'}}>
          <center>
            <Button
              size='tiny'
              icon='plus'
              onMouseDown={this.addMoreRow}
            />
            <Button
              size='tiny'
              icon='minus'
              disabled={this.state.bolPodEntries.length == 1}
              onMouseDown={this.removeLastRow}
            />
          </center>
          <Button
            disabled={disableSubmit}
            primary
            content='Submit'
            onClick={this.onSubmitBolPods}
          />
        </div>
        <div style={{padding: '1rem', marginTop: '5rem'}}>
          <Header>
            <Header.Content>
              Recently Added BOL / POD (s)
            </Header.Content>
            {
              this.state.recentBolPods.length <= 0 &&
              <Header.Subheader>
                (There are no BOL / POD(s) right now)
              </Header.Subheader>
            }
          </Header>
          {
            this.state.recentBolPods.length > 0 &&
            <Message>
              {
                this.state.recentBolPods.slice(0, this.RECORD_LIMIT).map((bolPod, j) => {
                  return <Message.Content key={j} style={{padding: '10px 0'}}>
                      <b>{bolPod.ponumber}</b> has been marked as <b>Shipped</b> By <b>{bolPod.creator}</b> <i>on</i>&nbsp;
                      <DateString
                        dateString={bolPod.createdAt}
                      />
                    </Message.Content>
                })
              }
            </Message>
          }
        </div>
      </div>

    return markup;
  }
}

export default BolPodPage;
