import React from 'react'
import {sumShipped, sumShippedDollar} from '../sum.js'

const shippedTotalWithSalesHistoryAccount = (cads, salesHistoryAccount) => {
  return (
    <div className="ui label">
      Shipped QTY
      <div className="detail">
        {
          window.salesAccountDataFiltered == null
          ?
          null
          :
          cads.reduce((a, b) => a + sumShipped(window.salesAccountDataFiltered[b.style_number.flatUpcase()], salesHistoryAccount) , 0).totalFormat()
        }
      </div>
    </div>
  )
}

const shippedDollarTotalWithSalesHistoryAccount = (cads, salesHistoryAccount) => {
  return (
    <div className="ui label">
      Shipped $
      <div className="detail">
        {
          window.salesAccountDataFiltered == null
          ?
          null
          :
          Math.round(cads.reduce((a, b) => a + sumShippedDollar(window.salesAccountDataFiltered[b.style_number.flatUpcase()], salesHistoryAccount) , 0)).totalFormat()
        }
      </div>
    </div>
  )
}

export {shippedTotalWithSalesHistoryAccount, shippedDollarTotalWithSalesHistoryAccount}
