import React from 'react'
import {Button} from 'semantic-ui-react'
import {validStyleNumber} from './valid-style-number'
import {runStyleCodes} from './run-style-codes'
import {externalLink} from '../upload-cad/external-link'

const inputStyleNumberButton = (updateStyleNumber, disabled) => {
  return (
    <div>
      <Button color='green' disabled={disabled} style={{float: 'right'}} onClick={() => inputStyleNumber(updateStyleNumber)}>Input Style Number</Button>

      <div style={{float: 'right', marginRight: '30px', background: 'rgb(255, 255, 240)', border: '1px solid yellow', padding: '10px', zoom: '60%'}}>
        <b>NOTE &nbsp; — &nbsp; 4/26/2024 &nbsp; — &nbsp; Sub-Categories combined</b><br/>
        V = PANT ← LEGGINGS<br/>
        T = SLIPS/SKIRTS ← DRESSES/SKORTS<br/>
        X = TOP ← TEE SHIRT<br/>
      </div>

      <div style={{float: 'right', marginRight: '30px', background: 'rgb(255, 255, 240)', border: '1px solid yellow', padding: '10px', zoom: '60%'}}>
        <b>NOTE &nbsp; — &nbsp; 8/8/2024 &nbsp; — &nbsp; Brand added</b><br/>
        K = BE HAPPY<br/>
      </div>

      <div style={{float: 'right', marginRight: '30px', background: 'rgb(255, 255, 240)', border: '1px solid yellow', padding: '10px', zoom: '60%'}}>
        <b>NOTE &nbsp; — &nbsp; 8/13/2024 &nbsp; — &nbsp; Pets Division and applicable Sub-Category / Category added</b><br/>
        <table>
          <tr>
            <td style={{verticalAlign: 'top', lineHeight: '1.3'}}>
              Division: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>
              P = PETS
            </td>
            <td style={{lineHeight: '1.3'}}>
              Sub-Category:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>
              D = DONUT<br/>
              C = COUCH<br/>
              S = SOFA<br/>
              P = PILLOW/CLASSIC<br/>
              E = COMFORTER SET<br/>
              T = SCRATCHER<br/>
              V = CAVE<br/>
              H = SWEATERS<br/>
              J = JACKETS<br/>
              F = PLUSH<br/>
              R = RUBBER<br/>
              B = BOWL<br/>
              G = GROOMING<br/>
              L = LEASH<br/>
              M = COLLAR<br/>
              A = THROWS
            </td>
            <td style={{verticalAlign: 'top', lineHeight: '1.3'}}>
              Category:<br/>
              D = BEDS<br/>
              P = APPAREL<br/>
              Y = TOYS<br/>
              C = ACCESSORIES<br/>
              J = CARRIERS<br/>
              U = HUTS
            </td>
          </tr>
        </table>
      </div>


    </div>
  )
}

const inputStyleNumber = (updateStyleNumber) => {
  let styleNumber = window.prompt('Input style number:')

  if (styleNumber == null) return

  if (validStyleNumber(styleNumber)) {
    runStyleCodes(styleNumber, updateStyleNumber)
  } else {
    alert('INVALID style number!')
  }
}

export {inputStyleNumberButton}
