import React, { Component } from 'react';
import $ from 'jquery';
import './NewSalesOrderPage.css';
import {docParserCustomerCompanies} from './components/edit-sales-order/options'
import { getStripePublicKey, sizesCheckoutOptions } from './getApiEndpoint.js'

import Dropzone from 'react-dropzone'
import { Popup, Button, Icon, Form, Transition, Segment, Dropdown, Loader, Dimmer, Checkbox, TextArea, Message, Grid, Input, Table, Label, Image } from 'semantic-ui-react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import CardSetupForm from './components/stripe/CardSetupForm'
import { connect } from 'react-redux'
import * as cadActions from './actions/cadActions'
import { bindActionCreators } from 'redux'

import Utils from "./modules/utils"
import {Auth0Context}    from './contexts/auth-context'

import ExtraFields from './components/edit-sales-order/ExtraFields.js'
import PaymentHistory from './components/edit-sales-order/PaymentHistory.js'
import ExtraOrderSection from './components/edit-sales-order/ExtraOrderSection.js'

import {filter, map} from 'lodash'
import CompanyAPI from './modules/api/company_api'
import CustomerAPI from './modules/api/customer_api'

import {labelStyle, styleNumberInfo, infoStyle, InfoCircle, textAreaStyle, customerOrderNumberInfo, holdNumberInfo} from "./components/edit-sales-order/Elements"

import Constants from './modules/constants'

import ReactQuill from 'react-quill'

import FlaggedMessage from './components/edit-sales-order/flagged-message.js'
import AutoEditMessage from './components/edit-sales-order/auto-edit-message.js'
import QbSyncMessage from './components/edit-sales-order/qb-sync-message.js'

import SpsXmlFileKey from './components/edit-sales-order/sps-xml-file-key.js'

import SuccessMessage from './components/edit-sales-order/success-message.js'

import StartShipDateLogs from './components/edit-sales-order/start-ship-date-logs.js'
import {displayExFactory} from './components/edit-sales-order/display-ex-factory.js'

import SwapCadPopup from './components/edit-sales-order/swap-cad-popup.js'
import {processSwapCad} from './components/edit-sales-order/process-swap-cad.js'

import DatesForNoStyles from './components/edit-sales-order/dates-for-no-styles.js'
import {
  dateInputStyle,
  processOnChangeBulkStartShipDate,
  processOnChangeStartShipDate,
  processOnChangeBulkCancelDate,
  processOnChangeCancelDate
} from './components/edit-sales-order/date-inputs.js'
import {getKey, getCommentKey} from './components/edit-sales-order/get-key.js'

import {orderTypeOptions} from './components/edit-sales-order/order-type-options.js'

import {formatDate} from './components/cads/format-date.js'
import {itemStatusOrderSummary, itemStatusOrderDetails} from './components/edit-sales-order/order-summary/item-status-order-summary.js'
import {getStatusMarkup} from './components/purchase-orders/sales-view/get-status-markup.js'

import {checkMissingDates} from './components/edit-sales-order/check-missing-dates.js'

import TicketedDueInput from './components/edit-sales-order/extra-fields/ticketed-due-input.js'

import {getAccounts} from './components/edit-sales-order/get-accounts'

import {brandApprovalContent} from './components/edit-sales-order/brand-approval/content'
import {removeStylesMessage} from './components/edit-sales-order/brand-approval/remove-styles-message'
import {setRemoveStyles} from './components/edit-sales-order/brand-approval/set-remove-styles'
import {setBrandApprovalData} from './components/edit-sales-order/brand-approval/set-data'

import {upfrontInput, radioButtonStyle, radioButtonLabelStyle} from './components/edit-sales-order/upfront-input'

const styleNumberInfoDisabled = 'Add or remove styles in cad details section below'
const docParserInfo = 'ONLY select Doc Parser if you know your PDF is compatible, OTHERWISE select Search for Cads' //'Doc Parser is a service we use to magically extract order data from customer PDFs'
const selectCustomerInfo = 'Choose which parser you want to process information from the PDF'
const orderTypeInfo = 'Order types are different levels of commitment to order.  Hold is lowest level, Worksheet is medium level, and PO is full commitment to purchase.'

const methodButtonStyle = {fontSize: '150%', marginLeft: '-20px', width: '220px', minHeight: '120px'}
const methodButtonStyleDisabled = Object.assign({}, methodButtonStyle, {cursor: 'not-allowed', opacity: 0.4})
const pdfFilenameLabelStyle = Object.assign({}, labelStyle, {verticalAlign: 'top', marginTop: '15px'})
const submitButtonStyle = {padding: '28px 60px', width: '100%', maxWidth: '500px', marginTop: '80px'}
const submitButtonStyleNoDocParser = Object.assign({}, submitButtonStyle, {position: 'relative', left: '11px', marginTop: '0px'})
const textAreaStyleDisabled = Object.assign({}, textAreaStyle, {cursor: 'not-allowed', opacity: 0.4, background: 'lightgrey'})

const stripePublicKey = getStripePublicKey()
const stripePromise = loadStripe(stripePublicKey)

function ErrorPopup(text) {
  return (
    <Popup position='right center' trigger={
      <Icon name='warning sign' style={{color: 'red', cursor: 'pointer', fontSize: '150%'}}/>} >
      <Popup.Header>Error</Popup.Header>
      <Popup.Content>
        {text}
      </Popup.Content>
    </Popup>
  )
}

function cadInsufficientATSPopup(oversold) {
  return ErrorPopup('Cad has INSUFFICIENT ATS or Future ATS (oversold ' + (-1 * oversold) + ' units)')
}

const cadNotFoundPopup = ErrorPopup('Cad NOT found')
const cadNoATSPopup = ErrorPopup('CAD has NO ATS or Future ATS')

const zeroResultsStyle = {
  border: '1px solid rgb(222,222,222)',
  borderTop: '1px solid white',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  padding: '15px',
  marginBottom: '10px',
  color: 'red',
  fontWeight: 'bold',
  textAlign: 'center',
  marginTop: '-15px'
}

const items = [
  'Pending PO orders imported into QB at 6pm each day',
]

class NewSalesOrderPage extends Component {
  static contextType = Auth0Context

  constructor(props) {
    super(props);
    this.state = {
      isViewPage: false,
      isEditPage: false,
      isMultiplePage: false,

      usingDocParser: null,
      customerCompany: null,
      styleNumbers: [],
      notFound: null,
      doNotSell: null,
      noAtsOrFutureAts: null,
      orderType: 'PO',
      cads: [],
      newAccountSaved: false,
      quickbooksAccount: null,
      pdfFilename: null,
      pdfFilenameSaved: [],
      addingFile: false,
      distFilename: null,
      distFilenameSaved: [],
      addingDistFile: false,

      bulkQuantity: null,
      bulkPrice: null,
      bulkStartShipDate: null,
      bulkCancelDate: null,
      cpoQuantity: {},
      cpoSizes: {},
      price: {},
      startShipDate: {},
      exFactoryDate: {},
      vendorPonumber: {},
      loadingDate: {},
      cancelDate: {},
      quantity: {},
      initialQuantity: {},

      accountClientSecret: null,
      accountPaymentMethods: null,
      showPaymentForm: true,
      uploadingFile: false,
      submittingForm: false,
      shippingCharge: null,

      accounts: [],
      customerId: null,
      docParserCustomerCompanies: null,
      flaggedForUpdate: false,
      needToAutoEdit: false,
      autoEdited: false,
      autoEditedDate: null,
      qbSynced: false,
      qbSyncedDate: null,
      logs: [],
      stripePayments: [],
      stripeRefunds: [],

      addStyleIcon: null,
      addStyleIconPosition: null,
      cad: null,
      cadErrors: {},
      orderCreateDate: '',
      docParserCompany: '',
      itemStatus: [],
      createdBy: '',
      orderStatus: '',
      oldRefNums: [],
      oldCreatedDates: [],

      ticketed: '',
      ticketsOrdered: null,
      ticketsOrderedDate: '',

      upfront: '',

      ticketsTrackingNumber: '',
      ticketsConfirmationNumber: '',
      richTextMessageHtml: '',
      aged: '',
      agedStyleNumber: '',
      hangers: '',
      reorder: '',
      topSamplesNeeded: '',
      topSamplesNeededDue: '',
      ppSamplesNeeded: '',
      ppSamplesNeededDue: '',
      terms: '',
      departmentNumber: '',
      shippingClass: '',

      pendingChanges: false,
      checkingUniqueCustomerPONumber: false,
      customerPONumberIcon: null,
      customerPONumberIconPosition: null,
      customerPONumberErrorMessage: null,

      brandApproval: null,
      removeStyles: [],

      numberOfExtraOrders: 0,
      //dontneed? extraStyleNumbers: {},
      extraCads: {},

      rep: null,

      isSuccess: false,
      successId: null,

      quickbooks_account_name: null,

      rowsKeep: [],
      rowsEdit: [],
      rowsCancel: []
    }

    this.getSalesOrderData = this.getSalesOrderData.bind(this)
    this.fromCadsPage = this.fromCadsPage.bind(this)
    this.getStyleNumbersFromIds = this.getStyleNumbersFromIds.bind(this)
    this.setInitialQuantityAndDate = this.setInitialQuantityAndDate.bind(this)
    this.getAccounts = this.getAccounts.bind(this)
    this.handleUsingDocParserButtonOnClick = this.handleUsingDocParserButtonOnClick.bind(this)
    this.textAreaOnChange = this.textAreaOnChange.bind(this)
    this.checkStyleNumbersForErrors = this.checkStyleNumbersForErrors.bind(this)
    this.handleCustomerCompanyButtonOnClick = this.handleCustomerCompanyButtonOnClick.bind(this)
    this.handleCustomerCompanyOnChange = this.handleCustomerCompanyOnChange.bind(this)
    this.handleQuickbooksAccountOnChange = this.handleQuickbooksAccountOnChange.bind(this)
    this.removeFile = this.removeFile.bind(this)
    this.removeDistFile = this.removeDistFile.bind(this)
    this.removeFromCart = this.removeFromCart.bind(this)

    this.onChangePrice = this.onChangePrice.bind(this)
    this.addAll = this.addAll.bind(this)
    this.addSize = this.addSize.bind(this)
    this.removeSize = this.removeSize.bind(this)
    this.onChangeCPOsize = this.onChangeCPOsize.bind(this)
    this.onChangeCPOquantity = this.onChangeCPOquantity.bind(this)
    this.onChangeStartShipDate = this.onChangeStartShipDate.bind(this)
    this.onChangeCancelDate = this.onChangeCancelDate.bind(this)
    this.onChangeBulkStartShipDate = this.onChangeBulkStartShipDate.bind(this)
    this.onChangeBulkCancelDate = this.onChangeBulkCancelDate.bind(this)
    this.onChangeBulkQuantity = this.onChangeBulkQuantity.bind(this)
    this.onChangeBulkPrice = this.onChangeBulkPrice.bind(this)
    this.scrollToCadDetails = this.scrollToCadDetails.bind(this)

    this.setEditPageQuantityAndDate = this.setEditPageQuantityAndDate.bind(this)
    this.submitForm = this.submitForm.bind(this)

    this.updatePaymentMethods = this.updatePaymentMethods.bind(this)
    this.updateClientSecret = this.updateClientSecret.bind(this)
    this.removePaymentMethod = this.removePaymentMethod.bind(this)

    this.onChangeShippingCharge = this.onChangeShippingCharge.bind(this)

    this.saveNewAccountName = this.saveNewAccountName.bind(this)
    this.removeAccount = this.removeAccount.bind(this)

    this.createNewSalesOrder = this.createNewSalesOrder.bind(this)
    this.orderTypeOnChange = this.orderTypeOnChange.bind(this)
    this.setCustomerId = this.setCustomerId.bind(this)
    this.setDocParserCustomerCompanies = this.setDocParserCustomerCompanies.bind(this)
    this.handleUsingDocParserOnChange = this.handleUsingDocParserOnChange.bind(this)

    this.downloadFile = this.downloadFile.bind(this)
    this.downloadDistFile = this.downloadDistFile.bind(this)
    this.downloadLogs = this.downloadLogs.bind(this)
    this.downloadOrderDetails = this.downloadOrderDetails.bind(this)
    this.removeFlag = this.removeFlag.bind(this)
    this.addStyle = this.addStyle.bind(this)
    this.styleOnChange = this.styleOnChange.bind(this)
    this.checkIfValidStyle = this.checkIfValidStyle.bind(this)

    this.ticketedOnChange = this.ticketedOnChange.bind(this)
    this.ticketsOrderedOnChange = this.ticketsOrderedOnChange.bind(this)

    this.upfrontOnChange = this.upfrontOnChange.bind(this)

    this.ticketsTrackingNumberOnChange = this.ticketsTrackingNumberOnChange.bind(this)
    this.ticketsConfirmationNumberOnChange = this.ticketsConfirmationNumberOnChange.bind(this)
    this.richTextMessageHtmlOnchange = this.richTextMessageHtmlOnchange.bind(this)
    this.agedOnChange = this.agedOnChange.bind(this)
    this.agedStyleNumberOnChange = this.agedStyleNumberOnChange.bind(this)
    this.termsOnChange = this.termsOnChange.bind(this)
    this.departmentNumberOnChange = this.departmentNumberOnChange.bind(this)
    this.shippingClassOnChange = this.shippingClassOnChange.bind(this)

    this.hangersOnChange = this.hangersOnChange.bind(this)
    this.reorderOnChange = this.reorderOnChange.bind(this)
    this.topSamplesNeededOnChange = this.topSamplesNeededOnChange.bind(this)
    this.topSamplesNeededDueOnChange = this.topSamplesNeededDueOnChange.bind(this)
    this.ppSamplesNeededOnChange = this.ppSamplesNeededOnChange.bind(this)
    this.ppSamplesNeededDueOnChange = this.ppSamplesNeededDueOnChange.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.addFile = this.addFile.bind(this)
    this.addDistFile = this.addDistFile.bind(this)
    this.setPendingChanges = this.setPendingChanges.bind(this)
    this.customerPONumberOnChange = this.customerPONumberOnChange.bind(this)
    this.checkIfValidCustomerPONumber = this.checkIfValidCustomerPONumber.bind(this)

    this.fetchCompanies = this.fetchCompanies.bind(this)
    this.setBrandApprovalData = this.setBrandApprovalData.bind(this)
    this.setRemoveStyles = this.setRemoveStyles.bind(this)
    this.toggleMultipleOrders = this.toggleMultipleOrders.bind(this)
    this.addAnotherOrder = this.addAnotherOrder.bind(this)
    this.removeLastOrder = this.removeLastOrder.bind(this)
    this.addExtraCads = this.addExtraCads.bind(this)

    this.repOnChange = this.repOnChange.bind(this)
    this.fetchOrganizations = this.fetchOrganizations.bind(this)
    this.generateUniquePonumber = this.generateUniquePonumber.bind(this)

    this.setParentState = this.setParentState.bind(this)
    this.swapCad = this.swapCad.bind(this)
    this.updateState = this.updateState.bind(this)
  }

  componentDidMount() {
    if (window.location.href.indexOf('?success=') > -1) {
      let successId = window.location.href.split('?success=')[1]
      this.setState({isSuccess: true, successId})
    }


    if (window.location.href.indexOf('?multiple=true') > -1) {
      this.setState({isMultiplePage: true, usingDocParser: 'No'})
    }

    this.getAccounts()

    let fromCadsPage = window.location.href.indexOf('?from=cadspage') > -1
    if (fromCadsPage) { this.fromCadsPage() }

    if (window.location.href.indexOf('/view') > -1) {
      this.setState({isViewPage: true})
    }

    if (this.props.id != null) {
      this.setState({isEditPage: true, usingDocParser: 'No'})
      this.getSalesOrderData()
    }
    window.scrollTo(0, 0)

    this.setCustomerId()
    this.fetchCompanies()
    this.fetchOrganizations()
  }

  fetchOrganizations = () =>
  {
    let callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        let organizations = data.customers
        this.setState({ organizations })
      }
    }

    CustomerAPI.getAll(callback)
  }

  componentDidUpdate() {}

  setCustomerId() {
    const {customer} = this.context
    if (customer == null) {
      setTimeout(this.setCustomerId, 500)
    } else {
      let approvalBrands = map(filter(customer.brands, (b => b.needsApproval == true )), "name")

      this.setState({customerId: customer.id, approvalBrands})

      setTimeout(this.setDocParserCustomerCompanies,0)
    }
  }

  fetchCompanies() {
    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        let companies = data.companies
        this.setState({ companies})
      }
    }

    CompanyAPI.getAll(callback)
  }

  setDocParserCustomerCompanies() {
    this.setState({docParserCustomerCompanies: docParserCustomerCompanies[this.state.customerId]})
  }

  getSalesOrderData() {
    $.get(process.env.REACT_APP_API_URL + '/api/sales_order_data/' + this.props.id,
      (result) => {
        let order = result.sales_order
        window.editSalesOrderPage = {}
        window.editSalesOrderPage.logs = result.all_logs
        let logs = result.logs
        let stripePayments = result.stripe_payments
        let stripeRefunds = result.stripe_refunds

        if (order.cad_style_numbers != null) {
          $('#style-numbers-textarea').val(order.cad_style_numbers.join("\n"))
          $('#style-numbers-div').html(order.cad_style_numbers.join("<br/>"))
        }

        let styleNumbers = order.cad_style_numbers
        let usingDocParser = order.is_doc_parser ? 'Yes' : 'No'
        let customerCompany = order.doc_parser_company
        let pdfFilename = order.filename == '' ? null : order.filename
        let distFilename = order.distribution_filename == '' ? null : order.distribution_filename

        let pdfFilenameSaved = []
        let distFilenameSaved = []

        if (pdfFilename != null) { pdfFilenameSaved = pdfFilename.split('@@@') }
        if (distFilename != null) { distFilenameSaved = distFilename.split('@@@') }

        let shippingCharge = order.shipping_charge / 100
        if (shippingCharge == 0) {shippingCharge = null}

        let ponumber = order.ponumber, docParserCompany = order.doc_parser_company
        let orderCreateDate = order.created_at_date + '  ' + order.created_at_time
        let createdBy = result.created_by, orderStatus = result.order_status

        let oldRefNums = result.old_ref_nums
        let oldCreatedDates = result.old_created_dates

        let aged = ''
        switch(order.aged) {
          case true:
            aged = 'Yes'
            break
          case false:
            aged = 'No'
            break
        }

        let top_samples_needed_due = order.top_samples_needed_due
        if (top_samples_needed_due == null || top_samples_needed_due == '') {
          top_samples_needed_due = ''
        } else {
          let parts = top_samples_needed_due.split('-')
          top_samples_needed_due = parts[1] + '/' + parts[2] + '/' + parts[0]
        }
        let pp_samples_needed_due = order.pp_samples_needed_due
        if (pp_samples_needed_due == null || pp_samples_needed_due == '') {
          pp_samples_needed_due = ''
        } else {
          let parts = pp_samples_needed_due.split('-')
          pp_samples_needed_due = parts[1] + '/' + parts[2] + '/' + parts[0]
        }

        let importedFromQb = order.old_purchase_order_id != null



        this.setState({
          styleNumbers, notFound: [], doNotSell: [], noAtsOrFutureAts: [],
          orderType: order.order_type, usingDocParser, customerCompany,
          quickbooksAccount: order.account_id,
          pdfFilename, pdfFilenameSaved, distFilename, distFilenameSaved,
          shippingCharge,
          flaggedForUpdate: order.flagged_for_update, logs, stripePayments,
          stripeRefunds, ponumber, docParserCompany,
          itemStatus: order.item_status,
          orderCreateDate, createdBy, orderStatus,
          ticketed: order.ticketed,

          upfront: order.upfront,

          ticketsOrdered: order.tickets_ordered,
          ticketsOrderedDate: (order.tickets_ordered_date == null ? '' : order.tickets_ordered_date),
          ticketsTrackingNumber: order.tickets_tracking_number,
          ticketsConfirmationNumber: order.tickets_confirmation_number,
          richTextMessageHtml: order.rich_text_message_html,
          aged: aged,
          agedStyleNumber: order.aged_style_number,
          hangers: order.hangers,
          reorder: order.po_or_hold,
          topSamplesNeeded: order.top_samples_needed,
          topSamplesNeededDue: top_samples_needed_due,
          ppSamplesNeeded: order.pp_samples_needed,
          ppSamplesNeededDue: pp_samples_needed_due,
          rep: order.user_id,
          importedFromQb,
          oldRefNums,
          oldCreatedDates,
          quickbooks_account_name: order.quickbooks_account_name,
          spsXmlFileKey: order.sps_xml_file_key,
          needToAutoEdit: order.need_to_auto_edit_holds,
          autoEdited: order.auto_edited,
          autoEditedDate: order.auto_edited_date,
          qbSynced: order.qb_synced,
          qbSyncedDate: order.qb_synced_date,
          terms: order.terms,
          departmentNumber: order.department_number,
          shippingClass: order.shipping_class
        })

        this.getCadData(styleNumbers, order)

        setTimeout(() => {
          $('#customer-order-number').val(order.customer_order_number)
          $('#tracking-number').val(order.shipping_tracking_number)
          this.updateClientSecret(order.account_id)
          this.setBrandApprovalData(order.account_id)

          $('#no-styles-start-ship-date').val(order.start_shipping_date)
          $('#no-styles-cancel-date').val(order.cancel_date)

          $('#ticketed-due').val(order.ticketed_due)
        }, 0)

        //fix for says pending changes when load edit page
        setTimeout(() => {
          this.setState({pendingChanges: false})
        }, 300)
      }
    )
  }

  getCadData(styleNumbers, salesOrderData) {
    Utils.post('check_style_numbers_for_errors', { styleNumbers }, (err, result) =>
    {
      if (err) {
        console.log('There was an error when trying to get cad data.')
      } else {
        let cads = []
        let cadData = JSON.parse(result.data.cads).data

        for (var i=0; i<cadData.length; i++) {
          cads.push(cadData[i].attributes)
        }

        this.setState({cads})
        setTimeout(() => this.setEditPageQuantityAndDate(salesOrderData),0)
      }
    })
  }

  fromCadsPage() {
    this.setState({usingDocParser: 'No'})
    let cadIds = Object.keys(this.props.cadReducer.shoppingBag)
    this.getStyleNumbersFromIds(cadIds)
  }

  getStyleNumbersFromIds(cadIds) {
    $.ajax({
      type: "POST",
      url: process.env.REACT_APP_API_URL + '/api/get_style_numbers_from_ids',
      data: { cadIds },
      success: (result) => {

        let cads = []
        let cadData = JSON.parse(result.cads).data

        for (var i=0; i<cadData.length; i++) {
          cads.push(cadData[i].attributes)
        }

        let styleNumbers = cads.map(cad => cad.style_number)

        $('#style-numbers-textarea').val(styleNumbers.join("\n"))
        $('#style-numbers-div').html(styleNumbers.join("<br/>"))

        this.setState({styleNumbers, cads, notFound: [], doNotSell: [], noAtsOrFutureAts: []})

        setTimeout(() => this.setInitialQuantityAndDate(),0)
      },
      error: (error) => {
          // handle error
          console.log('There was an error when trying to get style numbers from ids.')
      },
    })
  }

  setInitialQuantityAndDate() {
    let {cads, cpoSizes, cpoQuantity, price, quantity, startShipDate, cancelDate, isMultiplePage} = this.state

    let newCpoSizes = {}
    let newCpoQuantity = {}
    let newPrice = {}
    let newQuantity = {}
    let newStartShipDate = {}
    let newCancelDate = {}

    cads.forEach(cad => {
      let key = getKey(cad)

      if (cpoSizes[key] == null) {
        newCpoSizes[key] = ['PREPACK']
        newCpoQuantity[key] = ['']
        newQuantity[key] = null
        newPrice[key] = null

        newStartShipDate[key] = null
        newCancelDate[key] = null
      } else {
        newCpoSizes[key] = cpoSizes[key]
        newCpoQuantity[key] = cpoQuantity[key]
        newQuantity[key] = quantity[key]
        newPrice[key] = price[key]
        newStartShipDate[key] = startShipDate[key]
        newCancelDate[key] = cancelDate[key]
      }
    })

    this.setState({cpoSizes: newCpoSizes, cpoQuantity: newCpoQuantity, startShipDate: newStartShipDate, cancelDate: newCancelDate, price: newPrice})
  }

  getAccounts() {
    var {customer} = this.context

    if (customer == null) {
      setTimeout(() => {
        this.getAccounts()
      }, 500)
    } else {
      let {contactOptions, accountIds} = getAccounts(customer)
      this.setState({accounts: contactOptions, accountIds})
    }
  }

  handleUsingDocParserOnChange(e, { value }) {
    if (value == 'Yes' && this.state.docParserCustomerCompanies != null && this.state.docParserCustomerCompanies.length == 0) {
      alert('Your organization has no doc parsers!')
      return
    }

    this.setState({usingDocParser: value, customerCompany: null, quickbooksAccount: null, pdfFilename: null})
  }

  handleUsingDocParserButtonOnClick(value) {
    if (this.state.isEditPage) {alert('Cannot edit method!'); return}
    if (this.state.isMultiplePage && value == 'Yes') {alert('Cannot create multiple doc parser orders!'); return}
    if (this.state.isMultiplePage && value == 'Worksheet') {alert('Cannot create multiple worksheet orders!'); return}
    if (value == 'Yes' && this.state.docParserCustomerCompanies != null && this.state.docParserCustomerCompanies.length == 0) {
      alert('Your organization has no doc parsers!')
      return
    }
    this.setState({usingDocParser: value, customerCompany: null, quickbooksAccount: null, pdfFilename: null})
  }

  textAreaOnChange(e, { value }) {
    clearTimeout(window.textAreaTimeout)
    window.textAreaTimeout = setTimeout(() => {
      let styleNumbers = value.split("\n").map(x => x.flatUpcase()).filter(x => x != '')
      styleNumbers = [...new Set(styleNumbers)]
      this.setState({styleNumbers})
      if (styleNumbers.length) {
        this.checkStyleNumbersForErrors(styleNumbers)
      } else {
        this.setState({notFound: [], noAtsOrFutureAts: []})
      }
    }, 1000)
  }

  checkStyleNumbersForErrors(styleNumbers) {
    Utils.post('check_style_numbers_for_errors', { styleNumbers }, (err, result) =>
    {
      if (err) {
        console.log('There was an error when trying to check style numbers for errors.')
      } else {
        let newState = result.data

        let cads = []
        let cadData = JSON.parse(result.data.cads).data

        for (var i=0; i<cadData.length; i++) {
          cads.push(cadData[i].attributes)
        }

        if (this.state.isMultiplePage) {
          delete newState.cads
          this.setState(newState)

          setTimeout(() => {
            cads.forEach(cad => {
              cad.orderIndex = -1
            })
            this.addExtraCads(cads, -1)
          },0)
        } else {
          newState.cads = cads

          this.setState(newState)

          setTimeout(() => {
            this.setInitialQuantityAndDate()

            if (!this.state.isEditPage && this.state.quickbooksAccount != null) {
              this.setRemoveStyles()
            }
          },0)
        }

        let searchForSimilarEvent = new Event('searchForSimilar')
        window.dispatchEvent(searchForSimilarEvent)
      }
    })
  }

  handleCustomerCompanyButtonOnClick(value) {
    if (this.state.isEditPage) {alert('Cannot change Account after create Sales Order!'); return}
    this.setState({customerCompany: value, quickbooksAccount: null, pdfFilename: null})
  }

  handleCustomerCompanyOnChange(e, { value }) {
    this.setState({customerCompany: value, quickbooksAccount: null, pdfFilename: null})
  }

  handleQuickbooksAccountOnChange(e, { value }) {
    this.setState({quickbooksAccount: value})
    this.updateClientSecret(value)

    // let oldValue = this.state.quickbooksAccount
    // if (this.state.pdfFilename == null && value != 'NEW CONTACT' && oldValue == null) {
    //   this.scrollDown()
    // }
    // if (value != 'NEW CONTACT') {
    //   this.updateClientSecret(value)
    // }

    setTimeout(() => {
      if (this.state.usingDocParser === 'No') {
        let customerPONumber = $('#customer-order-number').val().trimFlattenUpcase()
        if (customerPONumber.length > 2 && this.state.customerPONumberIcon == 'close') {
          this.setState({checkingUniqueCustomerPONumber: true})
          this.checkIfValidCustomerPONumber(customerPONumber)
        }
      }

      if (this.state.isMultiplePage) {
        let checkCustomerPonumberEvent = new Event('checkCustomerPonumberEvent')
        window.dispatchEvent(checkCustomerPonumberEvent)
      }

      this.setBrandApprovalData(value)

      let searchForSimilarEvent = new Event('searchForSimilar')
      window.dispatchEvent(searchForSimilarEvent)
    }, 0)
  }

  scrollDown() {
    try {
      setTimeout(() => $('html, body').animate({scrollTop: $("#my-dropzone-title").offset().top - 100}, 1000), 100)
    } catch (error) {
      console.error(error)
    }
  }

  handleAcceptedFiles(acceptedFiles, isDistFile = false) {
    for (let k=0; k<acceptedFiles.length; k++) {
      let file = acceptedFiles[k]

      let formData = new FormData()
      let filename = file.name

      let fileTypes = !isDistFile && this.state.usingDocParser === 'Yes' ? ['.pdf'] : ['.xlsx', '.xls', '.png', '.jpeg', '.jpg', '.doc', '.docx', '.pdf', '.txt']
      let message = !isDistFile && this.state.usingDocParser === 'Yes' ? 'Only allow PDF file types.' : 'Only allow image, microsoft word, excel, PDF file types'

      let fileTypeAcceptable = fileTypes.some((fileType) => filename.toLowerCase().indexOf(fileType) > -1)

      if (!fileTypeAcceptable) {
        alert(message)
      } else {
        formData.append("file", file, file.name)

        this.setState({uploadingFile: true})

        $.ajax({
          type: "POST",
          url: process.env.REACT_APP_API_URL + '/api/file-upload-worksheet',
          success: function (data) {
            if (data.success) {
              let newState = {pendingChanges: true}
              if (isDistFile) {
                //deal with multiple files
                if (this.state.distFilename == null) {
                  newState.distFilename = data.worksheet_filename
                } else {
                  newState.distFilename = (this.state.distFilename + '@@@' + data.worksheet_filename)
                }

                newState.addingDistFile = false
              } else {
                //deal with multiple files
                if (this.state.pdfFilename == null) {
                  newState.pdfFilename = data.worksheet_filename
                } else {
                  newState.pdfFilename = (this.state.pdfFilename + '@@@' + data.worksheet_filename)
                }

                newState.addingFile = false
              }
              this.setState(newState)
            } else {
              if (!!data.corrupt_file) {
                //assume if empty file that is "corrupt"
                alert('The file "' + filename + '" is corrupt.')
              } else {
                alert('There was an error when trying to upload the file "' + filename + '"')
              }
            }
            this.setState({uploadingFile: false})
          }.bind(this),
          error: function (error) {
            // handle error
            alert('There was an error when trying to upload the file.')
          },
            async: true,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            timeout: 60000
        })
      }

    }
  }

  removeFile(filename) {
    if (window.confirm('Are you sure you want to remove the file?')) {
      let newPdfFilename = this.state.pdfFilename.split('@@@')
      newPdfFilename = newPdfFilename.filter((x) => {return x != filename}).join('@@@')
      if (newPdfFilename == '') {newPdfFilename = null}
      this.setState({pdfFilename: newPdfFilename})
      if (newPdfFilename == null) {this.scrollDown()}
    }
  }

  removeDistFile(filename) {
    if (window.confirm('Are you sure you want to remove the distribution file?')) {
      let newDistFilename = this.state.distFilename.split('@@@')
      newDistFilename = newDistFilename.filter((x) => {return x != filename}).join('@@@')
      if (newDistFilename == '') {newDistFilename = null}
      this.setState({distFilename: newDistFilename})
    }
  }

  downloadFile(filename) {
    let foldername = filename.split('/')[0]
    let downloadUrl = process.env.REACT_APP_API_URL + '/api/download_order_file/' + this.props.id + '?file=' + foldername
    window.open(downloadUrl, '_blank', "height=700,width=700")
  }

  downloadDistFile(filename) {
    let foldername = filename.split('/')[0]
    let downloadUrl = process.env.REACT_APP_API_URL + '/api/download_order_file/' + this.props.id + '?is_dist_file=true&file=' + foldername
    window.open(downloadUrl, '_blank', "height=700,width=700")
  }

  removeFromCart(cadId, index) {
    if (window.confirm('Are you sure you want to remove the cad?')) {
      let {styleNumbers, cads, cpoSizes, cpoQuantity, price, quantity, cadErrors} = this.state


      let comments = []
      cads.forEach(cad => {
        let key = getKey(cad)
        comments.push($('#cad-comment-' + getCommentKey(key)).val())
      })

      styleNumbers.splice(index, 1)
      cads.splice(index, 1)
      comments.splice(index, 1)

      $('#style-numbers-textarea').val(styleNumbers.join("\n"))
      $('#style-numbers-div').html(styleNumbers.join("<br/>"))

      delete cpoSizes[cadId]
      delete cpoQuantity[cadId]
      delete price[cadId]
      delete quantity[cadId]
      delete cadErrors[cadId]

      this.setState({styleNumbers, cads, cpoSizes, cpoQuantity, price, quantity, cadErrors, pendingChanges: true})
      setTimeout(() => {
        let index = 0
        cads.forEach(cad => {
          let key = getKey(cad)
          $('#cad-comment-' + getCommentKey(key)).val(comments[index])
          index += 1
        })
      }, 100)
    }
  }

  onChangePrice(cadId, event, {value}) {
    var price = this.state.price
    value = Math.max(value, 0)
    price[cadId] = value
    this.setState({price, pendingChanges: true})
  }

  addAll(index, newQuantity) {
    let {quantity, cpoQuantity} = this.state
    quantity[index] = newQuantity
    cpoQuantity[index] = [newQuantity]
    this.setState({quantity, cpoQuantity, pendingChanges: true})
  }

  addSize(cadId) {
    let {cpoSizes, cpoQuantity} = this.state
    cpoSizes[cadId].push('PREPACK')
    cpoQuantity[cadId].push('')
    this.setState({cpoSizes, cpoQuantity, pendingChanges: true})
  }

  removeSize(cadId) {
    let {cpoSizes, cpoQuantity} = this.state
    cpoSizes[cadId].pop()
    cpoQuantity[cadId].pop()
    this.setState({cpoSizes, cpoQuantity, pendingChanges: true})
  }

  onChangeCPOsize(cadId, index2, event, {value}) {
    let {cpoSizes} = this.state
    cpoSizes[cadId][index2] = value
    this.setState({cpoSizes, pendingChanges: true})
  }

  onChangeCPOquantity(cadId, index2, event, {value}) {
    let {quantity, cpoQuantity, cads, isEditPage} = this.state
    let new_value = Math.floor(value)

    //console.log(new_value)


    //DISABLE ROUNDING UP from 90%
    // if (cpoQuantity[cadId].length == 1 && !isEditPage && this.state.orderType != 'Hold') {
    //   let cad = cads.filter(cad => cad.id == cadId)[0]
    //
    //   if (cad != null) {
    //     let cad_available = this.get_cad_available(cad)
    //     let cad_future_ats = cad.future_ats
    //
    //     let percent_90_cad_available = 0.9 * cad_available
    //     let percent_90_cad_future_ats = 0.9 * cad_future_ats
    //
    //     if (new_value >= percent_90_cad_available && new_value < cad_available) {
    //       new_value = cad_available
    //     }
    //
    //     if (new_value >= percent_90_cad_future_ats && cad_future_ats != 0 && cad_future_ats != null && new_value < cad_future_ats) {
    //       new_value = cad_future_ats
    //     }
    //   }
    // }


    // if (new_value == 0) {
    //   new_value = ''
    // }
    cpoQuantity[cadId][index2] = new_value

    let sum = 0
    for (let i=0; i<cpoQuantity[cadId].length; i++) {
      let el = cpoQuantity[cadId][i]
      if (el != '') {
        sum += el
      }
    }
    quantity[cadId] = sum

    this.setState({cpoQuantity, quantity, pendingChanges: true})
  }

  onChangeStartShipDate(cadId, event) {
    processOnChangeStartShipDate(this.state, this.updateState, cadId, event)
  }

  onChangeCancelDate(cadId, event) {
    processOnChangeCancelDate(this.state, this.updateState, cadId, event)
  }

  onChangeBulkStartShipDate(event) {
    processOnChangeBulkStartShipDate(this.state, this.updateState, event)
  }

  onChangeBulkCancelDate(event) {
    processOnChangeBulkCancelDate(this.state, this.updateState, event)
  }

  onChangeBulkQuantity(event, {value}) {
    value = Number(value)

    let {cads, quantity, cpoQuantity, isMultiplePage} = this.state

    cads.forEach((cad) => {
      let key = getKey(cad)

      let new_value = Math.floor(value)

      let cad_available = this.get_cad_available(cad)
      let cad_future_ats = cad.future_ats

      if (cpoQuantity[key].length == 1) {
        cpoQuantity[key] = [new_value]
        quantity[key] = new_value
      }
    })

    let bulkQuantity = Math.floor(value)

    this.setState({bulkQuantity, cpoQuantity, quantity, pendingChanges: true})
  }

  onChangeBulkPrice(event, {value}) {
    let {isMultiplePage} = this.state
    let new_value = Math.round(value * 100) / 100
    let price = {}

    this.state.cads.forEach((cad) => {
      let key = getKey(cad)
      price[key] = new_value
    })

    this.setState({bulkPrice: new_value, price: price, pendingChanges: true});
  }

  scrollToCadDetails() {
    try {
      let diff = (this.state.cads.length > 1 ? 140 : 100)
      setTimeout(() => $('html, body').animate({scrollTop: $("#cad-details-title").offset().top - diff}, 1000), 100)
    } catch (error) {
      console.error(error)
    }
  }

  get_cad_available(cad) {
    let cad_available
    if (cad != null) {
      cad_available = cad.available
    }
    if (cad_available != null && cad_available != '' && cad_available < 0) {
      cad_available = 0
    }

    if (cad_available === null || cad_available === '') {
      cad_available = 0
    }
    return cad_available
  }

  setEditPageQuantityAndDate(salesOrderData) {
    let {cads} = this.state

    let cpoSizes = {}
    let cpoQuantity = {}
    let price = {}
    let quantity = {}
    let startShipDate = {}
    let exFactoryDate = {}
    let vendorPonumber = {}
    let loadingDate = {}
    let cancelDate = {}
    let cadErrors = {}
    let index = 0

    cads.forEach(cad => {
      let key = getKey(cad)

      quantity[key] = salesOrderData.quantity[index]
      cpoQuantity[key] = JSON.parse(salesOrderData.quantity_multiple[index])
      cpoSizes[key] = JSON.parse(salesOrderData.sizes_multiple[index])
      price[key] = Number(salesOrderData.price[index])

      startShipDate[key] = salesOrderData.start_ship[index]
      cancelDate[key] = salesOrderData.cancel[index]

      exFactoryDate[key] = salesOrderData.ex_factory_date[index]
      vendorPonumber[key] = salesOrderData.vendor_ponumber[index]
      loadingDate[key] = salesOrderData.loading_date[index]

      if (salesOrderData.comments != null) {
        $('#cad-comment-' + getCommentKey(key)).val(salesOrderData.comments[index])
      }

      cadErrors[key] = salesOrderData.cad_errors[index]

      index += 1
    })

    let initialQuantity = JSON.parse(JSON.stringify(quantity))

    //add missing sizes to options list
    let sizes = Object.values(cpoSizes).flat()
    let distinctSizes = [...new Set(sizes)]
    distinctSizes.forEach((size) => {
      let index = sizesCheckoutOptions.findIndex(x => x.text == size)
      if (index == -1) {
        sizesCheckoutOptions.push({ key: size, text: size, value: size })
      }
    })

    this.setState({initialQuantity, quantity, cpoQuantity, cpoSizes, price, startShipDate, exFactoryDate, vendorPonumber, loadingDate, cancelDate, cadErrors})
  }

  submitForm() {
    this.createNewSalesOrder()
  }

  updateClientSecret(contact_id) {
    $.get(process.env.REACT_APP_API_URL + '/api/card-wallet?contact_id=' + contact_id, (result) => {
      if (result.intent != null) {
        this.setState({accountClientSecret: result.intent.client_secret, accountPaymentMethods: result.payment_methods, showPaymentForm: true})
      }
    })
  }

  updatePaymentMethods(selectPaymentMethod) {
    $.get(process.env.REACT_APP_API_URL + '/api/list-payment-methods?contact_id=' + this.state.quickbooksAccount, (result) => {
      if (result.payment_methods != null) {
        this.setState({accountPaymentMethods: result.payment_methods, showPaymentForm: false})
        if (selectPaymentMethod != null) {
          setTimeout(() => {
            $("#"+selectPaymentMethod).prop("checked", true)
          }, 0)
        }
      }
    })
  }

  removePaymentMethod(id) {
    $.get(process.env.REACT_APP_API_URL + '/api/remove-payment-method/' + id, (result) => {
      this.updateClientSecret(this.state.quickbooksAccount)
    })
  }

  onChangeShippingCharge(event, {value}) {
    let new_value = Math.round(value * 100) / 100
    if (new_value == 0 || new_value == '') {
      new_value = null
    }
    this.setState({shippingCharge: new_value, pendingChanges: true})
  }

  saveNewAccountName() {
    $('#save-contact-button').addClass('loading')

    let account_name = $('#new-account-name').val().trim().toUpperCase().replace(/\p{Space}/g,' ').replace(/  +/g, ' ').replace(/\?/g, '')
    let contact_name = $('#new-customer-name').val().trim().toUpperCase().replace(/\p{Space}/g,' ').replace(/  +/g, ' ').replace(/\?/g, '')

    let url = 'add-stripe-account?contact_name=' + contact_name.replace(/&/g,'%26') + '&account_name=' + account_name.replace(/&/g,'%26')

    Utils.get(url, (err, res) =>
    {
      if (res != null) {
        let contactId = res.data.contactId
        let existing = res.data.existing
        if (existing) {
          this.setState({ quickbooksAccount: contactId })
        } else {
          this.setState({ quickbooksAccount: contactId, newAccountSaved: true, newAccountName: account_name, newContactName: contact_name })
        }

        this.updateClientSecret(contactId)
      }
    })
  }

  removeAccount() {
    this.setState({quickbooksAccount: null, newAccountSaved: false, pendingChanges: true})
  }

  createNewSalesOrder() {
    let {isMultiplePage, pdfFilename, isEditPage, styleNumbers} = this.state
    let url = (isEditPage ? ('edit_sales_order/' + this.props.id) : 'create_sales_order_with_doc_parser')

    let data = Object.assign({}, this.state)
    let profile = JSON.parse(sessionStorage.getItem('user'))
    data.auth0_id = profile.auth0Uuid
    data.accounts = undefined
    data.doc_parser_customer_companies = undefined
    data.logs = undefined
    data.accountIds = undefined
    data.approvalBrands = undefined
    data.brandApproval = undefined
    data.companies = undefined


    let customerOrderNumber = ''
    if (this.state.usingDocParser === 'No' || isEditPage) {
      customerOrderNumber = $('#customer-order-number').val().trimFlattenUpcase()
    }
    if (customerOrderNumber != '') {
      data.customer_order_number = customerOrderNumber
    }

    if ((isEditPage && styleNumbers != null && styleNumbers.length == 0) || (styleNumbers.length == 1 && styleNumbers[0] == '-')) {
      data.no_styles_start_ship_date = $('#no-styles-start-ship-date').val()
      data.no_styles_cancel_date = $('#no-styles-cancel-date').val()
    }

    if (isMultiplePage) {
      let extraOrderNumbers = []

      for (let i=0; i<this.state.numberOfExtraOrders; i++) {
        let customerOrderNumber = $('#customer-order-number-'+i).val().trimFlattenUpcase()
        extraOrderNumbers.push(customerOrderNumber)
      }

      data.extraOrderNumbers = extraOrderNumbers
    }

    if (data.filename == '') {
      data.filename = undefined
    }

    if (this.state.usingDocParser === 'No' || this.state.isEditPage) {
      let cadIds = data.cads.map(x => x.id).map(x => x == null ? 'null' : x )
      data.cad_ids = cadIds
    }

    data.cads = undefined
    data.extraCads = undefined

    if (data.shippingCharge != null) {
      data.shippingCharge = data.shippingCharge*100
    }

    let trackingNumber = $('#tracking-number').val()
    if (trackingNumber != '') {
      data.trackingNumber = trackingNumber
    }


    data.comments = []
    this.state.cads.forEach(cad => {
      let key = getKey(cad)
      data.comments.push($('#cad-comment-' + getCommentKey(key)).val())
    })



    let {cads, quantity, price, shippingCharge} = this.state
    let payment_method_id = $("input[name='payment_method']:checked").val()

    let totalCost = 0
    cads.forEach((cad) => {
      let key = getKey(cad)

      if (Constants.NON_INVENTORY_ITEMS.indexOf(cad.style_number) > -1 && price[key] != null) {
        totalCost += price[key]
      } else {
        if (quantity[key] != null && price[key] != null) {
          totalCost += quantity[key] * price[key]
        }
      }
    })

    if (isNaN(totalCost)) {
      totalCost = 0
    }

    totalCost = totalCost*100
    if (shippingCharge != null) {
      totalCost += shippingCharge*100
    }

    totalCost = totalCost.toFixed(0)

    data.payment_method_id = payment_method_id
    data.total_cost = totalCost

    data.rowsKeep = undefined

    data.ticketed_due = $('#ticketed-due').val()


    if (checkMissingDates(data)) {
      alert('Cannot save because some cads are missing dates!')
    } else {
      this.setState({submittingForm: true})
      Utils.post(url, data, (err, result) =>
      {
        if (err) {
          console.log('There was an error when trying to save new sales order, WILL NOT RETRY')
          // setTimeout(this.createNewSalesOrder, 20000)
        } else {
          if (result.data.success) {
            if (this.state.isEditPage) {
              window.location.reload()
            } else {
              setTimeout(() => {

                this.props.history.push('/')
                this.props.history.push('/sales-orders/new?success=' + result.data.successId)
                $('body').css('display', 'none')
                window.location.reload()

              }, 0)//dont need to wait if making stripe payment because will create delay
            }
          }
        }
      })
    }
  }

  orderTypeOnChange(event, {value}) {
    this.setState({orderType: value, pendingChanges: true})
    if (!this.state.isEditPage) {
      $('#customer-order-number').val('')
    }
  }

  repOnChange(event, {value}) {
    this.setState({rep: value, pendingChanges: true})
  }

  downloadLogs() {
    if (this.state.pendingChanges) {
      alert('Please save changes before downloading logs!')
      return
    }

    $('#download-history-of-changes-button').addClass('loading')
    Utils.get('sales_order_logs/' + this.props.id, (err, res) =>
    {
      $('#download-history-of-changes-button').removeClass('loading')
      if (err) {
        alert('There was an error when trying to download logs.')
      } else {
        if (res.data.success) {
          window.location = res.data.publicUrl
        }
      }
    })
  }

  downloadOrderDetails() {
    if (this.state.pendingChanges) {
      alert('Please save changes to order before downloading order details!')
      return
    }

    $('#download-order-details-button').addClass('loading')

    Utils.get('sales_order_details/' + this.props.id, (err, res) =>
    {
      $('#download-order-details-button').removeClass('loading')
      if (err) {
        alert('There was an error when trying to download details.')
      } else {
        if (res.data.success) {
          window.location = res.data.publicUrl
        }
      }
    })
  }

  removeFlag() {
    //TODO require saving changes?
    if (window.confirm('Are you sure you want to remove the flag?')) {
      $('#remove-flag-button').addClass('loading')
      Utils.get('remove_flag/' + this.props.id, (err, res) =>
      {
        if (err) {
          alert('There was an error when trying to remove the flag.')
        } else {
          if (res.data.success) {
            window.location.reload()
          }
        }
      })
    }
  }

  addStyle() {
    let {cad, cads, styleNumbers, quantity, cpoQuantity, cpoSizes, price, startShipDate, cancelDate} = this.state

    if (cpoSizes[cad.id] == null) {
      cads.push(cad)
      styleNumbers.push(cad.style_number)


      cpoSizes[cad.id] = ['PREPACK']
      cpoQuantity[cad.id] = ['']
      quantity[cad.id] = null
      price[cad.id] = null

      startShipDate[cad.id] = null
      cancelDate[cad.id] = null

      this.setState({
        cad: null, cads, styleNumbers, quantity, cpoQuantity, cpoSizes,
        price, startShipDate, cancelDate, addStyleIcon: null,
        addStyleIconPosition: null, pendingChanges: true
      })
      $('#add-style-input').val('')
      $('#style-numbers-div').html(styleNumbers.join("<br/>"))
    } else {
      alert('This style is already added to the order!')
    }
  }

  styleOnChange = (e, {value}) => {
    $('#add-style-button').addClass('loading')
    clearTimeout(window.styleOnChangeTimeout)
    window.styleOnChangeTimeout = setTimeout(() => {
      let styleNumber = value.flatUpcase()
      if (styleNumber.length > 2) {
        this.checkIfValidStyle(styleNumber)
      } else {
        this.setState({addStyleIcon: null, addStyleIconPosition: null})
        $('#add-style-button').removeClass('loading')
      }
    }, 500)
  }

  checkIfValidStyle(styleNumber) {
    let {brandApproval, approvalBrands} = this.state

    Utils.post('check_if_valid_style_number', { styleNumber }, (err, result) =>
    {
      $('#add-style-button').removeClass('loading')
      if (err) {
        console.log('There was an error when trying to check if style number is valid.')
      } else {
        if (result.data.valid) {
          let cad = JSON.parse(result.data.cad).data.attributes

          this.setState({addStyleIcon: 'check', addStyleIconPosition: 'left', cad})
        } else {
          this.setState({addStyleIcon: 'close', addStyleIconPosition: 'left', cad: null})
        }
      }
    })
  }

  ticketedOnChange(e) {
    let ticketed = e.currentTarget.value

    let newState = {ticketed, pendingChanges: true}

    if (ticketed.includes('YES')) {
      newState.upfront = 'YES'
    }

    this.setState(newState)
  }

  upfrontOnChange(e) {
    let upfront = e.currentTarget.value
    this.setState({upfront, pendingChanges: true})
  }

  ticketsOrderedOnChange(e, { value }) {
    this.setState({ticketsOrdered: value, pendingChanges: true})
  }

  ticketsTrackingNumberOnChange(e, { value }) {
    this.setState({ticketsTrackingNumber: value, pendingChanges: true})
  }

  ticketsConfirmationNumberOnChange(e, { value }) {
    this.setState({ticketsConfirmationNumber: value, pendingChanges: true})
  }

  richTextMessageHtmlOnchange(value) {
    this.setState({richTextMessageHtml: value, pendingChanges: true})
  }

  agedOnChange(e, { value }) {
    this.setState({aged: value, pendingChanges: true})
  }

  agedStyleNumberOnChange(e, { value }) {
    this.setState({agedStyleNumber: value, pendingChanges: true})
  }

  termsOnChange(event) {
    let {value} = event.currentTarget
    this.setState({terms: value, pendingChanges: true})
  }

  departmentNumberOnChange(e, { value }) {
    this.setState({departmentNumber: value, pendingChanges: true})
  }

  shippingClassOnChange(e, { value }) {
    this.setState({shippingClass: value, pendingChanges: true})
  }

  hangersOnChange(e, { value }) {
    this.setState({hangers: value, pendingChanges: true})
  }

  reorderOnChange(e, { value }) {
    this.setState({reorder: value, pendingChanges: true})
  }

  topSamplesNeededOnChange(e, { value }) {
    this.setState({topSamplesNeeded: value, pendingChanges: true})
  }

  topSamplesNeededDueOnChange(e, { value }) {
    this.setState({topSamplesNeededDue: value, pendingChanges: true})
  }

  ppSamplesNeededOnChange(e, { value }) {
    this.setState({ppSamplesNeeded: value, pendingChanges: true})
  }

  ppSamplesNeededDueOnChange(e, { value }) {
    this.setState({ppSamplesNeededDue: value, pendingChanges: true})
  }

  addFile() {
    this.setState({addingFile: true})
  }

  addDistFile() {
    this.setState({addingDistFile: true})
  }

  setPendingChanges() {
    this.setState({pendingChanges: true})
  }

  customerPONumberOnChange = (e, {value}) => {
    this.setState({checkingUniqueCustomerPONumber: true})
    clearTimeout(window.styleOnChangeTimeout)
    window.styleOnChangeTimeout = setTimeout(() => {
      let customerPONumber = value.trimFlattenUpcase()
      if (customerPONumber.length > 2) {
        this.checkIfValidCustomerPONumber(customerPONumber)
      } else {
        this.setState({
          checkingUniqueCustomerPONumber: false,
          customerPONumberIcon: null,
          customerPONumberIconPosition: null,
          customerPONumberErrorMessage: null
        })
        $('#customer-order-number')[0].setCustomValidity("")
      }
    }, 500)
  }

  checkIfValidCustomerPONumber(customerPONumber) {
    let data = {
      customerOrderNumber: customerPONumber, contactId: this.state.quickbooksAccount,
      id: this.props.id
    }
    Utils.post('check_if_valid_customer_po_number', data, (err, result) =>
    {
      this.setState({checkingUniqueCustomerPONumber: false})
      if (err) {
        console.log('There was an error when trying to check if PO # is unique.')
      } else {
        let newState = {customerPONumberErrorMessage: result.data.errorMessage, customerPONumberIconPosition: 'left'}
        newState["customerPONumberIcon"] = (result.data.valid ? 'check' : 'close')
        this.setState(newState)

        let customValidity = ""
        if (result.data.errorMessage != null) {
          customValidity = result.data.errorMessage
        }
        $('#customer-order-number')[0].setCustomValidity(customValidity)
      }
    })
  }

  setBrandApprovalData(contactId) {
    const {customer} = this.context
    if (customer == null) {
      setTimeout(() => this.setBrandApprovalData(contactId), 1000)
    } else {
      const {brands, categories} = customer
      let {accountIds, companies, isEditPage} = this.state
      this.setState(setBrandApprovalData(contactId, accountIds, companies, isEditPage, this.setBrandApprovalData, this.setRemoveStyles, brands, categories))
    }
  }

  setRemoveStyles() {
    let {cads, brandApproval, approvalBrands} = this.state
    this.setState(setRemoveStyles(cads, brandApproval, approvalBrands))
  }

  createMultiplePO() {
    if (window.confirm("Are you sure you want to create multiple orders? Any work you have done on this page will be LOST!")) {
      let url = window.location.href.split('?')[0]
      window.location = url + "?multiple=true"
      window.location.reload()
    }
  }

  createSinglePO() {
    if (window.confirm("Are you sure you want to create a single order? Any work you have done on this page will be LOST!")) {
      let url = window.location.href.split('?')[0]
      window.location = url
      window.location.reload()
    }
  }

  toggleMultipleOrders() {
    if (this.state.isMultiplePage) {
      this.createSinglePO()
    } else {

      alert('Multiple PO is currently disabled!!!')
      //this.createMultiplePO()
    }
  }

  addAnotherOrder() {
    let {numberOfExtraOrders} = this.state
    numberOfExtraOrders += 1
    this.setState({numberOfExtraOrders})
  }

  removeLastOrder() {
    if (window.confirm('Are you sure you want to remove last order?')) {
      let {numberOfExtraOrders, extraCads} = this.state
      delete extraCads[numberOfExtraOrders-1]
      numberOfExtraOrders -= 1
      this.setState({numberOfExtraOrders, extraCads})

      setTimeout(() => {
        this.rebuildCads()
      },0)
    }
  }

  addExtraCads(cads, index) {
    let {extraCads} = this.state
    extraCads[index+1] = cads
    this.setState({extraCads})

    setTimeout(() => {
      this.rebuildCads()
    },0)
  }

  rebuildCads() {
    let {extraCads} = this.state
    let keys = Object.keys(extraCads)
    let cads = []
    keys.forEach((key) => {
      cads = cads.concat(extraCads[key])
    })
    this.setState({cads})

    setTimeout(() => {
      this.setInitialQuantityAndDate()
      if (this.state.quickbooksAccount != null) {
        this.setRemoveStyles()
      }
    },0)
  }

  generateUniquePonumber() {
    const now = new Date()
    const secondsSinceEpoch = Math.round(now.getTime() / 1000).toString()
    $('#customer-order-number').val(secondsSinceEpoch)
    this.customerPONumberOnChange(null, {value: secondsSinceEpoch})
  }

  setParentState(object) {
    this.setState(object)
  }

  swapCad(cad, key, index) {
    processSwapCad(this.state, this.updateState, cad, key, index)
  }

  updateState(newState) {
    this.setState(newState)
  }

  render() {
    let {user, customer} = this.context

    let {isViewPage, isEditPage, usingDocParser, styleNumbers, notFound, noAtsOrFutureAts, customerCompany, orderType, newAccountSaved, quickbooksAccount, pdfFilename, pdfFilenameSaved, cads,
      bulkQuantity, bulkPrice, bulkStartShipDate, bulkCancelDate, cpoQuantity, cpoSizes, price, startShipDate, exFactoryDate, vendorPonumber, loadingDate, cancelDate, uploadingFile, submittingForm,
      shippingCharge, showPaymentForm, accountClientSecret, accountPaymentMethods, quantity, accounts, docParserCustomerCompanies, cadErrors, initialQuantity,
      ponumber, docParserCompany, orderCreateDate, itemStatus, createdBy, orderStatus, stripePayments, stripeRefunds, brandApproval, removeStyles, isMultiplePage,
      numberOfExtraOrders, rep, organizations, importedFromQb, isSuccess, successId, oldRefNums, oldCreatedDates, quickbooks_account_name, approvalBrands} = this.state

    let isSalesAssistant = (user && ['sales_assistant'].indexOf(user.role) > -1)
    let isProduction = (user && ['production'].indexOf(user.role) > -1)

    let salesAdminName = ''

    let repOptions = []

    if (organizations != null && customer != null) {
      let currentCompanyId = customer.id
      let organization = organizations.find(org => org.id === currentCompanyId)
      let users = (organization == null ? [] : organization.users)
      repOptions = users.filter(x => x.role == 'sales')
        .map((x) => {return {key: x.id, text: x.name, value: x.id}})
        .sort(function(a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })

      if (isSalesAssistant) {
        repOptions = repOptions.filter((x) => {
          return user.salesAdminIds.indexOf(x.key) != -1
        })
        salesAdminName = repOptions[0].text
      }
    }

    const quickbooksAccountInfo = docParserCustomerCompanies == null ? '' : docParserCustomerCompanies.map(x => x.name).join(', ') + ' are all Doc Parser enabled!'

    const companyItems = []
    if (usingDocParser === 'Yes' && docParserCustomerCompanies != null) {
      for (const [index, company] of docParserCustomerCompanies.entries()) {
        companyItems.push(
          <Form.Radio
            key={index*2}
            style={{fontSize: '150%', marginLeft: '50px'}}
            label=''
            value={company.name}
            checked={customerCompany === company.name}
            onChange={this.handleCustomerCompanyOnChange}
            disabled={isEditPage}
          />
        )
        companyItems.push(
          <Button basic
            key={index*2+1}
            style={isEditPage ? methodButtonStyleDisabled : methodButtonStyle}
            className={isEditPage ? 'method-button-disabled' : null}
            onClick={this.handleCustomerCompanyButtonOnClick.bind(null, company.name)}>
            <img src={company.logo_url} style={{maxHeight: '75px', maxWidth: '75px', display: 'block', margin: 'auto', marginTop: '10px', marginBottom: '20px'}}/>
            {company.name}
          </Button>
        )
      }
    }

    const showingStyleNumberError = (notFound != null && notFound.length > 0) || (noAtsOrFutureAts != null && noAtsOrFutureAts.length > 0) || (styleNumbers != null && styleNumbers.length == 0 && usingDocParser === 'No')
    const addingNewCustomer = quickbooksAccount == 'NEW CUSTOMER' && !newAccountSaved

    let showFutureATS = cads.some((cad) => Number(cad.future_ats) > 0)


    var sum = 0
    var totalStyles = 0
    var cost = 0
    cads.forEach((cad) => {
      let key = getKey(cad)

      if (quantity[key] != null) {
        sum += quantity[key]
      }

      if (Constants.NON_INVENTORY_ITEMS.indexOf(cad.style_number) > -1 && price[key] != null) {
        cost += price[key]
      } else {
        if (quantity[key] != null && price[key] != null) {
          cost += quantity[key] * price[key]
        }
      }

      if (quantity[key] != null && quantity[key] > 0) {
        totalStyles += 1
      }
    })

    if (isNaN(cost)) {
      cost = 0
    }

    if (shippingCharge != null) {
      cost += shippingCharge
    }

    let totalPayment = 0
    stripePayments.filter(x => !!x.is_paid).forEach((payment) => {
      totalPayment += payment.amount_paid
    })
    stripeRefunds.filter(x => !!x.is_paid).forEach((refund) => {
      totalPayment -= refund.amount_paid
    })
    totalPayment /= 100

    let isAdminOrInventory = (user && ['superadmin', 'admin', 'inventory'].indexOf(user.role) > -1)

    let loading = (repOptions.length == 0 && (customer == null || customer.id == 1)) || (isEditPage && ponumber == null)

    return (
      <div style={{padding: '50px 60px'}}>

        {isSuccess ? <SuccessMessage successId={successId}/> : null}

        {
          loading
          ?
          <Dimmer active>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          :
          null
        }
        <div style={{maxWidth: '1200px', margin: 'auto'}}>
          {oldRefNums.length ?
            <Message>
              This order is a new version.  OLD REF # {oldRefNums.join(', ')}
            </Message>
          : null}

          {
            isEditPage ?
            <h1 style={{marginTop: '25px', marginBottom: '25px'}}>
              {isViewPage ? 'View' : 'Edit'} sales order #{this.props.id}

              <Popup content='Download history of changes' position='bottom right' trigger={
                <Button id="download-history-of-changes-button" basic onClick={this.downloadLogs} style={{float: 'right', padding: '14px', marginLeft: '10px'}}><Icon name='tree'/>Logs</Button>
              }/>

              <Popup content='Download order details' position='bottom right' trigger={
                <Button id="download-order-details-button" basic onClick={this.downloadOrderDetails} style={{float: 'right', padding: '14px', marginLeft: '30px'}}><Icon name='file excel outline'/>Order details</Button>
              }/>
            </h1>
            :
            <h1 style={{marginTop: '25px', marginBottom: '25px', display: 'none'}}>
              New sales order {isMultiplePage ? ' — Multiple' : null}

              <Checkbox toggle label='Multiple orders' style={{float: 'right'}} onClick={this.toggleMultipleOrders} checked={isMultiplePage}/>
            </h1>
          }

          <AutoEditMessage autoEdited={this.state.autoEdited} autoEditedDate={this.state.autoEditedDate} needToAutoEdit={this.state.needToAutoEdit}/>

          <QbSyncMessage qbSynced={this.state.qbSynced} qbSyncedDate={this.state.qbSyncedDate} />

          <FlaggedMessage flaggedForUpdate={this.state.flaggedForUpdate}
            removeFlag={this.removeFlag}
            isProduction={isProduction}
            logs={this.state.logs}
          />

          {!isEditPage && user && user.role === 'sales_assistant' && user.salesAdminIds.length == 1 ?
            <Message
              style={{maxWidth: '1200px', margin: 'auto', marginTop: '40px'}}
              info
              icon='user'
              header={<h2>Note for sales assistants</h2>}
              content={
                <div>
                  This order will automatically be assigned to your sales admin: &nbsp;&nbsp;&nbsp; <u>{salesAdminName}</u>
                </div>
              }
            />
          : null}
        </div>
        <br/><br/><br/>
        {isEditPage ?
          <div style={{maxWidth: '1200px', margin: 'auto'}}>
            <SpsXmlFileKey spsXmlFileKey={this.state.spsXmlFileKey} />
            <h3 style={{fontSize: '150%'}}>Order summary</h3>
            <Table padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>REF #</Table.HeaderCell>
                  <Table.HeaderCell>Method</Table.HeaderCell>
                  {usingDocParser === 'Yes' ?
                    <Table.HeaderCell>Doc Parser</Table.HeaderCell>
                  : null}
                  <Table.HeaderCell>
                    Style #{styleNumbers != null && styleNumbers.length > 1 ? ' ('+styleNumbers.length+')' : ''}
                    {InfoCircle(styleNumberInfoDisabled)}
                  </Table.HeaderCell>
                  <Table.HeaderCell>Item Status</Table.HeaderCell>
                  <Table.HeaderCell>Date Created</Table.HeaderCell>
                  <Table.HeaderCell>Rep</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  {quickbooks_account_name != null && quickbooks_account_name != '' ?
                    <Table.HeaderCell>QB account name</Table.HeaderCell>
                  : null}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>{ponumber}</Table.Cell>
                  <Table.Cell>
                    {
                      importedFromQb
                      ?
                      'Imported from QB'
                      :
                      (
                        usingDocParser === 'Yes'
                        ?
                        'Doc Parser'
                        :
                        this.state.spsXmlFileKey != null
                        ?
                        'Imported from SPS'
                        :
                        'Search for cads'
                      )
                    }
                  </Table.Cell>
                  {usingDocParser === 'Yes' ? <Table.Cell>{docParserCompany}</Table.Cell> : null}
                  <Table.Cell><div id="style-numbers-div"></div></Table.Cell>
                  <Table.Cell>{itemStatusOrderSummary(itemStatus)}</Table.Cell>
                  <Table.Cell>
                    {oldCreatedDates.map(function(row, index) {
                      return (
                        <div dangerouslySetInnerHTML={{__html: '<small>'+oldRefNums[index]+'</small> — '+row.replace(/ /g, '&nbsp;').replace('EST', '')}}></div>
                      )
                    })}
                    <div dangerouslySetInnerHTML={{__html: (oldCreatedDates.length ? '<small>'+ponumber+'</small> — ' : '')+orderCreateDate.replace(/ /g, '&nbsp;').replace('EST', '')}}></div>
                  </Table.Cell>
                  <Table.Cell>{createdBy}</Table.Cell>
                  <Table.Cell>{getStatusMarkup(orderStatus)}</Table.Cell>
                  {quickbooks_account_name != null && quickbooks_account_name != '' ?
                    <Table.Cell>{quickbooks_account_name}</Table.Cell>
                  : null}
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        : null}

        <div style={{maxWidth: '1200px', margin: 'auto'}}>
          {isAdminOrInventory || (isSalesAssistant && user.salesAdminIds.length > 1) ?
            <div style={isEditPage ? {marginTop: '60px'} : null}>
              <Form style={{minHeight: '100px'}}>
                <Form.Group inline>
                  <label style={labelStyle}>
                    Rep
                  </label>
                  <span style={{width: '400px', marginLeft: '65px'}}>
                    <Dropdown disabled={repOptions.length == 0} loading={repOptions.length == 0} placeholder='Rep' fluid selection search options={repOptions} onChange={this.repOnChange} value={rep}/>
                  </span>
                </Form.Group>
              </Form>
            </div>
          : null}

          {isEditPage || (isAdminOrInventory && rep == null) ? null :
            <Form>
              <Form.Group inline>
                <label style={labelStyle}>
                  Method
                  {InfoCircle(docParserInfo)}
                </label>
                <Form.Radio
                  style={{fontSize: '150%', marginLeft: '50px'}}
                  label=''
                  value='Yes'
                  checked={usingDocParser === 'Yes'}
                  onChange={this.handleUsingDocParserOnChange}
                  disabled={isMultiplePage || isEditPage}
                />
                <Button basic
                  style={isMultiplePage || isEditPage || (this.state.docParserCustomerCompanies != null && this.state.docParserCustomerCompanies.length == 0) ? methodButtonStyleDisabled : methodButtonStyle}
                  className={isEditPage ? 'method-button-disabled' : null}
                  onClick={this.handleUsingDocParserButtonOnClick.bind(null, 'Yes')}>
                  <img src="https://ihlgroup-app-production.s3.amazonaws.com/docparser_logo.png" style={{maxHeight: '75px', maxWidth: '75px', display: 'block', margin: 'auto', marginTop: '10px', marginBottom: '20px'}}/>
                  Doc Parser
                </Button>
                <Form.Radio
                  style={{fontSize: '150%', marginLeft: '50px'}}
                  label=''
                  value='No'
                  checked={usingDocParser === 'No'}
                  onChange={this.handleUsingDocParserOnChange}
                  disabled={isEditPage}
                />
                <Button basic
                  style={isEditPage ? methodButtonStyleDisabled : methodButtonStyle}
                  className={isEditPage ? 'method-button-disabled' : null}
                  onClick={this.handleUsingDocParserButtonOnClick.bind(null, 'No')}>
                  <Icon name="search" style={{maxHeight: '75px', maxWidth: '75px', display: 'block', margin: 'auto', marginTop: '10px', marginBottom: '20px'}}/>
                  Search for cads
                </Button>

                <Form.Radio
                  style={{fontSize: '150%', marginLeft: '50px', opacity: 0.5, pointerEvents: 'none', display: 'none'}}
                  label=''
                  value='No'
                  checked={usingDocParser === 'Worksheet'}
                  onChange={this.handleUsingDocParserOnChange}
                  disabled={isEditPage}
                />
                <span style={{opacity: 0.5, pointerEvents: 'none', display: 'none'}}>
                  <Button basic
                    style={isMultiplePage || isEditPage ? methodButtonStyleDisabled : methodButtonStyle}
                    className={isEditPage ? 'method-button-disabled' : null}
                    onClick={this.handleUsingDocParserButtonOnClick.bind(null, 'Worksheet')}>
                    <Icon name="file excel outline" style={{maxHeight: '75px', maxWidth: '75px', display: 'block', margin: 'auto', marginTop: '10px', marginBottom: '20px'}}/>
                    Upload worksheet
                  </Button>
                </span>

              </Form.Group>
            </Form>
          }

          {usingDocParser === 'Worksheet' ?
            <Message>
              Must have columns: FINAL STYLE NUMBER, START SHIP, CANCEL DATE, TOTAL UNITS, FINAL COST
            </Message>
          : null}

          {isMultiplePage && !(isAdminOrInventory && rep == null) ? <div><Label style={{float: 'right'}}>Order #1</Label></div> : null}
          {isEditPage ? null :
            <Transition visible={usingDocParser === 'No'} animation='fade' duration={1000}>
              <div style={{marginTop: '60px'}}>
                <h3 style={labelStyle}>
                  Style numbers {styleNumbers.length > 0 ? '('+styleNumbers.length+')' : ''}
                  {InfoCircle(isEditPage ? styleNumberInfoDisabled : styleNumberInfo)}
                  <br/><br/><Button size='mini' primary onClick={() => {$('#style-numbers-textarea').val('_'); this.textAreaOnChange(null, {value: '_'}); }}>I don't have style numbers</Button>
                </h3>
                <TextArea id="style-numbers-textarea" onChange={this.textAreaOnChange} placeholder="Enter style numbers" style={isEditPage ? textAreaStyleDisabled : textAreaStyle} disabled={isEditPage}/>
                <div style={{marginTop: '50px', fontSize: '120%'}}>
                  <Grid columns={2}>
                    {notFound != null && notFound.length > 0 ?
                      <Grid.Column>
                        <Message icon warning>
                          <Icon name='warning sign'/>
                          <Message.Content>
                            <Message.Header>Cads NOT found</Message.Header>
                            <p>
                              <ul>
                                {notFound.map(function(row, index) {
                                  return (<li key={index}>
                                    {row}
                                  </li>)
                                })}
                              </ul>
                            </p>
                          </Message.Content>
                        </Message>
                      </Grid.Column>
                    : null}
                    {noAtsOrFutureAts != null && noAtsOrFutureAts.length > 0 ?
                      <Grid.Column>
                        <Message icon warning>
                          <Icon name='warning sign'/>
                          <Message.Content>
                            <Message.Header>NO ATS or Future ATS</Message.Header>
                            <p>
                              <ul>
                                {noAtsOrFutureAts.map(function(row, index) {
                                  return (<li key={index}>
                                    {row}
                                  </li>)
                                })}
                              </ul>
                            </p>
                          </Message.Content>
                        </Message>
                      </Grid.Column>
                    : null}
                  </Grid>
                </div>
              </div>
            </Transition>
          }

          <Transition visible={usingDocParser === 'Yes' && !isEditPage} animation='fade' duration={1000}>
            <Form style={{minHeight: '100px', marginTop: '60px'}}>
              <Form.Group inline>
                <label style={labelStyle}>
                  Parser
                  {InfoCircle(selectCustomerInfo)}
                </label>
                {companyItems.slice(0, 6)}
             </Form.Group>
             {
               companyItems.length > 6
               ?
               <Form.Group inline style={{marginLeft: '306px', width: '550px'}}>
                  {companyItems.slice(6, 14)}
               </Form.Group>
               :
               null
             }
             {
               companyItems.length > 14
               ?
               <Form.Group inline style={{marginLeft: '306px', width: '550px', marginTop: '30px'}}>
                  {companyItems.slice(14, companyItems.length)}
               </Form.Group>
               :
               null
             }
            </Form>
          </Transition>

          <Transition visible={((usingDocParser === 'Yes' && customerCompany != null) || usingDocParser === 'Worksheet') && !isEditPage} animation='fade' duration={1000}>
            <Form style={{minHeight: '100px', marginTop: '60px'}}>
              <Form.Group inline>
                <label style={labelStyle}>
                  Contact
                </label>
                <span style={{width: '400px', marginLeft: '65px'}}>
                  <Dropdown placeholder='Contact' loading={accounts.length == 0} search fluid selection options={accounts} onChange={this.handleQuickbooksAccountOnChange} value={quickbooksAccount} />
                </span>
             </Form.Group>


             <Form.Group inline style={{marginBottom: '80px'}}>
               <label style={labelStyle}>
                 Message
               </label>

               <div style={{width: '600px', marginLeft: '65px', display: 'inline-block', marginTop: '60px'}}>
                 <ReactQuill
                   value={this.state.richTextMessageHtml}
                   onChange={this.richTextMessageHtmlOnchange}
                 />
               </div>
             </Form.Group>
            </Form>
          </Transition>

          <Transition visible={isEditPage || (usingDocParser === 'No' && styleNumbers.length > 0 && notFound != null && notFound.length == 0 && noAtsOrFutureAts != null && noAtsOrFutureAts.length == 0)} animation='fade' duration={1000}>
            <div style={isEditPage && isAdminOrInventory ? null : {marginTop: '60px'}}>
              <Form style={{minHeight: '100px'}}>
                <Form.Group inline>
                  <label style={labelStyle}>
                    Order type
                    {InfoCircle(orderTypeInfo)}
                  </label>
                  <span style={{width: '400px', marginLeft: '65px'}}>
                    <Dropdown placeholder='Order Type' fluid selection search options={orderTypeOptions} onChange={this.orderTypeOnChange} value={orderType}/>
                  </span>
               </Form.Group>
              </Form>

              <Form style={{minHeight: '135px'}}>
                <Form.Group inline className={orderType == 'Hold' ? 'locked-section' : ''}>
                  <label style={labelStyle}>
                    {orderType.toUpperCase()} #
                    {orderType == 'Hold' ? InfoCircle(holdNumberInfo) : InfoCircle(customerOrderNumberInfo)}
                    <br/><br/><Button size='mini' primary onClick={this.generateUniquePonumber} disabled={orderType == 'Hold'}>Generate unique {orderType.toUpperCase()} #</Button>
                  </label>
                  <div style={{width: '400px', marginLeft: '65px', display: 'inline-block'}} className="customer-po-number-input-holder">
                    <Input id="customer-order-number" fluid placeholder={orderType.toUpperCase()+' #'}
                      icon={this.state.customerPONumberIcon}
                      iconPosition={this.state.customerPONumberIconPosition}
                      onChange={this.customerPONumberOnChange}
                      form="new-sales-order"
                      required={usingDocParser === 'No'}
                      disabled={orderType == 'Hold'}
                      />
                    {this.state.customerPONumberErrorMessage == null ? null :
                      <small style={{color: 'red', fontWeight: 'bold', position: 'absolute'}} class="ui pointing above prompt label">{this.state.customerPONumberErrorMessage}</small>
                    }
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  {this.state.checkingUniqueCustomerPONumber ? <Loader active inline size='small'/> : null}
               </Form.Group>
              </Form>

              {
                [...Array(numberOfExtraOrders).keys()].map((index) => {
                  return (
                    <ExtraOrderSection key={index} index={index} addExtraCads={this.addExtraCads}
                      quickbooksAccount={this.state.quickbooksAccount} />
                  )
                })
              }


              {isMultiplePage ?
                <div style={{textAlign: 'right', paddingBottom: '60px', display: 'flex'}}>
                  <div style={{borderTop: '1px solid rgb(222,222,222)', display: 'inline-block', flex: '1', marginRight: '20px', position: 'relative', top: '18px'}}></div>
                  <Button basic color='green' onClick={this.addAnotherOrder}><Icon name="plus"/>Add another order</Button>
                  {numberOfExtraOrders == 0 ? null :
                    <Button basic color='red' onClick={this.removeLastOrder} style={{marginLeft: '10px'}}><Icon name="minus"/>Remove last order</Button>
                  }
                </div>
              : null}

              <Form id="new-account-form" style={{minHeight: '100px'}}>
                <Form.Group inline>
                  <label style={labelStyle}>
                    Contact
                    {InfoCircle(quickbooksAccountInfo)}
                  </label>

                  {
                    newAccountSaved ?
                    <span style={{width: '700px', marginLeft: '65px', fontSize: '150%'}}>
                      {this.state.newAccountName} ({this.state.newContactName})
                      <Popup content='Remove Contact' trigger={<Button onClick={this.removeAccount} color='red' basic icon='trash' style={{marginLeft: '10px', padding: '5px'}}/>} />
                    </span>
                    :
                    <span style={{width: '400px', marginLeft: '65px'}}>
                      <Dropdown placeholder='Contact' loading={accounts.length == 0} search fluid selection options={accounts} onChange={this.handleQuickbooksAccountOnChange} value={quickbooksAccount} />
                    </span>
                  }

                  {quickbooksAccount == 'NEW CONTACT' ?
                    <span style={{width: '400px', marginLeft: '10px'}}>
                      <Input id="new-account-name" fluid placeholder='New Account Name' required form="new-sales-order" minLength="4" style={{marginBottom: '4px'}}/>
                      <Input id="new-customer-name" fluid placeholder='New Contact Person Name' required form="new-sales-order" minLength="4"/>
                    </span>
                  : null}
                  {quickbooksAccount == 'NEW CONTACT' ?
                    <div>{newAccountSaved ? null : <Button id="save-contact-button" primary type="submit" style={{width: '134px', marginLeft: '10px'}}>Save Contact</Button>}</div>
                  : null}
               </Form.Group>

               {brandApprovalContent(brandApproval, approvalBrands)}

               <Form.Group inline style={{marginBottom: '80px'}}>
                 <label style={labelStyle}>
                   Message
                 </label>

                 <div style={{width: '600px', marginLeft: '65px', display: 'inline-block', marginTop: '60px'}}>
                   <ReactQuill
                     value={this.state.richTextMessageHtml}
                     onChange={this.richTextMessageHtmlOnchange}
                   />
                 </div>
               </Form.Group>
              </Form>
            </div>
          </Transition>

          {removeStylesMessage(removeStyles)}

          <Transition visible={(quickbooksAccount != null && removeStyles.length == 0 && pdfFilename != null && !showingStyleNumberError && !addingNewCustomer) || (isEditPage && pdfFilename != null)} animation='fade' duration={1000}>
            <div>
              <span style={pdfFilenameLabelStyle}>{usingDocParser === 'Worksheet' ? 'Excel' : usingDocParser === 'No' ? 'File' : 'PDF'} uploaded</span>
              <div style={{width: '600px', marginLeft: '85px', display: 'inline-block'}}>
                <ul>
                  {(pdfFilename == null ? [] : pdfFilename.split('@@@')).map((filename, index) => {

                    let dateString = filename.split('/')[0].substr(32)
                    var d = new Date(0) // The 0 there is the key, which sets the date to the epoch
                    d.setUTCSeconds(dateString)

                    return (
                      <li style={{fontSize: '150%', marginBottom: '10px'}} key={index}>
                        {filename.substring(43)}

                        {pdfFilenameSaved.indexOf(filename) > -1 ?
                          <Popup content='Download File' trigger={<Button onClick={this.downloadFile.bind(null, filename)} color='green' basic icon='download' style={{marginLeft: '10px', padding: '5px'}}/>} />
                        : null}

                        <Popup content='Remove File' trigger={<Button onClick={this.removeFile.bind(null, filename)} color='red' basic icon='trash' style={{marginLeft: '10px', padding: '5px', marginTop: '10px'}}/>} />

                        &nbsp;&nbsp; <small style={{opacity: 0.5, fontSize: '50%', whiteSpace: 'nowrap'}}>Uploaded: {d.toLocaleDateString()}</small>

                        {isMultiplePage ?
                          <select id={'filename-order-number-'+index} style={{fontSize: '78%', marginLeft: '5px'}}>
                            {[...Array(numberOfExtraOrders+1).keys()].map((index2) => {
                              return (
                                <option key={index2} value={index2} selected={index == index2 ? "selected" : ""}>
                                  Order #{index2+1}
                                </option>
                              )
                            })}
                          </select>
                        : null}
                      </li>
                    )
                  })}
                </ul>
                {
                  this.state.addingFile
                  ?
                  null
                  :
                  <div style={{paddingTop: '10px', paddingLeft: '12px'}}>
                    <Popup content='Add file' trigger={<Button onClick={this.addFile} basic size="mini" style={{paddingRight: '8px'}}><Icon name="plus"/></Button>} />
                  </div>
                }
              </div>
            </div>
          </Transition>
          <Transition visible={(quickbooksAccount != null && removeStyles.length == 0 && pdfFilename == null && !showingStyleNumberError && !addingNewCustomer && orderType != 'Hold') || (isEditPage && pdfFilename == null) || this.state.addingFile} animation='fade' duration={1000}>
            <div style={this.state.addingFile ? {marginTop: '50px'} : {}}>
              {usingDocParser === 'No' ? <div style={{float: 'right'}}><b>Accepted file types:</b> image, microsoft word, excel, pdf</div> : null}
              <span id="my-dropzone-title" style={{fontSize: '150%', fontWeight: 'bold', marginBottom: '15px', display: 'inline-block'}}>Upload {usingDocParser === 'Worksheet' ? 'Excel' : usingDocParser === 'No' ? 'File' : 'PDF'} {usingDocParser === 'No' ? <span style={{opacity: 0.5, fontStyle: 'italic', marginLeft: '20px'}}>(optional)</span> : null }</span>
              <Dropzone onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()} id="my-dropzone" >
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop sales order PDF here, or click to select file</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </Transition>
        </div>

        <DatesForNoStyles styleNumbers={styleNumbers} quickbooksAccount={quickbooksAccount} isEditPage={isEditPage} />

        <Transition visible={(styleNumbers != null && styleNumbers.length > 0 && (cads.length > 0 ? (cads[0].style_number == '_' ? false : true) : true) && usingDocParser === 'No' && quickbooksAccount != null && removeStyles.length == 0 && !showingStyleNumberError && !addingNewCustomer) || isEditPage} animation='fade' duration={1000}>
          <div>
            {pdfFilename == null ? <center><Icon name='chevron down' size='huge' style={{color: 'orange', cursor: 'pointer', marginTop: '10px'}} onClick={this.scrollToCadDetails}/></center> : null}
            <h3 id="cad-details-title" style={{fontSize: '150%', marginTop: (cads.length > 1 ? '200px' : '100px'), position: 'relative', bottom: (cads.length > 1 ? '50px' : null)}}>
              Cad details
            </h3>
            <Table compact='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Style &#35;</Table.HeaderCell>
                  <Table.HeaderCell>ATS</Table.HeaderCell>
                  {showFutureATS ? <Table.HeaderCell>Future ATS</Table.HeaderCell> : null}
                  {showFutureATS ? <Table.HeaderCell>Ship Window</Table.HeaderCell> : null}
                  <Table.HeaderCell>
                    {cads.length > 1 ?
                      <div className="bulk-checkout-holder">
                        <div className="bulk-checkout">
                          <b>Bulk Quantity</b><br/>
                          <Input placeholder='Quantity' style={{width: '150px'}} icon='hashtag' iconPosition='left' type="number" min="0" step="1" onChange={this.onChangeBulkQuantity} value={bulkQuantity}/>
                        </div>
                      </div>
                    : null}
                    Quantity
                  </Table.HeaderCell>
                  <Table.HeaderCell>Size</Table.HeaderCell>
                  <Table.HeaderCell>
                    {cads.length > 1 ?
                      <div className="bulk-checkout-holder">
                        <div className="bulk-checkout">
                          <b>Bulk Cost</b><br/>
                          <Input placeholder='Cost' style={{width: '150px'}} icon='dollar' iconPosition='left' type="number" min="0" step=".01" onChange={this.onChangeBulkPrice} value={bulkPrice} />
                        </div>
                      </div>
                    : null}
                    Cost
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {
                      cads.length > 1
                      ?
                      <div className="bulk-checkout-holder">
                        <div className="bulk-checkout">
                          <b>Bulk Start Ship Date</b><br/>
                          <input type="date"
                            value={bulkStartShipDate}
                            onChange={this.onChangeBulkStartShipDate}
                            style={dateInputStyle}
                          />
                        </div>
                      </div>
                      :
                      null
                    }
                    Start Ship Date
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {cads.length > 1 ?
                      <div className="bulk-checkout-holder">
                        <div className="bulk-checkout">
                          <b>Bulk Cancel Date</b><br/>
                          <input type="date"
                            value={bulkCancelDate}
                            onChange={this.onChangeBulkCancelDate}
                            style={dateInputStyle}
                          />
                        </div>
                      </div>
                    : null}
                    Cancel Date
                  </Table.HeaderCell>
                  <Table.HeaderCell>Comment</Table.HeaderCell>

                </Table.Row>
              </Table.Header>
              <Table.Body>
                {cads.map((cad, index) => {
                  let key = getKey(cad)

                  let image_url, imageClass = ''

                  let s3BucketName = cad.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME

                  if (cad.filename == null) {
                    image_url = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new.png'
                    imageClass = 'missing'
                  } else if (cad.have_thumb) {
                    image_url = 'https://s3.amazonaws.com/' + s3BucketName + '/cad_thumb/' + cad.filename.split('/')[0] + '/thumb.jpg'
                  } else {
                    image_url = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + cad.filename
                  }

                  var dateText = '—'
                  if (cad.earliest_delivery_date != null) {
                    dateText = formatDate(cad.earliest_delivery_date.split('T')[0])
                  }

                  if (cad.loading_date != null) {
                    dateText = 'ETA: ' + formatDate(cad.loading_date)
                  }

                  let cad_available = cad.available
                  if (cad_available != null && cad_available != '' && cad_available < 0) {
                    cad_available = '0'
                  }

                  if (cad_available === null || cad_available === '') {
                    cad_available = '0'
                  }

                  let on_hand = Number(cad.on_hand)
                  if (typeof cad_available === "number" && cad_available > on_hand) {
                    cad_available = on_hand
                  }

                  let cad_future_ats = cad.future_ats

                  if (cad_future_ats != null && cad_future_ats != '' && cad_future_ats < 0) {
                    cad_future_ats = '0'
                    dateText = ''
                  }

                  if (cad_future_ats === null || cad_future_ats === '') {
                    cad_future_ats = '0'
                  }

                  let maxQuantity = Number(cad_available) + Number(cad_future_ats)

                  if (isEditPage && initialQuantity[key] != null) {
                    maxQuantity += initialQuantity[key]
                  }

                  let cadError = cadErrors[key]

                  return (
                    <Table.Row key={index}>
                      <Table.Cell className="break-word-14vw-desktop" style={{whiteSpace: 'nowrap', fontWeight: 'bold', fontSize: '120%', padding: '20px'}}>
                        {cad.style_number} {cad.orderIndex == null ? null : <Label>Order #{cad.orderIndex+2}</Label>} {cad.id == null ? cadNotFoundPopup : null}
                        {cadError == 'no_ats_or_future_ats' && cad.id != null ? cadNoATSPopup : null}
                        {cadError == 'insufficient_ats_or_future_ats' && cad.id != null ? cadInsufficientATSPopup(cad.future_ats) : null}
                        <br/>
                        {cad.sizes == null ? null :
                          <div><small style={{opacity: 0.5, fontSize: '12px'}}>sizes: {cad.sizes}</small></div>
                        }
                        {cad.brand == null ? null :
                          <div><small style={{opacity: 0.5, fontSize: '12px'}}>brand: {cad.brand}</small></div>
                        }
                        {cad.parent_category == null ? null :
                          <div><small style={{opacity: 0.5, fontSize: '12px'}}>category: {cad.parent_category}</small></div>
                        }
                        <div><br/>{itemStatus == null ? null : itemStatusOrderDetails(itemStatus[index])}<img className={imageClass} style={{maxWidth: '100%', maxHeight: '170px', boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 10px -2px', marginBottom: '20px'}} src={image_url}/></div>
                        {
                          isMultiplePage
                          ?
                          null
                          :
                          <React.Fragment>
                            <Button onClick={this.removeFromCart.bind(null, key, index)} negative size='mini'><Icon name='trash' /> Remove</Button>
                            <SwapCadPopup isEditPage={isEditPage} swapCad={this.swapCad} cadKey={key} index={index}/>
                          </React.Fragment>
                        }
                      </Table.Cell>
                      <Table.Cell>{cad.id == null ? '—' : Number(cad_available).toLocaleString()}</Table.Cell>
                      {showFutureATS ? <Table.Cell>{cad.id == null ? '—' : Number(cad_future_ats).toLocaleString()}</Table.Cell> : null}
                      {showFutureATS ? <Table.Cell>{dateText}</Table.Cell> : null}
                      <Table.Cell>
                        {cpoQuantity[key] == null ? null :
                          cpoQuantity[key].map((cad_quantity, index2) => {
                            return (
                              <div key={index2} style={{paddingTop: '2px', paddingBottom: '2px'}}>
                                <Input placeholder='Quantity' style={{width: '150px'}} value={cad_quantity} onChange={this.onChangeCPOquantity.bind(null, key, index2)} icon='hashtag' iconPosition='left' type="number" min="0" step="1" form="new-sales-order" required/>
                              </div>
                            )
                          })
                        }
                        {maxQuantity == 0 || cpoQuantity[key] == null || cpoQuantity[key].length > 1  ? null : <Button onClick={this.addAll.bind(null, key, maxQuantity)} basic size='mini' style={{position: 'absolute', marginTop: '5px'}}>Add all</Button>}
                      </Table.Cell>
                      <Table.Cell>
                        {cpoSizes[key] == null ? null :
                          cpoSizes[key].map((cad_size, index2) => {
                            return (
                              <div key={index2} style={{width: '110px', paddingTop: '2px', paddingBottom: '2px'}}>
                                <Dropdown placeholder='Size' fluid selection search options={sizesCheckoutOptions}
                                  onChange={this.onChangeCPOsize.bind(null, key, index2)} value={cad_size}
                                />
                              </div>
                            )
                          })
                        }
                        <Button onClick={this.addSize.bind(null, cad.id)} basic size='mini' style={{position: 'absolute', marginTop: '5px', paddingRight: '10px'}}><Icon name='plus'/></Button>
                        {cpoSizes[key] == null || cpoSizes[key].length == 1 ? null : <Button onClick={this.removeSize.bind(null, key)} negative size='mini' style={{position: 'absolute', marginTop: '5px', marginLeft: '50px', paddingRight: '10px'}}><Icon name='minus'/></Button>}
                      </Table.Cell>
                      <Table.Cell>
                        <Input placeholder="Cost" style={{width: '150px'}} value={price[key] == null ? '' : price[key]} onChange={this.onChangePrice.bind(null, key)} icon='dollar' iconPosition='left' type="number" min="0" step=".01" /><br/>
                      </Table.Cell>
                      <Table.Cell>
                        <input type="date"
                          value={startShipDate[key]}
                          form="new-sales-order" required
                          onChange={this.onChangeStartShipDate.bind(null, key)}
                          style={dateInputStyle}
                        />
                        <StartShipDateLogs cadId={key}/>
                        {displayExFactory(exFactoryDate[key], vendorPonumber[key], loadingDate[key], isEditPage)}
                      </Table.Cell>
                      <Table.Cell>
                        <input type="date"
                          value={cancelDate[key]}
                          form="new-sales-order" required
                          onChange={this.onChangeCancelDate.bind(null, key)}
                          style={dateInputStyle}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TextArea id={'cad-comment-' + getCommentKey(key)} style={{width: '200px', maxWidth: '200px'}} rows={5} placeholder='Comment' onChange={this.setPendingChanges}/>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}

              </Table.Body>
            </Table>

            {cads.length == 0 ? <div style={zeroResultsStyle}>You have NO cads added to the order!</div> : null}

            {isEditPage ?
              <div id="add-style-input-holder">
                <Input
                  id="add-style-input"
                  action={
                    <Button id="add-style-button" primary onClick={this.addStyle} disabled={this.state.addStyleIcon != 'check'}>Add cad</Button>
                  }
                  placeholder='Enter style number'
                  style={{width: '400px'}}
                  icon={this.state.addStyleIcon}
                  iconPosition={this.state.addStyleIconPosition}
                  onChange={this.styleOnChange}
                />
              </div>
            : null}
          </div>
        </Transition>

        <div style={{maxWidth: '1200px', margin: 'auto'}}>
          <Transition visible={isEditPage || (!isMultiplePage && (styleNumbers.length > 0 || usingDocParser === 'Worksheet') && quickbooksAccount != null && removeStyles.length == 0 && !showingStyleNumberError && !addingNewCustomer)} animation='fade' duration={1000}>
            <div>
              <Form style={{minHeight: '100px', marginTop: '80px'}}>
                <Form.Group>
                  <label style={labelStyle}>
                    Tickets
                  </label>
                  <span style={{width: '900px', marginLeft: '83px', padding: '20px 50px', border: '1px solid rgb(222, 222, 222)', borderRadius: '5px'}}>

                    <input type="radio" id="ticketed-no" name="ticketed" style={radioButtonStyle} value="NO" checked={this.state.ticketed === 'NO'} onChange={this.ticketedOnChange}
                      form="new-sales-order" required
                    />
                    <label for="ticketed-no" style={radioButtonLabelStyle}>NO</label> &nbsp;&nbsp;&nbsp;

                    <input type="radio" id="ticketed-upfront-no-tickets" name="ticketed" style={radioButtonStyle} value="UPFRONT-NO-TICKETS" checked={this.state.ticketed === 'UPFRONT-NO-TICKETS'} onChange={this.ticketedOnChange}
                      form="new-sales-order" required
                    />
                    <label for="ticketed-upfront-no-tickets" style={radioButtonLabelStyle}>UPFRONT-NO-TICKETS</label> &nbsp;&nbsp;&nbsp;

                    <input type="radio" id="ticketed-yes-factory" name="ticketed" style={radioButtonStyle} value="YES FACTORY" checked={this.state.ticketed === 'YES FACTORY'} onChange={this.ticketedOnChange}
                      form="new-sales-order" required
                    />
                    <label for="ticketed-yes-factory" style={radioButtonLabelStyle}>YES FACTORY</label> &nbsp;&nbsp;&nbsp;
                    <input type="radio" id="ticketed-yes-la" name="ticketed" style={radioButtonStyle} value="YES-LA" checked={this.state.ticketed === 'YES-LA'} onChange={this.ticketedOnChange}
                      form="new-sales-order" required
                    />
                    <label for="ticketed-yes-la" style={radioButtonLabelStyle}>YES-LA</label> &nbsp;&nbsp;&nbsp;
                    <input type="radio" id="ticketed-yes-china-la" name="ticketed" style={radioButtonStyle} value="YES-CHINA/LA" checked={this.state.ticketed === 'YES-CHINA/LA'} onChange={this.ticketedOnChange}
                      form="new-sales-order" required
                    />
                    <label for="ticketed-yes-china-la" style={radioButtonLabelStyle}>YES-CHINA/LA</label>


                    <Form style={{width: '600px'}}>
                      <Transition visible={!(['NO', '', null, 'UPFRONT-NO-TICKETS'].includes(this.state.ticketed))} animation='fade' duration={1000}>
                        <div style={{marginTop: '15px'}}>
                          <TicketedDueInput />

                          <Grid columns={2}>
                            <Grid.Column>
                              <b style={{paddingBottom: '5px', display: 'block', fontSize: '13px'}}>Ordered</b>
                              <Form.Group inline>
                                <Form.Radio
                                  name='ticketsOrdered'
                                  label='No'
                                  value={false}
                                  checked={this.state.ticketsOrdered === false}
                                  onChange={this.ticketsOrderedOnChange}
                                />
                                <Form.Radio
                                  name='ticketsOrdered'
                                  label='Yes'
                                  value={true}
                                  checked={this.state.ticketsOrdered === true}
                                  onChange={this.ticketsOrderedOnChange}
                                />
                              </Form.Group>
                            </Grid.Column>
                            {this.state.ticketsOrdered && this.state.ticketsOrderedDate != '' ?
                              <Grid.Column>
                                <b style={{paddingBottom: '5px', display: 'block', fontSize: '13px'}}>Ordered date</b>
                                <Input type='date' disabled style={{width: '200px'}} value={this.state.ticketsOrderedDate}/>
                              </Grid.Column>
                            : null}
                          </Grid>

                          <Form.Input
                            fluid
                            name='ticketsConfirmationNumber'
                            label='Confirmation #'
                            placeholder='Confirmation #'
                            value={this.state.ticketsConfirmationNumber}
                            onChange={this.ticketsConfirmationNumberOnChange}
                            form="new-sales-order"
                          />

                          <Form.Input
                            fluid
                            name='ticketsTrackingNumber'
                            label='Tracking #'
                            placeholder='Tracking #'
                            value={this.state.ticketsTrackingNumber}
                            onChange={this.ticketsTrackingNumberOnChange}
                            form="new-sales-order"
                          />
                        </div>
                      </Transition>
                    </Form>

                  </span>
                </Form.Group>
              </Form>

              {upfrontInput(this.state.upfront, this.upfrontOnChange)}

              <Form style={{minHeight: '100px', marginTop: '80px'}}>
                <Form.Group inline>
                  <label style={labelStyle}>
                    Shipping details
                    <span style={{opacity: 0.5, fontStyle: 'italic', marginLeft: '20px'}}>(optional)</span>
                  </label>
                  <span style={{width: '400px', marginLeft: '56px'}}>
                    <Input fluid placeholder='Shipping cost' icon='dollar' iconPosition='left' type="number" min="0" step=".01" onChange={this.onChangeShippingCharge} value={shippingCharge} />
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span style={{width: '400px'}}>
                    <Input id="tracking-number" fluid placeholder='Tracking #' icon='hashtag' iconPosition='left' />
                  </span>

                </Form.Group>
              </Form>
            </div>
          </Transition>
          <Transition visible={false && (isEditPage || (!isMultiplePage && styleNumbers.length > 0 && quickbooksAccount != null && removeStyles.length == 0 && !showingStyleNumberError && !addingNewCustomer && orderType == 'PO' && usingDocParser === 'No'))} animation='fade' duration={1000}>
            <div style={{marginTop: '55px'}}>
              <span style={{fontSize: '150%', fontWeight: 'bold', verticalAlign: 'top'}}>
                Payment method
                <span style={{opacity: 0.5, fontStyle: 'italic', marginLeft: '20px'}}>(optional)</span>
              </span>

              <div style={{width: '720px', background: 'rgb(247,248,249)', padding: '20px 50px', display: 'inline-block',  marginLeft: '80px'}}>
                <Elements stripe={stripePromise}>
                  <CardSetupForm account={quickbooksAccount}
                    accountClientSecret={accountClientSecret}
                    accountPaymentMethods={accountPaymentMethods}
                    updatePaymentMethods={this.updatePaymentMethods}
                    removePaymentMethod={this.removePaymentMethod}
                    showPaymentForm={showPaymentForm}
                    />
                </Elements>
              </div>
            </div>
          </Transition>

          {false && isEditPage ?
            <PaymentHistory stripePayments={stripePayments} stripeRefunds={stripeRefunds} orderId={this.props.id}
              contactId={quickbooksAccount} pendingChanges={this.state.pendingChanges} />
          : null}

          <Transition visible={(styleNumbers != null && styleNumbers.length > 0 && quickbooksAccount != null && removeStyles.length == 0 && !showingStyleNumberError && !addingNewCustomer && usingDocParser === 'No') || isEditPage} animation='fade' duration={1000}>
            <div style={{marginTop: '65px'}}>
              <span style={{fontSize: '150%', fontWeight: 'bold', verticalAlign: 'top'}}>
                Extra fields
                <span style={{opacity: 0.5, fontStyle: 'italic', marginLeft: '20px'}}>(optional)</span>
              </span>

              <div style={{border: '1px solid rgb(222,222,222)', borderRadius: '5px', width: '720px', padding: '20px 50px', display: 'inline-block',  marginLeft: '135px'}}>
                <ExtraFields
                  richTextMessageHtml={this.state.richTextMessageHtml} richTextMessageHtmlOnchange={this.richTextMessageHtmlOnchange}
                  aged={this.state.aged} agedOnChange={this.agedOnChange}
                  agedStyleNumber={this.state.agedStyleNumber} agedStyleNumberOnChange={this.agedStyleNumberOnChange}
                  hangers={this.state.hangers} hangersOnChange={this.hangersOnChange}
                  reorder={this.state.reorder} reorderOnChange={this.reorderOnChange}
                  topSamplesNeeded={this.state.topSamplesNeeded} topSamplesNeededOnChange={this.topSamplesNeededOnChange}
                  topSamplesNeededDue={this.state.topSamplesNeededDue} topSamplesNeededDueOnChange={this.topSamplesNeededDueOnChange}
                  ppSamplesNeeded={this.state.ppSamplesNeeded} ppSamplesNeededOnChange={this.ppSamplesNeededOnChange}
                  ppSamplesNeededDue={this.state.ppSamplesNeededDue} ppSamplesNeededDueOnChange={this.ppSamplesNeededDueOnChange}
                  handleAcceptedFiles={this.handleAcceptedFiles} distFilename={this.state.distFilename} distFilenameSaved={this.state.distFilenameSaved}
                  downloadDistFile={this.downloadDistFile} removeDistFile={this.removeDistFile}
                  addDistFile={this.addDistFile} addingDistFile={this.state.addingDistFile}

                  terms={this.state.terms} termsOnChange={this.termsOnChange}
                  departmentNumber={this.state.departmentNumber} departmentNumberOnChange={this.departmentNumberOnChange}
                  shippingClass={this.state.shippingClass} shippingClassOnChange={this.shippingClassOnChange}
                />
              </div>

              <div style={{height: '65px'}}></div>

              <span style={{fontSize: '150%', fontWeight: 'bold', verticalAlign: 'top'}}>
                Order totals
              </span>
              <div style={{width: '500px', background: 'rgb(247,248,249)', padding: '20px 50px', display: 'inline-block',  marginLeft: '240px'}}>
                <Grid style={{fontSize: '120%'}}>
                  <Grid.Row>
                    <Grid.Column width={9} style={{textAlign: 'left'}}>
                      Total quantity:<br/><br/>
                      Total styles:<br/><br/>
                      {orderType != 'PO' || isMultiplePage ? null : <span>Shipping cost: <br/><br/></span>}
                      <hr style={{marginRight: '-20px'}}/><br/>
                      Total cost:
                      {totalPayment != 0 ?
                        <span>
                          <br/><br/>
                          Total payment:<br/><br/>
                          <hr style={{marginRight: '-20px'}}/><br/>
                          Balance:
                        </span>
                      : null}
                    </Grid.Column>
                    <Grid.Column width={7} style={{textAlign: 'right'}}>
                      {sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<br/><br/>
                      {totalStyles}<br/><br/>
                      {orderType != 'PO' || isMultiplePage ? null : <span>$ {shippingCharge == null ? '0.00' : shippingCharge.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}<br/><br/></span>}
                      <hr style={{marginLeft: '-20px'}}/><br/>
                      $ {cost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                      {totalPayment != 0 ?
                        <span>
                          <br/><br/>
                          $ {totalPayment.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}<br/><br/>
                          <hr style={{marginLeft: '-20px'}}/><br/>
                          $ {(cost - totalPayment).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </span>
                      : null}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </Transition>
        </div>
        <br/>

        {isProduction ? null :
          <Transition visible={isEditPage || pdfFilename != null || (usingDocParser === 'No' && quickbooksAccount != null && removeStyles.length == 0 && !showingStyleNumberError && !addingNewCustomer)} animation='fade' duration={1000}>
            <Form id="new-sales-order" style={{textAlign: 'center'}} onSubmit={this.submitForm}>
              <Button size='huge' loading={submittingForm} disabled={submittingForm} type='submit' className='grey' style={usingDocParser === 'Yes' && !isEditPage ? submitButtonStyle : submitButtonStyleNoDocParser}>
                {isEditPage ?  'Save Changes' : 'Submit '+orderType}
              </Button>
            </Form>
          </Transition>
        }

        <br/><br/>
        <Dimmer active={uploadingFile}>
          <Loader />
        </Dimmer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
 ...state
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(cadActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSalesOrderPage)
