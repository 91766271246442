import React, {Component} from "react"
import {
        Modal,
        Header,
        Icon,
        Select,
        Button,
        Popup
      }                   from 'semantic-ui-react'
import {Auth0Context}     from "./../../contexts/auth-context"
import CustomerAPI        from "./../../modules/api/customer_api"
import UserAPI            from "./../../modules/api/user_api"
import Utils              from "./../../modules/utils"

export class SwitchOrganization extends Component
{
  static contextType = Auth0Context;

  constructor(props)
  {
    super(props)
    this.state =
    {
      loading      : true,
      open         : false,
      organizations: [],
      selected     : null,
      changed      : false
    }
  }

  componentDidMount()
  {
    this.fetchAndShow()
  }

  fetchAndShow = (options={}) =>
  {
    var {organizations, selected, open} = this.state
    let callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        organizations = data.customers
        var heads = JSON.parse(sessionStorage.getItem('headers'))
        if (heads && heads.CustomerToken)
        {
          selected = heads.CustomerToken
        }
        open = options.show ? options.show : !selected

        this.setState({
          organizations,
          selected,
          open,
          loading: false
        })
      }
    }

    CustomerAPI.getAll(callback)
  }

  handleChange = (e, {value}) =>
  {
    var {selected} = this.state
    if (value)
    {
      selected = value
      Utils.setHttpHeaders({CustomerToken: value}, true)
      this.setState({selected, changed: true})
    }
  }

  handleSubmit = () =>
  {
    this.setState({open: false})
    if (!this.state.changed) return

    window.location.reload()

    // We should find a find to reload th react-app wrt to organization rather than reload location!

    // var {setCustomer, user, setUser} = this.context
    // UserAPI.getCurrentUser((error, data) => {
    //   if (error)
    //   {
    //     alert("alert in setting organization")
    //   }
    //   else
    //   {
    //     user = {...user, ...data}
    //     sessionStorage.setItem('user', JSON.stringify(user))
    //     setUser(user)
    //     setCustomer(user.customer)
    //   }
    // })
  }

  show = () =>
  {
    this.fetchAndShow({show: true})
  }

  render()
  {
    let {organizations, selected, open, loading} = this.state
    var orgs = Utils.objectsToOptionsHash([...organizations], 'id', 'name') || []

    var markup =
      <div>
        <Modal
          size='tiny'
          open={open}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          >
          <Modal.Header>
            <Icon name='building outline' /> Select Organization</Modal.Header>
          <Modal.Content>
            <Select
              fluid
              loading={loading}
              options={orgs}
              value={selected}
              onChange={this.handleChange}
              placeholder="Select Organization"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              disabled={!selected || loading}
              icon='checkmark'
              labelPosition='left'
              content='Done'
              onClick={this.handleSubmit}
            />
          </Modal.Actions>
        </Modal>

        <Popup
          content="Switch Organization"
          position="right center"
          size="tiny"
          trigger={<Button
                    primary
                    id="sticky-org-btn"
                    size="tiny"
                    icon='building outline'
                    onClick={this.show}
                  />}
        />
      </div>

    return markup
  }
}

export default SwitchOrganization
