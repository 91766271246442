import React, {Component} from 'react'
import {Icon, Popup} from 'semantic-ui-react'

let htmlStyle = {width: '350px', marginTop: '10px', paddingTop: '10px', borderTop: '1px solid rgb(220,220,220)'}
let stickyNoteStyle = {color: 'yellow', marginLeft: '10px', fontSize: '18px', textShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 3px'}

class NotePopup extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {header, myHtml} = this.props
    return (
      ["<p><br></p>", null, ''].indexOf(myHtml) == -1
      ?
      <Popup
        header={header}
        content={
          <div dangerouslySetInnerHTML={{__html: myHtml}} style={htmlStyle} />
        }
        trigger={
          <Icon name="sticky note" style={stickyNoteStyle} />
        }
      />
      :
      null
    )
  }
}

export default NotePopup
