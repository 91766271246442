import React from 'react'
import {Checkbox} from 'semantic-ui-react'

const checkbox = (label, id, setChecked, checked) => {
  return (
    <Checkbox label={label} onChange={(e, data) => setChecked(id, data.checked)} checked={checked}/>
  )
}

export {checkbox}
