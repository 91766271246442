import {getValueFromPrefillOrder} from './get-value-from-prefill-order'

const getComplete = (myProps, myState) => {
  let complete = true
  let {cadIds} = myProps
  let {quantity, size, sizeRatio, cost, msrp, qtyPerPolibag, exFactoryDate, cancelDate, delivDate, fabrication, at, country, casePack} = myState
  let {prefillOrders} = window.newVpoPage.styleNumbersTextarea

  cadIds.forEach(cadId => {
    if (complete && isNullOrIncludesNull(quantity[cadId])) {
      complete = false
    }

    if (complete && missingExtraSize(size[cadId])) {
      complete = false
    }

    if (complete && anyPrepackIsMissingSizeRatio(size[cadId], sizeRatio[cadId]) && anyPrepackIsMissingSizeRatio(size[cadId], getValueFromPrefillOrder(prefillOrders, cadId, 'size_ratio'))) {
      complete = false
    }

    if (complete && isNullOrEmptyString(cost[cadId])) {
      complete = false
    }

    if (complete && isNullOrEmptyString(at[cadId]) && isNullOrEmptyString(getValueFromPrefillOrder(prefillOrders, cadId, 'at'))) {
      complete = false
    }

    if (complete && isNullOrEmptyString(country[cadId])) {
      complete = false
    }

    if (complete && isNullOrEmptyString(msrp[cadId]) && isNullOrEmptyString(getValueFromPrefillOrder(prefillOrders, cadId, 'msrp'))) {
      complete = false
    }

    if (complete && isNullOrEmptyString(qtyPerPolibag[cadId]) && qtyPerPolibag[cadId] != 0 && isNullOrEmptyString(getValueFromPrefillOrder(prefillOrders, cadId, 'qty_per_polibag'))) {
      complete = false
    }

    if (complete && isNullOrEmptyString(casePack[cadId]) && isNullOrEmptyString(getValueFromPrefillOrder(prefillOrders, cadId, 'case_pack'))) {
      complete = false
    }

    if (complete && isNullOrEmptyString(exFactoryDate[cadId])) {
      complete = false
    }

    if (complete && isNullOrEmptyString(cancelDate[cadId])) {
      complete = false
    }

    if (complete && isNullOrEmptyString(delivDate[cadId])) {
      complete = false
    }

    if (complete && isNullOrEmptyString(fabrication[cadId]) && isNullOrEmptyString(getValueFromPrefillOrder(prefillOrders, cadId, 'fabrication'))) {
      complete = false
    }
  })

  return complete
}

const isNullOrIncludesNull = (value) => {
  return value == null || value.includes(null)
}

const isNullOrEmptyString = (value) => {
  return value == null || value == ''
}

const missingExtraSize = (size) => {
  if (size == null) {
    return false
  } else {
    return size.includes(null)
  }
}

const anyPrepackIsMissingSizeRatio = (size, sizeRatio) => {
  if (size == null && sizeRatio == null) {
    return true
  }

  let result = false

  if (size == null) {
    size = ["PREPACK"]
  }

  size.forEach((row, index) => {
    if (!result && row == "PREPACK" && sizeRatio[index] == null) {
      result = true
    }
  })

  return result
}

export {getComplete, isNullOrEmptyString}
