const isUserRole = (user) => {
  let isWarehouse = user && user.role == 'warehouse'
  let isInventory = user && user.role == 'inventory'
  let isProduction = user && user.role == 'production'
  let isSalesAssistant = user && user.role == 'sales_assistant'
  let isAdmin = user && (user.role == 'admin' || user.role == 'superadmin')
  let isSales = user && user.role == 'sales'
  let isCustomer = user && user.role == 'customer'

  return {isWarehouse, isInventory, isProduction, isSalesAssistant, isAdmin, isSales, isCustomer}
}

export default isUserRole
