import React, {Component}from 'react'
import {
          Segment,
          Form,
          Grid,
          Checkbox,
          Button
       }                 from 'semantic-ui-react'
import Utils             from './../../../modules/utils'
import Constants         from './../../../modules/constants'
import {DatePickerField} from './../../ui/date-picker.js'
import {Auth0Context}    from './../../../contexts/auth-context'
import _                 from 'lodash'
import $ from 'jquery'

import {upfrontOptions, ticketedOptions, marginsOptions, monthsOptions} from './options-list.js'

class POFiltration extends Component
{
  static contextType = Auth0Context

  constructor(props)
  {
    super(props)
    this.state =
    {
      loading   : true,
      statuses  : [],
      orderTypes: [],
      regions   : [],
      brands    : [],
      categories: [],
      packs: [],
      divisions: [],
      subcategories: [],
      dateTypes : [],
      dateRanges: [],
      reps      : [],
      accounts  : [],
      companies : [],
      accountOptions: [],
      inputs    : this.getInitialState()
    }

    this.setInitialState = this.setInitialState.bind(this)
  }

  getInitialState()
  {
    let role = sessionStorage.getItem('currentUserRole')
    return {
      status   : 'Show All',
      orderType: (['sales','sales_assistant'].includes(role) ? ['PO','Hold','Worksheet'] : 'Show All'),
      region   : 'Show All',
      category : 'Show All',
      ticketed: 'Show All',
      upfront: 'Show All',
      margins: 'Show All',
      pack: 'Show All',
      division: 'Show All',
      subcategory: 'Show All',
      month: 'Show All',
      brand    : 'Show All',
      rep      : 'Show All',
      account  : 'Show All',
      contact  : 'Show All',
      dateType : 'Show All',
      dateRange: 'Show All',
      dateFrom : null,
      dateTo   : null,
      newFirst : false
    }
  }

  getDefaultState()
  {
    return {
      id  : 'Show All',
      name: 'Show All'
    }
  }

  setInitialState() {
    var _default = this.getDefaultState()
    var {user, customer, purchaseOrderFilters} = this.context
    var {inputs, statuses, orderTypes, regions, brands, categories, dateTypes, dateRanges, accounts, reps, companies,
        packs, divisions, subcategories
        } = this.state

    if (customer == null) {
      setTimeout(() => {
        this.setInitialState()
      }, 500)
    } else {

      if (user && user.role === 'warehouse') {
        statuses = Utils.arrayToOptionsHashUnsorted([_default.id, ...Constants.PO_STATUSES_WAREHOUSE])
      } else {
        //statuses = Utils.arrayToOptionsHashUnsorted([_default.id, ...Constants.PO_STATUSES])
        statuses = Constants.PO_STATUSES
      }

      orderTypes = Utils.arrayToOptionsHashUnsorted([_default.id, ...Constants.ORDER_TYPES])
      regions = Utils.arrayToOptionsHash([_default.id, ...Constants.REGIONS])
      // dateTypes = Utils.arrayToOptionsHash([_default.id, ...Constants.PO_DATE_TYPES])
      // dateRanges = Utils.arrayToOptionsHash([_default.id, ...Constants.PO_DATE_RANGES])
      categories = Utils.objectsToOptionsHash([_default, ...customer.categories], 'id', 'name')
      brands = Utils.objectsToOptionsHash([_default, ...customer.brands], 'id', 'name')
      reps = Utils.objectsToOptionsHash([_default, ...customer.distinctReps], 'id', 'name')
        .sort(function(a, b) {
          if (a.text == 'Show All') {
            return -1
          }
          if (b.text == 'Show All') {
            return 1
          }
          if (a.text.toLowerCase() > b.text.toLowerCase()) {
            return 1
          }
          if (a.text.toLowerCase() < b.text.toLowerCase()) {
            return -1
          }

          return 0
        })


      accounts = Utils.objectsToOptionsHash([_default, ...customer.distinctAccounts], 'id', 'name', (account) => account.mainContactPersonName ? `${account.name} (${account.mainContactPersonName})` : account.name)
      companies = Utils.objectsToOptionsHash([_default, ...customer.companies], 'id', 'name')

      packs = Utils.objectsToOptionsHash([_default, ...customer.packs], 'id', 'name')
      divisions = Utils.objectsToOptionsHash([_default, ...customer.divisions], 'id', 'name')
      subcategories = Utils.objectsToOptionsHash([_default, ...customer.subcategories], 'id', 'name')

      categories = categories.optionsHashTextAsKey()
      brands = brands.optionsHashTextAsKey()
      packs = packs.optionsHashTextAsKey()
      divisions = divisions.optionsHashTextAsKey()
      subcategories = subcategories.optionsHashTextAsKey()




      categories.push({key: "Missing", text: "Missing", value: "Missing"})
      brands.push({key: "Missing", text: "Missing", value: "Missing"})
      packs.push({key: "Missing", text: "Missing", value: "Missing"})
      divisions.push({key: "Missing", text: "Missing", value: "Missing"})
      subcategories.push({key: "Missing", text: "Missing", value: "Missing"})

      if (!_.isEmpty(purchaseOrderFilters))
      {
        inputs = _.cloneDeep(purchaseOrderFilters)
      }

      let accountOptions = accounts.filter((x) => { return this.props.accountIds.indexOf(x.key) > -1 || "Show All" == x.key }).sort((a, b) => {
        if (a.text == "Show All") return -1
        if (b.text == "Show All") return 1
        if (a.text > b.text) return 1
        if (b.text > a.text) return -1

        return 0;
      })

      this.setState({
        loading: false,
        inputs,
        statuses,
        orderTypes,
        regions,
        brands,
        categories,
        dateTypes,
        dateRanges,
        accounts,
        reps,
        companies,
        accountOptions,
        packs, divisions, subcategories
      })
    }
  }

  componentDidMount()
  {
    this.setInitialState()
    this.clearFilters()
  }

  onSubmit = (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
  }

  clearFilters = (e) =>
  {
    if (e == null) {

    } else {
      e.preventDefault()
      e.stopPropagation()
    }

    let {setPurchaseOrderFilters} = this.context
    let {inputs} = this.state

    inputs = this.getInitialState()
    window.inputs = inputs
    setPurchaseOrderFilters(inputs)

    this.setState({inputs})

    $('#multi-search-textarea').val('')

    //turn off missing cad filter
    let turnOffMissingCadEvent = new Event('turnOffMissingCadEvent')
    window.dispatchEvent(turnOffMissingCadEvent)

    setTimeout(this.props.filterPurchaseOrder, 0)
  }

  handleChange = (e, data) =>
  {
    let {setPurchaseOrderFilters} = this.context
    let {name, value} = data
    let {inputs} = this.state

    if (name === 'newFirst')
    {
      value = data.checked
    }

    if ((["status",'ticketed','upfront',"margins","category","brand","pack","division","subcategory","month","orderType"].indexOf(name) > -1) && (value.indexOf('Show All') > -1 || value.length == 0)) {
      value = "Show All"
    }

    inputs[name] = value
    setPurchaseOrderFilters(inputs)
    window.inputs = inputs

    this.props.filterPurchaseOrder(inputs)
    this.setState({inputs})
  }

  render()
  {
    let {loading, inputs, statuses, orderTypes, regions, brands, categories, dateTypes, dateRanges, reps, accounts, companies,
      packs, divisions, subcategories, accountOptions
    } = this.state

    let role = sessionStorage.getItem('currentUserRole')

    var markup =
      !loading ?
      <Segment basic style={{zIndex: 101, position: 'relative'}}>
        <Form onSubmit={this.onSubmit}>
          <Form.Group widths='equal'>
            <Form.Select
              id="po-status-select"
              fluid
              search
              name='status'
              label='Status'
              onChange={this.handleChange}
              options={statuses}
              placeholder='Select Status'
              value={inputs.status}
            />
            <Form.Select
              id="order-type-select"
              fluid
              search
              multiple
              name='orderType'
              label='Order type'
              onChange={this.handleChange}
              options={orderTypes}
              placeholder='Select order type'
              value={inputs.orderType}
            />
            <Form.Select
              id="region-select"
              fluid
              search
              name='region'
              label='Region'
              onChange={this.handleChange}
              options={regions}
              placeholder='Select Region'
              value={inputs.region}
            />
            <Form.Select
              id="category-select"
              fluid
              search
              name='category'
              label='Category'
              onChange={this.handleChange}
              options={categories}
              placeholder='Select Category'
              value={inputs.category}
              multiple
            />
            {role == 'admin' || role == 'superadmin' || role == 'inventory' ?
              <Form.Select
                id="rep-select"
                placeholder='Select Rep'
                fluid
                search
                name='rep'
                label='Rep'
                onChange={this.handleChange}
                options={reps}
                value={inputs.rep}
              />
            : null}
            <Form.Select
              id="brand-select"
              fluid
              search
              name='brand'
              label='Brand'
              onChange={this.handleChange}
              options={brands}
              placeholder='Select Brand'
              value={inputs.brand}
              multiple
            />
            <DatePickerField
              label="Date From"
              name='dateFrom'
              placeholderText='mm/dd/yyyy'
              selected={inputs.dateFrom || null}
              isClearable
              onChange={(data, e) => this.handleChange(e, {name: 'dateFrom', value: data})}
            />
            <DatePickerField
              label="Date To"
              name='dateTo'
              placeholderText='mm/dd/yyyy'
              selected={inputs.dateTo || null}
              isClearable
              onChange={(data, e) => this.handleChange(e, {name: 'dateTo', value: data})}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Select
              id="ticketed-select"
              fluid
              search
              name='ticketed'
              label='Tickets'
              onChange={this.handleChange}
              options={ticketedOptions}
              placeholder='Select Ticketed'
              value={inputs.ticketed}
              multiple
            />
            <Form.Select
              id="margins-select"
              fluid
              search
              name='margins'
              label='Margins'
              onChange={this.handleChange}
              options={marginsOptions}
              placeholder='Select Margins'
              value={inputs.margins}
              multiple
            />
            <Form.Select
              id="packs-select"
              fluid
              search
              name='pack'
              label='Packs'
              onChange={this.handleChange}
              options={packs}
              placeholder='Select Packs'
              value={inputs.pack}
              multiple
            />
            <Form.Select
              id="division-select"
              fluid
              search
              name='division'
              label='Division'
              onChange={this.handleChange}
              options={divisions}
              placeholder='Select Division'
              value={inputs.division}
              multiple
            />
            <Form.Select
              id="subcategory-select"
              fluid
              search
              name='subcategory'
              label='Sub-Category'
              onChange={this.handleChange}
              options={subcategories}
              placeholder='Select Sub-Category'
              value={inputs.subcategory}
              multiple
            />
            <Form.Select
              id="month-select"
              fluid
              search
              name='month'
              label='Month'
              onChange={this.handleChange}
              options={monthsOptions}
              placeholder='Select Month'
              value={inputs.month}
              multiple
            />
            <Form.Select
              id="contact-select"
              fluid
              search
              name='contact'
              placeholder='Select Contact'
              label='Contact'
              onChange={this.handleChange}
              options={accountOptions}
              value={inputs.contact}
              search
            />
          </Form.Group>
          <Form.Group widths={7}>
            <Form.Select
              id="upfront-select"
              fluid
              search
              name='upfront'
              label='Upfront'
              onChange={this.handleChange}
              options={upfrontOptions}
              placeholder='Select Upfront'
              value={inputs.upfront}
              multiple
            />
          </Form.Group>
          <div style={{textAlign: 'right', marginBottom: '-16px', paddingTop: '10px'}}>
            <a onClick={this.clearFilters} style={{cursor: 'pointer'}} className="cads-reset-filters">
              Reset Filters
            </a>
          </div>
        </Form>
      </Segment>
      :
      null

    return markup;
  }
}

export default POFiltration
