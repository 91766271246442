import React, { Component } from 'react'
import {Transition, Grid} from 'semantic-ui-react'
import VendorOrdersDropdown from '../vendor-orders/vendor-orders-dropdown.js'
import {buildingTypeOptionsShort, sourceOptionsShort, minRoomsInitial, maxRoomsInitial, minBuildingSizeInitial, maxBuildingSizeInitial} from './options'
import 'rheostat/initialize'
import Rheostat from 'rheostat'
import DisplayMinMax from '../cads/display-min-max.js'
import $ from 'jquery'
import {numberWithCommas} from './number-with-commas'

const sliderTitleStyle = {float: 'left', position: 'relative', bottom: '38px'}

const addTotal = (options, totals) => {
  if (totals == null) return options

  return options.map((x) => {
    let totalText = totals[x.value] == null ? '' : ' ('+totals[x.value]+')'
    return {key: x.key, text: x.text+totalText, value: x.value, icon: x.icon}
  })
}

const roundToThousand = (value) => {
  return Math.round(value/1000)*1000
}

const roundToTen = (value) => {
  return Math.round(value/10)*10
}

class PropertyFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.updateBuildingSizeValues = this.updateBuildingSizeValues.bind(this)
    this.updateRoomsValues = this.updateRoomsValues.bind(this)
  }

  updateBuildingSizeValues(sliderState) {
    let {mySetState} = this.props

    let currentMinBuildingSize = sliderState.values[0]
    let currentMaxBuildingSize = sliderState.values[1]

    let minBuildingSize = roundToThousand(sliderState.values[0])
    let maxBuildingSize = roundToThousand(sliderState.values[1])

    if (minBuildingSize == 0) {
      minBuildingSize = 1
    }

    $('#sqft-min').text(numberWithCommas(minBuildingSize))
    $('#sqft-max').text(numberWithCommas(maxBuildingSize)+(maxBuildingSize == maxBuildingSizeInitial ? '+' : ''))

    mySetState({currentMinBuildingSize, currentMaxBuildingSize, minBuildingSize, maxBuildingSize})
  }

  updateRoomsValues(sliderState) {
    let {mySetState} = this.props

    let currentMinRooms = sliderState.values[0]
    let currentMaxRooms = sliderState.values[1]

    let minRooms = roundToTen(sliderState.values[0])
    let maxRooms = roundToTen(sliderState.values[1])

    if (minRooms == 0) {
      minRooms = 1
    }

    $('#rooms-min').text(numberWithCommas(minRooms))
    $('#rooms-max').text(numberWithCommas(maxRooms)+(maxRooms == maxRoomsInitial ? '+' : ''))

    mySetState({currentMinRooms, currentMaxRooms, minRooms, maxRooms})
  }

  render() {
    let {showFilters, fieldOnChange, buildingType, source, clearFilters, totals, usingFilters,
      currentMinRooms, currentMaxRooms, currentMinBuildingSize, currentMaxBuildingSize} = this.props

    return (
      <Transition visible={showFilters} animation='fade' duration={showFilters ? 1000 : 500}>
        <div style={{marginTop: '20px'}}>
          <Grid columns={5}>
            <VendorOrdersDropdown
              placeholder='Building Type'
              name='buildingType'
              options={addTotal(buildingTypeOptionsShort, totals['buildingType'])}
              onChange={fieldOnChange}
              value={buildingType}
              loading={false}
              style={{marginBottom: '23px'}}
            />

            <VendorOrdersDropdown
              placeholder='Source'
              name='source'
              options={addTotal(sourceOptionsShort, totals['source'])}
              onChange={fieldOnChange}
              value={source}
              loading={false}
              style={{marginBottom: '23px'}}
            />

            <Grid.Column style={{whiteSpace: 'nowrap', paddingLeft: '20px'}}>
              <div style={sliderTitleStyle}>
                Building Size
              </div>
              <Rheostat
                min={minBuildingSizeInitial}
                max={maxBuildingSizeInitial}
                values={[ currentMinBuildingSize, currentMaxBuildingSize]}
                onValuesUpdated={this.updateBuildingSizeValues}
              />
              <DisplayMinMax name='sqft' minInitialValue={minBuildingSizeInitial} maxInitialValue={maxBuildingSizeInitial+'+'}/>
            </Grid.Column>
            <Grid.Column style={{whiteSpace: 'nowrap', paddingLeft: '20px'}}>
              <div style={sliderTitleStyle}>
                Rooms #
              </div>
              <Rheostat
                min={minRoomsInitial}
                max={maxRoomsInitial}
                values={[ currentMinRooms, currentMaxRooms]}
                onValuesUpdated={this.updateRoomsValues}
              />
              <DisplayMinMax name='rooms' minInitialValue={minRoomsInitial} maxInitialValue={maxRoomsInitial+'+'}/>
            </Grid.Column>

            {
              usingFilters()
              ?
              <Grid.Column style={{textAlign: 'right'}}>
                <a onClick={clearFilters} className="cads-reset-filters">Reset filters</a>
              </Grid.Column>
              :
              null
            }

          </Grid>
        </div>
      </Transition>
    )
  }
}

export default PropertyFilters
