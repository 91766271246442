import $ from 'jquery'
import compareMultiSearchIndex from './compare.js'
import {isCustomer} from './is-role.js'

const removeFromArray = (arr, valToRemove) => {
  for( var i = 0; i < arr.length; i++){
     if ( arr[i] === valToRemove) {
       arr.splice(i, 1);
     }
  }
}

const getExportShowList = (myProps) => {
  let role = sessionStorage.getItem('currentUserRole');

  let isShowroom = role == 'showroom';
  // let isDdsAshley = role == 'salesAccount';
  // let isSalesAccount2 = role == 'salesAccount2';
  let isDesign = role == 'design';

  var showList = [];

  if (myProps.cadReducer.showATS){
    showList.push("ATS")
  }
  if (myProps.cadReducer.showOnHand){
    showList.push("On Hand")
  }
  if (myProps.cadReducer.showFACTORYRTS){
    showList.push("RTS")
  }
  if (myProps.cadReducer.showBrand){
    showList.push("Brand")
  }
  if (myProps.cadReducer.showDivision){
    showList.push("Division")
  }
  if (myProps.cadReducer.showCategory){
    showList.push("Category")
  }
  if (myProps.cadReducer.showSubCategory){
    showList.push("Sub-Category")
  }
  if (myProps.cadReducer.showColor){
    showList.push("Color")
  }
  if (myProps.cadReducer.showFabrication){
    showList.push("Fabrication")
  }
  if (myProps.cadReducer.showCasePack){
    showList.push("Case Pack")
  }
  if (myProps.cadReducer.showCountryofOrigin){
    showList.push("Country")
  }
  if (myProps.cadReducer.showPacks){
    showList.push("Packs")
  }
  if (myProps.cadReducer.showDescription){
    showList.push("Description")
  }
  if (myProps.cadReducer.showFutureATS){
    showList.push("Future ATS")
  }
  if (myProps.cadReducer.showFutureStock){
    showList.push("Future Stock")
  }
  if (myProps.cadReducer.showHold){
    showList.push("Hold")
  }
  if (myProps.cadReducer.showMSRP){
    showList.push("MSRP")
  }
  if (myProps.cadReducer.showPage){
    showList.push("Page")
  }
  if (myProps.cadReducer.showPO){
    showList.push("Sales Order")
  }
  if (myProps.cadReducer.showSeason){
    showList.push("Season")
  }
  if (myProps.cadReducer.showShipped){
    showList.push("Shipped")
  }
  if (myProps.cadReducer.showSizeBreakdown){
    showList.push("Size Breakdown")
  }
  if (myProps.cadReducer.showWholesale){
    showList.push("Wholesale")
  }
  if (myProps.cadReducer.showWorksheet){
    showList.push("Worksheet")
  }
  if (myProps.cadReducer.showWarehouse){
    showList.push("Warehouse")
  }
  if (myProps.cadReducer.showContainerNumber){
    showList.push("ContainerNumber")
  }
  if (myProps.cadReducer.showExFactory){
    showList.push("ExFactory")
  }
  if (myProps.cadReducer.showUPC){
    showList.push("UPC")
  }
  if (myProps.cadReducer.showLDPCOST){
    showList.push("LDPCOST")
  }

  //quick fix for that filters are rememebered if switch user on same computer!?!?!
  if (isShowroom || isDesign) {
    removeFromArray(showList, 'Shipped');
    removeFromArray(showList, 'Sales Order');
    removeFromArray(showList, 'Hold');
    removeFromArray(showList, 'Worksheet');
  }

  if (isShowroom ) {//|| isDdsAshley || isSalesAccount2
    removeFromArray(showList, 'Wholesale');
    removeFromArray(showList, 'MSRP');
    removeFromArray(showList, 'Size Breakdown');
    removeFromArray(showList, 'Page');
  }

  return showList;
}



const accountFlagNumbers = (keys, exportType) => {
  let result = '';

  let shippedNumbers = [];
  let shippedPonumbers = [];
  let shippedStartship = [];
  let shippedAccount = [];
  let shippedPrice = [];
  let salesOrderNumbers = [];
  let salesOrderPonumbers = [];
  let salesOrderStartship = [];
  let salesOrderAccount = [];
  let salesOrderPrice = [];
  let worksheetNumbers = [];
  let worksheetPonumbers = [];
  let worksheetStartship = [];
  let worksheetAccount = [];
  let worksheetPrice = [];
  let holdNumbers = [];
  let holdPonumbers = [];
  let holdStartship = [];
  let holdAccount = [];
  let holdPrice = [];

  for (var i=0; i<keys.length; i++) {
    let cadId = keys[i];

    if (exportType == 'excel') {

      if (!!window.shipped_total[cadId]) {
        shippedNumbers.push(window.shipped_total[cadId]);
      } else {
        shippedNumbers.push(0);
      }

      if (!!window.shipped_ponumbers[cadId]) {
        shippedPonumbers.push(window.shipped_ponumbers[cadId]);
      } else {
        shippedPonumbers.push('no data');
      }

      if (!!window.shipped_startship[cadId]) {
        shippedStartship.push(window.shipped_startship[cadId]);
      } else {
        shippedStartship.push('no data');
      }

      if (!!window.shipped_account[cadId]) {
        shippedAccount.push(window.shipped_account[cadId]);
      } else {
        shippedAccount.push('no data');
      }

      if (!!window.shipped_price[cadId]) {
        shippedPrice.push(window.shipped_price[cadId]);
      } else {
        shippedPrice.push('no data');
      }

      if (!!window.sales_order_total[cadId]) {
        salesOrderNumbers.push(window.sales_order_total[cadId]);
      } else {
        salesOrderNumbers.push(0);
      }

      if (!!window.sales_order_ponumbers[cadId]) {
        salesOrderPonumbers.push(window.sales_order_ponumbers[cadId]);
      } else {
        salesOrderPonumbers.push('no data');
      }

      if (!!window.sales_order_startship[cadId]) {
        salesOrderStartship.push(window.sales_order_startship[cadId]);
      } else {
        salesOrderStartship.push('no data');
      }

      if (!!window.sales_order_account[cadId]) {
        salesOrderAccount.push(window.sales_order_account[cadId]);
      } else {
        salesOrderAccount.push('no data');
      }

      if (!!window.sales_order_price[cadId]) {
        salesOrderPrice.push(window.sales_order_price[cadId]);
      } else {
        salesOrderPrice.push('no data');
      }

      if (!!window.worksheet_total[cadId]) {
        worksheetNumbers.push(window.worksheet_total[cadId]);
      } else {
        worksheetNumbers.push(0);
      }

      if (!!window.worksheet_ponumbers[cadId]) {
        worksheetPonumbers.push(window.worksheet_ponumbers[cadId]);
      } else {
        worksheetPonumbers.push('no data');
      }

      if (!!window.worksheet_startship[cadId]) {
        worksheetStartship.push(window.worksheet_startship[cadId]);
      } else {
        worksheetStartship.push('no data');
      }

      if (!!window.worksheet_account[cadId]) {
        worksheetAccount.push(window.worksheet_account[cadId]);
      } else {
        worksheetAccount.push('no data');
      }

      if (!!window.worksheet_price[cadId]) {
        worksheetPrice.push(window.worksheet_price[cadId]);
      } else {
        worksheetPrice.push('no data');
      }

      if (!!window.hold_total[cadId]) {
        holdNumbers.push(window.hold_total[cadId]);
      } else {
        holdNumbers.push(0);
      }

      if (!!window.hold_ponumbers[cadId]) {
        holdPonumbers.push(window.hold_ponumbers[cadId]);
      } else {
        holdPonumbers.push('no data');
      }

      if (!!window.hold_startship[cadId]) {
        holdStartship.push(window.hold_startship[cadId]);
      } else {
        holdStartship.push('no data');
      }

      if (!!window.hold_account[cadId]) {
        holdAccount.push(window.hold_account[cadId]);
      } else {
        holdAccount.push('no data');
      }

      if (!!window.hold_price[cadId]) {
        holdPrice.push(window.hold_price[cadId]);
      } else {
        holdPrice.push('no data');
      }



    } else {
      if (!!window.shipped_export_rows[cadId]) {
        shippedNumbers.push(window.shipped_export_rows[cadId].join('|'));
      } else {
        shippedNumbers.push('no data');
      }

      if (!!window.sales_order_export_rows[cadId]) {
        salesOrderNumbers.push(window.sales_order_export_rows[cadId].join('|'));
      } else {
        salesOrderNumbers.push('no data');
      }

      if (!!window.worksheet_export_rows[cadId]) {
        worksheetNumbers.push(window.worksheet_export_rows[cadId].join('|'));
      } else {
        worksheetNumbers.push('no data');
      }

      if (!!window.hold_export_rows[cadId]) {
        holdNumbers.push(window.hold_export_rows[cadId].join('|'));
      } else {
        holdNumbers.push('no data');
      }

    }
  }

  result += "&&&shipped=" + shippedNumbers.join('@@@');
  result += "&&&shipped_ponumber=" + shippedPonumbers.join('@@@');
  result += "&&&shipped_startship=" + shippedStartship.join('@@@');
  result += "&&&shipped_account=" + shippedAccount.join('@@@');

  result += "&&&sales_order=" + salesOrderNumbers.join('@@@');
  result += "&&&sales_order_ponumber=" + salesOrderPonumbers.join('@@@');
  result += "&&&sales_order_startship=" + salesOrderStartship.join('@@@');
  result += "&&&sales_order_account=" + salesOrderAccount.join('@@@');

  result += "&&&worksheet=" + worksheetNumbers.join('@@@');
  result += "&&&worksheet_ponumber=" + worksheetPonumbers.join('@@@');
  result += "&&&worksheet_startship=" + worksheetStartship.join('@@@');
  result += "&&&worksheet_account=" + worksheetAccount.join('@@@');

  result += "&&&hold=" + holdNumbers.join('@@@');
  result += "&&&hold_ponumber=" + holdPonumbers.join('@@@');
  result += "&&&hold_startship=" + holdStartship.join('@@@');
  result += "&&&hold_account=" + holdAccount.join('@@@');

  result += "&&&shipped_price=" + shippedPrice.join('@@@');
  result += "&&&sales_order_price=" + salesOrderPrice.join('@@@');
  result += "&&&worksheet_price=" + worksheetPrice.join('@@@');
  result += "&&&hold_price=" + holdPrice.join('@@@');

  let url = process.env.REACT_APP_API_URL + '/api/save_account_flag_numbers';
  let data = {result: result};
  let account_numbers_id = null;
  let success = function (data2) {
    account_numbers_id = data2.account_numbers_id;
  }
  $.ajax({
    type: 'POST',
    url: url,
    data: data,
    success: success,
    //dataType: dataType,
    async:false
  })

  return '&account_numbers_id=' + account_numbers_id
}


const getPdfUrl = (myState, myProps, cadsPerPage, coverPageTitle, coverPageSubTitle) => {
  let showList = getExportShowList(myProps)

  var keys = Object.keys(window.cadIds);
  var sortKeysParams = '';
  if (myState.multiSearch2 != '') {
    keys = keys.sort(compareMultiSearchIndex)
    sortKeysParams = '&sortKeys=True'
  }
  var keysJoin = keys.join(',');

  var pdfUrl = process.env.REACT_APP_API_URL + '/download_cads_pdf?ids=' + keysJoin + '&show=' + showList.join(',') + '&cover_text=' + encodeURIComponent(coverPageTitle) + '&cover_subtitle=' + encodeURIComponent(coverPageSubTitle) + sortKeysParams + '';

  if (cadsPerPage == "One") {
    pdfUrl += "&one_per_page=true";
  } else if (cadsPerPage == "Two") {
    pdfUrl += "&two_per_page=true";
  } else if (cadsPerPage == "Six") {
    pdfUrl += "&three_per_page=true";
  }

  // if (myState.salesHistoryAccount != '') {
  //   pdfUrl += "&account_flag=true";
  //
    if (myProps.cadReducer.defaultAccount != null) {
      pdfUrl += ("&account_name=" + myProps.cadReducer.defaultAccount);
    }
  //   pdfUrl += ("&date_from=" + myProps.cadReducer.dateFrom);
  //   pdfUrl += ("&date_to=" + myProps.cadReducer.dateTo);

    pdfUrl += accountFlagNumbers(keys, 'pdf');

  // } else if (myProps.cadReducer.defaultAccount != null) {
  //   pdfUrl += "&default_account_flag=true";
  //   pdfUrl += accountFlagNumbers(keys, 'excel');
  // }

  if (isCustomer()) {
    pdfUrl += "&is_customer=true"
  }

  return pdfUrl
}

const getExcelUrl = (myState, myProps, orgId) => {
  let role = sessionStorage.getItem('currentUserRole')

  let {cadStatus} = myProps.cadReducer
  let {exportNoImages} = myState
  let keys = Object.keys(window.cadIds)

  if (cadStatus == 'No Image w/ Future Stock') {
    exportNoImages = true
  }

  if (!exportNoImages && keys.length == 0) {
    alert('Select at least 1 CAD, or select "Export with NO images" to export all CADs')
    return;
  } else if (!exportNoImages && keys.length > 200) {
    alert('You have selected ' + keys.length + ' Cads. Limit is 200 Cads!')
    return;
  }

  if (exportNoImages && keys.length == 0) {
    let cadsToFilter = window.cadsToSearch.slice(0, 1000)

    if (cadsToFilter.length == 0) {
      alert("Can't find any CADs to export");
      return;
    }

    keys = [];

    for (var i=0; i<cadsToFilter.length; i++) {
      let cadId = cadsToFilter[i].id;
      keys.push(cadId);
    }
  }

  let showList = getExportShowList(myProps)

  var sortKeysParams = '';
  if (myState.multiSearch2 != '') {
    keys = keys.sort(compareMultiSearchIndex);
    sortKeysParams = '&sortKeys=True';
  }
  var keysJoin = keys.join(',');
  var excelUrl = process.env.REACT_APP_API_URL + '/create_cads_excel?role=' + role + '&ids=' + keysJoin + '&show=' + showList.join(',') + sortKeysParams + ''
  if (myState.salesHistoryAccount != '') {
    excelUrl += "&account_flag=true";
  } else if (myProps.cadReducer.defaultAccount != null) {
    excelUrl += "&default_account_flag=true";
  }
  excelUrl += accountFlagNumbers(keys, 'excel');

  if (myProps.cadReducer.futureAtsMonths.length > 0) {
    let monthsJoin = myProps.cadReducer.futureAtsMonths.join(',')
    excelUrl += '&future_ats_months=' + monthsJoin
  }

  if (myState.exportByMonth) {
    excelUrl += "&export_by_month=true";
  }

  if (myState.exportByBrand) {
    excelUrl += "&export_by_brand=true";
  }

  if (exportNoImages && myState.salesHistoryAccount == '') {
    excelUrl += "&export_no_images=true";
  }

  if (!myProps.cadReducer.showFutureATS && !myProps.cadReducer.showFutureStock) {
    excelUrl += "&immediate_only=true";
  }

  if (myState.hideUnitsUnder && (myProps.cadReducer.showATS || myProps.cadReducer.showFutureStock || myProps.cadReducer.showFutureATS)) {
    excelUrl += "&hide_units_under=true&max_value="+$('#hide-units-under').val()
  }

  if (myState.exportMargins) {
    excelUrl += "&margins=true"
  }

  if (['Brand Approval Requested', 'Brand Approved', 'Brand Rejected'].includes(myProps.cadReducer.cadStatus)) {
    excelUrl += "&brand_approval=true"
  }

  if (isCustomer()) {
    excelUrl += "&is_customer=true"
  }

  if (myProps.cadReducer.warehouse.length > 0) {
    excelUrl += "&warehouse="+myProps.cadReducer.warehouse.join(',')
  }

  excelUrl += "&org_id="+orgId

  return excelUrl
}

export {getPdfUrl, getExcelUrl}
