import $ from 'jquery'
import Utils from "../../modules/utils"

const getNonPrepack = (results) => {
  let {vpoIds} = window
  let vpos = results.filter(vpo => !!vpoIds[vpo.id])

  let nonPrepackSizes = vpos.some(vpo => vpo.non_prepack_sizes)
  let nonPrepackRefnumber = (nonPrepackSizes ? vpos.find(vpo => vpo.non_prepack_sizes).refnumber : '')

  return {nonPrepackSizes, nonPrepackRefnumber}
}

const saveBulkEdit = (onlyAsnXfactoryDateInput) => {
  let data = getBulkData()
  callApi(data, onlyAsnXfactoryDateInput)
}

const getBulkData = () => {
  let {bulkEditVpoModal, vpoIds} = window
  return {bulkEditVpoModal, vpoIds}
}

const callApi = (data, onlyAsnXfactoryDateInput) => {
  const url = 'bulk_update_vpos'

  const callbackFunc = (err, result) => {
    if (err) {
      alert('There was an error when trying to bulk update')
    } else {
      if (result.data.success) {
        $('#save-bulk-button').removeClass('loading disabled')
        sendBulkModalCloseEvent()
        updateReactState()
        setTimeout(() => alert(onlyAsnXfactoryDateInput ? 'The ASN info was saved!' : 'The bulk changes were saved!'), 10)
      }
    }
  }

  Utils.post(url, data, callbackFunc)
}

const updateReactState = () => {

}

const sendBulkModalCloseEvent = () => {
  window.dispatchEvent(new Event('bulk-vpo-modal-close'))
}

export {saveBulkEdit, getNonPrepack}
