import React, {Component} from 'react'
import {Dimmer, Loader, Table, Dropdown, Button, Icon, Input} from 'semantic-ui-react'
import $ from 'jquery'
import './EditContactPage.css'


class EditContactPage extends Component {

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>

        helloworld
      </div>
    )
  }
}

export default EditContactPage
