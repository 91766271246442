import React          from 'react';
import PropTypes      from 'prop-types';
import { Form }       from 'semantic-ui-react';
import {Auth0Context} from ".././../../../contexts/auth-context"
import Constants      from '../../../../modules/constants'
import Utils          from '../../../../modules/utils'
import CompanyAPI     from '../../../../modules/api/company_api'
import UserAPI        from '../../../../modules/api/user_api'

export class FilterRow extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.state = {
      companyOptions: [],
      repOptions: [],
      fetchingCompanies: true,
      fetchingReps: true,
      filter: {...this.props.initialFilterState}
    };
  }

  componentDidMount() {
    this._ismounted = true;
    this.getCompanies();
    this.fetchReps();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  setMyState = (state, cb) => {
    if (this._ismounted){
      this.setState(state, cb)
    }
  }

  getCompanies = () => {
    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        var keys       = {text: ['name'], value: 'name'}
        let companyOptions = Utils.toOptionsHashWithKeys(data.companies, keys)
        this.setMyState({ fetchingCompanies: false, companyOptions })
      }
    }
    CompanyAPI.getAll(callback)
  }

  fetchReps = () =>
  {
    var {repOptions} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        var keys       = {text: ['name'], value: 'name'}
        repOptions     =  Utils.toOptionsHashWithKeys(data.reps, keys)
        this.setMyState({ fetchingReps: false, repOptions})
      }
    }

    UserAPI.getAllReps(callback)
  }

  resetFilter = () => {
    this.setMyState({ filter: {...this.props.initialFilterState } }, this.applyFilters )
  };

  applyFilters = () => {
    this.props.applyRowFilters(this.state.filter)
  };

  handleChange = (event, { name, value }) => {
    let {filter} = this.state;
    filter[name] = value;
    this.setMyState({ filter: {...filter } }, this.applyFilters )
  };

  render() {
    const { filter, companyOptions, repOptions, fetchingCompanies, fetchingReps } = this.state;

    const {user, customer} = this.context;
    const categories = customer ? Utils.objectsToOptionsHash([...customer.categories], 'name', 'name') : []
    const subcategories = customer ? Utils.objectsToOptionsHash([...customer.subcategories], 'name', 'name') : []
    const divisions = customer ? Utils.objectsToOptionsHash([...customer.divisions], 'name', 'name') : []

    let selectAll = [{key: 'all', text: "Select All", value: 'all'}]

    return (
      <Form className='filter-row'>
        <Form.Group widths='equal'>
          <Form.Select
            fluid
            name='account'
            label='Account'
            onChange={this.handleChange}
            options={[...selectAll, ...companyOptions]}
            placeholder='Account'
            value={filter.account}
            loading={fetchingCompanies}
          />

          <Form.Select
            fluid
            name='title'
            label='Title'
            onChange={this.handleChange}
            options={[...selectAll, ...Utils.arrayToOptionsHash(Constants.CONTACT_PERSON_TITLES)]}
            placeholder='Title'
            value={filter.title}
          />

          <Form.Select
            fluid
            name='status'
            label='Status'
            onChange={this.handleChange}
            options={Utils.arrayToOptionsHash(Constants.STATUS_FILTER_LIST, {startCaseText: false})}
            placeholder='Status'
            value={filter.status}
          />

          <Form.Select
            fluid
            name='division'
            label='Division'
            onChange={this.handleChange}
            options={[...selectAll, ...divisions]}
            placeholder='Division'
            value={filter.division}
          />

          <Form.Select
            fluid
            name='channel'
            label='Channel'
            onChange={this.handleChange}
            options={[...selectAll, ...Utils.arrayToOptionsHash(Constants.CHANNEL_DISTRIBUTIONS)]}
            placeholder='Channel'
            value={filter.channel}
          />

          <Form.Select
            fluid
            name='region'
            label='Region'
            onChange={this.handleChange}
            options={[...selectAll, ...Utils.arrayToOptionsHash(Constants.REGIONS)]}
            placeholder='Region'
            value={filter.region}
          />

          <Form.Select
            fluid
            name='category'
            label='Category'
            onChange={this.handleChange}
            options={[...selectAll, ...categories]}
            placeholder='Category'
            value={filter.category}
          />

          <Form.Select
            fluid
            name='accountRep'
            label='Account Rep'
            onChange={this.handleChange}
            loading={fetchingReps}
            options={[...selectAll, ...repOptions]}
            placeholder='Account Rep'
            value={filter.accountRep}
          />

          <label onClick={this.resetFilter} className='clear-filter'>
            <a>Clear Filters</a>
          </label>

      </Form.Group>
    </Form>
    );
  }
}

FilterRow.propTypes = {
  applyRowFilters: PropTypes.func.isRequired,
  initialFilterState: PropTypes.object.isRequired,
};
