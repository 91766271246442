const roundToPenny = (value) => {
  let result = Math.round(value * 100)
  result /= 100
  if (isNaN(result)) {
    result = null
  }
  return result
}

export {roundToPenny}
