import React, {Component} from 'react'
import {dateInputStyle} from '../edit-sales-order/date-inputs.js'
import {dateLabel} from '../new-vpo-page/cad-details-section/date-label.js'
import {Grid, GridRow, GridColumn, Dropdown, Icon, Popup, Button} from 'semantic-ui-react'

class BestSellersAddForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: null,
      contact: null
    }
  }

  onChangeDate = (event) => {
    let {value} = event.currentTarget
    this.setState({date: value})
  }

  addBestSeller = () => {
    let {date, contact} = this.state
    this.props.addBestSeller(date, contact)
  }

  fieldOnChange = (event, {value, name}) => {
    let newState = {}
    newState[name] = value
    this.setState(newState)
  }

  render() {
    let {date, contact} = this.state
    let {salesHistoryAccounts} = this.props

    return (
      <React.Fragment>
        <Popup content='Go back' trigger={
          <Button onClick={this.props.goBack} style={{marginBottom: '20px'}}><Icon name="arrow left" style={{marginRight: '-3px'}}/></Button>
        }/>

        <div style={{background: 'rgb(255,255,240)', padding: '20px 30px'}}>
          <b style={{textTransform: 'uppercase', borderBottom: '1px solid black'}}>Add {this.props.addType} seller</b>

          <Grid style={{marginTop: '5px'}}>
            <GridRow>
              <GridColumn width={6}>
                <div style={{marginBottom: '6px'}}>{dateLabel('Date')}</div>

                <input type="date"
                  value={date}
                  onChange={this.onChangeDate}
                  style={dateInputStyle}
                />
              </GridColumn>
              <GridColumn width={10}>
                <div style={{marginBottom: '6px'}}>{dateLabel('Contact')}</div>

                <div style={{width: '250px'}}>
                  <Dropdown placeholder='Contact' fluid selection search options={salesHistoryAccounts}
                    onChange={this.fieldOnChange} name='contact' value={contact}
                  />
                </div>
              </GridColumn>
            </GridRow>
          </Grid>

          <div style={{marginTop: '23px'}}>
            <Button id="add-best-seller-button" primary onClick={this.addBestSeller}
              style={{paddingLeft: '74px', paddingRight: '81px'}}
              disabled={date == null || contact == null}>
              <Icon name={this.props.addType == 'best' ? 'thumbs up' : 'thumbs down'}/> Add
            </Button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default BestSellersAddForm
