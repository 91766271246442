import Utils from '../../modules/utils'

const getAuto = (callbackFunc) => {
  const url = 'recently_auto_qbentered'
  Utils.get(url, callbackFunc)
}

const getManual = (callbackFunc) => {
  const url = 'recently_manually_qbentered'
  Utils.get(url, callbackFunc)
}

export {getAuto, getManual}
