import Utils from "../../modules/utils"

const getAccounts = (customer) => {
  //hash to get companyId from accountId
  let accountIds = {}
  customer.distinctAccounts.forEach(account => {
    accountIds[account.id] = account.companyId
  })

  let distinctAccounts = customer.distinctAccounts.filter(x => !x.notUsing && x.status == 'active')

  let contactOptions = Utils.objectsToOptionsHash([...distinctAccounts], 'id', 'name', (account) => account.mainContactPersonName ? `${account.name} (${account.mainContactPersonName})` : account.name)

  contactOptions = contactOptions.sort((a, b) => {
    if (a.text > b.text) return 1
    if (b.text > a.text) return -1

    return 0;
  })

  return {contactOptions, accountIds}
}

export {getAccounts}
