import React, {Component} from 'react'
import {Icon, Popup} from 'semantic-ui-react'
import Utils from './../../modules/utils'

let stickyNoteStyle = {color: 'rgb(244,195,86)', marginLeft: '10px', fontSize: '18px'}

class QbSyncedPopup extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {po} = this.props
    if (po.qbSynced) {
      return (
        <Popup
          size='mini'
          content={'This hold was QB synced on ' + Utils.formatSimpleDate(po.qbSyncedDate)}
          position='bottom left'
          trigger={
            <Icon name="cloud" style={stickyNoteStyle}/>
          }
        />
      )
    } else {
      return null
    }
  }
}

export default QbSyncedPopup
