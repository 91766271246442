const getHeadings = (showDetails, showShippingClass, showVponumber, showExFactory, activeIndex, isWarehouse, isInventory, isSalesAssistant) => {
  let headings = showDetails && !isInventory ?
  [
    {
      key         : "ponumber",
      displayName : "REF #",
      sortable    : true,
    },
    {
      key         : "customer_order_number",
      displayName : "PO #",
      sortable    : true,
    },
    {
      key         : "contact_name",
      displayName : "CONTACT",
      sortable    : true,
    },
    {
      key         : "styles",
      displayName : "STYLES",
      sortable    : true,
    },
    {
      key         : "quantity",
      displayName : "QTY",
      sortable    : true,
    },
    {
      key         : "price",
      displayName : "PRICE",
      sortable    : true,
    },
    {
      key         : "item_status",
      displayName : "ITEM STATUS",
      sortable    : false,
    }
    ,
    (
      showVponumber
      ?
      {
        key         : "vponumber",
        displayName : "VPO #",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      showExFactory
      ?
      {
        key         : "ex_factory_date",
        displayName : "EX-FACTORY",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      showShippingClass
      ?
      {
        key         : "shipping_class",
        displayName : "CLASS",
        sortable    : true,
      }
      :
      null
    )
    ,
    {
      key         : "status",
      displayName : "STATUS",
      sortable    : true,
    }
    ,
    {
      key         : "start_ship_date",
      displayName : "START SHIP",
      sortable    : true,
    },
    {
      key         : "cancel_date",
      displayName : "CANCEL",
      sortable    : true,
    },
    {
      key         : "created_date",
      displayName : "CREATED",
      sortable    : true,
    },
    {
      key         : "updated_at",
      displayName : "UPDATED",
      sortable    : true,
    },
    {
      key         : "actions",
      displayName : "ACTIONS",
      sortable    : false,
    }
  ]
  :
  [
    {
      key         : "ponumber",
      displayName : "REF #",
      sortable    : true,
    },
    {
      key         : "customer_order_number",
      displayName : "PO #",
      sortable    : true,
    },
    (
      isWarehouse || isInventory ?
      null
      :
      {
        key         : "contact_name",
        displayName : "CONTACT",
        sortable    : true,
      }
    )
    ,
    (
      isWarehouse || isInventory ?
      null
      :
      {
        key         : "quantity",
        displayName : "QTY",
        sortable    : true,
      }
    )
    ,
    (
      isWarehouse || isInventory ?
      null
      :
      {
        key         : "amount",
        displayName : "AMOUNT",
        sortable    : true,
      }
    )
    ,
    (
      isWarehouse || isInventory ?
      {
        key         : "account",
        displayName : "ACCOUNT",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      showDetails
      ?
      {
        key         : "styles",
        displayName : "STYLES",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      showDetails
      ?
      {
        key         : "quantity",
        displayName : "QTY",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      showDetails
      ?
      {
        key         : "price",
        displayName : "PRICE",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      showDetails
      ?
      {
        key         : "item_status",
        displayName : "ITEM STATUS",
        sortable    : false,
      }
      :
      null
    )
    ,
    (
      isInventory || isSalesAssistant
      ?
      {
        key         : "user_name",
        displayName : "REP",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      showVponumber
      ?
      {
        key         : "vponumber",
        displayName : "VPO #",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      showExFactory
      ?
      {
        key         : "ex_factory_date",
        displayName : "EX-FACTORY",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      showShippingClass
      ?
      {
        key         : "shipping_class",
        displayName : "CLASS",
        sortable    : true,
      }
      :
      null
    )
    ,
    {
      key         : "status",
      displayName : "STATUS",
      sortable    : true,
    }
    ,
    {
      key         : "start_ship_date",
      displayName : "START SHIP",
      sortable    : true,
    }
    ,
    (
      isWarehouse ?
      null
      :
      {
        key         : "cancel_date",
        displayName : "CANCEL",
        sortable    : true,
      }
    )
    ,
    (
      isWarehouse ?
      null
      :
      {
        key         : "created_date",
        displayName : "CREATED",
        sortable    : true,
      }
    )
    ,
    (
      isWarehouse ?
      null
      :
      {
        key         : "updated_at",
        displayName : "UPDATED",
        sortable    : true,
      }
    )
    ,
    (
      (isWarehouse && activeIndex > 2) || (isInventory && activeIndex > 4) ?
      {
        key         : "routing_number",
        displayName : "ROUTING NUMBER",
        sortable    : true,
      }
      :
      null
    )
    ,
    (
      (isWarehouse && activeIndex > 3) || (isInventory && activeIndex > 5) ?
      {
        key         : "pick_up_date",
        displayName : "PICK-UP DATE",
        sortable    : true,
      }
      :
      null
    )
    ,
    {
      key         : "actions",
      displayName : (isWarehouse ? "SHIPPING DOCUMENTS" : "ACTIONS"),
      sortable    : false,
    }
  ]

  return headings
}


export default getHeadings
