let sizeRatios = [
  "NONE",
  "0,2,4,6,8,10: 1-1-1-1-1-1",
  "2,4,6,8,10: 1-1-2-1-1",
  "2,4,6,8,10,12: 1-1-1-1-1-1",
  "2,4,6,8,10,12: 1-2-2-1-1-1",
  "5,6,8,10,12: 2-2-2-1-1",
  "5,6,8,10,12,14: 2-2-2-2-1-1",

  "7-8-9-10-11: 1-1-3-2-2",
  "7-8-10/12-14: 1-2-2-1",
  "6-7-8-9-10-11: 1-2-2-3-2-2",
  "6-7-8-9-10: 1-2-3-2-1",
  "6-7-8-9-10-11: 1-2-4-3-1-1",
  "6-7-8-9-10-11: 1-1-2-3-3-2",
  "6-8-10-12-14: 2-2-2-1-1",
  "6-7-8-9-10: 1-2-1-1-1",
  "6-7-8-9-10: 1-2-2-2-1",
  "7-8-9-10-11: 1-2-1-1-1",


  "4-4-6-6-8-8-10-10-12-12: 17-15-17-15-16-14-13-15-13-15",
  "4-6-8-10-12-14-16: 1-1-1-2-1-1-1",
  "0-3mo,3-6mo,6-9mo: 1-3-2",
  "6-9mo,12mo,18mo: 2-2-2",

  "3-6mo,6-9mo,12mo,18mo: 1-2-2-1",
  "3-6mo,6-9mo,12mo: 2-2-2",

  "34B-34C-36B-36C-38C 1-1-1-2-1",
  "34B/S-34C/M-36B/M 1-1-1",
  "36DD-38D-38DD 1-1-1",
  "36DD-38D-38DD-40D-40DD-42D 1-1-1-1-1-1",
  // "(1)34B/S- (1)34C/M - (1)36B/M"
  // "(1)36DD-(1)38D-(1)38DD-(1)40D-"
  // "(2)2T - (2)3T - (2)4T"
  // "(2)S-(3)M-(1)L"
  // "(2)S/(3)M/(1)L"
  // "(3)2T/3T - (3)4T"
  // "(3)2T/3T-(3)4T"
  // "(3)2T/3T/(3)4T"
  // "(3)M-(3)L"
  // "(3)XS-(3)S"
  // "1)34B-(1)34C-(1)36B-(2)36C-(1)"
  // "1)36DD-(1)38D-(1)38DD-(1)40D-("
  //
  "12M-18M 3-3",
  "12M-18M-24M 2-2-2",
  "12M-18M-24M 4-4-4",
  // "12m,18m,24m = 1,1,1"
  // "12m,18m,24m = 2,2,2"
  // "1S6/7-2M8/10-2L12/14-1XL16/18"
  //
  // "1X(2)-2X(2)-3X(2)"
  // "1X-2X 2-1"
  "1X/2X-2X/3X 3-3",
  "1X-2X 2-2",
  "1X-2X-3X 1-1-1",
  "1X-2X-3X 2-1-1",
  "1X-2X-3X 2-2-2",
  "1X-2X-3X 3-2-1",
  // "1X-2X-3X=2-2-2"
  // "1X-2X-3X=3-2-1"
  //
  "2T-3T 1-1",
  "2T-3T 3-3",
  "2T-3T-4T 3-3-2",
  "2T-3T-4T 1-1-1",
  "2T-3T-4T 2-2-2",
  "2T-3T-4T 2-3-3",
  "2T-3T-4T 8-8-8",
  "2T-3T-4T-5-6-7 1-1-2-2-1-1",
  "2T-3T-4T-5T 1-2-2-1",
  "2T/3T-4T 3-3",

  // "2T,3,T,4T,5T=2,2,2,2"
  // "2T,3,T,4T=2,2,2"
  // "2T,3T,4T = 1,1,1"
  // "2T,3T,4T = 4,4,4"
  // "2T,3T,4T,5T = 1,2,2,1"
  // "2T,3T,4T=2,2,2"
  // "2T-3T-4T (2-2-2)"

  // "2T-3T-4T-2-2-2"
  // "2T-3T-4T-5-6-6X=1-1-1-1-1-1"
  // "2T-3T-4T-5-6-6x=1-1-1-1-1-1"
  // "2T-3T-4T-5-6-7=1-1-1-1-1-1"

  // "2T-3T-4T-5-6-7=1-2-2-2-2-1"
  // "2T-3T-4T-6-7-8=1-1-2-2-1-1"
  // "2T-3T-4T=2-2-2"
  //
  // "30A-32-34A-2/2/2"
  // "30A-32A--34A-2/2/2"
  // "30A-32A-34A  (2-2-2)"
  // "30A-32A-34A (2-2-2)"
  "30A-32A-34A 2-2-2",
  // "30A-32A-34A-2/2/2"
  //
  // "34B(170) 34C (150) 36B (110)"
  // "34B(330) 34C (300) 36B (230)"
  "34B-34C-36C-38C 1-1-2-1",
  "28A-30A-32A-34A 1-1-2-2",
  // "34B-34C-36B-36C-38C 1-1-1-2-1"
  // "34B/1-34C/1-36B/1-36C/2-38C/1"
  // "34B/1-34C/1-36B/2-36C/1-38C/1"
  // "34B/S(1)   34C/M(1)  36B/M(1)"
  // "34B/S(1)-34C/M(1)-36B/M(1)-36C"
  //
  // "34b-34C-36B-36C-38C 1-1-1-2-1"
  //
  // "36C/L(2)    38C/XL(1)"
  // "36DD- 38D-38DD-40D-40DD-42D"
  // "36DD/1-38D/1-38DD/1"
  // "36DD/1-38D/1-38DD/1-40D/1-40DD"
  // "36DD/1X (1)    38D/1X (1)"
  //
  // "3m-6m,6m-12m,12m-18m=2-2-2"
  //
  // "4(1)- 5(2)- 6(2)- 6X/7(1)"
  // "4(2) 5/6(2) 6X(2)"
  // "4,5,6,7,8,10=1,1,1,1,2,2"
  // "4,5/6,6X = 2,2,2"
  // "4,6,8,10 = 1,2,2,1"
  // "4-5-6-6X (1-2-2-1)"
  // "4-5/6-6X-(2)-(2)-(2)"
  "4-5/6-7: 1-3-2",
  "4-5/6-6X: 2-2-2",
  "4-5/6-7/8-10/12: 1-2-2-1",
  "4-5/6-6X-7/8: 1-2-2-1",
  "4-5-6-7: 1-2-2-1",
  "4-6-8-10: 2-3-2-1",
  "4-6-8-10: 3-4-3-2",
  "4-5-6-7-8: 1-1-2-1-1",
  "4/6(S)-8/10(M)-12/14(L)-16/18(XL) 1-2-2-1",
  // "5,6,8,10,12,14=2,2,2,1,1,1"
  // "5,6,8,10,12,14=2-2-2-1-1-1"
  // "5-6-8-10-12-14=2-2-2-1-1-1"
  // "5/6 (1) - 7/8 (2) - 10/12 (3)"
  // "5/6 (2) - 7/8 (2) - 10/12 (2)"
  // "5/6 (2) 7/8 (2) 10/12 (2)"
  // "5/6 7/8 10/12 2-2-2"
  // "5/6(2) 7/8(2) 10/12(2)"
  "5/6(S)-7/8(M)-10/12(L) 2-2-2",
  // "5/6-7/8-10/12  2-2-2"
  // "5/6-7/8-10/12 2-2-2"
  "5/6-7/8-10/12: 4-4-4",
  "5/6-7/8-10/12: 1-2-1",
  "6,8,10,12: 2,2,1,1",
  "6,7,8,9,10: 1,3,4,3,1",
  "6,7,8,9,10,11: 1,3,4,3,1,1",
  // "6,8,10,12=2,2,1,1"
  // "6,8,10,12=2,2,2,2"
  // "6-9 MO,12 MO, 18 MO 2-2-2"
  // "6-9 MO-12 MO-18 MO=2-2-2"
  // "6-9M - 12M -18M :2-2-2"
  //
  // "7/8 (2) -10/12 (2) -14/16 (2)"
  // "7/8-10/12-14/16-2-2-2"
  //
  // "A-B-C-D-1-2-1-1"


  "4-5/6-6X 2-2-2",
  "4-5/6-7: 1-3-2",
  "4-5/6-7/8-10: 1-2-2-1",
  "5/6-7/8-10/12: 2-2-2",
  "7/8-10/12: 3-3",
  "7/8-10/12-14/16: 2-2-2",
  "8-10-12: 2-2-2",
  "8-10/12-14-16: 1-2-2-1",

  "A-B-C 1-3-2",
  "A-B-C-D 1-2-2-1",
  "A/B-C/D 1-2",

  "10/12-14/16-18/20: 2-3-3",
  "10/12-14/16-18/20: 3-4-4",
  "10/12-14/16-18/20: 4-5-6",

  //
  // "M(1) L(2) XL(2) 2XL(1)"
  // "M(1)-L(2)-1X(2)-2X(1)"
  // "M(1)-L(2)-1XL(2)-2XL(1)"
  // "M(8/10)-L(12/14)=3-3"
  "M-L-1X-2X 1-2-2-1",
  "M-L-XL 1-3-2",
  "M-L-XL 2-2-2",
  "M-L-XL 3-3-1", //reduced from 6-6-2
  "M-L-XL 1-4-3",
  "M-L-XL 2-3-3",
  "M-L-XL 3-4-4",
  "M-L-XL 4-5-6",
  "M-L-XL-2X 1-2-2-1",
  "M-L-XL-2XL 1-2-2-1",
  "M-L-1XL-2XL 1-2-2-1",
  "PS-PM-PL-PXL 1-1-2-2",
  // "M/1-L/2-1X/2-2X/1"
  // "M/1-L/2-XL/2-2X/1"
  // "M/2 -L/2 -XL/2"
  //
  //
  // "S 7-M 8-L 10/12-XL 14/16 (1-2-"
  // "S(6/7)-M(10/12)-L(12/14) 2-2-2"
  // "S(6/7)-M(8/10)-L(10/12) 2-2-2"
  // "S(6/7)-M(8/10)-L(12/14)  2-2-2"
  // "S(6/7)-M(8/10)-L(12/14) 2-2-2"
  // "S,M,L,XL 0-1-4-3"
  "S-M-L 1-1-1",
  "S-M-L 1-2-1",
  "S-M-L-XL 1-3-3-1",
  "S-M-L-XL 2-3-2-1",
  // "S,M,L,XL=3-6-6-3" //duplicate of 3663
  "S-M-L 1-2-2",
  "S-M-L 2-2-2",
  "S-M-L 2-3-1",
  "S-M-L 3-2-1",
  "S-M-L-XL 1-1-1-1",
  "S-M-L-XL 1-1-2-2",
  "S-M-L-XL 1-2-2-1",
  "S-M-L-XL 1-2-3-1",
  "S-M-L-XL 1-2-3-2",
  "S-M-L-XL 1-3-2-1",
  "S-M-L-XL 2-2-2-2",
  "S-M-L-XL 2-2-3-1",
  "S-M-L-XL 2-2-1-1",
  "S-M-L-XL 2-4-4-2", //duplicate of  1221
  "S-M-L-XL 2-4-4-3",
  "S-M-L-XL 2-5-5-2",
  "S-M-L-XL 3-6-6-3",
  "S-M-L-XL-XXL 1-1-2-1-1",
  "S-M-L-XL-XXL 1-1-2-2-1",
  "S-M-L-XL-XXL 2-2-2-1-1",
  "S-M-L-XL-XXL 2-3-4-3-2",
  // "S-M-L-XL0-1-4-3",
  // "S-M-L-XL=3-6-6-3", //duplicate of 3663
  // "S/6,7/8,10/12 = 2,2,2"
  // "S/M(2) L/XL(2)"

  "S/M-M/L 3-3",
  "S/M-L/XL 2-2",
  "S/M-L/XL 3-3",
  "S/M-L/XL 4-2",
  "S/M-M/L-L/XL-XL/2XL 1-2-2-1",
  "S/M-M/L-L/XL 1-1-1",
  // "S5/6,M7/8,L10/12 = 2,2,2"
  // "S6/6X-M7/8-L10/12-XL14/16"
  // "S6/7,M8/10,L12/14,XL16 1-2-2-1"
  // "S6/7-M10/12-L12/14-XL1 1-2-2-1"
  // "S6/7-M8/10-L12/14 2-2-2"
  // "S6/7-M8/10-L12/14-  2-2-2"
  // "S6/7-M8/10-L12/14- 2-2-2"
  // "S6/7-M8/10-L12/14-XL16 1-2-2-1"
  // "S6/7M8/10L12/14XL16/18 1-2-2-1"
  // "S6/7M8/10L12/14XL16/18-1-2-2-1"
  // "S7-M8-L10/12-XL14/16 (1-2-2-1"

  // "XS(4/5)-S(6/7)=3-3"
  "XS-S-M-L 1-1-1-1",
  "XS-S-M-L-XL 1-1-3-2-1",
  "XS-S-M-L-XL 1-2-2-2-1",
  "XS-S-M-L-XL 1-2-3-3-2",
  "XS-S-M-L 1-2-2-1",
  "XS-S-M-L-XL 1-1-2-1-1",
  "XS-S-M-L-XL 1-2-2-1-1",
  "XS-S-M-L-XL 1-2-4-3-1",
  "XS-S-M-L-XL 1-2-1-1-1",
  "XS-S-M-L-XL-XXL 1-1-1-1-1-1",
  "XS-S-M-L-XL-XXL 1-3-5-5-4-2",
]

sizeRatios = sizeRatios.sort()
let move2top = 'S-M-L-XL 1-2-2-1'
let move2top2 = 'NONE'
sizeRatios = sizeRatios.filter(item => item !== move2top && item !== move2top2)
sizeRatios.unshift(move2top2)
sizeRatios.unshift(move2top)

export {sizeRatios}
