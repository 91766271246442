import React, {Component} from 'react'
import {} from 'semantic-ui-react'
import {isCustomer} from './is-role.js'

let sliderLabelStyle = {textAlign: 'left', display: 'inline-block', border: '1px solid rgb(222,222,222)', borderRadius: '5px', padding: '0px 10px', width: '110px', paddingBottom: '5px'}
let longDashStyle = {display: 'inline-block', verticalAlign: 'top', paddingTop: '12px', paddingLeft: '7px', paddingRight: '7px'}
let textStyle = {opacity: 0.5, letterSpacing: '-0.7px'}

class DisplayMinMax extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {name, minInitialValue, maxInitialValue} = this.props

    let minId = name.replace(' ', '-')+'-min'
    let maxId = name.replace(' ', '-')+'-max'

    return (
      <div style={{paddingTop: '6px'}}>
        <div style={sliderLabelStyle}>
          <small style={textStyle}>min {name}</small>
          <div id={minId}>{minInitialValue}</div>
        </div>
        {
          isCustomer()
          ?
          null
          :
          <React.Fragment>
            <div style={longDashStyle}>—</div>
            <div style={sliderLabelStyle}>
              <small style={textStyle}>max {name}</small>
              <div id={maxId}>{maxInitialValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}

export default DisplayMinMax
