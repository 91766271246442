import $ from 'jquery'
import {getKey} from './get-key.js'

const processSwapCad = (myState, updateState, cad, oldKey, index) => {
  let {
    cads, styleNumbers,
    cpoSizes, cpoQuantity, quantity, price,
    startShipDate, cancelDate
  } = myState
  let cadIds = cads.map((cad) => cad.id)

  if (cadIds.includes(cad.id)) {
    alert('Error: Cad is already added to order!')
  } else {
    $('#swap-cad-input').val('')
    cads[index] = cad
    styleNumbers[index] = cad.style_number

    let newKey = getKey(cad)

    renameKey(cpoSizes, oldKey, newKey)
    renameKey(cpoQuantity, oldKey, newKey)
    renameKey(quantity, oldKey, newKey)
    renameKey(price, oldKey, newKey)
    renameKey(startShipDate, oldKey, newKey)
    renameKey(cancelDate, oldKey, newKey)

    updateState({
      cads, styleNumbers,
      cpoSizes, cpoQuantity, quantity, price,
      startShipDate, cancelDate
    })
    $('#style-numbers-div').html(styleNumbers.join("<br/>"))
  }
}

const renameKey = (object, oldKey, newKey) => {
  if (oldKey !== newKey) {
    Object.defineProperty(object, newKey, Object.getOwnPropertyDescriptor(object, oldKey))
    delete object[oldKey]
  }
}

export {processSwapCad}
