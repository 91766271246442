import React from 'react'
import {Icon, Popup} from 'semantic-ui-react'

let messageStyle = {color: 'white', background: 'rgb(227,119,55)', padding: '5px 10px'}
let messageStyle2 = {color: 'white', background: 'rgb(63,133,202)', padding: '5px 10px'}
let messageStyle3 = {color: 'white', background: 'rgb(244,195,86)', padding: '5px 10px'}
const stickyNoteStyle = {color: 'rgb(244,195,86)', marginLeft: '10px', fontSize: '18px'}
const stickyNoteStyle2 = {color: 'rgb(63,133,202)', marginLeft: '10px', fontSize: '18px'}
const stickyNoteStyle3 = {cursor: 'not-allowed', color: 'rgb(227,119,55)', marginLeft: '10px', fontSize: '18px'}

const getIsLocked = (status, dateText) => {
  let futureTime = new Date(dateText)
  let now = new Date()
  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000
  const timeDiffInMs = futureTime.getTime() - now.getTime()
  let isLocked = status == 'Processed' && timeDiffInMs <= thirtyDaysInMs
  return isLocked
}

const isUnlockedMarkup = (isLocked, dateText, status) => {
  return isLocked ? <b style={messageStyle3}><Icon name='lock open'/> ORDER IS UN-LOCKED  &nbsp; {daysUntilExfactory(dateText, status)} &nbsp;<Icon name='lock open'/></b> : ''
}

const isLockedMarkup = (isLocked, dateText) => {
  return isLocked ? <b style={messageStyle2}><Icon name='lock'/> ORDER IS LOCKED & WAITING FOR IMPORT TO QB &nbsp; {daysUntilExfactory(dateText)} &nbsp;<Icon name='lock'/></b> : ''
}

const isDisabledMarkup = (isDisabled) => {
  return isDisabled ? <b style={messageStyle}><Icon name='dont'/> EDITING IS DISABLED (ANY CHANGES MUST BE MANUALLY DONE IN QB !!!) &nbsp;<Icon name='dont'/></b> : ''
}

const getIsUnlocked = (status, dateText) => {
  let isLocked = getIsLocked(status, dateText)

  return ['Pending', 'Processed'].includes(status) && !isLocked
}

const getIsDisabled = (status) => {
  return !['Pending', 'Processed'].includes(status) && status != null
}

const isLockedTooltip = (isLocked, dateText) => {
  return (
    isLocked
    ?
    <Popup
      size='mini'
      content={'VPO is LOCKED' + daysUntilExfactory(dateText)}
      position='bottom left'
      trigger={
        <Icon name="lock" style={stickyNoteStyle2}/>
      }
    />
    :
    null
  )
}

const isUnlockedTooltip = (isUnlocked, dateText, status) => {
  return (
    isUnlocked
    ?
    <Popup
      size='mini'
      content={'VPO is UN-LOCKED' + daysUntilExfactory(dateText, status)}
      position='bottom left'
      trigger={
        <Icon name="lock open" style={stickyNoteStyle}/>
      }
    />
    :
    null
  )
}

const daysUntilExfactory = (dateText, status) => {
  if (status == 'Pending') return ' (Status is Pending)'
  let futureTime = new Date(dateText)
  let now = new Date()
  const timeDiffInMs = futureTime.getTime() - now.getTime()
  const timeDiffDays = Math.floor(timeDiffInMs / (24 * 60 * 60 * 1000))
  if (timeDiffDays > 0) {
    return ' (' + timeDiffDays + ' days until EX-FACTORY DATE)'
  } else {
    return ' (EX-FACTORY DATE has past)'
  }
}

const isDisabledTooltip = (isDisabled) => {
  return (
    isDisabled
    ?
    <Popup
      size='mini'
      content={'Editing is DISABLED!'}
      position='bottom left'
      trigger={
        <Icon name="dont" style={stickyNoteStyle3}/>
      }
    />
    :
    null
  )
}

export {getIsLocked, isLockedMarkup, getIsUnlocked, getIsDisabled, isDisabledMarkup, isLockedTooltip, stickyNoteStyle, isUnlockedTooltip, isDisabledTooltip, isUnlockedMarkup}
