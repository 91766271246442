import React from 'react'
import {Table} from 'semantic-ui-react'
import {isMarginsMatch, isMarginsMissing} from './margins.js'

const negativeBoldStyle = {fontWeight: 'bold', color: 'red'}
const positiveBoldStyle = {fontWeight: 'bold', color: 'green'}


const getVponumberIndicies = (searchValue, po) => {
  let vponumberIndicies = []
  if (searchValue.length < 3 || po.vendorPonumber == null) {
    //do nothing
  } else {
    let nums = searchValue.split(/\r?\n/).map(num => num.noSpaces()).filter(num => num != '')
    po.vendorPonumber.forEach((el, i) => {
      if (nums.includes(el)) {
        vponumberIndicies.push(i)
      }
    })
  }
  return vponumberIndicies
}


const getStylesDisplayCell = (po, isInventory, i, myProps) => {
  let vponumberIndicies = getVponumberIndicies(myProps.searchValue, po)

  return (
    <Table.Cell key={`styles-${i}`} style={{whiteSpace: 'nowrap', verticalAlign: 'top'}}>
      {po.cadStyleNumbers == null ?  null : po.cadStyleNumbers.map((row, j) => {
        let style = {}
        if (po.cads != null) {
          let cad = po.cads[j]

          if (isInventory) {
            if (cad != null && myProps.brand.length > 0 && myProps.brand.indexOf(cad.brand) > -1) {
              style = positiveBoldStyle
            } else if (vponumberIndicies.includes(j)) {
              style = positiveBoldStyle
            }
          } else {
            if (myProps.showMissingCad && cad == null) {
              style = negativeBoldStyle

            ///
            } else if (cad != null && window.inputs != null && window.inputs.brand != 'Show All' && window.inputs.brand.indexOf(cad.brand) > -1) {
              style = positiveBoldStyle
            } else if (window.inputs != null && window.inputs.brand.indexOf('Missing') > -1 && (cad == null || cad.brand == null || cad.brand == '')) {
              style = negativeBoldStyle

            ////
            } else if (cad != null && window.inputs != null && window.inputs.category != 'Show All' && window.inputs.category.indexOf(cad.parent_category) > -1) {
              style = positiveBoldStyle
            } else if (window.inputs != null && window.inputs.category.indexOf('Missing') > -1 && (cad == null || cad.parent_category == null || cad.parent_category == '')) {
              style = negativeBoldStyle

            //margins
            } else if (cad != null && window.inputs != null && window.inputs.margins != 'Show All' && isMarginsMatch(window.inputs.margins, po, j)) {
              style = positiveBoldStyle
            } else if (window.inputs != null && window.inputs.margins.indexOf('Missing') > -1 && isMarginsMissing(po, j)) {
              style = negativeBoldStyle

            ///pack
            } else if (cad != null && window.inputs != null && window.inputs.pack != 'Show All' && window.inputs.pack.indexOf(cad.packs) > -1) {
              style = positiveBoldStyle
            } else if (window.inputs != null && window.inputs.pack.indexOf('Missing') > -1 && (cad == null || cad.packs == null || cad.packs == '')) {
              style = negativeBoldStyle

            ///division
            } else if (cad != null && window.inputs != null && window.inputs.division != 'Show All' && window.inputs.division.indexOf(cad.department) > -1) {
              style = positiveBoldStyle
            } else if (window.inputs != null && window.inputs.division.indexOf('Missing') > -1 && (cad == null || cad.department == null || cad.department == '')) {
              style = negativeBoldStyle

            ///subcategory
            } else if (cad != null && window.inputs != null && window.inputs.subcategory != 'Show All' && window.inputs.subcategory.indexOf(cad.category) > -1) {
              style = positiveBoldStyle
            } else if (window.inputs != null && window.inputs.subcategory.indexOf('Missing') > -1 && (cad == null || cad.category == null || cad.category == '')) {
              style = negativeBoldStyle

            ///VPO# search
            } else if (vponumberIndicies.includes(j)) {
              style = positiveBoldStyle

            }
          }
        }
        return <div key={`styles-${i}-${j}`} style={style}>{row}</div>
      })}
    </Table.Cell>
  )
}

export {getStylesDisplayCell, getVponumberIndicies}
