import React from 'react'
import {Popup, Button} from 'semantic-ui-react'

const getDistFileContent = (id, distributionFilenames, distroUrlFunc, downloadDistro, isAdminView, isWarehouse = false) => {

  let buttonStyle = {marginBottom: '4px', marginRight: '5px', padding: '5px', fontSize: '16px'}
  if (isAdminView) {buttonStyle.padding = '7px'}

  let distroIframeStyle = {marginLeft:"-12px", border: "1px solid rgb(204,204,204)", marginTop: "-9px", marginBottom: "-14px", background:"white", overflow:"hidden"}

  return (
    distributionFilenames == null
    ?
    null
    :
    distributionFilenames.map((filename, index) => {
      let distroUrl = distroUrlFunc(id, filename)

      let dateText
      let oldFile = filename.startsWith('upload_') || filename.startsWith('distribution_')
      if (oldFile) {
        //do nothing
      } else {
        let dateString = filename.split('/')[0].substr(32)
        var d = new Date(0) // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(dateString)
        dateText = <small>(Uploaded: {d.toLocaleDateString()})</small>
      }

      return (
        <Popup
          content={
            isWarehouse
            ?
            'Download distro'
            :
            <div>
              <h4>
                Distro
                {
                  distributionFilenames.length == 1
                  ?
                  null
                  :
                  <span>&nbsp;#{index+1}</span>
                }
                &nbsp;&nbsp;&nbsp; {dateText}
              </h4>
              <iframe
                src={distroUrl}
                height="400px" width="400px"
                style={distroIframeStyle}
                scrolling='no'
              />
            </div>
          }
          position='bottom right'
          trigger={
            <Button
              size={isAdminView ? 'large' : null}
              id={"download-distro-button-" + id} icon='sitemap'
              onClick={downloadDistro.bind(null, id, filename)} style={buttonStyle}
            />
          }
        />
      )
    })
  )
}

export default getDistFileContent
