const statuses = ['Pending', 'Active', 'In Review', 'Inactive']

const keyTextValue = (arr) => {
  return arr.map(x => {return {key: x, text: x, value: x}})
}

const statusOptions = keyTextValue([
  'Pending',
  'Active',
  'In Review',
  'Inactive',
])

const titleOptions = keyTextValue([
  'Assistant Buyer',
  'Buyer',
  'CEO',
  'Design',
  'Director of Merchandising',
  'DM',
  'GMM',
  'IT',
  'Merchandising',
  'MMM',
  'Productions',
  'VP'
])

const distributionChannelOptions = keyTextValue([
  'Clubs',
  'Department Store',
  'Discounter',
  'Distributor',
  'E Comm',
  'Jobber',
  'Midtier',
  'Offprice',
  'Small Account',
  'Wholesale'
])

const regionOptions = keyTextValue([
  'Asia',
  'Australia',
  'Canada',
  'Central America',
  'Europe',
  'Latin America',
  'Middle East',
  'USA'
])

const labels = [
  'NAME',
  'TITLE',
  'PHONE',
  'COMPANY',
  'EMAIL',
  'STATUS',
  'CONTACT ID',
  'LATEST ACTIVITY',
  'REP',
  'DISTRIBUTION CHANNEL',
  'REGION',
  'DIVISION',
  'CATEGORY',
]

export {statuses, statusOptions, titleOptions, distributionChannelOptions, regionOptions, labels}
