import React from 'react'
import {roundToPenny} from '../../vendor-orders/round-to-penny.js'

const reducer = (previousValue, currentValue) => previousValue + currentValue

const totalsTable = (cadIds, quantity, cost, discount) => {
  let totalBefore = 0, totalAfter = 0, totalUnits = 0
  try {

    cadIds.forEach(cadId => {
      let itemQty = quantity[cadId].reduce(reducer)
      totalUnits += itemQty
      let itemTotal = itemQty * cost[cadId]
      totalBefore += itemTotal
      if (discount[cadId] != '' && discount[cadId] != null) {
        itemTotal *= (1.0 - Number(discount[cadId])/100.0)
      }
      totalAfter += itemTotal
    })

    totalBefore = roundToPenny(totalBefore)
    totalAfter = roundToPenny(totalAfter)

  } catch (error) {
    console.error(error)
  }

  return (
    totalBefore != 0 && totalBefore != totalAfter && !isNaN(totalBefore)
    ?
    <div style={{width: '670px', display: 'inline-block'}}>
      <table style={{border: '1px solid rgb(222,222,222)', borderRadius: '2px', padding: '2px 10px'}}>
        <tr>
          <th>Total Units</th>
          <th style={{paddingLeft: '20px'}}>Total Dollars Before Discount</th>
          <th style={{paddingLeft: '20px'}}>Total Dollars After Discount</th>
        </tr>
        <tr>
          <td style={{textAlign: 'center'}}>{totalUnits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
          <td style={{textAlign: 'center'}}>$ {totalBefore == null ? null : totalBefore.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
          <td style={{paddingLeft: '20px', textAlign: 'center'}}>$ {totalAfter == null ? null : totalAfter.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
        </tr>
      </table>
    </div>
    :
    <div style={{width: '470px', display: 'inline-block'}}>
      <table style={{border: '1px solid rgb(222,222,222)', borderRadius: '2px', padding: '2px 10px'}}>
        <tr>
          <th>Total Units</th>
          <th style={{paddingLeft: '20px'}}>Total Dollars</th>
        </tr>
        <tr>
          <td style={{textAlign: 'center'}}>{totalUnits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
          <td style={{paddingLeft: '20px', textAlign: 'center'}}>$ {totalBefore == null ? null : totalBefore.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
        </tr>
      </table>
    </div>
  )
}

export {totalsTable}
