import React from 'react'
import {Message, Popup, Button, Icon} from 'semantic-ui-react'
import {copyToClipboard} from '../copy-to-clipboard'
import StyleNumberError from '../style-number-error'

const styleNumberMessage = (familyId, color, pattern, season) => {
  let styleNumber = 'G' + familyId.padStart(4,'0') + '-' + color.padStart(3,'0')

  return (
    <center>
      <Message info style={{display: 'inline-block', fontSize: '200%'}}>
        <Message.Header id="new-style-number-graphic">
<Popup position='bottom center' content='Prints always start with P' trigger={<span style={{cursor: 'pointer'}}>G</span>} />
<Popup position='bottom center' content='Family Id' trigger={<span style={{cursor: 'pointer'}}>{familyId.padStart(4,'0')}</span>} />
-<Popup position='bottom center' content='Color' trigger={<span style={{cursor: 'pointer'}}>{color.padStart(3,'0')}</span>} />
        </Message.Header>
        <StyleNumberError styleNumber={styleNumber} type={'GRAPHIC'}/>
      </Message>
      <Button basic style={{marginLeft: '20px'}} onClick={() => copyToClipboard(false, true)}>Copy to clipboard &nbsp;<Icon name='clipboard outline'/></Button>
    </center>
  )
}

export {styleNumberMessage}
