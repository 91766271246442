import React, {Component} from 'react'
import {Button, Icon, Dimmer, Loader} from 'semantic-ui-react'
import Utils from './../../../modules/utils'
import Dropzone from 'react-dropzone'
import $ from 'jquery'

class UploadDistro extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      uploadingFile: false
    }

    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
  }

  componentDidMount() {

  }

  handleAcceptedFiles(acceptedFiles) {
    for (let k=0; k<acceptedFiles.length; k++) {
      let file = acceptedFiles[k]

      let formData = new FormData()
      let filename = file.name

      let fileTypes = ['.xlsx', '.xls', '.png', '.jpeg', '.jpg', '.doc', '.docx', '.pdf', '.txt']
      let message = 'Only allow image, microsoft word, excel, PDF file types'

      let fileTypeAcceptable = fileTypes.some((fileType) => filename.toLowerCase().indexOf(fileType) > -1)

      if (!fileTypeAcceptable) {
        alert(message)
      } else {
        formData.append("file", file, file.name)
        formData.append("purchase_order_id", this.props.purchaseOrder.id)

        this.setState({uploadingFile: true})

        $.ajax({
          type: "POST",
          url: process.env.REACT_APP_API_URL + '/api/file-upload-worksheet',
          success: function (data) {
            this.setState({uploadingFile: false})
            this.props.closeModal()
            alert('Distro upload was a success!')
            $('#upload-distro-'+this.props.purchaseOrder.id).fadeOut()


          }.bind(this),
          error: function (error) {
            // handle error
            alert('There was an error when trying to upload the file.')
          },
            async: true,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            timeout: 60000
        })
      }

    }
  }

  render() {
    let {uploadingFile} = this.state

    return (
      <div>
        <div style={{padding: '10px'}}><b>Accepted file types:</b> image, microsoft word, excel, pdf</div>
        <Dropzone onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()} id="my-dropzone" >
                <input {...getInputProps()} />
                <p>Drag 'n' drop distro file here, or click to select file</p>
              </div>
            </section>
          )}
        </Dropzone>
        <Dimmer active={uploadingFile}>
          <Loader />
        </Dimmer>
      </div>
    )
  }
}

export default UploadDistro
