import React, { Component } from "react"
import AccountForm          from "../account-form"
import AccountAPI           from '../../../modules/api/account_api.js'
import {LoadingParagraph}   from '../../ui/loading-paragraph';

export class AccountView extends Component
{

    constructor(props)
    {
        super(props)
        this.state = {
          loading: true,
          account: null
        }
    }

    componentDidMount() {
        this.fetchAccount();
    }

    fetchAccount = () => {
        let id = this.props.match.params.id
        AccountAPI.get(id, (error, response) =>
        {
            if (error)
            {
              alert("account error see console")
              console.log(error)
              this.setState({ loading: false })
            }
            else
            {
              if (response.notUsing) {
                alert('This contact is not in use!')
              } else {
                this.setState({ account: response, loading: false })
              }
            }
          })
    };

    render()
    {
        let { loading, account } = this.state
        return loading ?
          null
          :
          <AccountForm
            account={account}
            history={this.props.history}
          />
    }
}

export default AccountView
