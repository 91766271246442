import Utils from '../../modules/utils'

const callbackFunc = (err, res) => {
  if (err) {
    alert('Error')
  } else {
    window.location.reload()
  }
}

const removeFlag = (vpoId) => {
  if (window.confirm('Are you sure you want to remove the flag?')) {
    const url = 'remove_vpo_flag'
    const data = {vpoId}
    Utils.post(url, data, callbackFunc)
  }
}

const callbackFuncBulk = (err, res) => {
  if (err) {
    alert('Error')
  } else {
    alert('Success')
  }
}

const removeFlagBulk = () => {
  if (window.confirm('Are you sure you want to remove the flag?')) {
    const url = 'remove_vpo_flag_bulk'
    const {vpoIds} = window
    const data = {vpoIds}
    Utils.post(url, data, callbackFuncBulk)
  }
}

export {removeFlag, removeFlagBulk}
