import React, { Component } from 'react';
import $ from 'jquery';
import { bulkStatusOptions } from '../../getApiEndpoint.js'
import { Form, Icon, Grid, Button, Label, Modal } from 'semantic-ui-react'
import {printSelect} from './prints/select'
import {orgIdsShowEditCost} from '../cost/options.js'
import {casePackInput} from './bulk-edit-modal/case-pack-input.js'

class BulkEditModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      bulkDivision: '',
      bulkBrand: '',
      bulkParent_category: '',
      bulkColor: '',
      bulkFabrication: '',
      bulkSeason: '',
      bulkPacks: '',
      bulkCategory: '',
      sub2: '',
      bulkStatus: '',
      msrp: '',
      cost: '',
      print: '',
      description: '',
      casePack: ''
    }

    this.saveBulkChangeCads = this.saveBulkChangeCads.bind(this);
    this.divisionOnChange2 = this.divisionOnChange2.bind(this);
    this.brandOnChange2 = this.brandOnChange2.bind(this);
    this.categoryOnChange2 = this.categoryOnChange2.bind(this);
    this.colorOnChange2 = this.colorOnChange2.bind(this);
    this.fabricationOnChange2 = this.fabricationOnChange2.bind(this);
    this.seasonOnChange2 = this.seasonOnChange2.bind(this);
    this.packsOnChange2 = this.packsOnChange2.bind(this);
    this.subCategoryOnChange2 = this.subCategoryOnChange2.bind(this);
    this.sub2OnChange2 = this.sub2OnChange2.bind(this);
    this.statusOnChange2 = this.statusOnChange2.bind(this);
    this.sizesOnChange = this.sizesOnChange.bind(this);
    this.msrpOnChange = this.msrpOnChange.bind(this)
    this.costOnChange = this.costOnChange.bind(this)
    this.printOnChange = this.printOnChange.bind(this)
    this.descriptionOnChange = this.descriptionOnChange.bind(this)
    this.casePackOnChange = this.casePackOnChange.bind(this)
  }

  componentDidMount() {
    window.addEventListener('clearBulkValues', () => {
      this.setState({
        bulkDivision: '',
        bulkBrand: '',
        bulkParent_category: '',
        bulkColor: '',
        bulkFabrication: '',
        bulkSeason: '',
        bulkPacks: '',
        bulkCategory: '',
        sub2: '',
        bulkStatus: '',
        msrp: '',
        cost: '',
        print: '',
        description: ''
      })
    })
  }

  saveBulkChangeCads() {
    let {orgId} = this.props
    if (this.state.bulkSizes == '' && this.state.bulkDivision == '' && this.state.bulkBrand == '' && this.state.bulkParent_category == '' && this.state.bulkColor == '' && this.state.bulkFabrication == '' && this.state.bulkSeason == '' && this.state.bulkPacks == '' && this.state.bulkCategory == '' && this.state.bulkStatus == '') {
      alert('Please make at least one change!')
    } else {
      $('#save-bulk-button').addClass('loading disabled');

      let profile = JSON.parse(sessionStorage.getItem('user'));
      let user_name = (profile.name != null ? profile.name : profile.email)

      var keys = Object.keys(window.cadIds);

      $.ajax({
        type: "POST",
        url: process.env.REACT_APP_API_URL + '/api/bulk_update_cads',
        data: {
          bulkDivision: this.state.bulkDivision,
          bulkBrand: this.state.bulkBrand,
          bulkParent_category: this.state.bulkParent_category,
          bulkColor: this.state.bulkColor,
          bulkFabrication: this.state.bulkFabrication,
          bulkSeason: this.state.bulkSeason,
          bulkPacks: this.state.bulkPacks,
          bulkCategory: this.state.bulkCategory,
          sub2: this.state.sub2,
          bulkStatus: this.state.bulkStatus,
          bulkSizes: this.state.bulkSizes,
          msrp: this.state.msrp,
          cost: this.state.cost,
          print: this.state.print,
          description: this.state.description,

          user_name: user_name,
          user_id: profile.auth0Uuid,
          cad_ids: keys,

          org_id: orgId,
          case_pack: this.state.casePack
        },
        success: function (data2) {
            // your callback here
            //success
            $('#save-bulk-button').removeClass('loading disabled');//turn off after api call finishes
            this.props.onClose();
            //this.search();
            setTimeout(function() {
              alert('The bulk changes were saved!');
              window.location.reload();
            }, 300);
        }.bind(this),
        error: function (error) {
            // handle error
            alert('There was an error when trying to bulk update the cads.')
        },
      });


    }

  }

  divisionOnChange2(event, {value}) {
    this.setState({bulkDivision: value});
  }
  brandOnChange2(event, {value}) {
    this.setState({bulkBrand: value});
  }

  categoryOnChange2(event, {value}) {
    this.setState({bulkParent_category: value});

    let category = this.props.customerCategories.filter((x) => {return x.name == value})[0]

    if (category.subcategories.length > 0) {
      let applicableSubcategories = category.subcategories.map((x) => {return x.name})
      let value2 = this.state.bulkCategory
      if (applicableSubcategories.indexOf(value2) == -1) {
        this.setState({bulkCategory: ''});
      }
    }
  }

  colorOnChange2(event, {value}) {
    this.setState({bulkColor: value});
  }
  fabricationOnChange2(event, {value}) {
    this.setState({bulkFabrication: value});
  }
  seasonOnChange2(event, {value}) {
    this.setState({bulkSeason: value});
  }
  packsOnChange2(event, {value}) {
    this.setState({bulkPacks: value});
  }
  subCategoryOnChange2(event, {value}) {
    this.setState({bulkCategory: value, sub2: ''});
  }
  sub2OnChange2(event, {value}) {
    this.setState({sub2: value});
  }
  statusOnChange2(event, {value}) {
    this.setState({bulkStatus: value});
  }

  sizesOnChange(event, {value}) {
    this.setState({bulkSizes: value});
  }

  msrpOnChange(event, {value}) {
    this.setState({msrp: value})
  }

  costOnChange(event, {value}) {
    this.setState({cost: value})
  }

  descriptionOnChange(event, {value}) {
    this.setState({description: value})
  }

  casePackOnChange(event, {value}) {
    this.setState({casePack: value})
  }

  printOnChange(event, {value}) {
    this.setState({print: value})
  }

  render() {
    let {print, casePack} = this.state
    let {isDesign, orgId} = this.props

    //get applicable subcategories
    let subcategories = this.props.subcategories
    if (this.state.bulkParent_category != '') {
      let category = this.props.customerCategories.filter((x) => {return x.name == this.state.bulkParent_category})[0]
      if (category.subcategories != null && category.subcategories.length > 0) {
        subcategories = category.subcategories
          .map(x => this.props.customerSubCategories.find(el => el.id == x))
          .map((x) => {return { key: x.name, text: x.name, value: x.name } })
      }
    }

    //get applicable sub2
    let sub2Options = []
    if (this.state.bulkCategory != '') {
      let category = this.props.customerSubCategories.filter((x) => {return x.name == this.state.bulkCategory})[0]

      if (category.grandSubcategories != null && category.grandSubcategories.length > 0) {
        sub2Options = category.grandSubcategories
          .map(x => this.props.customerGrandSubcategories.find(el => el.id == x))
          .map((x) => {return { key: x.name, text: x.name, value: x.name } })
      }
    }

    return (
      <Modal size={isDesign ? 'small' : 'large'} open={this.props.open} onClose={this.props.onClose} closeIcon>
        <Modal.Header>Bulk edit cads</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.saveBulkChangeCads} id="my-bulk-form">
            <div className={isDesign ? 'percent80-width-desktop' : 'percent50-width-desktop'} style={{marginBottom: '15px'}}>
              <Form.Input fluid label='Description' type="text" onChange={this.descriptionOnChange} placeholder='Description' value={this.state.description} />
            </div>

            {
              isDesign
              ?
              null
              :
              <Form.Group widths='equal'>
                <Form.Select id="brand-select" fluid label='Brand' onChange={this.brandOnChange2} options={this.props.brands} placeholder='Brand' value={this.state.bulkBrand} />
                <Form.Select id="division-select" fluid label='Division' onChange={this.divisionOnChange2} options={this.props.divisions} placeholder='Division' value={this.state.bulkDivision} />
                <Form.Select id="category-select" fluid label='Category' onChange={this.categoryOnChange2} options={this.props.categories} placeholder='Category' value={this.state.bulkParent_category} />
                <Form.Select fluid label='Sub-category' onChange={this.subCategoryOnChange2} options={subcategories} placeholder='Sub-category' value={this.state.bulkCategory} />
              </Form.Group>
            }

            {
              isDesign
              ?
              <Form.Group widths='equal'>
                <Form.Select id="fabrication-select" fluid label='Fabrication' onChange={this.fabricationOnChange2} options={this.props.fabrications} placeholder='Fabrication' value={this.state.bulkFabrication} />
                <Form.Input fluid label='MSRP ($)' min="1" step="0.01" type="number" onChange={this.msrpOnChange} placeholder='MSRP' value={this.state.msrp} />
              </Form.Group>
              :
              <Form.Group widths='equal'>
                <Form.Select id="color-select" fluid multiple label='Color' onChange={this.colorOnChange2} options={this.props.colors} placeholder='Color' value={this.state.bulkColor} />
                <Form.Select id="fabrication-select" fluid label='Fabrication' onChange={this.fabricationOnChange2} options={this.props.fabrications} placeholder='Fabrication' value={this.state.bulkFabrication} />
                <Form.Select id="packs-select" fluid label='Packs' onChange={this.packsOnChange2} options={this.props.packs} placeholder='Packs' value={this.state.bulkPacks} />
                <Form.Select fluid label='Season' onChange={this.seasonOnChange2} options={this.props.seasons} placeholder='Season' value={this.state.bulkSeason} />
              </Form.Group>
            }

            {
              isDesign
              ?
              null
              :
              <Grid columns={4} stackable style={{marginTop: '-1rem'}}>
                <Grid.Row>
                  <Grid.Column style={{paddingRight: '5px'}}>
                    <Form.Group widths='equal'>
                      <Form.Select fluid label='Deals' onChange={this.statusOnChange2} options={bulkStatusOptions} placeholder='Deals' value={this.state.bulkStatus} />
                    </Form.Group>
                  </Grid.Column>

                  {orgIdsShowEditCost.includes(orgId) ?
                    <Grid.Column style={{paddingRight: '5px'}}>
                      <Form.Group widths='equal'>
                        <Form.Input fluid label='COST ($)' min="1" step="0.01" type="number" onChange={this.costOnChange} placeholder='COST' value={this.state.cost} />
                      </Form.Group>
                    </Grid.Column>
                  : null}

                  <Grid.Column style={{paddingRight: '5px'}}>
                    <Form.Group widths='equal'>
                      <Form.Input fluid label='MSRP ($)' min="1" step="0.01" type="number" onChange={this.msrpOnChange} placeholder='MSRP' value={this.state.msrp} />
                    </Form.Group>
                  </Grid.Column>

                  <Grid.Column style={{paddingRight: '5px'}}>
                    <Form.Group widths='equal'>
                      <Form.Select fluid label='Sizes' onChange={this.sizesOnChange} options={this.props.sizes} placeholder='Sizes' value={this.state.bulkSizes} />
                    </Form.Group>
                  </Grid.Column>

                  {sub2Options.length > 0 ?
                    <Grid.Column style={{paddingRight: '5px'}}>
                      <Form.Group widths='equal'>
                        <Form.Select fluid label={this.state.bulkCategory+'-Category'} onChange={this.sub2OnChange2} options={sub2Options} placeholder={this.state.bulkCategory+'-Category'} value={this.state.sub2} />
                      </Form.Group>
                    </Grid.Column>
                  : null}
                </Grid.Row>
              </Grid>
            }

            {printSelect(this.printOnChange, print, isDesign)}

            {casePackInput(this.casePackOnChange, casePack, isDesign)}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button id="save-bulk-button" type="submit" form="my-bulk-form" primary>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

}

export default BulkEditModal
