import React, {Component} from 'react'
import {refreshButtonStyle} from '../home/refresh-button-style.js'
import Utils from "../../modules/utils"
import OpenOrders from './open-orders.js'
import QuantityChangeLogs from './quantity-change-logs.js'
import RemovedCadLogs from './removed-cad-logs.js'

class ValidateUnits extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      loading: false,
      orders: [],
      stockReportData: {},
      quantityChangeLogs: [],
      removedCadLogs: [],
      addedCadLogs: [],
      cancelOrderLogs: []
    }
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    let {cadId} = this.props
    this.setState({loading: true})

    Utils.get('validate_units/'+cadId, (err, res) =>
    {
      this.setState({loading: false})
      if (err) {
        alert('Error getting validate units data')
      } else {
        this.setState(res.data)
      }
    })
  }

  render() {
    let {cadId} = this.props
    let {
      loading, onHand, futureStock, ats, futureAts, orders, stockReportData, quantityChangeLogs, logOrders, removedCadLogs, logOrders2,
      addedCadLogs, logOrders3, cancelOrderLogs, logOrders4
    } = this.state

    return (
      <div>
        <div style={{float: 'right'}}><button style={refreshButtonStyle} onClick={this.refresh}>refresh</button></div>
        {
          loading
          ?
          'Loading...'
          :
          <div>
            <div style={{display: 'inline-block', textAlign: 'right', lineHeight: '25px'}}>
              <b>Cad id:</b><br/>
              <b>Current Time:</b><br/>
              <b>On Hand:</b><br/>
              <b>ATS:</b><br/>
              <b>Future Stock:</b><br/>
              <b>Future ATS:</b><br/>
              <b>Stock Report Data:</b><br/>
            </div>
            <div style={{display: 'inline-block', paddingLeft: '15px', lineHeight: '25px'}}>
              {cadId}<br/>
              {new Date().toLocaleDateString()} &nbsp; {new Date().toLocaleTimeString()}<br/>
              {onHand}<br/>
              {ats}<br/>
              {futureStock}<br/>
              {futureAts}<br/>
              {JSON.stringify(stockReportData)}
            </div>
            <div>
              <OpenOrders orders={orders} />
            </div>
            <div>
              <QuantityChangeLogs logs={quantityChangeLogs} orders={logOrders}/>
            </div>
            <div>
              <RemovedCadLogs logs={removedCadLogs} orders={logOrders2} title="REMOVED"/>
            </div>
            <div>
              <RemovedCadLogs logs={addedCadLogs} orders={logOrders3} title="ADDED"/>
            </div>
            <div>
              <RemovedCadLogs logs={cancelOrderLogs} orders={logOrders4} title="CANCELLED ORDERS"/>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default ValidateUnits
