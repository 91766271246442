import React, {Component} from 'react'

class AddFabricationPage extends Component {

  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>
        <h1>Add Fabrication</h1>

      </div>
    )
  }
}

export default AddFabricationPage
