import React from 'react'
import {Select} from 'semantic-ui-react'
import {divisionOptions} from './options'


const divisionInput = (division, fieldOnChange) => {
  return (
    <React.Fragment>
      <label>Division</label><br/>
      <Select search name="division" style={{width: '300px'}} placeholder='Select a division'
        options={divisionOptions} onChange={fieldOnChange}
        value={division}
      />
    </React.Fragment>
  )
}

export {divisionInput}
