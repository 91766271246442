const checkLoadingDate = (cad) => {
  if (![null,''].includes(cad.po_detail)) {
    let poDetailRows = cad.po_detail.split(',')

    let hasLoadingDate = poDetailRows.some((row) => {
      let part2 = row.split('—')[1]
      return part2.trim() != ''
    })

    return hasLoadingDate
  } else {
    return false
  }
}

export {checkLoadingDate}
