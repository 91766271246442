import React, {Component} from 'react'
import {Loader, Modal, TextArea, Input, Button} from 'semantic-ui-react'
import Utils from "../../modules/utils"
import {roundToPenny} from '../vendor-orders/round-to-penny.js'

class ConfirmExcel extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      loading: true,
      openEmailModal: false,
      emailSub: '',
      emailBody: '',
      loadingPrint: false
    }

    this.getDownloadLink = this.getDownloadLink.bind(this)
    this.stopLoading = this.stopLoading.bind(this)
    this.closeEmailModal = this.closeEmailModal.bind(this)
    this.openPrintPage = this.openPrintPage.bind(this)
  }

  componentDidMount() {
    let {action} = this.props
    if (action == 'print') {
      this.stopLoading()
      this.setState({loadingPrint: true})
      setTimeout(this.openPrintPage, 100)
    } else {
      this.getDownloadLink()
    }
  }

  openPrintPage() {
    let {successId} = this.props

    let data = {successId}

    Utils.post('confirm_print', data, (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to get the vendor order.')
      } else {
        this.setState({loadingPrint: false})

        let {vpos, styleNumbers, userEmail, contactName, createdAt} = res.data

        let refnumbers = successId.split(',')

        var myWindow=window.open('','','width=800,height=500')
        myWindow.document.write("<h1>Vendor Order</h1>")
        myWindow.document.write("<p>Created at: " + createdAt + "</p>")
        myWindow.document.write("<p>Created by: " + userEmail + "</p>")

        myWindow.document.write("<p>Status: " + vpos[0].status + "</p>")
        myWindow.document.write("<p>Buyer: " + vpos[0].buyer + "</p>")
        myWindow.document.write("<p>Contact: " + contactName + "</p>")

        let total = 0, totalDollar = 0
        try {
          vpos.forEach((vpo) => {
            total += vpo.quantity[0]

            let itemTotal = vpo.quantity[0] * Number(vpo.price[0])

            if (vpo.discount != '' && vpo.discount != null) {
              itemTotal *= (1.0 - Number(vpo.discount)/100.0)
            }

            totalDollar += itemTotal
          })
        } catch (error) {
          console.error(error)
        }

        myWindow.document.write("<p>Unit Total: " + total + "</p>")
        myWindow.document.write("<p>Total $: " + roundToPenny(totalDollar) + "</p>")

        myWindow.document.write("<h3>CAD Details</h3>")

        vpos.forEach((vpo, counter) => {
          let index = 0
          let styleNumber = styleNumbers[vpo.cadIds[0]]
          myWindow.document.write("<h4> Cad "+(counter+1)+": "+styleNumber+"</h4>")
          myWindow.document.write("<pre>REF #: " + refnumbers[counter] + "</pre>")
          myWindow.document.write("<pre>PO #: " + vpo.ponumber + "</pre>")
          myWindow.document.write("<pre>QTY: " + vpo.quantity[index] + "</pre>")
          myWindow.document.write("<pre>QTY Details: " + vpo.quantityMultiple[index] + "</pre>")
          myWindow.document.write("<pre>Size: " + vpo.size[index] + "</pre>")
          myWindow.document.write("<pre>Size Ratio: " + vpo.sizeRatio[index] + "</pre>")
          myWindow.document.write("<pre>Cost: $" + vpo.price[index] + "</pre>")
          myWindow.document.write("<pre>AT: " + vpo.at[index] + "</pre>")
          myWindow.document.write("<pre>MSRP: $" + vpo.msrp + "</pre>")
          myWindow.document.write("<pre>QTY per Polibag: " + vpo.qtyPerPolibag[index] + "</pre>")
          myWindow.document.write("<pre>QTY per Case Pack: " + vpo.casePack[index] + "</pre>")
          myWindow.document.write("<pre>Ex-Factory Date: " + JSON.stringify(vpo.exFactory[index]) + "</pre>")
          myWindow.document.write("<pre>ASN X Factory: " + (vpo.asnxFactory[index] == null ? '—' : JSON.stringify(vpo.asnxFactory[index])) + "</pre>")
          myWindow.document.write("<pre>Cancel Date: " + JSON.stringify(vpo.cancel[index]) + "</pre>")
          myWindow.document.write("<pre>Deliv Date: " + JSON.stringify(vpo.deliv[index]) + "</pre>")
          myWindow.document.write("<pre>Fabrication: " + (vpo.fabrication[index] == null ? '—' : vpo.fabrication[index]) + "</pre>")
          myWindow.document.write("<pre>Factory: " + vpo.factory + "</pre>")
          myWindow.document.write("<pre>Country of Origin: " + vpo.country + "</pre>")
          myWindow.document.write("<pre>Class: " + vpo.shippingClass[index] + "</pre>")
          myWindow.document.write("<pre>Tickets: " + vpo.tickets[index] + "</pre>")
          myWindow.document.write("<pre>Loading Date: " + (vpo.loading[index] == null ? '—' : JSON.stringify(vpo.loading[index])) + "</pre>")
          myWindow.document.write("<pre>FOB RTS: " + (vpo.fobRts[index] == null ? '—' : JSON.stringify(vpo.fobRts[index])) + "</pre>")
          myWindow.document.write("<pre>Container #: " + (vpo.containerNumber[index] == null ? '—' : vpo.containerNumber[index]) + "</pre>")
          myWindow.document.write("<pre>Discount: " + (vpo.discount == null ? '' : vpo.discount.to_s+'%') + "</pre>")
          myWindow.document.write("<pre>Memo: " + (vpo.comments[index] == null ? '—' : vpo.comments[index]) + "</pre>")
        })


        myWindow.document.close()
        myWindow.focus()
        myWindow.print()
        myWindow.close()
      }
    })


  }

  getDownloadLink() {
    let {successId, action} = this.props
    let data = {successId, successPage: true}
    Utils.post((action == 'pdf' ? 'vpo_pdf' : 'vpo_excel'), data, (err, result) => {
      if (err) {
        alert('There was an error when trying to download ' + (action == 'pdf' ? 'pdf' : 'excel'))
      } else {
        this.stopLoading()
        if (action == 'email') {
          let emailSub = 'NEW VPO with REF # ' + successId
          let emailBody = "Here is the link to the VPO Excel...   " + result.data.url
          this.setState({openEmailModal: true, emailSub, emailBody})
          window.location.href = 'mailto:?subject='+emailSub+'&body='+emailBody
        } else if (action == 'pdf') {
          window.open(result.data.url, '_blank', "height=500,width=800")
        } else {
          window.location = result.data.url
        }
      }
    })
  }

  stopLoading() {
    this.setState({loading: false})
  }

  closeEmailModal() {
    this.setState({openEmailModal: false})
  }

  render() {
    let {loading, openEmailModal, emailSub, emailBody, loadingPrint} = this.state
    let {successId, action} = this.props

    return (
      <React.Fragment>
        <div>
          {
            loading
            ?
            <span>
              Creating {action == 'excel' ? 'Excel' : 'PDF'}
              <Loader active={true} inline size='tiny'
                style={{marginLeft: '10px'}}
              />
            </span>
            :
            null
          }
          {
            loadingPrint
            ?
            <span>
              Loading Print page
              <Loader active={true} inline size='tiny'
                style={{marginLeft: '10px'}}
              />
            </span>
            :
            null
          }
        </div>

        <Modal size={'small'} open={openEmailModal}>
          <Modal.Header>Share new VPO by email</Modal.Header>
          <Modal.Content>
            <b>Create an email and copy in the subject and body.</b>
            <Input value={emailSub} label="Email subject" style={{width: '100%', marginTop: '10px'}}/>
            <TextArea value={emailBody} style={{width: '100%', minHeight: '100px', marginTop: '10px', padding: '10px'}} />
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.closeEmailModal}>
              Done
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
}

export default ConfirmExcel
