import React, { Component } from 'react'
import { Form } from 'semantic-ui-react'
import {labelStyle} from './Elements'
import $ from 'jquery'
import {datePlus9ToYYYYMMDD} from './date-inputs.js'

class DatesForNoStyles extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  startShipOnChange(e) {
    let {value} = e.currentTarget

    if (value != '') {
      let cancelDate = datePlus9ToYYYYMMDD(value)
      $('#no-styles-cancel-date').val(cancelDate)
    }
  }

  render() {
    let {styleNumbers, quickbooksAccount, isEditPage} = this.props

    if ((isEditPage && styleNumbers != null && styleNumbers.length == 0) || (quickbooksAccount != null && styleNumbers != null && styleNumbers.length == 1 && styleNumbers[0] == '-')) {
      return (
        <div style={{maxWidth: '1200px', margin: 'auto'}}>
          <Form style={{minHeight: '100px', marginTop: '80px'}}>
            <Form.Group inline>
              <label style={labelStyle}>
                Dates
              </label>
              <span style={{width: '400px', marginLeft: '56px'}}>
                <span style={{color: 'rgb(200,200,200)'}}>Start Ship</span><br/>
                <input id="no-styles-start-ship-date" type="date" form="new-sales-order" onChange={this.startShipOnChange} required />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{width: '400px'}}>
                <span style={{color: 'rgb(200,200,200)'}}>Cancel</span><br/>
                <input id="no-styles-cancel-date" type="date" form="new-sales-order" required />
              </span>
            </Form.Group>
          </Form>
        </div>
      )
    } else {
      return null
    }
  }
}

export default DatesForNoStyles
