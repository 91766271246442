import { Table } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

export function DataTableHeader(props) {
  return (
    <Table.Header>
      <Table.Row>
        {
            props.headings.map((heading, i) =>
            {
                if (heading.visible)
                {
                  let cell =
                      <Table.HeaderCell
                            key={'header-'+i}
                            width={1}
                            sorted={props.column === heading.key ? props.direction : null}
                            onClick={() => props.handleSort(heading.key)}
                          >
                        { heading.displayValue }
                      </Table.HeaderCell>
                  return cell
                }
            })
        }
      </Table.Row>
    </Table.Header>
  );
}

DataTableHeader.propTypes = {
  headings: PropTypes.array.isRequired,
  column: PropTypes.string,
};
