const setRemoveStyles = (cads, brandApproval, approvalBrands) => {
  let removeStyles = []

  cads.forEach((cad) => {
    if (approvalBrands.indexOf(cad.brand) > -1) {
      let approvedBrands = Object.keys(brandApproval)
      if (approvedBrands.indexOf(cad.brand) === -1) {
        removeStyles.push(cad.style_number)
      } else {
        let categories = brandApproval[cad.brand]
        if (categories.length && categories.indexOf(cad.parent_category) === -1) {
          removeStyles.push(cad.style_number)
        }
      }
    }
  })

  return {removeStyles}
}

export {setRemoveStyles}
