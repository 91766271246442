const costMultipleByOrg = (orgId) => {
  switch(orgId) {
    case 2:
      return 1.00
    default:
      return 2.00
  }
}

export {costMultipleByOrg}
