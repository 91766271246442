import React from 'react'
import {Form} from 'semantic-ui-react'
import {labelStyle, InfoCircle} from "./Elements"

const radioButtonStyle = {
  zoom: '140%',
  cursor: 'pointer',
  position: 'relative',
  top: '3px'
}

const radioButtonLabelStyle = {
  fontSize: '120%',
  cursor: 'pointer',
}

const upfrontInfo = 'Ticketed orders are always Upfront orders. From Stock is from current On Hand units.'

const upfrontInput = (upfront, upfrontOnChange) => {
  return (
    <Form style={{minHeight: '100px', marginTop: '80px'}}>
      <Form.Group>
        <label style={labelStyle}>
          Upfront
          {InfoCircle(upfrontInfo)}
        </label>
        <span style={{width: '600px', marginLeft: '83px', padding: '20px 50px', border: '1px solid rgb(222, 222, 222)', borderRadius: '5px'}}>
          <input type="radio" id="upfront-no" name="upfront" style={radioButtonStyle} value="NO" checked={upfront === 'NO'} onChange={upfrontOnChange}
            form="new-sales-order" required
          />
          <label for="upfront-no" style={radioButtonLabelStyle}>NO, FROM STOCK</label> &nbsp;&nbsp;&nbsp;
          <input type="radio" id="upfront-yes" name="upfront" style={radioButtonStyle} value="YES" checked={upfront === 'YES'} onChange={upfrontOnChange}
            form="new-sales-order" required
          />&nbsp;&nbsp;
          <label for="upfront-yes" style={radioButtonLabelStyle}>YES, UPFRONT</label> &nbsp;&nbsp;&nbsp;
        </span>
      </Form.Group>
    </Form>
  )
}

export {upfrontInput, radioButtonStyle, radioButtonLabelStyle}
