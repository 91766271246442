import React, { Component } from 'react'
import $ from 'jquery'
import { Form, Icon, Grid, Button, Label, Modal } from 'semantic-ui-react'
import Utils from "./../../modules/utils"
import ExtraFields from './../edit-sales-order/ExtraFields.js'

const initialBulkEditModalState = {
  shippingCharge: null,
  aged: '',
  agedStyleNumber: '',
  hangers: '',
  reorder: '',
  ticketed: '',
  ticketsOrdered: null,

  ticketsTrackingNumber: '',
  ticketsConfirmationNumber: '',
  topSamplesNeeded: '',
  topSamplesNeededDue: '',
  ppSamplesNeeded: '',
  ppSamplesNeededDue: '',
  richTextMessageHtml: '',
  pendingChanges: false,

  terms: '',
  departmentNumber: '',
  shippingClass: ''
}

class BulkEditModal extends Component {
  constructor(props) {
    super(props)
    this.state = initialBulkEditModalState

    this.onChangeShippingCharge = this.onChangeShippingCharge.bind(this)
    this.saveBulkChangeCads = this.saveBulkChangeCads.bind(this)
    this.agedOnChange = this.agedOnChange.bind(this)
    this.agedStyleNumberOnChange = this.agedStyleNumberOnChange.bind(this)
    this.hangersOnChange = this.hangersOnChange.bind(this)
    this.reorderOnChange = this.reorderOnChange.bind(this)
    this.ticketedOnChange = this.ticketedOnChange.bind(this)

    this.ticketsTrackingNumberOnChange = this.ticketsTrackingNumberOnChange.bind(this)
    this.topSamplesNeededOnChange = this.topSamplesNeededOnChange.bind(this)
    this.topSamplesNeededDueOnChange = this.topSamplesNeededDueOnChange.bind(this)
    this.ppSamplesNeededOnChange = this.ppSamplesNeededOnChange.bind(this)
    this.ppSamplesNeededDueOnChange = this.ppSamplesNeededDueOnChange.bind(this)
    this.richTextMessageHtmlOnchange = this.richTextMessageHtmlOnchange.bind(this)
    this.resetModal = this.resetModal.bind(this)
    this.onClose = this.onClose.bind(this)
    this.ticketsOrderedOnChange = this.ticketsOrderedOnChange.bind(this)
    this.ticketsConfirmationNumberOnChange = this.ticketsConfirmationNumberOnChange.bind(this)

    this.termsOnChange = this.termsOnChange.bind(this)
    this.departmentNumberOnChange = this.departmentNumberOnChange.bind(this)
    this.shippingClassOnChange = this.shippingClassOnChange.bind(this)
  }

  resetModal() {
    this.setState(initialBulkEditModalState)
  }

  onChangeShippingCharge(event, {value}) {
    let new_value = Math.floor(value * 100) / 100
    if (new_value == 0 || new_value == '') {
      new_value = null
    }
    this.setState({shippingCharge: new_value, pendingChanges: true})
  }

  saveBulkChangeCads() {
    let trackingNumber = $('#tracking-number').val()
    let startShipDate = $('#bulk-start-ship-date').val()
    let cancelDate = $('#bulk-cancel-date').val()
    let ticketedDue = $('#ticketed-due').val()

    let {
      shippingCharge, pendingChanges,
      ticketed, ticketsTrackingNumber, topSamplesNeeded, topSamplesNeededDue, ppSamplesNeeded, ppSamplesNeededDue,
      aged, agedStyleNumber, hangers, reorder, richTextMessageHtml, ticketsOrdered, ticketsConfirmationNumber,
      terms, departmentNumber, shippingClass
    } = this.state

    if (trackingNumber == '' && startShipDate == '' && cancelDate == '' && !pendingChanges) {
      alert('Please make at least one change!')
    } else {
      $('#save-bulk-button').addClass('loading disabled')

      let poIds = Object.keys(window.poIds)

      let data = {
        trackingNumber: trackingNumber,
        shippingCharge: (shippingCharge == null ? null : shippingCharge*100),
        po_ids: poIds,
        ticketed, ticketsTrackingNumber, topSamplesNeeded, topSamplesNeededDue, ppSamplesNeeded, ppSamplesNeededDue,
        aged, agedStyleNumber, hangers, reorder, richTextMessageHtml,
        startShipDate, cancelDate, ticketsOrdered, ticketsConfirmationNumber,
        terms, departmentNumber, shippingClass, ticketedDue
      }

      let url = 'bulk_update_sales_orders'

      Utils.post(url, data, (err, result) =>
      {
        if (err) {
          console.log('There was an error when trying to bulk update the orders.')
        } else {
          if (result.data.success) {
            $('#save-bulk-button').removeClass('loading disabled');
            this.onClose();

            setTimeout(() => {
              alert('The bulk changes were saved!')
              this.props.refresh()
            }, 300)
          }
        }
      })
    }
  }

  agedOnChange(e, { value }) {
    this.setState({aged: value, pendingChanges: true})
  }

  agedStyleNumberOnChange(e, { value }) {
    this.setState({agedStyleNumber: value, pendingChanges: true})
  }

  hangersOnChange(e, { value }) {
    this.setState({hangers: value, pendingChanges: true})
  }

  reorderOnChange(e, { value }) {
    this.setState({reorder: value, pendingChanges: true})
  }

  ticketedOnChange(e, { value }) {
    this.setState({ticketed: value, pendingChanges: true})
  }

  ticketsTrackingNumberOnChange(e, { value }) {
    this.setState({ticketsTrackingNumber: value, pendingChanges: true})
  }

  topSamplesNeededOnChange(e, { value }) {
    this.setState({topSamplesNeeded: value, pendingChanges: true})
  }

  topSamplesNeededDueOnChange(e, { value }) {
    this.setState({topSamplesNeededDue: value, pendingChanges: true})
  }

  ppSamplesNeededOnChange(e, { value }) {
    this.setState({ppSamplesNeeded: value, pendingChanges: true})
  }

  ppSamplesNeededDueOnChange(e, { value }) {
    this.setState({ppSamplesNeededDue: value, pendingChanges: true})
  }

  richTextMessageHtmlOnchange(value) {
    this.setState({richTextMessageHtml: value, pendingChanges: true})
  }

  termsOnChange(event) {
    let {value} = event.currentTarget
    this.setState({terms: value, pendingChanges: true})
  }

  departmentNumberOnChange(e, { value }) {
    this.setState({departmentNumber: value, pendingChanges: true})
  }

  shippingClassOnChange(e, { value }) {
    this.setState({shippingClass: value, pendingChanges: true})
  }

  onClose() {
    this.resetModal()
    this.props.onClose()
  }

  onChangeStartShip() {
    let startShipDate = $('#bulk-start-ship-date').val()

    if (startShipDate == '') {
      //console.log('clear date inputs')

    } else {

      let plus_9 = new Date(startShipDate); plus_9.setDate(plus_9.getDate()+9)
      let plus_9_str = plus_9.toISOString().split('T')[0]
      $('#bulk-cancel-date').val(plus_9_str)
    }
  }

  ticketsOrderedOnChange(e, { value }) {
    this.setState({ticketsOrdered: value, pendingChanges: true})
  }

  ticketsConfirmationNumberOnChange(e, { value }) {
    this.setState({ticketsConfirmationNumber: value, pendingChanges: true})
  }

  render() {
    let {shippingCharge} = this.state

    return (
      <Modal size={'large'} open={this.props.open} onClose={this.onClose} closeIcon>
        <Modal.Header>Bulk edit sales orders</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.saveBulkChangeCads} id="my-bulk-form">
            <Form.Group widths='equal'>
              <Form.Field style={{fontSize: '20px', fontWeight: 'bold'}}>
                Start ship and cancel dates
              </Form.Field>
              <Form.Input id="bulk-start-ship-date" type="date" onChange={this.onChangeStartShip}/>
              <Form.Input id="bulk-cancel-date" type="date"/>
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Field style={{fontSize: '20px', fontWeight: 'bold'}}>
                Shipping details
              </Form.Field>
              <Form.Input placeholder='Shipping cost' icon='dollar' iconPosition='left' type="number" min="0" step=".01" onChange={this.onChangeShippingCharge} value={shippingCharge} />
              <Form.Input id="tracking-number" fluid placeholder='Tracking #' icon='hashtag' iconPosition='left' />
            </Form.Group>



            <div>
              <span style={{width: '34%', fontSize: '20px', fontWeight: 'bold', display: 'inline-block', verticalAlign: 'top', paddingLeft: '3px'}}>Extra fields</span>

              <div style={{border: '1px solid rgb(222,222,222)', borderRadius: '5px', width: '66%', padding: '20px 50px', display: 'inline-block'}}>
                <ExtraFields
                  aged={this.state.aged} agedOnChange={this.agedOnChange}
                  agedStyleNumber={this.state.agedStyleNumber} agedStyleNumberOnChange={this.agedStyleNumberOnChange}
                  hangers={this.state.hangers} hangersOnChange={this.hangersOnChange}
                  reorder={this.state.reorder} reorderOnChange={this.reorderOnChange}
                  ticketed={this.state.ticketed} ticketedOnChange={this.ticketedOnChange}
                  ticketsOrderedDate={''}
                  ticketsOrdered={this.state.ticketsOrdered} ticketsOrderedOnChange={this.ticketsOrderedOnChange}
                  ticketsConfirmationNumber={this.state.ticketsConfirmationNumber} ticketsConfirmationNumberOnChange={this.ticketsConfirmationNumberOnChange}

                  ticketsTrackingNumber={this.state.ticketsTrackingNumber} ticketsTrackingNumberOnChange={this.ticketsTrackingNumberOnChange}
                  topSamplesNeeded={this.state.topSamplesNeeded} topSamplesNeededOnChange={this.topSamplesNeededOnChange}
                  topSamplesNeededDue={this.state.topSamplesNeededDue} topSamplesNeededDueOnChange={this.topSamplesNeededDueOnChange}
                  ppSamplesNeeded={this.state.ppSamplesNeeded} ppSamplesNeededOnChange={this.ppSamplesNeededOnChange}
                  ppSamplesNeededDue={this.state.ppSamplesNeededDue} ppSamplesNeededDueOnChange={this.ppSamplesNeededDueOnChange}
                  richTextMessageHtml={this.state.richTextMessageHtml} richTextMessageHtmlOnchange={this.richTextMessageHtmlOnchange}

                  terms={this.state.terms} termsOnChange={this.termsOnChange}
                  departmentNumber={this.state.departmentNumber} departmentNumberOnChange={this.departmentNumberOnChange}
                  shippingClass={this.state.shippingClass} shippingClassOnChange={this.shippingClassOnChange}
                />
              </div>

            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button id="save-bulk-button" type="submit" form="my-bulk-form" primary>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }


}

export default BulkEditModal
