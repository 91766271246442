import React, {Component} from 'react'
import {Message, Popup} from 'semantic-ui-react'
import {BAD_CONTAINER_NUMBERS, BAD_STYLE_NUMBERS} from '../../modules/constants'

class DoNotSellMessage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
    }
  }

  render() {
    let {cad} = this.props

    let hasBadContainerNumber = false

    if (cad.container_number != null) {
      let containerNumbers = cad.container_number.split('@@@')
      hasBadContainerNumber = containerNumbers.some(x => BAD_CONTAINER_NUMBERS.includes(x))
    }

    let hasBadStyleNumber = BAD_STYLE_NUMBERS.includes(cad.style_number)

    if (hasBadContainerNumber) {
      return (
        <Popup content='Issue reported with Container #' trigger={
          <Message
            icon='warning sign'
            color='red'
            header={<h2>Do not sell</h2>}
            size='mini'
            style={{cursor: 'pointer'}}
          />
        } />
      )
    } else if (hasBadStyleNumber) {
      return (
        <Popup content='Issue reported with Style #' trigger={
          <Message
            icon='warning sign'
            color='red'
            header={<h2>Do not sell</h2>}
            size='mini'
            style={{cursor: 'pointer'}}
          />
        } />
      )
    } else {
      return null
    }
  }
}

export default DoNotSellMessage
