const checkMissingDates = (data) => {
  let {cad_ids, startShipDate, cancelDate, styleNumbers, no_styles_cancel_date, no_styles_start_ship_date} = data

  if (styleNumbers.length == 1 && styleNumbers[0] == '-') {
    return !(validDate(no_styles_cancel_date) && validDate(no_styles_start_ship_date))
  }

  if (cad_ids == null || cad_ids.length == 0) {
    return false
  }

  let startShipDates = cad_ids.map((cadId, index) => {
    return startShipDate[getKey(cadId, styleNumbers[index])]
  })

  let cancelDates = cad_ids.map((cadId, index) => {
    return cancelDate[getKey(cadId, styleNumbers[index])]
  })

  let validStartShipDates = startShipDates.map(validDate)
  let validCancelDates = cancelDates.map(validDate)

  return validStartShipDates.includes(false) || validCancelDates.includes(false)
}

const validDate = (date) => {
  return date != null && date != '' && date.includes('-')
}

const getKey = (cadId, styleNumber) => {
  if (cadId == null || cadId == 'null') {
    return styleNumber.toLowerCase().replace(/-/g, '_')
  } else {
    return cadId
  }
}

export {checkMissingDates}
