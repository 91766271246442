import React                                from 'react';
import {
  Segment,
  Dropdown,
  Button,
  Icon,
  Grid,
  Checkbox }                        from 'semantic-ui-react';
import { DataTable }                from './DataTable.jsx';
import { DataFilter }               from './DataFilter.jsx';
import { FilterRow }                from './FilterRow.js';
import { debounce, orderBy, filter }from 'lodash';
import { Link }                     from 'react-router-dom'
import Constants                    from '../../../../modules/constants'
import Utils                        from '../../../../modules/utils'

let rowFilterState =
{
  account    : 'all',
  title      : 'all',
  status     : Constants.STATUS_FILTER_LIST[0],
  division   : 'all',
  channel    : 'all',
  region     : 'all',
  category   : 'all',
  accountRep : 'all',
}

export default class DataList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data          : this.props.columns || [],
      _sort         : this.props.sortColumn,
      _page         : 1,
      _order        : null,
      _limit        : 10,
      q             : '',
      totalCount    : 0,
      loading       : false,
      showFilterRow : false,
      filterColumns : {...rowFilterState}
    };
    this.onSubmitFilter = debounce(this.onSubmitFilter, 500);
  }

  handleSort = (clickedColumn, selectSort=null) => {
    const { _sort, _order, data } = this.state;
    let newOrder, sortedData;
    if (selectSort === null)
    {
      newOrder = _order === 'asc' ? 'desc' : 'asc';
      if (_sort !== clickedColumn) {
        newOrder = 'asc';
      }
    }
    else
    {
      newOrder = selectSort._order
      clickedColumn = selectSort._sort
    }

    if (clickedColumn === 'account_name'){
      sortedData = orderBy(data, ['account_name.value','primary_contact_name.value'], [newOrder, 'asc']);
    }
    else
    {
      sortedData = orderBy(data, [`${clickedColumn}.value`], [newOrder])
    }

    this.loadData({
      _sort  : clickedColumn,
      _page  : 1,
      _order : newOrder,
      data   : sortedData
    });
  };

  componentDidMount() {
    this.setState({ loading: false, totalCount: this.props.columns.length }, () => this.handleSort(this.state._sort));
  }

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  toggleVisiblity = (heading) => {
    heading.visible = !heading.visible
    this.setState(this.state, Utils.setStore('account-table-headings', this.props.headings))
  };

  onSubmitFilter = query => {
    if (query !== this.state.q) {
      let data = this.props.queryFilter(this.props.columns, query);
      this.loadData({ data, q: query, _page: 1 });
    }
  };

  applyRowFilters = (filterColumns) => {
    let data = this.props.filterRows(this.props.columns, filterColumns);
    this.loadData({ data, filterColumns, _page: 1 });
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  loadData = params => {
    this.setState(params)
  };

  toggleFilterRow = () =>{
    this.setState({showFilterRow: !this.state.showFilterRow})
  };

  render() {
    let state           = this.state
    let {showFilterRow, filterColumns} = state;
    let queryString     = state.q
    let columns         = state.data;
    let headings        = this.props.headings
    let totalCount      = this.state.data.length
    let startIndex      = (state._page - 1) * state._limit
    let limit           = state._limit*state._page

    columns = columns.slice(startIndex, limit)
    return (
      <div>
        <h1 style={{marginTop: '25px', marginBottom: '35px'}}>{totalCount} contact{totalCount == 1 ? '' : 's'} found</h1>
        <Grid>
          <Grid.Row>
            <Grid.Column >
              <DataFilter
                filter={this.state.q}
                onSubmitFilter={this.onSubmitFilter}
                loading={this.state.loading}
                style={{float: 'right', width: '32%', position: 'relative', bottom: '70px'}}
              />
              <Dropdown trigger={<Button basic icon>
                <Icon name='eye' />
                </Button>}
                simple
                item
                icon={null}
                >
                {
                  <Dropdown.Menu direction='right'>
                  <Dropdown.Item>Show / Hide Fields</Dropdown.Item>
                  <Dropdown.Divider />
                  {
                    orderBy(this.props.headings, ['displayValue'], ['asc']).map((heading, i) =>
                    {
                      return <Dropdown.Item key={i}>
                      {
                        <Checkbox
                        checked={heading.visible}
                        label={{ children: heading.displayValue }}
                        onChange={(e, { checked }) => {
                          this.toggleVisiblity(heading)
                          }
                        }
                        />
                      }
                    </Dropdown.Item>
                    })
                  }
                  </Dropdown.Menu>
                }
              </Dropdown>

              <Button
                basic
                color={showFilterRow ? 'blue' : null}
                onClick={this.toggleFilterRow}>
                <Icon name='filter' /> Filters
              </Button>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{display: showFilterRow ? 'block' : 'none'}}>
            <Grid.Column>
              <FilterRow
                applyRowFilters={this.applyRowFilters}
                initialFilterState={{...rowFilterState}}
              />
            </Grid.Column>
           </Grid.Row>
        </Grid>

        <DataTable
          data={this.state.data}
          totalCount={totalCount}
          totalPages={Math.ceil(totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={Utils.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
          headings={this.props.headings}
          columns={columns}
          filter={this.state.q}
          noContentMessage={this.props.noContentMessage}
          />
      </div>
    );
  }
}
