import React from 'react'
import {Message, Icon} from 'semantic-ui-react'


const removeStylesMessage = (removeStyles) => {
  return (
    removeStyles.length == 0
    ?
    null
    :
    <Message icon warning>
      <Icon name='warning sign'/>
      <Message.Content>
        <Message.Header>The following cads are not brand approved for account</Message.Header>
        <p>
          <ul>
            {removeStyles.map((style, index) => {
              return (
                <li key={index}>{style}</li>
              )
            })}
          </ul>
        </p>
      </Message.Content>
    </Message>
  )
}

export {removeStylesMessage}
