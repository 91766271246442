import React, { Component } from 'react'
import SectionTitle from '../new-vpo-page/section-title'
import ContinueButton from '../new-vpo-page/continue-button'
import {updateProperty} from './update'

class UpdateProperty extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submittingForm: false
    }

    this.saveProperty = this.saveProperty.bind(this)
  }

  saveProperty() {
    let {property} = this.props
    this.setState({submittingForm: true})
    property['assetId'] = this.props.getAssetId()
    updateProperty(property, (err, res) => {
      if (err) {
        alert('There was an error when trying to update the property.')
      } else {
        window.location.reload()
      }
    })
  }

  render() {
    let {titleNum, isActive} = this.props
    let {submittingForm} = this.state

    return (
      <div>
        <SectionTitle num={titleNum} title='UPDATE PROPERTY' complete={false}/>

        {
          isActive
          ?
          <ContinueButton markAsComplete={this.saveProperty} complete={true} text='SAVE'
            submittingForm={submittingForm}
          />
          :
          null
        }
      </div>
    )
  }
}

export default UpdateProperty
