import React, {useState} from 'react'
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js'

import CardSection from './CardSection'

import $ from 'jquery';

import { Popup, Button } from 'semantic-ui-react'


export default function CardSetupForm(props) {
  const [error, setError] = useState(null);
  const stripe = useStripe()
  const elements = useElements()

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    let name = $('#name-on-card').val()
    let email = $('#email-for-card').val()

    let billing_details = {name: name}

    if (email != '') {
      billing_details.email = email
    }

    $('#save-card-button').addClass('loading disabled')

    const result = await stripe.confirmCardSetup(props.accountClientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billing_details,
      }
    })

    if (result.error) {
      // Display result.error.message in your UI.
      setError(result.error.message);

    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
      setError(null)

      props.updatePaymentMethods(result.setupIntent.payment_method)
    }
  }

  const removeMethod = (id) => {
    if (window.confirm('Are you sure you want to remove the payment method?')) {
      props.removePaymentMethod(id)
    }
  }

  //console.log(props.accountPaymentMethods)

  if (props.account == null || props.accountClientSecret == null) {
    return <div>Select an existing account, or save a new customer, to enter credit card details</div>
  } else {
    return (
      <div>
        {
          props.accountPaymentMethods.length > 0 ?
          <div>
            <h4>Select saved payment method</h4>
            {props.accountPaymentMethods.map(function(row, index) {
              return (
                <p key={index}>
                  <label style={{cursor: 'pointer'}}>
                    <input id={row.id} type="radio" name="payment_method" form="new-purchase-order" required value={row.id} style={{cursor: 'pointer'}}/> &nbsp;&nbsp;&nbsp;&nbsp;
                    {row.card.brand.toUpperCase()} &nbsp;&nbsp;&nbsp;	•••• {row.card.last4}	&nbsp;&nbsp;&nbsp; {row.card.exp_month} / {row.card.exp_year}	&nbsp;&nbsp;&nbsp;

                    {row.billing_details.address.postal_code}	&nbsp;&nbsp;&nbsp;

                    {row.billing_details.name} &nbsp;&nbsp;&nbsp;
                    {row.billing_details.email} &nbsp;&nbsp;&nbsp;
                  </label>
                  <Popup content='Remove Payment Method' trigger={<Button onClick={removeMethod.bind(null, row.id)} color='red' basic icon='trash' style={{marginLeft: '10px', padding: '5px'}}/>} />
                </p>
              )
            })}
            <label style={{cursor: 'pointer'}}><input type="radio" name="payment_method" form="new-purchase-order" required value="pay-later" style={{cursor: 'pointer'}}/> &nbsp;&nbsp;&nbsp; Pay later</label>
          </div>
          :
          <div>No saved payment methods!</div>
        }
        <br/><br/>
        {props.showPaymentForm ?
          <form onSubmit={handleSubmit}>
            <CardSection account={props.account} error={error}/>
            <button id="save-card-button" className="ui button blue" style={{marginTop: '10px'}} disabled={!stripe}>Save Card</button>
          </form>
        : null}
      </div>
    )
  }

}
