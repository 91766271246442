import React, {Component} from 'react'
import {Image} from 'semantic-ui-react'


class SpsXmlFileKey extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    const {spsXmlFileKey} = this.props

    return (
      spsXmlFileKey != null
      ?
      <div style={{marginTop: '-50px'}}>
        <Image src='https://erp-rails-usa-production.s3.amazonaws.com/images/sps_logo.svg' avatar />
        <span style={{color: 'rgb(200, 200, 200)', fontWeight: 'bold'}}>XML file key:</span>
        &nbsp;&nbsp;
        {spsXmlFileKey}
      </div>
      :
      null
    )
  }
}

export default SpsXmlFileKey
