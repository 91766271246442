import React, {Component} from 'react'
import {styleNumberMessage} from './style-number-message'
import {Grid} from 'semantic-ui-react'
import {familyIdInput} from '../family-id-input'
import {colorInput} from '../color-input'
import {patternInput} from './pattern-input'
import {seasonInput} from './season-input'

const br = <div><br/><br/><br/></div>

class PrintsForm extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      familyId: '0000',
      color: '300',
      pattern: 'AB',
      season: 'F'
    }

    this.fieldOnChange = this.fieldOnChange.bind(this)
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}
    if (name == 'familyId') {
      value = value.substring(0, 4)
    }

    if (name == 'color') {
      value = value.substring(0, 3)
    }
    newState[name] = value
    this.setState(newState)
  }

  render() {
    let {show} = this.props
    let {familyId, color, pattern, season} = this.state

    return (
      <div style={show ? {} : {display: 'none'}}>
        {styleNumberMessage(familyId, color, pattern, season)}

        <Grid divided columns={2} style={{fontSize: '120%', marginTop: 0}}>
          <Grid.Row>
            <Grid.Column style={{paddingLeft: '32px', paddingTop: '25px'}}>
              {familyIdInput(familyId, this.fieldOnChange, null, null)}
            </Grid.Column>
            <Grid.Column style={{paddingBottom: '50px', paddingLeft: '32px', paddingTop: '25px'}}>
              {colorInput(color, this.fieldOnChange)}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div style={{borderTop: '1px solid rgb(240,240,240)'}}/>

        <Grid divided columns={2} style={{fontSize: '120%'}}>
          <Grid.Row>
            <Grid.Column style={{paddingTop: '25px', paddingLeft: '32px'}}>
              {patternInput(pattern, this.fieldOnChange)}
            </Grid.Column>
            <Grid.Column style={{paddingTop: '25px', paddingLeft: '32px', paddingBottom: '50px'}}>
              {seasonInput(season, this.fieldOnChange)}
            </Grid.Column>
          </Grid.Row>
        </Grid>



      </div>
    )
  }
}

export default PrintsForm
