import React      from 'react'
import {
          Form,
          Grid,
          Input,
          Button,
          Icon
       }          from 'semantic-ui-react'
import Utils      from './../../../modules/utils'


class SizeInput extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading : true,
      edit    : false,
      size    : this.props.size || null,
      inputs  :
      {
        name :
        {
          value   : "",
          valid   : null,
          required: true,
          message : ""
        }
      }
    }
  }

  componentDidMount()
  {
    var {size} = this.props
    if (size && size.id)
    {
      this.setInputFromSize()
    }
    else
    {
      this.setState({loading: false})
    }
  }

  componentDidUpdate()
  {
    if (this.props.size !== this.state.size)
    {
      this.setState({size: this.props.size})
    }
  }

  setInputFromSize = () =>
  {
    let {size, inputs} = this.state
    if (!size) return;

    Object.keys(inputs).forEach((key) =>
    {
      let input = inputs[key]

      switch (key)
      {
        case "name"  :
        {
          input.valid = true
          input.value = size[key]
          break
        }
        default :
        {

        }
      }
    })

    this.setState({inputs, loading: false})
  }

  handleChange = (e, data) =>
  {
    let {inputs, size} = this.state
    let input    = inputs[data.name]
    input.value  = data.value

    switch (data.name)
    {
      case "name" :
      {
        input.value   = input.value.toUpperCase()
        let required  = input.required && input.value.length === 0
        let validate  = !required ? this.props.validate(input.value, 'sizes') : true
        input.valid   = !required && validate
        input.message = required ? "Value should not be empty" : "Name should be unique"
        break
      }
      default :
      {

      }
    }

    this.setState({inputs})
  }

  handleSubmit = (e) =>
  {
    e.preventDefault()
    let {size, inputs} = this.state
    let {data, valid} = this.getData()
    let sizeId = size && size.id

    var callback = (error, data) =>
    {
      if(error)
      {
        let errors = error.response.data
        let errorMessage = Object.keys(errors).map((key) =>
        {
          return `${key}: ${errors[key].join(', ')}`
        })
        inputs.name.message = errorMessage
        this.setState({inputs})
      }
      else
      {
        if (sizeId)
        {
          this.setState({edit: false})
        }
        else
        {
          inputs.name.value = ""
          inputs.name.valid = null
          this.setState({inputs})
        }
      }
    }

    this.props.handleSubmit({size: data}, sizeId, 'sizes', callback)
  }

  handleDestroy = (e) =>
  {
    e.preventDefault()
    var {size} = this.state
    this.props.handleDestroy(size.id, 'sizes')
  }

  enableEdit = () =>
  {
    this.setState({edit: true})
  }

  getData = () =>
  {
    let {inputs} = this.state
    let data     = {}
    data.name    = Utils.trim(inputs.name.value)

    return {data, valid: this.isInputsValid()}
  }

  isInputsValid = () =>
  {
    let {inputs} = this.state
    let valid    = Utils.isInputsValidated(inputs)
    return valid
  }

  render()
  {
    let {inputs, size, loading, edit} = this.state
    let isOld    = size && size.id
    let editable = isOld && !edit
    let isValid  = this.isInputsValid()

    let markup =
      loading ? null
      :
      <Grid.Row>
        <Grid.Column width={14}>
          <Form.Field
            required
            control={Input}
            placeholder='Size Name'
            name='name'
            readOnly={editable}
            value={inputs.name.value}
            onChange={(e, {value}) => this.handleChange(e, {name: 'name', value: value})}
            error={inputs.name.valid == false ? {
              content: inputs.name.message
            } : false }
          />
        </Grid.Column>
        {
          isOld ?
          <Grid.Column width={2} style={{display: 'flex', alignItems: 'center'}}>
            {
              this.state.edit ?
              <Icon
                style={{marginRight: '15px', cursor: 'pointer'}}
                name='check'
                color='green'
                disabled={!isValid}
                onClick={this.handleSubmit}
              />
              :
              <Icon
                style={{marginRight: '15px', cursor: 'pointer'}}
                name='pencil'
                onClick={this.enableEdit}
              />
            }
            <Icon
              style={{cursor: 'pointer'}}
              name='trash alternate'
              color='red'
              onClick={this.handleDestroy}
            />
          </Grid.Column>
          :
          <Grid.Column width={2}>
            <Button
              primary
              content="Add"
              disabled={!isValid}
              onClick={this.handleSubmit}
            />
          </Grid.Column>
        }
      </Grid.Row>

    return markup;
  }
}

export default SizeInput
