import Utils from "../../modules/utils"

const searchToUnarchiveBulk = (styleNumbers) => {
  let data = {styleNumbers}

  Utils.post('search_to_unarchive_bulk', data, (err, res) =>
  {
    if (err) {
      // alert('There was an error when trying to check if Cad is archived.')
    } else {
      if (res.data.archived) {
        if (window.confirm('Archived CADs found! Do you want to make ACTIVE?')) {
          unarchiveBulk(styleNumbers)
        }
      }
    }
  })
}

const searchToUnarchive = (styleNumber) => {
  let data = {styleNumber}

  Utils.post('search_to_unarchive', data, (err, res) =>
  {
    if (err) {
      // alert('There was an error when trying to check if Cad is archived.')
    } else {
      if (res.data.archived) {
        if (window.confirm('Archived CADs found! Do you want to make ACTIVE?')) {
          unarchive(styleNumber)
        }
      }
    }
  })
}

const unarchiveBulk = (styleNumbers) => {
  let data = {styleNumbers}

  Utils.post('unarchive', data, (err, res) =>
  {
    if (err) {
      alert('There was an error when trying to unarchive.')
    } else {
      alert('CADs are now ACTIVE.')
    }
  })
}

const unarchive = (styleNumber) => {
  let data = {styleNumber}

  Utils.post('unarchive', data, (err, res) =>
  {
    if (err) {
      alert('There was an error when trying to unarchive.')
    } else {
      alert('CADs are now ACTIVE.')
    }
  })
}

export {searchToUnarchive, searchToUnarchiveBulk}
