import React, { Component } from 'react'
import $ from 'jquery'
import {Input, Popup, Button, Modal, TextArea, Dropdown} from 'semantic-ui-react'
import {buttonStyle} from './button-style'
import {files} from '../contacts/files'
import {saveProperty} from './save'
import {buildingTypeOptions, sourceOptions} from './options'
import {getAssetId} from './get-asset-id'
import ImageCropper from '../edit-property/image-cropper'

class NewPropertyButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      filenames: [],
      buildingType: null,
      source: null,
      buildingSize: null,
      roomsNumber: null,
      cropperOpen: false,
      cropperFilename: null,
      cropperFilenames: []
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.addFilename = this.addFilename.bind(this)
    this.removeFilename = this.removeFilename.bind(this)
    this.saveProperty = this.saveProperty.bind(this)
    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.getAssetId = this.getAssetId.bind(this)
    this.missingRequiredFields = this.missingRequiredFields.bind(this)
    this.openImageCropperBulk = this.openImageCropperBulk.bind(this)
    this.cropperCloseModal = this.cropperCloseModal.bind(this)
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}
    newState[name] = value
    this.setState(newState)
  }

  openModal() {
    this.setState({open: true, filenames: [], buildingType: null, source: null, buildingSize: null, roomsNumber: null})
  }

  closeModal() {
    this.setState({open: false})
  }

  addFilename(filename) {
    let {filenames} = this.state
    filenames.push(filename)
    this.setState({filenames})
  }

  removeFilename(filename) {
    let {filenames} = this.state
    filenames = filenames.filter(x => x != filename)
    this.setState({filenames})
  }

  getAssetId() {
    let {buildingType, source, buildingSize, roomsNumber} = this.state
    return getAssetId(buildingType, source, buildingSize, roomsNumber)
  }

  missingRequiredFields() {
    let {filenames, buildingType, source, buildingSize, roomsNumber} = this.state
    let address = $('#newPropertyAddress').val()
    let description = $('#newPropertyDescription').val()

    if (address.trim().length < 3) {
      alert('Address is required!')
      $("#newPropertyAddress").trigger("focus")
      return true
    }

    if (description.trim().length < 3) {
      alert('Description is required!')
      $("#newPropertyDescription").trigger("focus")
      return true
    }

    if (buildingType == null) {
      alert('Type is required!')
      return true
    }

    if (source == null) {
      alert('Source is required!')
      return true
    }

    if (buildingSize == null) {
      alert('Building Size is required!')
      $("#newPropertyBuildingSize").trigger("focus")
      return true
    }

    if (roomsNumber == null) {
      alert('Rooms # is required!')
      $("#newPropertyRoomsNumber").trigger("focus")
      return true
    }

    if (filenames.length == 0) {
      alert('File is required!')
      return true
    }

    return false
  }

  saveProperty() {
    if (this.missingRequiredFields()) {
      return
    }


    let {filenames, buildingType, source, buildingSize, roomsNumber} = this.state
    let description = $('#newPropertyDescription').val()
    let address = $('#newPropertyAddress').val()

    let assetId = this.getAssetId()

    const data = {property: {description, filenames, address, buildingType, source, buildingSize, roomsNumber, assetId}}

    saveProperty(data, (err, res) => {
      if (err) {
        alert('There was an error when trying to save the property.')
      } else {
        this.closeModal()
        this.openImageCropperBulk(filenames)
      }
    })
  }

  openImageCropperBulk(filenames) {
    let firstElement = filenames.shift()
    this.setState({cropperOpen: true, cropperFilename: firstElement, cropperFilenames: filenames})
  }

  cropperCloseModal() {
    let {getProperties} = this.props
    let {cropperFilenames} = this.state
    if (cropperFilenames.length > 0) {
      this.setState({cropperOpen: false, cropperFilename: null})
      setTimeout(() => {this.openImageCropperBulk(cropperFilenames)},1000)
    } else {
      this.setState({cropperOpen: false, cropperFilename: null})
      getProperties()
    }
  }

  render() {
    let {open, filenames, buildingType, source, buildingSize, roomsNumber, cropperOpen, cropperFilename} = this.state

    return (
      <React.Fragment>
        <Modal
          open={open}
        >
          <Modal.Header>New Property</Modal.Header>
          <Modal.Content>
            <Input placeholder='Enter address...' label='Address' fluid id="newPropertyAddress" />
            <br/>
            <TextArea placeholder='Enter description...' rows={5} id="newPropertyDescription"/>
            <br/>
            <br/>
            <div style={{float: 'right'}}><u>EXAMPLE</u> Asset id: R***-{this.getAssetId()}</div>
            <b>Classification Fields</b><br/><br/>
            <Dropdown placeholder='Type' name='buildingType' selection onChange={this.fieldOnChange} options={buildingTypeOptions} style={{width: '48%'}}/>
            <Dropdown placeholder='Source' name='source' selection onChange={this.fieldOnChange} options={sourceOptions} style={{marginLeft: '10px', width: '48%'}}/>
            <br/>
            <Input type="number" id="newPropertyBuildingSize" name='buildingSize' step="1" min="0" placeholder='Enter building size (sqft)' label='Building Size (sqft)' onChange={this.fieldOnChange} style={{marginTop: '10px', width: '48%'}}/>

            <Input type="number" id="newPropertyRoomsNumber" name='roomsNumber' step="1" min="0" placeholder='Enter rooms #' label='Rooms #' onChange={this.fieldOnChange} style={{marginLeft: '10px', width: '48%'}}/>

            <br/><br/>
            <b>Images</b>
            {files('property_images', filenames, this.addFilename, this.removeFilename)}
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              content="Save property"
              labelPosition='right'
              icon='checkmark'
              onClick={this.saveProperty}
              positive
            />
          </Modal.Actions>
        </Modal>
        {
          cropperOpen
          ?
          <ImageCropper open={true} closeModal={this.cropperCloseModal} filename={cropperFilename} showCancel={false}/>
          :
          null
        }
        <Popup content='New property' position='bottom left'
          trigger={
            <Button basic icon='plus' onClick={this.openModal} style={buttonStyle}/>
          }
        />
      </React.Fragment>
    )
  }
}

export default NewPropertyButton
