const sortMapIdNameOptions = (objects) => {
  return objects.sort(function(a, b) {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1
    }

    return 0
  }).map(x => {return ({ key: x.id, text: x.name, value: x.id })})
}

const sortMapNameOptions = (objects) => {
  return objects.sort(function(a, b) {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1
    }

    return 0
  }).map(x => {return ({ key: x.name, text: x.name, value: x.name })})
}

const sortMapOptions = (objects) => {
  return objects.sort().map(x => {return ({ key: x, text: x, value: x })})
}

export {sortMapIdNameOptions, sortMapNameOptions, sortMapOptions}
