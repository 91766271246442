import Utils from '../../modules/utils'

const createPrint = (data, addLogMessage) => {
  let filename = data.cad_filename

  Utils.post('prints', {filename}, (err, res) =>
  {
    if (err) {
      alert('There was an error when trying to create the print!')
    } else {
      let {styleNumber, autoLinked} = res.data

      let printType = styleNumber[0] == 'P' ? 'PRINT' : 'GRAPHIC'

      if (res.data.alreadyTaken) {
        addLogMessage('A ' + printType + ' already exists with style number "' + styleNumber + '", the image has been updated!')
      } else {
        let autoLinkedText = autoLinked == 0 ? '' : ', '+autoLinked+' CADs were auto-linked'
        addLogMessage('A new ' + printType + ' has successfully been created with style number "' + styleNumber + '"' + autoLinkedText)
      }
    }
  })
}

export {createPrint}
