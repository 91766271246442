const checkCadsForCustomAccess = (cads) => {
  let myCustomerOptions = window.currentUserCustomAccess

  if (myCustomerOptions == null) {
    return false
  } else if (Object.keys(myCustomerOptions).filter(x => cadMatchFields.includes(x)).length == 0) {
    return false
  } else {
    return !cads.some(cad => cadMatch(cad, myCustomerOptions))
  }
}

const cadMatchFields = ['division','category','brand','subCategory']

const cadMatch = (cad, myCustomerOptions) => {
  if (cad == null) return false
  let keys = Object.keys(myCustomerOptions).filter(x => cadMatchFields.includes(x))
  return keys.some(key => myCustomerOptions[key].includes(getCadValue(cad, key)))
}

const getCadValue = (cad, key) => {
  switch(key) {
    case 'division':
      return cad.department
    case 'category':
      return cad.parent_category
    case 'brand':
      return cad.brand
    case 'subCategory':
      return cad.category
    default:
      // code block
  }
}

export {checkCadsForCustomAccess}
