import React, {Component} from 'react'
import {Button, Popup} from 'semantic-ui-react'
import {requestBrandApproval, approveCadLicense} from './brand-approval/index.js'

class BulkBrandApproval extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}

    this.requestBrandApproval = this.requestBrandApproval.bind(this)
    this.approveBrand = this.approveBrand.bind(this)
    this.rejectBrand = this.rejectBrand.bind(this)
  }

  requestBrandApproval() {
    var keys = Object.keys(window.cadIds)
    if (keys.length == 0) {
      alert('Select at least 1 CAD')
    } else {
      requestBrandApproval(keys, this.props.clearCadCard)
    }
  }

  approveBrand() {
    var keys = Object.keys(window.cadIds)
    if (keys.length == 0) {
      alert('Select at least 1 CAD')
    } else {
      approveCadLicense(keys, true, this.props.clearCadCard)
    }
  }

  rejectBrand() {
    var keys = Object.keys(window.cadIds);
    if (keys.length == 0) {
      alert('Select at least 1 CAD')
    } else {
      approveCadLicense(keys, false, this.props.clearCadCard)
    }
  }

  render() {
    let {canApproveBrands} = this.props
    
    if (canApproveBrands) {
      return (
        <React.Fragment>
          <div style={{display:'inline-block', position: 'relative'}}>
            <div style={{position: 'absolute', fontWeight: 'bold', borderBottom: '2px solid black', top: '-65px', width: '154px', textAlign: 'center', paddingBottom: '3px'}}>Brand Approval</div>
            <div style={{position: 'absolute', height: '9px', borderLeft: '2px solid black', borderRight: '2px solid black', width: '154px', top: '-42px'}}/>
          </div>
          <Popup content='Request Brand Approval' position='bottom left' trigger={<Button basic icon='warning sign' color='red' onClick={this.requestBrandApproval} style={{padding: '14px', marginRight: '10px'}}/>} />
          <Popup content='Approve Brand' position='bottom left' trigger={<Button basic icon='check' color='green' onClick={this.approveBrand} style={{padding: '14px', marginRight: '10px'}}/>} />
          <Popup content='Reject Brand' position='bottom left' trigger={<Button basic icon='x' color='black' onClick={this.rejectBrand} style={{padding: '14px', marginRight: '10px'}}/>} />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Popup content='Request Brand Approval' position='bottom left' trigger={<Button basic icon='warning sign' color='red' onClick={this.requestBrandApproval} style={{padding: '14px', marginRight: '10px'}}/>} />
        </React.Fragment>
      )
    }
  }
}

export default BulkBrandApproval
