import React from 'react'
import {Modal, TextArea} from 'semantic-ui-react'
import BestSellersForm from './form'

const bestSellersModal = (open, closeModal, cad, salesHistoryAccounts) => {
  return (
    <Modal
      onClose={closeModal}
      open={open}
    >
      <Modal.Header>
      Edit best sellers
      <div style={{float: 'right', fontSize: '60%', lineHeight: '1.4'}}>
        style number = {cad == null ? '' : cad.style_number} <br/>
        cad id = {cad == null ? '' : cad.id}
      </div>
      </Modal.Header>
      <BestSellersForm cad={cad} closeModal={closeModal} salesHistoryAccounts={salesHistoryAccounts}/>
    </Modal>
  )
}

export {bestSellersModal}
