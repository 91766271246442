import React from 'react'
import {Select} from 'semantic-ui-react'
import {subcategoryOptions} from './options'
import {subcategoryInputMessage} from './subcategory-input-message'

const subcategoryInput = (subcategory, fieldOnChange, showApplicableSubcategories, category, division, packs) => {

  let options = showApplicableSubcategories ? filterApplicable(subcategoryOptions, category, division, packs) : subcategoryOptions

  return (
    <React.Fragment>
      <label>Sub-Category [Sub2] <small style={{opacity: 0.4, marginLeft: '20px'}}>(Can depend on Division or Category or Packs)</small></label><br/>
      <Select search name="subcategory" style={{width: '500px'}} placeholder='Select a sub-category'
        options={options} onChange={fieldOnChange}
        value={subcategory}
      />
      <br/><br/>
      {subcategoryInputMessage(subcategory, options, category, division, packs)}
    </React.Fragment>
  )
}

const filterApplicable = (subcategoryOptions, category, division, packs) => {
  return subcategoryOptions.filter((option) => {
    let {category_char, category_char_not, division_char, division_char_not, packs_char, packs_char_not} = option
    return (
      (category_char == null || category_char.includes(category))
      &&
      (category_char_not == null || !category_char_not.includes(category))
      &&
      (
        ['P','H'].includes(division)
        ?
        (division_char != null && division_char.includes(division)) || division_char_not != null
        :
        (division_char == null || division_char.includes(division))
      )
      &&
      (division_char_not == null || !division_char_not.includes(division))
      &&
      (packs_char == null || packs_char.includes(packs))
      &&
      (packs_char_not == null || !packs_char_not.includes(packs))
    )
  })
}

export {subcategoryInput, filterApplicable}
