const uploadMessagesStyle = {
  float: 'right',
  padding: '10px',
  textAlign: 'right',
  background: 'lightgrey',
  opacity: 0.5,
  marginTop: '20px',
  marginRight: '20px',
  pointerEvents: 'none',
  maxHeight: 'calc(100vh - 231px)',
  overflow: 'hidden',
  fontSize: '125%'
}

export {uploadMessagesStyle}
