import {roundToPenny} from '../vendor-orders/round-to-penny.js'

const sumShipped = (invoices, defaultAccount) => {
  return sumInvoicesOfType(invoices, 'shipped', true, defaultAccount);
}

const sumShippedDollar = (invoices, defaultAccount) => {
  return sumInvoicesOfType(invoices, 'shipped', false, defaultAccount);
}

const sumSalesOrders = (invoices, defaultAccount) => {
  return sumInvoicesOfType(invoices, 'sales order', true, defaultAccount);
}

const sumSalesOrdersDollar = (invoices, defaultAccount) => {
  return sumInvoicesOfType(invoices, 'sales order', false, defaultAccount);
}

const sumHold = (invoices, defaultAccount) => {
  return sumInvoicesOfType(invoices, 'hold', true, defaultAccount);
}

const sumHoldDollar = (invoices, defaultAccount) => {
  return sumInvoicesOfType(invoices, 'hold', false, defaultAccount);
}

const sumWorksheet = (invoices, defaultAccount) => {
  return sumInvoicesOfType(invoices, 'worksheet', true, defaultAccount);
}

const sumWorksheetDollar = (invoices, defaultAccount) => {
  return sumInvoicesOfType(invoices, 'worksheet', false, defaultAccount);
}

const sumInvoicesOfType = (invoices, invoiceType, qtyOrDollar, defaultAccount) => { //qtyOrDollar = true for quantity false for dollar
  //type can be "shipped", "sales order", "hold", "worksheet"
  if (typeof invoices === "undefined") { return 0; }

  var result = 0;
  for (var i=0; i<invoices.length; i++) {
    let invoice = invoices[i];

    let addToTotal = false;

    switch(invoiceType) {
      case "shipped":
        addToTotal = true;

        break;
      case "sales order":
        if (!invoice.hold && !invoice.worksheet) {
          addToTotal = true;
        }

        break;
      case "hold":
        if (!!invoice.hold) {
          addToTotal = true;
        }

        break;
      case "worksheet":
        if (!!invoice.worksheet) {
          addToTotal = true;
        }

        break;
      default:
        //nothing
    }

    //check for default account
    if (defaultAccount != null && defaultAccount != invoice.account_name) {
      addToTotal = false;
    }

    if (addToTotal) {
      if (qtyOrDollar) {
        result += Number(invoice.quantity);
      } else {
        result += roundToPenny(Number(invoice.quantity) * Number(invoice.sales_price));
      }
    }
  }

  return result;
}

export {sumShipped, sumShippedDollar, sumSalesOrders, sumSalesOrdersDollar, sumHold, sumHoldDollar, sumWorksheet, sumWorksheetDollar, roundToPenny}
