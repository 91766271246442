import $ from 'jquery'
import {orgText} from './get'
import {filterSalesData} from '../get-rep-options'

let shippedData, openSalesOrderData, pendingSalesOrderData

const getShippedCache = (orgId, user, callbackFunc) => {
  let randomNum = Math.floor(Math.random()*99999999).toString()
  let url = 'https://' + process.env.REACT_APP_S3_BUCKET_NAME + '.s3.amazonaws.com/stock_report/shipped/sales_history_data' + orgText(orgId) + '.json?t=' + randomNum
  let url2 = 'https://' + process.env.REACT_APP_S3_BUCKET_NAME + '.s3.amazonaws.com/stock_report/open_orders/sales_order_data' + orgText(orgId) + '.json?t=' + randomNum
  let url3 = 'https://' + process.env.REACT_APP_S3_BUCKET_NAME + '.s3.amazonaws.com/stock_report/pending_orders/sales_order_data' + orgText(orgId) + '.json?t=' + randomNum

  $.get(url, function(res) {
    shippedData = JSON.parse(res)
    callbackFuncLocal(user, callbackFunc)
  })

  $.get(url2, function(res) {
    openSalesOrderData = JSON.parse(res)
    callbackFuncLocal(user, callbackFunc)
  })

  $.get(url3, function(res) {
    pendingSalesOrderData = JSON.parse(res)
    callbackFuncLocal(user, callbackFunc)
  })
}

const callbackFuncLocal = (user, callbackFunc) => {
  if (shippedData != null && openSalesOrderData != null && pendingSalesOrderData != null) {



    //waiting for cads
    if (window.cads == null) {
     setTimeout(() => callbackFuncLocal(user, callbackFunc), 500)
    } else {
      let salesOrderData = mergeData(openSalesOrderData, pendingSalesOrderData)
      salesOrderData = setReadyToShip(salesOrderData)
      callbackFunc(filterSalesData(shippedData, user), filterSalesData(salesOrderData, user))
    }
  }
}

const mergeData = (a, b) => {
  return Object.assign({}, a, b)
}

const setReadyToShip = (salesOrderData) => {
  let onHandCads = window.cads.filter(cad => cad.on_hand != null && cad.on_hand > 0)

  onHandCads.forEach((cad) => {
    let styleNumber = cad.style_number
    let onHand = cad.on_hand == null ? 0 : cad.on_hand

    let openOrders = salesOrderData[styleNumber] == null ? [] : salesOrderData[styleNumber]
    let pendingOrders = salesOrderData[cad.id] == null ? [] : salesOrderData[cad.id].map((x) => {x['style_number'] = styleNumber; delete x['cad_id']; return x})

    delete salesOrderData[cad.id]

    let newOrders = openOrders.concat(pendingOrders).sort((a, b) => {return a.date > b.date ? 1 : -1})

    if (onHand > 0) {
      newOrders = newOrders.map((x) => {
        let qty = Number(x['quantity'])

        if (onHand > 0 && (qty * 0.9) < onHand) {
          onHand -= qty
          x['rts'] = true
        }

        return x
      })
    }

    salesOrderData[styleNumber] = newOrders
  })

  return salesOrderData
}

export {getShippedCache, mergeData}
