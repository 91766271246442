import React from 'react'
import {Modal, Button, Form, Accordion, Icon, Checkbox} from 'semantic-ui-react'
import {sortMapNameOptions, sortMapOptions} from '../purchase-orders/sort-map-id-name-options.js'
import {statusOptions} from '../cads/status-options.js'
import {formatDate} from '../cads/format-date.js'
import {checkboxHolderStyle} from './checkbox-holder-style'
import {needAdminPinInput} from './need-admin-pin-input'
import {needCancelVpoAlertInput} from './need-cancel-vpo-alert-input'
import {titleInput} from './title-input'

const customAccessModal = (open, closeModal, user, saveCustomAccess, customAccessData, notes, users, customAccessFieldOnChange, customer, quickbooksAccountNameOptions, toggleAccordion, active) => {
  let brandOptions = [], divisionOptions = [], categoryOptions = [], subCategoryOptions = []

  if (customer != null) {
    brandOptions = sortMapNameOptions(customer.brands)
    divisionOptions = sortMapNameOptions(customer.divisions)
    categoryOptions = sortMapNameOptions(customer.categories)
    subCategoryOptions = sortMapNameOptions(customer.subcategories)
  }

  let sendCancelVpoAlert, forwardCancelVpoEmail, canSeePrice, canSeeContainerTotals, canSeeCost, canEditCads, canDeleteCads,
      canImpersonateUsers, quickbooksAccountName, brand, division, category, subCategory, status, forwardAdminPinEmail, title

  if (customAccessData != null) {
    sendCancelVpoAlert = customAccessData['sendCancelVpoAlert']
    forwardCancelVpoEmail = customAccessData['forwardCancelVpoEmail']
    canSeePrice = customAccessData['canSeePrice']
    canSeeContainerTotals = customAccessData['canSeeContainerTotals']
    canSeeCost = customAccessData['canSeeCost']
    canEditCads = customAccessData['canEditCads']
    canDeleteCads = customAccessData['canDeleteCads']
    canImpersonateUsers = customAccessData['canImpersonateUsers']
    quickbooksAccountName = customAccessData['quickbooksAccountName']
    brand = customAccessData['brand']
    division = customAccessData['division']
    category = customAccessData['category']
    subCategory = customAccessData['subCategory']
    status = customAccessData['status']
    forwardAdminPinEmail = customAccessData['forwardAdminPinEmail']
    title = customAccessData['title']
  }

  let needCancelVpoAlert = !!user && ['inventory', 'admin', 'production'].includes(user.role)
  let needAdminPin = (!!user && user.role == 'admin') || canSeeCost

  return (
    <Modal
      open={open}
    >
      <Modal.Header>
        Edit Custom Access

        <div style={{float: 'right', fontSize: '60%', lineHeight: '1.4', position: 'relative', bottom: '12px'}}>
          Name = {!!user ? user.name : ''} <br/>
          Email = {!!user ? user.email : ''} <br/>
          Role = {!!user ? user.role : ''}
        </div>
      </Modal.Header>
      <Modal.Content>
        {titleInput(customAccessFieldOnChange, title)}

        {
          needCancelVpoAlert
          ?
          needCancelVpoAlertInput(user, customAccessFieldOnChange, sendCancelVpoAlert, forwardCancelVpoEmail)
          :
          null
        }

        {
          needAdminPin
          ?
          needAdminPinInput(user, customAccessFieldOnChange, forwardAdminPinEmail)
          :
          null
        }

        {
          !!user && ['production', 'inventory'].includes(user.role)
          ?
          <div style={checkboxHolderStyle}>
            <b>Inventory and Production ONLY:</b>
            <Checkbox style={{marginLeft: '208px'}} toggle label='Can see Container # totals' name='canSeeContainerTotals' checked={canSeeContainerTotals} onChange={customAccessFieldOnChange}/>
          </div>
          :
          null
        }

        {
          !!user && ['production', 'sales', 'sales_assistant'].includes(user.role)
          ?
          <div style={checkboxHolderStyle}>
            <b>Sales, Sales Assistant and Production ONLY:</b>
            <Checkbox style={{marginLeft: '40px'}} toggle label='Can see price on Cads' name='canSeePrice' checked={canSeePrice} onChange={customAccessFieldOnChange}/>
          </div>
          :
          null
        }

        {
          !!user && ['production', 'sales'].includes(user.role)
          ?
          <div style={checkboxHolderStyle}>
            <b>Sales and Production ONLY:</b>
            <Checkbox style={{marginLeft: '142px'}} toggle label='Can see factory cost on Cads' name='canSeeCost' checked={canSeeCost} onChange={customAccessFieldOnChange}/>
          </div>
          :
          null
        }

        {
          !!user && ['sales', 'sales_assistant', 'design', 'production'].includes(user.role)
          ?
          <div style={checkboxHolderStyle}>
            <b>Sales, Sales Assistant, Production and Design ONLY:</b>
            <Checkbox style={{marginLeft: '68px'}} toggle label='Can edit Cads' name='canEditCads' checked={canEditCads} onChange={customAccessFieldOnChange}/>
            <Checkbox style={{marginLeft: '80px'}} toggle label='Can delete Cads' name='canDeleteCads' checked={canDeleteCads} onChange={customAccessFieldOnChange}/>
          </div>
          :
          null
        }

        {
          !!user && ['sales', 'sales_assistant'].includes(user.role)
          ?
          <div style={checkboxHolderStyle}>
            <b>Sales and Sales Assistant ONLY:</b>
            <Checkbox style={{marginLeft: '248px'}} toggle label='Can impersonate users on Sales Orders Page' name='canImpersonateUsers' checked={canImpersonateUsers} onChange={customAccessFieldOnChange}/>
          </div>
          :
          null
        }

        {
          !!user && user.role == 'customer'
          ?
          <div style={checkboxHolderStyle}>
            <b>Customer ONLY:</b>
            <Form.Group widths='equal' style={{textAlign: 'center'}}>
              <span>Quickbooks Account Name</span>
              <Form.Select name='quickbooksAccountName' label='' onChange={customAccessFieldOnChange} options={quickbooksAccountNameOptions} placeholder='Quickbooks Account Name' value={quickbooksAccountName} />
            </Form.Group>
          </div>
          :
          null
        }

        <Form.Group widths='equal'>
          <Form.Select fluid multiple name='brand' label='Brand' onChange={customAccessFieldOnChange} options={brandOptions} placeholder='Brand' value={brand} />
        </Form.Group>

        <Form.Group widths='equal' style={{marginTop: '20px'}}>
          <Form.Select fluid multiple name='division' label='Division' onChange={customAccessFieldOnChange} options={divisionOptions} placeholder='Division' value={division} />
        </Form.Group>

        <Form.Group widths='equal' style={{marginTop: '20px'}}>
          <Form.Select fluid multiple name='category' label='Category' onChange={customAccessFieldOnChange} options={categoryOptions} placeholder='Category' value={category} />
        </Form.Group>

        <Form.Group widths='equal' style={{marginTop: '20px'}}>
          <Form.Select fluid multiple name='subCategory' label='Sub-Category' onChange={customAccessFieldOnChange} options={subCategoryOptions} placeholder='Sub-Category' value={subCategory} />
        </Form.Group>

        <Form.Group widths='equal' style={{marginTop: '20px'}}>
          <Form.Select fluid multiple name='status' label='Status' onChange={customAccessFieldOnChange} options={statusOptions} placeholder='Status' value={status} />
        </Form.Group>

        <Accordion style={{marginTop: '20px'}}>
          <Accordion.Title onClick={toggleAccordion}>
            <Icon name='dropdown' />
            Log of changes
          </Accordion.Title>
          <Accordion.Content active={active}>
            <div style={{overflowX: 'scroll'}}>
              <ul>
                {
                  notes.length == 0
                  ?
                  <div style={{opacity: 0.5}}>(No changes made yet)</div>
                  :
                  notes.map((note) => {
                    return (
                      <li>
                        <pre>{users.find(x => x.id == note.userId).name} on {formatDate(note.createdAt.split('T')[0])} — {note.text}</pre>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </Accordion.Content>
        </Accordion>

      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={closeModal}>
          Cancel
        </Button>
        <Button
          content="Save"
          labelPosition='right'
          icon='checkmark'
          onClick={saveCustomAccess}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export {customAccessModal}
