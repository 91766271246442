import React from 'react'
import {Transition, Grid} from 'semantic-ui-react'
import VendorOrdersDropdown from '../vendor-orders/vendor-orders-dropdown'
import {statusOptions, titleOptions, distributionChannelOptions, regionOptions} from './options'

const filters = (showFilters, fieldOnChange, status, title, distributionChannel, region, division, category, repOptions, rep, divisionOptions, categoryOptions) => {
  return (
    <Transition visible={showFilters} animation='fade' duration={showFilters ? 1000 : 500}>
      <div id="filter-section">
        <Grid columns={6}>
          <VendorOrdersDropdown
            placeholder='Status'
            name='status'
            options={statusOptions}
            onChange={fieldOnChange}
            value={status}
            loading={false}
            style={{marginBottom: '23px'}}
          />
          <VendorOrdersDropdown
            placeholder='Title'
            name='title'
            options={titleOptions}
            onChange={fieldOnChange}
            value={title}
            loading={false}
            style={{marginBottom: '23px'}}
          />
          <VendorOrdersDropdown
            placeholder='Distribution Channel'
            name='distributionChannel'
            options={distributionChannelOptions}
            onChange={fieldOnChange}
            value={distributionChannel}
            loading={false}
            style={{marginBottom: '23px'}}
          />
          <VendorOrdersDropdown
            placeholder='Region'
            name='region'
            options={regionOptions}
            onChange={fieldOnChange}
            value={region}
            loading={false}
            style={{marginBottom: '23px'}}
          />
          <VendorOrdersDropdown
            placeholder='Division'
            name='division'
            options={divisionOptions}
            onChange={fieldOnChange}
            value={division}
            loading={false}
            style={{marginBottom: '23px'}}
          />
          <VendorOrdersDropdown
            placeholder='Category'
            name='category'
            options={categoryOptions}
            onChange={fieldOnChange}
            value={category}
            loading={false}
            style={{marginBottom: '23px'}}
          />
        </Grid>

        <Grid columns={6} style={{marginTop: '-20px'}}>
          <VendorOrdersDropdown
            placeholder='Rep'
            name='rep'
            options={repOptions}
            onChange={fieldOnChange}
            value={rep}
            loading={false}
            style={{marginBottom: '23px'}}
          />
        </Grid>
      </div>
    </Transition>
  )
}

export {filters}
