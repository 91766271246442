import React from 'react'
import {Button, Popup} from 'semantic-ui-react'
import {buttonStyle} from './button-style'

const selectAll = (onClick) => {
  return (
    <Popup content='Select all' position='bottom left'
      trigger={
        <Button basic icon='mouse pointer' onClick={onClick} style={buttonStyle}/>
      }
    />
  )
}



export {selectAll}
