import React, {Component} from 'react'
import {Icon, Popup} from 'semantic-ui-react'
import Utils from './../../modules/utils'

class AutoEditedPopup extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {po} = this.props
    if (po.autoEdited || po.needToAutoEditHolds) {
      let content = (
        po.needToAutoEditHolds
        ?
        'Waiting to AUTO EDIT HOLDS & WORKSHEETS for this PO'
        :
        'This order was auto edited on ' + Utils.formatSimpleDate(po.autoEditedDate)
      )

      let stickyNoteStyle = {color: 'rgb(244,195,86)', marginLeft: '10px', fontSize: '18px'}

      return (
        <Popup
          size='mini'
          content={content}
          position='bottom left'
          trigger={
            <Icon name="bolt" className={po.needToAutoEditHolds ? 'bounce' : null} style={stickyNoteStyle}/>
          }
        />
      )
    } else {
      return null
    }
  }
}

export default AutoEditedPopup
