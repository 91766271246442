import React, {Component} from 'react'
import {Form, Icon} from 'semantic-ui-react'
import {digest, checkAdminPin, savePin, sendSetAdminPinTrueEvent} from './pin.js'
import requestPin from './request-pin'
import {canSeeCost} from '../custom-access/can.js'

const showToRoles = ['admin', 'superadmin']

const requestPinStyle = {
  zoom: '70%',
  cursor: 'pointer',
  float: 'left',
  marginTop: '5px',
  marginBottom: '-10px',
  marginLeft: '50px'
}

class AdminPinInput extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      showInput: false,
      pin: null,
      correctPin: false
    }
    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.savePin = this.savePin.bind(this)
    this.setPinTrue = this.setPinTrue.bind(this)
    this.setPinFalse = this.setPinFalse.bind(this)
    this.checkUserPermission = this.checkUserPermission.bind(this)
  }

  setPinTrue() {
    this.setState({correctPin: true})
  }

  setPinFalse() {
    this.setState({correctPin: false})
  }

  setInitialConditions() {

  }

  checkUserPermission() {
    if (canSeeCost()) {
      this.setState({showInput: true})
    } else {
      setTimeout(this.checkUserPermission, 1000)
    }
  }

  componentDidMount() {
    let role = sessionStorage.getItem('currentUserRole')
    if (showToRoles.includes(role)) {
      this.setState({showInput: true})
    } else {
      this.checkUserPermission()
    }

    window.addEventListener('set-admin-pin-true', this.setPinTrue)
    window.addEventListener('set-admin-pin-false', this.setPinFalse)
    checkAdminPin()
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}
    newState[name] = value
    this.setState(newState)
  }

  savePin() {
    let {pin} = this.state
    if (pin != null && pin.length == 6) {
      savePin(pin)
    }
  }

  render() {
    let {showInput, correctPin} = this.state

    if (!showInput || correctPin) {
      return null
    }

    return (
      <div style={{display: 'inline-block', position: 'relative', top: '4px'}} className="left menu">
        <Form onSubmit={this.savePin}>
          <Form.Input size='mini' icon='key' iconPosition='left' placeholder='Enter 6-digit admin pin'
            action={{ icon: 'lock'}} onChange={this.fieldOnChange} type="password" name="pin"
            id="admin-pin-input"
          />
        </Form>
        <center><button style={requestPinStyle} onClick={requestPin}><Icon name='mail'/> Request pin</button></center>
      </div>
    )
  }
}

export default AdminPinInput
