import React, {Component} from 'react'
import {Modal, Button, Icon, Checkbox} from 'semantic-ui-react'
import {labels} from './labels.js'

const classificationFields = ['BRAND', 'DIVISION', 'CATEGORY', 'SUB-CATEGORY', 'COLOR', 'FABRICATION', 'PACKS']
const disabledFields = ['REF #']

class VisibleFields extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      open: false
    }

    this.openModal = this.openModal.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  openModal() {
    this.setState({open: true})
  }

  onClose() {
    this.setState({open: false})
  }

  render() {
    let {open} = this.state
    let {toggleCheckbox, visibleFields, selectAll, selectDefault, selectNone} = this.props

    return (
      <React.Fragment>
        <Modal size={'small'} open={open} onClose={this.onClose} closeIcon>
          <Modal.Header>Select visible fields</Modal.Header>
          <Modal.Content>
            <div style={{columnCount: '3'}}>
              {
                labels.map((label, index) => {
                  return (
                    <div key={index} style={{whiteSpace: 'nowrap'}}>
                      <Checkbox disabled={disabledFields.includes(label)}
                        label={label+(classificationFields.includes(label) ? ' (classification)' : '')}
                        onChange={toggleCheckbox.bind(null, label)}
                        checked={visibleFields.includes(label)}
                        style={disabledFields.includes(label) ? {opacity: 0.4} : {}}
                      /> &nbsp;
                      <br/><br/>
                    </div>
                  )
                })
              }
              Select:
              <button className="mini ui basic button" onClick={selectNone} style={{padding: '5px', marginLeft: '10px'}}>
                None
              </button>
              <button className="mini ui basic button" onClick={selectDefault} style={{padding: '5px', marginLeft: '10px'}}>
                Default
              </button>
              <button className="mini ui basic button" onClick={selectAll} style={{padding: '5px', marginLeft: '10px'}}>
                All
              </button>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onClose} primary>
              Done
            </Button>
          </Modal.Actions>
        </Modal>

        <Button icon size='mini' onClick={this.openModal} style={{background: 'white', float: 'right', fontSize: '20px', padding: '3px 10px', position: 'relative', top: '15px'}}>
          <Icon name='eye' />
        </Button>
      </React.Fragment>
    )
  }
}

export default VisibleFields
