import React, {Component} from 'react'
import {Table} from 'semantic-ui-react'
import CadDetailsRow from './cad-details-row.js'
import {datePlus9ToYYYYMMDD} from '../../edit-sales-order/date-inputs.js'
import {getComplete} from './get-complete.js'
import {optionalText} from './date-label.js'
import {ADD_DAYS_TO_EX_FACTORY_DATE, ADD_DAYS_EX_FACTORY_TO_LOADING, ADD_DAYS_EX_FACTORY_TO_RTS} from '../../../modules/constants'
import {totalsTable} from './totals-table'

const allowEmptyString = ['fabricWeight', 'fabricContent']

class CadDetailsTable extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      quantity: {},
      size: {},
      sizeRatio: {},
      cost: {},
      msrp: {},
      qtyPerPolibag: {},
      exFactoryDate: {},
      overrideOriginalExfactory: {},
      asnxFactoryDate: {},
      cancelDate: {},
      delivDate: {},
      fabrication: {},
      fabricWeight: {},
      fabricContent: {},
      factory: {},
      loadingDate: {},
      fobRtsDate: {},
      containerNumber: {},
      comment: {},
      shippingClass: {},
      tickets: {},
      at: {},
      currency: {},
      country: {},
      casePack: {},
      cponumber: {},
      discount: {}
    }

    this.onChangeQuantity = this.onChangeQuantity.bind(this)
    this.onChangeSize = this.onChangeSize.bind(this)
    this.onChangeSizeRatio = this.onChangeSizeRatio.bind(this)
    this.addSize = this.addSize.bind(this)
    this.removeSize = this.removeSize.bind(this)
    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.copyBulkQuantitySizeToAll = this.copyBulkQuantitySizeToAll.bind(this)
    this.onChangeExFactoryDate = this.onChangeExFactoryDate.bind(this)
    this.onChangeOverrideOriginalExfactory = this.onChangeOverrideOriginalExfactory.bind(this)
    this.onChangeAsnxFactoryDate = this.onChangeAsnxFactoryDate.bind(this)
    this.onChangeCancelDate = this.onChangeCancelDate.bind(this)
    this.onChangeDelivDate = this.onChangeDelivDate.bind(this)
    this.onChangeLoadingDate = this.onChangeLoadingDate.bind(this)
    this.onChangeFobRtsDate = this.onChangeFobRtsDate.bind(this)
    this.calculateComplete = this.calculateComplete.bind(this)
  }

  componentDidMount() {
    if (window.newVpoPage.cadDetailsTable != null) {
      this.setState(window.newVpoPage.cadDetailsTable)
      setTimeout(this.calculateComplete, 0)
    }
  }

  componentDidUpdate() {
    window.newVpoPage.cadDetailsTable = JSON.parse(JSON.stringify(this.state))
  }

  calculateComplete() {
    let {setComplete} = this.props
    let complete = getComplete(this.props, this.state)
    setComplete(complete)
  }

  onChangeQuantity(cadId, i, event, {value}) {
    let {quantity} = this.state
    if (quantity[cadId] == null) {
      quantity[cadId] = []
    }

    quantity[cadId][i] = (value == '' ? null : Math.floor(value))

    this.setState({quantity})
    if (cadId == 'BULK EDIT') {
      setTimeout(this.copyBulkQuantitySizeToAll, 0)
    }
    setTimeout(this.calculateComplete, 0)
  }

  onChangeSize(cadId, i, event, {value}) {
    let {size, sizeRatio} = this.state
    if (size[cadId] == null) {
      size[cadId] = []
    }
    size[cadId][i] = value
    if (sizeRatio[cadId] == null) {
     sizeRatio[cadId] = []
    }
    if (value != 'PREPACK') {
      sizeRatio[cadId][i] = null
    }
    this.setState({size, sizeRatio})
    if (cadId == 'BULK EDIT') {
      setTimeout(this.copyBulkQuantitySizeToAll, 0)
    }
    setTimeout(this.calculateComplete, 0)
  }

  onChangeSizeRatio(cadId, i, event, {value}) {
    let {sizeRatio} = this.state
    if (sizeRatio[cadId] == null) {
      sizeRatio[cadId] = []
    }
    sizeRatio[cadId][i] = value
    this.setState({sizeRatio})
    if (cadId == 'BULK EDIT') {
      setTimeout(this.copyBulkQuantitySizeToAll, 0)
    }
    setTimeout(this.calculateComplete, 0)
  }

  addSize(cadId) {
    let {quantity, size, sizeRatio} = this.state
    if (quantity[cadId] == null) {
      quantity[cadId] = [null]
    }
    if (size[cadId] == null) {
      size[cadId] = ['PREPACK']
    }
    if (sizeRatio[cadId] == null) {
      sizeRatio[cadId] = [null]
    }
    quantity[cadId].push(null)
    size[cadId].push(null)
    sizeRatio[cadId].push(null)
    this.setState({quantity, size})
    if (cadId == 'BULK EDIT') {
      setTimeout(this.copyBulkQuantitySizeToAll, 0)
    }
    setTimeout(this.calculateComplete, 0)
  }

  removeSize(cadId) {
    let {quantity, size, sizeRatio} = this.state
    size[cadId].pop()
    quantity[cadId].pop()
    sizeRatio[cadId].pop()
    this.setState({quantity, size})
    if (cadId == 'BULK EDIT') {
      setTimeout(this.copyBulkQuantitySizeToAll, 0)
    }
    setTimeout(this.calculateComplete, 0)
  }

  copyBulkQuantitySizeToAll() {
    let {cadIds} = this.props
    let {quantity, size, sizeRatio} = this.state

    cadIds.forEach(cadId => {
      if (quantity['BULK EDIT'] != null) {
        quantity[cadId] = JSON.parse(JSON.stringify(quantity['BULK EDIT']))
      }
      size[cadId] = JSON.parse(JSON.stringify(size['BULK EDIT'] == null ? ['PREPACK'] : size['BULK EDIT']))
      sizeRatio[cadId] = JSON.parse(JSON.stringify(sizeRatio['BULK EDIT'] == null ? [null] : sizeRatio['BULK EDIT']))
    })

    this.setState({quantity, size, sizeRatio})
  }

  fieldOnChange(cadId, event, {value, name}) {
    let newState = {}
    newState[name] = this.state[name]

    if ((value == '' || value == null) && !allowEmptyString.includes(name)) {
      delete newState[name][cadId]
    } else {

      if (name == 'qtyPerPolibag' || name == 'casePack') {
        value = Math.floor(Number(value))
      }

      newState[name][cadId] = value
    }

    if (cadId == 'BULK EDIT') {
      let {cadIds} = this.props

      cadIds.forEach(x => {
        if (value == '' || value == null) {
          delete newState[name][x]
        } else {
          newState[name][x] = value
        }
      })
    }

    this.setState(newState)
    setTimeout(this.calculateComplete, 0)
  }

  onChangeOverrideOriginalExfactory(cadId, event) {
    let {overrideOriginalExfactory} = this.state
    overrideOriginalExfactory[cadId] = event.target.checked
    this.setState({overrideOriginalExfactory})
  }

  onChangeExFactoryDate(cadId, event) {
    let {value} = event.currentTarget
    let {exFactoryDate, cancelDate, delivDate} = this.state

    exFactoryDate[cadId] = value
    cancelDate[cadId] = datePlus9ToYYYYMMDD(value)
    delivDate[cadId] = datePlus9ToYYYYMMDD(value, ADD_DAYS_TO_EX_FACTORY_DATE)

    if (cadId == 'BULK EDIT') {
      let {cadIds} = this.props
      cadIds.forEach(x => {
        exFactoryDate[x] = value
        cancelDate[x] = datePlus9ToYYYYMMDD(value)
        delivDate[x] = datePlus9ToYYYYMMDD(value, ADD_DAYS_TO_EX_FACTORY_DATE)
      })
    }

    this.setState({exFactoryDate, cancelDate, delivDate})
    setTimeout(this.calculateComplete, 0)
  }

  onChangeAsnxFactoryDate(cadId, event) {
    let {value} = event.currentTarget
    let {asnxFactoryDate, loadingDate} = this.state
    asnxFactoryDate[cadId] = value
    loadingDate[cadId] = datePlus9ToYYYYMMDD(value, ADD_DAYS_EX_FACTORY_TO_LOADING)

    if (cadId == 'BULK EDIT') {
      let {cadIds} = this.props
      cadIds.forEach(x => {
        asnxFactoryDate[x] = value
        loadingDate[x] = datePlus9ToYYYYMMDD(value, ADD_DAYS_EX_FACTORY_TO_LOADING)
      })
    }
    this.setState({asnxFactoryDate, loadingDate})
  }

  onChangeCancelDate(cadId, event) {
    let {value} = event.currentTarget
    let {cancelDate} = this.state
    cancelDate[cadId] = value
    if (cadId == 'BULK EDIT') {
      let {cadIds} = this.props
      cadIds.forEach(x => {
        cancelDate[x] = value
      })
    }
    this.setState({cancelDate})
    setTimeout(this.calculateComplete, 0)
  }

  onChangeDelivDate(cadId, event) {
    let {value} = event.currentTarget
    let {delivDate} = this.state
    delivDate[cadId] = value
    if (cadId == 'BULK EDIT') {
      let {cadIds} = this.props
      cadIds.forEach(x => {
        delivDate[x] = value
      })
    }
    this.setState({delivDate})
    setTimeout(this.calculateComplete, 0)
  }

  onChangeLoadingDate(cadId, event) {
    let {value} = event.currentTarget
    let {loadingDate} = this.state
    loadingDate[cadId] = value
    if (cadId == 'BULK EDIT') {
      let {cadIds} = this.props
      cadIds.forEach(x => {
        loadingDate[x] = value
      })
    }
    this.setState({loadingDate})
  }

  onChangeFobRtsDate(cadId, event) {
    let {value} = event.currentTarget
    let {fobRtsDate} = this.state
    fobRtsDate[cadId] = value
    if (cadId == 'BULK EDIT') {
      let {cadIds} = this.props
      cadIds.forEach(x => {
        fobRtsDate[x] = value
      })
    }
    this.setState({fobRtsDate})
  }

  render() {
    let {showBulkEdit, cadIds, isEditPage} = this.props
    let {
      quantity, size, sizeRatio, cost, msrp, qtyPerPolibag, exFactoryDate, overrideOriginalExfactory, cancelDate, delivDate, fabrication, factory, loadingDate, fobRtsDate, containerNumber, comment,
      shippingClass, tickets, asnxFactoryDate, at, currency, country, casePack, cponumber, discount, fabricWeight, fabricContent
    } = this.state

    return (
      <React.Fragment>
        <Table id="cad-details-table" compact='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Style &#35;</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>
                Currency<br/>
                Cost<br/>
                AT<br/>
                MSRP<br/>
                QTY PER POLIBAG<br/>
                QTY PER CASE PACK
              </Table.HeaderCell>
              <Table.HeaderCell style={{whiteSpace: 'nowrap'}}>
                Ex-Factory Date<br/>
                Cancel Date<br/>
                Deliv Date
              </Table.HeaderCell>
              <Table.HeaderCell style={{width: '250px'}}>
                Fabrication<br/>
                Fabric Weight {optionalText}<br/>
                Fabric Content {optionalText}<br/>
                Factory<br/>
                Country of Origin<br/>
                Class<br/>
                Tickets
              </Table.HeaderCell>
              <Table.HeaderCell style={{width: '200px', whiteSpace: 'nowrap'}}>
                Asn X-Factory Date {optionalText}<br/>
                Loading Date {optionalText}<br/>
                FACTORY-RTS Date {optionalText}<br/>
                Container &#35; {optionalText}<br/>
                Customer PO &#35; {optionalText}<br/>
                DISCOUNT {optionalText}<br/>
                Memo {optionalText}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              showBulkEdit
              ?
              <CadDetailsRow cadId={'BULK EDIT'} index={0} cost={cost} msrp={msrp} qtyPerPolibag={qtyPerPolibag} fabrication={fabrication} factory={factory}
                containerNumber={containerNumber} comment={comment}
                fieldOnChange={this.fieldOnChange.bind(null, 'BULK EDIT')}
                quantity={quantity['BULK EDIT']}
                size={size['BULK EDIT']}
                sizeRatio={sizeRatio['BULK EDIT']}
                onChangeQuantity={this.onChangeQuantity}
                onChangeSize={this.onChangeSize}
                onChangeSizeRatio={this.onChangeSizeRatio}
                addSize={this.addSize}
                removeSize={this.removeSize}
                exFactoryDate={exFactoryDate['BULK EDIT']}
                cancelDate={cancelDate['BULK EDIT']}
                delivDate={delivDate['BULK EDIT']}
                loadingDate={loadingDate['BULK EDIT']}
                fobRtsDate={fobRtsDate['BULK EDIT']}
                onChangeExFactoryDate={this.onChangeExFactoryDate.bind(null, 'BULK EDIT')}
                onChangeOverrideOriginalExfactory={this.onChangeOverrideOriginalExfactory.bind(null, 'BULK EDIT')}
                onChangeCancelDate={this.onChangeCancelDate.bind(null, 'BULK EDIT')}
                onChangeDelivDate={this.onChangeDelivDate.bind(null, 'BULK EDIT')}
                onChangeLoadingDate={this.onChangeLoadingDate.bind(null, 'BULK EDIT')}
                onChangeFobRtsDate={this.onChangeFobRtsDate.bind(null, 'BULK EDIT')}
                shippingClass={shippingClass}
                tickets={tickets}
                asnxFactoryDate={asnxFactoryDate['BULK EDIT']}
                onChangeAsnxFactoryDate={this.onChangeAsnxFactoryDate.bind(null, 'BULK EDIT')}
                at={at}
                currency={currency}
                country={country}
                casePack={casePack}
                cponumber={cponumber}
                discount={discount}
                fabricWeight={fabricWeight}
                fabricContent={fabricContent}
                isEditPage={isEditPage}
              />
              :
              null
            }
            {
              cadIds.map((cadId, index) => {
                return (
                  <CadDetailsRow cadId={cadId} index={index} cost={cost} msrp={msrp} qtyPerPolibag={qtyPerPolibag} fabrication={fabrication} factory={factory}
                    containerNumber={containerNumber} comment={comment}
                    fieldOnChange={this.fieldOnChange.bind(null, cadId)}
                    quantity={quantity[cadId]}
                    size={size[cadId]}
                    sizeRatio={sizeRatio[cadId]}
                    onChangeQuantity={this.onChangeQuantity}
                    onChangeSize={this.onChangeSize}
                    onChangeSizeRatio={this.onChangeSizeRatio}
                    addSize={this.addSize}
                    removeSize={this.removeSize}
                    exFactoryDate={exFactoryDate[cadId]}
                    overrideOriginalExfactory={overrideOriginalExfactory[cadId]}
                    cancelDate={cancelDate[cadId]}
                    delivDate={delivDate[cadId]}
                    loadingDate={loadingDate[cadId]}
                    fobRtsDate={fobRtsDate[cadId]}
                    onChangeExFactoryDate={this.onChangeExFactoryDate.bind(null, cadId)}
                    onChangeOverrideOriginalExfactory={this.onChangeOverrideOriginalExfactory.bind(null, cadId)}
                    onChangeCancelDate={this.onChangeCancelDate.bind(null, cadId)}
                    onChangeDelivDate={this.onChangeDelivDate.bind(null, cadId)}
                    onChangeLoadingDate={this.onChangeLoadingDate.bind(null, cadId)}
                    onChangeFobRtsDate={this.onChangeFobRtsDate.bind(null, cadId)}
                    shippingClass={shippingClass}
                    tickets={tickets}
                    asnxFactoryDate={asnxFactoryDate[cadId]}
                    onChangeAsnxFactoryDate={this.onChangeAsnxFactoryDate.bind(null, cadId)}
                    at={at}
                    currency={currency}
                    country={country}
                    casePack={casePack}
                    cponumber={cponumber}
                    discount={discount}
                    fabricWeight={fabricWeight}
                    fabricContent={fabricContent}
                    isEditPage={isEditPage}
                  />
                )
              })
            }
          </Table.Body>
        </Table>

        {totalsTable(cadIds, quantity, cost, discount)}

      </React.Fragment>
    )
  }
}

export default CadDetailsTable
