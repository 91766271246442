import React, {Component} from 'react'
import {Button, Checkbox, Icon, Input, Modal} from 'semantic-ui-react'
import $ from 'jquery'
import {updateIsPaid} from './update_is_paid'
import {roundToPenny} from './round-to-penny'

const getLabel = (text, show) => {
  return (
    show
    ?
    <b style={{display: 'block', textAlign: 'left', paddingLeft: '20px'}}>{text}</b>
    :
    <b>&nbsp;</b>
  )
}

class IsPaidModal extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      showLabelAmountPaid: false,
      showLabelDiscount: false
    }

    this.submitForm = this.submitForm.bind(this)
    this.onChangeAmount = this.onChangeAmount.bind(this)
    this.onChangeDiscount = this.onChangeDiscount.bind(this)
    this.payAll = this.payAll.bind(this)
  }

  submitForm(e) {
    e.preventDefault()
    let {onClose, refnumber, total} = this.props
    let amountPaid = $('#is-paid-amount').val()

    if (Number(amountPaid) <= 0) {
      alert('Enter an Amount Paid!')
    } else if (Number(amountPaid) > total) {
      alert('Enter an Amount Paid LESS THAN or EQUAL TO Amount Due!')
    } else {
      updateIsPaid(refnumber, amountPaid, total)
      onClose()
    }
  }

  onChangeAmount() {
    $('#is-paid-discount').val('')
    this.setState({showLabelAmountPaid: true})
  }

  onChangeDiscount() {
    let {total} = this.props
    let discount = $('#is-paid-discount').val()

    if (discount == '') {
      this.setState({showLabelDiscount: false})
      return
    } else if (discount == '0') {
      $('#is-paid-amount').val(total)
    } else {
      let newTotal = total * ((100.0 - Number(discount)) / 100.0)
      $('#is-paid-amount').val(roundToPenny(newTotal))
      this.setState({showLabelAmountPaid: true})
    }
    this.setState({showLabelDiscount: true})
  }

  payAll() {
    let {total} = this.props
    $('#is-paid-amount').val(total)
    $('#is-paid-discount').val('')
    this.setState({showLabelAmountPaid: true, showLabelDiscount: false})
  }

  render() {
    let {showLabelAmountPaid, showLabelDiscount} = this.state
    let {open, onClose, refnumber, total} = this.props
    return (
      <Modal size={'mini'} open={open} onClose={onClose} closeIcon>
        <Modal.Header>Bulk pay items</Modal.Header>

        <div style={{padding: '20px', paddingBottom: '10px', fontSize: '18px'}}>
          <b>REF #:</b> {refnumber}
        </div>

        <div style={{padding: '20px', paddingBottom: '10px', fontSize: '18px'}}>
          <b>Amount Due:</b> ${total.totalFormat()} <button style={{padding: '0 2px', fontSize: '12px', marginLeft: '20px', cursor: 'pointer'}} onClick={this.payAll}>pay all</button>
        </div>

        <center style={{marginTop: '15px'}}>
          {getLabel('Amount Paid', showLabelAmountPaid)}
          <Input label="$" id="is-paid-amount" fluid style={{margin: '10px 20px', marginTop: '3px'}}
            placeholder="Amount Paid" type="number" min="0" step="0.01" max={total}
            onChange={this.onChangeAmount}
          />

          {getLabel('Discount', showLabelDiscount)}
          <Input  labelPosition='right' label="%" id="is-paid-discount" fluid style={{margin: '20px', marginTop: 0}}
            placeholder="Discount" type="number" min="0" step="1" max="100"
            onChange={this.onChangeDiscount}
          />
        </center>

        <div style={{padding: '10px'}}>
          <Button color='green' fluid onClick={this.submitForm}><Icon name='dollar'/> Update Is Paid</Button>
        </div>
      </Modal>
    )
  }
}

export default IsPaidModal
