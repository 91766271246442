import * as types from './actionTypes';

export const filterApplicableSubCategories = (applicableSubCategories) => dispatch => {
  dispatch({
   type: types.FILTER_APPLICABLE_SUB_CATEGORIES,
   applicableSubCategories
  })
}

export const updateLast5Searches = (newSearch) => dispatch => {
  dispatch({
   type: types.UPDATE_LAST_5_SEARCHES,
   newSearch
  })
}

export const updateDefaultAccount = (defaultAccount) => dispatch => {
  dispatch({
   type: types.UPDATE_DEFAULT_ACCOUNT,
   defaultAccount
  })
}

export const updateCadStatus = (cadStatus) => dispatch => {
  dispatch({
   type: types.UPDATE_CAD_STATUS,
   cadStatus
  })
}

export const updateCategory = (category) => dispatch => {
  dispatch({
   type: types.UPDATE_CATEGORY,
   category
  })
}

export const updatePacks = (packs) => dispatch => {
  dispatch({
   type: types.UPDATE_PACKS,
   packs
  })
}

export const updateMargins = (margins) => dispatch => {
  dispatch({
   type: types.UPDATE_MARGINS,
   margins
  })
}

export const onChangeHideableInput = (fieldName, value) => dispatch => {
  dispatch({
    type: types.ON_CHANGE_HIDEABLE_INPUT,
    fieldName,
    value
  })
}

export const toggleHideableCheckbox = (fieldName) => dispatch => {
  dispatch({
    type: types.TOGGLE_HIDEABLE_CHECKBOX,
    fieldName
  })
}

export const selectAllHideableCheckbox = (fieldNames) => dispatch => {
  dispatch({
    type: types.SELECT_ALL_HIDEABLE_CHECKBOX,
    fieldNames
  })
}

export const updatePlaylist = (currentPlaylistId, currentPlaylistName) => dispatch => {
  dispatch({
    type: types.UPDATE_PLAYLIST,
    currentPlaylistId,
    currentPlaylistName
  })
}

export const updateFutureAtsMonths = (futureAtsMonths) => dispatch => {
  dispatch({
   type: types.UPDATE_FUTURE_ATS_MONTHS,
   futureAtsMonths
  })
}

export const updateFactory = (factory) => dispatch => {
  dispatch({
   type: types.UPDATE_FACTORY,
   factory
  })
}

export const updateWarehouse = (warehouse) => dispatch => {
  dispatch({
   type: types.UPDATE_WAREHOUSE,
   warehouse
  })
}

export const updateBrand = (brand) => dispatch => {
  dispatch({
   type: types.UPDATE_BRAND,
   brand
  })
}

export const updateFabrication = (fabrication) => dispatch => {
  dispatch({
   type: types.UPDATE_FABRICATION,
   fabrication
  })
}

export const updateDivision = (division) => dispatch => {
  dispatch({
   type: types.UPDATE_DIVISION,
   division
  })
}

export const updateColor = (color) => dispatch => {
  dispatch({
   type: types.UPDATE_COLOR,
   color
  })
}

export const updateSeason = (season) => dispatch => {
  dispatch({
   type: types.UPDATE_SEASON,
   season
  })
}

export const updateSubCategory = (subCategory) => dispatch => {
  dispatch({
   type: types.UPDATE_SUB_CATEGORY,
   subCategory
  })
}

export const updateDateFrom = (dateFrom) => dispatch => {
  dispatch({
   type: types.UPDATE_DATE_FROM,
   dateFrom
  })
}


export const updateSub2 = (sub2) => dispatch => {
  dispatch({
   type: types.UPDATE_SUB2,
   sub2
  })
}


export const updateDateTo = (dateTo) => dispatch => {
  dispatch({
   type: types.UPDATE_DATE_TO,
   dateTo
  })
}

export const clearFilters = () => dispatch => {
  dispatch({
   type: types.CLEAR_FILTERS
  })
}

export const updateGuestFilters = (filters) => dispatch => {
  dispatch({
   type: types.UPDATE_GUEST_FILTERS,
   filters
  })
}

export const addToShoppingBag = (cadId) => dispatch => {
  dispatch({
   type: types.ADD_TO_SHOPPING_CART,
   cadId
  })
}

export const removeFromShoppingBag = (cadId) => dispatch => {
  dispatch({
   type: types.REMOVE_FROM_SHOPPING_CART,
   cadId
  })
}

export const bulkAddToShoppingBag = (cadIds) => dispatch => {
  dispatch({
   type: types.BULK_ADD_TO_SHOPPING_CART,
   cadIds
  })
}

export const bulkRemoveFromShoppingBag = () => dispatch => {
  dispatch({
   type: types.BULK_REMOVE_FROM_SHOPPING_CART
  })
}

export const updatePrint = (print) => dispatch => {
  dispatch({
   type: types.UPDATE_PRINT,
   print
  })
}

export const updatePattern = (pattern) => dispatch => {
  dispatch({
   type: types.UPDATE_PATTERN,
   pattern
  })
}
