import React, {Component} from 'react'
import {Button, Checkbox, Icon, Modal} from 'semantic-ui-react'
import $ from 'jquery'
import {downloadPDF} from './download-excel'

class PdfExportModal extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}

    this.submitForm = this.submitForm.bind(this)
  }

  submitForm(e) {
    e.preventDefault()
    let {onClose, stopLoading, startLoading, results} = this.props
    startLoading()
    let separatePdfs = $('#separate-pdfs').is(':checked')
    downloadPDF(results, stopLoading, separatePdfs)
    onClose()
  }

  render() {
    let {open, onClose} = this.props
    return (
      <Modal size={'mini'} open={open} onClose={onClose} closeIcon>
        <Modal.Header>Export to PDF</Modal.Header>

        <center>
          <Checkbox id="separate-pdfs" toggle label='Separate PDFs'
            style={{marginBottom: '40px', marginTop: '25px'}} />
        </center>

        <div style={{padding: '10px'}}>
          <Button color='red' fluid onClick={this.submitForm}><Icon name='file pdf outline'/> Download PDF</Button>
        </div>
      </Modal>
    )
  }
}

export default PdfExportModal
