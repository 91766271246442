import React, {Component} from 'react'
import {Popup, Icon} from 'semantic-ui-react'
import {infoCircle} from './status-info-circle.js'

import {
  ADD_DAYS_TO_LOADING_DATE,
  ADD_DAYS_TO_POE_LOADING_DATE
} from '../../modules/constants'

const DefaultContactInfoCircleText = "Hide all OTHER contact's info"
const SalesHistoryInfoCircleText = 'Show the sales history for a contact'
const EtaDateInfoCircleText = 'ETA date = loading date + ' + ADD_DAYS_TO_LOADING_DATE + ' days.  For POE orders only add ' + ADD_DAYS_TO_POE_LOADING_DATE + ' days!'

const DefaultContactInfoCircle = infoCircle(DefaultContactInfoCircleText)
const SalesHistoryInfoCircle = infoCircle(SalesHistoryInfoCircleText)
const EtaDateInfoCircle = infoCircle(EtaDateInfoCircleText)

export {DefaultContactInfoCircle, SalesHistoryInfoCircle, EtaDateInfoCircle}
