import React from 'react'
import {Icon, Popup} from 'semantic-ui-react'
import Utils from '../../../modules/utils'

const deleteButton = (styleNumber, id, markPrintAsDeleted) => {
  return (
    <div style={{float: 'right'}}>
      <Popup content='Delete print' position='bottom left'
        trigger={
          <Icon name='trash alternate' style={{color: 'red', cursor: 'pointer', fontSize: '140%'}}
            onClick={() => {deletePrint(styleNumber, id, markPrintAsDeleted)}}
          />
        }
      />
    </div>
  )
}

const deletePrint = (styleNumber, id, markPrintAsDeleted) => {
  if (window.confirm('Are you sure you want to DELETE print ' + styleNumber + ' ?')) {
    Utils.delete('prints/' + id, (err, res) => {deletePrintSuccess(err, res, id, markPrintAsDeleted)})
  }
}

const deletePrintSuccess = (err, res, id, markPrintAsDeleted) => {
  if (err) {
    alert('There was an error when trying to delete print.')
  } else {
    markPrintAsDeleted(id)
  }
}

const markPrintAsDeleted = (id, prints, filteredPrints) => {
  for (let i=0;i<prints.length;i++) {
    if (prints[i].id == id) {
      prints[i].is_deleted = true
      break
    }
  }

  for (let i=0;i<filteredPrints.length;i++) {
    if (filteredPrints[i].id == id) {
      filteredPrints[i].is_deleted = true
      break
    }
  }

  return {newPrints: prints, newFilteredPrints: filteredPrints}
}

export {deleteButton, markPrintAsDeleted}
