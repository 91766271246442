import Utils from "../../modules/utils"

const url = 'update_is_paid'

const updateIsPaid = (refnumber, amountPaid, total) => {

  const callbackFunc = (err, result) => {
    if (err) {
      alert('There was an error when trying to save is paid.')
    } else {
      if (result.data.success) {
        alert('The VPOs has been successfully been paid')

        let refnumbers = refnumber.split(', ')
        let vpos_api = result.data.vpos
        let transactionId = result.data.transactionId

        //update react state
        let {vendorOrders, filteredVendorOrders} = window

        let newVendorOrders = []
        vendorOrders.forEach((vendorOrder) => {
          if (refnumbers.includes(vendorOrder.refnumber)) {
            vendorOrder.transaction_id = transactionId
            vendorOrder.time_paid = 'JUST NOW'
            let vpo_api = vpos_api.find(x => x.id == vendorOrder.id)
            vendorOrder.amount_paid = vpo_api.amountPaid / 100.0
            if (vendorOrder.discount == null) {
              vendorOrder.discount = vpo_api.discount
            }

          }
          newVendorOrders.push(vendorOrder)
        })
        window.vendorOrders = newVendorOrders

        if (filteredVendorOrders != null) {
          let newfilteredVendorOrders = []
          filteredVendorOrders.forEach((filteredVendorOrder) => {
            if (refnumbers.includes(filteredVendorOrder.refnumber)) {
              filteredVendorOrder.transaction_id = transactionId
              filteredVendorOrder.time_paid = 'JUST NOW'
              let vpo_api = vpos_api.find(x => x.id == filteredVendorOrder.id)
              filteredVendorOrder.amount_paid = vpo_api.amountPaid / 100.0
              if (filteredVendorOrder.discount == null) {
                filteredVendorOrder.discount = vpo_api.discount
              }
            }
            newfilteredVendorOrders.push(filteredVendorOrder)
          })
          window.filteredVendorOrders = newfilteredVendorOrders
        }

        let vpoTableForceUpdateEvent = new Event('vpoTableForceUpdate')
        window.dispatchEvent(vpoTableForceUpdateEvent)

      }
    }
  }

  let data = {refnumber, amountPaid, total}
  Utils.post(url, data, callbackFunc)
}

export {updateIsPaid}
