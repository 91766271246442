import React, { Component } from 'react'
import {getVpoStages} from './get'
import {stages} from '../../vpo-stages/options.js'
import {formatDate} from '../../cads/format-date'

const camelCase = (str) => {
    // Using replace method with regEx
    return str.replace('-','').replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

class VpoStages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stageText: '⏳'
    }

    this.getStageText = this.getStageText.bind(this)
    this.updateFromWindowDot = this.updateFromWindowDot.bind(this)
    this.updateAfterBulkEdit = this.updateAfterBulkEdit.bind(this)
  }

  updateAfterBulkEdit() {
    let {vpoIds} = window
    let {vpo} = this.props
    if (!!vpoIds[vpo['id']]) {
      this.getStageText()
      this.setState({stageText: '⏳'})
    }
  }

  componentDidMount() {
    this.getStageText()
    window.addEventListener('get-stage-text', this.updateAfterBulkEdit)
    window.addEventListener('get-stage-text-done', this.updateFromWindowDot)
  }

  updateFromWindowDot() {
    let {vpo} = this.props
    this.setState({stageText: window.vpoStages[vpo['id']]})
  }

  getStageText() {
    let {vpo} = this.props
    window.vpoStages[vpo['id']] = '⏳'

    clearTimeout(window.stageTextSetTimeout)
    window.stageTextSetTimeout = setTimeout(() => {
      getVpoStages((err, res) => {
        if (err) {
          alert('There was an error when trying to get stage text')
        } else {
          let {stages, vpoIds} = res.data
          vpoIds.forEach(vpoId => window.vpoStages[vpoId] = '—')
          stages.forEach(stage => window.vpoStages[stage.vpoId] = this.processText(stage))
          //send out event to update from window to state
          window.dispatchEvent(new Event('get-stage-text-done'))
        }
      })
    }, 1500)
  }

  processText(stage) {
    let text = ''
    let {data} = stage

    stages.forEach((name) => {
      if (data[camelCase(name)] != null && data[camelCase(name)]['status'] != null) {
        text += (name+'='+data[camelCase(name)]['status']+' ('+ data[camelCase(name)]['date'] +")\n")
      } else if (data[camelCase(name)] != null && Array.isArray(data[camelCase(name)])) {
        data[camelCase(name)].sort((a, b) => {return a['color'] > b['color'] ? 1 : -1}).forEach((row) => {
          text += (name+'='+row['status']+' ('+ row['color'] + ' ' + row['date'] +")\n")
        })
      }

      let etdDate = data[camelCase(name)] == null ? null : data[camelCase(name)]['etdDate']
      if (etdDate != null) {
        text += 'ETD Date=' + formatDate(etdDate) + "\n"
      }
    })

    return text
  }

  render() {
    let {stageText} = this.state

    return (
      <pre>{stageText}</pre>
    )
  }
}

export default VpoStages
