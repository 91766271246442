
import                         './index.css'
import React, {Component} from 'react'
import {
        Form,
        Checkbox,
        Grid,
        Button,
        Message,
        Segment,
        Header,
        Input
       }                  from 'semantic-ui-react'
import {filter, map}      from 'lodash'
import {withRouter}       from 'react-router-dom'
import LogMessages        from './../../shared/log-messages'
import Constants          from './../../../modules/constants'
import Utils              from './../../../modules/utils'
import CompanyAPI         from './../../../modules/api/company_api'
import CustomerAPI        from './../../../modules/api/customer_api'
import {DatePickerField}  from './../../ui/date-picker.js'
import {Auth0Context}     from './../../../contexts/auth-context'


class EditCompany extends Component
{
  static contextType = Auth0Context;

  constructor(props)
  {
    super(props)
    this.state =
    {
      loading       : true,
      isReady       : false,
      cLoading      : false,
      companies     : [],
      currentCompany: {},
      displayMessage: false,
      error         : null,
      approvalBrands: [],
      brandsLocked  : true,
      inputs        :
      {
        companyName :
        {
          value   : "",
          valid   : null,
          required: true,
          message : ""
        },
        brands: []
      }
    }

    this.fetchCompanies()
  }

  componentDidMount()
  {
    this.setState({isReady: true})
  }

  componentDidUpdate()
  {
    if (this.state.isReady)
    {
      var {customer} = this.context
      var {approvalBrands} = this.state

      if (customer != null) {
        approvalBrands = map(filter(customer.brands, (b => b.needsApproval == true )), "id")

        this.setBrandInputs(approvalBrands, (inputs) => {
          this.setState({
            inputs,
            approvalBrands,
            isReady: false,
          })
        })
      }
    }
  }

  fetchCompanies = () =>
  {
    var {companies} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        companies = data.companies
        this.setState({ companies, loading: false })
      }
    }

    CompanyAPI.getAll(callback)
  }

  handleSubmit = () =>
  {
    this.setState({ cLoading: true })
    const {cLoading, inputs, currentCompany, companies} = this.state

    let {data, valid} = this.getData()

    let callback = (error, data) =>
    {
      if (error)
      {
        this.setState({
          cLoading: false,
          displayMessage: true,
          error: { apiError: true, error: error }
        })
      }
      else
      {
        var index = companies.findIndex((c) => c.id === data.company.id)
        companies[index] = data.company
        this.clearInputs()

        this.setState({
          cLoading: false,
          currentCompany: {},
          companies
        })
      }
    }

    if (valid && currentCompany && currentCompany.id)
    {
      CompanyAPI.update(currentCompany.id, {company: data}, callback)
    }
  }

  getData = () =>
  {
    let {inputs} = this.state
    let data     = {}

    data.name = inputs.companyName.value
    data.companyBrandsAttributes = []

    for (var brand of inputs.brands)
    {
      data.companyBrandsAttributes.push(
      {
        id       : brand.companyBrandId,
        approved : brand.approved,
        startDate: brand.approved ? Utils.formatDateForSubmit(brand.startDate) : null,
        category : brand.approved ? brand.category : []
      })
    }

    return { data, valid: this.isInputsValid() }
  }

  clearInputs = () =>
  {
    var {inputs} = this.state
    inputs.companyName =
    {
      value   : "",
      valid   : null,
      required: true,
      message : ""
    }

    for (var brand of inputs.brands)
    {
      brand.companyBrandId = null
      brand.category       = new Array
      brand.startDate      = new Date
      brand.approved       = false
    }
  }

  isInputsValid = () =>
  {
    let {inputs} = this.state
    let valid    = Utils.isInputsValidated(inputs)

    for (var brand of inputs.brands)
    {
      if (brand.approved)
      {
        valid = brand.startDate //&& brand.category.length > 0 //empty is all
        if (!valid) return valid
      }
      else
      {
        valid = true
      }
    }
    return valid
  }

  onCompanySelection = (event, {value}) =>
  {
    let {companies} = this.state
    let company = companies.find((comp) => comp.id === value)
    if (company && company.id)
    {
      this.setInputsFromCompany(company)
    }
  }

  setInputsFromCompany = (company, setState=true) =>
  {
    let {inputs} = this.state
    if (!company)
    {
      return;
    }

    inputs.companyName.value = company.name
    inputs.companyName.valid = true

    for (var brand of inputs.brands)
    {
      var tempBrand = company.companyBrands.find(b => b.brandId === brand.id)
      brand.companyBrandId = tempBrand.id
      brand.approved       = tempBrand.approved
      brand.startDate      = tempBrand.startDate || new Date
      brand.category       = tempBrand.category
    }

    if (setState)
    {
      this.setState({ inputs, currentCompany: company })
    }
  }

  handleChange = (e, data, brand=null) =>
  {
    let state  = this.state
    let name, value, brandInput;
    let inputs = state.inputs

    if (brand)
    {
      brandInput = inputs.brands.find(b => b.id === brand.id)
    }

    switch(data.name)
    {
      case "companyName":
      {
        inputs.companyName.value = data.value
        inputs.companyName.valid = !!data.value && this.validateCompanyName(data.value)
        inputs.companyName.message = !data.value ? "Cant be empty!" : "Name is already Taken!"
        break
      }
      case "approved":
      {
        brandInput.approved = data.value
        break
      }
      case "startDate":
      {
        brandInput.startDate = data.value
        break
      }
      case "category":
      {
        brandInput.category = data.value
        break
      }
      default :
      {

      }
    }

    this.setState({ inputs })
  }

  validateCompanyName = (name) =>
  {
    var {companies, currentCompany} = this.state
    if (!currentCompany) return false
    var found = companies.find((comp) => comp.id !== currentCompany.id && comp.name.toLowerCase() == name.toLowerCase())
    return !found
  }

  changeBrandsApproval = (e, {value}) =>
  {
    this.setState({approvalBrands: value, brandsLocked: false})
  }

  submitBrandsApproval = () =>
  {
    this.setState({cLoading: true})
    var {approvalBrands, inputs, currentCompany} = this.state
    var {customer, setCustomer} = this.context

    let callback = (error, data) =>
    {
      if (error)
      {
        this.setState({
          cLoading: false,
          displayMessage: true,
          error: { apiError: true, error: error }
        })
      }
      else
      {
        customer.brands = data.brands
        setCustomer(customer)
        this.setBrandInputs(approvalBrands, (inputs) => {
          this.setState({
            cLoading: false,
            brandsLocked: true,
            inputs
          })
        })
      }
    }

    CustomerAPI.updateBrandApprovals({approval: approvalBrands}, callback)
  }

  setBrandInputs = (brands=[], cb) =>
  {
    var {inputs, currentCompany} = this.state
    var {customer} = this.context

    for (var brandId of brands)
    {
      var brand = customer.brands.find(b => b.id === brandId)
      var brandInput = Object.assign(
        {
          id: brand.id,
          name: brand.name,
          companyBrandId: null,
          category: new Array,
          startDate: new Date,
          approved: false
        })

      let index = inputs.brands.findIndex((b) => b.id === brandInput.id)
      if (index === -1)
      {
        inputs.brands.push(brandInput)
      }
      else
      {
        inputs.brands[index] = brandInput
      }
    }

    inputs.brands = filter(inputs.brands, (b=> brands.includes(b.id)))

    if (currentCompany && currentCompany.id)
    {
      this.setInputsFromCompany(currentCompany, false)
    }

    return cb(inputs)
  }

  render()
  {
    var {inputs, currentCompany, companies, approvalBrands, brandsLocked} = this.state

    const {customer} = this.context
    const categories = customer ? Utils.objectsToOptionsHash([...customer.categories], 'id', 'name') : []
    const brands = customer ? Utils.objectsToOptionsHash([...customer.brands], 'id', 'name') : []
    const companyOptions = Utils.objectsToOptionsHash([...companies], 'id', 'name')

    const isEditable = (brand=null) => brand ? brand.approved && currentCompany && currentCompany.id : currentCompany && currentCompany.id

    let markup =
      <div id='companies-section' style={{padding: '75px 60px'}}>
        {
          this.state.loading ?
          null
          :
          <div>
            <h1 style={{marginBottom: '80px'}}>Accounts</h1>
            <Grid centered columns={2}>
              <Grid.Column width={11}>

                <Segment className="segment-wrap">
                  <Header content="Edit Accounts" />
                  <Form
                    onSubmit={this.handleSubmit}
                    loading={this.state.cLoading}
                    >
                    <Form.Group widths='equal'>
                      <Form.Select
                        id="company-select"
                        label='Accounts'
                        onChange={this.onCompanySelection}
                        options={companyOptions}
                        placeholder='Select Account'
                        value={currentCompany.id || ''}
                        search
                      />
                      <Form.Input
                        id="new-name"
                        label='New Name'
                        name='companyName'
                        icon={inputs.companyName.valid ? 'check' : 'close'}
                        iconPosition='left'
                        placeholder='Enter New Name'
                        value={inputs.companyName.value}
                        onChange={(e, {value}) => this.handleChange(e, {name: 'companyName', value: value})}
                        error={inputs.companyName.valid == false ? {
                          content: inputs.companyName.message,
                        } : false }
                      />
                    </Form.Group>

                    {
                      inputs.brands.map((brand, i) => {
                        return <React.Fragment>
                          <Form.Group className="brand-input-group" widths='equal' key={`brand__${i}`}>
                            <Form.Field
                              control={Checkbox}
                              toggle
                              disabled={!isEditable()}
                              checked={brand.approved}
                              label={`${brand.name} Approved`}
                              onChange={(e, {checked})=> this.handleChange(e, {name: 'approved', value: checked}, brand)}
                            />

                            <div style={!isEditable(brand) ? {opacity: 0.5, pointerEvents: 'none', display: 'inline-block'} : {display: 'inline-block'}}>
                              <b style={{fontSize: '95%'}}>Start Date</b><br/>
                              <input type="date" value={brand.startDate}
                                onChange={(e) => this.handleChange(e, {name: 'startDate', value: e.target.value}, brand)}
                                style={{paddingTop: '7px', paddingBottom: '8px', marginTop: '2px'}}
                              />
                            </div>

                            <Form.Select
                              label='Category'
                              multiple
                              disabled={!isEditable(brand)}
                              options={categories}
                              placeholder='Category'
                              value={brand.category}
                              onChange={(e, {value}) => this.handleChange(e, {name: 'category', value: value}, brand)}
                            />

                          </Form.Group>
                          <div style={{textAlign: 'right', marginTop: '-12px'}}><small style={{opacity: 0.5}}>Leave empty for all categories</small></div>
                        </React.Fragment>
                      })
                    }

                    <Button
                      className="submit-btn"
                      disabled={!this.isInputsValid()}
                      primary
                      type='submit'
                      content='Submit'
                    />
                  </Form>
                </Segment>

                <br/>
                <br/>

                <Segment className="segment-wrap">
                  <Form onSubmit={this.submitBrandsApproval} loading={this.state.cLoading}>
                    <Header content="Edit Brands Needing Approval" />
                    <Form.Select
                      fluid
                      width={10}
                      options={brands}
                      multiple
                      label='Brand'
                      name='brandsForApproval'
                      value={approvalBrands}
                      onChange={this.changeBrandsApproval}
                      placeholder='brands'
                    />
                    <Button
                      className="submit-btn"
                      primary
                      disabled={brandsLocked}
                      type='submit'
                      content='Submit'
                    />
                  </Form>
                </Segment>

                <br/>
                <br/>

                {
                  currentCompany && currentCompany.id &&
                  <LogMessages
                    titles={["Account Logs"]}
                    logs={[currentCompany.logs]}
                  />
                }
              </Grid.Column>
            </Grid>
          </div>
        }
      </div>

    return markup;
  }
}

export default withRouter(EditCompany)
