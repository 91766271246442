import React from 'react'
import {Select} from 'semantic-ui-react'
import {seasonOptions} from './options'

const seasonInput = (season, fieldOnChange) => {
  return (
    <React.Fragment>
      <label>Season</label><br/>
      <Select search name="season" style={{width: '300px'}} placeholder='Select a season'
        options={seasonOptions} onChange={fieldOnChange}
        value={season}
      />
    </React.Fragment>
  )
}

export {seasonInput}
