const getAssetId = (buildingType, source, buildingSize, roomsNumber) => {
  return ('' + (buildingType == null ? '?' : buildingType[0]) + (source == null ? '?' : source[0]) + (logNumberToLetter(buildingSize)) + (logNumberToLetter(roomsNumber)))
}

const letters = ['A','B','C','D','E','F','G','H','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','Z']

const logNumberToLetter = (str) => {
  let index = Math.floor(Math.log10(Number(str)))
  return letters[index] == null ? '?' : letters[index]
}

export {getAssetId}
