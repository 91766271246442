import React, {Component} from 'react'
import {Icon} from 'semantic-ui-react'
import {dateInputStyle} from '../edit-sales-order/date-inputs.js'
import {dateLabel} from '../new-vpo-page/cad-details-section/date-label.js'
import ContinueButton from '../new-vpo-page/continue-button.js'
import {getUpdatedDates} from './container-number/get-updated-dates.js'
import {saveUpdatedDates} from './container-number/save-updated-dates.js'
import {ADD_DAYS_TO_UPDATED_ETA, ADD_DAYS_TO_UPDATED_DELIV_DATE} from '../../modules/constants'

const mainDivStyle = {boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5', padding: '10px 20px', borderRadius: '.28571429rem', width: '700px'}

const initialState = {
  updatedEta: '',
  updatedDelivDate: '',
  loading: true,
  showSuccess: false
}

class ContainerNumber extends Component
{
  constructor(props)
  {
    super(props)
    this.state = initialState

    this.getUpdatedDates = this.getUpdatedDates.bind(this)
    this.mySetState = this.mySetState.bind(this)
    this.onChangeUpdatedEta = this.onChangeUpdatedEta.bind(this)
    this.onChangeUpdatedDelivDate = this.onChangeUpdatedDelivDate.bind(this)
    this.markAsComplete = this.markAsComplete.bind(this)
    this.stopLoading = this.stopLoading.bind(this)
    this.showSuccess = this.showSuccess.bind(this)
  }

  componentDidMount() {
    setTimeout(this.getUpdatedDates, 1000)
  }

  getUpdatedDates() {
    let {number, numbers} = this.props

    if (number == '') {
      number = numbers.join('@@@').toUpperCase()
    }

    this.setState(initialState)
    getUpdatedDates(number, this.mySetState, this.stopLoading)
  }

  mySetState(newState) {
    this.setState(newState)
  }

  onChangeUpdatedEta(event) {
    let {value} = event.currentTarget
    this.setState({updatedEta: value, showSuccess: false})
  }

  onChangeUpdatedDelivDate(event) {
    let {value} = event.currentTarget
    this.setState({updatedDelivDate: value, showSuccess: false})
  }

  markAsComplete() {
    let {updatedEta, updatedDelivDate} = this.state
    let {number, numbers} = this.props

    if (number == '') {
      number = numbers.join('@@@').toUpperCase()
    }

    this.setState({loading: true, showSuccess: false})
    saveUpdatedDates(updatedEta, updatedDelivDate, number, this.stopLoading, this.showSuccess)
  }

  stopLoading() {
    this.setState({loading: false})
  }

  showSuccess() {
    this.setState({showSuccess: true})
    window.dispatchEvent(new Event('get-container-number-data'))
  }

  render() {
    let {updatedEta, updatedDelivDate, loading, showSuccess} = this.state
    let {number, numbers} = this.props

    if (number == '') {
      number = numbers.join(', ').toUpperCase()
    }

    let complete = updatedEta != '' || updatedDelivDate != ''

    return (
      <div style={mainDivStyle}>
        <div style={{fontWeight: 'bold'}}><Icon name='ship'/> UPDATE DATES FOR CONTAINER # <span style={{borderBottom: '1px solid black'}}>{number}</span></div>

        <div style={{display: 'inline-block', paddingTop: '10px'}}>
          {dateLabel('Updated ETA', {})}
          <input type="date"
            value={updatedEta}
            onChange={this.onChangeUpdatedEta}
            style={dateInputStyle}
          />
          <div style={{color: 'rgb(55,125,34)'}}>
            We add <b>+{ADD_DAYS_TO_UPDATED_ETA} days</b> to <Icon name='long arrow alternate up'/>
          </div>
        </div>

        <div style={{display: 'inline-block', paddingTop: '15px', paddingLeft: '15px'}}>
          {dateLabel('Updated Deliv Date', {})}
          <input type="date"
            value={updatedDelivDate}
            onChange={this.onChangeUpdatedDelivDate}
            style={dateInputStyle}
          />
          <div style={{color: 'rgb(55,125,34)'}}>
            We add <b>+{ADD_DAYS_TO_UPDATED_DELIV_DATE} days</b> to <Icon name='long arrow alternate up'/>
          </div>
        </div>

        <div style={{display: 'inline-block', paddingTop: '15px', paddingLeft: '15px', verticalAlign: 'top', marginTop: '-20px'}}>
          <ContinueButton markAsComplete={this.markAsComplete} complete={complete} text="Save"
            submittingForm={loading}
          />
        </div>

        <div style={{opacity: (showSuccess ? 1 : 0), fontWeight: 'bold', color: 'rgb(89,182,85)', marginTop: '15px', textAlign: 'center'}}>
          Saved <Icon name='check' />
        </div>
      </div>
    )
  }
}

export default ContainerNumber
