import React, { Component } from 'react'
import $ from 'jquery'
import { Message, Button, Icon, Popup } from 'semantic-ui-react'
import Utils from "../../modules/utils"

let uncheckedCircleStyle = {
  display: 'inline-block',
  border: '1px solid rgb(220,220,220)',
  height: '20px',
  width: '20px',
  borderRadius: '10px',
  position: 'relative',
  top: '3px',
  right: '25px',
  background: 'white',
  marginRight: '-15px',
  cursor: 'pointer',
  padding: '1px',
  marginBottom: '5px'
}

class FlaggedMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: []
    }

    this.check = this.check.bind(this)
    this.uncheck = this.uncheck.bind(this)
  }

  componentDidMount() {}

  componentDidUpdate() {}

  check(log) {
    let data = {logId: log.id}

    Utils.post('check_log', data, (err, result) =>
    {
      if (err) {
        console.log('There was an error when trying to mark change as done')
      } else {
        let {checked} = this.state
        checked.push(log.id)
        this.setState({checked})
      }
    })
  }

  uncheck(log) {
    let data = {logId: log.id}

    Utils.post('uncheck_log', data, (err, result) =>
    {
      if (err) {
        console.log('There was an error when trying to unmark change as done')
      } else {
        let {checked} = this.state
        checked = checked.filter(i => i != log.id)
        this.setState({checked})
      }
    })
  }

  render() {
    let {flaggedForUpdate, removeFlag, isProduction, logs} = this.props
    let {checked} = this.state

    if (flaggedForUpdate) {

      const content = (
        <div style={{paddingLeft: '20px'}}>
          <Button id="remove-flag-button" basic style={{float: 'right'}} onClick={removeFlag}>Remove flag</Button>

          {
            logs.length > 0
            ?
            <span>The following changes need to be made in QB:</span>
            :
            null
          }

          <ul>
            {logs.map((log, index) => {

              let isChecked = checked.includes(log.id)

              return (
                <li key={index}>
                  <div style={{display: 'none'}}>
                    {
                      isChecked
                      ?
                      <div style={uncheckedCircleStyle} onClick={this.uncheck.bind(null, log)}>
                        <Icon name='check' style={{color: 'rgb(119,192,176)', position: 'relative', bottom: '1px'}}/>
                      </div>
                      :
                      <Popup content='Mark change as done' trigger={
                        <div style={uncheckedCircleStyle} onClick={this.check.bind(null, log)}>
                          <Icon name='check' style={{color: 'white', position: 'relative', bottom: '1px'}}/>
                        </div>
                      } />
                    }
                  </div>
                  <span style={isChecked ? {textDecoration: 'line-through', opacity: 0.3} : {}}>
                    {log.message} &nbsp; — &nbsp; {log.user_name} &nbsp; — &nbsp; {log.date_string}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      )


      return (
        <Message
          style={{maxWidth: '1200px', margin: 'auto', marginTop: '40px'}}
          warning
          icon='flag'
          header={<h2>This order is flagged for update in QB!</h2>}
          content={content}
        />
      )
    } else {
      return null
    }
  }
}

export default FlaggedMessage
