import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import { Grid, Button, Icon, TextArea, Dimmer, Loader, Popup, Label } from 'semantic-ui-react'
import Dropzone from 'react-dropzone'
import $ from 'jquery'
import Constants from './modules/constants'
import Utils from './modules/utils'
import {resizeTextarea} from './components/new-vpo-page/select-cads/resize-textarea.js'
import {processText} from './components/new-vpo-page/select-cads/process-text.js'

let uploadMessagesStyle = {
  float: 'right',
  padding: '10px',
  textAlign: 'right',
  background: 'lightgrey',
  opacity: 0.5,
  marginTop: '20px',
  marginRight: '20px',
  pointerEvents: 'none',
  maxHeight: 'calc(100vh - 231px)',
  overflow: 'hidden'
}

let longDashStyle = {
  color: 'rgb(89,182,85)',
  fontSize: '19px',
  display: 'inline-block',
  paddingRight: '3px',
  marginTop: '-10px',
  marginBottom: '-10px',
  cursor: 'default'
}

class NewPackingSlipOrBolPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      richTextMessageHtml: '',
      messages: [],
      bolShippedDate: '',
      ponumberText: '',
      ponumberTextLoading: false,
      ponumberStatus: [], //['found', 'found', 'found', null, 'not found', 'multiple found']
      ponumbers: [],
      numPonumbers: 0,
      numPonumbersFound: 0,
      numPonumbersDuplicate: 0,
      numPonumbersNotFound: 0,
      poIds: [],
      loading: false
    }

    this.richTextMessageHtmlOnchange = this.richTextMessageHtmlOnchange.bind(this)
    this.addLogMessage = this.addLogMessage.bind(this)
    this.createPackingSlipOrBol = this.createPackingSlipOrBol.bind(this)
    this.clearMessage = this.clearMessage.bind(this)
    this.bolShippedDateOnchange = this.bolShippedDateOnchange.bind(this)
    this.clearDate = this.clearDate.bind(this)
    this.todaysDate = this.todaysDate.bind(this)
    this.textareaOnChange = this.textareaOnChange.bind(this)
    this.clearTextarea = this.clearTextarea.bind(this)
    this.searchForPonumbers = this.searchForPonumbers.bind(this)
    this.getPonumberStatus = this.getPonumberStatus.bind(this)
    this.markAsShipped = this.markAsShipped.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  richTextMessageHtmlOnchange(value) {
    this.setState({richTextMessageHtml: value})
  }

  handleAcceptedFiles(acceptedFiles) {
    let {ponumberStatus} = this.state
    let {fileType} = this.props

    //what using in here?
    var role = sessionStorage.getItem('currentUserRole')
    let isAdmin = role == 'admin' || role == 'superadmin'
    let profile = JSON.parse(sessionStorage.getItem('user'))
    let user_email = profile.email

    //You must have shipped date if uploading bol
    if (fileType == 'bol') {
      let haveShippedDate = $('#bol-ship-date').val() != ''
      if (!haveShippedDate) {
        alert('Shipped date is required!')
        return
      }

      let ponumberStatusAllFoundOrNull = ponumberStatus.length == 0 || ponumberStatus.every(status => status == 'found' || status == null)
      if (!ponumberStatusAllFoundOrNull) {
        alert('Not all REF # or PO # are found!')
        return
      }
    }

    if (fileType == 'packing_slip') {
      let haveMessage = this.state.richTextMessageHtml != '' && this.state.richTextMessageHtml != '<p><br></p>'
      if (!haveMessage) {
        alert('Message is required!')
        return
      }
    }

    let isDistro = fileType == 'distro'

    acceptedFiles.forEach(file => {
      let formData = new FormData()
      formData.append("file", file, file.name)

      let url = Constants.API.apiEndPoint + 'upload-file'

      if (isDistro) {
        url += '?is_distro=true'
      }

      Utils.uploadFile(url, formData, (error, data) =>
      {
        if (error)
        {
          alert('There was an error when trying to upload the file.')
        }
        else
        {
          if (data.success)
          {
            this.createPackingSlipOrBol(data)
          }
          else
          {
            if (data.corrupt_file)
            {
              alert('The file ' + file.name + ' is corrupt.')
            }
            else
            {
              alert('There was an error when trying to upload the file.')
            }
          }
        }
      })
    })
  }

  createPackingSlipOrBol(data) {
    let {fileType} = this.props
    let {poIds} = this.state

    let data2 = {
      url: data.url,
      filename: data.filename,
      rich_text: this.state.richTextMessageHtml,
      file_type: fileType
    }

    if (fileType == 'bol') {
      data2.bol_ship_date = $('#bol-ship-date').val()
      data2.po_ids = poIds
    }

    let ponumber = data.filename.split('.').slice(0, -1).join('.').toUpperCase().trim()

    if (fileType == 'packing_slip' && ponumber.substring(0, 12) == 'PACKINGSLIP_') {
      ponumber = ponumber.replace('PACKINGSLIP_', '')
    }

    if (fileType == 'bol' && ponumber.substring(0, 4) == 'BOL_') {
      ponumber = ponumber.replace('BOL_', '')
    }

    if (fileType == 'distro' && ponumber.substring(0, 7) == 'DISTRO_') {
      ponumber = ponumber.replace('DISTRO_', '')
    }

    ponumber = ponumber.trim()

    Utils.post('create_packing_slip_or_bol', data2, (err, res) =>
    {
      if (err) {
        this.addLogMessage('There was an error when trying to create the ' + this.props.title)
      } else {
        if (res.data.success) {
          //success
          if (res.data.successMessage == "created") {
            let successPonumber = res.data.ponumber == null ? ponumber : res.data.ponumber
            this.addLogMessage('A new ' + this.props.title + ' has successfully been created from file "' + data.filename + '", sales order with PO # "' + successPonumber + '" is status ' + res.data.status);

          } else if (res.data.successMessage == "replaced") {
            this.addLogMessage('The ' + this.props.title + ' has successfully been replaced, sales order with PO # "' + ponumber + '" is status ' + res.data.status);

          } else if (res.data.successMessage == "multiple_bol") {
            res.data.orderData.reverse().forEach(row => {
              this.addLogMessage('— Sales order with PO # "' + row.ponumber + '" (REF # "' + row.refnumber + '") is status ' + row.status)
            })
            this.addLogMessage('A new BOL has successfully been created from file "' + data.filename + '" and attached to the following orders:')

          }
        } else {
          //must be an error
          if (res.data.errorMessage == "PO # not found") {
            this.addLogMessage('Error creating new ' + this.props.title + ', sales order with PO # "' + ponumber + '" CANNOT be found!');

          } else if (res.data.errorMessage == "multiple matches for PO #") {
            this.addLogMessage('Error creating new ' + this.props.title + ', there are multiple sales order with PO # "' + ponumber + '" found!');

          } else {
            this.addLogMessage('There was an error when trying to create the ' + this.props.title)
          }


        }
      }
    })
  }

  addLogMessage(message) {
    let newMessages = this.state.messages;
    newMessages.unshift(message);
    this.setState({messages: newMessages});
  }

  clearMessage() {
    this.setState({richTextMessageHtml: ''})
  }

  bolShippedDateOnchange(e) {
    this.setState({bolShippedDate: e.currentTarget.value})
  }

  clearDate() {
    this.setState({bolShippedDate: ''})
    $('#bol-ship-date').val('')
  }

  todaysDate() {
    var todayDate = new Date()
    todayDate.setMinutes(todayDate.getMinutes() - todayDate.getTimezoneOffset())
    let value = todayDate.toISOString().slice(0,10)
    this.setState({bolShippedDate: value})
    $('#bol-ship-date').val(value)
  }

  textareaOnChange(e, value) {
    this.setState({ponumberText: value.value, ponumberStatus: [], poIds: []})
    clearTimeout(window.ponumberTextareaTimeout)
    window.ponumberTextareaTimeout = setTimeout(this.searchForPonumbers, 1000)
  }

  clearTextarea() {
    this.setState({ponumberText: ''})
    $('#ponumber-textarea').val('')
    resizeTextarea('ponumber-textarea')
    this.setState({ponumberTextLoading: false, ponumberStatus: [], poIds: [], numPonumbers: 0, numPonumbersFound: 0, numPonumbersDuplicate: 0, numPonumbersNotFound: 0})
  }

  searchForPonumbers() {
    resizeTextarea('ponumber-textarea')

    let {ponumberText} = this.state
    if (ponumberText.length > 2) {
      this.setState({ponumberTextLoading: true})
      let ponumbers = processText(ponumberText)
      let numPonumbers = ponumbers.filter(x => x != '').length
      this.setState({ponumbers, numPonumbers})
      this.getPonumberStatus(ponumbers)

    } else {
      this.setState({ponumberTextLoading: false, ponumberStatus: [], poIds: [], numPonumbers: 0, numPonumbersFound: 0, numPonumbersDuplicate: 0, numPonumbersNotFound: 0})
    }
  }

  getPonumberStatus(ponumbers) {
    Utils.post('get_ponumber_status', {ponumbers}, (err, res) =>
    {
      if (err) {
        this.addLogMessage('There was an error when trying to validate PO #s')
      } else {
        if (res.data.success) {
          let {ponumberStatus, poIds} = res.data

          let numPonumbersFound = ponumberStatus.filter(x => x == 'found').length
          let numPonumbersDuplicate = ponumberStatus.filter(x => x == 'duplicate').length
          let numPonumbersNotFound = ponumberStatus.filter(x => x == 'not found').length

          this.setState({ponumberStatus, poIds, ponumberTextLoading: false, numPonumbersFound, numPonumbersDuplicate, numPonumbersNotFound})
        } else {
          this.addLogMessage('There was an error when trying to validate PO #s')
        }
      }
    })
  }

  markAsShipped() {
    let {poIds} = this.state

    let data = {purchase_order_ids: poIds}

    this.setState({loading: true})

    Utils.post('sales_order_shipped', data, (err, res) =>
    {
      this.setState({loading: false})
      if (err) {
        alert('There was an error when trying to mark orders as Shipped.')
      } else {
        if (res.data.success) {
          this.clearTextarea()
          setTimeout(
            () => alert((poIds.length > 1 ? 'Orders are' : 'Order is') + ' now shipped')
          , 10)
        }
      }
    })
  }

  render() {
    let {richTextMessageHtml, messages, bolShippedDate, ponumberText, ponumberTextLoading, ponumberStatus, numPonumbers, numPonumbersFound, numPonumbersDuplicate, numPonumbersNotFound, loading} = this.state
    let {fileType, title} = this.props
    let haveMessage = richTextMessageHtml != '' && richTextMessageHtml != '<p><br></p>'
    let haveBolShippedDate = bolShippedDate != ''

    let bolTextArea = (
      <React.Fragment>
        <div style={{position: 'absolute', left: '-10px'}}>
          <Loader active={ponumberTextLoading} inline size='tiny'/>
        </div>
        {
          ponumberTextLoading
          ?
          null
          :
          <div style={{position: 'absolute', left: '-10px', marginTop: '3px'}}>
            {ponumberStatus.map((status, index) => {
              switch(status) {
                case 'found':
                  return (
                    <React.Fragment key={index}>
                      <Icon name='check' color='green'/><br/>
                    </React.Fragment>
                  )
                case 'duplicate':
                  return (
                    <React.Fragment key={index}>
                      <Popup position='left center' content='Duplicate found' trigger={<span style={longDashStyle}>—</span>}/><br/>
                    </React.Fragment>
                  )
                case null:
                  return <br key={index}/>
                case 'not found':
                  return (
                    <React.Fragment key={index}>
                      <Popup position='left center' content='Error: Sales order not found' trigger={<Icon name='x' color='red'/>}/><br/>
                    </React.Fragment>
                  )
                case 'multiple found':
                  return (
                    <React.Fragment key={index}>
                      <Popup position='left center' content='Error: Multiple sales orders found' trigger={<Icon name='warning' color='red'/>}/><br/>
                    </React.Fragment>
                  )
                default:
                  return <br key={index}/>
              }
            })}
          </div>
        }
        <TextArea id="ponumber-textarea" placeholder='Enter multiple REF #s or PO #s' style={{width: '100%', minHeight: '150px', minWidth: '100%', maxWidth: '100%', marginBottom: '5px', lineHeight: '1.4em'}} onChange={this.textareaOnChange}/>
        <div style={{position: 'relative', bottom: '10px', opacity: 0.5}}>
          {
            numPonumbers == 0
            ?
            null
            :
            <small>
              ({numPonumbers} PO #{numPonumbers == 1 ? '' : 's'} entered)
            </small>
          }
        </div>
      </React.Fragment>
    )

    let bolTextAreaColumn = (
      <Grid.Column width={4}>
        <div>
          <small style={{opacity: 0.5}}>Each REF # or PO # must be on a separate line!</small>
          {
            ponumberText != ''
            ?
            <Button basic color='red' onClick={this.clearTextarea} style={{padding: '3px 6px', float: 'right', position: 'relative', bottom: '3px'}}><Icon name='eraser'/> Erase</Button>
            :
            null
          }
        </div>
        {bolTextArea}

        {
          fileType == 'shipped'
          ?
          <center style={{paddingTop: '50px'}}>
            <div style={numPonumbers == 0 ? {cursor: 'not-allowed'} : {}}>
              <Button disabled={numPonumbers == 0} onClick={this.markAsShipped} size='massive' primary style={{paddingLeft: '100px', paddingRight: '100px'}}>Submit</Button>
            </div>
          </center>
          :
          null
        }
      </Grid.Column>
    )

    const totalLabels = (
      <Grid.Column width={6} style={{textAlign: 'right', paddingTop: '34px'}}>
        <Label as='a' image>
          <Icon name='check' color='green'/>
          {numPonumbersFound}
        </Label>
        +
        <Label as='a' image>
          <Popup position='left center' content='Duplicate found' trigger={<span style={longDashStyle}>—</span>}/>
          {numPonumbersDuplicate}
        </Label>
        +
        <Label as='a' image>
          <Popup position='left center' content='Error: Sales order not found' trigger={<Icon name='x' color='red'/>}/>
          {numPonumbersNotFound}
        </Label>
        =
        <Label as='a' image>
          {numPonumbersFound + numPonumbersDuplicate + numPonumbersNotFound}
        </Label>
      </Grid.Column>
    )

    return (
      <div style={{padding: '50px 60px'}}>
        <Dimmer active={loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <Grid>
          <Grid.Column width={fileType == 'shipped' ? 6 : 9}>
            <h1 style={{marginTop: '25px', marginBottom: '30px'}}>
              {fileType == 'shipped' ? '' : 'Upload'} {title}
            </h1>
            <h3 style={{opacity: 0.6, fontWeight: 500, marginBottom: '25px'}}>
              {
                fileType == 'shipped'
                ?
                <React.Fragment>
                  PO # lookup rules: <br/>
                  - exact match<br/>
                  - ignore first 1 or 2 digits for TJ Maxx or Winners<br/>
                  - ignore last 2 digits for Burlington<br/>
                  - add leading zero<br/>
                  - remove dash and last 4 digits for Nordstrom<br/><br/>

                  For example:<br/>
                  - If you enter <b>12345678</b> for a TJ Maxx or Winners order, <br/>
                  &nbsp; it will check for exact match, <br/>
                  &nbsp; then check for <b>2345678</b> or <b>345678</b><br/>
                  - If you enter <b>55556666</b> for a Burlington order, <br/>
                  &nbsp; it will check for exact match, then check for <b>555566</b>
                </React.Fragment>
                :
                <React.Fragment>
                  The filename will be used as the PO #

                  {fileType == 'distro' ?
                    <span>
                      &nbsp;&nbsp; <b style={{opacity: 0.5, fontSize: '150%'}}>|</b> &nbsp;&nbsp; Doc parser will get Ship to
                    </span>
                  : null}

                  {fileType == 'bol' ?
                    <span>
                      &nbsp;&nbsp; <b style={{opacity: 0.5, fontSize: '150%'}}>OR</b> &nbsp;&nbsp; Enter multiple REF #s or PO #s into the textarea
                    </span>
                  : null}

                  <br/>
                  <small>
                    ("{fileType.replace('_','').toUpperCase()}_" in start of filename will be ignored, for example: "{fileType.replace('_','').toUpperCase()}_12345678.pdf")
                  </small>
                </React.Fragment>
              }
            </h3>
          </Grid.Column>
          {fileType == 'packing_slip' ?
            <Grid.Column width={8}>
              <div style={{width: '600px', margin: 'auto', marginTop: '20px', marginBottom: '10px'}}>
                <b style={{position: 'relative', bottom: '5px'}}>Message</b> &nbsp;
                {
                  haveMessage
                  ?
                  <Button basic color='red' onClick={this.clearMessage} style={{padding: '3px 6px', position: 'relative', bottom: '5px', float: 'right'}}><Icon name='eraser'/> erase message</Button>
                  :
                  null
                }
                <br/>
                <ReactQuill
                  value={richTextMessageHtml}
                  onChange={this.richTextMessageHtmlOnchange}
                />
              </div>
            </Grid.Column>
          : null}
          {fileType == 'bol' ?
            <React.Fragment>
              <Grid.Column width={3}>
                <div style={{width: '200px', margin: 'auto', marginTop: '30px'}}>
                  <b>BOL shipped date</b><br/>
                  <input id="bol-ship-date" type="date" onChange={this.bolShippedDateOnchange}/> <div style={{height: '5px'}}></div>
                  {
                    haveBolShippedDate
                    ?
                    <Button basic color='red' onClick={this.clearDate} style={{padding: '3px 6px', position: 'absolute'}}><Icon name='eraser'/> Erase date</Button>
                    :
                    <Button basic color='blue' onClick={this.todaysDate} style={{padding: '3px 6px', position: 'absolute'}}>Select today's date</Button>

                  }
                </div>
              </Grid.Column>
              {bolTextAreaColumn}
            </React.Fragment>
          : null}

          {
            fileType == 'shipped'
            ?
            <React.Fragment>
              {bolTextAreaColumn}
              {totalLabels}
            </React.Fragment>
            :
            null
          }
        </Grid>

        {messages.length == 0 ? null : (
          <div style={uploadMessagesStyle}>
            {messages.map(function(message, index) {
              return (
                <div key={index}>{message}</div>
              );
            })}
          </div>
        )}

        {
          fileType == 'shipped'
          ?
          null
          :
          <Dropzone onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} id="my-dropzone" >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>
        }
      </div>
    )
  }
}


export default NewPackingSlipOrBolPage
