import React, {Component} from 'react'
import {Modal} from 'semantic-ui-react'
import Utils from './../../../modules/utils'
import Dropzone from 'react-dropzone'
import $ from 'jquery'
import UploadDistro from '../upload-distro'

class UploadDistroModal extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <Modal
        closeIcon
        open={this.props.uploadDistroModal}
        onClose={this.props.closeUploadDistroModal}
        size={'fullscreen'}
        >
        <Modal.Header>
          Upload distro for
          {this.props.currentPO == null || this.props.currentPO.customerOrderNumber == null || this.props.currentPO.customerOrderNumber == '' ? null : ' PO# '+this.props.currentPO.customerOrderNumber}
          &nbsp;&nbsp;&nbsp;
          {this.props.currentPO == null ? null : <small style={{opacity: '0.5', position: 'relative', bottom: '2px'}}>(REF# {this.props.currentPO.ponumber})</small>}
        </Modal.Header>
        <Modal.Content>
          {
            this.props.currentPO ?
            <UploadDistro
              purchaseOrder={this.props.currentPO}
              closeModal={this.props.closeUploadDistroModal}
            />
            :
            null
          }
        </Modal.Content>
      </Modal>
    )
  }
}

export default UploadDistroModal
