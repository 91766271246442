import React            from 'react'
import {
          Button, Loader, Popup, Label, Icon
       }                 from 'semantic-ui-react'
import Constants         from './../../modules/constants'
import Utils             from './../../modules/utils'
import CustomerAPI       from './../../modules/api/customer_api'
import {LoadingParagraph}from './../ui/loading-paragraph'
import { GoogleLogin }   from 'react-google-login';

class GoogleIntegration extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading     : true,
      company     : this.props.company,
      accountInfo : null
    }

  }

  componentDidMount()
  {
    this.fetchIntegratedAccount()
  }

  fetchIntegratedAccount = () =>
  {
    this.setState({ loading: true, accountInfo: null })

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error);
      }
      else
      {
        this.setState({ loading: false, accountInfo: data.accountInfo })
      }
    }
    CustomerAPI.getGoogleAccountInfo(callback)
  }

  successGoogle = (response) => {
    this.setState({ loading: true, accountInfo: null })
    var callback = (error, data) =>
    {
      if (error)
      {
        alert('error')
        console.log(error)
      }
      else
      {
        this.fetchIntegratedAccount()
      }
    }
    let data = {...response, baseUrl: window.location.origin}
    CustomerAPI.integrateGoogle(data, callback)
  }

  failureGoogle = (response) => {
    console.log("google integratione failed")
    console.log(response)
  }

  render()
  {
    let {loading, company, accountInfo} = this.state;

    let markup =
      <div className='google-integration-section'>
        {
          loading ?
          <Loader active inline />
          :
          accountInfo &&

          <Popup
              content='Already connected google account for email and calendar events creation.'
              position='top'
              size='small'
              trigger={<Label>
                        <Icon name='google' />
                          {accountInfo.email}
                      </Label>
                    }
              />

          }
          {
            !loading &&
            <GoogleLogin
              clientId="280035615219-ni891t46pq53gsg2tfs0c84hqd9nbocd.apps.googleusercontent.com"
              buttonText="Authorize Gmail and Calendar"
              render={renderProps => (
                <Popup
                content='Authorize google account for email and calendar events creation.'
                position='top'
                size='small'
                trigger={<Button
                          inverted
                          content={accountInfo && accountInfo.email ? 'Change Google Account' : 'Integrate Google Account'}
                          icon='google'
                          size='mini'
                          color='blue'
                          className=''
                          disabled={renderProps.disabled}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            renderProps.onClick()
                            }
                          }
                          />
                      }
                />
              )}
              onSuccess={this.successGoogle}
              onFailure={this.failureGoogle}
              cookiePolicy={'single_host_origin'}
              accessType='offline'
              responseType='code'
              scope="profile email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/gmail.send"
            />
          }
        </div>
    return null //markup;
  }
}

export default GoogleIntegration
