import React, {Component} from 'react'
import {Dimmer, Loader} from 'semantic-ui-react'
import $ from 'jquery'

class NewArtistPage extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>

        <h1 style={{marginBottom: '35px', marginTop: '-10px'}}>New Artist</h1>

      </div>
    )
  }
}

export default NewArtistPage
