
const CONTACT_PERSON_TITLES =
[
    "Assistant Buyer",
    "Buyer",
    "CEO",
    "Director of Merchandising",
    "DM",
    "GMM",
    "IT",
    "MMM",
    "VP",
    "Productions",
    "Merchandising",
    "Design"
]

const CHANNEL_DISTRIBUTIONS =
[
    "Clubs",
    "Department store",
    "Distributor",
    "Discounter",
    "E comm",
    "Jobber",
    "Midtier",
    "Offprice",
    "Small Account",
    "Wholesale"
]

const REGIONS =
[
  'Asia',
  'Australia',
  'Canada',
  'Central America',
  'Europe',
  'Latin America',
  'Middle East',
  'USA'
]

const API =
{
    apiEndPoint: process.env.REACT_APP_API_URL + "/api/",
    s3BucketName: process.env.S3_BUCKET_NAME,
    tempApiKey: process.env.REACT_TEMP_API_KEY
}

const USER_ROLES =
[
    "ADMIN",
    "HR",
    "SALES",
    "SALES ASSISTANT",
    "WAREHOUSE",
    "DESIGN",
    "INVENTORY",
    "FINANCE",
    "CUSTOMER",
    "PRODUCTION",
    "ABC"
]

const PO_STATUSES =
[
    { key: "Show All", text: "Show All", value: "Show All"},
    { key: "Pending", text: "Pending", value: "Pending"},
    { key: "QB-Entered", text: "QB-Entered", value: "QB-Entered"},
    { key: "Needs-Fulfillment", text: "Needs-Fulfillment", value: "Needs-Fulfillment"},
    { key: "Ready-To-Ship", text: "Ready-To-Ship", value: "Ready-To-Ship"},
    { key: "In-Progress", text: "In-Progress", value: "In-Progress"},
    { key: "Routed", text: "Routed", value: "Routed"},
    { key: "Pick-Up-Scheduled", text: "Pick-Up-Scheduled", value: "Pick-Up-Scheduled"},
    { key: "Partially-Shipped", text: "Partially-Shipped", value: "Partially-Shipped"},
    { key: "Shipped", text: "Shipped", value: "Shipped"},
    { key: "Invoiced", text: "Invoiced", value: "Invoiced"},
    { key: "Delayed", text: "Delayed", value: "Delayed"},
    { key: "Cancelled", text: "Cancelled", value: "Cancelled"},
    { key: 'Filter by Special Status', text: 'Filter by Special Status', value: 'Filter by Special Status', icon: 'star', className: 'header' },
    { key: "Auto-Edited", text: "Auto-Edited", value: "Auto-Edited"},
    { key: "QB-Synced", text: "QB-Synced", value: "QB-Synced"},
    //finish adding later
    // { key: "Flagged-For-Update", text: "Flagged-For-Update", value: "Flagged-For-Update"},
    { key: 'Filter by Missing File', text: 'Filter by Missing File', value: 'Filter by Missing File', icon: 'ban', className: 'header' },
    { key: 'No packing slip', text: 'No packing slip', value: 'No packing slip' },
    { key: 'No distro', text: 'No distro', value: 'No distro' },
    { key: 'No BOL', text: 'No BOL', value: 'No BOL' },
    { key: 'Filter by Missing Field', text: 'Filter by Missing Field', value: 'Filter by Missing Field', icon: 'ban', className: 'header' },
    { key: 'No Item Status', text: 'No Item Status', value: 'No Item Status' },
]

const PO_STATUSES_WAREHOUSE =
[
    "Needs-Fulfillment",
    "Ready-To-Ship",
    "In-Progress",
    "Routed",
    "Pick-Up-Scheduled",
    "Shipped"
]

const ORDER_TYPES =
[
    "Hold",
    "Worksheet",
    "PO",
    "Shipped"
]

const PO_DATE_TYPES =
[
    "Created",
    "Ordered",
    "Shipped",
    "Canceled",
]

const PO_DATE_RANGES =
[
    "YTD",
    "MTD"
]

const PREVILIGES =
{
    CADS:
    [
        "superadmin",
        "admin",
        "sales",
        "sales_assistant",
        "inventory",
        "design",
        "finance",
        "warehouse",
        "production",
        "customer"
    ],
    SALESORDER:
    [
        "superadmin",
        "admin",
        "sales",
        "sales_assistant",
        "inventory",
        "finance",
        "warehouse",
        "production"
    ],
    BOLPOD:
    [
        "superadmin",
        "warehouse",
        "inventory"
    ],
    CONTACT:
    [
        "superadmin",
        "admin",
        "sales",
        "sales_assistant",
        "production",
        "inventory"
    ],
    ACCOUNTREP:
    [
        "superadmin",
        "admin"
    ],
    ACCOUNT:
    [
        "superadmin",
        "admin"
    ],
    ORGANIZATION:
    [
        "superadmin",
        "admin",
        "hr"
    ],
    PRODUCTION:
    [
      "superadmin",
      "admin",
      "inventory",
      "production"
    ],
    ARTISTS:
    [
      "abc"
    ]
}

const ROLE_PERMISSIONS =
{
    all:
    [
        "admin",
        "superadmin",
        "sales",
        "sales_assistant",
        "warehouse",
        "design",
        "inventory",
        "finance",
        "production"
    ],
    hr:
    [
        "superadmin",
        "admin",
        "hr"
    ],
    admin:
    [
        "admin",
        "superadmin"
    ],
    sales:
    [
        "admin",
        "superadmin",
        "sales",
        "sales_assistant",
        "inventory",
        "finance",
        "production"
    ],
    warehouse:
    [
        "warehouse",
        // "superadmin",
        // "inventory",
        // "production"
    ],
    artist:
    [
      'abc'
    ]
}


const STATUS_FILTER_LIST =
[
    'Active + Pending',
    'Active',
    'Pending',
    'In Review',
    'Inactive'
]

const NON_INVENTORY_ITEMS = [
  "COMMISION",
  "COMPLIANCE",
  "COVID-19 DISCOUNT",
  "CR HANGERS",
  "CREDIT TERM",
  "CREDIT TERMS",
  "DAMAGE",
  "DELIVERY",
  "FN CB EXTENTION",
  "SHORTAGE",
  "TICKETS",
  "YE ALLOWANCE"
]

const ADD_DAYS_TO_LOADING_DATE = 30
const ADD_DAYS_TO_POE_LOADING_DATE = 20
const ADD_DAYS_TO_EX_FACTORY_DATE = 60
const ADD_DAYS_EX_FACTORY_TO_LOADING = 15
const ADD_DAYS_EX_FACTORY_TO_RTS = 20


const ADD_DAYS_TO_UPDATED_ETA = 0 //NOT IN USE
const ADD_DAYS_TO_UPDATED_DELIV_DATE = 3 //NOT IN USE

const BAD_CONTAINER_NUMBERS = [] //NOT IN USE

const BAD_STYLE_NUMBERS = [ //DO NOT SELL
  'WT1075-001-FSA',         //DO NOT SELL
  '5001-001-FWZSE',          //DO NOT SELL
  'T10051-402',
  'T10051-601',
  'T10051-660',
  'T10055-050',
  'WA1000-021-TSA',
  'WA0881-020-TSP'
]

const ORDER_TERMS = [
  null,
  "No",
  "1% 10 Net 30",
  "1% 75",
  "1% 90 NET 120 ROG",
  "1% Net 30",
  "1% ROG",
  "1%10EOM+30",
  "10 EOM",
  "10 EOM + 4%",
  "2% 50 Days Net",
  "2% 10 Net 30",
  "2% 30 NET 60",
  "2% NET 45",
  "2% NET 60",
  "3%  15 ROG",
  "3% 10 eom+60",
  "3% 45 Days ROG",
  "3% 60 Days ROG",
  "3% NET 60",
  "3% ROG",
  "30 days from ETD",
  "30% DEPOSIT",
  "36 month",
  "4% Net 60",
  "4% Net 90",
  "4% Prepay",
  "5% COD",
  "5% Net 30",
  "50% DEPOSIT",
  "50% net 30 ROG",
  "50% net 60 ROG",
  "50% net 90 ROG",
  "ACH",
  "AMEX",
  "C.B.D.",
  "C.O.D.",
  "CC",
  "CHECK",
  "COD net 1",
  "Due on receipt",
  "EOM 10 + 105",
  "EOM 10 + 45",
  "EOM 10 + 60",
  "EOM 10 + 95 Days",
  "eom 24+10 3%",
  "eom10+105 Days",
  "eom10+60 Days",
  "eom10+75 Days",
  "net-10 ROG",
  "Net-15",
  "net-3 ROG+3% disc",
  "Net-30",
  "Net-60",
  "NET-90",
  "NET - 20",
  "NET 10",
  "Net 10 + 2.5% discount",
  "NET 10 EOM+30",
  "NET 10 EOM+35",
  "NET 10 EOM=60",
  "NET 10EOM",
  "Net 15",
  "Net 15 + 2% discount",
  "Net 20",
  "NET 20 1% disc",
  "Net 30",
  "NET 45",
  "Net 60",
  "NET 65",
  "Net 7",
  "Net 7 + 5% discount",
  "NET 75",
  "Net 90",
  "net10 eom+30 days",
  "NET10EOM +60",
  "net10eom+30",
  "ROG",
  "ROG 60",
  "ROG 90",
  "ROG 90 Days BL",
  "WIRE",
  "90 DAYS"
]

module.exports =
{
    CONTACT_PERSON_TITLES,
    CHANNEL_DISTRIBUTIONS,
    USER_ROLES,
    ROLE_PERMISSIONS,
    PREVILIGES,
    PO_STATUSES,
    PO_STATUSES_WAREHOUSE,
    ORDER_TYPES,
    PO_DATE_RANGES,
    PO_DATE_TYPES,
    REGIONS,
    API,
    STATUS_FILTER_LIST,
    NON_INVENTORY_ITEMS,
    ADD_DAYS_TO_LOADING_DATE,
    ADD_DAYS_TO_POE_LOADING_DATE,
    ADD_DAYS_TO_EX_FACTORY_DATE,
    ADD_DAYS_EX_FACTORY_TO_LOADING,
    ADD_DAYS_EX_FACTORY_TO_RTS,
    ADD_DAYS_TO_UPDATED_ETA,
    ADD_DAYS_TO_UPDATED_DELIV_DATE,
    BAD_CONTAINER_NUMBERS,
    BAD_STYLE_NUMBERS,
    ORDER_TERMS
}
