import React, { Component } from 'react'
import Cropper from 'cropperjs'
import {Modal, Button} from 'semantic-ui-react'
import $ from 'jquery'

let cropper

class ImageCropper extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.saveCroppedImage = this.saveCroppedImage.bind(this)
  }

  componentDidMount() {
    setTimeout(() => {
      let image = document.getElementById('modal-picture')
      cropper = new Cropper(image, {
        aspectRatio: 16 / 9,
        crop: function (e) {

        }
      })
    }, 1000)
  }

  saveCroppedImage() {
    let {closeModal} = this.props

    cropper.getCroppedCanvas({ width: 600, height: 338 }).toBlob((blob) => {
      let formData = new FormData()
      formData.append('cropped', blob)
      formData.append('filename', this.props.filename)

      $.ajax({
        type: "POST",
        url: process.env.REACT_APP_API_URL + '/api/upload_cropped_image',
        success: (data) => {
          if (data.success) {
            closeModal()
          } else {
            alert('There was an error when trying to upload the file.')
          }
        },
        error: (error) => {
          alert('There was an error when trying to upload the file.')
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000
      })
    })
  }

  render() {
    let {open, closeModal, filename, showCancel} = this.props

    return (
      <Modal
        open={open}
        size='tiny'
      >
        <Modal.Header>Crop Image</Modal.Header>
        <Modal.Content style={{padding: 0}}>
          <img id="modal-picture" src={filename} style={{width: '100%', height: 'auto'}}/>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={closeModal} style={showCancel ? {} : {pointerEvents: 'none', opacity: 0.1}}>
            Cancel
          </Button>
          <Button
            content="Save cropped image"
            labelPosition='right'
            icon='checkmark'
            onClick={this.saveCroppedImage}
            positive
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ImageCropper
