import {propertiesFavicon} from './favicon'
import {propertiesTitle} from './title'
import {orgName} from './options'
import $ from 'jquery'

const start = (orgId, address = null) => {
  window.orgId = orgId
  propertiesFavicon()

  let guest = window.location.href.includes('/share-p')
  let token = window.location.href.split('token=')[1]

  let addressText = address != null ? address+' | ' : ''
  propertiesTitle(guest ? addressText+orgName(orgId)+' Shared Link' : addressText+orgName(orgId))

  $('#SharedLinkTitle').text(orgName(orgId)+' Shared Link'+(token == null ? ' (PREVIEW)' : ''))

  window.scrollTo(0, 0)
  window.onbeforeunload = function () {
    window.scrollTo(0, 0)
  }
}

export {start}
