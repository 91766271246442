import React from 'react'

const adminCost = (cad) => {
  let {ave_cost, future_ats_ave_cost} = cad

  //IF HAVE FUTURE USE THAT NOW.... AND HIDE "PAST" (INCORRECT SOMETIMES)
  let haveFutureCost = future_ats_ave_cost != null && Number(future_ats_ave_cost) != 0
  let havePastCost = ave_cost != null && Number(ave_cost) != 0


  return (
    <React.Fragment>
      <div style={{width: '60%', lineHeight: '1rem', paddingTop: '5px', marginBottom: '-8px', marginLeft: '-8px'}}>
        {cad.long_style_number}
      </div>
      <div style={{position: 'absolute', right: 0, bottom: 0}}>
        {cad.id}
        {cad.margin == null ? '-' : cad.margin[0]}
        {
          haveFutureCost
          ?
          '___'
          :
          havePastCost
          ?
          Number(ave_cost).toFixed(2).replace('.','')
          :
          ''
        }_
        {haveFutureCost ? Number(future_ats_ave_cost).toFixed(2).replace('.','') : '___'}
      </div>
    </React.Fragment>
  )
}

export {adminCost}
