import {roundToPenny} from './round-to-penny.js'

const reducer = (a, b) => a + b

const totalDollar = (x) => {
  let itemTotal = Number(x.qty) * Number(x.price)

  if (x.discount != '' && x.discount != null) {
    itemTotal *= (1.0 - Number(x.discount)/100.0)
  }

  return roundToPenny(itemTotal)
}

export {totalDollar, reducer}
