import React, {Component} from 'react'
import SectionTitle from './section-title.js'
import ContinueButton from './continue-button.js'
import {saveVendorOrder} from './save-vendor-order.js'
import {Dimmer, Loader} from 'semantic-ui-react'
import {getIsDisabled, isDisabledMarkup, getIsLocked, isLockedMarkup, getIsUnlocked, isUnlockedMarkup} from './get-is-locked'

let messageStyle = {color: 'red', background: 'yellow', padding: '5px 10px'}

class CompleteVendorOrder extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      submittingForm: false
    }
    this.saveVendorOrder = this.saveVendorOrder.bind(this)
  }

  saveVendorOrder(action = null) {
    this.setState({submittingForm: true})
    saveVendorOrder(this.props.history, this.props.id, action)
  }

  render() {
    let {isActive, editSection, isEditPage, id, titleNum} = this.props
    let {submittingForm} = this.state

    let {status} = window.editVpoPage == null || window.editVpoPage.orderSummary == null ? {} : window.editVpoPage.orderSummary

    return (
      <div>
        {
          submittingForm
          ?
          <Dimmer active>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          :
          null
        }
        <SectionTitle num={titleNum} title={isEditPage ? 'UPDATE VENDOR ORDER' : 'COMPLETE VENDOR ORDER'} complete={false}
          showLink={isEditPage && !isActive}
          editSection={editSection}
        />
        {
          isActive
          ?
          <ContinueButton markAsComplete={this.saveVendorOrder} complete={true} text={isEditPage ? 'SAVE' : 'CONFIRM'}
            submittingForm={submittingForm}
          />
          :
          null
        }
      </div>
    )
  }
}

export default CompleteVendorOrder
