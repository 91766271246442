import React, { Component } from 'react'
import SectionTitle from '../new-vpo-page/section-title'
import {editLink} from './edit-link'

class CashFlow extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let {titleNum, isActive, editSection} = this.props
    let complete = false

    return (
      <div>
        <SectionTitle num={titleNum} title='CASH FLOW' complete={complete}/>


        <p style={isActive ? {display: 'none'} : {fontSize: '13px', paddingBottom: '1px'}}>
          {editLink(editSection, complete, isActive)}
        </p>

      </div>
    )
  }
}

export default CashFlow
