import React              from 'react'
import {
  Segment, Grid, Button, Popup, Table, Header, Step, Modal, Input, Loader,
  TextArea, Checkbox, Tab, Menu, Label, Message, Icon, Dimmer, Dropdown, Image
} from 'semantic-ui-react'
import $                   from 'jquery'
import _                   from 'lodash'
import {Link}              from 'react-router-dom'
import OfficeEditForm      from './office-edit-po-form'
import ProgressSteps       from './po-progresses/progress-steps'
import FulfillmentForm     from './po-progresses/fulfillment-form'
import POFiltration        from './po-filtration'
import {LoadingParagraph}  from './../ui/loading-paragraph'
import PurchaseOrderAPI    from './../../modules/api/purchase_order_api'
import {Auth0Context}      from './../../contexts/auth-context'
import SalesView           from './sales-view'
import Utils from "./../../modules/utils"
import XLSX from 'xlsx';
import BulkEditModal from './bulk-edit-modal.js'
import ShippedCancelModal from './shipped-cancel-modal.js'
import PickUpScheduledModal from './pick-up-scheduled-modal.js'
import './index.css'
import Constants from './../../modules/constants'
import ReactQuill from 'react-quill'
import {roundToPenny} from './../../components/cads/sum.js'
import isUserRole from './is-user-role.js'
import {sortMapIdNameOptions, sortMapNameOptions} from './sort-map-id-name-options.js'
import {getFilterPOWarehouse, getStatuses, getPanes} from './po-filter.js'
import VisibleColumnsButton from './visible-columns-button.js'
import {updateFlagged} from './update-orders.js'
import AutoCancelHoldLogs from './auto-cancel-hold-logs.js'
import QbSyncHoldLogs from './qb-sync-hold-logs.js'
import {getExcel} from './excel/get-excel.js'
import {checkPonumberMatch} from './check-ponumber-match.js'
import {isMatch} from './filter/is-match.js'
import {impersonateUsersDropdown, isDefaultImpersonateRep} from './impersonate-users-dropdown'
import {impersonateContactsDropdown} from './impersonate-contacts-dropdown'
import {getAccounts} from '../edit-sales-order/get-accounts'
import {getCadsCache} from '../cads/cache/get'
import {checkCadsForCustomAccess} from '../custom-access/check-cads.js'
import {canImpersonateUsers} from '../custom-access/can.js'
import {infoCircle} from '../cads/status-info-circle'
import {getSalesOrdersCache} from './cache/get-sales-orders'
import '../cads/scroll-to-top.css'

let ticketsOptions = [
  // 'Show All',
  'NO',
  'Ordered',
  'Not Ordered',
].map((x) => {return {key: x, text: x, value: x} })

let orderTypeOptions = [
  'Hold',
  'PO',
  'Worksheet'
].map((x) => {return {key: x, text: x, value: x} })

class PurchaseOrders extends React.Component
{
  static contextType = Auth0Context;

  constructor(props)
  {
    super(props)
    this.state =
    {
      showImage: false,
      showVponumber: false,
      showExFactory: false,
      showShippingClass: false,
      showDetails: false,
      showMissingCad: false,
      showFlagged: false,
      sLoading       : false,
      loading        : true,
      actualPOs      : [],
      purchaseOrders : [],
      total          : 0,
      sliceNum       : 100,
      searchValue    : '',
      officeEditModal: false,
      uploadDistroModal: false,
      currentPO      : null,
      sortColumn     : "startShippingDate",
      progressModal  :
      {
        fulfillment: false,
        routed     : false,
        shipped    : false
      },
      bulkModalOpen: false,
      activeIndex: 0,
      totals: [0,0,0,0],
      accountTotals: [0,0,0,0],
      addBolModalOpen: false,

      addRoutingNumberModalOpen: false,
      addPackingSlipModalOpen: false,
      generatePackingSlipModalOpen: false,
      missingPONumbers: [],
      multipleMatches: false,
      numCadsSelected: 0,
      addBolId: null,

      addPackingSlipId: null,
      bolLoading: false,
      routingNumberLoading: false,
      accountOptions: [],
      account: [],
      tickets: [],
      orderType: [],
      repOptions: [],
      rep: [],
      brandOptions: [],
      brand: [],
      cadsLoading: true,

      shippedCancelActiveIndex: null,
      markShippedCancelModalOpen: false,
      markShippedCancelId: null,

      markPickUpScheduledModalOpen: false,
      markPickUpScheduledId: null,

      impersonateRep: [],
      contactOptions: [],
      impersonateContact: []
    }

    this.toggleShowImageCheckbox = this.toggleShowImageCheckbox.bind(this);
    this.toggleShowDetailsCheckbox = this.toggleShowDetailsCheckbox.bind(this);
    this.copyCadToSalesOrders = this.copyCadToSalesOrders.bind(this)
    this.toggleShowMissingCadCheckbox = this.toggleShowMissingCadCheckbox.bind(this)
    this.toggleShowFlaggedCheckbox = this.toggleShowFlaggedCheckbox.bind(this)
    this.downloadExcel = this.downloadExcel.bind(this)
    this.downloadExcelWarehouse = this.downloadExcelWarehouse.bind(this)
    this.bulkAddBol = this.bulkAddBol.bind(this)
    this.bulkAddRoutingNumber = this.bulkAddRoutingNumber.bind(this)
    this.bulkAddPackingSlip = this.bulkAddPackingSlip.bind(this)
    this.bulkGeneratePackingSlip = this.bulkGeneratePackingSlip.bind(this)
    this.selectAll = this.selectAll.bind(this)
    this.bulkEdit = this.bulkEdit.bind(this)
    this.bulkModalClose = this.bulkModalClose.bind(this)
    this.filterPOWarehouse = this.filterPOWarehouse.bind(this)
    this.calculateTotals = this.calculateTotals.bind(this)
    this.addBol = this.addBol.bind(this)
    this.addBolModalClose = this.addBolModalClose.bind(this)
    this.addRoutingNumberModalClose = this.addRoutingNumberModalClose.bind(this)
    this.addPackingSlipModalClose = this.addPackingSlipModalClose.bind(this)
    this.generatePackingSlipModalClose = this.generatePackingSlipModalClose.bind(this)
    this.generatePackingSlipModalOpen = this.generatePackingSlipModalOpen.bind(this)
    this.generatePackingSlip = this.generatePackingSlip.bind(this)
    this.generatePackingSlipSubmit = this.generatePackingSlipSubmit.bind(this)
    this.updateNumSelected = this.updateNumSelected.bind(this)
    this.refresh = this.refresh.bind(this)
    this.startComponent = this.startComponent.bind(this)
    this.downloadDistro = this.downloadDistro.bind(this)
    this.saveBol = this.saveBol.bind(this)
    this.createBol = this.createBol.bind(this)
    this.addPackingSlip = this.addPackingSlip.bind(this)
    this.saveRoutingNumber = this.saveRoutingNumber.bind(this)
    this.savePackingSlip = this.savePackingSlip.bind(this)
    this.richTextMessageHtmlOnchange = this.richTextMessageHtmlOnchange.bind(this)
    this.richTextMessageHtmlOnchangeBulk = this.richTextMessageHtmlOnchangeBulk.bind(this)
    this.myUpdate = this.myUpdate.bind(this)
    this.changeStatusGreyRow = this.changeStatusGreyRow.bind(this)
    this.changeStatusPackingSlip = this.changeStatusPackingSlip.bind(this)
    this.accountOnChange = this.accountOnChange.bind(this)
    this.repOnChange = this.repOnChange.bind(this)
    this.brandOnChange = this.brandOnChange.bind(this)
    this.ticketsOnChange = this.ticketsOnChange.bind(this)
    this.orderTypeOnChange = this.orderTypeOnChange.bind(this)
    this.afterFilterChange = this.afterFilterChange.bind(this)
    this.resetWarehouseFilters = this.resetWarehouseFilters.bind(this)
    this.calculateAccountTotals = this.calculateAccountTotals.bind(this)
    this.downloadPackingSlip = this.downloadPackingSlip.bind(this)
    this.updateShipTo = this.updateShipTo.bind(this)
    this.updatePrinted = this.updatePrinted.bind(this)
    this.setOptions = this.setOptions.bind(this)
    this.markQbEntered = this.markQbEntered.bind(this)
    this.markInvoiced = this.markInvoiced.bind(this)

    this.markStatus = this.markStatus.bind(this)
    this.updateOrders = this.updateOrders.bind(this)

    this.textareaOnKeyUp = this.textareaOnKeyUp.bind(this)

    this.markShippedCancelModalClose = this.markShippedCancelModalClose.bind(this)
    this.markShippedCancel = this.markShippedCancel.bind(this)

    this.markPickUpScheduled = this.markPickUpScheduled.bind(this)
    this.markPickUpScheduledModalClose = this.markPickUpScheduledModalClose.bind(this)
    this.updatePickUpScheduledFields = this.updatePickUpScheduledFields.bind(this)

    this.toggleVponumberCheckbox = this.toggleVponumberCheckbox.bind(this)
    this.toggleExFactoryCheckbox = this.toggleExFactoryCheckbox.bind(this)
    this.toggleShippingClassCheckbox = this.toggleShippingClassCheckbox.bind(this)

    this.impersonateRepOnChange = this.impersonateRepOnChange.bind(this)
    this.impersonateContactOnChange = this.impersonateContactOnChange.bind(this)

    this.cadsSuccessCallback = this.cadsSuccessCallback.bind(this)
  }

  textareaOnKeyUp(event) {
    let {value} = event.target
    clearTimeout(window.multiSearchKeyUp)
    window.multiSearchKeyUp = setTimeout(() => {
      this.handleSearchChange(value)
    }, 300)
  }

  cadsSuccessCallback(cads) {
    window.cads = cads

    this.setState({cadsLoading: false})

    window.numberOfApiSuccess += 1

    if (window.numberOfApiSuccess == 2) {
      this.copyCadToSalesOrders()
    }
  }

  startComponent() {
    window.scrollTo(0, 0)
    window.richTextMessageHtml = ''
    window.richTextMessageHtmlBulk = {}
    window.poIds = {}
    window.numberOfApiSuccess = 0

    this.fetchPurchaseOrders()

    window.addEventListener('turnOffMissingCadEvent', () => {
      this.setState({showMissingCad: false})
    })

    $(window).scroll(function() {
      if ($(window).scrollTop() + $(window).height() < 1000 && $('#scroll-to-top').is(":visible") ) {
        $('#scroll-to-top-corner-buttons').hide();
      }
      if ($(window).scrollTop() + $(window).height() >= 1000 && !$('#scroll-to-top').is(":visible") ) {
        $('#scroll-to-top-corner-buttons').fadeIn();
      }
    })
  }

  componentDidMount() {
    this.startComponent()

    let {user} = this.context
    let {isInventory, isAdmin, isSales, isSalesAssistant} = isUserRole(user)
    if (isInventory || isAdmin || isSales || isSalesAssistant) {
      this.setOptions()
    }
  }

  setOptions() {
    let {customer, user} = this.context
    if (customer == null) {
      setTimeout(this.setOptions, 500)
    } else {
      if (user.role != 'warehouse') {
        getCadsCache(customer.id, this.cadsSuccessCallback)
      }
      let repOptions = sortMapIdNameOptions(customer.distinctReps)
      let brandOptions = sortMapNameOptions(customer.brands)
      let impersonateRep = [user.id]
      let {contactOptions} = getAccounts(customer)
      this.setState({repOptions, brandOptions, impersonateRep, contactOptions})
    }
  }

  convertToCamel = (obj) => _.mapKeys(obj, (v, k) => _.camelCase(k))
  convertToCamelArr = (arr) => arr.map(i => this.convertToCamel(i))

  fetchPurchaseOrdersCallback = (data) => {
    let {customer, user} = this.context
    let {isWarehouse, isInventory, isAdmin} = isUserRole(user)
    let result = []

    for (var i=0; i<data.length; i++) {
      result.push(data[i].attributes)
    }

    const needCamelCase = result.length > 0 && result[0].cadIds == null
    if (needCamelCase) {
      result = this.convertToCamelArr(result)
    }

    if (isInventory) {
      result = result.filter(i => !(i.status == 'Pending' && i.orderType == 'Hold'))
    }

    var total = 0
    for (var i=0; i<result.length; i++)
    {
      if (result[i].cancelledDate == null &&
          result[i].grossAmount != null &&
          result[i].grossAmount != ''
      )
      {
        total += Number(result[i].grossAmount)
        if (result[i].discountDollar != null && result[i].discountDollar != '')
        {
          total -= Number(result[i].discountDollar)
        }
      }
    }

    let accountIds = result.map((i) => {return i.accountId})
    accountIds = [...new Set(accountIds)]

    window.poLength = result.length
    this.setState({
      actualPOs: result,
      purchaseOrders: result,
      total: total,
      loading: false,
      accountIds
    })

    window.numberOfApiSuccess += 1;
    if (window.numberOfApiSuccess > 1) {
      this.copyCadToSalesOrders();
    }

    if (isWarehouse || isInventory || isAdmin) {
      this.calculateTotals()
      this.filterPOWarehouse()

      //get account options
      let accountOptions = result.map(x => x.warehouseAccountName)
      accountOptions = [...new Set(accountOptions)].sort().map(x => {return ({ key: x, text: x, value: x })})

      this.setState({accountOptions})
    } else {
      this.filterPurchaseOrder()
    }
  }

  fetchPurchaseOrders = (impersonateRep) => {
    let {customer, user} = this.context

    this.setState({loading: true})

    if (customer == null) {
      setTimeout(() => this.fetchPurchaseOrders(impersonateRep), 500)
    } else {
      let orgId = customer.id

      if (['admin', 'inventory', 'production'].includes(user.role) && orgId == 1) {
        getSalesOrdersCache(customer.id, this.fetchPurchaseOrdersCallback)
      } else {
        PurchaseOrderAPI.getAll((error, data) => this.fetchPurchaseOrdersCallback(data == null ? [] : data.data), impersonateRep)
      }
    }
  }

  goToNewPOPage = () =>
  {
    this.props.history.push('/sales-orders/new')
  }

  viewPO = (e, po) =>
  {
    console.log(po.id)
  }

  showMorePO = () =>
  {
    var {sliceNum} = this.state
    if (sliceNum < window.poLength)
    {
      sliceNum += 100
      this.setState({ sliceNum })
    }
    setTimeout(this.updateSelected, 100)
  }

  updateSelected() {
    //reset checkboxes
    let checkboxes = document.getElementsByClassName("po-checkbox")
    for (var i = 0; i < checkboxes.length; i++) {
       checkboxes.item(i).checked = false
    }

    //check boxes
    let poIds = Object.keys(window.poIds)
    for (var i=0; i<poIds.length; i++) {
      let poId = poIds[i]
      var elementId = 'po-checkbox-'+poId
      if (document.getElementById(elementId) != null) {
        document.getElementById(elementId).checked = true
      }
    }
  }

  handleSearchChange = (value) =>
  {
    let {user} = this.context
    let {isWarehouse, isInventory, isAdmin} = isUserRole(user)

    if (this.state.searchValue.trim() != value.trim()) {
      window.poIds = {}
      this.updateNumSelected()
      this.updateSelected()
    }

    this.setState({ sLoading: true, searchValue: value })
    setTimeout(() =>
    {
      let {searchValue, actualPOs} = this.state

      if (isWarehouse) {
        this.setState({account: []})
      }

      //RESET FILTERS IF SEARCHING
      if (isInventory || isAdmin) {
        this.setState({account: [], tickets: [], orderType: [], brand: [], rep: [], showFlagged: false})
      }

      if (searchValue.length < 3)
      {
        if (isWarehouse || isInventory || isAdmin) {
          this.setState({sLoading: false, missingPONumbers: [], multipleMatches: false})
          setTimeout(this.filterPOWarehouse, 10)

        } else {
          this.setState({sLoading: false, purchaseOrders: [], missingPONumbers: [], multipleMatches: false})
          this.filterPurchaseOrder()
        }
      } else {
        let nums = value.split(/\r?\n/).map(num => num.flatUpcase()).filter(num => num != '')

        let purchaseOrders = actualPOs.filter((x) => {
          let ponumberMatch = checkPonumberMatch(x.ponumber, x.customerOrderNumber, nums)

          let vendorPonumberMatch = (
            x.vendorPonumber != null
            &&
            nums.map(num => num.noSpaces()).filter(num => x.vendorPonumber.includes(num)).length > 0
          )

          let stylesMatch = false
          if (x.cadStyleNumbers != null) {
            for (let i=0; i<x.cadStyleNumbers.length; i++) {
              let styleNumber = x.cadStyleNumbers[i]
              if (nums.filter(num => styleNumber.startsWith(num)).length > 0) {
                stylesMatch = true
                break
              }
            }
          }
          return ponumberMatch || stylesMatch || vendorPonumberMatch
        })

        let multipleMatches = false
        let missingPONumbers = nums
        missingPONumbers = missingPONumbers.filter((num) => {
          let foundOrders = purchaseOrders.filter((x) => {
            return checkPonumberMatch(x.ponumber, x.customerOrderNumber, [num])
          })

          if (foundOrders.length > 1) {
            multipleMatches = true
          }


          let foundOrdersByVponumber = purchaseOrders.filter((x) => {
            return (
              x.vendorPonumber != null
              &&
              x.vendorPonumber.includes(num.noSpaces())
            )
          })

          if (foundOrdersByVponumber.length > 1) {
            multipleMatches = true
          }


          let foundOrdersByStyle = purchaseOrders.filter((x) => {
            let stylesMatch = false
            if (x.cadStyleNumbers != null) {
              for (let i=0; i<x.cadStyleNumbers.length; i++) {
                let styleNumber = x.cadStyleNumbers[i]
                if (styleNumber.startsWith(num)) {
                  stylesMatch = true
                  break
                }
              }
            }
            return stylesMatch
          })

          if (foundOrdersByStyle.length > 1) {
            multipleMatches = true
          }

          return foundOrders.length == 0 && foundOrdersByStyle.length == 0 && foundOrdersByVponumber.length == 0
        })


        this.setState({
          sLoading: false,
          purchaseOrders,
          missingPONumbers,
          multipleMatches
        })
      }
    }, 300)
  }

  gotoDownloadLink = (e, po, file) =>
  {
    var callback = (error, data) =>
    {
      if(error)
      {
        console.log(error)
      }
      else
      {
        if (data.filePath.indexOf('.xlsx') > -1) {
          window.location = data.filePath
        } else {
          window.open(data.filePath, '_blank', "height=700,width=700")
        }
      }
    }

    let fileType = (file.fileType == null ? 'bol' : file.fileType)

    PurchaseOrderAPI.getFileLink(po.id, file.id, fileType, callback)
  }

  openOfficeEditModal = (e, po) =>
  {
    this.setState({officeEditModal: true, currentPO: po})
  }

  openUploadDistroModal = (e, po) =>
  {
    this.setState({uploadDistroModal: true, currentPO: po})
  }

  closeOfficeEditModal = (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    this.setState({officeEditModal: false, currentPO: null})
  }

  closeUploadDistroModal = (e) =>
  {
    if (e != null) {
      e.preventDefault()
      e.stopPropagation()
    }

    this.setState({uploadDistroModal: false, currentPO: null})
  }

  addPackingSlip(po) {
    window.richTextMessageHtml = ''
    window.richTextMessageHtmlBulk = {}
    window.richTextMessageHtmlBulk[po.id] = ''
    this.setState({addPackingSlipModalOpen: true, addPackingSlipId: po.id})
  }

  setFulfillmentModal = (e, {po, value}) =>
  {
    var {progressModal, currentPO} = this.state
    progressModal.fulfillment = value
    currentPO = po ? po : null
    this.setState({progressModal, currentPO})
  }

  openProgressAlert = (e, po, {progress, name}) =>
  {
    if (progress.status == 'completed') return

    var {progressModal} = this.state
    progressModal[name] = true
    this.setState({progressModal})

    var message = name === "routed" ? window.prompt("Please enter routing number", '') : window.confirm("Are you sure you want to change status to Shipped? You might want to instead use Add BOL / POD shortcut and attach a file!")

    if (message)
    {
      progress.notes  = name === "routed" ? message : 'shipped'
      progress.status = 'completed'

      if (po && po.id && progress && progress.id)
      {
        var updated =
        {
          purchaseOrderProgressesAttributes: [{...progress}]
        }

        PurchaseOrderAPI.update(po.id, {purchaseOrder: updated}, (error, data) =>
        {
          if (error)
          {
            console.error(error)
          }
          this.closeProgressAlert(name)
        })
      }
    }
    else
    {
      this.closeProgressAlert(name)
    }
  }

  closeProgressAlert = (name) =>
  {
    var {progressModal} = this.state
    progressModal[name] = false
    this.setState({progressModal})
  }

  filterPurchaseOrder = (filters = null, clear = false) => {
    let {actualPOs, purchaseOrders, sortColumn} = this.state

    if (filters == null) {
      filters = this.context.purchaseOrderFilters
    }

    if (clear) {
      purchaseOrders = actualPOs
    } else {
      purchaseOrders = actualPOs.filter(order => isMatch(order, filters, this.state))
    }

    sortColumn = (filters == null ? 'startShippingDate' : (filters.newFirst ? 'createdAt' : 'startShippingDate'))

    //clear selected
    window.poIds = {}

    this.setState({purchaseOrders, sortColumn})

    //go to page 1
    let page1event = new Event('page1event')
    window.dispatchEvent(page1event)
  }

  toggleShowImageCheckbox() {
    this.setState({showImage: !this.state.showImage});
  }

  toggleVponumberCheckbox() {
    this.setState({showVponumber: !this.state.showVponumber})
  }

  toggleExFactoryCheckbox() {
    this.setState({showExFactory: !this.state.showExFactory})
  }

  toggleShippingClassCheckbox() {
    this.setState({showShippingClass: !this.state.showShippingClass})
  }

  toggleShowDetailsCheckbox() {
    this.setState({showDetails: !this.state.showDetails});
  }

  toggleShowMissingCadCheckbox() {
    this.setState({showMissingCad: !this.state.showMissingCad});
    setTimeout(this.filterPurchaseOrder, 10)
  }

  toggleShowFlaggedCheckbox() {
    let {user} = this.context
    let {isInventory, isAdmin} = isUserRole(user)

    if (isInventory || isAdmin) {
      this.setState({showFlagged: !this.state.showFlagged, searchValue: ''});
      this.afterFilterChange()
    } else {
      this.setState({showFlagged: !this.state.showFlagged});
      setTimeout(this.filterPurchaseOrder, 10)
    }
  }

  copyCadToSalesOrders() {
    ///// generate cad hash //////
    let cadHash = {};
    for (let k=0; k<window.cads.length; k++) {
      let cad = window.cads[k];
      let cad_style_number = cad.style_number.flatUpcase();
      cadHash[cad_style_number] = cad;
    }

    let {actualPOs} = this.state
    let newActualPOs = []

    for (var i=0; i<actualPOs.length; i++) {
      let po = actualPOs[i];

      let styles = po.cadStyleNumbers;
      let image_urls = [];
      let cads = [];

      if (styles != null) {
        for (var j=0; j<styles.length; j++) {
          let style_number = styles[j];

          let image_url = null;

          let cad = cadHash[style_number];

          if (cad == null) {
            cads.push(null);
            image_url = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new_padding.png'
          } else {
            let s3BucketName = cad.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME
            cads.push(cad);
            if (cad.filename == null) {
              image_url = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new_padding.png'
            } else if (cad.have_thumb) {
              image_url = 'https://s3.amazonaws.com/' + s3BucketName + '/cad_thumb/' + cad.filename.split('/')[0] + '/thumb.jpg'
            } else {
              image_url = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + cad.filename
            }
          }

          image_urls.push(image_url);
        }
      }

      po.cads = cads;
      po.image_urls = image_urls;
      po.greyedOut = checkCadsForCustomAccess(cads)

      newActualPOs.push(po);
    }

    if (this.state.actualPOs.length == this.state.purchaseOrders.length) {
      this.setState({
        actualPOs: newActualPOs,
        purchaseOrders: newActualPOs,
      })
    } else {
      let currentIds = this.state.purchaseOrders.map(x => x.id)
      this.setState({
        actualPOs: newActualPOs,
        purchaseOrders: newActualPOs.filter(x => currentIds.indexOf(x.id) > -1),
      })
    }

  }

  selectAll() {
    let {purchaseOrders} = this.state

    let allAreSelected = true

    for (let x=0; x<purchaseOrders.length; x++) {
      if (!window.poIds[purchaseOrders[x].id]) {
        allAreSelected = false
        break;
      }
    }

    if (allAreSelected) {
      window.poIds = {}
    } else {
      for (let x=0; x<purchaseOrders.length; x++) {
        window.poIds[purchaseOrders[x].id] = true
      }
    }

    this.updateSelected()
    this.updateNumSelected()
    this.forceUpdate()
  }

  bulkEdit() {
    let poIds = Object.keys(window.poIds)

    if (poIds.length == 0) {
      alert('Please select at least one order!')
    } else {
      this.setState({bulkModalOpen: true})
    }
  }

  bulkModalClose() {
    this.setState({bulkModalOpen: false})
  }

  bulkAddBol() {
    let poIds = Object.keys(window.poIds)

    if (poIds.length == 0) {
      alert('Please select at least one order!')
    } else {
      this.setState({addBolModalOpen: true})
    }
  }

  bulkAddRoutingNumber() {
    let poIds = Object.keys(window.poIds)

    if (poIds.length == 0) {
      alert('Please select at least one order!')
    } else {
      this.setState({addRoutingNumberModalOpen: true})
    }
  }

  bulkAddPackingSlip() {
    let poIds = Object.keys(window.poIds)

    if (poIds.length == 0) {
      alert('Please select at least one order!')
    } else {
      window.richTextMessageHtml = ''
      window.richTextMessageHtmlBulk = {}
      poIds.forEach(id => {
        window.richTextMessageHtmlBulk[id] = ''
      })
      this.setState({addPackingSlipModalOpen: true})
    }
  }

  bulkGeneratePackingSlip() {
    let {actualPOs} = this.state
    let poIds = Object.keys(window.poIds)

    if (poIds.length == 0) {
      alert('Please select at least one order!')
    } else {
      //check if any are missing ship to
      let anyMissingShipTo = poIds.some(id => {
        let po = actualPOs.find((element) => element.id == id)
        return (po.shipTo == null || po.shipTo.trim() == '')
      })

      if (anyMissingShipTo) {
        alert('Ship to field is required to generate packing slip!')
      } else {
        window.richTextMessageHtml = ''
        this.setState({generatePackingSlipModalOpen: true, addPackingSlipId: null, currentPO: null})
      }
    }
  }

  downloadExcelWarehouse() {
    var data = [
      [ "REF #", "PO #", "ACCOUNT", "STATUS", "START SHIP DATE", "ROUTING NUMBER", "PICK-UP DATE", "NOTE"]
    ]

    let pos = window.sortedRows

    let poIds = Object.keys(window.poIds)
    if (poIds.length > 0) {
      pos = pos.filter((po) => {return !!window.poIds[po.id]})
    } else {
      alert('Please select at least one order!')
      return
    }


    for (let i=0; i<pos.length; i++) {
      let po = pos[i]

      let row = []

      row.push(po.ponumber.value)

      row.push(po.customer_order_number.value)

      row.push(po.account.value)

      row.push(po.status.value)

      row.push(Utils.formatSimpleDate(po.start_ship_date.value))

      row.push(po.routing_number.value)

      row.push(Utils.formatSimpleDate(po.pick_up_date.value))

      row.push(po.needs_fulfillment_message.value == null ? null : po.needs_fulfillment_message.value.replace(/(<([^>]+)>)/g, ""))

      data.push(row)
    }


    var worksheet = XLSX.utils.aoa_to_sheet(data);

    var wscols = [
        {wch:20},
        {wch:40},
        {wch:40},
        {wch:20},
        {wch:20},
        {wch:40},
        {wch:20},
        {wch:40}
    ];

    worksheet['!cols'] = wscols;

    var wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, worksheet, "sales orders");

    let dateText = new Date().toISOString().split('T')[0]
    return XLSX.writeFile(wb, ('sales-orders-export_' + dateText + '.xlsx'))
  }

  downloadExcel() {
    return getExcel(this.state, this.context)
  }

  handleTabChange = (e, { activeIndex }) => {
    if (this.state.searchValue.length >= 3 || activeIndex != this.state.activeIndex) {
      window.poIds = {}
      this.setState({ activeIndex, searchValue: ''})
      setTimeout(this.filterPOWarehouse, 10)
      window.scrollTo(0, 0)
      $('#multi-search-textarea').val('')
      this.updateNumSelected()
      this.updateSelected()
      window.dispatchEvent(new Event('page1event'))
    }
  }

  filterPOWarehouse() {
    let {user} = this.context
    let {isInventory, isAdmin} = isUserRole(user)
    let status = getStatuses(isInventory || isAdmin)[this.state.activeIndex]
    let purchaseOrders = getFilterPOWarehouse(status, this.state)
    this.setState({purchaseOrders})
  }

  calculateTotals() {
    let {user} = this.context
    let {isInventory, isAdmin} = isUserRole(user)
    let {actualPOs} = this.state
    let totals = []

    getStatuses(isInventory || isAdmin).forEach(status => {
      let purchaseOrdersLength = actualPOs.filter(x => x.status == status).length
      totals.push(purchaseOrdersLength)
    })

    this.setState({totals})
  }

  calculateAccountTotals() {
    let {user} = this.context
    let {isInventory, isAdmin} = isUserRole(user)
    let accountTotals = []

    getStatuses(isInventory || isAdmin).forEach(status => {
      let purchaseOrdersLength = getFilterPOWarehouse(status, this.state).length
      accountTotals.push(purchaseOrdersLength)
    })

    this.setState({accountTotals})
  }

  addBol = (id) => {
    this.setState({addBolModalOpen: true, addBolId: id})
  }

  addBolModalClose() {
    this.setState({addBolModalOpen: false, addBolId: null})
  }

  markShippedCancelModalClose() {
    this.setState({markShippedCancelModalOpen: false, markShippedCancelId: null, shippedCancelActiveIndex: null})
  }

  markPickUpScheduledModalClose() {
    this.setState({markPickUpScheduledModalOpen: false, markPickUpScheduledId: null})
  }

  addRoutingNumberModalClose() {
    this.setState({addRoutingNumberModalOpen: false})
  }

  addPackingSlipModalClose() {
    this.setState({addPackingSlipModalOpen: false, addPackingSlipId: null, currentPO: null})
  }

  generatePackingSlipModalOpen(po) {
    window.richTextMessageHtml = ''
    this.setState({generatePackingSlipModalOpen: true, addPackingSlipId: po.id, currentPO: po})
  }

  generatePackingSlipModalClose() {
    this.setState({generatePackingSlipModalOpen: false, addPackingSlipId: null})
  }

  generatePackingSlip = (po) => {
    //ship to is required
    if (po.shipTo == null || po.shipTo.trim() == '') {
      alert('Ship to field on contact is required to generate packing slip!')
    } else {
      this.generatePackingSlipModalOpen(po)
    }
  }

  generatePackingSlipSubmit(e) {
    e.preventDefault()

    let poIds = this.state.addPackingSlipId == null ? Object.keys(window.poIds) : [this.state.addPackingSlipId]

    poIds.forEach(id => {
      $('#generate-packing-slip-button-'+id).addClass('loading')
    })

    $('#generate-packing-slip-form-submit').addClass('disabled loading')

    let data = {
      purchase_order_ids: poIds,
      richText: window.richTextMessageHtml
    }

    Utils.post('sales_order_packing_slip', data, (err, res) =>
    {
      poIds.forEach(id => {
        $('#generate-packing-slip-button-'+id).removeClass('loading')
      })

      $('#generate-packing-slip-form-submit').removeClass('disabled loading')

      if (err) {
        alert('There was an error when trying to generate packing slip.')
      } else {
        if (res.data.success) {
          alert('Packing slip generated successfully. ' + (poIds.length > 1 ? 'Orders are' : 'Order is') + ' now status Needs-Fulfillment.')

          let objectKeys = res.data.objectKeys
          let statuses = res.data.statuses

          poIds.forEach((id, index) => {
            this.changeStatusPackingSlip(id, objectKeys[index], statuses[index])
          })
          this.calculateTotals()
          this.calculateAccountTotals()

          this.generatePackingSlipModalClose()
        }
      }
    })
  }

  updateNumSelected() {
    var keys = Object.keys(window.poIds)
    if (this.state.numCadsSelected != keys.length) {
      this.setState({numCadsSelected: keys.length})
    }
  }

  scrollToTop() {
    window.scrollTo(0,0)
    $('#scroll-to-top').hide()
  }

  refresh() {
    $('#multi-search-textarea').val('')
    this.setState({
      loading: true,
      missingPONumbers: [],
      multipleMatches: false,
      numCadsSelected: 0,
      searchValue: '',
    })

    var {user} = this.context
    let isAdminOrInventory = (user && ['superadmin', 'admin', 'inventory'].indexOf(user.role) > -1)

    if (isAdminOrInventory) {
      window.location.reload()
    } else {
      this.startComponent()
    }

  }

  distroUrl(id, filename) {
    let foldername = filename.split('/')[0]
    return process.env.REACT_APP_API_URL + '/api/download_order_file/' + id + '?is_dist_file=true&file=' + foldername
  }

  downloadDistro(id, filename) {
    $('#download-distro-button-'+id).addClass('loading')
    setTimeout(() => { $('#download-distro-button-'+id).removeClass('loading') }, 1000)
    let distroUrl = this.distroUrl(id, filename)
    window.open(distroUrl, '_blank', "height=700,width=700")
  }

  saveBol(e) {
    e.preventDefault()

    let bolShippedDate = $('#bol-ship-date').val()
    let {addBolId} = this.state

    let formData = new FormData()
    let file = $('#bol-file')[0].files[0]
    formData.append("file", file, file.name)

    let url = Constants.API.apiEndPoint + 'upload-file'

    this.setState({bolLoading: true, addBolId: null})
    this.addBolModalClose()

    Utils.uploadFile(url, formData, (error, data) =>
    {
      if (error)
      {
        alert('There was an error when trying to upload the file.')
      }
      else
      {
        if (data.success)
        {
          console.log('data success')
          this.createBol(data.filename, data.url, bolShippedDate, addBolId)
        }
        else
        {
          if (data.corrupt_file)
          {
            alert('The file is corrupt.')
          }
          else
          {
            alert('There was an error when trying to upload the file.')
          }
        }
      }
    })
  }

  createBol(filename, url, bolShippedDate, addBolId) {
    let isBulk = (addBolId == null)

    let data = {
      filename,
      url,
      bolShippedDate
    }

    if (isBulk) {
      data.purchase_order_ids = Object.keys(window.poIds)
    } else {
      data.purchase_order_id = addBolId
    }


    Utils.post('create_bol', data, (err, res) =>
    {
      this.setState({bolLoading: false})
      if (err) {
        console.log('There was an error when trying to create the BOL.')
      } else {
        alert(isBulk ? 'BOL successfully bulk added. Orders are now status Shipped.' :  'BOL successfully added. Order is now status Shipped.')
        this.refresh()
      }
    })
  }

  saveRoutingNumber(e) {
    e.preventDefault()

    let poIds = Object.keys(window.poIds)

    let routingNumbers = []
    for (let i=0; i<poIds.length; i++) {
      let poId = poIds[i]
      routingNumbers.push($('#routing-number-'+poId).val())
    }

    let data = {
      purchase_order_ids: poIds,
      routingNumbers
    }

    this.setState({routingNumberLoading: true})
    this.addRoutingNumberModalClose()

    Utils.post('add_routing_number', data, (err, res) =>
    {
      this.setState({routingNumberLoading: false})
      if (err) {
        console.log('There was an error when trying to bulk add the routing number.')
      } else {
        alert('Bulk routing number successfully added. Orders are now status Routed.')

        poIds.forEach(id => {
          $('#add-routing-number-'+id).fadeOut()
        })

        this.refresh()
      }
    })
  }

  savePackingSlip(e) {
    e.preventDefault()

    let poIds = this.state.addPackingSlipId == null ? Object.keys(window.poIds) : [this.state.addPackingSlipId]

    let numberOfFilesToUpload = poIds.length
    let numberOfFilesUploaded = 0
    let uploadData = {}

    this.setState({packingSlipLoading: true})

    //upload files
    poIds.forEach(id => {
      let formData = new FormData()
      let file = $('#packing-slip-'+id)[0].files[0]
      formData.append("file", file, file.name)

      let url = Constants.API.apiEndPoint + 'upload-file'

      Utils.uploadFile(url, formData, (error, data) =>
      {
        if (error)
        {
          alert('There was an error when trying to upload the file.')
        }
        else
        {
          if (data.success)
          {
            console.log('data success')
            uploadData[id] = data

            //when done uploading files call api
            numberOfFilesUploaded += 1
            if (numberOfFilesUploaded == numberOfFilesToUpload) {
              this.createPackingSlip(poIds, uploadData)
            }
          }
          else
          {
            if (data.corrupt_file)
            {
              alert('The file ' + file.name + ' is corrupt.')
            }
            else
            {
              alert('There was an error when trying to upload the file.')
            }
          }
        }
      })
    })
  }

  createPackingSlip(poIds, uploadData) {
    let richText = poIds.map(id => window.richTextMessageHtmlBulk[id])
    let urls = poIds.map(id => uploadData[id].url)
    let filenames = poIds.map(id => uploadData[id].filename)

    let data = {
      purchase_order_ids: poIds,
      urls: urls,
      filenames: filenames,
      rich_text: richText
    }

    Utils.post('create_packing_slip', data, (err, res) =>
    {
      this.setState({bolLoading: false})
      if (err) {
        console.log('There was an error when trying to create the packing slip.')
      } else {
        //success
        this.setState({packingSlipLoading: false, addPackingSlipId: null})
        this.addPackingSlipModalClose()

        poIds.forEach(id => {
          $('#upload-packing-slip-'+id).fadeOut()
        })
        setTimeout(() => {
          alert('Packing slip uploaded successfully. ' + (poIds.length > 1 ? 'Orders are' : 'Order is') + ' now status Needs-Fulfillment.')
        }, 0)
      }
    })
  }

  onChangeBulkRoutingNumber(event, {value}) {
    let poIds = Object.keys(window.poIds)

    for (let i=0; i<poIds.length; i++) {
      let poId = poIds[i]
      $('#routing-number-'+poId).val(value)
    }
  }

  addRoutingNumber = (id) => {
    let routingNumber = prompt("Please enter routing number")

    if (routingNumber == null) {
      return
    }

    if (routingNumber.length < 4) {
      alert('Routing number must be at least 4 characters long!')
      return
    }

    let data = {
      purchase_order_id: id,
      routingNumber
    }

    this.setState({routingNumberLoading: true})

    Utils.post('add_routing_number', data, (err, res) =>
    {
      this.setState({routingNumberLoading: false})
      if (err) {
        console.log('There was an error when trying to add the routing number.')
      } else {
        alert('Routing number successfully added. Order is now status Routed.')
        $('#add-routing-number-'+id).fadeOut()
        this.refresh()
      }
    })
  }

  richTextMessageHtmlOnchange(value) {
    window.richTextMessageHtml = value

    let poIds = this.state.addPackingSlipId == null ? Object.keys(window.poIds) : [this.state.addPackingSlipId]

    poIds.forEach(id => {
      window.richTextMessageHtmlBulk[id] = value
    })

    clearTimeout(window.updateTimeout)
    window.updateTimeout = setTimeout(this.myUpdate, 500)
  }

  richTextMessageHtmlOnchangeBulk(id, value) {
    window.richTextMessageHtmlBulk[id] = value
  }

  myUpdate() {
    this.forceUpdate()
  }

  changeStatusGreyRow(id, newStatus) {
    let {purchaseOrders, actualPOs} = this.state

    let index = purchaseOrders.findIndex((element) => element.id == id)
    purchaseOrders[index].status = newStatus

    let index2 = actualPOs.findIndex((element) => element.id == id)
    actualPOs[index2].status = newStatus

    this.setState({purchaseOrders, actualPOs})
  }

  changeStatusPackingSlip(id, objectKey, status) {

    let {purchaseOrders, actualPOs} = this.state


    let index = purchaseOrders.findIndex((element) => element.id == id)
    purchaseOrders[index].packingSlipFilename = objectKey
    purchaseOrders[index].status = status

    let index2 = actualPOs.findIndex((element) => element.id == id)
    actualPOs[index2].packingSlipFilename = objectKey
    actualPOs[index2].status = status


    this.setState({purchaseOrders, actualPOs})
  }

  accountOnChange(event, {value}) {
    this.setState({account: value, searchValue: ''})
    this.afterFilterChange()
  }

  repOnChange(event, {value}) {
    this.setState({rep: value, searchValue: ''})
    this.afterFilterChange()
  }

  brandOnChange(event, {value}) {
    this.setState({brand: value, searchValue: ''})
    this.afterFilterChange()
  }

  ticketsOnChange(event, {value}) {
    this.setState({tickets: value, searchValue: ''})
    this.afterFilterChange()
  }

  orderTypeOnChange(event, {value}) {
    this.setState({orderType: value, searchValue: ''})
    this.afterFilterChange()
  }

  afterFilterChange() {
    window.poIds = {}
    setTimeout(this.filterPOWarehouse, 10)
    window.scrollTo(0, 0)
    $('#multi-search-textarea').val('')
    this.setState({missingPONumbers: [], multipleMatches: false})
    this.updateNumSelected()
    this.updateSelected()
    setTimeout(this.calculateAccountTotals, 10)
  }

  resetWarehouseFilters() {
    this.setState({orderType: [], tickets: [], account: [], rep: [], brand: [], searchValue: '', showFlagged: false, missingPONumbers: [], multipleMatches: false})
    this.afterFilterChange()
  }

  downloadPackingSlip = (id) => {
    let {activeIndex} = this.state
    let {user} = this.context
    let {isWarehouse} = isUserRole(user)

    $('#download-packing-slip-button-'+id).addClass('loading')

    let url = "/purchase_orders/1/purchase_order_files/PO-" + id + "/download.json?file_type=packing_slip"

    Utils.get(url, (err, res) =>
    {
      $('#download-packing-slip-button-'+id).removeClass('loading')
      if (err) {
        alert('There was an error when trying to download packing slip.')
      } else {
        if (res.data.success) {
          if (res.data.filePath.indexOf('.xlsx') > -1) {
            window.location = res.data.filePath
          } else {
            window.open(res.data.filePath, '_blank', "height=700,width=700")
          }

          //if tab is needs fulfillment, change status and grey out row!!!
          if (activeIndex == 0 && isWarehouse) {
            this.changeStatusGreyRow(id, 'In-Progress')
          }
        }
      }
    })
  }

  updateShipTo(id, shipTo) {
    let {purchaseOrders, actualPOs} = this.state


    let index = purchaseOrders.findIndex((element) => element.id == id)
    purchaseOrders[index].shipTo = shipTo

    let index2 = actualPOs.findIndex((element) => element.id == id)
    actualPOs[index2].shipTo = shipTo


    this.setState({purchaseOrders, actualPOs})
  }

  //should dry up similar functions
  updatePrinted(id, printed) {
    let {purchaseOrders, actualPOs} = this.state


    let index = purchaseOrders.findIndex((element) => element.id == id)
    purchaseOrders[index].printed = printed

    let index2 = actualPOs.findIndex((element) => element.id == id)
    actualPOs[index2].printed = printed


    this.setState({purchaseOrders, actualPOs})
  }

  markQbEntered(id) {
    this.markStatus(id, 'QB-Entered')
  }

  markInvoiced(id) {
    this.markStatus(id, 'Invoiced')
  }

  markShippedCancel(id, shippedCancelActiveIndex) {//shipped or cancel parameters?
    let poIds = Object.keys(window.poIds)

    id = (typeof id == 'number' ? id : null)

    if (poIds.length == 0 && id == null) {
      alert('Please select at least one order!')
    } else {
      this.setState({markShippedCancelModalOpen: true, markShippedCancelId: id, shippedCancelActiveIndex})
    }
  }

  markPickUpScheduled(id) {
    let poIds = Object.keys(window.poIds)

    id = (typeof id == 'number' ? id : null)

    if (poIds.length == 0 && id == null) {
      alert('Please select at least one order!')
    } else {
      this.setState({markPickUpScheduledModalOpen: true, markPickUpScheduledId: id})
    }
  }

  markStatus(id, status, url = '', date = '') {
    let poIds = (typeof id == 'number' ? [id] : Object.keys(window.poIds))

    if (poIds.length == 0) {
      alert('Please select at least one order!')
    } else {
      let data = {
        purchase_order_ids: poIds,
        status,
        url,
        date
      }

      Utils.post('mark_status', data, (err, res) =>
      {
        if (err) {
          alert('There was an error when trying to mark as ' + status + '.')
        } else {
          if (res.data.success) {
            alert((poIds.length > 1 ? 'Orders are' : 'Order is') + ' now status ' + status + '.')
            poIds.forEach(id => {
              this.changeStatusGreyRow(id, status)
              if (status == 'Pick-Up-Scheduled') {
                this.updatePickUpScheduledFields(id, url, date)
              }
            })
            this.calculateTotals()
            this.calculateAccountTotals()
          }
        }
      })
    }
  }

  updateOrders(poIds, status, flaggedForUpdate) {
    poIds.forEach(id => {
      this.changeStatusGreyRow(id, status)
      if (status == 'Cancelled' && flaggedForUpdate) {
        this.updateFlagged(id)
      }
    })
    this.calculateTotals()
    this.calculateAccountTotals()
    window.poIds = {}
    this.updateSelected()
    this.updateNumSelected()
  }

  updateFlagged(id) {
    let {purchaseOrders, actualPOs} = this.state
    purchaseOrders = updateFlagged(purchaseOrders, id)
    actualPOs = updateFlagged(actualPOs, id)
    this.setState({purchaseOrders, actualPOs})
  }

  updatePickUpScheduledFields(id, url, date) {
    let {purchaseOrders, actualPOs} = this.state
    let filename = (url == null ? null : url.split('purchase_order/')[1])

    let index = purchaseOrders.findIndex((element) => element.id == id)
    purchaseOrders[index].pickUpScheduledDate = date
    purchaseOrders[index].pickUpScheduledFilename = filename

    let index2 = actualPOs.findIndex((element) => element.id == id)
    actualPOs[index2].pickUpScheduledDate = date
    actualPOs[index2].pickUpScheduledFilename = filename

    this.setState({purchaseOrders, actualPOs})
  }

  impersonateRepOnChange(event, {value}) {
    let {user} = this.context
    let {impersonateRep} = this.state

    this.setState({impersonateRep: value})

    clearTimeout(window.impersonateRepTimeout)

    if (isDefaultImpersonateRep(impersonateRep, user, value)) {
      //dont need to fetch purchase orders
    } else {
      window.impersonateRepTimeout = setTimeout(() => {
        this.fetchPurchaseOrders(value)
      }, 3000)
    }
  }

  impersonateContactOnChange(event, {value}) {
    this.setState({impersonateContact: value})
  }

  render()
  {
    var {user} = this.context
    let canImpersonateContacts = !!user && user.canImpersonateContacts

    let {progressModal, purchaseOrders, sliceNum, officeEditModal, uploadDistroModal, currentPO, sortColumn, account, totals, accountTotals, repOptions, impersonateRep, contactOptions, impersonateContact} = this.state
    let isOpenProgress = Object.values(progressModal).includes(true)

    let {isWarehouse, isInventory, isProduction, isAdmin, isSales} = isUserRole(user)

    let notUsingFilters = (
      isInventory || isAdmin
      ?
      account.length == 0 && this.state.rep.length == 0 && this.state.brand.length == 0 && this.state.orderType.length == 0 && this.state.tickets.length == 0 && !this.state.showFlagged
      :
      account.length == 0
    )

    const panes = getPanes(isInventory || isAdmin, notUsingFilters, totals, accountTotals)
    const statuses = getStatuses(isInventory || isAdmin)

    let refreshButton = (
      <div style={{float: 'right', marginLeft: '-40px'}} className="refresh-button">
        <Popup content='Refresh' position='bottom right' trigger={
          <Button size='huge' onClick={this.refresh} loading={false} basic icon='refresh'></Button>
        }/>
      </div>
    )

    let poIds = (window.poIds == null ? [] : Object.keys(window.poIds) )

    let visibleColumnsButton = (
      <VisibleColumnsButton
        showImage={this.state.showImage}
        toggleShowImageCheckbox={this.toggleShowImageCheckbox}
        showDetails={this.state.showDetails}
        toggleShowDetailsCheckbox={this.toggleShowDetailsCheckbox}
        showVponumber={this.state.showVponumber}
        toggleVponumberCheckbox={this.toggleVponumberCheckbox}
        showExFactory={this.state.showExFactory}
        toggleExFactoryCheckbox={this.toggleExFactoryCheckbox}
        toggleShippingClassCheckbox={this.toggleShippingClassCheckbox}
        showShippingClass={this.state.showShippingClass}
      />
    )

    let markup =
      this.state.loading
      ?
      <Dimmer active>
        <Loader size='massive'>Loading</Loader>
      </Dimmer>
      :
      <div style={{padding: '50px 60px'}}>

        {canImpersonateContacts ? impersonateContactsDropdown(contactOptions, this.impersonateContactOnChange, impersonateContact) : null}

        {canImpersonateUsers() ? impersonateUsersDropdown(repOptions, this.impersonateRepOnChange, impersonateRep) : null}

        <AutoCancelHoldLogs user={user}/>

        <QbSyncHoldLogs user={user}/>

        <BulkEditModal open={this.state.bulkModalOpen} onClose={this.bulkModalClose} refresh={this.refresh}/>

        {
          this.state.markShippedCancelModalOpen
          ?
          <ShippedCancelModal
            open={this.state.markShippedCancelModalOpen}
            onClose={this.markShippedCancelModalClose}
            id={this.state.markShippedCancelId}
            activeIndex={this.state.shippedCancelActiveIndex}
            updateOrders={this.updateOrders}
            actualPOs={this.state.actualPOs}
          />
          :
          null
        }

        <PickUpScheduledModal
          open={this.state.markPickUpScheduledModalOpen}
          onClose={this.markPickUpScheduledModalClose}
          id={this.state.markPickUpScheduledId}
          markStatus={this.markStatus} />

        {this.state.missingPONumbers.length > 0 || this.state.multipleMatches ?
          <Message id="multi-search-message" style={{marginBottom: '40px'}}
            icon='search'
            header={
              <div className="header">
                You are searching multiple values. &nbsp;&nbsp;

                {this.state.multipleMatches ? 'Some have multiple matches.' : null}

                {this.state.missingPONumbers.length > 0 ?
                  <div id="multi-search-not-found">
                    The following are NOT found:
                    <ul>
                      {this.state.missingPONumbers.map( (x) => {return (
                        <li>{x}</li>
                      )})}
                    </ul>
                  </div>
                : null}
              </div>
            }
          />
        : null}

        <Grid columns='equal'>
          <Grid.Row>
            {
              isSales
              ?
              <Grid.Column width={4}>
                {
                  this.state.loading
                  ?
                  <Header
                    as='h1'
                    content={<span>Loading...<Loader active style={{position: 'relative', bottom: '20px', zIndex: 0}}/></span>}
                    style={{marginTop: '25px', marginBottom: '40px'}}
                  />
                  :
                  <h1 style={{marginTop: '25px', marginBottom: '40px'}}>
                    {`${purchaseOrders.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} sales order${purchaseOrders.length == 1 ? '' : 's'} found`}
                  </h1>
                }
              </Grid.Column>
              :
              null
            }

            <Grid.Column style={{lineHeight: '46px', paddingTop: '11px', textAlign: (isSales ? 'center' : 'left')}}>
              <div style={{display: 'inline-block', padding: '14px 42px 0px 0px', border: '1px solid rgb(244,244,244)', borderRadius: '3px'}}>
                <Popup content='Select all' position='bottom left' trigger={
                  <Button basic icon='mouse pointer' className='bulk-button' onClick={this.selectAll} />
                }/>

                <Popup content='Download excel' position='bottom left' trigger={
                  <Button basic icon='file excel outline' className='bulk-button' onClick={this.downloadExcel} />
                }/>

                {
                  isProduction
                  ?
                  null
                  :
                  <React.Fragment>
                    {
                      isSales
                      ?
                      null
                      :
                      <Popup content='Bulk edit' position='bottom left' trigger={
                        <Button basic icon='pencil' className='bulk-button' onClick={this.bulkEdit} />
                      }/>
                    }

                    <Popup content='Mark as QB-Entered' position='bottom left' trigger={
                      <Button basic color='orange' className='bulk-button' onClick={this.markQbEntered} style={{paddingLeft: '11px', paddingRight: '11px'}}>
                        <Image id='qb-icon-status' src='https://erp-rails-usa-production.s3.amazonaws.com/images/qb_logo_small.png' avatar style={{marginTop: '-2px', marginBottom: '-1px', marginRight: '1px'}}/>
                      </Button>
                    }/>

                    <div style={{display: 'inline-block', position: 'absolute', marginLeft: '2px', paddingTop: '12px'}}>{infoCircle('Tip: Auto QB-Entered will work by matching PO# and Style#')}</div>

                    <Button.Group style={{marginRight: '5px'}}>
                      <Popup content='Bulk upload packing slip' position='bottom left' trigger={
                        <Button basic color='blue' icon='box' className='bulk-button' onClick={this.bulkAddPackingSlip} />
                      }/>

                      <Popup content='Bulk generate packing slip' position='bottom left' trigger={
                        <Button basic color='orange' icon='box' className='bulk-button no-margin-left' onClick={this.bulkGeneratePackingSlip} />
                      }/>
                    </Button.Group>

                    <Popup content='Bulk add routing number' position='bottom left' trigger={
                      <Button basic color='blue' icon='map pin' className='bulk-button' onClick={this.bulkAddRoutingNumber} />
                    }/>

                    <Popup content='Bulk upload BOL' position='bottom left' trigger={
                      <Button basic color='blue' icon='truck' className='bulk-button' onClick={this.bulkAddBol} />
                    }/>

                    <Popup content='Mark as Shipped' position='bottom left' trigger={
                      <Button basic color='green' icon='check' className='bulk-button' onClick={() => this.markShippedCancel(null, 0)} />
                    }/>

                    <Popup content='Cancel' position='bottom left' trigger={
                      <Button basic color='black' icon='trash' className='bulk-button' onClick={() => this.markShippedCancel(null, 1)} />
                    }/>

                    <Popup content='Combine' position='bottom left' trigger={
                      <Button style={{display: 'none'}} basic color='yellow' icon='clone' className='bulk-button' onClick={() => this.markShippedCancel(null, 2)} />
                    }/>
                  </React.Fragment>
                }
              </div>

              {
                this.state.numCadsSelected == 0
                ?
                null
                :
                <small style={{paddingLeft: '30px', position: 'relative', bottom: '6px', display: 'block'}}>
                  ({this.state.numCadsSelected} selected)
                </small>
              }
            </Grid.Column>

            <Grid.Column
              floated='right'
              width={5}
              style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', position:
            'relative', bottom: '20px'}}
              >
              {
                user && ['sales', 'sales_assistant', 'admin', 'superadmin'].includes(user.role) &&
                <Popup
                  content='New PO'
                  position='bottom center'
                  trigger={<Button basic size='tiny' icon='cart plus' onClick={this.goToNewPOPage} style={{display: 'none'}}/>}

                />
              }

              <div style={{maxWidth: '320px', width: '100%'}}>
                {
                  isWarehouse ?
                  <small style={{opacity: 0.5}}>Each REF # or PO # must be on a separate line!</small>
                  :
                  <small style={{opacity: 0.5}}>Each REF #, PO #, style #, VPO # must be on a separate line!</small>
                }
                <TextArea disabled={this.state.loading} id="multi-search-textarea" onKeyUp={this.textareaOnKeyUp} style={{width: '100%', minHeight: '100px'}} placeholder="Search..."/>
              </div>

            </Grid.Column>
          </Grid.Row>
        </Grid>


        {
          <div>
            {
              this.state.loading ?
              null
              :
              isWarehouse || isInventory || isAdmin
              ?
              <div>
                <div style={{marginTop: '-30px'}}></div>



                <Grid columns={isInventory || isAdmin ? 6 : 3} style={{width: (isInventory || isAdmin ? '100%' :'80%'), zIndex: 102, position: 'relative'}}>
                  <Grid.Column>
                    <Dropdown
                      placeholder={isWarehouse ? 'Select an Account' : 'Account'}
                      fluid
                      multiple
                      search
                      selection
                      options={this.state.accountOptions}
                      style={isInventory || isAdmin ? null : {marginBottom: '30px'}}
                      onChange={this.accountOnChange}
                      value={this.state.account}
                    />
                  </Grid.Column>
                  {
                    isInventory || isAdmin
                    ?
                    <React.Fragment>
                      <Grid.Column>
                        <Dropdown
                          placeholder='Rep'
                          fluid
                          multiple
                          search
                          selection
                          options={this.state.repOptions}
                          onChange={this.repOnChange}
                          value={this.state.rep}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Dropdown
                          placeholder='Tickets'
                          fluid
                          multiple
                          search
                          selection
                          options={ticketsOptions}
                          onChange={this.ticketsOnChange}
                          value={this.state.tickets}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Dropdown
                          placeholder='Brand'
                          fluid
                          multiple
                          search
                          selection
                          options={this.state.brandOptions}
                          onChange={this.brandOnChange}
                          value={this.state.brand}
                          loading={this.state.cadsLoading}
                          disabled={this.state.cadsLoading}
                        />
                      </Grid.Column>
                      <Grid.Column style={{textAlign: 'center', paddingTop: '22px'}}>
                        <Checkbox toggle label='Flagged for update' onChange={this.toggleShowFlaggedCheckbox} checked={this.state.showFlagged} />
                      </Grid.Column>
                    </React.Fragment>
                    :
                    null
                  }

                  <Grid.Column style={{textAlign: 'center'}}>
                    {
                      notUsingFilters
                      ?
                      null
                      :
                      <div style={{height: 0}}><a className="underline-on-hover reset-filters-link" onClick={this.resetWarehouseFilters}>Reset filters</a></div>
                    }
                  </Grid.Column>
                </Grid>

                {
                  isInventory || isAdmin
                  ?
                  <Grid columns={6} style={{width: '100%', zIndex: 101, position: 'relative'}}>
                    <Grid.Column>
                      <Dropdown
                        placeholder='Order type'
                        fluid
                        multiple
                        search
                        selection
                        options={orderTypeOptions}
                        style={{marginBottom: '30px'}}
                        onChange={this.orderTypeOnChange}
                        value={this.state.orderType}
                      />
                    </Grid.Column>
                  </Grid>
                  :
                  null
                }

                <span className="visible-column-and-refresh-holder">
                  {refreshButton}
                  {
                    isInventory || isAdmin
                    ?
                    visibleColumnsButton
                    :
                    null
                  }
                </span>
                <div style={{maxHeight: (isInventory || isAdmin ? '44px' : '49px'), overflow: 'hidden'}}>
                  <Tab panes={panes} activeIndex={this.state.searchValue.length < 3 ? this.state.activeIndex : null} onTabChange={this.handleTabChange}
                     />
                </div>
              </div>
              :
              <POFiltration
                filterPurchaseOrder={this.filterPurchaseOrder}
                accountIds={this.state.accountIds}
              />
            }

            {
              this.state.loading || isWarehouse || isInventory || isAdmin
              ?
              null
              :
              user && user.role === 'sales' || user.role === 'sales_assistant' ?
              <div style={{marginLeft: '15px', position: 'relative', bottom: '15px'}}>
                <Checkbox toggle label='Missing cad' onChange={this.toggleShowMissingCadCheckbox} checked={this.state.showMissingCad}/>
                <Checkbox toggle label='Flagged for update' onChange={this.toggleShowFlaggedCheckbox} checked={this.state.showFlagged} style={{marginLeft:'30px'}}/>
              </div>
              :
              <div style={{marginLeft: '15px', position: 'relative', bottom: '15px'}}>
                <Checkbox toggle label='Flagged for update' onChange={this.toggleShowFlaggedCheckbox} checked={this.state.showFlagged}/>
              </div>
            }

            {
              !this.state.loading && (user.role === 'sales' || user.role === 'sales_assistant')
              ?
              <React.Fragment>
                {refreshButton}
                {visibleColumnsButton}
              </React.Fragment>
              :
              null
            }

            {
              this.state.loading
              ?
              null
              :
              <SalesView
                purchaseOrders={purchaseOrders}
                showImage={this.state.showImage}
                showVponumber={this.state.showVponumber}
                showExFactory={this.state.showExFactory}
                showShippingClass={this.state.showShippingClass}
                showDetails={this.state.showDetails}
                showMissingCad={this.state.showMissingCad}
                history={this.props.history}
                activeIndex={this.state.activeIndex}
                addBol={this.addBol}
                addRoutingNumberModalOpen={this.state.addRoutingNumberModalOpen}
                addRoutingNumberModalClose={this.addRoutingNumberModalClose}
                updateNumSelected={this.updateNumSelected}
                refresh={this.refresh}
                openUploadDistroModal={this.openUploadDistroModal}
                currentPO={currentPO}
                closeUploadDistroModal={this.closeUploadDistroModal}
                uploadDistroModal={uploadDistroModal}
                downloadDistro={this.downloadDistro}
                distroUrl={this.distroUrl}
                bolLoading={this.state.bolLoading}
                routingNumberLoading={this.state.routingNumberLoading}
                addRoutingNumber={this.addRoutingNumber}
                downloadPackingSlip={this.downloadPackingSlip}
                searchValue={this.state.searchValue}
                generatePackingSlip={this.generatePackingSlip}
                addPackingSlip={this.addPackingSlip}
                updateShipTo={this.updateShipTo}
                updatePrinted={this.updatePrinted}
                brand={this.state.brand}
                markQbEntered={this.markQbEntered}
                markInvoiced={this.markInvoiced}
                markShippedCancel={this.markShippedCancel}
                markPickUpScheduled={this.markPickUpScheduled}
                updateSelected={this.updateSelected}
              />
            }
          </div>
        }

        <div id="scroll-to-top-corner-buttons">
          <button id="scroll-to-top" className="ui basic button" onClick={this.scrollToTop}><Icon name='arrow up' /> Back to top</button>
        </div>

        <Modal size={'small'} open={this.state.addBolModalOpen} onClose={this.addBolModalClose} closeIcon>
          <Modal.Header>{this.state.addBolId == null ? 'Bulk upload' : 'Upload' } BOL</Modal.Header>
          <Modal.Content>
            <form id="add-bol-form" onSubmit={this.saveBol}>
              <label style={{fontSize: '16px', fontWeight: 'bold', marginRight: '50px'}}>
                BOL shipped date
              </label>
              <input id="bol-ship-date" type="date" required/>
              <br/><br/>
              <label style={{fontSize: '16px', fontWeight: 'bold', marginRight: '145px'}}>
                File
              </label>
              &nbsp;&nbsp;&nbsp;
              <input id="bol-file" type="file" required/>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button primary type="submit" form="add-bol-form">
              Save
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal size={'medium'} open={this.state.addRoutingNumberModalOpen} onClose={this.addRoutingNumberModalClose} closeIcon>
          <Modal.Header>Bulk add routing number</Modal.Header>
          <Modal.Content>
            <form id="add-routing-number-form" onSubmit={this.saveRoutingNumber}>


              <Table compact='very' style={poIds.length > 1 ? {marginTop: '100px'} : {}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>REF #</Table.HeaderCell>
                    <Table.HeaderCell>PO #</Table.HeaderCell>
                    <Table.HeaderCell>
                      {poIds.length > 1 ?
                        <div className="bulk-checkout-holder">
                          <div className="bulk-checkout">
                            <b>Bulk Routing Number</b><br/>
                            <Input id="bulk-routing-number" style={{width: '350px'}} placeholder='Enter bulk routing number' onChange={this.onChangeBulkRoutingNumber}/>
                          </div>
                        </div>
                      : null}
                      Routing number
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    this.state.addRoutingNumberModalOpen ?
                    poIds.map((poId, index) => {
                      let order = purchaseOrders.find(order => order.id == poId)
                      let refNum = order.ponumber
                      let ponumber = order.customerOrderNumber

                      return <Table.Row key={index}>
                        <Table.Cell>
                          {refNum}
                        </Table.Cell>
                        <Table.Cell>
                          {ponumber}
                        </Table.Cell>
                        <Table.Cell>
                          <Input id={'routing-number-'+poId} style={{width: '350px'}} placeholder='Enter routing number' required/>
                        </Table.Cell>
                      </Table.Row>
                    })
                    :
                    null
                  }
                </Table.Body>
              </Table>




            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button primary type="submit" form="add-routing-number-form">
              Save
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal size={'tiny'} open={this.state.generatePackingSlipModalOpen} onClose={this.generatePackingSlipModalClose} closeIcon>
          <Modal.Header>
            {
              this.state.currentPO == null || this.state.currentPO.customerOrderNumber == null || this.state.currentPO.customerOrderNumber == ''
              ?
              'Bulk generate packing slip'
              :
              'Generate packing slip for PO# '+this.state.currentPO.customerOrderNumber
            }
          </Modal.Header>
          <Modal.Content>
            <form id="generate-packing-slip-form" onSubmit={this.generatePackingSlipSubmit}>
              <b>Message</b><br/>
              <ReactQuill
                value={window.richTextMessageHtml}
                onChange={this.richTextMessageHtmlOnchange}
              />
            </form>
          </Modal.Content>
          <Modal.Actions>
            {
              window.richTextMessageHtml == '' || window.richTextMessageHtml == "<p><br></p>"
              ?
              <small style={{color: 'red', marginRight: '20px', border: '1px solid red', padding: '5px 10px', paddingRight: 0, borderRadius: '11px', fontWeight: 'bold', background: 'rgb(255,220,220)'}}>
                Message is required<Icon name='warning' />
              </small>
              :
              null
            }

            <span style={window.richTextMessageHtml == '' || window.richTextMessageHtml == "<p><br></p>" ? {cursor: 'not-allowed'} : {}}>
              <Button id="generate-packing-slip-form-submit" primary type="submit" form="generate-packing-slip-form"
                disabled={window.richTextMessageHtml == '' || window.richTextMessageHtml == "<p><br></p>"}>
                Save
              </Button>
            </span>
          </Modal.Actions>
        </Modal>

        <Modal size={'large'} open={this.state.addPackingSlipModalOpen} onClose={this.addPackingSlipModalClose} closeIcon>
          <Modal.Header>{this.state.addPackingSlipId == null ? 'Bulk upload' : 'Upload'} packing slip</Modal.Header>
          <Modal.Content>
            {
              this.state.packingSlipLoading
              ?
              <Dimmer active={true}>
                <Loader />
              </Dimmer>
              :
              null
            }

            <form id="add-packing-slip-form" onSubmit={this.savePackingSlip}>

              {poIds.length > 1 ?
                <div style={{width: '600px', margin: 'auto'}}>
                  <b>BULK MESSAGE</b><br/>
                  <ReactQuill
                    value={window.richTextMessageHtml}
                    onChange={this.richTextMessageHtmlOnchange}
                  />
                </div>
              : null}

              <Table compact='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>REF #</Table.HeaderCell>
                    <Table.HeaderCell>PO #</Table.HeaderCell>
                    <Table.HeaderCell>Message</Table.HeaderCell>
                    <Table.HeaderCell>PACKING SLIP</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {(this.state.addPackingSlipId == null ? poIds : [this.state.addPackingSlipId]).map((poId, index) => {

                    let order = purchaseOrders.find(order => order.id == poId)
                    let refNum = (order == null ? null : order.ponumber)
                    let ponumber = (order == null ? null : order.customerOrderNumber)

                    return <Table.Row key={index}>
                      <Table.Cell>
                        {refNum}
                      </Table.Cell>
                      <Table.Cell>
                        {ponumber}
                      </Table.Cell>
                      <Table.Cell>
                        <ReactQuill
                          value={window.richTextMessageHtmlBulk[poId]}
                          onChange={this.richTextMessageHtmlOnchangeBulk.bind(null, poId)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input id={'packing-slip-'+poId} type="file" required/>
                      </Table.Cell>
                    </Table.Row>
                  })}
                </Table.Body>
              </Table>




            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button primary type="submit" form="add-packing-slip-form">
              Save
            </Button>
          </Modal.Actions>
        </Modal>


      </div>

    return markup;
  }
}

export default PurchaseOrders;
