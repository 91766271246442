import React from 'react'

const showPrintButton = (cadId, togglePrintImage, showPrintImage, primaryImageUrl, secondImageUrl) => {

  let top = secondImageUrl != null ? '55px' : '0px'

  const imageStyle = {
    position: 'absolute', right: '3px', top: top, cursor: 'pointer', height: '50px', width: 'auto', zIndex: 999
  }

  const buttonStyle = {
    position: 'absolute', right: '3px', top: top, cursor: 'pointer', background: 'white', border: '1px solid rgb(222,222,222)', borderRadius: '3px', fontSize: '8px', opacity: 0.5, zIndex: 999
  }

  return (
    <React.Fragment>
      <img src={showPrintImage ? primaryImageUrl : printImageUrl(cadId)} style={imageStyle} onClick={togglePrintImage}/>
      <button style={buttonStyle} onClick={togglePrintImage}>{showPrintImage ? 'primary' : 'print'} image</button>
    </React.Fragment>
  )
}

const printImageUrl = (cadId, index) => {
  let {cadPrints, prints} = window
  let print = prints[cadPrints[cadId]]
  if (print == null) {
    return ''
  } else {
    let filename = print['filename']
    return 'https://s3.amazonaws.com/' + process.env.REACT_APP_S3_BUCKET_NAME + '/cad_thumb/' + filename.split('/')[0] + '/thumb.jpg' + (index == null ? '' : '?index='+index)
  }
}

export {showPrintButton, printImageUrl}
