import React      from 'react'
import {
  Form,
  Grid,
  Input,
  Button,
  Message,
  Label
}                  from 'semantic-ui-react'
import Constants   from '../../../modules/constants'
import Utils       from '../../../modules/utils'
import CustomerAPI from '../../../modules/api/customer_api'
import validator   from 'validator'

export class CompanyForm extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading       : false,
      company       : this.props.company || null,
      displayMessage: false,
      error         : null,
      inputs :
      {
        name :
        {
          value   : "",
          valid   : null,
          required: true,
          message : ""
        },
        status :
        {
          value   : "active",
          valid   : true,
          required: true,
          message : ""
        }
      }
    }

    this.setInputsFromCompany()
  }

  toggleMessageVisibility = () =>
  {
    this.setState({ displayMessage: !this.state.displayMessage })
  }

  setInputsFromCompany = () =>
  {
    let {company, inputs} = this.state
    if (!company) return

    Object.keys(inputs).forEach((key) =>
    {
      let input = inputs[key]

      switch (key)
      {
        case "name"  :
        case "status":
        {
          input.valid = true
          input.value = company[key]
          break
        }
        default :
        {
          break
        }
      }
    })

    this.setState({ inputs })
  }

  handleSubmit = () =>
  {
    this.setState({ loading: true })
    const { loading, inputs, company } = this.state
    let { data, valid } = this.getData()

    let callback = (error, data) =>
    {
      if (error)
      {
        this.setState({
          loading: false,
          displayMessage: true,
          error: { apiError: true, error: error }
        })
      }
      else
      {
        this.props.closeNewCompanyForm(null, data)
        this.setState({ loading: false })
      }
    }

    if (valid)
    {
      if (company)
      {
        CustomerAPI.update(company.id, {customer: data}, callback)
      }
      else
      {
        CustomerAPI.create({customer: data}, callback)
      }
    }
  }

  getData = () =>
  {
    let { inputs } = this.state
    let data       = {}

    data.name      = inputs.name.value
    data.status    = inputs.status.value

    return { data, valid: this.isInputsValid() }
  }

  isInputsValid = () =>
  {
    let {inputs} = this.state
    let valid    = Utils.isInputsValidated(inputs)
    return valid
  }

  handleChange = (e, data) =>
  {
    let state  = this.state
    let inputs = state.inputs
    let value  = e.target.value
    let name   = e.target.name

    let input   = inputs[name]
    input.value = value

    if (input.required && (input.value.length == 0))
    {
      input.message = 'Please enter the value'
    }
    else
    {
      input.message = ''
    }

    switch (name)
    {
      case "name"  :
      {
        input.valid = !input.message
        break
      }
      default :
      {
        break
      }
    }

    this.setState({ inputs })
  }

  updateCompanyStatus = () =>
  {
    let inputs  = this.state.inputs
    let input   = inputs.status
    input.value = input.value == 'active' ? 'inactive' : 'active'

    this.setState({ inputs })
    this.handleSubmit()
  }

  render()
  {
    let { inputs, company } = this.state
    let isOldCompany        = company && company.id
    let errorMessage     = ''

    if (
      this.state.error &&
      this.state.error.apiError &&
      this.state.error.error
    )
    {
      let errors   = this.state.error.error.response.data
      errorMessage = Object.keys(errors).map((key, i) =>
      {
        return <p key={i}><b>{key}: </b>{errors[key].join(', ')}</p>
      })
    }

    let markup = (
      <Form onSubmit={this.handleSubmit} loading={this.state.loading}>
        {
          this.state.displayMessage && (
            <Message
              negative
              size='mini'
              onDismiss={this.toggleMessageVisibility}
            >
              <Message.Header>
                We're sorry some error occured!
              </Message.Header>
              {errorMessage}
            </Message>
          )
        }
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <Form.Field
                required
                control={Input}
                placeholder='Name'
                name='name'
                label='Name'
                value={inputs.name.value}
                onChange={(e) => this.handleChange(e, inputs.name)}
                error={inputs.name.valid == false ? {
                  content: inputs.name.message,
                } : false }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button
                floated='right'
                disabled={!this.isInputsValid()}
                primary
                content={isOldCompany ? 'Update' : 'Save'}
              />
              <Button
                floated='right'
                basic
                content='Cancel'
                onClick={this.props.closeNewCompanyForm}
              />
              {isOldCompany &&
                <Button
                  floated='left'
                  basic
                  onClick={this.updateCompanyStatus}
                  disabled={!this.isInputsValid()}
                  color={company.status === 'active' ? 'red' : 'green'}
                  content={company.status === 'active' ? 'Deactivate' : 'Activate'}
                />
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    )

    return markup;
  }
}

export default CompanyForm
