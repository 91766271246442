import React from 'react'
import {Checkbox} from 'semantic-ui-react'

const applicableSubcategoriesToggle = (showApplicableSubcategories, toggleApplicable) => {
  return (
    <Checkbox toggle label='Only show VALID Sub-Categories'
      style={{marginLeft: '20px'}} onClick={toggleApplicable} checked={showApplicableSubcategories}
    />
  )
}

export {applicableSubcategoriesToggle}
