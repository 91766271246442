import React from 'react'
import {Select} from 'semantic-ui-react'
import {patternOptions} from './options'

const patternInput = (pattern, fieldOnChange) => {
  return (
    <React.Fragment>
      <label>Pattern</label><br/>
      <Select search name="pattern" style={{width: '300px'}} placeholder='Select a pattern'
        options={patternOptions} onChange={fieldOnChange}
        value={pattern}
      />
    </React.Fragment>
  )
}

export {patternInput}
