import Utils from "../utils"

class UserAPI
{
  static create(data, cb, customerId=null)
  {
    Utils.post(`users.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static update(id, data, cb, customerId=null)
  {
    Utils.put(`users/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static get(id, cb, customerId=null)
  {
    Utils.get(`users/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static delete(id, cb, customerId=null)
  {
    Utils.delete(`users/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static getAllReps(cb)
  {
    Utils.get(`users/reps.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static getCurrentUser(cb)
  {
    Utils.get('current_user', (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static getCurrentCustomer(cb)
  {
    Utils.get('current_customer', (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }
}

export default UserAPI
