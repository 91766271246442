import axios   from 'axios'
import humps   from 'humps'
import { API } from './../constants'

const http = axios.create(
{
  headers:
  {
    'Authorization': 'Bearer '+ window.localStorage.getItem('id_token'),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'X-Key-Inflection': 'camel'
  },
  baseURL: API.apiEndPoint,
  transformResponse:
  [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data)
  ],
  transformRequest:
  [
    data => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest
  ]
})

export default http;
