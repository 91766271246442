import React, {Component} from 'react'
import {Button, Dropdown, Menu} from 'semantic-ui-react'
import {confirmOptions} from '../vendor-orders/options.js'

class ContinueButton extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}

    this.confirm = this.confirm.bind(this)
  }

  confirm(event, {value}) {
    let {markAsComplete} = this.props
    if (value == 'CONFIRM') {
      markAsComplete()
    } else {
      let action = value.split(' & ')[1].toLowerCase()
      markAsComplete(action)
    }
  }

  render() {
    let {markAsComplete, complete, text, submittingForm, generateCads} = this.props

    if (text == null) {
      text = 'CONTINUE'
    }

    return (
      <div style={{marginTop: '32px'}}>
        <span style={complete ? {} : {cursor: 'not-allowed', display: 'inline-block'}}>
          {
            text == 'CONFIRM'
            ?
            <Menu compact style={{boxShadow: 'none', borderRadius: '2px'}}>
              <Dropdown text={text} options={confirmOptions} simple item onChange={this.confirm} value={null} style={{padding: '15px 40px'}} />
            </Menu>
            :
            <Button disabled={(!complete || submittingForm) && !generateCads} color={complete ? 'pink' : 'grey'}
              style={{padding: '15px 40px', borderRadius: '2px'}}
              onClick={markAsComplete} loading={submittingForm}
            >
              {text} {generateCads ? '& GENERATE CADS' : ''}
            </Button>
          }
        </span>
      </div>
    )
  }
}

export default ContinueButton
