import Utils from '../../modules/utils'
import copyCadToVendorOrders from './copy-cad-to-vendor-orders.js'

const getArchivedVpos = (callbackFunc) => {
  Utils.get('archived_vpos/', (err, res) =>
  {
    if (err) {
      alert('There was an error when trying to get archived vendor orders.')
    } else {
      if (res.data.success) {
        window.archivedVpos = JSON.parse(res.data.data)
        window.numberOfApiSuccess += 1
        if (window.numberOfApiSuccess == 3) {callbackFunc()}
      }
    }
  })
}

export {getArchivedVpos}
