import React from 'react'
import $ from 'jquery'
import { Icon} from 'semantic-ui-react'

const closeFullscreen = () => {
  $('#my-fullscreen-cad-viewer > img').attr('src', '')
  $('#my-fullscreen-cad-viewer').hide()
  window.fullscreenIndex = null
  window.fullscreenPrint = null
}

const printLeftArrow = () => {
  let {fullscreenPrint, prints} = window
  let index = prints.indexOf(fullscreenPrint)
  if (index == 0) {
    alert('You are at the beginning of the list')
  } else {
    showPrint(prints[index - 1], index - 1)
  }
}

const printRightArrow = () => {
  let {fullscreenPrint, prints} = window
  let index = prints.indexOf(fullscreenPrint)
  if (index == prints.length - 1) {
    alert('You are at the end of the list')
  } else {
    showPrint(prints[index + 1], index + 1)
  }
}

const showPrint = (print, index) => {
  window.fullscreenPrint = print
  $('#zooming-style-number').text(print.styleNumber)
  let imageUrl = 'https://s3.amazonaws.com/' + process.env.REACT_APP_S3_BUCKET_NAME + '/cad/' + print.filename
  $('#my-fullscreen-cad-viewer > img').attr('src', imageUrl)
  $('#fullscreen-index').text('' + (index+1))
}

const imageClickPrint = (src) => {
  let folderName = src.split('cad_thumb/')[1].split('/')[0]
  let {prints} = window
  let print = prints.find(x => x.filename.includes(folderName))
  let index = prints.indexOf(print)
  $('#my-fullscreen-cad-viewer').show()
  showPrint(print, index)
  $('#fullscreen-index-max').text('' + prints.length)
}

const imageClick = (imgElement) => {
  let url, cadIndex, image_url, cad
  let src = $(imgElement.currentTarget).prop('src')
  let cadsToFilter = window.cadsToSearch
  let showSecondImage = false


  if (src.includes('?index=')) {
    //do nothing
  } else if (src.indexOf('cad_thumb') == -1) {
    url = src.split('cad/')[1].split('/')[0];
  } else {
    url = src.split('cad_thumb/')[1].split('/')[0];
  }


  if (src.indexOf('?index=') > -1) {
    cadIndex = Number(src.split('?index=')[1]);
    cad = cadsToFilter[cadIndex];
    image_url = src;
  } else {
    cad = cadsToFilter.find(cad => (cad.filename != null && cad.filename.includes(url)) || (cad.filename2 != null && cad.filename2.includes(url)))
    cadIndex = cadsToFilter.indexOf(cad)

    let s3BucketName = cad.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME
    image_url = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + cad.filename
  }

  $('#my-fullscreen-cad-viewer').show()
  $('#my-fullscreen-cad-viewer > img').attr('src', image_url);
  window.fullscreenIndex = cadIndex;
  window.fullscreenCadId = cad.id;

  var myAts = cad.available;
  if (myAts == null || myAts < 0) {
    myAts = 0;
  }
  var myFutureAts = cad.future_ats;
  if (myFutureAts == null || myFutureAts < 0) {
    myFutureAts = 0;
  }
  $('#zooming-style-number').text(cad.style_number);
  $('#zooming-ats').text('' + myAts);
  $('#zooming-future-ats').text('' + myFutureAts);

  $('#fullscreen-index').text('' + (cadIndex+1));
  $('#fullscreen-index-max').text('' + cadsToFilter.length);

  document.getElementById("cad-checkbox-fullscreen").checked = window.cadIds[cad.id];
}

const onChangeScreenCheckbox = (updateNumSelected) => {
  var checked = $("#cad-checkbox-fullscreen").is(':checked');

  if (checked) {
    window.cadIds[window.fullscreenCadId] = true;
  } else {
    delete window.cadIds[window.fullscreenCadId];
  }

  document.getElementById("cad-checkbox-"+window.fullscreenCadId).checked = checked;
  updateNumSelected();
}

const cadViewer = (updateNumSelected = null, wantUnits = false) => {
  return (
    <div id="my-fullscreen-cad-viewer" style={{display: 'none'}}>
      <img src='' style={{height: '75vh', marginTop: '10vh'}} />
      <button style={{opacity: '0.7', position: 'fixed', right: '10px', top: '10px', borderRadius: '17px'}} className="ui basic button" onClick={closeFullscreen}><Icon name='close' /> Close fullscreen</button>
      <div style={{opacity: '0.7', position: 'fixed', right: '10px', bottom: '10px'}}>Use left/right arrow keys to change image, press escape to exit</div>
      <div style={{opacity: '0.7', position: 'fixed', left: '10px', top: '10px'}}>Showing <span id="fullscreen-index">1</span> of <span id="fullscreen-index-max">10</span></div>

      <div id="zoomingInfoBoxStyle">
        {
          wantUnits
          ?
          <div className="ui checkbox" data-tooltip="Select cad" data-position="top left">
            <input id="cad-checkbox-fullscreen"  onChange={onChangeScreenCheckbox.bind(null, updateNumSelected)} type="checkbox" />
            <label htmlFor="cad-checkbox-fullscreen"><b id="zooming-style-number" style={{cursor: 'pointer'}}>STYLENUMBER1234</b></label>
          </div>
          :
          <b id="zooming-style-number" style={{cursor: 'pointer'}}>STYLENUMBER1234</b>
        }


        {
          wantUnits
          ?
          <React.Fragment>
            <br/>ATS: <b id="zooming-ats">0</b><br/>
            <div>Future ATS: <b id="zooming-future-ats">0</b></div>
          </React.Fragment>
          :
          null
        }
      </div>
    </div>
  )
}

//$(document).keyup(function(e) {
const arrowKeyboardClick = (e) => {
  if (e.key === "Escape") {
    closeFullscreen()
  }
  if (window.fullscreenPrint != null) {
    if (e.keyCode == '37') {
      printLeftArrow()
    } else if (e.keyCode == '39') {
      printRightArrow()
    }
  }

  if (window.fullscreenIndex != null) {
    if (e.keyCode == '38') {
        // up arrow
    }
    else if (e.keyCode == '40') {
        // down arrow
    }
    else if (e.keyCode == '37') {
       // left arrow

       var fullscreenIndexNew = window.fullscreenIndex - 1;

       if (fullscreenIndexNew >= 0) {
         $('#my-fullscreen-cad-viewer > img').attr('src', '');
         let cadsToFilter = window.cadsToSearch

         var cad = cadsToFilter[fullscreenIndexNew];
         var image_url;
         if (cad.filename == null || cad.filename == '') {
           image_url = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new.png';
         } else {
           const s3BucketName = cad.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME
           image_url = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + cad.filename;
         }

         $('#my-fullscreen-cad-viewer > img').attr('src', image_url);
         window.fullscreenIndex = fullscreenIndexNew;
         window.fullscreenCadId = cad.id;

         var myAts = cad.available;
         if (myAts == null || myAts < 0) {
           myAts = 0;
         }
         var myFutureAts = cad.future_ats;
         if (myFutureAts == null || myFutureAts < 0) {
           myFutureAts = 0;
         }
         $('#zooming-style-number').text(cad.style_number);
         $('#zooming-ats').text('' + myAts);
         $('#zooming-future-ats').text('' + myFutureAts);

         $('#fullscreen-index').text('' + (fullscreenIndexNew+1));
         $('#fullscreen-index-max').text('' + cadsToFilter.length);

         document.getElementById("cad-checkbox-fullscreen").checked = window.cadIds[cad.id];
         $('#cad-card-'+cad.id)[0].scrollIntoView();
         window.scrollBy(0, -90)
       } else {
         alert('You are at the beginning of the list');
       }
    }
    else if (e.keyCode == '39') {
       // right arrow

       var fullscreenIndexNew = window.fullscreenIndex + 1;

       let cadsToFilter = window.cadsToSearch

       if (fullscreenIndexNew < cadsToFilter.length ) {
         $('#my-fullscreen-cad-viewer > img').attr('src', '');
         var cad = cadsToFilter[fullscreenIndexNew];
         var image_url;
         if (cad.filename == null) {
           image_url = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new.png';
         } else {
           const s3BucketName = cad.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME
           image_url = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + cad.filename;
         }
         $('#my-fullscreen-cad-viewer > img').attr('src', image_url);
         window.fullscreenIndex = fullscreenIndexNew;
         window.fullscreenCadId = cad.id;

         var myAts = cad.available;
         if (myAts == null || myAts < 0) {
           myAts = 0;
         }
         var myFutureAts = cad.future_ats;
         if (myFutureAts == null || myFutureAts < 0) {
           myFutureAts = 0;
         }
         $('#zooming-style-number').text(cad.style_number);
         $('#zooming-ats').text('' + myAts);
         $('#zooming-future-ats').text('' + myFutureAts);

         $('#fullscreen-index').text('' + (fullscreenIndexNew+1));
         $('#fullscreen-index-max').text('' + cadsToFilter.length);

         document.getElementById("cad-checkbox-fullscreen").checked = window.cadIds[cad.id];
         $('#cad-card-'+cad.id)[0].scrollIntoView();
         window.scrollBy(0, -90)


       } else {
         //console.log(cadsToFilter.length);
         //console.log(fullscreenIndexNew);
         alert('You are at the end of the list');
       }
    }
  }

}

export {imageClick, cadViewer, arrowKeyboardClick, imageClickPrint}
