import React, { useEffect } from "react"
import { Route }            from "react-router-dom"
import { useAuth0 }         from "./../../contexts/auth-context"

const PrivateRoute = ({ component: Component, path, permissions, ...rest }) =>
{
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  useEffect(() =>
  {
    if (loading || isAuthenticated) return
    const fn = async () =>
    {
      await loginWithRedirect({
        appState: { targetUrl: path }
      })
    }

    fn();

  }, [loading, isAuthenticated, loginWithRedirect, path])

  const render = (props) =>
  {
    if (
      user &&
      isAuthenticated &&
      permissions.includes(user.role)
    )
    {
      return (<Component {...props} />)
    }
    else
    {
      return null
    }
  }

  return <Route path={path} render={render} {...rest} />
}

export default PrivateRoute;
