import React, { Component } from 'react'
import {files} from './components/contacts/files'
import {Button} from 'semantic-ui-react'
import {saveUpcFiles} from './components/upc/save.js'
import {getUploadedFiles, exportAll} from './components/upc/get'
import {formatDate} from './components/cads/format-date'

class ImportUpcPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filenames: [],
      uploadedFiles: [],
      users: []
    }

    this.addFilename = this.addFilename.bind(this)
    this.removeFilename = this.removeFilename.bind(this)
    this.saveUpcFiles = this.saveUpcFiles.bind(this)
    this.getUploadedFiles = this.getUploadedFiles.bind(this)
  }

  componentDidMount() {
    this.getUploadedFiles()
  }

  getUploadedFiles() {
    getUploadedFiles((err, res) => {
      if (err) {
        alert('There was an error when trying to get uploaded upc files.')
      } else {
        this.setState({uploadedFiles: res.data.upcFiles, users: res.data.users})
      }
    })
  }

  addFilename(filename) {
    let {filenames} = this.state
    filenames.push(filename)
    this.setState({filenames})
  }

  removeFilename(filename) {
    let {filenames} = this.state
    filenames = filenames.filter(x => x != filename)
    this.setState({filenames})
  }

  saveUpcFiles() {
    let {filenames} = this.state
    saveUpcFiles(filenames, (err, res) => {
      if (err) {
        alert('There was an error when trying to save upc files.')
      } else {
        alert('UPC files uploaded.')
        this.setState({filenames: []})
        this.getUploadedFiles()
      }
    })
  }

  render() {
    let {filenames, uploadedFiles, users} = this.state

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>
        <h1 style={{marginTop: '25px', marginBottom: '35px'}}>Import UPC</h1>
        <div>Excel Headers must be: SKU | Description | GTIN | BrandName</div>
        <div style={{width: '50%', float: 'left'}}>
          {files('upc', filenames, this.addFilename, this.removeFilename)}

          <Button
            content="Save UPC File"
            labelPosition='right'
            icon='checkmark'
            onClick={this.saveUpcFiles}
            positive
            disabled={filenames.length == 0}
            style={{marginTop: '20px', float: 'right'}}
          />
        </div>
        <div style={{width: '45%', float: 'right'}}>
          <div style={{borderLeft: '1px solid black', paddingLeft: '10px'}}>
            <u>Uploaded Files</u> ({uploadedFiles.length}):

            {uploadedFiles.map((uploadedFile) => {return (
              <div>
                id: {uploadedFile.id}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href={uploadedFile.filename} target="_blank">{uploadedFile.filename.slice(75)}</a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                Created: {formatDate(uploadedFile.createdAt.split('T')[0])}
                &nbsp;&nbsp;&nbsp;&nbsp;
                User: {users.find(user => user.id == uploadedFile.userId).email}
                &nbsp;&nbsp;&nbsp;&nbsp;
                Status: {uploadedFile.processed ? (uploadedFile.errorProcessing ? 'Error' : 'Processed') : 'Pending'}
              </div>
            )})}
          </div>

          <br/><br/><br/><br/>

          <div style={{textAlign: 'right'}}><Button negative size='mini' onClick={exportAll}>Export ALL UPC</Button></div>

        </div>
      </div>
    )
  }
}


export default ImportUpcPage
