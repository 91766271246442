const getDifferenceInDays = (date) => {
  let date2 = new Date()
  let date1 = new Date(date)

  let differenceInTime = date2.getTime() - date1.getTime()
  let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24))

  if (differenceInDays < 0) {
    differenceInDays = 0
  }

  return differenceInDays
}

export {getDifferenceInDays}
