import React, { Component } from 'react'
import { Message } from 'semantic-ui-react'
import Utils from './../../modules/utils'

class AutoEditMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let {autoEdited, autoEditedDate, needToAutoEdit} = this.props

    if (needToAutoEdit || autoEdited) {
      let header = (
        needToAutoEdit
        ?
        <h2>Waiting to <u>AUTO EDIT HOLDS & WORKSHEETS</u> for this PO</h2>
        :
        <h2>This order was auto edited on {Utils.formatSimpleDate(autoEditedDate)}</h2>
      )

      return (
        <Message
          style={{maxWidth: '1200px', margin: 'auto', marginTop: '40px', cursor: (needToAutoEdit ? 'wait' : 'default')}}
          icon='bolt'
          header={header}
          color='yellow'
        />
      )
    } else {
      return null
    }
  }
}

export default AutoEditMessage
