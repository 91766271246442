import React, {Component} from 'react'
import {Message, Icon} from 'semantic-ui-react'
import RefNumberItem from './ref-number-item.js'
import {refreshButtonStyle} from './refresh-button-style.js'

class TodaysRefNumbers extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {user_role, loadingRefNumbers, refNumbers, refresh, times, ponumbers, reps, filenames} = this.props

    if (['sales', 'sales_assistant'].includes(user_role)) {
      return (
        <div style={{display: 'inline-block', textAlign: 'center', width: '80vw', marginBottom: '50px'}}>
          <Message warning>
            <Message.Header>These are todays REF #s <button style={refreshButtonStyle} onClick={this.props.refresh}>refresh</button></Message.Header>
            {
              loadingRefNumbers
              ?
              <p>Loading...</p>
              :
              refNumbers.length == 0
              ?
              <p>NONE</p>
              :
              <p style={{textAlign: 'left'}}>
                {
                  refNumbers.map((item, index) => {
                    return (
                      <RefNumberItem item={item}
                        time={times[index]}
                        ponumber={ponumbers[index]}
                        rep={reps[index]}
                        filename={filenames[index]}
                      />
                    )
                  })
                }
              </p>
            }
         </Message>
        </div>
      )
    } else {
      return null
    }
  }
}

export default TodaysRefNumbers
