import Utils from "../../modules/utils"

const downloadPDF = (results, stopLoading, separatePdfs) => {
  let refnumbers = getRefnumbers(results)
  let data = {successId: refnumbers.join(), separatePdfs}
  Utils.post('vpo_pdf', data, (err, result) => {
    if (err) {
      alert('There was an error when trying to download pdf')
    } else {
      stopLoading()
      let {url} = result.data
      let urls = typeof url === 'string' ? [url] : url
      urls.forEach(el => window.open(el, '_blank'))
    }
  })
}

const downloadExcel = (results, visibleFields, stopLoading) => {
  let data = getData(results, visibleFields)

  Utils.post('vpo_export_excel', data, (err, result) => {
    if (err) {
      alert('There was an error when trying to download excel')
    } else {
      stopLoading()
      window.location = result.data.url
    }
  })
}

let getData = (results, visibleFields) => {
  let orders = getOrders(results)
  return {orders, visibleFields}
}

let getOrders = (results) => {
  let {vpoIds} = window

  let orders = results.filter((el, index) => {
    return (!!vpoIds[el.id] && !!vpoIds[el.id][el.cad_id]) || (!!vpoIds['index'] && !!vpoIds['index'][index])
  })

  return orders
}

let getRefnumbers = (results) => {
  let orders = getOrders(results)
  let refnumbers = orders.map(x => x.refnumber)
  return refnumbers
}

export {downloadExcel, downloadPDF}
