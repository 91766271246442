import React, { Component } from 'react'
import { Input, Button, Dropdown, Dimmer, Loader, Checkbox } from 'semantic-ui-react'
import $ from 'jquery'
import {labelStyle} from "./components/edit-sales-order/Elements"
import {sortMapNameOptions, sortMapOptions} from './components/purchase-orders/sort-map-id-name-options.js'
import {Auth0Context} from "./contexts/auth-context"
import Utils from "./modules/utils"
import ZeroPriceReport from "./components/reports/zero-price.js"
import {
  yearOptions, timeOptions, orderTypeOptions, reportTypeOptions,
  inventoryTypeOptions, summaryPageOptions, inSeasonOptions, inSeasonOptionsWithAll
} from './components/reports/options.js'
import {checkAdminPin} from './components/admin/pin.js'
import {quickFixAllBoysAllGirls} from './reducers/quickFixAllBoysAllGirls'
import SavedReports from './components/reports/saved-reports.js'
import {inventoryTypeForSalesDropdown} from './components/reports/inventory-type-for-sales-dropdown'

class ReportsPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      brandOptions: [],
      divisionOptions: [],
      categoryOptions: [],
      subCategoryOptions: [],
      applicableSubCategoryOptions: [],
      brand: [],
      division: [],
      category: [],
      subCategory: [],
      year: ['2024'],
      reportType: 'Sales',
      inventoryType: 'ATS + Future ATS',
      inventoryTypeForSales: null,
      loading: false,
      time: 'Quarterly',
      orderType: [],
      salesUserOptions: [],
      user: [],
      accountOptions: [],
      account: [],
      summaryPage: 'Dollars',
      onlyInSeason: false,
      onlyLarge: true,
      inSeason: null,
      correctPin: false,
      onlySubCategoryByBrand: false
    }

    this.setOptions = this.setOptions.bind(this)
    this.setApplicableSubCategoryOptions = this.setApplicableSubCategoryOptions.bind(this)
    this.download = this.download.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.stopLoading = this.stopLoading.bind(this)
    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.setPinTrue = this.setPinTrue.bind(this)
    this.setPinFalse = this.setPinFalse.bind(this)
    this.checkboxOnChange = this.checkboxOnChange.bind(this)
    this.checkboxOnChange2 = this.checkboxOnChange2.bind(this)
    this.onlyLargeOnChange = this.onlyLargeOnChange.bind(this)
  }

  setPinTrue() {
    this.setState({correctPin: true})
  }

  setPinFalse() {
    this.setState({correctPin: false})
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setOptions()
    this.setAccountOptions()

    window.addEventListener('set-admin-pin-true', this.setPinTrue)
    window.addEventListener('set-admin-pin-false', this.setPinFalse)
    checkAdminPin()
  }

  setOptions() {
    let {customer} = this.context
    if (customer == null) {
      setTimeout(this.setOptions, 500)
    } else {
      let salesUserOptions = sortMapOptions(customer.users.filter(x => x.role == 'sales').map(x => x.email)).concat([{ key: 'MISSING USER', text: 'MISSING USER', value: 'MISSING USER' }])
      let brandOptions = sortMapNameOptions(customer.brands)
      let divisionOptions = sortMapNameOptions(customer.divisions)
      let categoryOptions = sortMapNameOptions(customer.categories)
      let subCategoryOptions = sortMapNameOptions(customer.subcategories)
      this.setState({brandOptions, divisionOptions, categoryOptions, salesUserOptions, subCategoryOptions})
    }
  }

  setAccountOptions() {
    Utils.get('sales_history_accounts', (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to get sales history accounts.')
      } else {
        if (res.data.salesHistoryAccounts != null) {
          let accountOptions = []
          for (var i=0; i<res.data.salesHistoryAccounts.length; i++) {
            let accountName = res.data.salesHistoryAccounts[i]
            accountOptions.push({ key: accountName, text: accountName, value: accountName })
          }

          this.setState({accountOptions})
        }
      }
    })
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}

    if (name == 'year') {
      value = value.sort()
    }
    if (value == 'All') {
      value = null
    }
    if (name == 'division') {
      value = quickFixAllBoysAllGirls(value)
    }

    newState[name] = value
    this.setState(newState)

    if (name == 'category' && value.length == 1) {
      this.setApplicableSubCategoryOptions(value[0])
    }
  }

  setApplicableSubCategoryOptions(categoryName) {
    let {categories, subcategories} = this.context.customer
    let {subCategory} = this.state
    let subcategoryIndicies = categories.find(x => x.name == categoryName).subcategories
    let applicableSubCategoryOptions = sortMapNameOptions(subcategoryIndicies.map(i => subcategories.find(j => j.id == i)))
    let applicableSubCategoryNames = applicableSubCategoryOptions.map(x => x.text)
    subCategory = subCategory.filter(x => applicableSubCategoryNames.includes(x))
    this.setState({applicableSubCategoryOptions, subCategory})
  }

  setLoading() {
    this.setState({loading: true})
  }

  stopLoading() {
    this.setState({loading: false})
  }

  download() {
    if (this.state.year.length == 0 && this.state.reportType == 'Sales') {
      alert('Please select a year')
      return
    }

    this.setLoading()

    let data = {}
    let fields = ['brand', 'year', 'time', 'category', 'subCategory', 'orderType', 'user', 'account', 'division', 'reportType', 'inventoryType', 'inventoryTypeForSales', 'summaryPage', 'onlyInSeason', 'inSeason', 'onlyLarge', 'onlySubCategoryByBrand']
    fields.forEach(key => data[key] = this.state[key])

    Utils.post('quarterly_brand_report', data, (err, res) =>
    {
      this.stopLoading()
      if (err) {
        alert('There was an error when trying to get report.')
      } else {
        alert('Report processing, ID = ' + res.data.id)
        //window.location = res.data.url
      }
    })
  }

  checkboxOnChange(e, data) {
    this.setState({onlyInSeason: data.checked})
  }

  checkboxOnChange2(e, data) {
    this.setState({onlySubCategoryByBrand: data.checked, onlyLarge: false})
  }

  onlyLargeOnChange(e, data) {
    this.setState({onlyLarge: data.checked, onlySubCategoryByBrand: false})
  }

  render() {
    let {
      brandOptions, brand, divisionOptions, division, categoryOptions,
      subCategoryOptions, applicableSubCategoryOptions, category, subCategory,
      year, loading, time, orderType, salesUserOptions, user, account, accountOptions, reportType, summaryPage,
      inventoryType, onlyInSeason, onlyLarge, inSeason, correctPin, onlySubCategoryByBrand, inventoryTypeForSales
    } = this.state

    if (!correctPin) {
      return null
    }

    const isInventory = reportType == 'Inventory'


    const userDropdown = (
      <span style={{width: '250px', marginLeft: '15px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
        <Dropdown disabled={salesUserOptions.length == 0} loading={salesUserOptions.length == 0} placeholder='Sales User - ALL' fluid selection multiple search options={salesUserOptions} onChange={this.fieldOnChange} name='user' value={user}/>
      </span>
    )

    const accountDropdown = (
      <span style={{width: '250px', marginLeft: '285px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
        <Dropdown disabled={accountOptions.length == 0} loading={accountOptions.length == 0} placeholder='Accounts - ALL' fluid selection multiple search options={accountOptions} onChange={this.fieldOnChange} name='account' value={account}/>
      </span>
    )

    const summaryPageDropdown = (
      <span style={{width: '150px', marginLeft: '15px', marginBottom: '15px', display: 'inline-block', position: 'relative', bottom: '10px'}}>
        <Dropdown placeholder='Summary Page' fluid selection search options={summaryPageOptions} onChange={this.fieldOnChange} name='summaryPage' value={summaryPage}/>
      </span>
    )

    const yearAndTimeInputs = (
      <React.Fragment>
        <span style={{width: '220px', marginLeft: '15px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
          <Dropdown placeholder='Year' fluid multiple selection search options={yearOptions} onChange={this.fieldOnChange} name='year' value={year}/>
        </span>

        <span style={{width: '150px', marginLeft: '15px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
          <Dropdown placeholder='Time' fluid selection search options={timeOptions} onChange={this.fieldOnChange} name='time' value={time}/>
        </span>
      </React.Fragment>
    )

    const subCategoryByBrandCheckbox = (
      <span style={{marginLeft: '50px', verticalAlign: 'top'}}>
        <Checkbox toggle label='Only show Sub-Category by Brand pages' checked={onlySubCategoryByBrand} onChange={this.checkboxOnChange2}/>
      </span>
    )

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>
        {loading ?
          <Dimmer active>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
        : null}

        <h1 style={{marginTop: '25px', marginBottom: '35px'}}>Reports</h1>

        <h3 style={{opacity: 0.6, fontWeight: 500, marginBottom: '55px'}}>Generated from Quickbooks data + PENDING ERP data</h3>
        {
          isInventory
          ?
          <label style={labelStyle}>{inventoryType} report </label>
          :
          <label style={labelStyle}>{time} report {year.join(', ')}</label>
        }

        <br/><br/><br/>

        <span style={{width: '150px', marginLeft: '285px', marginBottom: '15px', display: 'inline-block', position: 'relative', bottom: '10px'}}>
          <Dropdown placeholder='Report Type' fluid selection search options={reportTypeOptions} onChange={this.fieldOnChange} name='reportType' value={reportType}/>
        </span>

        {
          isInventory
          ?
          <React.Fragment>
            <span style={{width: '200px', marginLeft: '15px', display: 'inline-block', position: 'relative', bottom: '10px'}}>
              <Dropdown placeholder='Inventory Type' fluid selection search options={inventoryTypeOptions} onChange={this.fieldOnChange} name='inventoryType' value={inventoryType}/>
            </span>

            {inventoryType == 'ATS' ?
              <React.Fragment>
                  <small style={{position:'relative', display: 'inline-block', bottom: '42px', width: '0px', left: '16px', opacity: 0.5}}>
                    For Age Columns
                  </small>
                {summaryPageDropdown}
              </React.Fragment>
            : null}
            {
              ['ATS + Future ATS', 'ATS', 'Future ATS'].includes(inventoryType)
              ?
              subCategoryByBrandCheckbox
              :
              null
            }
          </React.Fragment>
          :
          <React.Fragment>
            {subCategoryByBrandCheckbox}
            <span style={{verticalAlign: 'top', display: 'none'}}>
              <Checkbox toggle label='Only show In Season vs Upfront Summary pages' checked={onlyInSeason} onChange={this.checkboxOnChange}/>
            </span>
            <span style={{marginLeft: '50px', verticalAlign: 'top'}}>
              <Checkbox toggle label='Only show 594+ QTY in detail pages' checked={onlyLarge} onChange={this.onlyLargeOnChange}/>
            </span>
          </React.Fragment>
        }

        <br/><br/><br/>

        <span style={{width: '220px', marginLeft: '285px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
          <Dropdown disabled={brandOptions.length == 0} loading={brandOptions.length == 0} placeholder='Brand - ALL' fluid selection multiple search options={brandOptions} onChange={this.fieldOnChange} name='brand' value={brand}/>
        </span>

        <span style={{width: '250px', marginLeft: '15px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
          <Dropdown disabled={divisionOptions.length == 0} loading={divisionOptions.length == 0} placeholder='Division - ALL' fluid selection multiple search options={divisionOptions} onChange={this.fieldOnChange} name='division' value={division}/>
        </span>

        <span style={{width: '250px', marginLeft: '15px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
          <Dropdown disabled={categoryOptions.length == 0} loading={categoryOptions.length == 0} placeholder='Category - ALL' fluid selection multiple search options={categoryOptions} onChange={this.fieldOnChange} name='category' value={category}/>
        </span>

        <span style={{width: '250px', marginLeft: '15px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
          <Dropdown disabled={subCategoryOptions.length == 0} loading={subCategoryOptions.length == 0} placeholder='Sub-Category - ALL' fluid selection multiple search options={category.length == 1 ? applicableSubCategoryOptions : subCategoryOptions} onChange={this.fieldOnChange} name='subCategory' value={subCategory}/>
          <small style={{opacity: 0.5}}>Select 1 category to see applicable sub-categories</small>
        </span>

        <br/><br/><br/>

        {
          isInventory
          ?
          (
            inventoryType == 'RTS'
            ?
            <React.Fragment>
              {accountDropdown}
              {userDropdown}
              <br/><br/><br/>
            </React.Fragment>
            :
            inventoryType == 'Future ATS'
            ?
            <div style={{paddingLeft: '270px', marginBottom: '40px'}}>
              {yearAndTimeInputs}
            </div>
            :
            null
          )
          :
          <React.Fragment>
            <span style={{width: '250px', marginLeft: '285px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
              <Dropdown placeholder='Order Type / Status - ALL' fluid multiple selection search options={orderTypeOptions} onChange={this.fieldOnChange} name='orderType' value={orderType}/>
              <small style={{opacity: 0.5}}>Worksheet, PO only apply to Open Sales Orders</small>
            </span>

            {yearAndTimeInputs}
            {userDropdown}
            {inventoryTypeForSalesDropdown(this.fieldOnChange, inventoryTypeForSales)}

            <br/><br/><br/>

            {accountDropdown}
            {summaryPageDropdown}

            <span style={{width: '250px', marginLeft: '15px', marginBottom: '15px', display: 'inline-block', position: 'relative', bottom: '10px', display: 'none'}}>
              <Dropdown placeholder='In Season vs Upfront - ALL' fluid selection search options={inSeason == null ? inSeasonOptions : inSeasonOptionsWithAll} onChange={this.fieldOnChange} name='inSeason' value={inSeason}/>
            </span>
          </React.Fragment>
        }

        {
          brand == null ? null :
          <Button primary style={{position: 'relative', bottom: '10px', marginLeft: (isInventory ? '285px' : '15px'), paddingLeft: '60px', paddingRight: '60px'}} onClick={this.download}>Add to Saved Reports</Button>
        }

        <div style={{borderBottom: '1px solid rgb(240,240,240)', marginTop: '40px', marginBottom: '5px'}}/>

        <div style={{display: 'none'}}>
          <ZeroPriceReport salesUserOptions={salesUserOptions}
            setLoading={this.setLoading}
            stopLoading={this.stopLoading}
          />
        </div>

        <SavedReports />

      </div>
    )
  }
}


export default ReportsPage
