const isSales = () => {
  return role() == 'sales'
}

const isSalesAssistant = () => {
  return role() == 'sales_assistant'
}

const isInventory = () => {
  return role() == 'inventory'
}

const isProduction = () => {
  return role() == 'production'
}

const isDesign = () => {
  return role() == 'design'
}

const isWarehouse = () => {
  return role() == 'warehouse'
}

const isCustomer = () => {
  return role() == 'customer'
}

const isAdmin = () => {
  return ['admin', 'superadmin', 'finance'].includes(role())
}

const role = () => {
  return sessionStorage.getItem('currentUserRole')
}

export {isSales, isSalesAssistant, isInventory, isProduction, isDesign, isWarehouse, isCustomer, isAdmin}
