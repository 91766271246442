import Utils from '../../modules/utils'

const requestPin = () => {
  Utils.post('admin_pins', {}, (err, res) => {
    if (err) {
      alert('There was an error when trying to create admin pin!')
    } else {
      alert('Check your email for admin pin.')
    }
  })
}

export default requestPin
