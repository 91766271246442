const getSalesOrders = (styleNumber, cadId) => {
  let salesOrders = window.salesOrderDataByStyleNumber[styleNumber.flatUpcase()]
  let pendingSalesOrders = window.salesOrderDataByStyleNumber[cadId]

  return concatOrders(salesOrders, pendingSalesOrders)
}

const getSalesOrdersForCad = (cad) => {
  return getSalesOrders(cad.style_number, cad.id)
}

const getSalesOrdersFilteredHold = (styleNumber, cadId) => {
  let hasSalesOrders = window.salesOrderDataFiltered_Hold[styleNumber.flatUpcase()]
  let hasPendingSalesOrders = window.salesOrderDataFiltered_Hold[cadId]

  return !!hasSalesOrders || !!hasPendingSalesOrders
}

const getSalesOrdersFilteredWorksheet = (styleNumber, cadId) => {
  let hasSalesOrders = window.salesOrderDataFiltered_Worksheet[styleNumber.flatUpcase()]
  let hasPendingSalesOrders = window.salesOrderDataFiltered_Worksheet[cadId]

  return !!hasSalesOrders || !!hasPendingSalesOrders
}

const getSalesOrdersFilteredNoHoldOrWorksheet = (styleNumber, cadId) => {
  let hasSalesOrders = window.salesOrderDataFiltered_NoHoldOrWorksheet[styleNumber.flatUpcase()]
  let hasPendingSalesOrders = window.salesOrderDataFiltered_NoHoldOrWorksheet[cadId]

  return !!hasSalesOrders || !!hasPendingSalesOrders
}

const getSalesOrdersFilteredByStyleNumber = (styleNumber, cadId) => {
  let salesOrders = window.salesOrderDataFilteredByStyleNumber == null ? [] : window.salesOrderDataFilteredByStyleNumber[styleNumber.flatUpcase()]
  let pendingSalesOrders = window.salesOrderDataFilteredByStyleNumber == null ? [] : window.salesOrderDataFilteredByStyleNumber[cadId]

  return concatOrders(salesOrders, pendingSalesOrders)
}

const getSalesOrdersFiltered = (styleNumber, cadId) => {
  let salesOrders = window.salesOrderDataFiltered[styleNumber.flatUpcase()]
  let pendingSalesOrders = window.salesOrderDataFiltered[cadId]

  return concatOrders(salesOrders, pendingSalesOrders)
}

const concatOrders = (salesOrders, pendingSalesOrders) => {
  if (salesOrders == null) salesOrders = []
  if (pendingSalesOrders == null) pendingSalesOrders = []

  salesOrders = salesOrders.concat(pendingSalesOrders)

  if (salesOrders.length == 0) salesOrders = null

  return salesOrders
}

export {getSalesOrders, getSalesOrdersForCad, getSalesOrdersFilteredHold, getSalesOrdersFilteredWorksheet, getSalesOrdersFilteredNoHoldOrWorksheet,
  getSalesOrdersFilteredByStyleNumber, getSalesOrdersFiltered}
