import Utils from '../../modules/utils'

const getFamilyId = (getFamilyIdSuccess) => {
  Utils.get('family_id', (err, res) =>
  {
    if (err) {
      alert('There was an error when trying to get the family id.')
    } else {
      getFamilyIdSuccess(res.data.familyId.toString())
    }
  })
}

export {getFamilyId}
