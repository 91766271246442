import React, {Component} from 'react'
import {Icon, Loader} from 'semantic-ui-react'
import Utils from "../../modules/utils"

const mainDivStyle = {boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5', padding: '10px 20px', borderRadius: '.28571429rem', width: '700px'}

const initialState = {
  containerNumber: null,
  quantity: null,
  cost: null,
  loading: true
}

class ContainerNumberTotals extends Component
{
  constructor(props)
  {
    super(props)
    this.state = initialState

    this.getTotal = this.getTotal.bind(this)
  }

  componentDidMount() {
    setTimeout(this.getTotal, 1000)
  }

  getTotal() {
    let {number, numbers} = this.props

    if (number == '') {
      number = numbers.join('@@@').toUpperCase()
    }

    this.setState(initialState)

    Utils.get('container_number_total/' + number, (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to get the container number total.')
      } else {
        this.setState(
          {
            quantity: res.data.quantity,
            cost: res.data.cost,
            loading: false
          }
        )
      }
    })
  }

  render() {
    let {number, numbers} = this.props

    if (number == '') {
      number = numbers.join(', ').toUpperCase()
    }

    let {quantity, cost, loading} = this.state

    return (
      <div style={mainDivStyle}>
        <div style={{fontWeight: 'bold'}}><Icon name='ship'/> TOTALS FOR CONTAINER # <span style={{borderBottom: '1px solid black'}}>{number}</span></div>

        <div style={{padding: '20px', lineHeight: '1.8rem', textAlign: 'center'}}>
          <div style={{display: 'inline-block', width: '100px', textAlign: 'left'}}>
            Total quantity:<br/>
            Total cost:
          </div>
          <div style={{display: 'inline-block', width: '100px', textAlign: 'right', verticalAlign: 'top'}}>
            {
              loading
              ?
              <Loader active inline size='tiny' style={{marginTop: '20px'}}/>
              :
              <React.Fragment>
                {quantity == null ? null : quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<br/>
                ${cost == null ? null : cost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ContainerNumberTotals
