import React from 'react'
import {Icon, Popup} from 'semantic-ui-react'

const editBestSellersButton = (cad) => {
  return (
    <Popup content='Open edit best sellers popup' trigger={
      <button onClick={() => openBestSellerModal(cad)} style={{
        marginTop: '5px', fontSize: '11px', padding: '2px 9px', cursor: 'pointer',
        background: 'rgb(229,111,33)', borderRadius: '8px', border: '1px solid rgb(229,111,33)',
        color: 'white'
      }}>
        <Icon name="pencil"/> edit best sellers
      </button>
    }/>
  )
}

const openBestSellerModal = (cad) => {
  window.bestSellersCad = cad
  window.dispatchEvent(new Event('openBestSellersModalEvent'))
}

export {editBestSellersButton}
