import traverse     from 'traverse'
import _            from 'lodash'
import moment       from 'moment'
import http         from '../common/http.js'
import $            from 'jquery'

class Utils
{
    static arrayToOptionsHash(list, options={})
    {

        let {normalizeValue, normalizeText, startCaseText} = options;

        if(!Array.isArray(list)) return

        let result = list.map((item) =>
        {
            let displayText;
            if (normalizeText)
            {
              displayText = normalizeText(item)
            }
            else
            {
              displayText = startCaseText === false ? item : _.startCase(item);
            }
            return {key: item, text: displayText, value: normalizeValue ? normalizeValue(item) : item}
        })

        result.sort(function(a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA == "SHOW ALL") {
            return -1;
          }
          if (nameB == "SHOW ALL") {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })

        return result
    }

    static arrayToOptionsHashUnsorted(list, options={})
    {

        let {normalizeValue, normalizeText, startCaseText} = options;

        if(!Array.isArray(list)) return

        let result = list.map((item) =>
        {
            let displayText;
            if (normalizeText)
            {
              displayText = normalizeText(item)
            }
            else
            {
              displayText = startCaseText === false ? item : _.startCase(item);
            }
            return {key: item, text: displayText, value: normalizeValue ? normalizeValue(item) : item}
        })

        return result
    }

    static objectsToOptionsHash(list, key, displayKey, customDisplayMessage=null)
    {
        if(!Array.isArray(list)) return

        return list.map((item) =>
        {
          let display;
          if (customDisplayMessage)
          {
            display = customDisplayMessage(item)
          }
          else
          {
            display = item[displayKey]
          }
          return {key: item[key], text: display, value: item[key]}
        })
    }

    static isInputsValidated(inputs)
    {
        let valid = true

        traverse(inputs).forEach(function(value)
        {
            if(this.key === "valid" && !value)
            {
                valid = false
            }
        })

        return valid
    }

    static setHttpHeaders(options={}, storage=false)
    {
        for (var option in options)
        {
            http.defaults.headers.common[option] = options[option]
        }

        if (storage)
        {
            var heads = JSON.parse(sessionStorage.getItem('headers'))
            heads     = {...heads, ...options}
            sessionStorage.setItem('headers', JSON.stringify(heads))
        }
    }

    static setHeadersFromStorage()
    {
        var heads = JSON.parse(sessionStorage.getItem('headers'))
        Utils.setHttpHeaders(heads)
    }

    static post(url, data, cb=null, customerId=null)
    {
        if (customerId) Utils.setHttpHeaders({CustomerToken: customerId})

        http
            .post(url, data)
            .then((response) =>
            {
                return cb(null, response)
            })
            .catch((error) =>
            {
                return cb(error)
            })
            .finally(() =>
            {
                this.setHeadersFromStorage()
            })
    }

    static get(url, cb=null, customerId=null)
    {
        if (customerId) Utils.setHttpHeaders({CustomerToken: customerId})

        http
            .get(url)
            .then((response) =>
            {
                return cb(null, response)
            })
            .catch((error) =>
            {
                return cb(error)
            })
            .finally(() =>
            {
                this.setHeadersFromStorage()
            })
    }

    static put(url, data, cb=null, customerId=null)
    {
        if (customerId) Utils.setHttpHeaders({CustomerToken: customerId})

        http
            .put(url, data)
            .then((response) =>
            {
                return cb(null, response)
            })
            .catch((error) =>
            {
                return cb(error)
            })
            .finally(() =>
            {
                this.setHeadersFromStorage()
            })
    }

    static uploadFile(url, data, cb=null)
    {
        $.ajax(
        {
          type   : "POST",
          url    : url,
          headers:
          {
            'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
          },
          success: function (data)
          {
            return cb(null, data)
          }.bind(this),
          error: function (error)
          {
            return cb(error, data)
          }.bind(this),
          async: true,
          data: data,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000
        })
    }

    static delete(url, cb=null, customerId=null)
    {
        if (customerId) Utils.setHttpHeaders({CustomerToken: customerId})

        http
            .delete(url)
            .then((response) =>
            {
                return cb(null, response)
            })
            .catch((error) =>
            {
                return cb(error)
            })
            .finally(() =>
            {
                this.setHeadersFromStorage()
            })
    }

    static addOrUpdateArray(arr, data)
    {
        var index = arr.findIndex((e) => e.id === data.id)

        if (index === -1)
        {
            arr.unshift(data)
        }
        else
        {
            arr[index] = data
        }
    }

    static getCurrentAppUser()
    {
        var user = sessionStorage.getItem('user')

        if (user)
        {
            return JSON.parse(user)
        }
        else
        {
            return {}
        }
    }

    static flatUpcase(value)
    {
        return value
            .trim()
            .toUpperCase()
            .replace(/:/g, '/')
            .replace(/\p{Space}/g,' ')
            .replace(/  +/g, ' ')
            .replace(/_/g, '-')
            .replace(/ - /g, '-')
            .replace(/ -/g, '-')
            .replace(/- /g, '-')
    }

    static trim(value)
    {
        return value
            .trim()
            .toUpperCase()
            .replace(/  +/g, ' ')
    }

    static badFileType(file)
    {
        let acceptableFileTypes =
        [
            'image/png',
            'image/jpg',
            'image/jpeg',
            'application/pdf',
            'application/vnd.ms-excel',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ]

        return !acceptableFileTypes.includes(file.type)
    }

    static getDate(days=0)
    {
        var date = new Date()
        return date.setDate(date.getDate() + days)
    }

    static getPreciseAmount(amount)
    {
        amount = Number(parseFloat(amount).toFixed(2))
        return isNaN(amount) ? 0 : amount
    }

    static isValidCurrency(currency)
    {
        return currency > 0
        // more conditions to come
    }

    static isValidDate(date)
    {
        return moment(date).isValid()
    }

    static formatDate(date)
    {
        if (this.isValidDate(date))
        {
            return moment(date).utc().format('M/D/YYYY')
        }

        return null
    }

    static formatDateForSubmit(date)
    {
        if (this.isValidDate(date))
        {
            return moment(date).utc().format('YYYY-M-D')
        }

        return null
    }

    static formatDateTime(dateTime)
    {
      const datetime = new Date(dateTime)
      if (datetime.getHours() === 0 ||  datetime.getHours() > 12) {
        return `${datetime.getMonth()+1}/${datetime.getDate()}/${datetime.getFullYear()} ${Math.abs((datetime.getHours()-12))}:${String(datetime.getMinutes()).length < 2 ? 0+String(datetime.getMinutes()) : String(datetime.getMinutes())} ${datetime.getHours() > 11 ? "PM" : "AM"}`

      } else {
        return `${datetime.getMonth()+1}/${datetime.getDate()}/${datetime.getFullYear()} ${Math.abs(datetime.getHours())}:${String(datetime.getMinutes()).length < 2 ? 0+String(datetime.getMinutes()) : String(datetime.getMinutes())} ${datetime.getHours() > 11 ? "PM" : "AM"}`
      }
      return null
    }

    static formatSimpleDate(date)
    {
        //this is used as a dummy date
        if (date == '2000-01-01') {
            return '—'
        } else if (this.isValidDate(date)) {
            return moment(date).format("M/D/YYYY")
        }
        return '—'
    }

    static formatSimpleDateTime(date)
    {
        if (this.isValidDate(date))
        {
            return moment(date).format('M/D/YYYY h:mm A')
        }

        return null
    }

    static toOptionsHashWithKeys(data, keys, sub=false)
    {
        var optionsHash = []

        data.forEach((item) =>
        {
            var text = keys.text.map((txt) =>
            {
                var tempValue = Object.assign({}, item)
                var splitKeys = txt.split('.')

                splitKeys.forEach((k) =>
                {
                    tempValue = tempValue[k]
                })

                if (typeof tempValue === 'boolean')
                {
                    return (tempValue ? '' : 'Not ').concat(_.startCase(splitKeys[splitKeys.length - 1]))
                }

                return tempValue
            })

            var value = item[keys.value]
            optionsHash.push(
            {
                key  : value,
                value: value,
                text : sub ? `${text[0]}  (${text[1]})` : text.join(' - ')
            })
        })

        return optionsHash
    }

    static readAsArray(subject)
    {
        if (subject === null || subject === undefined)
        {
            return new Array
        }
        else if (!Array.isArray(subject))
        {
            return [subject]
        }

        return subject
    }

    static generateUniqueId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
        });
    }

    static setStore(key, value)
    {
        try
        {
            value = JSON.stringify(value)
        }
        catch(err)
        {
            // do nothing
        }

        window.localStorage && window.localStorage.setItem(key, value)
    }

    static getStore(key)
    {
        try
        {
            return JSON.parse(window.localStorage.getItem(key))
        }
        catch(err)
        {
            // do nothing
        }
    }

    static directionConverter(order, short=false) {
      if (order)
      {
        if (order.includes('asc')) {
          if (short) return 'asc'
            return 'ascending';
        } else if (order.includes('desc')) {
          if (short) return 'desc'
            return 'descending';
        } else {
          return null;
        }
      }
      else return null;
    }
}

export default Utils
