import React, {Component} from 'react'
import {Dropdown, Button, Accordion, Icon} from 'semantic-ui-react'
import {labelStyle} from "../edit-sales-order/Elements"
import Utils from "../../modules/utils"
import {formatDate} from '../cads/format-date.js'

class SavedReports extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      active: false,
      reports: [],
      refreshing: false
    }
  }

  componentDidMount() {
    this.getSavedReports()
  }

  toggleAccordion = () => {
    let active = !this.state.active
    this.setState({active})
  }

  getSavedReports() {
    const url = 'admin_reports'
    Utils.get(url, (err, res) => {
      if (err) {
        alert('There was an error when trying to get saved reports')
      } else {
        let reports = []
        if (res.data != null) {
          reports = res.data.reports
        }
        this.setState({reports, refreshing: false})
      }
    })
  }

  refresh = () => {
    this.setState({refreshing: true})
    this.getSavedReports()
  }

  render() {
    let {active, reports, refreshing} = this.state
    return (
      <div style={{paddingTop: '60px'}}>
        <label style={labelStyle}>
          Saved Reports <Icon onClick={this.refresh} name='refresh' style={{cursor: 'pointer', marginLeft: '30px'}} color='orange'/>
        </label>

        <Accordion style={{marginTop: '20px'}}>
          <Accordion.Title onClick={this.toggleAccordion}>
            <Icon name='dropdown' style={active ? {rotate: '90deg'} : {}}/>
            Click to {active ? 'Collapse' : 'Expand'} List
          </Accordion.Title>
          <Accordion.Content active={active}>
            <ul style={refreshing ? {opacity: 0.2} : {}}>
              {
                reports.length == 0
                ?
                <div style={{opacity: 0.5}}>(No reports saved yet)</div>
                :
                reports.map((report) => {
                  return (
                    <li style={{marginBottom: '50px'}}>
                      <pre>ID={report.id} &nbsp; CREATED={formatDate(report.createdAt.split('T')[0])} &nbsp; DOWNLOAD URL={report.url == null ? '(still processing)' : <a href={report.url}>link</a>}
                      <br/><div style={{overflowX: 'scroll'}}>OPTIONS={JSON.stringify(report.data)}</div></pre>
                    </li>
                  )
                })
              }
            </ul>
          </Accordion.Content>
        </Accordion>
      </div>
    )
  }
}

export default SavedReports
