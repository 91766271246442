import Utils from '../../modules/utils'

const getProperties = (token, callbackFunc) => {
  let url = 'properties'

  if (token != null) {
    url = 'share_properties?token=' + token
  }

  Utils.get(url, callbackFunc)
}

const getProperty = (token, id, callbackFunc) => {
  let url = 'properties/' + id

  if (token != null) {
    url = 'share_properties/' + id + '?token=' + token
  }

  Utils.get(url, callbackFunc)
}

export {getProperties, getProperty}
