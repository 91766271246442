import React from 'react'
import {Button} from 'semantic-ui-react'

const moreResults = (sliceNum, numResults, loadMore) => {
  return (
    sliceNum < numResults
    ?
    <div style={{textAlign: 'center', marginTop: '20px'}}>
      <Button style={{width: '50vw'}} onClick={loadMore}>More results ({numResults - sliceNum} left)</Button>
    </div>
    :
    null
  )
}

export {moreResults}
