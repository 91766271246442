import React, {Component} from 'react'
import {Icon} from 'semantic-ui-react'

import {createdAtToAmPm} from './created-at-to-am-pm.js'

const thStyle = {paddingLeft: '20px'}

const formatDate = (value) => {
  if (value == null || value == '') {
    return null
  } else {
    let date = new Date(value)
    date.setHours(date.getHours() + 12)
    return date.toLocaleDateString()
  }
}

const reducer = (accumulator, currentValue) => accumulator + currentValue;

class QuantityChangeLogs extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {logs, orders} = this.props

    return (
      <div style={{marginTop: '20px'}}>
        <b>Recent ERP quantity changes:</b><br />
        <table style={{border: '1px solid black'}}>
          <tr>
            <th>REF#</th>
            <th style={thStyle}>PO#</th>
            <th style={thStyle}>Old QTY</th>
            <th style={thStyle}>New QTY</th>
            <th style={thStyle}>Diff</th>
            <th style={thStyle}>Date</th>
            <th style={thStyle}>Time</th>
            <th style={thStyle}>User</th>
            <th style={thStyle}>LOG#</th>
          </tr>

          {logs.map((log, index) => {

            let oldValue = JSON.parse(log.oldValue).reduce(reducer)
            let newValue = JSON.parse(log.newValue).reduce(reducer)
            let change = newValue - oldValue

            if (change > 0) {
              change = '+'+change
            }

            let order = orders[index]

            return (
              <tr>
                <td>{order.refnumber} {order.flagged ? <Icon name='flag' style={{color: 'red'}}/> : null}</td>
                <td style={thStyle}>{order.ponumber}</td>
                <td style={thStyle}>{oldValue}</td>
                <td style={thStyle}>{newValue}</td>
                <td style={thStyle}>{change}</td>
                <td style={thStyle}>{log.dateString.split(' ')[0]}</td>
                <td style={thStyle}>{createdAtToAmPm(log.createdAt)}</td>
                <td style={thStyle}>{log.userName.split('@')[0]}</td>
                <td style={thStyle}>L{log.id < 999 ? '0' : ''}{log.id}</td>

              </tr>
            )
          })}

          {logs.length == 0 ?
            <tr><td></td><td></td><td>NONE FOUND</td></tr>
          : null}

        </table>
      </div>
    )
  }
}

export default QuantityChangeLogs
