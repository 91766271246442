import React from 'react'
import {Form} from 'semantic-ui-react'

const casePackInput = (casePackOnChange, casePack, isDesign) => {
  return (
    <div className={isDesign ? 'percent80-width-desktop' : 'percent50-width-desktop'} style={{marginBottom: '15px', marginTop: '15px'}}>
      <Form.Input fluid label='Case Pack' type="number" min="1" max="999" step="1" onChange={casePackOnChange} placeholder='Case Pack' value={casePack} />
    </div>
  )
}

export {casePackInput}
