import React, {Component} from 'react'
import {Popup, Button, Icon} from 'semantic-ui-react'
import SwapCadForm from './swap-cad-form.js'

class SwapCadPopup extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {isEditPage, swapCad, cadKey, index} = this.props

    if (isEditPage) {
      return (
        <Popup
          size='mini'
          trigger={
            <Button size='mini' color='orange'><Icon name='exchange'/> Swap</Button>
          }
          header="Swap cad"
          content={
            <SwapCadForm swapCad={swapCad} cadKey={cadKey} index={index}/>
          }
          on='click'
          position='top left'
        />

      )
    } else {
      return null
    }
  }
}

export default SwapCadPopup
