import React, { Component } from 'react'
import {Dimmer, Loader} from 'semantic-ui-react'
import LongStyleNumbersTextArea from './components/long-style-numbers/textarea.js'
import {getCadsCache} from './components/cads/cache/get'
import {Auth0Context} from './contexts/auth-context'

class LongStyleNumbersPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      cadsHash: {}
    }

    this.cadsSuccessCallback = this.cadsSuccessCallback.bind(this)
    this.getCustomer = this.getCustomer.bind(this)
  }

  componentDidMount() {
    this.getCustomer()
  }

  getCustomer() {
    let {customer} = this.context
    if (customer == null) {
      setTimeout(this.getCustomer, 500)
    } else {
      getCadsCache(customer.id, this.cadsSuccessCallback)
    }
  }

  cadsSuccessCallback(cads) {
    let newState = {loading: false}
    let cadHash = {}

    cads.forEach(cad => cadHash[cad.style_number] = cad.long_style_number)

    newState['cadsHash'] = cadHash
    this.setState(newState)
  }

  render() {
    let {loading, cadsHash} = this.state

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>
        {
          loading
          ?
          <Dimmer active>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          :
          <React.Fragment>
            <h1 style={{marginTop: '25px', marginBottom: '35px'}}>Get long style numbers</h1>
            <LongStyleNumbersTextArea cadsHash={cadsHash} />
          </React.Fragment>
        }
      </div>
    )
  }
}


export default LongStyleNumbersPage
