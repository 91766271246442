const createThumb = (file, formData, thumbCallback) => {

  const reader = new FileReader()

  reader.onload = (e) => {

    let tempImg = new Image()
    tempImg.src = reader.result
    tempImg.onload = (d) => {

      let canvas = document.createElement('canvas')
      //document.body.appendChild(canvas)

      let thumbWidth, thumbHeight

      if (tempImg.height > tempImg.width) {
        thumbHeight = 400
        thumbWidth = thumbHeight / tempImg.height * tempImg.width
      } else {
        thumbWidth = 400
        thumbHeight = thumbWidth / tempImg.width * tempImg.height
      }

      canvas.width = thumbWidth
      canvas.height = thumbHeight

      var ctx = canvas.getContext("2d")
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, thumbWidth, thumbHeight)
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.drawImage(tempImg, 0, 0, thumbWidth, thumbHeight)
      ctx.setTransform(1, 0, 0, 1, 0, 0)

      canvas.toBlob((blob) => {
        formData.append('thumb', blob)
        thumbCallback()
      })
    }
  }

  reader.readAsDataURL(file)
}



export default createThumb
