import React, {Component} from 'react'
import {Input, Button, Icon} from 'semantic-ui-react'
import Utils from "../../modules/utils"
import $ from 'jquery'

class SwapCadForm extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      loading: false
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(e) {
    e.preventDefault()
    let {swapCad, cadKey, index} = this.props

    let styleNumber = $('#swap-cad-input').val().flatUpcase()
    this.setState({loading: true})

    Utils.post('check_if_valid_style_number', { styleNumber }, (err, result) => {
      this.setState({loading: false})
      if (err) {
        console.log('There was an error when trying to check if style number is valid.')
      } else {
        if (result.data.valid) {
          let cad = JSON.parse(result.data.cad).data.attributes
          swapCad(cad, cadKey, index)
        } else {
          alert('Error: cannot find cad!')
        }
      }
    })
  }

  render() {
    let {loading} = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <Input
          id='swap-cad-input'
          action={
            <Button type="submit" loading={loading} disabled={loading} color="orange">Swap cad</Button>
          }
          placeholder='Enter style number'
          style={{width: '400px', marginTop: '10px', marginBottom: '10px'}}
          required
        />
      </form>
    )
  }
}

export default SwapCadForm
