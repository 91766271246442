import React, {Component} from 'react'
import {
          Form,
          Checkbox,
          Grid,
          Loader,
          Dimmer,
          Button,
          Header,
          Icon,
          Divider,
          Label,
          Message
       }                  from 'semantic-ui-react'
import Utils              from './../../../modules/utils'
import PurchaseOrderAPI   from './../../../modules/api/purchase_order_api'
import {LoadingParagraph} from './../../ui/loading-paragraph'
import DateString         from './../../shared/custom-date-string'


class OfficeEditPOForm extends Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading      : true,
      cLoading     : false,
      purchaseOrder: {}
    }
  }

  componentDidMount()
  {
    var {purchaseOrder} = this.props
    if (purchaseOrder && purchaseOrder.id)
    {
      this.fetchPO(purchaseOrder.id)
    }
  }

  fetchPO = (poId) =>
  {
    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        this.setState({
          purchaseOrder: data.purchaseOrder,
          loading      : false
        })
      }
    }

    PurchaseOrderAPI.get(poId, callback)
  }

  savePO = (event) =>
  {
    this.setState({ cLoading: true })
    var {purchaseOrder} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
        this.setState({ cLoading: false })
      }
      else
      {
        this.props.closeModal(event)
      }
    }

    if (purchaseOrder && purchaseOrder.id)
    {
      purchaseOrder.purchaseOrderStatusesAttributes = purchaseOrder.poStatuses
      PurchaseOrderAPI.update(purchaseOrder.id, {purchaseOrder: purchaseOrder}, callback)
    }
  }

  onChangeGrossAmount = (event, {value}) =>
  {
    var {purchaseOrder}       = this.state
    purchaseOrder.grossAmount = Utils.getPreciseAmount(value)

    this.setState({ purchaseOrder })
  }

  onChange = (e, data) =>
  {
    var {purchaseOrder} = this.state
    var status = purchaseOrder.poStatuses.find(s => s.id == data.statusId)

    switch(data.name)
    {
      case "approved":
      {
        status.value = Boolean(data.value)
        if (!status.value) status.notes = ""
        break
      }
      case "notes":
      {
        status.notes = data.value
        status.value = Boolean(data.value)
        break
      }
      default:
      {

      }
    }

    this.setState({ purchaseOrder })
  }

  render()
  {
    var {purchaseOrder} = this.state
    var space = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    var printDate = (date) => {
      if (date == null) {
        return '—'
      } else {
        return new Date(date).toLocaleDateString()
      }

    }

    var markup =
      <div style={{padding: '50px 60px'}}>
        {
          this.state.loading ?
          <LoadingParagraph />
          :
          <Form onSubmit={this.savePO} loading={this.state.cLoading}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <p>
                <b className="text-underline">Created:</b> {printDate(purchaseOrder.createdAt)}
              </p>
              <p style={{display: 'none'}}>
                <b className="text-underline">order:</b> {printDate(purchaseOrder.orderDate)}
              </p>
              <p>
                <b className="text-underline">Start ship:</b> {printDate(purchaseOrder.startShippingDate)}
              </p>
              <p style={{minWidth: '200px'}}>
                <b className="text-underline">Cancel:</b> {printDate(purchaseOrder.cancelDate)}
              </p>
            </div>
            <br/>

            <Grid columns={2} className='form'>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    readOnly
                    type="text"
                    fluid
                    label='Account name'
                    placeholder='account name'
                    value={purchaseOrder.account.name}
                    style={{pointerEvents: 'none', opacity: 0.6}}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    readOnly
                    type="text"
                    fluid
                    label='PO #'
                    placeholder='xyz'
                    value={purchaseOrder.ponumber}
                    style={{pointerEvents: 'none', opacity: 0.6}}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    id="gross-amount"
                    readOnly={true}
                    type="number"
                    step="any"
                    fluid
                    label='Total cost'
                    icon='dollar sign'
                    iconPosition='left'
                    placeholder='0.00'
                    value={Number(purchaseOrder.grossAmount).toString()}
                    style={{pointerEvents: 'none', opacity: 0.6}}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <br/>
            <Divider horizontal>PO Status</Divider>

            <div style={{marginTop: '20px'}}>
              {
                purchaseOrder.poStatuses.map((status, index) => (
                  <Grid key={index} columns={2}>
                    <Grid.Row style={{paddingBottom: '0'}}>
                      <Grid.Column width={4} style={{display: 'flex'}}>
                        <Form.Group inline>
                          <Checkbox
                            toggle
                            label={status.name.replace(/-/g, ' ')}
                            readOnly={!!status.statusUpdatedAt}
                            checked={status.value}
                            name="approved"
                            onChange={(e, {value}) => this.onChange(e, {name: "approved", statusId: status.id, value: value})}
                          />
                        </Form.Group>
                      </Grid.Column>
                      <Grid.Column width={12}>
                      {
                        !status.statusUpdatedAt ?
                        <Form.Group>
                          <Form.Input
                            width={16}
                            type="text"
                            name="notes"
                            placeholder={status.name == 'Routed' ? 'Enter routing number' : 'Enter note'}
                            onChange={(e, {value}) => this.onChange(e, {name: "notes", statusId: status.id, value: value})}
                            value={status.notes}
                          />
                        </Form.Group>
                        :
                        <Message size='tiny'>
                          <Message.Header>
                            <span>
                              {status.statusUpdatedBy == null ? 'ERP' : status.statusUpdatedBy} @ <DateString dateString={status.statusUpdatedAt} />
                            </span>
                          </Message.Header>
                          <Message.Content>
                            <p style={{wordBreak: 'break-all'}} dangerouslySetInnerHTML={{__html: status.notes}}>
                            </p>
                          </Message.Content>
                        </Message>
                      }
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ))
              }
              <br/>
              <Divider />
              <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <Button
                  primary
                  content="Save"
                />
                <Button
                  basic
                  onClick={this.props.closeModal}
                  content="Close"
                />
              </div>
            </div>
          </Form>
        }
      </div>

    return markup;
  }
}

export default OfficeEditPOForm
