import {getSalesOrdersForCad} from './sales-orders/get'

const notPurchasedFilter = (cad, myProps) => {
  // if there is a shipped or sales order then its NOT NOT PURCHASED

  //see if there is a shipped order
  let hasShippedOrder = false
  if (!!window.salesAccountDataByStyleNumber) {
    let invoices = window.salesAccountDataByStyleNumber[cad.style_number.flatUpcase()]

    if (myProps.cadReducer.defaultAccount != null) {
      if (!!invoices) {
        for (var i=0; i<invoices.length; i++) {
          if (myProps.cadReducer.defaultAccount == invoices[i].account_name) {
            hasShippedOrder = true
            break
          }
        }
      }
    } else {
      hasShippedOrder = !!invoices
    }
  }


  //see if there is a sales order, dont need to check if there is a shipped order
  let hasSalesOrder = false
  if (!!window.salesOrderDataByStyleNumber && !hasShippedOrder) {
    let invoices2 = getSalesOrdersForCad(cad)

    if (myProps.cadReducer.defaultAccount != null) {
      if (!!invoices2) {
        for (var i=0; i<invoices2.length; i++) {
          if (!invoices2[i].hold && myProps.cadReducer.defaultAccount == invoices2[i].account_name) {
            hasSalesOrder = true
            break
          }
        }
      }
    } else {
      hasSalesOrder = !!invoices2
    }
  }

  let purchased = hasShippedOrder || hasSalesOrder

  return !purchased
}


export {notPurchasedFilter}
