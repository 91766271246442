import React, {Component} from 'react'
import {Image, Icon, Input, Button, Grid, Popup} from 'semantic-ui-react'
import Utils from "../../../modules/utils"

class QbAccountName extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      savedName: null,
      editing: false
    }

    this.edit = this.edit.bind(this)
    this.stopEdit = this.stopEdit.bind(this)
    this.save = this.save.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
  }

  edit() {
    this.setState({editing: true})
  }

  stopEdit() {
    this.setState({editing: false})
  }

  save() {
    const {account} = this.props

    let savedName = document.getElementById('new-qb-name').value

    let data = {
      contact_id: account.id,
      quickbooks_account_name: savedName
    }

    Utils.post('update_qb_name', data, (err, res) =>
    {
      if (err) {
        console.log('There was an error when trying to update the qb name.')
      } else {
        this.setState({savedName})
        this.stopEdit()
      }
    })
  }

  onChangeInput() {
    clearTimeout(window.setTimeoutNormalize)
    window.setTimeoutNormalize = setTimeout(this.normalize, 500)
  }

  normalize() {
    let value = document.getElementById('new-qb-name').value

    value = value.toUpperCase().replace(/\p{Space}/g,' ').replace(/  +/g, ' ').trim()

    document.getElementById('new-qb-name').value = value
  }

  render() {
    const {account} = this.props
    const {editing, savedName} = this.state

    let defaultValue = (savedName == null ? account.quickbooksAccountName : savedName)

    const SaveConfirmTrigger = <Icon name='check' style={{color: 'rgb(213,168,60)', cursor: 'pointer'}} />

    const SaveConfirm = (
      <Popup wide trigger={SaveConfirmTrigger} on='click'>
        <Button color='blue' content='Confirm save' fluid onClick={this.save}/>
      </Popup>
    )

    return (
      <React.Fragment>
        {
          account.spsTradingPartnerId != null
          ?
          <div style={{marginTop: '20px'}}>
            <Image src='https://erp-rails-usa-production.s3.amazonaws.com/images/sps_logo.svg' avatar />
            <span style={{color: 'rgb(200, 200, 200)', fontWeight: 'bold'}}>trading partner name:</span>
            &nbsp;&nbsp;
            {account.spsTradingPartnerId}
          </div>
          :
          null
        }
        <div style={{marginTop: '20px'}}>
          <Image src='https://erp-rails-usa-production.s3.amazonaws.com/images/qb_logo.png' avatar />
          <span style={{color: 'rgb(200,200,200)', fontWeight: 'bold'}}>name:</span>
          &nbsp;&nbsp;
          {
            editing
            ?
            <React.Fragment>
              <Input id="new-qb-name" style={{width: '60%'}} placeholder='QB name' defaultValue={defaultValue} onChange={this.onChangeInput}/>
              &nbsp;&nbsp;
              {SaveConfirm}
              &nbsp;&nbsp;
              <Icon name='close' style={{color: 'red', cursor: 'pointer'}} onClick={this.stopEdit}/>
            </React.Fragment>
            :
            <React.Fragment>
              <Input defaultValue={defaultValue} placeholder='(missing)' style={{width: '60%'}} disabled/>
              &nbsp;&nbsp;
              <Icon name='pencil' style={{color: 'rgb(213,168,60)', cursor: 'pointer'}} onClick={this.edit}/>
            </React.Fragment>
          }

        </div>
      </React.Fragment>
    )
  }
}

export default QbAccountName
