import React from 'react'
import {findPrint, printStyleNumber} from '../cads/prints/find'

const nullCheck = (value, fieldName = '', isPrice = false) => {
  return (['', null].includes(value) ? fieldName+'??' : (isPrice ? '$' : '')+value)
}

const getMeta = (cadReducer, cad) => {
  const meta = []
  let {brand, department, parent_category, category, color, fabrication, packs, msrp, sizes} = cad
  let {showBrand, showDivision, showCategory, showSubCategory, showColor, showFabrication, showPacks, showMSRP, showSizeBreakdown} = cadReducer

  if (showBrand) {
    meta.push(nullCheck(brand, 'BRAND'))
  }

  if (showDivision) {
    meta.push(nullCheck(department, 'DIVISION'))
  }

  if (showCategory) {
    meta.push(nullCheck(parent_category, 'CATEGORY'))
  }

  if (showSubCategory) {
    meta.push(nullCheck(category, 'SUBCATEGORY'))
  }

  if (showColor) {
    meta.push(nullCheck(color, 'COLOR'))
  }

  if (showFabrication) {
    meta.push(nullCheck(fabrication, 'FABRICATION'))
  }

  if (showPacks) {
    meta.push(nullCheck(packs, 'PACKS'))
  }

  if (showMSRP) {
    meta.push(nullCheck(msrp, 'MSRP', true))
  }

  if (showSizeBreakdown) {
    meta.push(nullCheck(sizes, 'SIZE BREAKDOWN'))
  }

  if (findPrint(cad.id)) {
    meta.push(printStyleNumber(cad.id))
  }


  //if (cad.category != null) { meta += cad.category; }
  //
  // if (cadReducer.showColor && cad.color != null) {
  //   let color = cad.color;
  //   if (color[0] == '[') {
  //     color = JSON.parse(color).join(', ')
  //   }
  //
  //   meta += ' — ' + color;
  // }
  // if (cadReducer.showFabrication && cad.fabrication != null) { meta += ' — ' + cad.fabrication; }
  // if (cadReducer.showSeason && cad.season != null && cad.season != 'N/A') { meta += ' — ' + cad.season; }
  //
  // if (cadReducer.showSizeBreakdown && cad.sizes != null && cad.sizes != '') {
  //   meta = <span className="meta">{meta} {meta == '' ? null : <br/>} {cad.sizes}</span>;
  // }


  return meta.join(' — ')
}

export default getMeta
