import React, {Component} from 'react'
import {Card, Icon, Image, Label} from 'semantic-ui-react'
import {imageClickPrint} from '../../components/cads/fullscreen-cadviewer.js'
import {cardHeader} from './card/header'
import {deleteButton} from './card/delete'

class PrintCard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  categoryIcon(category) {
    if (category == 'SLEEP / LOUNGE') {
      return <Icon name='bed' />
    } else if (category == 'BASICS / INTIMATES') {
      return <Icon name='heart' />
    } else {
      return null
    }
  }

  seasonIcon(season) {
    if (season == null) {
      return null
    } else if (season.includes('FALL')) {
      return <Icon name='leaf' />
    } else if (season.includes('SUMMER')) {
      return <Icon name='sun' />
    } else if (season.includes('HOLIDAY')) {
      return <Icon name='snowflake' />
    } else if (season.includes('SPRING')) {
      return <Icon name='tree' />
    } {
      return null
    }
  }

  colorLabel(color) {
    if (color == 'WHITE') {
      return <Label basic>{color}</Label>
    } else {
      return <Label color={color.toLowerCase()}>{color}</Label>
    }
  }

  printTypeIcon(styleNumber) {
    return (
      styleNumber[0] == 'G'
      ?
      <React.Fragment><Label><Icon name='image' />GRAPHICS</Label>&nbsp;</React.Fragment>
      :
      <React.Fragment><Label><Icon name='paint brush' />PRINT</Label>&nbsp;</React.Fragment>
    )
  }

  render() {
    let {print, cadTotal, index} = this.props
    let {filename, styleNumber, createdAt, updatedAt, id} = print

    const date = new Date(createdAt)

    let imageUrl = 'https://s3.amazonaws.com/' + process.env.REACT_APP_S3_BUCKET_NAME + '/cad_thumb/' + filename.split('/')[0] + '/thumb.jpg'

    return (
      <Card style={print.is_deleted ? {opacity: 0.3, pointerEvents: 'none'} : {}}>
        <div style={{height: 0, zIndex: 9, paddingLeft: '4px', opacity: 0.2}}>{index+1}</div>
        <Image src={imageUrl} wrapped ui={false} onClick={imageClickPrint.bind(null, imageUrl)}/>
        <Card.Content>
          {cardHeader(styleNumber, id, this.props.updateNumSelected, print.is_deleted)}
          <Card.Meta>
            <span className='date'>Created &nbsp; {date.toLocaleDateString()} &nbsp; {date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</span>
          </Card.Meta>
          <Card.Description style={{whiteSpace: 'pre-wrap', lineHeight: '33px'}}>
            {this.printTypeIcon(styleNumber)}
            {
              print.color == null
              ?
              null
              :
              this.colorLabel(print.color)
            }
            &nbsp;
            {print.pattern == null ? null : <Label><Icon name='grid layout' />{print.pattern}</Label>}
            &nbsp;
            {print.season == null ? null : <Label>{this.seasonIcon(print.season)}{print.season}</Label>}


          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {
            cadTotal == null
            ?
            <React.Fragment>&nbsp;</React.Fragment>
            :
            <span style={{fontSize: '120%'}}>
              <Icon name='cube' />
              {cadTotal} Linked Cad{cadTotal == 1 ? '' : 's'}
            </span>
          }

          {print.is_deleted ? null : deleteButton(styleNumber, id, this.props.markPrintAsDeleted)}
        </Card.Content>
      </Card>
    )
  }
}

export default PrintCard
