import React, {Component} from 'react'
import {Icon, Input, Button, TextArea, Transition} from 'semantic-ui-react'
import $ from 'jquery'
import DisplayStyleNumberStatus from '../new-vpo-page/select-cads/display-style-number-status.js'
import {resizeTextarea} from '../new-vpo-page/select-cads/resize-textarea.js'

const textareaStyle = {width: '620px', minWidth: '620px', padding: '10px', height: '100px', minHeight: '100px', maxWidth: '620px', border: '1px solid rgb(212,212,212)', lineHeight: '1.4em'}

const connectingLineStyle = {
  display: 'inline-block',
  borderTop: '2px solid black',
  width: '20px',
  marginTop: '23px',
  verticalAlign: 'top',
  marginLeft: '5px',
  marginRight: '5px'
}

const connectingLineStyle2 = Object.assign({}, connectingLineStyle, {marginTop: '93px'})

class SearchInput extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}

    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.toggleBulk = this.toggleBulk.bind(this)
  }

  handleSearchChange(e, {value}) {
    let {showBulk} = this.props
    clearTimeout(window.searchStringTimeout)
    window.searchStringTimeout = setTimeout(() => {
      if (this.props.keepSpacesAndCommas) {
        value = value.trim().toUpperCase().split('.').join('').replace(/,/g, ' ').replace(/\t/g, ' ').replace(/\p{Space}/g,' ').replace(/  +/g, ' ').replace(/\n\s*\n/g, '\n')
      } else {
        value = value.trim().toUpperCase().split('.').join('').replace(/,/g, ' ').replace(/\t/g, ' ').replace(/\p{Space}/g,' ').replace(/  +/g, ' ').replace(/ /g, "\n").replace(/\n\s*\n/g, '\n')
      }
      this.props.updateSearchString(value)
      $('#vpo-bulk-search').val(value)
      if (showBulk) resizeTextarea('vpo-bulk-search')
    }, 1500)
  }

  toggleBulk() {
    this.props.toggleBulk()
    $('#vpo-bulk-search').val('')
    $('#vpo-single-search').val('')
    this.props.updateSearchString('')
  }

  render() {
    let {showBulk, statuses, placeholder} = this.props
    return (
      <div>
        <Transition visible={showBulk} animation='fade' duration={showBulk ? 1000 : 200}>
        <div>
          {
            showBulk
            ?
            <React.Fragment>
              <DisplayStyleNumberStatus statuses={statuses}/>
              <div style={{display: 'inline-block'}}>
                <TextArea id="vpo-bulk-search" style={textareaStyle} placeholder={placeholder} onChange={this.handleSearchChange}/>
              </div>

            </React.Fragment>
            :
            null
          }
          </div>
        </Transition>
        <Transition visible={!showBulk} animation='fade' duration={showBulk ? 200 : 1000}>
          <div>
            {
              showBulk
              ?
              null
              :
              <React.Fragment>
                <Input id="vpo-single-search" size='big' icon='search' placeholder='Search: REF # , PO # or Style #' style={{width: '33%'}}
                  onChange={this.handleSearchChange}
                />
                <div style={connectingLineStyle} />
                <Button icon size='mini' primary={showBulk} onClick={this.toggleBulk} style={{verticalAlign: 'top', marginTop: '10px'}}>
                  <Icon name='search' /> Bulk
                </Button>
              </React.Fragment>
            }
          </div>
        </Transition>
      </div>
    )
  }
}

export default SearchInput
