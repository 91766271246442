import React from 'react'
import {Icon, Popup} from 'semantic-ui-react'

const editButton = (id) => {
  return (
    <div style={{float: 'right'}}>
      <Popup content='Edit property' position='bottom left'
        trigger={
          <Icon name='pencil alternate' style={{color: 'rgb(63,133,202)', cursor: 'pointer'}}
            onClick={() => {editProperty(id)}}
          />
        }
      />
    </div>
  )
}

const editProperty = (id) => {
  window.open('/#/property/' + id)
}

export {editButton}
