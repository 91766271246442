import React                                 from 'react';
import PropTypes                             from 'prop-types';
import { Table, Pagination, Grid, Container }from 'semantic-ui-react';
import { DataPageSizeSelect }                from './DataPageSizeSelect.jsx';
import { DataSortSelect }                    from './DataSortSelect.jsx';
import { DataRow }                           from './DataRow.jsx';
import { DataTableHeader }                   from './DataTableHeader.jsx';
import Utils                                 from '../../../../modules/utils'

export const DataTable = props => {
  if (!props.columns) {
    return <React.Fragment />;
  }
  const dataRows = props.columns.map((data, index) => (
    <DataRow key={index} column={data} headings={props.headings}/>
  ));
  return (
    <React.Fragment>
      <Grid>
        <Grid.Column floated='left' width={5}>
        </Grid.Column>
        <Grid.Column floated='right' width={5}>
          <Container textAlign='right'>
            <DataPageSizeSelect
              limit={props.limit}
              onChangeLimit={props.onChangeLimit}
            />
          </Container>
          <Container textAlign='right'>
            <DataSortSelect
              selected={{_sort: props.column, _order: Utils.directionConverter(props.direction, true)}}
              // onChangeSelectSort={props.onChangeSelectSort}
              handleSort={props.handleSort}
            />
          </Container>


        </Grid.Column>
      </Grid>

      {
        // Total count: {props.totalCount}.
      }
      <div className='table-wrapper' className="heavyShadow">
        <Table celled sortable size='large' compact='very'>
          <DataTableHeader
            column={props.column}
            direction={props.direction}
            handleSort={props.handleSort}
            headings={props.headings}
          />

          <Table.Body>
            {dataRows}
            {
              dataRows.length === 0 &&
              <tr>
                <td colSpan={props.headings.length}>
                  You have no contacts yet
                </td>
              </tr>
            }
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={props.headings.length}>
                <Pagination
                  totalPages={props.totalPages}
                  activePage={props.currentPage}
                  onPageChange={props.onChangePage}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
       </div>
    </React.Fragment>
  );
};

DataTable.propTypes = {
  totalCount    : PropTypes.number.isRequired,
  totalPages    : PropTypes.number.isRequired,
  currentPage   : PropTypes.number.isRequired,
  onChangePage  : PropTypes.func.isRequired,
  onChangeLimit : PropTypes.func.isRequired,
  limit         : PropTypes.string.isRequired,
  filter        : PropTypes.string.isRequired,
  headings      : PropTypes.array.isRequired,
  columns       : PropTypes.array.isRequired,
  noContentMessage: PropTypes.string.isRequired

};
