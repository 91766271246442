import React from 'react'
import {Modal, Button, TextArea} from 'semantic-ui-react'
import {files} from '../contacts/files.js'

const modal = (open, closeModal, saveUpc, addFilename, removeFilename, cad) => {
  return (
    <Modal
      onClose={closeModal}
      open={open}
    >
      <Modal.Header>
      Edit UPC
      <div style={{float: 'right', fontSize: '60%', lineHeight: '1.4'}}>
        Style Number = {cad.style_number} <br/>
        Cad Id = {cad.id}
      </div>
      </Modal.Header>
      <Modal.Content>
        <TextArea placeholder='S=012345678905
M=111122223333
etc...' rows={5} id="addUpc" style={{width: '100%', padding: '10px', minHeight: '150px'}}/>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={closeModal}>
          Cancel
        </Button>
        <Button
          content="Save UPC"
          labelPosition='right'
          icon='checkmark'
          onClick={saveUpc}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export {modal}
