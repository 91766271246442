const warehouseOptions = [
  'EURO', 'MIDAS', 'PIXIOR',
  'Canada East',
  'Canada West',
  'FBA',
  'FOB China',
  'NY-HQ',
  'Drop Ship',
  'REGULAR',
  'POE', 'MISSING'
].map(x => {return { key: x, text: x, value: x }})

export default warehouseOptions
