import {fabricationValue} from './fabrication-table-cell'

const checkIsNanLabels = ['DISCOUNT', 'CASE PACK', '# OF CARTONS', 'POLIBAG']

const vpoSort = (results, column, direction) => {
  if (column == null || direction == null) {
    return results
  } else {
    results = results.sort((a, b) => {
      let a_val = getValue(a, column)
      let b_val = getValue(b, column)

      if (checkIsNanLabels.includes(column)) {
        if (isNaN(a_val)) {
          a_val = null
        }
        if (isNaN(b_val)) {
          b_val = null
        }
      }

      if (a_val == b_val) {
        a_val = getValue(a, 'PO #')
        b_val = getValue(b, 'PO #')
      }

      if (a_val == b_val) {
        a_val = getValue(a, 'STYLE #')
        b_val = getValue(b, 'STYLE #')
      }

      if (a_val > b_val) return 1
      if (b_val > a_val) return -1

      if (a_val != null && b_val == null) return -1
      if (a_val == null && b_val != null) return 1

      return 0
    })

    if (direction === 'descending') {
      results = results.reverse()
    }

    return results
  }
}

const getTotalDollar = (row) => {
  return (Number(row.price) * Number(row.qty)) * (row.discount == null ? 1 : (1 - Number(row.discount) / 100.0))
}

const getValue = (x, column) => {
  switch(column) {
    case 'AMOUNT PAID':
      return x.amount_paid
    case 'TRANSACTION ID':
      return x.transaction_id
    case 'TIME PAID':
      return x.transaction_id
    case 'TIME CANCELLED':
      return x.time_cancelled_epoch == null ? 9999999999 : x.time_cancelled_epoch
    case 'REF #':
      return Number(x.refnumber.replace('V',''))
    case 'PO #':
      return x.vponumber
    case 'STYLE #':
      return x.style_number
    case 'FACTORY ID':
      return x.factory_id
    case 'BUYER':
      return x.buyer_name == null ? 'Pending' : x.buyer_name
    case 'QTY':
      return Number(x.qty)
    case 'PRICE':
      return Number(x.price)
    case 'STATUS':
      return x.status
    case 'DISCOUNT':
      return Number(x.discount)
    case 'TOTAL $':
      return getTotalDollar(x)
    case 'SIZE':
      return x.size
    case 'CREATED DATE':
     return parseDate(x.created_date)
    case 'DELIV DATE':
      return parseDate(x.deliv_date)
    case 'EX-FACTORY DATE':
      return parseDate(x.ex_factory)
    case 'ORIGINAL EX-FACTORY DATE':
      return parseDate(x.original_ex_factory)
    case 'LOADING DATE':
      return parseDate(x.loading_date)
    case 'CONTAINER #':
      return x.container_number
    case 'MEMO':
      return x.memo
    case 'CASE PACK':
      return Number(x.case_pack)
    case '# OF CARTONS':
      return Number(x.qty) / Number(x.case_pack)
    case 'POLIBAG':
      return Number(x.polibag)
    case 'COUNTRY':
      return x.country
    case 'FACTORY-RTS DATE':
      return parseDate(x.fob_rts)
    case 'ASN X-FACTORY DATE':
      return parseDate(x.asnx_factory)
    case 'BRAND':
      return x.cad.brand
    case 'DIVISION':
      return x.cad.department
    case 'CATEGORY':
      return x.cad.parent_category
    case 'SUB-CATEGORY':
      return x.cad.category
    case 'COLOR':
      return x.cad.color
    case 'FABRICATION':
      return fabricationValue(x)
    case 'PACKS':
      return x.cad.packs

    default:

  }
}

const parseDate = (str) => {
  if (str == null || str == '') return null
  let parts = str.split("/")
  return new Date(Number(parts[2]), Number(parts[0]) - 1, Number(parts[1]))
}

export {vpoSort, getTotalDollar, parseDate}
