import React, {Component} from 'react'

const thStyle = {paddingLeft: '20px'}

const formatDate = (value) => {
  if (value == null || value == '') {
    return null
  } else {
    let date = new Date(value)
    date.setHours(date.getHours() + 12)
    return date.toLocaleDateString()
  }
}

class OpenOrders extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }


  render() {
    let {orders} = this.props

    return (
      <div style={{marginTop: '20px'}}>
        <b>Open Orders:</b><br />
        <table style={{border: '1px solid black'}}>
          <tr>
            <th>REF#</th>
            <th style={thStyle}>PO#</th>
            <th style={thStyle}>Order Type</th>
            <th style={thStyle}>QTY</th>
            <th style={thStyle}>Start Ship</th>
            <th style={thStyle}>Created</th>
            <th style={thStyle}>QB Account Name</th>
            <th style={thStyle}>Rep</th>
          </tr>

          {orders.map((order) => {

            let orderType = 'PO'
            if (!!order.hold) {orderType = 'Hold'}
            if (!!order.worksheet) {orderType = 'Worksheet'}


            return (
              <tr>
                <td>{order.refnumber == null ? '—' : order.refnumber}</td>
                <td style={thStyle}>{order.ponumber}</td>
                <td style={thStyle}>{orderType}</td>
                <td style={thStyle}>{order.quantity}</td>
                <td style={thStyle}>{formatDate(order.date)}</td>
                <td style={thStyle}>{formatDate(order.dateWasEntered)}</td>
                <td style={thStyle}>{order.accountName}</td>
                <td style={thStyle}>{order.rep.split('@')[0]}</td>

              </tr>
            )
          })}

          {orders.length == 0 ?
            <tr><td></td><td></td><td>NONE FOUND</td></tr>
          : null}

        </table>
      </div>
    )
  }
}

export default OpenOrders
