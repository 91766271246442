import                                             './index.css'
import React, { Component }                   from "react"
import { Feed, Icon, Button, Loader, Message, Popup, Label }from 'semantic-ui-react'
import PropTypes                              from 'prop-types';
import AccountAPI                             from '../../../../modules/api/account_api.js'
import PurchaseOrderAPI                       from '../../../../modules/api/purchase_order_api'
import { Auth0Context }                       from "../../../../contexts/auth-context"
import Utils                                  from '../../../../modules/utils'
import { ConfirmAlert }                from '../../../ui/confirm-alert'
import {Link}             from 'react-router-dom'

class ActivitiesSection extends Component
{
  static contextType = Auth0Context;

  constructor(props)
  {
    super(props)
    this.state = {
      account               : this.props.account,
      notes                 : this.props.account.notes,
      activitiesOf          : this.props.activitiesOf,
      deleteNote            : null,
      deleteConfirmation    : false,
      fileName              : '',
      fileUrl               : '',
      purchaseOrders        : {},
      tasks                 : [],
      waitForTasks          : false,
    };
  }

  componentDidMount()
  {
    this.fetchTasks()
  }

  loadMore = () =>
  {
    this.setState({ loading: true })
    let {pagination} = this.props;
    let {next} = pagination;
    this.props.fetchActivities(next)
  }

  fetchPO = async (poId) =>
  {
    let purchaseOrder

    let callback = async (error, data) =>
    {
      if (error)
      {
        // noops
        console.error(error)
      }
      else
      {
        purchaseOrder = data.purchaseOrder
        await this.setState({purchaseOrders: {...this.state.purchaseOrders, [poId]: purchaseOrder} })
      }
    }

    await PurchaseOrderAPI.get(poId, callback)
  }

  async getPurchaseOrderActivityInfo(activity)
  {
    !this.state.purchaseOrders[activity.itemId] && await this.fetchPO(activity.itemId)

    let icon     = 'cart plus'
    let summary  = 'Purchase order shipping date'
    let helpText = `PO # ${activity.itemMeta.ponumber}`
    if (activity.action === 'shipping_date')
    {
      helpText = `PO # ${activity.itemMeta.ponumber}`
    }
    return {icon, summary, helpText}
  }

  getEventActivityInfo(activity)
  {
    let icon     = 'calendar check'
    let summary  = 'Event on'
    let helpText;


    if (activity.action === 'created')
    {
      summary  = 'Event created on'
      helpText = `Event "${activity.itemMeta.title}" created on "${Utils.formatDate(activity.onDate)}."`
    }
    else if (activity.action === 'start_time')
    {
      summary  = 'Event coming on'
      helpText = `Event "${activity.itemMeta.title}" coming on "${Utils.formatDate(activity.onDate)}."`
      if (this.state.past)
      {
        summary  = 'Event past on'
        helpText = `Event "${activity.itemMeta.title}" was on "${Utils.formatDate(activity.onDate)}."`
      }
    }
    return {icon, summary, helpText}
  }

  getTaskActivityInfo(activity)
  {
    let icon     = 'tasks'
    let summary  = this.state.past ? 'Task was due on' : 'Task Due on'
    let helpText;

    const taskIndex = this.state.tasks.findIndex((task)=>task.id === activity.itemId)

    if (activity.action === 'created')
    {
      summary  = 'Event created on'
      helpText = `Event "${activity.itemMeta.title}" created on "${Utils.formatDate(activity.onDate)}."`
    }
    else if (!this.state.tasks[taskIndex].isComplete)
    {
      helpText = `Task "${activity.itemMeta.title}" has due date ${Utils.formatDate(activity.onDate)}.`
      if (this.state.past)
      {
        helpText = `Task "${activity.itemMeta.title}" had due date ${Utils.formatDate(activity.onDate)}.`
      }
    }
    else if (this.state.tasks[taskIndex].isComplete)
    {
      summary  = 'Task was completed on'
      helpText = `Task "${activity.itemMeta.title}" completed on ${Utils.formatDate(activity.onDate)}.`
    }


    return {icon, summary, helpText}
  }

  getNoteActivityInfo(activity)
  {
    let icon     = 'sticky note outline'
    let summary  = 'Note created on'
    let helpText = `Note title "${activity.itemMeta.note}".`
    return {icon, summary, helpText}
  }

  getEmailActivityInfo(activity)
  {
    let icon     = 'mail'
    let summary  = 'Email sent on'
    let helpText = `Email subject "${activity.itemMeta.subject}".`
    return {icon, summary, helpText}
  }

  getActivityInfo(activity)
  {
    let data;
    switch(activity.itemType)
    {
      case "PurchaseOrder" :
      {
        data = this.getPurchaseOrderActivityInfo(activity)
        break;
      }
      case "Event" :
      {
        data = this.getEventActivityInfo(activity)
        break;
      }
      case "Task" :
      {
        data = this.getTaskActivityInfo(activity)
        break;
      }
      case "Note" :
      {
        data = this.getNoteActivityInfo(activity)
        break;
      }
      case "Email" :
      {
        data = this.getEmailActivityInfo(activity)
        break;
      }
      default :
      {

      }

    }
    return data;
  }

  downloadFile = (noteId) => {
    let { account } = this.state;
    if (!noteId || !account ) return;

    this.setState({ loading: true })

    AccountAPI.downloadNoteFile(account.id, noteId, (error, data) =>
    {
      if (error)
      {
        alert(error)
        this.setState({ loading: false, displayMessage: true, error: {apiError: true, error: error} })
      }
      else
      {
        this.setState({ loading: false }, () => window.open(data.filePath))
      }
    })
  }

  showDeleteConfirmation = (note) => {
    let { deleteNote, deleteConfirmation } = this.state
    deleteNote = note
    deleteConfirmation = true
    this.setState({ deleteNote, deleteConfirmation })
  }

  onModalReject = () => {
    let { deleteNote, deleteConfirmation } = this.state
    deleteNote = null
    deleteConfirmation = false
    this.setState({ deleteNote, deleteConfirmation })
  }

  onDeleteNote = () => {
    this.setState({ loading: true, deleteConfirmation: false })
    let { account, deleteNote } = this.state
    let note = deleteNote
    const {setMessage} = this.context
    if (account && note.id)
    {
      AccountAPI.deleteNote(account.id, note.id, (error, data) =>
      {
          if (error)
          {
            alert(error)
            this.setState({ loading: false, displayMessage: true, error: {apiError: true, error: error} })
          }
          else
          {
            note._destroy = true

            this.setState({ loading: false }, () => setMessage({displayValue: `Successfully deleted note: ${note.note}`, type: 'success'}))
          }
      })
    }
    else{
      note._destroy = true
      this.setState({ loading: false }, this.afterSubmit)
    }
    this.fetchTasks()
  }

  fileDownloader(filename, id) {
    let foldername = filename.split('/')[0]
    let downloadUrl = process.env.REACT_APP_API_URL + '/api/download_order_file/' + id + '?file=' + foldername
    window.open(downloadUrl, '_blank', "height=700,width=700")
  }

  distFileDownloader(filename, id) {
    let foldername = filename.split('/')[0]
    let downloadUrl = process.env.REACT_APP_API_URL + '/api/download_order_file/' + id + '?is_dist_file=true&file=' + foldername
    window.open(downloadUrl, '_blank', "height=700,width=700")
  }

  fileDownloadButtons = (purchaseOrder) => {
    if (!!purchaseOrder) {
      const filenameArry = !!purchaseOrder.filename ? purchaseOrder.filename.split('@@@') : []
      const fileComponentArry = filenameArry.map((filename)=>{
        return(
          <Popup
            content={filename.slice(filename.lastIndexOf("/")+1)}
            trigger={
              <Button
                onClick={()=>this.fileDownloader(filename, purchaseOrder.id)}
                color='green'
                basic
                icon='download'
                style={{marginLeft: '10px', padding: '5px'}}
              />
            }
          />
        )
      })
      const distributionFilenameArry = !!purchaseOrder.distributionFilename ? purchaseOrder.distributionFilename.split('@@@') : []
      const distComponentArry = distributionFilenameArry.map((filename)=>{
        return(
          <Popup
            content={filename.slice(filename.lastIndexOf("/")+1)}
            trigger={
              <Button
                onClick={()=>this.distFileDownloader(filename, purchaseOrder.id)}
                color='green'
                basic
                icon='box'
                style={{marginLeft: '10px', padding: '5px'}}
              />
            }
          />
        )
      })
      return [...fileComponentArry, ...distComponentArry]
    }
  }

  fetchTasks = async () =>
  {
    var {account} = this.state;

    var callback = (error, tasks) =>
    {
      if (error)
      {
        console.error(error);
        this.props.reloadActivities()
      }
      else
      {
        this.setState({ tasks }, this.props.reloadActivities)
      }
    }
    await AccountAPI.getTasks(account.id, callback)

  }

  toggleTaskIsComplete = (taskIndex) => {
    let { account, tasks } = this.state;
    this.setState({
      tasks: [...tasks.slice(0,taskIndex), {...tasks[taskIndex], isComplete: !tasks[taskIndex].isComplete}, ...tasks.slice(taskIndex+1)],
      waitForTasks: true,
    }, ()=> setTimeout(()=>this.setState({waitForTasks: false}), 3000))
    const setFlashMessage = this.context.setMessage
    AccountAPI.toggleTaskCompleteness(account.id, tasks[taskIndex].id, (error, data) =>
    {
      if (error)
      {
        setFlashMessage({apiError: true, displayValue: error.response.data, type: 'negative'})
      }
      else
      {
        setFlashMessage({displayValue: 'Successfully marked as complete', type: 'success'})
      }
    })
  }

  render()
  {
    let { deleteConfirmation, deleteNote} = this.state;
    let {pagination, loading, activities} = this.props;
    let {next} = pagination;
    return <div className="activities-section">
      {
        deleteConfirmation &&
        <ConfirmAlert
            header='Delete Your Note'
            confirmationText='Are you sure you want to delete your note?'
            onReject={this.onModalReject}
            onAccept={this.onDeleteNote}
            >
            <p>
              <b>Note: </b>
              {deleteNote.note}
            </p>
        </ConfirmAlert>
      }
      <Feed size='small'>
        <span className="refresh-activities">
        {
          loading ?
          <Icon loading name='spinner' size='large' disabled/>
          :
          <Popup
            content='Refresh activities'
            position='top right'
            trigger={<Icon name='refresh' onClick={this.fetchTasks}/>}
          />

        }
        </span>
        {
          activities.length > 0 &&
            activities.map((activity, i) => {
              let activityInfo = this.getActivityInfo(activity)
              const note = this.state.notes.find(note => note.id === activity.itemId)
              const taskIndex = this.state.tasks.findIndex((task)=>task.id === activity.itemId)

              if ((activity.itemType === "Note") && !!note) {
                const {user} = this.context;
                return(
                  <Feed.Event key={'activity-'+i}>
                    <Feed.Label icon={activityInfo.icon} />
                    <Feed.Content>
                      <Feed.Summary>
                        <Feed.Date><div className='feed-date'>{Utils.formatDateTime(note.createdAt)}</div></Feed.Date> <b>{note.userName}</b> created a note
                        {String(user.id) === String(note.userId) ? <Icon onClick={() => {this.showDeleteConfirmation(note)}} style={{margin: "4px", color: "Red"}} name='trash alternate' /> : null }
                      </Feed.Summary>
                      <Feed.Extra text>
                        <div dangerouslySetInnerHTML={{__html: `Note title "${note.note.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>')}"`}} />
                      </Feed.Extra>
                      <Feed.Extra text>
                        {
                          note.fileName &&
                          <b>File: <span className='link' onClick={() => this.downloadFile(note.id) }>{note.fileName}</span></b>
                        }
                      </Feed.Extra>
                    </Feed.Content>
                  </Feed.Event>
                )
              } if (activity.itemType === "PurchaseOrder") {
                return(
                  <Feed.Event key={'activity-'+i}>
                    <Feed.Label icon={activityInfo.icon} />
                    <Feed.Content>
                      <Feed.Summary>
                         <Feed.Date><div className='feed-date'>{Utils.formatDateTime(activity.onDate)}</div></Feed.Date>{` `}{activityInfo.summary}
                      </Feed.Summary>
                      <Feed.Extra text>
                         {`PO # `}<Link to={`/sales-orders/${activity.itemId}/edit`}>{activity.itemMeta.ponumber}</Link>
                         {this.fileDownloadButtons(this.state.purchaseOrders[activity.itemId])}
                      </Feed.Extra>
                    </Feed.Content>
                  </Feed.Event>
                )
              } if ((activity.itemType === "Task") && (taskIndex > -1)) {
                const task = this.state.tasks[taskIndex]
                let haveAccess = (task) => {
                  const {user} = this.context;
                  return !!task.assignees.find( (assignee) => String(user.id) === String(assignee.id) )
                }
                return(
                  <Feed.Event key={'activity-'+i}>
                    <Feed.Label icon={activityInfo.icon} />
                    <Feed.Content>
                      {
                        task.isComplete ?
                        <div
                          style={{
                            right: "3rem",
                            position: "absolute",
                            borderRadius: "10px",
                            border: "1px solid rgba(25, 145, 78, 1)",
                            height: "3.5rem",
                            width: "3.5rem",
                            marginTop: "-0.25rem",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#ffffff",
                            background: "#2da562",
                            fontSize: "1.5rem",
                            marginLeft: "0.5rem"
                          }}
                          onClick={ () => {!this.state.waitForTasks && this.toggleTaskIsComplete(taskIndex)} }
                        >
                          <Icon style={{margin: "50%", marginTop: "65%"}} name='check' />
                        </div>
                        :
                        haveAccess(task) ?
                        <div
                          style={{
                            borderRadius: "10px",
                            border: "1.5px solid #D3D3D3",
                            height: "3.5rem",
                            width: "3.5rem",
                            right: "3rem",
                            marginTop: "-0.25rem",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            color: "#283c46",
                            fontSize: "0.70rem",
                            cursor: "pointer",
                          }}
                          onClick={ () => {!this.state.waitForTasks && this.toggleTaskIsComplete(taskIndex)} }
                        >
                          Mark as done
                        </div>
                        : null
                      }
                      <Feed.Summary>
                        <Feed.Date><div className='feed-date'>{Utils.formatDateTime(activity.onDate)}</div></Feed.Date>{` `}{activityInfo.summary}
                      </Feed.Summary>
                      <Feed.Extra text>
                        {activityInfo.helpText}
                      </Feed.Extra>
                    </Feed.Content>
                  </Feed.Event>
                )

              } else {
                return(
                  <Feed.Event key={'activity-'+i}>
                    <Feed.Label icon={activityInfo.icon} />
                    <Feed.Content>
                      <Feed.Summary>
                        <Feed.Date><div className='feed-date'>{Utils.formatDateTime(activity.onDate)}</div></Feed.Date>{` `}{activityInfo.summary}
                      </Feed.Summary>
                      <Feed.Extra text>
                        {activityInfo.helpText}
                      </Feed.Extra>
                    </Feed.Content>
                  </Feed.Event>
                )
              }
            })
          }

          {
            loading ?
            <Loader active inline='centered'>
              Fetching Activities...
            </Loader>
            :
            (next) ?
            <div style={{textAlign: 'center'}}>
              <Button size='mini' basic color='blue' onClick={this.loadMore}>Load more activities</Button>
            </div>
            :
            <Message
              size='mini'
              key='no-activities'
              className='help-text-message'
              >
              <div>
                {
                  activities.length === 0 ?
                  "No activities present!" : "No more activities!"
                }
              </div>
            </Message>
          }

        </Feed>
      </div>
  }
}

ActivitiesSection.propTypes = {
  account: PropTypes.object.isRequired,
  activitiesOf: PropTypes.oneOf(['past', 'future']).isRequired
};

export default ActivitiesSection
