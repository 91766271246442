import React, { Component } from 'react'
import $ from 'jquery'
import { Icon, Progress,  } from 'semantic-ui-react'

let logoStyle = {height: '35vh', marginTop: '10vh', display: 'none'}

class ConfirmationPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmNumber: null,
      message: '',
      percent: 0,
      isGuest: false,
      usingDocParser: false,
      isError: false
    }

    this.getStatus = this.getStatus.bind(this)
  }

  componentDidMount() {
    let isGuest = localStorage.getItem('isGuest') == 'TRUE'
    let confirmNumber = this.props.id
    let usingDocParser = confirmNumber[0] == 'D'
    this.setState({isGuest, confirmNumber, usingDocParser})
    this.getStatus()
    setTimeout(() => $('.calendly-badge-widget').hide(), 0)//hide calendar widget
    if (usingDocParser) {
      window.checkStatusInterval = window.setInterval(() => this.getStatus(), 20000)
    }
    window.scrollTo(0, 0)
  }

  getStatus() {
    //call api for doc parser status
    $.get(process.env.REACT_APP_API_URL + '/api/doc_parser_status/' + this.props.id, (result) => {
      let message = result.message
      let percent = result.percent
      let isError = result.isError
      this.setState({message, percent, isError})
      if (percent == 100) {
        clearInterval(window.checkStatusInterval)
      }
    })
  }

  render() {
    let {isGuest, confirmNumber, usingDocParser, message, percent, isError } = this.state

    let orderType = ''
    if (confirmNumber != null) {
      switch(confirmNumber[0]) {
        case 'H':
          orderType = 'hold'
          break
        case 'S':
          orderType = 'sales order'
          break
        case 'D':
          orderType = 'sales order'
          usingDocParser = true
          break
        case 'W':
          orderType = 'worksheet'
          break
        case 'V':
          orderType = 'VPO'
          break
        default:
          // code block
      }
    }

    let splitVpos = false
    if (orderType == 'VPO') {
      splitVpos = confirmNumber.indexOf(',V') > -1
    }

    if (orderType != '') {
      return (
        <div style={{padding: '50px 60px'}}>
          <center>
            <h3 style={{marginTop: '8vh', fontSize: '24px'}}>
              <div style={{maxWidth: '60vw', textAlign: 'left', marginBottom: '100px', marginTop: '80px'}}>
                Doc Parser Progress for REF #: &nbsp; {confirmNumber.replace(/,V/g, ", V")}
                <Progress percent={percent} indicating error={isError} style={{marginTop: '3px'}}>
                  {message}
                </Progress>
              </div>
            </h3>
          </center>
        </div>
      )
    } else {
      return <div></div>
    }
  }
}

export default ConfirmationPage
