import React, {Component}  from 'react'
import {Step, Icon, Popup} from 'semantic-ui-react'
import Utils               from './../../../../modules/utils'


class ProgressStep extends Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      purchaseOrder     : {},
      progressStep      : {},
      progressModal     : false,
      purchaseOrderFiles: [],
      details           :
      {
        'Pending':
        {
          icon        : 'file alternate outline',
          description : '',
          files       : 'purchaseOrderFiles'
        },
        'Needs-Fulfillment':
        {
          icon        : 'box',
          description : 'View packing slip',
          files       : 'packingSlipFiles'
        },
        'Routed':
        {
          icon        : 'truck',
          description : 'Verify order details',
          files       : 'routedFiles'
        },
        'Shipped':
        {
          icon        : 'info',
          description : 'View BOL / POD',
          files       : 'bolPodFiles'
        }
      }
    }

    this.downloadFile = this.downloadFile.bind(this)
  }

  componentDidMount()
  {
    var {purchaseOrder, progressStep, progressModal} = this.props
    var purchaseOrderFiles = Utils.readAsArray(purchaseOrder[this.state.details[progressStep.name].files])

    this.setState({ purchaseOrder, progressStep, progressModal, purchaseOrderFiles })
  }

  componentDidUpdate()
  {
    if (this.props.progressModal !== this.state.progressModal)
    {
      this.setState({progressModal: this.props.progressModal})
    }
  }

  openProgressModal = (e) =>
  {
    var {purchaseOrder, progressStep} = this.state
    this.setState({ progressModal: true })

    if (progressStep.name === 'Needs-Fulfillment')
    {
      this.props.setFulfillmentModal(e, {po: purchaseOrder, value: true})
    }
    else if (progressStep.name === 'Routed')
    {
      this.props.openProgressAlert(e, purchaseOrder, {progress: progressStep, name: 'routed'})
    }
    else if (progressStep.name === 'Shipped')
    {
      this.props.openProgressAlert(e, purchaseOrder, {progress: progressStep, name: 'shipped'})
    }
  }

  renderFiles = (files) =>
  {
    let markup =
      <Step.Description>
        {
          files && files.map((file, i) => {
            return <Popup
              position='bottom center'
              key={i}
              basic
              content={file.filename}
              trigger={<Icon
                        name='file alternate'
                        link
                        onClick={(e) => this.props.gotoDownloadLink(e, this.state.purchaseOrder, file)}
                        />
                      }
            />
          })
        }
      </Step.Description>

    return markup;
  }

  downloadFile(filename) {
    let foldername = filename.split('/')[0]
    let downloadUrl = process.env.REACT_APP_API_URL + '/api/download_order_file/' + this.props.purchaseOrder.id + '?file=' + foldername
    window.open(downloadUrl, '_blank', "height=700,width=700")
  }

  formatShippedDate(dateStr) {
    if (dateStr == null || dateStr == '') {
      return null
    }

    let dateParts = dateStr.split('-')

    return ' on '+dateParts[1]+'/'+dateParts[2]+'/'+dateParts[0]
  }

  render()
  {
    var {progressStep, purchaseOrder, purchaseOrderFiles, progressModal, details} = this.state
    var popupDisabled = progressModal || (progressStep.status === 'completed' && ['Routed', 'Shipped'].includes(progressStep.name))

    let filenames = (this.props.purchaseOrder.filename == null ? [] : this.props.purchaseOrder.filename.split('@@@'))

    var markup =
      progressStep && progressStep.id ?
      <Step
        active={progressStep.status === 'draft'}
        completed={progressStep.status === 'completed'}
        disabled={this.props.isDisabled}
        >
        <Icon name={details[progressStep.name].icon} />
        <Step.Content>
          {
            progressStep.name === 'Pending' ?
            <div>
              <Step.Title>Pending</Step.Title>
              <Step.Description>
                View order
              </Step.Description>
              {filenames.map((filename, index) => {
                return (
                  <Popup
                    position='bottom center'
                    key={index}
                    basic
                    content={filename.substring(43)}
                    trigger={
                      <Icon
                        name='file alternate'
                        link
                        onClick={this.downloadFile.bind(null, filename)}
                        />
                    }
                  />
                )
              })}
            </div>
            :
            <div>
              <Popup
                hoverable
                inverted
                hideOnScroll
                disabled={popupDisabled}
                position='bottom center'
                trigger={
                  <Step.Title onClick={this.openProgressModal} style={{cursor: 'pointer'}}>
                    {progressStep.name.replace(/-/g, ' ')} {progressStep.name === 'Shipped' ? this.formatShippedDate(purchaseOrder.shippedDate) : null}
                  </Step.Title>
                }
                >
                <center>
                  <span style={{textDecoration: 'underline'}}>Change Status to</span>
                  <b style={{marginLeft: '5px'}}>{progressStep.name.replace(/-/g, ' ')}</b>
                </center>
              </Popup>
              <Step.Description>
                {details[progressStep.name].description}
              </Step.Description>
              {this.renderFiles(purchaseOrderFiles)}
            </div>
          }
        </Step.Content>
      </Step>
      :
      null

    return markup;
  }
}

export default ProgressStep;
