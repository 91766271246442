import Utils from '../../modules/utils'

const checkStyleNumber = (styleNumber, type, checkStyleNumberSuccess) => {
  let url = 'style_numbers?check=true&type='+type
  let data = {styleNumber}

  Utils.post(url, data, (err, res) => {
    if (err) {
      alert('There was an error when trying to check the style number!')
    } else {
      checkStyleNumberSuccess(res.data)
    }
  })
}

const saveStyleNumber = (styleNumber, saveStyleNumberSuccess) => {
  let url = 'style_numbers'
  let data = {styleNumber}

  Utils.post(url, data, (err, res) => {
    if (err) {
      alert('There was an error when trying to save the style number!')
    } else {
      if (res.data.cadAlreadyExists) {
        alert('ERROR: Cad with Style Number already exists!')
      } else if (res.data.alreadySaved) {
        alert('ERROR: Already saved Style Number!')
      } else {
        saveStyleNumberSuccess(styleNumber)
      }
    }
  })
}

const getSavedStyledNumbers = (getSavedStyledNumbersSuccess) => {
  let url = 'style_numbers'

  Utils.get(url, (err, res) => {
    if (err) {
      alert('There was an error when trying to get the style numbers!')
    } else {
      getSavedStyledNumbersSuccess(res.data.styleNumbers)
    }
  })

}

export {checkStyleNumber, saveStyleNumber, getSavedStyledNumbers}
