import $ from 'jquery'

const updateSelected = () => {
  $('.print-checkbox-holder > input').prop('checked', false)

  var keys = Object.keys(window.printIds)

  for (var i=0; i<keys.length; i++) {
    let printId = keys[i]
    let myCheckbox = document.getElementById('print-checkbox-'+printId)
    if (myCheckbox == null)  {
      delete window.printIds[printId]
    } else {
      myCheckbox.checked = true
    }
  }
}

export {updateSelected}
