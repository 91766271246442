import React, { Component } from 'react'
import {Card, Icon, Transition, Label, Button, Popup} from 'semantic-ui-react'
import './style.css'
import {editButton} from './edit-button'
import {checkbox} from './checkbox'
import {buildingTypes, sources, getFilename} from './options'
import {numberWithCommas} from './number-with-commas'
import {guestViewProperty} from './guest-view'
import $ from 'jquery'
import FullscreenImage from '../share-property/fullscreen-image'

const isOutOfViewport = (elem) => {
	// Get element's bounding
	var bounding = elem[0].getBoundingClientRect();

	// Check if it's out of the viewport on each side
	var out = {};
	out.top = bounding.top < 0;
	out.left = bounding.left < 0;
	out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
	out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
	out.any = out.top || out.left || out.bottom || out.right;
	out.all = out.top && out.left && out.bottom && out.right;

	return out.any;
}

class PropertyCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageIndex: 0
    }

    this.propertyCard = this.propertyCard.bind(this)
    this.imageOnClick = this.imageOnClick.bind(this)
    this.arrowKeyboardClick = this.arrowKeyboardClick.bind(this)
    this.nextImage = this.nextImage.bind(this)
    this.previousImage = this.previousImage.bind(this)
    this.onFullScreenChange = this.onFullScreenChange.bind(this)
  }

  componentDidMount() {
    $(document).unbind( "keyup", this.arrowKeyboardClick )
    $(document).keyup(this.arrowKeyboardClick)

    document.addEventListener('fullscreenchange', this.onFullScreenChange, false);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenChange, false);
    document.addEventListener('mozfullscreenchange', this.onFullScreenChange, false);
  }

  onFullScreenChange() {
    let fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement

    // if in fullscreen mode fullscreenElement won't be null
    if (fullscreenElement == null) {
      setTimeout(() => {
        if (isOutOfViewport($("#property-card-"+this.state.imageIndex))) {
          $("#property-card-"+this.state.imageIndex)[0].scrollIntoView()
        }
      }, 100)
    }
  }

  nextImage() {
    let {imageIndex} = this.state
    let {properties} = this.props

    if (imageIndex != properties.length - 1) {
      let newIndex = this.state.imageIndex+1
      this.setState({imageIndex: newIndex})
    }
  }

  previousImage() {
    let {imageIndex} = this.state

    if (imageIndex != 0) {
      let newIndex = imageIndex-1
      this.setState({imageIndex: newIndex})
    }
  }

  arrowKeyboardClick(e) {
    if( window.innerHeight == window.screen.height) {
      // browser is fullscreen
      if (e.keyCode == '37') {
        this.previousImage()
      } else if (e.keyCode == '39') {
        this.nextImage()
      }
    }
  }

  buildingTypeIcon(buildingType) {
    if (buildingType == null) {
      return null
    } else {
      let y = buildingTypes.find(x => x.text == buildingType)
      return <Icon name={y.icon} />
    }
  }

  sourceIcon(source) {
    if (source == null) {
      return null
    } else {
      let y = sources.find(x => x.text == source)
      return <Icon name={y.icon} />
    }
  }

  imageOnClick(index, event) {
    this.setState({imageIndex: index})
    document.getElementById('fullscreen-image').requestFullscreen()
  }

  propertyCard(property, index) {
    let {guest} = this.props
    const date = new Date(property.createdAt)
    const meta = 'Created ' + date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})

    const extra = (
      <span>
        <Icon name='map pin' />
        {property.address}
      </span>
    )

    const description = (
      <span>
        {property.description}

        {
          guest
          ?
          <Button primary fluid style={{marginTop: '10px'}} onClick={guestViewProperty.bind(null, property.id)}>View Property</Button>
          :
          <br/>
        }

        <Label size='tiny' style={{marginTop: '10px'}}>{this.buildingTypeIcon(property.buildingType)}{property.buildingType == null ? 'MISSING TYPE' : property.buildingType.split(' = ')[1]}</Label>
        <Label size='tiny'>{this.sourceIcon(property.source)}{property.source == null ? 'MISSING SOURCE' : property.source.split(' = ')[1]}</Label>
        <Label size='tiny'>{property.buildingSize == null ? 'MISSING BUILDING SIZE' : numberWithCommas(property.buildingSize)+' sqft'}</Label>
        <Label size='tiny' style={{marginTop: '3px'}}>{property.roomsNumber == null ? 'MISSING ROOMS #' : property.roomsNumber+' room'+(property.roomsNumber == '1' ? '' : 's')}</Label>
      </span>
    )

    let checked = this.props.checked[property.id]

    const header = (
      <div className="header">
        {checkbox(property.assetId, property.id, this.props.setChecked, checked)}
        {editButton(property.id)}
      </div>
    )

    let className = 'property-card'
    if (checked) {
      className += ' property-card-checked'
    }

    let image = (
      <div className="image">
        <Popup content={'Click for fullscreen mode!'} position='top right'
          trigger={
            <img id={'image-'+index} onClick={this.imageOnClick.bind(null, index)} src={getFilename(property.filename, true)}/>
          }
        />
      </div>
    )

    return (
      <Card
        id={"property-card-"+index}
        key={index}
        className={className}
        image={image}
        header={guest ? property.assetId : header}
        meta={guest ? null : meta}
        description={description}
        extra={extra}
      />
    )
  }

  render() {
    let {properties, showProperties} = this.props
    let {imageIndex} = this.state
    return (
      <Transition visible={showProperties} animation='fade' duration={3000}>
        <div style={{marginTop: '20px'}}>
          <FullscreenImage src={properties.length > 0 ? getFilename(properties[imageIndex].filename) : ''}
            address={properties.length > 0 ? properties[imageIndex].address : ''}
            showing={(imageIndex+1)+'/'+(properties.length)}
          />

          <Card.Group>
            {properties.map(this.propertyCard)}
          </Card.Group>
        </div>
      </Transition>
    )
  }
}

export default PropertyCards
