import React from 'react'
import {Checkbox, Form} from 'semantic-ui-react'
import {checkboxHolderStyle} from './checkbox-holder-style'

const needCancelVpoAlertInput = (user, customAccessFieldOnChange, sendCancelVpoAlert, forwardCancelVpoEmail) => {
  return (
    <div style={checkboxHolderStyle}>
      <b>Inventory, Production and Admin ONLY:</b>
      <Checkbox style={{marginLeft: '65px'}} toggle label='Send Cancel Vpo Alert' name='sendCancelVpoAlert' checked={sendCancelVpoAlert} onChange={customAccessFieldOnChange}/>

      <Form.Group widths='equal' style={{textAlign: 'center', marginTop: '20px'}}>
        <span>Forward Cancel VPO Email</span>
        <Form.Input name='forwardCancelVpoEmail' type="email" label='' onChange={customAccessFieldOnChange} placeholder={!!user ? user.email : ''} value={forwardCancelVpoEmail}
          style={{width: '300px'}} />
      </Form.Group>
    </div>
  )
}

export {needCancelVpoAlertInput}
