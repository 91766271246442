import React from 'react'
import $ from 'jquery'
import {Icon} from 'semantic-ui-react'

const showScrollToTop = (isLoading, showMoreCads) => {
  if (!isLoading()) {
    if ($(window).scrollTop() + $(window).height() < 1000 && $('#scroll-to-top').is(":visible") ) {
      $('#scroll-to-top-corner-buttons, #bulk-edit-cads-corner-button, #best-seller-export-corner-buttons').hide()
    }

    if ($(window).scrollTop() + $(window).height() >= 1000 && !$('#scroll-to-top').is(":visible") ) {
      $('#scroll-to-top-corner-buttons, #bulk-edit-cads-corner-button, #best-seller-export-corner-buttons').fadeIn()
    }

    if($(window).scrollTop() + $(window).height() >= ($(document).height() - 600)) {
      showMoreCads()
    }
  }
}

const scrollToTop = () => {
  window.scrollTo(0,0)
  $('#scrollToTop').hide()
}

const scrollToTopButton = (numCadsSelected) => {
  return (
    <div id="scroll-to-top-corner-buttons">
      <span style={{marginRight: '10px', borderBottom: '2px solid black', fontWeight: 'bold'}}>{numCadsSelected == 0 ? 'No' : numCadsSelected} cad{numCadsSelected == 1 ? '' : 's'} selected</span> <button id="scroll-to-top" className="ui basic button" onClick={scrollToTop}><Icon name='arrow up' /> Back to top</button>
    </div>
  )
}

const bulkEditCads = () => $('#bulk-edit-cads').click()
const markAsBestSeller = () => $('#mark-as-best-seller').click()
const markAsWorstSeller = () => $('#mark-as-worst-seller').click()

const bulkEditCornerButton = (
  <button id="bulk-edit-cads-corner-button" className="ui basic button" onClick={bulkEditCads}><Icon name='pencil' /> Bulk edit cads</button>
)

const bestSellerExportCornerButtons = (showBestSeller, exportPdf) => {
  return (
    <div id="best-seller-export-corner-buttons">
      {
        showBestSeller
        ?
        <React.Fragment>
          <button className="ui basic button best-seller-corner-button" onClick={markAsBestSeller}><Icon name='thumbs up outline' /></button>
          <button className="ui basic button best-seller-corner-button" onClick={markAsWorstSeller}><Icon name='thumbs down outline' /></button>
        </React.Fragment>
        :
        null
      }
      <button className="ui basic button" onClick={() => exportPdf(null, {value: 'PDF'})}><Icon name='file pdf outline' /> PDF</button>
      <button className="ui basic button" onClick={() => exportPdf(null, {value: 'Excel'})}><Icon name='file excel outline' /> Excel</button>
    </div>
  )
}

export {showScrollToTop, scrollToTopButton, bulkEditCornerButton, bestSellerExportCornerButtons}
