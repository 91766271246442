import React from 'react'
import {Table, List, Label} from 'semantic-ui-react'
import {numberWithCommas} from '../properties/number-with-commas'

const infoTable = (property) => {
  return (
    <List divided selection>
      <List.Item>
        <Label horizontal>
          Asset id
        </Label>
        {property.assetId}
      </List.Item>
      <List.Item>
        <Label horizontal>
          Address
        </Label>
        {property.address}
      </List.Item>
      <List.Item>
        <Label horizontal>
          Description
        </Label>
        {property.description}
      </List.Item>
      <List.Item>
        <Label horizontal>
          Type
        </Label>
        {property.buildingType == null ? '' : property.buildingType.split(' = ')[1]}
      </List.Item>
      <List.Item>
        <Label horizontal>
          Source
        </Label>
        {property.source == null ? '' : property.source.split(' = ')[1]}
      </List.Item>
      <List.Item>
        <Label horizontal>
          Building Size (sqft)
        </Label>
        {property.buildingSize == null ? '' : numberWithCommas(property.buildingSize)}
      </List.Item>
      <List.Item>
        <Label horizontal>
          Rooms #
        </Label>
        {property.roomsNumber == null ? '' : numberWithCommas(property.roomsNumber)}
      </List.Item>
    </List>
  )
}

export {infoTable}
