import moment from 'moment'
import {anyMarginsMatch} from '../sales-view/margins.js'
import {checkCadsForField} from '../po-filter.js'

const checkForMissingCad = (po) => {
  let foundMissingCad = false

  if (po.cads != null) {
    for (let i=0; i<po.cads.length; i++) {
      let cad = po.cads[i]

      if (cad == null) {
        foundMissingCad = true
        break
      }
    }
  }

  return foundMissingCad
}

const checkPOMonth = (po, dropdownOptions) => {
  //later have to get the earliest ship date?  yes we allow multiple ship date
  //also in the excel js export

  let dateText = po.startShippingDate //"2020-03-09"

  if (dateText != '' && dateText != null) {
    let dateTextParts = dateText.split('-')
    let cad_year = Number(dateTextParts[0])
    let cad_month = Number(dateTextParts[1])
    let date_is_good = false

    for (let i=0; i < dropdownOptions.length ; i++) {
      let month = dropdownOptions[i];

      if (!date_is_good && month == "January 2021") {
        date_is_good = (cad_month == 1 && cad_year == 2021)
      }
      if (!date_is_good && month == "February 2021") {
        date_is_good = (cad_month == 2 && cad_year == 2021)
      }
      if (!date_is_good && month == "March 2021") {
        date_is_good = (cad_month == 3 && cad_year == 2021)
      }
      if (!date_is_good && month == "April 2021") {
        date_is_good = (cad_month == 4 && cad_year == 2021)
      }
      if (!date_is_good && month == "May 2021") {
        date_is_good = (cad_month == 5 && cad_year == 2021)
      }
      if (!date_is_good && month == "June 2021") {
        date_is_good = (cad_month == 6 && cad_year == 2021)
      }
      if (!date_is_good && month == "July 2021") {
        date_is_good = (cad_month == 7 && cad_year == 2021)
      }
      if (!date_is_good && month == "August 2021") {
        date_is_good = (cad_month == 8 && cad_year == 2021)
      }
      if (!date_is_good && month == "September 2021") {
        date_is_good = (cad_month == 9 && cad_year == 2021)
      }
      if (!date_is_good && month == "October 2021") {
        date_is_good = (cad_month == 10 && cad_year == 2021)
      }
      if (!date_is_good && month == "November 2021") {
        date_is_good = (cad_month == 11 && cad_year == 2021)
      }
      if (!date_is_good && month == "December 2021") {
        date_is_good = (cad_month == 1 && cad_year == 2021)
      }
      if (!date_is_good && month == "January 2022+") {
        date_is_good = (cad_year >= 22);
      }
    }

    return date_is_good
  } else {
    return false
  }
}

const isMatch = (result, filters, myState) => {
  var match = true

  if (myState.showMissingCad)  {
    match = checkForMissingCad(result)
  }

  if (myState.showFlagged)  {
    match = match && result.flaggedForUpdate
  }

  for (let key of Object.keys(filters))
  {
    var value = filters[key]
    if (value === 'Show All' || value === null) continue;

    switch(key)
    {
      // TODO: Optimize by making the "result" object have a more consistent structure
      // i.e. decide that all "result" objects will have the key currentStatus.name
      // which should be equivalent to result.status etc.
      case "status":
      {
        if (value == 'Auto-Edited') {
          match = (match && result.autoEdited)
          break
        } else if (value == 'QB-Synced') {
          match = (match && result.qbSynced)
          break
        } else if (!value.includes('No')) {
          match = (match && result.currentStatus && (result.currentStatus.name === value)) || (match && (result.status === value))
          break
        } else {
          if (value == 'No packing slip') {
            match = (match && (result.packingSlipFilename == null || result.packingSlipFilename == ''))
            break
          } else if (value == 'No distro') {
            match = (match && (result.distributionFilename == null || result.distributionFilename == ''))
            break
          } else if (value == 'No BOL') {
            match = (match && (result.bolFilename == null || result.bolFilename == ''))
            break
          } else if (value == 'No Item Status') {
            match = (match && result.itemStatus == null)
            break
          } else {
            console.log('unknown status value')
          }
        }
      }
      case "orderType":
      {
        let orderTypes = value.slice(0)
        if (orderTypes.includes("PO")) {orderTypes.push('Sales Order')}

        if (orderTypes.length > 0) {
          //shipped is an order status
          match = match && (orderTypes.includes(result.orderType) || orderTypes.includes(result.status))

          if (orderTypes.includes('Shipped')) {
            //if any order type filter out cancelled
            match = match && result.status !== "Cancelled"
          } else {
            //if contains PO Hold or Worksheet , and NOT Shipped, filter out shipped and cancelled
            match = match && result.status !== "Shipped" && result.status !== "Cancelled" && result.status !== "Invoiced"
          }

        }

        break
      }
      case "region":
      {
        match = (match && result.account && (result.account.region === value)) || (result.accountRegion === value)
        break
      }
      case "category":
      {
        match = match && checkCadsForField(result, "parent_category", value)
        break
      }
      case "brand":
      {
        match = match && checkCadsForField(result, "brand", value)
        break
      }
      case "ticketed":
      {
        let anyMatch = false

        if (value.includes('NO')) {
          anyMatch = result.ticketed == null || result.ticketed == 'NO'
        }

        if (!anyMatch && value.includes('Ordered')) {
          anyMatch = result.ticketed != null && result.ticketed != 'NO' && result.ticketsOrdered === true
        }

        if (!anyMatch && value.includes('Not Ordered')) {
          anyMatch = result.ticketed != null && result.ticketed != 'NO' && !result.ticketsOrdered
        }

        match = match && anyMatch
        break
      }
      case "upfront":
      {
        let anyMatch = false

        if (value.includes('NO, From Stock')) {
          anyMatch = result.upfront == 'NO'
        }

        if (!anyMatch && value.includes('YES, Upfront')) {
          anyMatch = result.upfront == 'YES'
        }

        if (!anyMatch && value.includes('Missing')) {
          anyMatch = [null, ''].includes(result.upfront)
        }

        match = match && anyMatch
        break
      }
      case "margins":
      {
        match = match && anyMarginsMatch(value, result.margins, result.price)
        break
      }
      case "pack":
      {
        match = match && checkCadsForField(result, "packs", value)
        break
      }
      case "division":
      {
        match = match && checkCadsForField(result, "department", value)
        break
      }
      case "subcategory":
      {
        match = match && checkCadsForField(result, "category", value)
        break
      }
      case "month":
      {
        match = match && checkPOMonth(result, value)
        break
      }
      case "rep":
      {
        match = match && result.userId === value
        break
      }
      case "contact":
      {
        match = match && result.accountId === value
        break
      }
      case "dateFrom":
      {
        if (match && result.startShippingDate)
        {
          var _d1 = moment(value.toLocaleDateString(), 'MM/DD/YYYY')
          var _d2 = moment(result.startShippingDate, 'YYYY-MM-DD')
          match = _d1 <= _d2
        }
        break
      }
      case "dateTo":
      {
        if (match && result.startShippingDate)
        {
          var _d1 = moment(value.toLocaleDateString(), 'MM/DD/YYYY')
          var _d2 = moment(result.startShippingDate, 'YYYY-MM-DD')
          match = _d1 >= _d2
        }
        break
      }
      case "account":
      {
        match = match && result.account && result.account.companyId === value
        break
      }
      case "dateType":
      case "dateRange":
      default :
      {
        break
      }
    }
  }

  return match
}

export {isMatch}
