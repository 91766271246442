import React from 'react'
import {Select} from 'semantic-ui-react'
import {brandOptions} from './options'

const brandInput = (brand, fieldOnChange) => {
  return (
    <React.Fragment>
      <label>Brand <small style={{opacity: 0.4, marginLeft: '20px'}}>(Can depend on Division or Category)</small></label><br/>
      <Select search name="brand" style={{width: '300px'}} placeholder='Select a brand'
        options={brandOptions} onChange={fieldOnChange}
        value={brand}
      />
    </React.Fragment>
  )
}

export {brandInput}
