import React from 'react'
import {Select} from 'semantic-ui-react'
import {categoryOptions} from './options'


const categoryInput = (category, fieldOnChange) => {
  return (
    <React.Fragment>
      <label>Category</label><br/>
      <Select search name="category" style={{width: '300px'}} placeholder='Select a category'
        options={categoryOptions} onChange={fieldOnChange}
        value={category}
      />
    </React.Fragment>
  )
}

export {categoryInput}
