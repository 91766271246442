import React  from 'react'
import moment from 'moment-timezone'

class CustomDateString extends React.Component
{
  formatDate = (date) =>
  {
    return {
      date: moment.tz(date, "America/New_York").format("M/D/YYYY"),
      time: moment.tz(date, "America/New_York").format("h:mm A zz").replace("EDT", "EST")
    }
  }

  render()
  {
    let {dateString} = this.props
    let formated     = dateString ? this.formatDate(dateString) : null
    let space        = <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>

    let markup =
      formated ?
      <span>
        <i> {formated.date}{space}{formated.time}</i>
      </span>
      :
      null

    return markup;
  }
}

export default CustomDateString;
