import React, {Component} from 'react'
import {Table} from 'semantic-ui-react'
import {dateInputStyle} from '../../edit-sales-order/date-inputs.js'
import {dateLabel} from './date-label.js'
import ExFactoryDateLogs from './ex-factory-date-logs'
import {infoCircle} from '../../cads/status-info-circle'
import {ADD_DAYS_TO_EX_FACTORY_DATE} from '../../../modules/constants'

class DateColumn extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {
      exFactoryDate, overrideOriginalExfactory, cancelDate, delivDate, onChangeExFactoryDate, onChangeOverrideOriginalExfactory, onChangeCancelDate, onChangeDelivDate,
      cadId, isEditPage
    } = this.props

    return (
      <Table.Cell>
        {dateLabel('Ex-Factory Date')}
        <input type="date"
          value={exFactoryDate}
          onChange={onChangeExFactoryDate}
          style={dateInputStyle}
        />

        {
          isEditPage
          ?
          <React.Fragment>
            <div style={{fontSize: '10px', border: '1px solid rgb(238,238,238)', marginTop: '5px', marginBottom: '5px', paddingTop: '5px', paddingLeft: '5px'}}>
              <label style={{cursor: 'pointer'}}>
                <input type="checkbox" style={{position: 'relative', top: '2px', cursor: 'pointer'}}
                  onChange={onChangeOverrideOriginalExfactory} checked={overrideOriginalExfactory === true}
                />&nbsp;
                Override Original Ex-Factory
              </label>
            </div>

            <ExFactoryDateLogs cadId={cadId}/>
          </React.Fragment>
          :
          null
        }

        {dateLabel('Cancel Date', {marginTop: '5px'})}

        <input type="date"
          value={cancelDate}
          onChange={onChangeCancelDate}
          style={dateInputStyle}
        />

        <div/>

        <div style={{display: 'inline-block'}}>
          {dateLabel('Deliv Date', {marginTop: '5px'})}
        </div>

        {infoCircle('Deliv Date is always ' + ADD_DAYS_TO_EX_FACTORY_DATE + ' days after Ex-Factory')}

        <div style={{opacity: 0.4}}>
          <input type="date"
            value={delivDate}
            onChange={onChangeDelivDate}
            style={dateInputStyle}
            disabled
          />
        </div>
      </Table.Cell>
    )
  }
}

export default DateColumn
