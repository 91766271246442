const checkPonumberMatch = (refnumber, ponumber, nums) => {
  let refnumberMatch = false, ponumberMatch = false

  if (refnumber != null) {
    refnumberMatch = nums.filter(num => refnumber.startsWith(num)).length > 0
  }

  if (ponumber != null) {
    ponumberMatch = nums.filter(num => ponumber.startsWith(num) || num.startsWith(ponumber) || num.endsWith(ponumber)).length > 0
  }

  if (ponumber != null && !ponumberMatch) {
    ponumberMatch = nums.filter(num => checkDistroPonumberMatch(ponumber, num)).length > 0
  }

  return refnumberMatch || ponumberMatch
}

const checkDistroPonumberMatch = (ponumber, num) => {
  //30806134 on order
  //searching with __806134
  //lets cut off first 2 letters

  //also allow to chop off 1 letter

  return num.length > 4 && (ponumber.substring(2) == num || ponumber.substring(1) == num)
}

export {checkPonumberMatch}
