import isUserRole from '../purchase-orders/is-user-role'

const userIdShowAllOrders = [14,286,11,338]

const getRepOptions = (repNames, user) => {
  let {isAdmin, isProduction, isInventory, isSales, isSalesAssistant} = isUserRole(user)

  if (isSalesAssistant) {
    return mapToOptions(user.salesAdminEmails)
  } else if (isAdmin || isProduction || isInventory || isSales) {
    return mapToOptions(repNames)
  } else {
    return []
  }
}

const mapToOptions = list => list.map(name => ({ key: name, text: name, value: name }))

const filterSalesData = (salesData, user) => {
  let {isAdmin, isProduction, isInventory, isSales, isSalesAssistant, isCustomer} = isUserRole(user)

  const emails = (() => {
    if (isSalesAssistant) {
      return user.salesAdminEmails
    } else if (isSales) {
      return [user.email]
    } else {
      return []
    }
  })()

  if (isAdmin || isProduction || isInventory || isCustomer || userIdShowAllOrders.includes(user.id)) {
    return salesData
  } else {
    let result = {}

    Object.keys(salesData).forEach((key) => {
      let orders = salesData[key].filter(order => emails.includes(order.rep) || !!order.hold)

      if (orders.length > 0) {
        if (orders.filter(order => emails.includes(order.rep)).length > 0) {
          result[key] = salesData[key]
        } else {
          result[key] = orders
        }
      }
    })

    return result
  }
}

export {getRepOptions, filterSalesData, userIdShowAllOrders}
