import React from 'react'
import {Input, Select} from 'semantic-ui-react'
import {colorOptions} from './options'
import {checkColorRange} from './check-color-range'

const colorInput = (color, fieldOnChange) => {
  let colorOptionsValues = colorOptions.map((x => x.value))

  let colorShort = Number(color).toString()

  return (
    <React.Fragment>
      <label>Color</label><br/>
      <Input name="color" placeholder='Color' value={color} onChange={fieldOnChange} />
      <Select search name="color" style={{marginLeft: '10px', width: '300px'}} placeholder='Select a color'
        options={colorOptions} onChange={fieldOnChange}
        value={colorOptionsValues.includes(colorShort) ? colorShort : checkColorRange(color)}
      />
    </React.Fragment>
  )
}

export {colorInput}
