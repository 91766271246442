import React from 'react'
import {Message} from 'semantic-ui-react'

const alerts = (dismissAlerts, showStatusAlert, showNoteAlert) => {
  return (
    <div>
      {
        showStatusAlert
        ?
        <Message warning onDismiss={dismissAlerts} style={{marginBottom: '40px', fontSize: '16px'}}>
          Contact status successfully updated
        </Message>
        :
        null
      }
      {
        showNoteAlert
        ?
        <Message warning onDismiss={dismissAlerts} style={{marginBottom: '40px', fontSize: '16px'}}>
          Note successfully created
        </Message>
        :
        null
      }
    </div>
  )
}

export {alerts}
