import React, {Component} from 'react'
import {Table, Input, TextArea, Dropdown} from 'semantic-ui-react'
import {getImageUrl} from './get-image-url.js'
import QuantitySizeColumns from './quantity-size-columns.js'
import DateColumn from './date-column.js'
import {factoryOptions, shippingClassOptions, ticketsOptions, atOptions, currencyOptions, countryOptions} from '../../vendor-orders/options.js'
import {dateInputStyle} from '../../edit-sales-order/date-inputs.js'
import {dateLabel} from './date-label.js'
import {getValueFromPrefillOrder} from './get-value-from-prefill-order'
import {isNullOrEmptyString} from './get-complete'
import {infoCircle} from '../../cads/status-info-circle'
import {ADD_DAYS_EX_FACTORY_TO_LOADING, ADD_DAYS_EX_FACTORY_TO_RTS} from '../../../modules/constants'

const imageStyle = {maxWidth: '100%', maxHeight: '170px', boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 10px -2px', marginBottom: '20px'}

const longDashIfNull = (value) => {
  if (value == null || value == '') {
    return '—'
  } else {
    return value
  }
}

class CadDetailsRow extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let {
      cadId, index, quantity, size, sizeRatio, cost, msrp, qtyPerPolibag, fabrication, factory, containerNumber, cponumber, comment, fieldOnChange, onChangeQuantity, onChangeSize, onChangeSizeRatio, addSize, removeSize,
      exFactoryDate, overrideOriginalExfactory, cancelDate, delivDate, fobRtsDate, loadingDate, onChangeExFactoryDate, onChangeOverrideOriginalExfactory, onChangeCancelDate, onChangeDelivDate, onChangeFobRtsDate, onChangeLoadingDate,
      shippingClass, tickets, asnxFactoryDate, onChangeAsnxFactoryDate, at, currency, country, casePack, discount,
      fabricWeight, fabricContent, isEditPage
    } = this.props
    let {styleNumbers, brandData, categoryData, colorData, prefillOrders, imageData} = window.newVpoPage.styleNumbersTextarea
    let {fabricationOptions} = window.newVpoPage
    let {imageUrl, imageClass} = getImageUrl(imageData, cadId)

    let defaultSizeRatio = sizeRatio == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'size_ratio') : sizeRatio
    let defaultAt = at == null || at[cadId] == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'at') : at[cadId]
    let defaultCurrency = currency == null || currency[cadId] == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'currency') : currency[cadId]
    let defaultMsrp = msrp == null || msrp[cadId] == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'msrp') : msrp[cadId]
    let defaultQtyPerPolibag = qtyPerPolibag == null || qtyPerPolibag[cadId] == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'qty_per_polibag') : qtyPerPolibag[cadId]
    let defaultCasePack = casePack == null || casePack[cadId] == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'case_pack') : casePack[cadId]
    let defaultValueFabrication = fabrication == null || fabrication[cadId] == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'fabrication') : fabrication[cadId]
    let defaultValueFabricWeight = fabricWeight == null || fabricWeight[cadId] == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'fabric_weight') : fabricWeight[cadId]
    let defaultValueFabricContent = fabricContent == null || fabricContent[cadId] == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'fabric_content') : fabricContent[cadId]


    let defaultValueComment = comment == null || comment[cadId] == null ? getValueFromPrefillOrder(prefillOrders, cadId, 'comment') : comment[cadId]

    const isBulkEdit = cadId == 'BULK EDIT'

    return (
      <Table.Row key={index} style={isBulkEdit ? {background: 'rgba(97, 99, 246, 0.1)'} : {}}>
        <Table.Cell>
          <div style={{whiteSpace: 'nowrap', fontWeight: 'bold', fontSize: (isBulkEdit ? '200%' : '120%')}}>
            {isBulkEdit ? 'BULK EDIT' : styleNumbers[index]}
          </div>
          {isBulkEdit ? null : <div style={{marginBottom: '20px', opacity: 0.5, fontSize: '12px', whiteSpace: 'nowrap'}}>
            brand: {longDashIfNull(brandData[cadId])}<br/>
            category: {longDashIfNull(categoryData[cadId])}<br/>
            color: {longDashIfNull(colorData[cadId])}
          </div>}
          {isBulkEdit ? null : <div>
            <img className={imageClass} style={imageStyle} src={imageUrl}/>
          </div>}
        </Table.Cell>
        <QuantitySizeColumns cadId={cadId}
          quantity={quantity == null ? [null] : quantity}
          size={size == null ? [null] : size}
          sizeRatio={defaultSizeRatio}
          onChangeQuantity={onChangeQuantity}
          onChangeSize={onChangeSize}
          onChangeSizeRatio={onChangeSizeRatio}
          addSize={addSize}
          removeSize={removeSize}
        />
        <Table.Cell>
          {dateLabel('Currency', {}, isNullOrEmptyString(defaultCurrency))}
          <div style={{width: '150px'}}>
            <Dropdown placeholder='Currency' fluid selection search options={currencyOptions}
              onChange={fieldOnChange} name='currency' value={defaultCurrency}
            />
          </div>
          {dateLabel('Cost', {}, cost == null || cost[cadId] == null)}
          <Input placeholder="Cost" name='cost' style={{width: '150px'}}
            value={cost == null || cost[cadId] == null ? '' : cost[cadId]}
            onChange={fieldOnChange} icon={defaultCurrency == 'RMB' ? 'yen' : 'dollar'}
            iconPosition='left' type="number" min="0" step=".01"
          />
          {dateLabel('AT', {}, isNullOrEmptyString(defaultAt))}
          <div style={{width: '150px'}}>
            <Dropdown placeholder='AT' fluid selection search options={atOptions}
              onChange={fieldOnChange} name='at' value={defaultAt}
            />
          </div>
          {dateLabel('MSRP', {marginTop: '5px'}, isNullOrEmptyString(defaultMsrp))}
          <Input placeholder="MSRP" name='msrp' style={{width: '150px'}}
            value={defaultMsrp}
            onChange={fieldOnChange} icon='dollar'
            iconPosition='left' type="number" min="0" step=".01"
          />
          {dateLabel('QTY PER POLIBAG', {marginTop: '5px'}, isNullOrEmptyString(defaultQtyPerPolibag))}
          <Input placeholder="QTY PER POLIBAG" name='qtyPerPolibag' style={{width: '200px'}}
            value={defaultQtyPerPolibag}
            onChange={fieldOnChange} icon='hashtag'
            iconPosition='left' type="number" min="0" step="1"
          />
          {dateLabel('QTY PER CASE PACK', {marginTop: '5px'}, isNullOrEmptyString(defaultCasePack))}
          <Input placeholder="QTY PER CASE PACK" name='casePack' style={{width: '200px'}}
            value={defaultCasePack}
            onChange={fieldOnChange} icon='hashtag'
            iconPosition='left' type="number" min="0" step="1"
          />
        </Table.Cell>
        <DateColumn
          exFactoryDate={exFactoryDate}
          overrideOriginalExfactory={overrideOriginalExfactory}
          cancelDate={cancelDate}
          delivDate={delivDate}
          onChangeExFactoryDate={onChangeExFactoryDate}
          onChangeOverrideOriginalExfactory={onChangeOverrideOriginalExfactory}
          onChangeCancelDate={onChangeCancelDate}
          onChangeDelivDate={onChangeDelivDate}
          cadId={cadId}
          isEditPage={isEditPage}
        />
        <Table.Cell>
          {dateLabel('Fabrication', {}, isNullOrEmptyString(defaultValueFabrication))}
          <div style={{width: '250px'}}>
            <Dropdown placeholder='Fabrication' fluid selection search options={fabricationOptions}
              onChange={fieldOnChange} name='fabrication' value={defaultValueFabrication}
            />
          </div>
          {dateLabel('Fabric Weight', {marginTop: '5px'}, isNullOrEmptyString(defaultValueFabricWeight))}
          <Input placeholder="Fabric Weight" name='fabricWeight' style={{width: '200px'}}
            value={defaultValueFabricWeight}
            onChange={fieldOnChange}
          />
          {dateLabel('Fabric Content', {marginTop: '5px'}, isNullOrEmptyString(defaultValueFabricContent))}
          <Input placeholder="Fabric Content" name='fabricContent' style={{width: '200px'}}
            value={defaultValueFabricContent}
            onChange={fieldOnChange}
          />

          {dateLabel('Factory', {marginTop: '5px'})}
          <div style={{width: '250px'}}>
            <Dropdown placeholder='Factory' fluid selection search options={factoryOptions}
              onChange={fieldOnChange} name='factory' value={factory[cadId] == null ? 'PENDING PI' : factory[cadId]}
            />
          </div>
          {dateLabel('Country of Origin', {marginTop: '5px'}, country[cadId] == null)}
          <div style={{width: '250px'}}>
            <Dropdown placeholder='Country of Origin' fluid selection search options={countryOptions}
              onChange={fieldOnChange} name='country' value={country[cadId]}
            />
          </div>
          {dateLabel('Class', {marginTop: '5px'})}
          <div style={{width: '250px'}}>
            <Dropdown placeholder='Class' fluid selection search options={shippingClassOptions}
              onChange={fieldOnChange} name='shippingClass' value={shippingClass[cadId] == null ? 'NO' : shippingClass[cadId]}
            />
          </div>
          {dateLabel('Tickets', {marginTop: '5px'})}
          <div style={{width: '250px'}}>
            <Dropdown placeholder='Tickets' fluid selection search options={ticketsOptions}
              onChange={fieldOnChange} name='tickets' value={tickets[cadId] == null ? 'NO' : tickets[cadId]}
            />
          </div>
        </Table.Cell>
        <Table.Cell>
          <div style={{display: 'inline-block'}}>
            {dateLabel('ASN X-Factory Date')}
          </div>

          {infoCircle('ASN X-Factory will override ALL OTHER DATES, in QB or ERP!  (Use it like an Updated Ex-Factory)')}

          <input type="date"
            value={asnxFactoryDate}
            onChange={onChangeAsnxFactoryDate}
            style={dateInputStyle}
          />

          <div style={{display: 'inline-block'}}>
            {dateLabel('Loading Date', {marginTop: '5px'})}
          </div>

          {infoCircle('Loading Date is always ' + ADD_DAYS_EX_FACTORY_TO_LOADING + ' days after ASN X-Factory Date')}

          <div style={{opacity: 0.4}}>
            <input type="date"
              value={loadingDate}
              onChange={onChangeLoadingDate}
              style={dateInputStyle}
              disabled
            />
          </div>

          <div style={{display: 'inline-block'}}>
            {dateLabel('FACTORY-RTS Date', {marginTop: '5px'})}
          </div>

          {infoCircle('FACTORY-RTS Date is normally ' + ADD_DAYS_EX_FACTORY_TO_RTS + ' days after Ex-Factory Date. STATUS → Ready-To-Ship. (Only edit from ASN popup!)')}

          <div style={{opacity: 0.4}}>
            <input type="date"
              value={fobRtsDate}
              onChange={onChangeFobRtsDate}
              style={dateInputStyle}
              disabled
            />
          </div>

          {dateLabel('Container #', {marginTop: '5px'}, containerNumber == null || containerNumber[cadId] == null)}
          <Input placeholder="Container #" name='containerNumber' style={{width: '200px'}}
            value={containerNumber == null || containerNumber[cadId] == null ? '' : containerNumber[cadId]}
            onChange={fieldOnChange}
          />
          {dateLabel('Customer PO #', {marginTop: '5px'}, cponumber == null || cponumber[cadId] == null)}
          <Input placeholder="Customer PO #" name='cponumber' style={{width: '200px'}}
            value={cponumber == null || cponumber[cadId] == null ? '' : cponumber[cadId]}
            onChange={fieldOnChange}
          />
          {dateLabel('Discount', {}, discount == null || discount[cadId] == null)}
          <Input placeholder="Discount" name='discount' style={{width: '150px'}}
            value={discount == null || discount[cadId] == null ? '' : discount[cadId]}
            onChange={fieldOnChange} icon='percent'
            iconPosition='right' type="number" min="0" max="100" step="1"
          />
          {dateLabel('Memo', {marginTop: '5px'}, isNullOrEmptyString(defaultValueComment))}
          <TextArea id={'cad-comment-' + cadId} style={{width: '200px', maxWidth: '250px'}}
            rows={5} placeholder='Memo' name='comment'
            value={defaultValueComment}
            onChange={fieldOnChange}
          />
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default CadDetailsRow
