import React from 'react'
import {getStatusColorRGB} from '../../purchase-orders/sales-view/get-status-markup.js'
import {Icon, Image} from 'semantic-ui-react'

const itemStatusOrderSummary = (itemStatus) => {
  if (itemStatus == null || itemStatus.length == 0) {
    return '—'
  } else {
    return itemStatus.map((status, i) => {
      let style = getStyle(status)
      return <div key={`item_status_order_summary-${i}`} style={{marginTop: '-1px'}}><span style={style}>{getIcon(status)} {status}</span></div>
    })
  }
}

const getStyle = (status) => {
  let color = getStatusColorRGB(status)
  let style = {fontSize: '12px', borderRadius: '.28571429rem', display: 'inline-block', fontWeight: 'bold', color: 'white', backgroundColor: color, padding: '0 .833em'}
  return style
}

const itemStatusOrderDetails = (status) => {
  let style = getStyle(status)
  return <div style={{position: 'absolute', marginTop: '-2px'}}><span style={style}>{getIcon(status)} {status}</span></div>
}

const getIcon = (status) => {
  let name, className

  switch(status) {
    case 'Pending':
      name = 'hourglass'
      break
    case 'Processed':
      name = 'cog'
      className = 'spinning-cog padding-left-3px margin-left-neg3px'
      break
    case 'Ready-To-Ship':
      name = 'ship'
      break
    case 'In-Progress':
      name = 'cog'
      className = 'spinning-cog padding-left-3px margin-left-neg3px'
      break
    case 'Partially-Shipped':
      name = 'star half empty'
      break
    case 'Shipped':
      name = 'check'
      break
    case 'Invoiced':
      name = 'dollar'
      break
    case 'Cancelled':
      name = 'trash'
      break
    default:
  }

  if (status == 'QB-Entered') {
    return <Image id='qb-icon-status' src='https://erp-rails-usa-production.s3.amazonaws.com/images/qb_logo_small.png' avatar />
  } else if (name == null) {
    return null
  } else {
    return <span className={className}><Icon name={name}/></span>
  }
}

export {itemStatusOrderSummary, itemStatusOrderDetails, getIcon}
