import React, { Component } from 'react'
import SectionTitle from '../new-vpo-page/section-title'

class TransactionOverview extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let {titleNum} = this.props
    let complete = false
    
    return (
      <div>
        <SectionTitle num={titleNum} title='TRANSACTION OVERVIEW' complete={complete}/>



      </div>
    )
  }
}

export default TransactionOverview
