export const SIMPLE_ACTION = 'SIMPLE_ACTION';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_PACKS = 'UPDATE_PACKS';
export const UPDATE_FUTURE_ATS_MONTHS = 'UPDATE_FUTURE_ATS_MONTHS';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_FABRICATION = 'UPDATE_FABRICATION';
export const UPDATE_DIVISION = 'UPDATE_DIVISION';
export const UPDATE_COLOR = 'UPDATE_COLOR';
export const UPDATE_SEASON = 'UPDATE_SEASON';
export const UPDATE_DATE_FROM = 'UPDATE_DATE_FROM';
export const UPDATE_DATE_TO = 'UPDATE_DATE_TO';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY';
export const UPDATE_SUB2 = 'UPDATE_SUB2';
export const UPDATE_PLAYLIST = 'UPDATE_PLAYLIST';
export const TOGGLE_HIDEABLE_CHECKBOX = 'TOGGLE_HIDEABLE_CHECKBOX';
export const SELECT_ALL_HIDEABLE_CHECKBOX = 'SELECT_ALL_HIDEABLE_CHECKBOX';
export const UPDATE_CAD_STATUS = 'UPDATE_CAD_STATUS';
export const UPDATE_DEFAULT_ACCOUNT = 'UPDATE_DEFAULT_ACCOUNT';
export const UPDATE_LAST_5_SEARCHES = 'UPDATE_LAST_5_SEARCHES';
export const FILTER_APPLICABLE_SUB_CATEGORIES = 'FILTER_APPLICABLE_SUB_CATEGORIES';
export const UPDATE_MARGINS = 'UPDATE_MARGINS';
export const UPDATE_GUEST_FILTERS = 'UPDATE_GUEST_FILTERS';
export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART';
export const REMOVE_FROM_SHOPPING_CART = 'REMOVE_FROM_SHOPPING_CART';
export const BULK_ADD_TO_SHOPPING_CART = 'BULK_ADD_TO_SHOPPING_CART';
export const BULK_REMOVE_FROM_SHOPPING_CART = 'BULK_REMOVE_FROM_SHOPPING_CART';
export const ON_CHANGE_HIDEABLE_INPUT = 'ON_CHANGE_HIDEABLE_INPUT';
export const UPDATE_FACTORY = 'UPDATE_FACTORY';
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';
export const UPDATE_PRINT = 'UPDATE_PRINT'
export const UPDATE_PATTERN = 'UPDATE_PATTERN'
