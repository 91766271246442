import React, { Component } from 'react'
import $ from 'jquery'
import { Form, Icon, Grid, Button, Label, Modal } from 'semantic-ui-react'
import {factoryOptions, buyerOptions, atOptions, countryOptions, shippingClassOptions, ticketsOptions, getSizeRatioOptions} from './options.js'
import {dateInputStyle} from '../edit-sales-order/date-inputs.js'
import {saveBulkEdit, getNonPrepack} from './save-bulk-edit.js'
import {datePlus9ToYYYYMMDD} from '../edit-sales-order/date-inputs.js'
import warehouseOptions from '../cads/warehouse-options'
import {infoCircle} from '../cads/status-info-circle'
import {ADD_DAYS_TO_EX_FACTORY_DATE, ADD_DAYS_EX_FACTORY_TO_LOADING, ADD_DAYS_EX_FACTORY_TO_RTS} from '../../modules/constants'
import {asnInfo} from './bulk-edit-modal/asn-info'

const labelStyle = {fontWeight: 'bold', fontSize: '.92857143em'}
const initialState = {
  factory: null,
  buyer: null,
  exFactoryDate: null,
  asnxFactoryDate: null,
  cancelDate: null,
  delivDate: null,
  loadingDate: null,
  fobRtsDate: null,
  containerNumber: null,
  discount: null,
  cost: null,
  at: null,
  msrp: null,
  qtyPerPolibag: null,
  casePack: null,
  fabrication: null,
  fabricWeight: null,
  fabricContent: null,
  country: null,
  shippingClass: null,
  tickets: null,
  quantity: null,
  siteWarehouse: null,
  sizeRatio: null,
  contact: null
}

const intialStateKeys = Object.keys(initialState)

class BulkEditModal extends Component {
  constructor(props) {
    super(props)
    this.state = initialState

    this.clearModal = this.clearModal.bind(this)
    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.saveBulkChangeCads = this.saveBulkChangeCads.bind(this)
    this.onChangeExFactoryDate = this.onChangeExFactoryDate.bind(this)
    this.onChangeAsnxFactoryDate = this.onChangeAsnxFactoryDate.bind(this)
    this.onChangeCancelDate = this.onChangeCancelDate.bind(this)
    this.onChangeDelivDate = this.onChangeDelivDate.bind(this)
    this.onChangeLoadingDate = this.onChangeLoadingDate.bind(this)
    this.onChangeFobRtsDate = this.onChangeFobRtsDate.bind(this)
  }

  componentDidUpdate() {
    window.bulkEditVpoModal = JSON.parse(JSON.stringify(this.state))
  }

  componentDidMount() {
    window.addEventListener('clear-bulk-modal', this.clearModal)
  }

  clearModal() {
    this.setState(initialState)
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}
    if (value == '') value = null
    newState[name] = value
    this.setState(newState)
  }

  onChangeExFactoryDate(event) {
    let {value} = event.currentTarget
    let exFactoryDate = value
    let cancelDate = datePlus9ToYYYYMMDD(value)
    let delivDate = datePlus9ToYYYYMMDD(value, ADD_DAYS_TO_EX_FACTORY_DATE)
    this.setState({exFactoryDate, cancelDate, delivDate})
  }

  onChangeAsnxFactoryDate(event) {
    let {value} = event.currentTarget
    let asnxFactoryDate = value
    let loadingDate = datePlus9ToYYYYMMDD(value, ADD_DAYS_EX_FACTORY_TO_LOADING)
    this.setState({asnxFactoryDate, loadingDate})
  }

  onChangeCancelDate(event) {
    let {value} = event.currentTarget
    let cancelDate = value
    this.setState({cancelDate})
  }

  onChangeDelivDate(event) {
    let {value} = event.currentTarget
    let delivDate = value
    this.setState({delivDate})
  }

  onChangeLoadingDate(event) {
    let {value} = event.currentTarget
    let loadingDate = value
    this.setState({loadingDate})
  }

  onChangeFobRtsDate(event) {
    let {value} = event.currentTarget
    let fobRtsDate = value
    this.setState({fobRtsDate})
  }

  saveBulkChangeCads() {
    let {results, onlyAsnXfactoryDateInput} = this.props
    let {quantity} = this.state

    let allNullValues = intialStateKeys.map(x => this.state[x]).every(x => x == null)
    if (allNullValues) {
      alert('Please make at least one change!')
    } else {
      let {nonPrepackSizes, nonPrepackRefnumber} = getNonPrepack(results)
      if (nonPrepackSizes && quantity != null && quantity != '') {
        alert('Cannot bulk edit quantity on REF # ' + nonPrepackRefnumber + ' because contains non PREPACK sizes!')
      } else {
        $('#save-bulk-button').addClass('loading disabled')
        saveBulkEdit(onlyAsnXfactoryDateInput)
      }
    }
  }

  render() {
    let {onlyAsnXfactoryDateInput, accountOptions, fabricationOptions} = this.props
    let {
      factory, buyer, containerNumber, exFactoryDate, asnxFactoryDate, cancelDate, delivDate, loadingDate, fobRtsDate, discount,
      cost, at, msrp, qtyPerPolibag, casePack,
      fabrication, fabricWeight, fabricContent, country, shippingClass, tickets, quantity,
      siteWarehouse, sizeRatio, contact
    } = this.state


    const costAndAtInput = (
      <React.Fragment>
        <Form.Input fluid label='Cost' name='cost' iconPosition='left' icon='dollar' type="number" min="0" step="0.01" onChange={this.fieldOnChange} placeholder='Cost' value={cost} />
        <Form.Select fluid label='AT' name='at' onChange={this.fieldOnChange} options={atOptions} placeholder='AT' value={at} />
      </React.Fragment>
    )

    const asnXfactoryDateInput = (
      <React.Fragment>
        <label style={labelStyle}>ASN X-Factory Date</label>

        {infoCircle('ASN X-Factory will override ALL OTHER DATES, in QB or ERP!  (Use it like an Updated Ex-Factory)')}

        <input type="date"
          value={asnxFactoryDate}
          onChange={this.onChangeAsnxFactoryDate}
          style={dateInputStyle}
        />
      </React.Fragment>
    )

    const loadingDateInput = (
      <React.Fragment>
        <label style={labelStyle}>Loading Date</label>

        {infoCircle('Loading Date is always ' + ADD_DAYS_EX_FACTORY_TO_LOADING + ' days after ASN X-Factory Date')}

        <div style={{opacity: 0.4}}>
          <input type="date"
            value={loadingDate}
            onChange={this.onChangeLoadingDate}
            style={dateInputStyle}
            disabled
          />
        </div>
      </React.Fragment>
    )

    const fobRtsDateInput = (
      <React.Fragment>
        <label style={labelStyle}>FACTORY-RTS Date</label>

        {infoCircle('FACTORY-RTS Date is normally ' + ADD_DAYS_EX_FACTORY_TO_RTS + ' days after Ex-Factory Date. STATUS → Ready-To-Ship')}

        <input type="date"
          value={fobRtsDate}
          onChange={this.onChangeFobRtsDate}
          style={dateInputStyle}
        />
      </React.Fragment>
    )

    const qtyInput = (
      <Form.Input fluid label='Quantity'  name='quantity' iconPosition='left' icon='hashtag' type="number" min="0" step="1" onChange={this.fieldOnChange} placeholder='Quantity' value={quantity} />
    )

    let sizeRatioOptions = getSizeRatioOptions()

    const sizeRatioInput = (width) => {
      return (
        <Form.Select fluid label='Size Ratio' name='sizeRatio' onChange={this.fieldOnChange} options={sizeRatioOptions} placeholder='Size Ratio' value={sizeRatio} style={{width: width}}/>
      )
    }

    const cancelDateInput = (
      <React.Fragment>
        <label style={labelStyle}>Cancel Date</label>
        <input type="date"
          value={cancelDate}
          onChange={this.onChangeCancelDate}
          style={dateInputStyle}
        />
      </React.Fragment>
    )

    const exFactoryDateInput = (
      <React.Fragment>
        <label style={labelStyle}>Ex-Factory Date</label>
        <input type="date"
          value={exFactoryDate}
          onChange={this.onChangeExFactoryDate}
          style={dateInputStyle}
        />
      </React.Fragment>
    )

    const factoryInput = (
      <Form.Select search fluid label='Factory' name='factory' onChange={this.fieldOnChange}
        options={factoryOptions} placeholder='Factory' value={factory}
      />
    )

    const contactInput = (
      <Form.Select search fluid label='Contact' name='contact' onChange={this.fieldOnChange}
        options={accountOptions} placeholder='Contact' value={contact}
      />
    )

    return (
      <Modal size='large' open={this.props.open} onClose={this.props.onClose} closeIcon>
        <Modal.Header>{onlyAsnXfactoryDateInput ? 'Advance Shipping Notice (ASN)' : 'Bulk edit items'}</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.saveBulkChangeCads} id="my-bulk-form" style={{marginBottom: '50px'}}>
            {
              onlyAsnXfactoryDateInput
              ?
              asnInfo(
                contactInput, factoryInput, costAndAtInput, qtyInput, sizeRatioInput,
                exFactoryDateInput, cancelDateInput, asnXfactoryDateInput, loadingDateInput, fobRtsDateInput
              )
              :
              <React.Fragment>
                <Form.Group widths='equal' style={{width: '41%'}}>
                  {contactInput}
                </Form.Group>
                <Form.Group widths='equal' style={{marginTop: '20px'}}>
                  {factoryInput}
                  <Form.Select fluid label='Buyer' name='buyer' onChange={this.fieldOnChange} options={buyerOptions} placeholder='Buyer' value={buyer} />
                  <Form.Input fluid label='Discount' name='discount' icon='percent' type="number" min="0" max="100" step="0.01" onChange={this.fieldOnChange} placeholder='Discount' value={discount} />
                  <Form.Input fluid label='Container #' name='containerNumber' onChange={this.fieldOnChange} placeholder='Container #' value={containerNumber} />
                  {qtyInput}
                </Form.Group>

                <Form.Group widths='equal' style={{marginTop: '20px'}}>
                  {costAndAtInput}
                  <Form.Input fluid label='MSRP' name='msrp' iconPosition='left' icon='dollar' type="number" min="0" step="0.01" onChange={this.fieldOnChange} placeholder='MSRP' value={msrp} />
                  <Form.Input fluid label='QTY per Polibag' name='qtyPerPolibag' iconPosition='left' icon='hashtag' type="number" min="0" step="1" onChange={this.fieldOnChange} placeholder='QTY per Polibag' value={qtyPerPolibag} />
                  <Form.Input fluid label='QTY per Case Pack' name='casePack' iconPosition='left' icon='hashtag' type="number" min="0" step="1" onChange={this.fieldOnChange} placeholder='QTY per Case Pack' value={casePack} />
                </Form.Group>

                <Form.Group widths='equal' style={{marginTop: '20px'}}>
                  <Form.Select fluid label='Fabrication' name='fabrication' onChange={this.fieldOnChange} options={fabricationOptions == null ? [] : fabricationOptions} placeholder='Fabrication' value={fabrication} />
                  <Form.Input fluid label='Fabric Weight' name='fabricWeight' onChange={this.fieldOnChange} placeholder='Fabric Weight' value={fabricWeight} />
                  <Form.Input fluid label='Fabric Content' name='fabricContent' onChange={this.fieldOnChange} placeholder='Fabric Content' value={fabricContent} />
                  <Form.Select fluid label='Country of Origin' name='country' onChange={this.fieldOnChange} options={countryOptions} placeholder='Country of Origin' value={country} />
                  <Form.Select fluid label='Class' name='shippingClass' onChange={this.fieldOnChange} options={shippingClassOptions} placeholder='Class' value={shippingClass} />
                </Form.Group>

                <Form.Group widths='equal' style={{marginTop: '20px', width: '61%'}}>
                  <Form.Select fluid label='Tickets' name='tickets' onChange={this.fieldOnChange} options={ticketsOptions} placeholder='Tickets' value={tickets} />
                  <Form.Select fluid label='Site / Warehouse' name='siteWarehouse' onChange={this.fieldOnChange} options={warehouseOptions.filter(x => x.key != 'MISSING')} placeholder='Site / Warehouse' value={siteWarehouse} />
                  {sizeRatioInput('206%')}
                </Form.Group>

                <Grid columns={5} stackable style={{marginRight: 0, marginTop: '-7px'}}>
                  <Grid.Column style={{paddingRight: '5px'}}>
                    {exFactoryDateInput}
                  </Grid.Column>

                  <Grid.Column style={{paddingRight: '5px'}}>
                    {cancelDateInput}
                  </Grid.Column>
                  <Grid.Column style={{paddingRight: '5px'}}>
                    <label style={labelStyle}>Deliv Date</label>

                    {infoCircle('Deliv Date is always ' + ADD_DAYS_TO_EX_FACTORY_DATE + ' days after Ex-Factory Date')}

                    <div style={{opacity: 0.4}}>
                      <input type="date"
                        value={delivDate}
                        onChange={this.onChangeDelivDate}
                        style={dateInputStyle}
                        disabled
                      />
                    </div>
                  </Grid.Column>
                </Grid>
              </React.Fragment>
            }
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button id="save-bulk-button" type="submit" form="my-bulk-form" primary>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default BulkEditModal
