import Utils from "../../modules/utils"

const defaultContactName = 'IHL Inventory'
const nonInventoryId = 670

const accountsSort = (results) => {
  results = results.sort((a, b) => {
    let a_val = (a.name+' (' + a.primaryContact.name + ')')
    let b_val = (b.name+' (' + b.primaryContact.name + ')')

    if (a_val > b_val) return 1
    if (b_val > a_val) return -1

    return 0
  })

  return results
}

const getContacts = (callbackFunc) => {
  Utils.get('accounts', (err, res) =>
  {
    if (err) {
      alert('There was an error when trying to get the accounts.')
    } else {
      let {accounts} = res.data
      let accountOptions = accountsSort(accounts).filter(x => x.id != nonInventoryId && x.status != 'inactive').map(x => {return ({ key: x.id, text: (x.name+' (' + x.primaryContact.name + ')'), value: x.id })})

      accountOptions.unshift({key: nonInventoryId, text: 'Non-Inventory', value: nonInventoryId})
      accountOptions.unshift({key: defaultContactName, text: defaultContactName, value: defaultContactName})

      callbackFunc(accountOptions)
    }
  })
}

export {getContacts, defaultContactName}
