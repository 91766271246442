import React           from 'react';
import PropTypes       from 'prop-types';
import { Form } from 'semantic-ui-react';

export class DataFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: ''
    };
  }

  handleOnChange = (event, { name, value }) => {
      this.setState({ [name]: value});
      this.props.onSubmitFilter(value);
  };

  render() {
    const { filter } = this.state;

    return (
      <Form style={this.props.style ? {...this.props.style} : {}}>
        <Form.Group>
          <Form.Field style={{width: '100%'}}>
            <Form.Input
              placeholder="Search..."
              name="filter"
              value={filter}
              onChange={this.handleOnChange}
              icon="search"
              loading={this.props.loading}
              style={{width: '100%'}}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

DataFilter.propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired
};
