import checkPin from './check-pin'

const digest = 'NDYzMTAx'
const tenMinutes = 10 * 60
const hundredMinutes = tenMinutes * 10

const checkAdminPin = () => {
  let storedPin = window.sessionStorage.getItem('storedPin')
  let storedPinTime = Number(window.sessionStorage.getItem('storedPinTime'))

  if (storedPin != null && pinIsFresh(storedPinTime)) {
    checkPin(storedPin, (isValid) => {
      if (isValid) {
        sendSetAdminPinTrueEvent()
        setPinTimeout()
      }
    })
  }
}

const savePin = (pin) => {
  checkPin(pin, (isValid) => {
    if (isValid) {
      window.sessionStorage.setItem('storedPin', pin)
      window.sessionStorage.setItem('storedPinTime', timeNow())
      sendSetAdminPinTrueEvent()
      setPinTimeout()
    } else {
      alert('Incorrect pin')
    }
  })

  // let encodedString = btoa(pin)
  // if (encodedString == digest) {
  //   window.sessionStorage.setItem('storedPin', pin)
  //   window.sessionStorage.setItem('storedPinTime', timeNow())
  //   sendSetAdminPinTrueEvent()
  //   setPinTimeout()
  // } else {
  //   alert('Incorrect pin')
  // }
}

const pinIsFresh = (storedPinTime) => {
  if (window.location.href.includes('localhost')) {
    return (timeNow() - storedPinTime) < hundredMinutes
  } else {
    return (timeNow() - storedPinTime) < tenMinutes
  }
}

const timeNow = () => {
  return Math.round(Date.now() / 1000)
}

const sendSetAdminPinTrueEvent = () => {
  window.dispatchEvent(new Event('set-admin-pin-true'))
}

const sendSetAdminPinFalseEvent = () => {
  window.dispatchEvent(new Event('set-admin-pin-false'))
}

const setPinTimeout = () => {
  setTimeout(() => {
    let storedPinTime = Number(window.sessionStorage.getItem('storedPinTime'))
    if (pinIsFresh(storedPinTime)) {
      setPinTimeout()
    } else {
      sendSetAdminPinFalseEvent()
    }
  }, 10000)
}

export {digest, checkAdminPin, savePin, sendSetAdminPinTrueEvent}
