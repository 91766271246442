import React      from 'react'
import $ from 'jquery';
import {Label, Table, Popup, Button, Icon, Modal, Loader, Dimmer, Input, Image} from 'semantic-ui-react'
import Utils              from './../../../modules/utils'
import SimpleTable        from './../../ui/simple-table'
import './sales-view.css'
import {Auth0Context} from "./../../../contexts/auth-context"
import UploadDistroModal  from '../upload-distro-modal'
import getDistFileContent  from '../dist-file-content.js'
import EditShipToPopup  from '../edit-ship-to-popup.js'
import NotePopup from '../note-popup.js'
import CombinedPopup from '../combined-popup.js'
import AutoEditedPopup from '../auto-edited-popup.js'
import QbSyncedPopup from '../qb-synced-popup.js'
import isUserRole from './../is-user-role.js'
import getHeadings from './get-headings.js'
import {getStatuses} from './../po-filter.js'
import InventoryPrintPopup from './inventory-print-popup.js'
import {getMarginPopup} from './margins.js'
import {getStylesDisplayCell} from './get-styles-display-cell.js'
import {getEarliestExFactory, getExFactoryDisplayCell, getVponumber, getVponumberDisplayCell} from './ex-factory.js'
import {getItemStatus, getItemStatusDisplayCell} from './item-status.js'
import {getStatusMarkup} from './get-status-markup.js'
import {ticketsPopup} from './tickets-popup'
import {upfrontPopup} from './upfront-popup'

const salesButtonStyle = {marginBottom: '4px', padding: '4px', fontSize: '16px'}

class SalesPurchaseOrders extends React.Component
{
  static contextType = Auth0Context

  constructor(props)
  {
    super(props)
    this.state = {}

    this.selectRow = this.selectRow.bind(this);
    this.qbEnterPO = this.qbEnterPO.bind(this)
    this.invoicePO = this.invoicePO.bind(this)
    this.pickUpScheduledPO = this.pickUpScheduledPO.bind(this)
  }

  componentDidMount() {}

  downloadBol = (id) => {
    $('#download-bol-button-'+id).addClass('loading')

    let url = "/purchase_orders/1/purchase_order_files/PO-" + id + "/download.json?file_type=bol"

    Utils.get(url, (err, res) =>
    {
      $('#download-bol-button-'+id).removeClass('loading')
      if (err) {
        alert('There was an error when trying to download BOL.')
      } else {
        if (res.data.success) {
          window.open(res.data.filePath, '_blank', "height=700,width=700")
        }
      }
    })
  }

  downloadSalesOrder = (id) => {
    $('#download-order-details-button-'+id).addClass('loading')

    Utils.get('sales_order_details/' + id, (err, res) =>
    {
      $('#download-order-details-button-'+id).removeClass('loading')
      if (err) {
        alert('There was an error when trying to download details.')
      } else {
        if (res.data.success) {
          window.location = res.data.publicUrl
        }
      }
    })
  }

  qbEnterPO = (id) => {
    if (window.confirm('Are you sure you want to mark order as QB-Entered?')) {
      this.props.markQbEntered(id)
    }
  }

  invoicePO = (id) => {
    if (window.confirm('Are you sure you want to mark order as Invoiced?')) {
      this.props.markInvoiced(id)
    }
  }

  pickUpScheduledPO = (id) => {
    if (window.confirm('Are you sure you want to mark order as Pick-Up-Scheduled?')) {
      this.props.markPickUpScheduled(id)
    }
  }

  selectRow(poId) {
    var checked = $('#po-checkbox-'+poId).is(':checked')
    if (checked) {
      window.poIds[poId] = true
    } else {
      delete window.poIds[poId]
    }

    this.props.updateNumSelected()
    this.forceUpdate()
  }

  downloadFile(filename, id) {
    let foldername = filename.split('/')[0]
    let downloadUrl = process.env.REACT_APP_API_URL + '/api/download_order_file/' + id + '?file=' + foldername
    return {url: downloadUrl, onClick: ()=>window.open(downloadUrl, '_blank', "height=700,width=700")}
  }

  downloadFileButtons = (po) => {
    const filenameArry = !!po.filename ? po.filename.split('@@@') : []
    return filenameArry.map((filename, index) => {
      const downloader = this.downloadFile(filename, po.id)

      let dateText
      let oldFile = filename.startsWith('upload_') || filename.startsWith('distribution_')
      if (oldFile) {
        //do nothing
      } else {
        let dateString = filename.split('/')[0].substr(32)
        var d = new Date(0) // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(dateString)
        dateText = <small>(Uploaded: {d.toLocaleDateString()})</small>
      }

      return(
        <Popup
          content={
            <div>
              <h4>
                PO file
                {
                  filenameArry.length == 1
                  ?
                  null
                  :
                  <span>&nbsp;#{index+1}</span>
                }
                &nbsp;&nbsp;&nbsp; {dateText}
              </h4>
              <iframe src={downloader.url} height="150px" width="150px" style={{marginLeft:"-12px", border: "1px solid rgb(204,204,204)", marginTop: "-9px", marginBottom: "-14px", background:"white", overflow:"hidden"}} scrolling='no'></iframe>
            </div>
          }
          position='bottom right'
          trigger={
            <Button
              id={"download-order-details-button-" + po.id}
              icon='file pdf outline'
              onClick={downloader.onClick}
              style={salesButtonStyle}
            />
          }
        />
      )
    })
  }

  downloadPickUpFile(po) {
    let {id, pickUpScheduledFilename} = po
    let foldername = pickUpScheduledFilename.split('/')[0]
    let url = process.env.REACT_APP_API_URL + '/api/download_order_file/' + id + '?is_pick_up_file=true&file=' + foldername
    window.open(url, '_blank', "height=700,width=700")
  }

  render()
  {
    var {user, customer} = this.context
    let {isWarehouse, isInventory, isAdmin, isSalesAssistant} = isUserRole(user)
    let statuses = getStatuses(isInventory || isAdmin)
    let {purchaseOrders} = this.props
    let distinctAccounts = (customer == null ? [] : customer.distinctAccounts)
    let users = (customer == null ? [] : customer.users)
    let headings = getHeadings(
      this.props.showDetails,
      this.props.showShippingClass,
      this.props.showVponumber,
      this.props.showExFactory,
      this.props.activeIndex, isWarehouse, isInventory || isAdmin, isSalesAssistant)

    let total_quantity = 0
    let styles = []

    if (purchaseOrders != null) {
      for (let i=0; i<purchaseOrders.length; i++) {
        let x = purchaseOrders[i].unitTotal
        if (x != null) {
          total_quantity += x
        }

        let y = purchaseOrders[i].cadStyleNumbers
        if (y != null) {
          styles = styles.concat(y)
        }
      }
    }
    if (total_quantity != 0) {
      total_quantity = total_quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    styles =  [...new Set(styles)];
    let total_styles   = styles.length;



    let rows = purchaseOrders.length > 0 ? purchaseOrders.map((po, i) =>
    {
      let account_name, user_name
      if (isWarehouse || isInventory || isAdmin || isSalesAssistant) {
        let user = users.find(x => x.id == po.userId)
        user_name = (user == null ? '' : user.name)
      }

      if (!(isWarehouse || isInventory || isAdmin)) {
        let account = distinctAccounts.find(x => x.id == po.accountId)
        account_name = (account == null ? '' : `${account.name} (${account.mainContactPersonName})`)
      }

      let distributionFilenames = (po.distributionFilename == null ? null : po.distributionFilename.split('@@@'))

      let distFileContent = getDistFileContent(po.id, distributionFilenames, this.props.distroUrl, this.props.downloadDistro, false, isWarehouse)

      let cells =
      {
        "ponumber":
        {
          value        : po.ponumber,
          displayCell  : (
            <Table.Cell key={`ponumber-${i}`} style={{whiteSpace: 'nowrap'}} className="simple-table-first-column">
              <div className="ui checkbox cad-checkbox-num">
                <input id={'po-checkbox-'+po.id} class='po-checkbox' onChange={this.selectRow.bind(this, po.id)} type="checkbox" />
                <label for={'po-checkbox-'+po.id} style={{cursor: 'pointer', fontWeight: 'bold', fontSize: '16px'}}>
                  {po.ponumber}
                </label>
              </div>

              {
                isInventory || isAdmin
                ?
                <InventoryPrintPopup po={po} updatePrinted={this.props.updatePrinted}/>
                :
                null
              }

              {
                po.flaggedForUpdate && !isWarehouse
                ?
                <Popup
                  size='mini'
                  content='This order is flagged for update in QB'
                  position='bottom left'
                  trigger={
                    <Icon name="flag" style={{color: 'red', marginLeft: '10px', fontSize: '20px'}}/>
                  }
                />
                :
                null
              }

              <AutoEditedPopup po={po} />

              <QbSyncedPopup po={po} />

              <CombinedPopup po={po} isWarehouse={isWarehouse} header='This is a combined order'/>

              {isWarehouse ? null :
                <NotePopup header='Message' myHtml={po.richTextMessageHtml} />
              }

              {ticketsPopup(po)}
              {upfrontPopup(po)}

              <EditShipToPopup po={po} updateShipTo={this.props.updateShipTo} isInventory={isInventory || isAdmin}/>

              <NotePopup header='Needs fulfillment note' myHtml={po.needsFulfillmentMessageHtml} />

            </Table.Cell>
          )
        },
        "customer_order_number":
        {
          value        : po.customerOrderNumber,
          displayCell  : <Table.Cell key={`customer_order_number-${i}`}>{po.customerOrderNumber == '' || po.customerOrderNumber == null ? '—' : po.customerOrderNumber}</Table.Cell>
        },
        "contact_name":
        (
          po.account == null ?
          {
            value        : account_name,
            displayCell  : <Table.Cell key={`contact_name-${i}`}><div style={{maxWidth: '400px'}}>{account_name}</div></Table.Cell>
          }
          :
          {
            value        : po.account.name,
            displayCell  : <Table.Cell key={`contact_name-${i}`}>{`${po.account == null ? null : po.account.name} (${po.account.primaryContact == null ? null : po.account.primaryContact.name})`}</Table.Cell>
          }
        ),
        "user_name":
        {
          value        : user_name,
          displayCell  : <Table.Cell key={`user_name-${i}`}>{user_name}</Table.Cell>
        }
        ,
        "quantity":
        {
          value        : po.unitTotal,
          displayCell  : (this.props.showDetails ?
                            <Table.Cell key={`quantity-${i}`} style={{whiteSpace: 'nowrap', verticalAlign: 'top'}}>
                              {po.quantity == null ? null : po.quantity.map((row, j) => {
                                return <div key={`quantity-${i}-${j}`}>{row == null ? '—' : row.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                              })}
                              <div style={{borderBottom: '2px solid black', position: 'relative', right: '0.4vw'}}></div>
                              <span style={{position: 'relative'}}><b style={{position: 'absolute', right: '20px'}}>Totals:</b></span>
                              <div>{po.unitTotal == null ? null : po.unitTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            </Table.Cell>
                          :
                            <Table.Cell key={`quantity-${i}`}>{po.unitTotal == null ? null : po.unitTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Table.Cell>
                          )
        },
        "status":
        {
          value        : po.status,
          displayCell  : <Table.Cell key={`status-${i}`} style={{whiteSpace: 'nowrap'}}>{getStatusMarkup(po.status)}</Table.Cell>
        },

        "amount":
        {
          value        : po.grossAmount,
          displayCell  : <Table.Cell key={`amount-${i}`}>${Number(po.grossAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Table.Cell>
        },

        "start_ship_date":
        {
          value        : po.startShippingDate,
          displayCell  : <Table.Cell key={`start_ship_date-${i}`}>{Utils.formatSimpleDate(po.startShippingDate)}</Table.Cell>
        },

        "cancel_date":
        {
          value        : po.cancelDate,
          displayCell  : <Table.Cell key={`cancel_date-${i}`}>{Utils.formatSimpleDate(po.cancelDate)}</Table.Cell>
        },

        "pick_up_date":
        {
          value        : po.pickUpScheduledDate,
          displayCell  : <Table.Cell key={`pick_up_date-${i}`}>{Utils.formatSimpleDate(po.pickUpScheduledDate)}</Table.Cell>
        },

        "created_date":
        {
          value        : po.createdAt,
          displayCell  : <Table.Cell key={`created_date-${i}`}><span style={{whiteSpace: 'nowrap'}}>{po.createdAtDate} &nbsp;&nbsp;&nbsp; {po.createdAtTime == null ? null : po.createdAtTime.replace('EST','')}</span></Table.Cell>
        },
        "updated_at":
        {
          value        : po.updatedAt,
          displayCell  : <Table.Cell key={`updated_at-${i}`}><span style={{whiteSpace: 'nowrap'}}>{Utils.formatSimpleDate(po.updatedAt)}</span></Table.Cell>
        },

        // "rep":
        // {
        //   value        : po.rep.name,
        //   displayCell  : <Table.Cell key={`rep-${i}`}>{po.rep.name}</Table.Cell>
        // },

        "styles":
        {
          value        : (po.cadStyleNumbers == null ? null : po.cadStyleNumbers[0]),
          displayCell  : getStylesDisplayCell(po, isInventory || isAdmin, i, this.props)
        },

        "item_status":
        {
          value: getItemStatus(po),
          displayCell: getItemStatusDisplayCell(po, i)
        },

        "vponumber":
        {
          value: getVponumber(po),
          displayCell: getVponumberDisplayCell(po, i, this.props.showDetails)
        },

        "ex_factory_date":
        {
          value: getEarliestExFactory(po),
          displayCell: getExFactoryDisplayCell(po, i, this.props.showDetails)
        },

        "price":
        {
          value        : po.grossAmount,
          displayCell  : <Table.Cell key={`price-${i}`} style={{whiteSpace: 'nowrap', verticalAlign: 'top'}}>
                           {(po.price == null ? [] : po.price).map((row, j) => {
                             return (
                               <div key={`price-${i}-${j}`}>
                                 ${Number(row).toFixed(2)}&nbsp;{getMarginPopup(po, j)}
                               </div>
                             )
                           })}
                           <div style={{borderBottom: '2px solid black', position: 'relative', right: '0.4vw'}}></div>
                           <div>${Number(po.grossAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
                         </Table.Cell>
        },
        "margins": po.margins,
        "image_urls": po.image_urls,
        "routing_number":
        {
          value : po.routingNumber,
          displayCell: <Table.Cell key={`routing_number-${i}`}><div style={{maxWidth: '200px'}}>{po.routingNumber}</div></Table.Cell>
        },
        "accountId":
        {
          value : po.accountId
        },
        "actions":
        {
          value        : null,
          displayCell  : (
            isWarehouse ?
            <Table.Cell key={`actions-${i}`}>
              <Popup content='Download packing slip' position='bottom right' trigger={
                <Button id={"download-packing-slip-button-" + po.id} icon='box' onClick={this.props.downloadPackingSlip.bind(null, po.id)}/>
              } />

              {
                ['Needs-Fulfillment', 'In-Progress', 'Ready-To-Ship'].includes(po.status)
                ?
                <Popup content='Add routing number' position='bottom right' trigger={
                  <Button primary icon='map pin' onClick={this.props.addRoutingNumber.bind(null, po.id)}/>
                } />
                :
                null
              }

              {
                po.pickUpScheduledFilename != null
                ?
                <Popup content='Download Pick-Up file' position='bottom right' trigger={
                  <Button icon='clock outline' onClick={this.downloadPickUpFile.bind(null, po)}/>
                } />
                :
                null
              }

              {
                ['Routed', 'Pick-Up-Scheduled'].includes(po.status)
                ?
                <Popup content='Upload BOL' position='bottom right' trigger={
                  <Button primary icon='truck' onClick={this.props.addBol.bind(null, po.id)}/>
                } />
                :
                null
              }

              {
                po.status == 'Shipped'
                ?
                <Popup content='Download BOL' position='bottom right' trigger={
                  <Button id={"download-bol-button-" + po.id} icon='truck' onClick={this.downloadBol.bind(null, po.id)}/>
                } />
                :
                null
              }
            </Table.Cell>
            :
            <Table.Cell key={`actions-${i}`} style={{whiteSpace: 'nowrap'}}>
              <Popup content='Edit' position='bottom right' size='mini' trigger={
                <a style={salesButtonStyle} target="_blank" href={"/#/sales-orders/" + po.id + '/edit'}><Icon name="pencil"/></a>
              } />
              <Popup content='View contact' position='bottom right' size='mini' trigger={
                <a style={salesButtonStyle} target="_blank" href={"/#/contacts/" + po.accountId}><Icon name="user outline"/></a>
              } />
              <Popup content='Download order details' position='bottom right' size='mini' trigger={
                <Button id={"download-order-details-button-" + po.id} icon='file excel' style={salesButtonStyle} onClick={this.downloadSalesOrder.bind(null, po.id)}/>
              } />
              {this.downloadFileButtons(po)}

              {
                po.status == 'Cancelled'
                ?
                null
                :
                distFileContent == null
                ?
                <Popup
                  trigger={
                    <Button style={salesButtonStyle} id={'upload-distro-'+po.id} primary icon='sitemap' onClick={(e) => this.props.openUploadDistroModal(e, po)} />
                  }
                  content='Upload distro'
                  position='bottom right'
                  size='mini'
                />
                :
                distFileContent
              }

              {
                po.status == 'Pending'
                ?
                <Popup content='Mark as QB-Entered' position='bottom right' size='mini' trigger={
                  <Button id={"qb-entered-button-" + po.id} color="orange" style={salesButtonStyle} onClick={this.qbEnterPO.bind(null, po.id)}>
                    <Image id='qb-icon-status' src='https://erp-rails-usa-production.s3.amazonaws.com/images/qb_logo_small.png' avatar style={{marginTop: '-2px', marginBottom: '-1px', marginRight: '1px'}}/>
                  </Button>
                } />
                :
                null
              }



              {
                po.status == 'Pending'
                ?
                null
                :
                po.packingSlipFilename != null
                ?
                <Popup content='Download packing slip' position='bottom right' size='mini' trigger={
                  <Button id={"download-packing-slip-button-" + po.id} icon='box' style={salesButtonStyle} onClick={this.props.downloadPackingSlip.bind(null, po.id)}/>
                } />
                :
                po.status == 'Cancelled'
                ?
                null
                :
                <Button.Group style={{marginRight: '4px'}}>
                  <Popup content='Upload packing slip' position='bottom right' size='mini' trigger={
                    <Button id={"upload-packing-slip-button-" + po.id} color="blue" icon='box' style={salesButtonStyle} onClick={this.props.addPackingSlip.bind(null, po)}/>
                  } />
                  <Popup content='Generate packing slip' position='bottom right' size='mini' trigger={
                    <Button id={"generate-packing-slip-button-" + po.id} color="green" icon='box' style={salesButtonStyle} onClick={this.props.generatePackingSlip.bind(null, po)}/>
                  } />
                </Button.Group>
              }

              {"Routed" == po.status ?
                <Popup content='Mark as Pick-Up-Scheduled' position='bottom right' size='mini' trigger={
                  <Button id={"pick-up-scheduled-order-button-" + po.id} color="orange" icon='clock outline' style={salesButtonStyle} onClick={this.pickUpScheduledPO.bind(null, po.id)}/>
                } />
                : null
              }

              {["Shipped", "Cancelled", "Pending", "Invoiced"].indexOf(po.status) != -1 ? null :
                <Popup content='Mark as Shipped' position='bottom right' size='mini' trigger={
                  <Button id={"ship-order-button-" + po.id} color="green" icon='check' style={salesButtonStyle}
                    onClick={() => this.props.markShippedCancel(po.id, 0)}/>
                } />
              }

              {"Shipped" == po.status ?
                <Popup content='Mark as Invoiced' position='bottom right' size='mini' trigger={
                  <Button id={"invoice-order-button-" + po.id} color="teal" icon='dollar' style={salesButtonStyle} onClick={this.invoicePO.bind(null, po.id)}/>
                } />
                : null
              }

              {["Shipped", "Cancelled", "Invoiced"].indexOf(po.status) != -1 ? null :
                <Popup content='Cancel' position='bottom right' size='mini' trigger={
                  <Button id={"cancel-order-button-" + po.id} color='black' icon='trash' style={salesButtonStyle}
                    onClick={() => this.props.markShippedCancel(po.id, 1)}/>
                } />
              }
            </Table.Cell>
          )
        },
        "id": po.id,
        cadStyleNumbers: po.cadStyleNumbers,
        vendorPonumber: po.vendorPonumber,
        greyedOut: po.greyedOut,
        "account":
        {
          value: po.warehouseAccountName,
          displayCell: <Table.Cell key={`account-${i}`}>{po.warehouseAccountName}</Table.Cell>
        },
        needs_fulfillment_message: {value: po.needsFulfillmentMessageHtml},
        shipping_class:
        {
          value: po.shippingClass,
          displayCell: <Table.Cell key={`shipping_class-${i}`}>{po.shippingClass == null || po.shippingClass == '' ? '—' : po.shippingClass}</Table.Cell>
        }
      }

      return cells

    }) : []


    let markup =
      <div>
        {
          this.props.bolLoading || this.props.routingNumberLoading
          ?
          <Dimmer active={true}>
            <Loader />
          </Dimmer>
          :
          null
        }

        <div style={{padding: '1rem 0'}}>
          {
            isWarehouse || isInventory || isAdmin
            ?
            null
            :
            <span>
              <Label size='medium'>
                # styles
                <Label.Detail>
                  {total_styles}
                </Label.Detail>
              </Label>

              <Label size='medium'>
                Total qty
                <Label.Detail>
                  {total_quantity}
                </Label.Detail>
              </Label>
            </span>
          }
        </div>

        <SimpleTable
          showImage={this.props.showImage}
          showShippingClass={this.props.showShippingClass}
          showDetails={this.props.showDetails}
          rows={rows}
          headings={headings}
          currentSortColumn={isInventory || isAdmin ? 'created_date' : 'start_ship_date'}
          noContentMessage='No Sales Orders found.'
          pagination={true}
          pageLimit={100}
          updateSelected={this.props.updateSelected}
        />

        <UploadDistroModal
          uploadDistroModal={this.props.uploadDistroModal}
          closeUploadDistroModal={this.props.closeUploadDistroModal}
          currentPO={this.props.currentPO}
          />
      </div>

    return markup;
  }
}

export default SalesPurchaseOrders;
