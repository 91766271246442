import React from 'react'

const brandApprovalContent = (brandApproval, approvalBrands) => {
  let approvedList = [], notApprovedList = []
  if (brandApproval != null) {
    approvedList = Object.keys(brandApproval)
    notApprovedList = approvalBrands.filter((x) => {
      return approvedList.indexOf(x) === -1
    })
  }

  return (
    brandApproval == null ? null : (
      notApprovedList.length == 0 && approvedList.length == 0 ? null :
      <div style={{paddingLeft: '375px'}}>
        <small style={{fontWeight: 'bold', minWidth: '400px'}} class="ui pointing above prompt label">
          {approvedList.length == 0 ? null :
            <div style={{color: 'green'}}>
              This account is approved for:
              <ul>
                {approvedList.map((brand, index) => {
                  let categories = brandApproval[brand].join(', ')
                  return (
                    <li key={index}>
                      {brand} (category: {categories == "" ? 'ALL' : categories})
                    </li>
                  )
                })}
              </ul>
            </div>
          }

          {notApprovedList.length == 0 || approvedList.length == 0 ? null :
            <div style={{borderTop: '1px solid rgb(222,222,222)', marginBottom: '15px'}}></div>
          }

          {notApprovedList.length == 0 ? null :
            <div style={{color: 'red'}}>
              This account is NOT approved for:
              <ul>
                {notApprovedList.map((brand, index) => {
                  return (
                    <li key={index}>
                      {brand}
                    </li>
                  )
                })}
              </ul>
            </div>
          }
        </small>
      </div>
    )
  )
}

export {brandApprovalContent}
