import React, {Component} from 'react'

const titleStyle = {verticalAlign: 'top', marginTop: '2px', display: 'inline-block'}
const titleStyleLink = Object.assign({}, titleStyle, {cursor: 'pointer', fontWeight: 'bold'})

class SectionTitle extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {num, title, complete, showLink, editSection} = this.props

    return (
      <p>
        {
          complete
          ?
          <svg style={{height: '24px', width: '24px', marginRight: '9px', marginBottom: '-5px'}} viewBox="0 0 24 24" icon="check" class="css-1m01c8l e5i1odf6"><g stroke="#057586" stroke-width="1.4" fill="none" fill-rule="evenodd"><path d="M23.3 12C23.3 5.76 18.24.7 12 .7 5.758.7.7 5.758.7 12c0 6.242 5.058 11.3 11.3 11.3 6.24 0 11.3-5.06 11.3-11.3z"></path><path stroke-linecap="square" d="M17.053 8.211l-7.579 7.578-2.527-2.706"></path></g></svg>
          :
          <span style={{border: '1px solid black', borderRadius: '12px', width: '23px', height: '23px', display: 'inline-block', paddingLeft: '6px', marginRight: '10px'}}>
            {num}
          </span>
        }
        {
          showLink
          ?
          <a style={titleStyleLink} onClick={editSection}>
            {title}
          </a>
          :
          <b style={titleStyle}>
            {title}
          </b>
        }
      </p>
    )
  }
}

export default SectionTitle
