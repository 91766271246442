import React      from 'react'
import {
          Form,
          Grid,
          Input,
          Button,
          Icon,
          Popup
       }          from 'semantic-ui-react'
import Utils      from './../../../modules/utils'


class SubcategoryInput extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading : true,
      edit    : false,
      subcategory: this.props.subcategory || null,
      showSubs: false,
      inputs  :
      {
        name :
        {
          value   : "",
          valid   : null,
          required: true,
          message : ""
        },
        grandSubcategories :
        {
          value   : [],
          valid   : true,
          required: true,
          message : ""
        }
      }
    }
  }

  componentDidMount()
  {
    var {subcategory} = this.props
    if (subcategory && subcategory.id)
    {
      this.setInputFromSubcategory()
    }
    else
    {
      this.setState({loading: false})
    }
  }

  componentDidUpdate()
  {
    if (this.props.subcategory !== this.state.subcategory)
    {
      this.setState({subcategory: this.props.subcategory})
    }
  }

  setInputFromSubcategory = () =>
  {
    let {subcategory, inputs, showSubs} = this.state
    if (!subcategory) return;

    Object.keys(inputs).forEach((key) =>
    {
      let input = inputs[key]

      switch (key)
      {
        case "name"  :
        {
          input.valid = true
          input.value = subcategory[key]
          break
        }
        case "grandSubcategories"  :
        {
          input.valid = true
          input.value = subcategory[key]
          if (input.value.length > 0) showSubs = true
          break
        }
        default :
        {

        }
      }
    })

    this.setState({inputs, loading: false, showSubs})
  }

  handleChange = (e, data) =>
  {
    let {inputs, subcategory} = this.state
    let input    = inputs[data.name]
    input.value  = data.value

    switch (data.name)
    {
      case "name" :
      {
        input.value   = input.value.toUpperCase()
        let required  = input.required && input.value.length === 0
        let validate  = !required ? this.props.validate(input.value, 'subcategories') : true
        input.valid   = !required && validate
        input.message = required ? "Value should not be empty" : "Name should be unique"
        break
      }
      case "grandSubcategories" :
      {
        let subcategoryId = subcategory && subcategory.id
        let data = {grandSubcategories: input.value}
        this.props.handleSubmit({subcategory: data}, subcategoryId, 'subcategories')
        break
      }
      default :
      {

      }
    }

    this.setState({inputs})
  }

  handleSubmit = (e) =>
  {
    e.preventDefault()
    let {subcategory, inputs} = this.state
    let {data, valid} = this.getData()
    let subcategoryId = subcategory && subcategory.id

    var callback = (error, data) =>
    {
      if(error)
      {
        let errors = error.response.data
        let errorMessage = Object.keys(errors).map((key) =>
        {
          return `${key}: ${errors[key].join(', ')}`
        })
        inputs.name.message = errorMessage
        this.setState({inputs})
      }
      else
      {
        if (subcategoryId)
        {
          this.setState({edit: false})
        }
        else
        {
          inputs.name.value = ""
          inputs.name.valid = null
          this.setState({inputs})
        }
      }
    }

    this.props.handleSubmit({subcategory: data}, subcategoryId, 'subcategories', callback)
  }

  handleDestroy = (e) =>
  {
    e.preventDefault()
    var {subcategory} = this.state
    this.props.handleDestroy(subcategory.id, 'subcategories')
  }

  enableEdit = () =>
  {
    this.setState({edit: true})
  }

  getData = () =>
  {
    let {inputs} = this.state
    let data     = {}
    data.name    = Utils.trim(inputs.name.value)

    return {data, valid: this.isInputsValid()}
  }

  isInputsValid = () =>
  {
    let {inputs} = this.state
    let valid    = Utils.isInputsValidated(inputs)
    return valid
  }

  toggleSubs = () =>
  {
    this.setState({showSubs: true })
  }

  render()
  {
    let {inputs, subcategory, loading, edit, showSubs} = this.state
    let isOld    = subcategory && subcategory.id
    let editable = isOld && !edit
    let isValid  = this.isInputsValid()

    var grandSubcategories = this.props.grandSubcategories ? Utils.objectsToOptionsHash(this.props.grandSubcategories, 'id', 'name') : []

    let markup =
      loading ? null
      :
      <Grid.Row>
        <Grid.Column width={14}>
          <Form.Field
            required
            control={Input}
            placeholder='Sub-Category Name'
            name='name'
            readOnly={editable}
            value={inputs.name.value}
            onChange={(e, {value}) => this.handleChange(e, {name: 'name', value: value})}
            error={inputs.name.valid == false ? {
              content: inputs.name.message
            } : false }
          />
          {
            showSubs &&
            <div>
              <div className='nested-line'></div>
              <Icon name="arrow right" className='nested-arrow' />
              <Form.Select
                width={15}
                multiple
                label="Sub2"
                className='float-right'
                name='sub2-select'
                placeholder="Specify Sub2"
                options={grandSubcategories}
                value={inputs.grandSubcategories.value}
                onChange={(e, {value}) => this.handleChange(e, {name: 'grandSubcategories', value: value})}
              />
            </div>
          }
        </Grid.Column>
        {
          isOld ?
          <Grid.Column width={2} style={{display: 'flex', justifyContent: 'space-around', top: '10px'}}>
            {
              this.state.edit ?
              <Icon
                style={{cursor: 'pointer'}}
                name='check'
                color='green'
                disabled={!isValid}
                onClick={this.handleSubmit}
              />
              :
              <Icon
                style={{cursor: 'pointer'}}
                name='pencil'
                onClick={this.enableEdit}
              />
            }
            {
              !showSubs &&
              <Popup
                content="Select Sub2"
                basic
                trigger={<Icon
                          style={{cursor: 'pointer'}}
                          name='sitemap'
                          onClick={this.toggleSubs}
                        />}
              />

            }
            <Icon
              style={{cursor: 'pointer'}}
              name='trash alternate'
              color='red'
              onClick={this.handleDestroy}
            />
          </Grid.Column>
          :
          <Grid.Column width={2}>
            <Button
              primary
              content="Add"
              disabled={!isValid}
              onClick={this.handleSubmit}
            />
          </Grid.Column>
        }
      </Grid.Row>

    return markup;
  }
}

export default SubcategoryInput
