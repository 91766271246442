import React, {Component} from 'react'
import {Dropdown, Button} from 'semantic-ui-react'
import {labelStyle} from "../edit-sales-order/Elements"
import Utils from "../../modules/utils"

class ZeroPriceReport extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      user: []
    }

    this.userOnChange = this.userOnChange.bind(this)
    this.download = this.download.bind(this)
  }

  userOnChange(event, {value}) {
    this.setState({user: value})
  }

  download() {
    let {setLoading, stopLoading} = this.props
    setLoading()

    Utils.post('zero_price_report', this.state, (err, res) =>
    {
      stopLoading()
      if (err) {
        alert('There was an error when trying to get $0 price report.')
      } else {
        window.location = res.data.url
      }
    })
  }

  render() {
    let {salesUserOptions} = this.props
    let {user} = this.state

    return <div style={{paddingTop: '60px'}}>
      <label style={labelStyle}>
        $0 price report
      </label>

      <span style={{width: '220px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
        <Dropdown disabled={salesUserOptions.length == 0} loading={salesUserOptions.length == 0} placeholder='Sales User - ALL' fluid selection multiple search options={salesUserOptions} onChange={this.userOnChange} value={user}/>
      </span>

      <Button primary style={{position: 'relative', bottom: '10px', marginLeft: '15px'}} onClick={this.download}>Download</Button>

    </div>
  }
}

export default ZeroPriceReport
