import React, {Component} from 'react'
import {Button} from 'semantic-ui-react'
import Utils from "../../modules/utils"

class DeleteCad extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}

    this.deleteCad = this.deleteCad.bind(this)
  }

  deleteCad() {
    let {cadId, styleNumber, clearCadCard} = this.props
    let data = {cadId}

    if (window.confirm('Are you sure you want to delete ' + styleNumber + '? This CANNOT be undone!')) {
      Utils.post('delete_cad', data, (err, res) => {
        if (err) {
          alert('There was an error when trying to delete cad.')
        } else {
          alert(styleNumber + ' has successfully been deleted.')

          //update realtime!!!
          let newCads = window.cads

          for (var i=0;i<newCads.length;i++) {
            if (newCads[i].id == cadId) {
              newCads[i].is_deleted = true
              break
            }
          }

          window.cads = newCads

          clearCadCard(true)
        }
      })
    }
  }

  render() {
    return (
      <Button color='red' size='mini'
        onClick={this.deleteCad}
        style={{cursor: 'pointer', float: 'right'}}
      >
        Delete
      </Button>
    )
  }
}

export default DeleteCad
