import React from 'react'
import {Dropdown} from 'semantic-ui-react'

const impersonateUsersDropdown = (repOptions, repOnChange, rep) => {
  return (
    <div id="impersonateUsersDropdown">
      <Dropdown
        placeholder='Rep'
        fluid
        multiple
        search
        selection
        options={repOptions}
        onChange={repOnChange}
        value={rep}
        style={{width: '300px'}}
      />
    </div>
  )
}

const isDefaultImpersonateRep = (impersonateRep, user, value) => {
  return JSON.stringify(impersonateRep) == JSON.stringify([user.id]) && value.length == 0
}

export {impersonateUsersDropdown, isDefaultImpersonateRep}
