import React, {Component} from 'react'
import {TextArea, Button} from 'semantic-ui-react'
import Utils from './../../modules/utils'
import $ from 'jquery'

let saveButtonStyle = {float: 'right', padding: '5px 10px', marginTop: '10px'}

class EditShipToForm extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
     shipTo: this.props.po.shipTo,
     loading: false
    }

    this.textareaOnChange = this.textareaOnChange.bind(this)
    this.save = this.save.bind(this)
  }

  componentDidMount() {

  }

  textareaOnChange(e, value) {
    this.setState({shipTo: value.value})
  }

  save() {
    this.setState({loading: true})
    let {id} = this.props.po
    let {shipTo} = this.state

    let data = {id, shipTo}

    Utils.post('save_ship_to', data, (err, res) =>
    {
      this.setState({loading: false})

      if (err) {
        alert('There was an error when trying to save ship to.')
      } else {
        if (res.data.success) {
          this.props.updateShipTo(id, shipTo)
        }
      }
    })
  }

  render() {
    let {shipTo, loading} = this.state
    let {isInventory} = this.props

    return (
      <div>
        <TextArea rows={10} value={shipTo} onChange={this.textareaOnChange} style={{width: '350px', marginTop: '10px'}}/>
        {
          isInventory
          ?
          <Button disabled={loading} loading={loading} color='green' content='Save' style={saveButtonStyle} onClick={this.save}/>
          :
          null
        }
      </div>
    )
  }
}

export default EditShipToForm
