import React      from 'react'
import {
          Form,
          Grid,
          Input,
          Button,
          Icon
       }          from 'semantic-ui-react'
import Utils      from './../../../modules/utils'


class PackInput extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading : true,
      edit    : false,
      pack: this.props.pack || null,
      inputs  :
      {
        name :
        {
          value   : "",
          valid   : null,
          required: true,
          message : ""
        }
      }
    }
  }

  componentDidMount()
  {
    var {pack} = this.props
    if (pack && pack.id)
    {
      this.setInputFromPack()
    }
    else
    {
      this.setState({loading: false})
    }
  }

  componentDidUpdate()
  {
    if (this.props.pack !== this.state.pack)
    {
      this.setState({pack: this.props.pack})
    }
  }

  setInputFromPack = () =>
  {
    let {pack, inputs} = this.state
    if (!pack) return;

    Object.keys(inputs).forEach((key) =>
    {
      let input = inputs[key]

      switch (key)
      {
        case "name"  :
        {
          input.valid = true
          input.value = pack[key]
          break
        }
        default :
        {

        }
      }
    })

    this.setState({inputs, loading: false})
  }

  handleChange = (e, data) =>
  {
    let state  = this.state
    let name, value;
    let inputs = state.inputs

    value = e.target.value
    name  = e.target.name

    let input   = inputs[name]
    input.value = value.toUpperCase()

    if (input.required && input.value.length === 0)
    {
      input.valid   = false
      input.message = 'Value should not be empty'
    }
    else if (!this.props.validate(value, 'packs'))
    {
      input.valid   = false
      input.message = 'Name should be unique'
    }
    else
    {
      input.valid   = true
      input.message = ''
    }

    this.setState({inputs})
  }

  handleSubmit = (e) =>
  {
    e.preventDefault()
    let {pack, inputs} = this.state
    let {data, valid} = this.getData()
    let packId = pack && pack.id

    var callback = (error, data) =>
    {
      if(error)
      {
        let errors = error.response.data
        let errorMessage = Object.keys(errors).map((key) =>
        {
          return `${key}: ${errors[key].join(', ')}`
        })
        inputs.name.message = errorMessage
        this.setState({inputs})
      }
      else
      {
        if (packId)
        {
          this.setState({edit: false})
        }
        else
        {
          inputs.name.value = ""
          inputs.name.valid = null
          this.setState({inputs})
        }
      }
    }

    this.props.handleSubmit({pack: data}, packId, 'packs', callback)
  }

  handleDestroy = (e) =>
  {
    e.preventDefault()
    var {pack} = this.state
    this.props.handleDestroy(pack.id, 'packs')
  }

  enableEdit = () =>
  {
    this.setState({edit: true})
  }

  getData = () =>
  {
    let {inputs} = this.state
    let data     = {}
    data.name    = Utils.trim(inputs.name.value)

    return {data, valid: this.isInputsValid()}
  }

  isInputsValid = () =>
  {
    let {inputs} = this.state
    let valid    = Utils.isInputsValidated(inputs)
    return valid
  }

  render()
  {
    let {inputs, pack, loading, edit} = this.state
    let isOld    = pack && pack.id
    let editable = isOld && !edit
    let isValid  = this.isInputsValid()

    let markup =
      loading ? null
      :
      <Grid.Row>
        <Grid.Column width={14}>
          <Form.Field
            required
            control={Input}
            placeholder='Pack Name'
            name='name'
            readOnly={editable}
            value={inputs.name.value}
            onChange={(e) => this.handleChange(e, inputs.name)}
            error={inputs.name.valid == false ? {
              content: inputs.name.message
            } : false }
          />
        </Grid.Column>
        {
          isOld ?
          <Grid.Column width={2} style={{display: 'flex', alignItems: 'center'}}>
            {
              this.state.edit ?
              <Icon
                style={{marginRight: '15px', cursor: 'pointer'}}
                name='check'
                color='green'
                disabled={!isValid}
                onClick={this.handleSubmit}
              />
              :
              <Icon
                style={{marginRight: '15px', cursor: 'pointer'}}
                name='pencil'
                onClick={this.enableEdit}
              />
            }
            <Icon
              style={{cursor: 'pointer'}}
              name='trash alternate'
              color='red'
              onClick={this.handleDestroy}
            />
          </Grid.Column>
          :
          <Grid.Column width={2}>
            <Button
              primary
              content="Add"
              disabled={!isValid}
              onClick={this.handleSubmit}
            />
          </Grid.Column>
        }
      </Grid.Row>

    return markup;
  }
}

export default PackInput
