const checkColorRange = (color) => {
  let x = Number(color)

  switch (true) {
    case (1 <= x && x <= 19):
      return '1'
    case (20 <= x && x <= 59):
      return '20'
    case (60 <= x && x <= 99):
      return '60'
    case (101 <= x && x <= 109):
      return '101'
    case (111 <= x && x <= 163):
      return '111'
    case (201 <= x && x <= 249):
      return '201'
    case (250 <= x && x <= 289):
      return '250'
    case (300 <= x && x <= 339):
      return '300'
    case (340 <= x && x <= 469):
      return '340'
    case (500 <= x && x <= 539):
      return '500'
    case (600 <= x && x <= 639):
      return '600'
    case (650 <= x && x <= 699):
      return '650'
    case (700 <= x && x <= 749):
      return '700'
    case (800 <= x && x <= 839):
      return '800'
    case (840 <= x && x <= 859):
      return '840'
    case (860 <= x && x <= 920):
      return '860'
    case (921 <= x && x <= 999):
      return '921'

    default:
      break;
  }

  return null
}

export {checkColorRange}
