import React, { Component } from 'react'
import $ from 'jquery'
import { Table, Input, Button, Form, Icon, Popup } from 'semantic-ui-react'
import './PaymentHistory.css'
import Utils from "../../modules/utils"

const zeroResultsStyle = {
  border: '1px solid rgb(222,222,222)',
  borderTop: '1px solid white',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  padding: '15px',
  marginBottom: '10px',
  color: 'red',
  fontWeight: 'bold',
  textAlign: 'center',
  marginTop: '-15px'
}

const closeRefundStyle = {
  cursor: 'pointer',
  position: 'relative',
  left: '37px',
  fontSize: '20px',
  top: '4px'
}

class PaymentHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      refundingId: null
    }

    this.makePaymentOnSubmit = this.makePaymentOnSubmit.bind(this)
    this.refundPaymentOnClick = this.refundPaymentOnClick.bind(this)
    this.refundPaymentOnSubmit = this.refundPaymentOnSubmit.bind(this)
    this.refundPayment = this.refundPayment.bind(this)
    this.closeRefund = this.closeRefund.bind(this)
  }

  componentDidMount() {}

  componentDidUpdate() {}

  makePaymentOnSubmit() {
    if (this.props.pendingChanges) {
      alert('Please save changes to order before making a payment!')
      return
    }

    let payment_method_id = $("input[name='payment_method']:checked").val()
    if (payment_method_id == null || payment_method_id == 'pay-later') {
      alert('Please select a valid payment method!')
      return
    }

    let amount = Math.floor(Number($('#make-payment-input').val()) * 100) / 100
    if (amount == 0) {
      alert('Cannot make payment for $0')
    } else {
      let message = "Are you sure you want to make a payment for $" + amount
      if (window.confirm(message)) {
        this.makePayment(payment_method_id, amount)
      }
    }
  }

  makePayment(payment_method_id, amount) {
    this.setState({loading: true})
    let {orderId, contactId} = this.props

    let url = 'make_payment/' + orderId
    amount *= 100
    let data = { payment_method_id, amount, contactId }

    Utils.post(url, data, (err, result) =>
    {
      this.setState({loading: false})
      if (err) {
        console.log('There was an error when trying to make payment')
      } else {
        window.location.reload()
      }
    })
  }

  refundPaymentOnClick(payment) {
    if (this.props.pendingChanges) {
      alert('Please save changes to order before making a refund!')
      return
    }

    this.setState({refundingId: payment.id})
  }

  refundPaymentOnSubmit() {
    let amount = Math.floor(Number($('#refund-payment-input').val()) * 100) / 100
    if (amount == 0) {
      alert('Cannot refund $0')
    } else {
      let message = "Are you sure you want to refund $" + amount
      if (window.confirm(message)) {
        this.refundPayment(amount)
      }
    }
  }

  refundPayment(amount) {
    this.setState({refundLoading: true})
    let {orderId, contactId} = this.props
    let {refundingId} = this.state

    let url = 'refund_payment/' + orderId
    amount *= 100
    let data = { payment_id: refundingId, amount, contactId }

    Utils.post(url, data, (err, result) =>
    {
      this.setState({refundLoading: false})
      if (err) {
        console.log('There was an error when trying to refund payment')
      } else {
        window.location.reload()
      }
    })

  }

  closeRefund() {
    this.setState({refundingId: null})
  }

  render() {
    let {stripePayments, stripeRefunds} = this.props
    let {loading, refundLoading, refundingId} = this.state

    return (
      <div style={{marginBottom: '65px', marginTop: '55px'}}>
        <h3 style={{fontSize: '150%'}}>Payment History</h3>
        <Table padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Transaction ID</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>Payment Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {
            stripePayments.map((payment, index) => {
              let refunds = stripeRefunds.filter((refund) => {return (payment.id == refund.stripe_payment_id && !!refund.is_paid)})

              let refund_amount = 0
              for (let i=0; i<refunds.length; i++) {
                refund_amount += refunds[i].amount_paid
              }

              let fully_refunded = (refund_amount == payment.amount_paid)

              return (
                <Table.Row key={index}>
                  <Table.Cell style={{whiteSpace: 'nowrap'}}>
                    <div style={{color: 'green'}}>{payment.transaction_id}</div>
                    {refunds.map((refund, index2) => {
                      return (
                        <div key={index2} style={{color: 'red'}}>refund</div>
                      )
                    })}
                  </Table.Cell>
                  <Table.Cell style={{whiteSpace: 'nowrap'}}>
                    <div style={{color: 'green'}}>$ {(payment.amount_paid / 100).toFixed(2)}</div>
                    {refunds.map((refund, index2) => {
                      return (
                        <div key={index2} style={{color: 'red'}}>—$ {(refund.amount_paid / 100).toFixed(2)}</div>
                      )
                    })}
                  </Table.Cell>
                  <Table.Cell style={{whiteSpace: 'nowrap'}}>
                    <div style={{color: 'green'}}>{payment.date_string}</div>
                    {refunds.map((refund, index2) => {
                      return (
                        <div key={index2} style={{color: 'red'}}>{refund.date_string}</div>
                      )
                    })}
                  </Table.Cell>
                  <Table.Cell style={{whiteSpace: 'nowrap'}}>
                    <div style={{color: 'green'}}>{payment.user_name}</div>
                    {refunds.map((refund, index2) => {
                      return (
                        <div key={index2} style={{color: 'red'}}>{refund.user_name}</div>
                      )
                    })}
                  </Table.Cell>
                  <Table.Cell>
                    {payment.is_paid ? <span style={{color: 'green'}}>Success</span> : <span style={{color: 'red'}}>Failed</span>}
                    &nbsp;&nbsp;&nbsp;
                    {
                      !payment.is_paid ?
                      null
                      :
                      refundingId == payment.id ?
                      <Input
                        type="number"
                        min="0"
                        id="refund-payment-input"
                        form="refund-payment-form"
                        action={
                          <Button color='red' type="submit" form="refund-payment-form" loading={refundLoading} disabled={refundLoading}
                            style={{padding: 0, paddingLeft: '10px'}}>
                            <Icon name="check"/>
                          </Button>
                        }
                        placeholder='Enter refund amount'
                        style={{width: '200px', marginLeft: '10px'}}
                        icon="dollar"
                        iconPosition="left"
                        required
                        disabled={refundLoading}
                      />
                      :
                      fully_refunded ?
                      null
                      :
                      <Popup content='Refund payment' trigger={
                        <Button onClick={this.refundPaymentOnClick.bind(null, payment)} color='red' basic icon='undo' style={{marginLeft: '10px', padding: '5px'}}/>
                      }/>
                    }
                    {refundingId == payment.id ? <Icon name='close' onClick={this.closeRefund} style={closeRefundStyle}/> : null}
                  </Table.Cell>
                </Table.Row>
              )
            })
          }
          </Table.Body>
        </Table>
        {stripePayments.length == 0 ? <div style={zeroResultsStyle}>No payment yet for order!</div> : null}
        <div style={{textAlign: 'right'}}>
          <Input
            type="number"
            min="0"
            id="make-payment-input"
            form="make-a-payment-form"
            action={
              <Button primary type="submit" form="make-a-payment-form" loading={loading} disabled={loading}>Make payment</Button>
            }
            placeholder='Enter amount'
            style={{width: '400px'}}
            icon="dollar"
            iconPosition="left"
            onChange={this.styleOnChange}
            required
            disabled={loading}
          />
          <Form id="make-a-payment-form" onSubmit={this.makePaymentOnSubmit}></Form>
        </div>
        <Form id="refund-payment-form" onSubmit={this.refundPaymentOnSubmit}></Form>
      </div>
    )
  }
}
//disabled={this.state.addStyleIcon != 'check'}

export default PaymentHistory
