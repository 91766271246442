import React, {Component} from 'react'
import {Grid, Popup, Icon} from 'semantic-ui-react'
import {divisionOptions, brandOptions, packsOptions, categoryOptions, colorOptions, subcategoryOptions} from './components/generate-style-number/options'

class StyleCodesTablePage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      onlyShowType: null,
      onlyShowValue: null
    }
  }

  getContent(x) {

    let result = []

    if (x['division_char'] != null) {
      result.push('Division must be: '+ x['division_char'].sort().join(', '))
    }

    if (x['division_char_not'] != null) {
      result.push('Division must NOT be: '+ x['division_char_not'].sort().join(', '))
    }

    if (x['category_char'] != null) {
      result.push('Category must be: '+ x['category_char'].sort().join(', '))
    }

    if (x['category_char_not'] != null) {
      result.push('Category must NOT be: '+ x['category_char_not'].sort().join(', '))
    }

    if (x['packs_char'] != null) {
      result.push('Packs must be: '+ x['packs_char'].sort().join(', '))
    }

    if (x['packs_char_not'] != null) {
      result.push('Packs must NOT be: '+ x['packs_char_not'].sort().join(', '))
    }

    if (result.length == 0) {
      return ''
    } else {
      return <div>{result.map(y => <div>{y}</div>)}</div>
    }
  }

  getIcon(x) {
    let result = []

    if (x['division_char'] != null && x['division_char'].includes('P')) {
      result.push(['paw', 'purple', 'division', 'P'])
    }

    if (x['division_char'] != null && x['division_char'].includes('H')) {
      result.push(['home', 'yellow', 'division', 'H'])
    }

    if (x['category_char'] != null && x['category_char'].includes('A')) {
      result.push(['bug', 'orange', 'category', 'A'])
    }

    if (x['category_char'] != null && x['category_char'].includes('B')) {
      result.push(['shopping bag', 'green', 'category', 'B'])
    }

    if (x['category_char'] != null && x['category_char'].includes('C')) {
      result.push(['star', 'blue', 'category', 'C'])
    }

    if (x['category_char'] != null && x['category_char'].includes('D')) {
      result.push(['cloud', 'orange', 'category', 'D'])
    }

    if (x['category_char'] != null && x['category_char'].includes('F')) {
      result.push(['bolt', 'orange', 'category', 'F'])
    }

    if (x['category_char'] != null && x['category_char'].includes('G')) {
      result.push(['military', 'orange', 'category', 'G'])
    }

    if (x['category_char'] != null && x['category_char'].includes('K')) {
      result.push(['coffee', 'grey', 'category', 'K'])
    }

    if (x['category_char'] != null && x['category_char'].includes('L')) {
      result.push(['snowflake', 'grey', 'category', 'L'])
    }

    if (x['category_char'] != null && x['category_char'].includes('M')) {
      result.push(['heartbeat', 'red', 'category', 'M'])
    }

    if (x['category_char'] != null && x['category_char'].includes('O')) {
      result.push(['smile', 'red', 'category', 'O'])
    }

    if (x['category_char'] != null && x['category_char'].includes('P')) {
      result.push(['tag', 'pink', 'category', 'P'])
    }

    if (x['category_char'] != null && x['category_char'].includes('R')) {
      result.push(['square', 'pink', 'category', 'R'])
    }

    if (x['category_char'] != null && x['category_char'].includes('S')) {
      result.push(['bed', 'brown', 'category', 'S'])
    }

    if (x['category_char'] != null && x['category_char'].includes('T')) {
      result.push(['rain', 'brown', 'category', 'T'])
    }

    if (x['category_char'] != null && x['category_char'].includes('W')) {
      result.push(['ship', 'red', 'category', 'W'])
    }

    if (x['category_char'] != null && x['category_char'].includes('Y')) {
      result.push(['rocket', 'blue', 'category', 'Y'])
    }

    if (x['category_char'] != null && x['category_char'].includes('Z')) {
      result.push(['cog', 'blue', 'category', 'Z'])
    }

    return result
  }

  getCategoryColumnIcon(x) {
    let result = ''

    if (x['key'][0] == 'Z') {
      result = ['cog', 'blue']
    }

    if (x['key'][0] == 'Y') {
      result = ['rocket', 'blue']
    }

    if (x['key'][0] == 'B') {
      result = ['shopping bag', 'green']
    }

    if (x['key'][0] == 'W') {
      result = ['ship', 'red']
    }

    if (x['key'][0] == 'F') {
      result = ['bolt', 'orange']
    }

    if (x['key'][0] == 'G') {
      result = ['military', 'orange']
    }

    if (x['key'][0] == 'S') {
      result = ['bed', 'brown']
    }

    if (x['key'][0] == 'T') {
      result = ['rain', 'brown']
    }

    if (x['key'][0] == 'P') {
      result = ['tag', 'pink']
    }

    if (x['key'][0] == 'R') {
      result = ['square', 'pink']
    }

    if (x['key'][0] == 'M') {
      result = ['heartbeat', 'red']
    }

    if (x['key'][0] == 'A') {
      result = ['bug', 'orange']
    }

    if (x['key'][0] == 'C') {
      result = ['star', 'blue']
    }

    if (x['key'][0] == 'L') {
      result = ['snowflake', 'grey']
    }

    if (x['key'][0] == 'K') {
      result = ['coffee', 'grey']
    }

    if (x['key'][0] == 'D') {
      result = ['cloud', 'orange']
    }

    if (x['key'][0] == 'O') {
      result = ['smile', 'red']
    }

    return result
  }

  onlyShow = (onlyShowType, onlyShowValue) => {
    if (this.state.onlyShowType != onlyShowType || this.state.onlyShowValue != onlyShowValue) {
      this.setState({onlyShowType, onlyShowValue})
    } else {
      this.setState({onlyShowType: null, onlyShowValue: null})
    }
  }

  onlyShowIcon = (onlyShowType, onlyShowValue) => {
    let result = ''

    if (onlyShowType == 'division' && onlyShowValue == 'H') {
      result = ['home', 'yellow']
    }

    if (onlyShowType == 'division' && onlyShowValue == 'P') {
      result = ['paw', 'purple']
    }

    if (onlyShowType == 'category') {
      result = this.getCategoryColumnIcon({key: onlyShowValue})
    }


    return result
  }

  filterSubcategoryOptions = (x) => {
    let {onlyShowType, onlyShowValue} = this.state

    if (onlyShowType == 'division') {
      x = x.filter(y => y['division_char'] != null && y['division_char'].includes(onlyShowValue))
    }

    if (onlyShowType == 'category') {
      x = x.filter(y => y['category_char'] != null && y['category_char'].includes(onlyShowValue))
    }

    return x
  }

  onlyShowText = () => {
    let {onlyShowType, onlyShowValue} = this.state

    if (onlyShowType == 'division') {
      return divisionOptions.find(x => x['text'][0] == onlyShowValue)['text']
    }

    if (onlyShowType == 'category') {
      return categoryOptions.find(x => x['text'][0] == onlyShowValue)['text']
    }

    return ''
  }

  render() {

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>
        <h1>Style Codes Table</h1>

        <h3><center>&#123;Division&#125; &#123;Sub-Category&#125; &#123;Family Id&#125; — &#123;Color&#125; — &#123;Brand&#125; &#123;Category&#125; &#123;Packs&#125;</center></h3>

        <h3><center>For example: PD17256-303-6DS</center></h3>

        {
          this.state.onlyShowType == null
          ?
          ''
          :
          <div style={{position: 'absolute'}}><Icon name={this.onlyShowIcon(this.state.onlyShowType, this.state.onlyShowValue)[0]} color={this.onlyShowIcon(this.state.onlyShowType, this.state.onlyShowValue)[1]} style={{fontSize: '23px', position: 'relative', top: '3px', marginLeft: '13vw'}}/><div style={{borderBottom: '1px solid black', display: 'inline-block'}}>Only show {this.state.onlyShowType} {this.onlyShowText()}</div> &nbsp; <button onClick={() => this.onlyShow(null, null)} style={{padding: '0 5px', cursor: 'pointer', fontSize: '10px'}}>remove</button></div>
        }
        <Grid columns={7} divided style={{marginTop: '50px'}}>
          <Grid.Row>
            <Grid.Column>
              <b>Division</b>
              {divisionOptions.map(x =>
                <div>{x['key']}
                  {
                    x['key'][0] == 'P'
                    ?
                    <Icon name='paw' color='purple' onClick={() => this.onlyShow('division', x['key'][0])} style={{cursor: 'pointer', marginLeft: '4px'}}/>
                    :
                    x['key'][0] == 'H'
                    ?
                    <Icon name='home' color='yellow' onClick={() => this.onlyShow('division', x['key'][0])} style={{cursor: 'pointer', marginLeft: '4px'}}/>
                    :
                    ''
                  }
                </div>
              )}
            </Grid.Column>
            <Grid.Column style={{zIndex: 100, width: '18%'}}>
              <b>Sub-Category [sub2]</b><br/>
              <span style={{opacity: 0.5}}>text in [ ] will be set as sub2 field</span>
              {this.filterSubcategoryOptions(subcategoryOptions).map((x) => {
                let content = this.getContent(x)
                let icons = this.getIcon(x)

                return (
                  content == ''
                  ?
                  <div>{x['key']}</div>
                  :
                  <div style={{cursor: 'default', marginBottom: '4px'}}><Popup position='left center' content={content} trigger={<span style={{background: 'rgb(255,255,191)'}}>{x['key']}</span>} />
                    {icons.map(icon =>
                      <Icon name={icon[0]} color={icon[1]} onClick={() => this.onlyShow(icon[2], icon[3])} style={{cursor: 'pointer', marginLeft: '4px'}}/>
                    )}
                  </div>
                )
              })}
            </Grid.Column>
            <Grid.Column style={{width: '10%'}}>
              <b>Family Id</b><br/><span style={{opacity: 0.5}}>(4 or 5 digit number)</span>
            </Grid.Column>
            <Grid.Column>
              <b>Color</b><br/><span style={{opacity: 0.5}}>(3 digit number)</span>
              {colorOptions.map(x => <div>{x['key']}</div>)}
            </Grid.Column>
            <Grid.Column>
              <b>Brand</b>
              {brandOptions.map((x) => {
                let content = this.getContent(x)

                return (
                  content == ''
                  ?
                  <div>{x['key']}</div>
                  :
                  <div style={{cursor: 'default', marginBottom: '4px'}}><Popup position='left center' content={this.getContent(x)} trigger={<span style={{background: 'rgb(255,255,191)'}}>{x['key']}</span>} /></div>
                )
              })}
            </Grid.Column>
            <Grid.Column>
              <b>Category</b>
              {categoryOptions.map((x) => {
                let icon = this.getCategoryColumnIcon(x)

                return (
                  icon == ''
                  ?
                  <div>{x['key']}</div>
                  :
                  <div>{x['key']}<Icon onClick={() => this.onlyShow('category', x['key'][0])} name={icon[0]} color={icon[1]} style={{marginLeft: '4px', cursor: 'pointer'}}/></div>
                )
              })}
            </Grid.Column>
            <Grid.Column>
              <b>Packs</b>
              {packsOptions.map(x => <div>{x['key']}</div>)}
            </Grid.Column>
          </Grid.Row>
        </Grid>


      </div>
    )
  }
}

export default StyleCodesTablePage
