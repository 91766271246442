import React, {Component} from 'react'
import {Menu, Image, Icon} from 'semantic-ui-react'
import {Auth0Context} from '../../contexts/auth-context'
import UserDropdown from '../navbar/user-dropdown'

class RealEstateNavbar extends Component {
  static contextType = Auth0Context

  constructor(props)
  {
    super(props)
    this.state = {}

    this.logoutSession = this.logoutSession.bind(this)
  }

  logoutSession() {
    const {logout} = this.context

    if (window.confirm('Are you sure you want to sign out?')) {
      sessionStorage.clear()
      localStorage.clear()
      logout({ returnTo: window.location.origin })
    }
  }

  render() {
    const {user} = this.context
    let {guest} = this.props

    const userAttribution = user == null ? null : `${user.name} (${user.role.toUpperCase().replace(/_/g, ' ')})`

    return (
      <div id="main-nav" style={guest ? {pointerEvents: 'none'} : {}}>
        <Menu style={{padding: '15px 40px', minHeight: '70px'}}>
          <Menu.Item style={{height: 0, position: 'relative', top: '5px'}}>
            <a href="/#/"><Icon name='building outline' style={{fontSize: '40px', color: 'black'}}/></a>
          </Menu.Item>

          {
            guest
            ?
            <Menu.Menu position='left'>
              <h2 style={{position: 'relative', top: '4px'}} id="SharedLinkTitle"></h2>
            </Menu.Menu>
            :
            <React.Fragment>
              <Menu.Item>
                <a href="/#/properties" style={{color: 'black'}}>Properties</a>
              </Menu.Item>

              <Menu.Menu position='right'>
                <span style={{display: 'flex'}}>
                  <UserDropdown userAttribution={userAttribution} logoutSession={this.logoutSession}/>
                </span>
              </Menu.Menu>
            </React.Fragment>
          }

        </Menu>
      </div>
    )
  }
}

export default RealEstateNavbar
