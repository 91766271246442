const canSeePrice = () => checkUserAbility('canSeePrice')
const canSeeCost = () => checkUserAbility('canSeeCost')
const canEditCads = () => checkUserAbility('canEditCads')
const canDeleteCads = () => checkUserAbility('canDeleteCads')
const canImpersonateUsers = () => checkUserAbility('canImpersonateUsers')
const canSeeContainerTotals = () => checkUserAbility('canSeeContainerTotals')

const checkUserAbility = (ability) => {
  return window.currentUserCustomAccess != null ? !!window.currentUserCustomAccess[ability] : false
}

export {canSeePrice, canSeeCost, canEditCads, canDeleteCads, canImpersonateUsers, canSeeContainerTotals}
