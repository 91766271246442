import {formatDate} from '../cads/format-date'

const processAuto = (data) => {
  let result = { ... [null].concat(data.statuses.map((status) => {
    let order = data.orders.find(order => order.id == status.purchaseOrderId)

    return {
      po_number: order.customerOrderNumber,
      ref_number: order.ponumber,
      auto_qb_entered_at: formatDate(status.statusUpdatedAt.split('T')[0])
    }
  }).reverse()) }

  delete result['0']

  return result
}

const processManual = data => {
   let result = { ... [null].concat(data.statuses.map((status) => {
    let order = data.orders.find(order => order.id == status.purchaseOrderId)

    return {
      po_number: order.customerOrderNumber,
      ref_number: order.ponumber,
      manually_qb_entered_at: formatDate(status.statusUpdatedAt.split('T')[0]),
      user_name: status.statusUpdatedBy
    }
  }).reverse()) }

  delete result['0']

  return result
}

export {processAuto, processManual}
