import Utils from "../utils"

class PurchaseOrderAPI
{
  static create(data, cb)
  {
    Utils.post(`purchase_orders.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    })
  }

  static update(id, data, cb)
  {
    Utils.put(`purchase_orders/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static get(id, cb)
  {
    Utils.get(`purchase_orders/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static getProgresses(id, cb)
  {
    Utils.get(`purchase_order_progresses/${id}`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static getAll(cb, impersonateRep)
  {
    let queryParams = impersonateRep == null ? '' : '?rep='+impersonateRep.join(',')

    Utils.get(`purchase_orders.json${queryParams}`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static delete(id, cb)
  {
    Utils.delete(`purchase_orders/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static validatePurchaseorder(value, cb, scope=null)
  {
    var url = scope ? `validate_purchaseorder/${value}.json?scope=${scope}` : `validate_purchaseorder/${value}.json`

    Utils.get(url, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static deletePOFile(poId, fileId, cb)
  {
    Utils.delete(`purchase_orders/${poId}/purchase_order_files/${fileId}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static getFileLink(poId, fileId, fileType, cb)
  {
    Utils.get(`purchase_orders/${poId}/purchase_order_files/${fileId}/download.json?file_type=${fileType}`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }
}

export default PurchaseOrderAPI
