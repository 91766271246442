import React, {Component} from 'react'
import {Icon, Popup} from 'semantic-ui-react'
import Utils from './../../modules/utils'
import $ from 'jquery'
import EditShipToForm  from './edit-ship-to-form.js'


class EditShipToPopup extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {

    }

  }

  componentDidMount() {

  }

  render() {
    let {po, updateShipTo, isInventory} = this.props
    let missingShipTo = (po.shipTo == null || po.shipTo.trim() == '')

    return (
      <Popup
        size='mini'
        trigger={
          missingShipTo
          ?
          (
            isInventory
            ?
            <small data-tooltip="Click to add ship to" data-position="top left" style={{color: 'red', border: '1px solid red', paddingLeft: '10px', borderRadius: '11px', whiteSpace: 'nowrap', fontWeight: 'bold', background: 'rgb(255,220,220)', marginLeft: '10px', position: 'relative', bottom: '3px', cursor: 'pointer'}}>
              <Icon name='truck' /><Icon name='question' />
            </small>
            :
            null
          )
          :
          <button data-tooltip={"Click to " + (isInventory ? 'edit' : 'view') + " ship to"} data-position="top left" style={{padding: 0, border: 0, background: 'transparent', outline: 0}}>
            <Icon name="truck" style={{color: 'rgb(194,194,194)', marginLeft: '10px', fontSize: '18px', cursor: 'pointer'}} />
          </button>
        }
        header={missingShipTo ? "Add ship to" : (isInventory ? 'Edit' : 'View')+" ship to"}
        content={
          <EditShipToForm po={po} updateShipTo={updateShipTo} isInventory={isInventory}/>
        }
        on='click'
        position='top left'
      />

    )
  }
}

export default EditShipToPopup
