import React, { Component } from 'react'
import {Button, Icon} from 'semantic-ui-react'
import Utils from '../../modules/utils'

class DeleteProperty extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.confirmDelete = this.confirmDelete.bind(this)
    this.sharePage = this.sharePage.bind(this)
  }

  confirmDelete() {
    let confirmText = window.prompt('Please type "permanently delete"')
    if (confirmText == 'permanently delete') {
      const url = 'properties/' + this.props.id
      Utils.delete(url, (err, res) => {
        if (err) {
          alert('There was an error when trying to delete the property.')
        } else {
          alert('Delete success')
          window.location = '/#/properties'
        }
      })
    }
  }

  sharePage() {
    window.open('/#/share-property/' + this.props.id)
  }

  render() {
    return (
      <div style={{float: 'right', position: 'relative', top: '40px', textAlign: 'right', height: '150px'}}>
        <Button color='red' onClick={this.confirmDelete}>
          <Icon name='trash' /> Delete Property
        </Button>
        <br/><br/>
        <Button color='violet' onClick={this.sharePage}>
          <Icon name='share alternate' /> Preview Shared Page
        </Button>
      </div>
    )
  }
}

export default DeleteProperty
