import React from 'react'
import {Modal, Button, TextArea, Checkbox} from 'semantic-ui-react'
import {labels} from './options'

const disabledFields = ['NAME']

const visibleFieldsModal = (open, close, toggleCheckbox, visibleFields) => {
  return (
    <Modal
      onClose={close}
      open={open}
    >
      <Modal.Header>Select Visible Fields</Modal.Header>
      <Modal.Content>
        <div style={{columnCount: '3'}}>
          {
            labels.map((label, index) => {
              return (
                <div key={index} style={{whiteSpace: 'nowrap'}}>
                  <Checkbox disabled={disabledFields.includes(label)}
                    label={label}
                    onChange={toggleCheckbox.bind(null, label)}
                    checked={visibleFields.includes(label)}
                    style={disabledFields.includes(label) ? {opacity: 0.4} : {}}
                  /> &nbsp;
                  <br/><br/>
                </div>
              )
            })
          }
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Continue"
          labelPosition='right'
          icon='checkmark'
          onClick={close}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export {visibleFieldsModal}
