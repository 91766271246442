
import                        './index.css'
import React, {Component}from 'react'
import {
          Form,
          Checkbox,
          Grid,
          Loader,
          Dimmer,
          Message,
          Modal,
          Button,
          Select,
          Header,
          Icon
      }                  from 'semantic-ui-react'
import {withRouter}      from 'react-router-dom'
import Dropzone          from 'react-dropzone'
import ReactQuill        from 'react-quill'
import validator         from 'validator'
import LogMessages       from './../../shared/log-messages'
import AccountAPI        from './../../../modules/api/account_api'
import PurchaseOrderAPI  from './../../../modules/api/purchase_order_api'
import Constants         from './../../../modules/constants'
import Utils             from './../../../modules/utils'
import {Auth0Context}    from './../../../contexts/auth-context'
import {DatePickerField} from './../../ui/date-picker.js'


class PurchaseOrderForm extends Component
{
  static contextType = Auth0Context

  constructor(props)
  {
    super(props)
    this.state =
    {
      loading        : true,
      purchaseOrder  : this.props.purchaseOrder || null,
      company        : this.props.company || null,
      displayMessage : false,
      error          : null,
      poNumberIsTaken: false,
      messages       : [],
      accountText    : '',
      repText        : '',
      confirmModal   : false,
      logsModal      : false,
      accountModal   : false,
      accounts       : [],
      accountOptions : [],
      repOptions     : [],
      inputs :
      {
        divisionId :
        {
          value   : null,
          valid   : null,
          required: true,
          message : ""
        },
        brandId :
        {
          value   : null,
          valid   : null,
          required: true,
          message : ""
        },
        categoryId :
        {
          value   : null,
          valid   : null,
          required: true,
          message : ""
        },
        aged :
        {
          value   : false,
          valid   : true,
          required: true,
          message : ""
        },
        orderFrom :
        {
          value   : 'In Stock',
          valid   : true,
          required: true,
          message : ""
        },
        ticketed :
        {
          value   : 'No',
          valid   : true,
          required: true,
          message : ""
        },
        hangers :
        {
          value   : false,
          valid   : true,
          required: true,
          message : ""
        },
        hold :
        {
          value   : false,
          valid   : true,
          required: true,
          message : ""
        },
        topSamplesNeeded :
        {
          value   : false,
          valid   : true,
          required: true,
          message : ""
        },
        ponumber :
        {
          value   : '',
          valid   : null,
          required: true,
          message : ""
        },
        orderDate :
        {
          value   : Utils.getDate(),
          valid   : true,
          required: true,
          message : ""
        },
        startShippingDate :
        {
          value   : Utils.getDate(2),
          valid   : true,
          required: true,
          message : ""
        },
        cancelDate :
        {
          value   : Utils.getDate(9),
          valid   : true,
          required: true,
          message : ""
        },
        ticketsTrackingNumber :
        {
          value   : '',
          valid   : true,
          required: false,
          message : ""
        },
        topSamplesNeededDue :
        {
          value   : Utils.getDate(),
          valid   : true,
          required: false,
          message : ""
        },
        grossAmount :
        {
          value   : 0,
          valid   : null,
          required: true,
          message : ""
        },
        richTextMessageHtml :
        {
          value   : '',
          valid   : true,
          required: false,
          message : ""
        },
        accountId :
        {
          value   : null,
          valid   : null,
          required: true,
          message : ""
        },
        userId :
        {
          value   : null,
          valid   : null,
          required: true,
          message : ""
        },
        purchaseOrderFilesAttributes :
        {
          value   : [],
          valid   : true,
          required: false,
          message : ""
        }
      }
    }

    this.fetchAccounts()
  }

  componentDidMount()
  {
    var {purchaseOrder} = this.props
    var editPO = purchaseOrder && purchaseOrder.id
    if (!editPO)
    {
      this.setState({ loading: false })
    }
  }

  fetchAccounts = () =>
  {
    var {accountOptions, accounts, repOptions, purchaseOrder} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        // noops
        console.error(error)
      }
      else
      {
        accountOptions = Utils.objectsToOptionsHash([...data.accounts], 'id', 'name', (account) => account.primaryContact ? `${account.name} (${account.primaryContact.name})` : account.name)
        accounts = data.accounts
        this.setState({ accountOptions, accounts })

        if (purchaseOrder && purchaseOrder.id)
        {
          this.setState({ repOptions: this.setRepOptions(purchaseOrder.account.id) })
          this.setInputsFromPurchaseOrder()
          this.setState({ loading: false })
        }
      }
    }

    AccountAPI.getAll(callback)
  }

  setInputsFromPurchaseOrder = () =>
  {
    if (!this.state.purchaseOrder)
    {
      return;
    }

    var state         = this.state
    var purchaseOrder = state.purchaseOrder
    var inputs        = state.inputs

    let inputKeys = Object.keys(inputs)

    inputKeys.forEach((key) =>
    {
      let input   = inputs[key]
      input.valid = true

      switch(key)
      {
        case "divisionId" :
        case "brandId" :
        case "categoryId" :
        case "aged" :
        case "orderFrom" :
        case "ticketed" :
        case "ponumber" :
        case "orderDate" :
        case "startShippingDate" :
        case "cancelDate" :
        case "ticketsTrackingNumber" :
        case "topSamplesNeededDue" :
        {
          input.value = purchaseOrder[key]
          break
        }
        case "purchaseOrderFilesAttributes" :
        {
          input.value = purchaseOrder.purchaseOrderFiles
          break
        }
        case "hangers" :
        {
          input.value =  purchaseOrder[key] === 'Hangers-Yes'
          break
        }
        case "hold" :
        {
          input.value =  purchaseOrder['poOrHold'] === 'Hold'
          break
        }
        case "topSamplesNeeded" :
        {
          input.value = purchaseOrder[key] === 't'
          break
        }
        case "grossAmount" :
        {
          input.value = Number(purchaseOrder.grossAmount) || 0
          break
        }
        case "richTextMessageHtml":
        {
          input.value = purchaseOrder.richTextMessageHtml.toString().substring(43) || ''
          break
        }
        case "accountId" :
        {
          input.value = purchaseOrder.account.id
          state.accountText = this.setAccountText(input.value)
          break
        }
        case "userId" :
        {
          input.value = purchaseOrder.rep.id
          state.repText = this.getRepText(input.value)
          break
        }
        default :
        {
          break
        }
      }
    })

    state.messages = this.setInitialMessage()

    this.setState(state)
  }

  handleChange =  (e, data, fromLoad=false) =>
  {
    let state  = this.state
    let name, value;
    let inputs = state.inputs

    if (data && ['orderDate', 'startShippingDate', 'cancelDate', 'topSamplesNeededDue'].includes(data.name))
    {
      name  = data.name
      value = data.value
    }
    else if (e && ['divisionId', 'brandId', 'categoryId', 'accountId', 'userId'].includes(e.name))
    {
      value = e.value
      name  = e.name
    }
    else if (data && ['aged', 'hangers', 'hold', 'topSamplesNeeded'].includes(data.name))
    {
      name  = data.name
      value = data.checked
    }
    else
    {
      name  = data.name
      value = data.value
    }

    let userInteraction = !fromLoad
    let input   = inputs[name]
    input.value = value

    if (typeof input.value === 'object' || input.value instanceof Date)
    {
      input.message = 'Please enter a valid date.'
    }
    else if (
      typeof input.value !== 'boolean' &&
      input.required &&
      input.value.length == 0
    )
    {
      input.message = 'Please enter the value.'
    }
    else
    {
      input.message = ''
    }

    switch(name)
    {
      case "brandId" :
      {
        input.valid = !input.message
        this.validateBrandAccount()
        break
      }
      case "divisionId" :
      case "categoryId" :
      {
        input.valid   = !input.message
        break
      }
      case "orderDate" :
      case "startShippingDate" :
      case "cancelDate" :
      case "topSamplesNeededDue" :
      {
        input.valid = Utils.isValidDate(input.value)
        break
      }
      case "ticketed" :
      {
        if (['No', '', null].includes(input.value))
        {
          inputs.ticketsTrackingNumber.required = false
          inputs.ticketsTrackingNumber.valid = true
          inputs.ticketsTrackingNumber.value = ''
        }
        else
        {
          inputs.ticketsTrackingNumber.required = true
          inputs.ticketsTrackingNumber.valid = inputs.ticketsTrackingNumber.value.length > 0
          inputs.ticketsTrackingNumber.message = 'Tracking number is required.'
        }
        break
      }
      case "grossAmount" :
      {
        input.valid = Utils.isValidCurrency(input.value)
        input.message = "Please enter a valid amount."
        break
      }
      case "topSamplesNeeded" :
      {
        inputs.topSamplesNeededDue.required = input.value
        break
      }
      case "accountId" :
      {
        inputs.userId.value = null
        input.valid   = !!input.value
        input.message = "Account is required."

        state.accountText = this.setAccountText(input.value)
        state.repOptions  = this.setRepOptions(input.value)
        state.repText     = ''

        this.validateBrandAccount()
        break
      }
      case "userId" :
      {
        state.repText = this.getRepText(input.value)
        input.message = "Rep is required."
        input.valid   = !!input.value
        break
      }
      case "ponumber" :
      {
        input.value  = Utils.flatUpcase(input.value)
        input.valid  = !input.message
        var callback = (error, data) =>
        {
          if (!error)
          {
            this.setState({ poNumberIsTaken: data.isValid })
          }
        }

        PurchaseOrderAPI.validatePurchaseorder(input.value, callback)
        break
      }
      case "ticketsTrackingNumber" :
      {
        input.value  = Utils.flatUpcase(input.value)
        input.valid  = !input.message
        break
      }
      case "orderFrom" :
      case "purchaseOrderFilesAttributes" :
      {
        input.valid   = !input.message
        break
      }

      default :
      {
        break
      }
    }

    this.setState(state)
  }

  validateBrandAccount = () =>
  {
    var {inputs, accounts} = this.state
    var {customer} = this.context
    var account = accounts.find((acc) => acc.id == inputs.accountId.value)

    if (!account || !inputs.brandId.value) return;

    var brand = customer.brands.find(b => b.id == inputs.brandId.value)
    var approved = account.company.companyBrands.find(b => b.brandId == inputs.brandId.value).approved

    inputs.brandId.message = "Selected Contact is not approved for " + brand.name
    inputs.brandId.valid = brand.needsApproval ? approved : true
  }

  setInitialMessage = () =>
  {
    var messages        = []
    var {purchaseOrder} = this.props

    for (var file of purchaseOrder.purchaseOrderFiles)
    {
      messages.unshift('The file "' + file.filename + '" has been attached to the purchase order!')
    }

    return messages
  }

  setAccountText = (accountId) =>
  {
    if (!accountId) return;
    var {accountOptions} = this.state
    var account = accountOptions.find((acc) => acc.value === accountId)

    return account.text
  }

  getRepText = (userId) =>
  {
    if (!userId) return;
    var {repOptions} = this.state
    var rep = repOptions.find((rp) => rp.value === userId)

    return rep.text
  }

  savePO = () =>
  {
    if (this.props.purchaseOrder && this.props.purchaseOrder.id)
    {
      this.handleSubmit()
    }
    else
    {
      this.setState({ confirmModal: true })
    }
  }

  handleSubmit = () =>
  {
    const { loading, inputs, purchaseOrder } = this.state
    this.setState({ loading: true, confirmModal: false })

    let {data, valid} = this.getData()

    let callback = (error, data) =>
    {
      if (error)
      {
        this.setState({ loading: false, displayMessage: true, error: {apiError: true, error: error} })
      }
      else
      {
        const { message, setMessage } = this.context
        let action = purchaseOrder ? 'edited' : 'created a new'
        let flashMessage = `You have successfully ${action} purchase order: ${data.purchaseOrder.ponumber}`
        setMessage({ displayValue: flashMessage, type: 'success' })

        this.props.history.push('/sales-orders');
        this.setState({ loading: false })
      }
    }

    if (valid)
    {
      if (purchaseOrder && purchaseOrder.id)
      {
        PurchaseOrderAPI.update(purchaseOrder.id, {purchaseOrder: data}, callback)
      }
      else
      {
        PurchaseOrderAPI.create({purchaseOrder: data}, callback)
      }
    }
  }

  getData = () =>
  {
    let { inputs } = this.state
    let data = {}

    Object.keys(inputs).map((key) =>
    {
      data[key] = inputs[key].value
    })

    data.orderDate           = Utils.formatDate(data.orderDate)
    data.startShippingDate   = Utils.formatDate(data.startShippingDate)
    data.cancelDate          = Utils.formatDate(data.cancelDate)
    data.topSamplesNeededDue = Utils.formatDate(data.topSamplesNeededDue)

    return { data, valid: this.isInputsValid() }
  }

  isInputsValid = () =>
  {
    let {inputs, poNumberIsTaken} = this.state
    let valid = Utils.isInputsValidated(inputs)

    return valid && !poNumberIsTaken
  }

  closeConfirmModal = () =>
  {
    this.setState({ confirmModal: false })
  }

  addFile = (data, filename) =>
  {
    this.addLogMessage('The file "' + filename + '" has been attached to the purchase order!')
    let {inputs} = this.state
    let filedata = {filename: data.filename, url: data.url}
    inputs.purchaseOrderFilesAttributes.value.push(filedata)

    this.setState({ inputs })
  }

  handleAcceptedFiles = (acceptedFiles) =>
  {
    this.setState({ loading: true })

    var numberOfFilesToUpload = acceptedFiles.length
    var numberOfFilesUploaded = 0
    let url = Constants.API.apiEndPoint + 'upload-file?tempKey=' + Constants.API.tempApiKey

    for (var i=0; i<acceptedFiles.length; i++)
    {
      let file     = acceptedFiles[i]
      let formData = new FormData()
      let filename = file.name

      if (Utils.badFileType(file))
      {
        this.addLogMessage('Only allow image, microsoft word, excel, pdf file types.  File "' + filename + '" cannot be uploaded!')
      }
      else
      {
        formData.append("file", file, file.name)
        Utils.uploadFile(url, formData, (error, data) =>
        {
          if (error)
          {
            alert('There was an error when trying to upload the file.')
            numberOfFilesUploaded += 1

            if (numberOfFilesUploaded == numberOfFilesToUpload)
            {
              this.setState({ loading: false })
            }
          }
          else
          {
            if (data.success)
            {
              this.addFile(data, filename)
            }
            else
            {
              if (data.corrupt_file)
              {
                this.addLogMessage('The file "' + filename + '" is corrupt.');
              }
              else
              {
                alert('There was an error when trying to upload the file "' + filename + '"')
              }
            }

            numberOfFilesUploaded += 1
            if (numberOfFilesUploaded == numberOfFilesToUpload)
            {
              this.setState({ loading: false })
            }
          }
        })
      }
    }
  }

  addLogMessage = (message) =>
  {
    let {messages} = this.state
    messages.unshift(message)
    this.setState({ messages })
  }

  clearAttachedFiles = () =>
  {
    var {inputs} = this.state
    var poFiles  = inputs.purchaseOrderFilesAttributes.value

    var findAndRemove = (key, value) =>
    {
      let i = poFiles.findIndex((item) => item[key] == value)
      if (i > -1) poFiles.splice(i, 1)
    }

    for (let file of poFiles)
    {
      if (file.id && file.purchaseOrderId)
      {
        var callback = (error, data) =>
        {
          if (!error)
          {
            findAndRemove('id', file.id)
          }
        }

        PurchaseOrderAPI.deletePOFile(file.purchaseOrderId, file.id, callback)
      }
      else
      {
        findAndRemove('filename', file.filename)
      }
    }

    this.setState({ inputs, messages: [] });
  }

  onChangeFileName = (event, {value}, index, file) =>
  {
    let {inputs} = this.state
    let poFiles  = inputs.purchaseOrderFilesAttributes.value
    poFiles[index].filename = value.trim()

    this.setState({ inputs })
  }

  setRepOptions = (accountId) =>
  {
    var {accounts} = this.state
    var keys       = {text : ['name'], value: 'id'}
    let account    = accounts.find(acc => acc.id == accountId)
    var repOptions = Utils.toOptionsHashWithKeys(account.reps, keys)

    return repOptions
  }

  accountSelection = (e) =>
  {
    e.preventDefault()
    this.setState({ accountModal: false })
  }

  openAccountModal = (e) =>
  {
    e.preventDefault()
    this.setState({ accountModal: true })
  }

  closeAccountModal = () =>
  {
    this.setState({ accountModal: false })
  }

  toggleLogs = () =>
  {
    this.setState({ logsModal: !this.state.logsModal })
  }

  toggleMessageVisibility = () =>
  {
    this.setState({ displayMessage: !this.state.displayMessage })
  }

  render()
  {
    var {inputs, purchaseOrder}  = this.state
    var {customer} = this.context
    var divisions = customer && customer.divisions ? Utils.objectsToOptionsHash(customer.divisions, 'id', 'name') : []
    var categories = customer && customer.categories ? Utils.objectsToOptionsHash(customer.categories, 'id', 'name') : []
    var brands = customer && customer.brands ? Utils.objectsToOptionsHash(customer.brands, 'id', 'name') : []
    var editPO = purchaseOrder && purchaseOrder.id
    let errorMessage = ''

    if (
      this.state.error &&
      this.state.error.apiError &&
      this.state.error.error
    )
    {
      let errors   = this.state.error.error.response.data
      errorMessage = Object.keys(errors).map((key, i) =>
      {
        return <p key={i}><b>{key}: </b>{errors[key].join(', ')}</p>
      })
    }

    let space = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;

    let markup =
      <div>
        <div style={{display: 'flex', alignItems: 'flex-start', marginBottom: '20px'}}>
          <Header
            as='h1'
            content={this.props.title}
            style={{marginBottom: '25px'}}
          />
          {
            editPO &&
            <Button
              size='mini'
              onClick={this.toggleLogs}
              basic
              content={this.state.logsModal ? 'Hide edit logs' : 'Show edit logs'}
              style={{marginLeft: '20px'}}
            />
          }
        </div>
        <Form onSubmit={this.savePO} loading={this.state.loading}>
          {
            this.state.displayMessage && (
              <Message
                negative
                size='mini'
                onDismiss={this.toggleMessageVisibility}
                >
                <Message.Header>
                  We're sorry some error occured!
                </Message.Header>
                {errorMessage}
              </Message>
            )
          }
          <center>
            <div className="po-account-content">
              <div>
                <p>Contact: {this.state.accountText}</p>
                <p>Rep: {this.state.repText}</p>
              </div>
              <Modal
                trigger={
                  <Button
                    style={{maxHeight: '40px'}}
                    basic
                    icon='add'
                    onClick={this.openAccountModal}
                  />}
                onClose={this.closeAccountModal}
                open={this.state.accountModal}
                >
                <Modal.Header>
                  Associate account with the PO
                  <span style={{fontStyle: 'italic', textDecoration: 'underline'}}>
                    {this.state.ponumber}
                  </span>
                </Modal.Header>
                <Modal.Content style={{padding: '20px'}}>
                  <Modal.Description>
                    <Form>
                      <Form.Group widths='equal'>
                        <Form.Select
                          id="account-select"
                          name='accountId'
                          fluid
                          label='Contact'
                          onChange={(e, data) => this.handleChange(data, inputs.accountId)}
                          options={this.state.accountOptions}
                          placeholder='Select Contact'
                          value={inputs.accountId.value}
                          error={inputs.accountId.valid == false ? {
                            content: inputs.accountId.message,
                          } : false }
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Select
                          id="rep-select"
                          name='userId'
                          fluid
                          label='Rep'
                          onChange={(e, data) => this.handleChange(data, inputs.userId)}
                          options={this.state.repOptions}
                          placeholder='Select representative'
                          value={inputs.userId.value}
                          error={inputs.userId.valid == false ? {
                            content: inputs.userId.message,
                          } : false }
                        />
                      </Form.Group>
                    </Form>
                    <br/>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    basic
                    primary
                    onClick={this.accountSelection}
                    content='Ok'
                  />
                </Modal.Actions>
              </Modal>
            </div>
          </center>
          {
            editPO && this.state.logsModal &&
            <div style={{marginBottom: '30px'}}>
              <LogMessages
                titles={["PurchaseOrder Logs"]}
                logs={[purchaseOrder.logs]}
              />
            </div>
          }
          <div>
            <Form.Group widths='equal'>
              <Form.Select
                id="division-select"
                fluid
                name='divisionId'
                label='Division'
                onChange={(e, data) => this.handleChange(data, inputs.divisionId)}
                options={divisions}
                placeholder='Select Division'
                value={inputs.divisionId.value}
                error={inputs.divisionId.valid == false ? {
                  content: inputs.divisionId.message,
                } : false }
              />
              <Form.Select
                id="brand-select"
                fluid
                name='brandId'
                label='Brand'
                onChange={(e, data) => this.handleChange(data, inputs.brandId)}
                options={brands}
                placeholder='Select Brand'
                value={inputs.brandId.value}
                error={inputs.brandId.valid == false ? {
                  content: inputs.brandId.message,
                } : false }
              />
              <Form.Select
                id="category-select"
                fluid
                label='Category'
                name='categoryId'
                onChange={(e, data) => this.handleChange(data, inputs.categoryId)}
                options={categories}
                placeholder='Select Category'
                value={inputs.categoryId.value}
                error={inputs.categoryId.valid == false ? {
                  content: inputs.categoryId.message,
                } : false }
              />
            </Form.Group>
            <br/>
            <Grid columns={2} stackable>
              <Grid.Row>
                <Grid.Column style={{paddingRight: '5px'}}>
                  <Form.Group inline>
                    <label>Order From</label>
                    <Form.Radio
                      name='orderFrom'
                      label='In Stock'
                      value='In Stock'
                      checked={inputs.orderFrom.value === 'In Stock'}
                      onChange={this.handleChange}
                    />
                    <Form.Radio
                      name='orderFrom'
                      label='Future Stock'
                      value='Future Stock'
                      checked={inputs.orderFrom.value === 'Future Stock'}
                      onChange={this.handleChange}
                    />
                    <Form.Radio
                      name='orderFrom'
                      label='Private Order'
                      value='Private Order'
                      checked={inputs.orderFrom.value === 'Private Order'}
                      onChange={this.handleChange}
                    />
                    {space}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column style={{paddingRight: '5px'}}>
                  <div style={{display: 'inline-block'}}>
                    <Form.Group inline id="ticketed-radio">
                      <label>Ticketed</label>
                      <Form.Radio
                        name='ticketed'
                        label='No'
                        value='No'
                        checked={inputs.ticketed.value === 'No'}
                        onChange={this.handleChange}
                      />
                      <Form.Radio
                        name='ticketed'
                        label='Yes-LA'
                        value='Yes-LA'
                        checked={inputs.ticketed.value === 'Yes-LA'}
                        onChange={this.handleChange}
                      />
                      <Form.Radio
                        name='ticketed'
                        label='Yes-China'
                        value='Yes-China'
                        checked={inputs.ticketed.value === 'Yes-China'}
                        onChange={this.handleChange}
                      />
                      <Form.Radio
                        name='ticketed'
                        label='Yes-LA/China'
                        value='Yes-LA/China'
                        checked={inputs.ticketed.value === 'Yes-LA/China'}
                        onChange={this.handleChange}
                      />
                      <Form.Radio
                        name='ticketed'
                        label='Yes-Bangladesh'
                        value='Yes-Bangladesh'
                        checked={inputs.ticketed.value === 'Yes-Bangladesh'}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    {
                      !(['No', '', null].includes(inputs.ticketed.value)) &&
                      <Form.Input
                        id="ticketed-number"
                        fluid
                        name='ticketsTrackingNumber'
                        label='Tickets Tracking Number'
                        placeholder='Tickets Tracking Number'
                        value={inputs.ticketsTrackingNumber.value}
                        onChange={this.handleChange}
                        error={inputs.ticketsTrackingNumber.valid == false ? {
                          content: inputs.ticketsTrackingNumber.message,
                        } : false }
                      />
                    }
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br/>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Form.Group inline>
                    <Checkbox
                      toggle
                      name='aged'
                      label='Aged'
                      onChange={this.handleChange}
                      checked={inputs.aged.value}
                    />
                    {space}
                    <Checkbox
                      toggle
                      label='Hangers'
                      name='hangers'
                      onChange={this.handleChange}
                      checked={inputs.hangers.value}
                    />
                    {space}
                    <Checkbox
                      toggle
                      label='Hold'
                      name='hold'
                      onChange={this.handleChange}
                      checked={inputs.hold.value}
                    />
                    {space}
                    <Checkbox
                      toggle
                      name='topSamplesNeeded'
                      label='Top Samples Needed?'
                      onChange={this.handleChange}
                      checked={inputs.topSamplesNeeded.value}
                    />
                    {space}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Group>
                    {
                      inputs.topSamplesNeeded.value &&
                      <div className="date-picker">
                        <DatePickerField
                          minDate={new Date}
                          label="Due Date for Samples"
                          placeholderText='mm/dd/yyyy'
                          selected={inputs.topSamplesNeededDue.value ? new Date(inputs.topSamplesNeededDue.value) : null}
                          isClearable
                          onChange={(data, e) => this.handleChange(e, {name: 'topSamplesNeededDue', value: data})}
                          error={inputs.topSamplesNeededDue.valid == false ? {
                            content: inputs.topSamplesNeededDue.message,
                          } : false }
                        />
                      </div>
                    }
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br/>
            <Form.Group widths={4}>
              <Form.Input
                id="gross-amount"
                type="number"
                step="any"
                name='grossAmount'
                fluid
                label='Gross Amount'
                icon='dollar sign'
                iconPosition='left'
                placeholder='0.00'
                onChange={this.handleChange}
                value={Number(inputs.grossAmount.value).toString()}
                error={inputs.grossAmount.valid == false ? {
                  content: inputs.grossAmount.message,
                } : false }
              />
            </Form.Group>
            <br/>
            <Form.Field>
              <label>Message</label>
              <ReactQuill
                modules={this.modules}
                className="text-editor"
                value={inputs.richTextMessageHtml.value}
                onChange={(val) => this.handleChange(null, {name: 'richTextMessageHtml', value: val})}
              />
            </Form.Field>
            <br/>
            <Form.Group widths='equal'>
              <div className="field" id="po-number-section">
                <Form.Input
                  id="po-number"
                  readOnly={editPO}
                  onChange={this.handleChange}
                  fluid
                  label={this.state.poNumberIsTaken ? 'PO Number (Is taken)' : 'PO Number'}
                  icon={this.state.poNumberIsTaken ? 'close' : 'check'}
                  iconPosition='left'
                  placeholder='PO Number'
                  name='ponumber'
                  value={inputs.ponumber.value}
                  error={inputs.ponumber.valid == false ? {
                    content: inputs.ponumber.message,
                  } : false }
                />
              </div>
              <Form.Field className="date-picker">
                <DatePickerField
                  minDate={new Date}
                  label='Order Date'
                  placeholderText='mm/dd/yyyy'
                  selected={inputs.orderDate.value ? new Date(inputs.orderDate.value) : null}
                  isClearable
                  onChange={(data, e) => this.handleChange(e, {name: 'orderDate', value: data})}
                  error={inputs.orderDate.valid == false ? {
                    content: inputs.orderDate.message,
                  } : false }
                />
              </Form.Field>
              <Form.Field className="date-picker">
                <DatePickerField
                  minDate={new Date}
                  label='Start Shipping'
                  placeholderText='mm/dd/yyyy'
                  selected={inputs.startShippingDate.value ? new Date(inputs.startShippingDate.value) : null}
                  isClearable
                  onChange={(data, e) => this.handleChange(e, {name: 'startShippingDate', value: data})}
                  error={inputs.startShippingDate.valid == false ? {
                    content: inputs.startShippingDate.message,
                  } : false }
                />
              </Form.Field>
              <Form.Field className="date-picker">
                <DatePickerField
                  minDate={new Date}
                  label='Cancel Date'
                  placeholderText='mm/dd/yyyy'
                  selected={inputs.cancelDate.value ? new Date(inputs.cancelDate.value) : null}
                  isClearable
                  onChange={(data, e) => this.handleChange(e, {name: 'cancelDate', value: data})}
                  error={inputs.cancelDate.valid == false ? {
                    content: inputs.cancelDate.message,
                  } : false }
                />
              </Form.Field>
            </Form.Group>
            <h3>
              Attach PO
              {
                inputs.purchaseOrderFilesAttributes.value.length > 0 &&
                <small>
                  <a style={{cursor: 'pointer', marginLeft: '40px'}}
                    onClick={this.clearAttachedFiles}>
                    Clear attached files
                  </a>
                </small>
              }
            </h3>
            <Form.Group widths={2} id="po-files-row">
              {
                inputs.purchaseOrderFilesAttributes.value.map((file, i) => {
                  return (
                    <Form.Field inline key={i}>
                      <Form.Input
                        label={i+1+')'}
                        fluid
                        placeholder='File name'
                        value={file.filename}
                        onChange={(e, data) => this.onChangeFileName(e, data, i, file)}
                        readOnly={!!file.id}
                      />
                    </Form.Field>
                  )
                })
              }
            </Form.Group>
            {
              this.state.messages && this.state.messages.length > 0 &&
              <div className="po-file-messages">
                {
                  this.state.messages.map((message, index) => {
                    return (
                      <div key={index}>{message}</div>
                    )
                  })
                }
              </div>
            }
            <Dropzone onDrop={(files) => this.handleAcceptedFiles(files)}>
              {
                ({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()} id="po-files-dropzone">
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )
              }
            </Dropzone>
            <br/>
            <Button
              style={{marginTop: '20px'}}
              primary
              content='Submit'
              disabled={!this.isInputsValid()}
            />
          </div>
        </Form>

        <br/><br/><br/>

        <Modal open={this.state.confirmModal}>
          <Modal.Content>
            <center>
              <div style={{padding: '20px', display: 'inline-block', textAlign: 'left', marginBottom: '20px'}}>
                <h3>Checklist</h3>
                <ul style={{listStyleType: 'none', paddingLeft: 0}}>
                  <li>
                    <input type="checkbox" disabled checked/>
                    &nbsp; Make sure to double check your gross amount, if PO is multiple pages is gross the sum of the pages?
                    <br/><br/>
                  </li>
                  <li>
                    <input type="checkbox" disabled checked/>
                    &nbsp; If you are revising a PO, make sure to cancel the old! Or instead just edit and replace the PDF on the old PO.
                    <br/><br/>
                  </li>
                  <li>
                    <input type="checkbox" disabled checked/>
                    &nbsp; If your PO is only the first page of the PDF, make sure to only upload the PDF pages that apply to that order
                    <br/><br/>
                  </li>
                  <li>
                    <input type="checkbox" disabled checked/>
                    &nbsp; Note for Tilly's: If your PDF file is multiple pages, separate PDF using
                    &nbsp; <a href="https://www.splitpdf.com">splitpdf.com</a> &nbsp;
                    and treat each page as separate purchase order!
                  </li>
                </ul>
              </div>
            </center>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color='red'
              onClick={this.closeConfirmModal}
              basic
              content='Cancel'
            />
            <Button
              color='green'
              onClick={this.handleSubmit}
              content='Confirm'
            />
          </Modal.Actions>
        </Modal>
      </div>

    return markup;
  }
}

export default withRouter(PurchaseOrderForm)
