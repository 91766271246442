import React from 'react'
import {Dropdown} from 'semantic-ui-react'

const impersonateContactsDropdown = (contactOptions, contactOnChange, contact) => {
  return (
    <div id="impersonateContactsDropdown">
      <Dropdown
        placeholder='Contact'
        fluid
        multiple
        search
        selection
        options={contactOptions}
        onChange={contactOnChange}
        value={contact}
        style={{width: '300px'}}
      />
    </div>
  )
}

export {impersonateContactsDropdown}
