import React from 'react'
import { Button, Popup } from 'semantic-ui-react'

const labelStyle = {fontSize: '150%', whiteSpace: 'nowrap', minWidth: '285px', display: 'inline-block', fontWeight: 'bold', verticalAlign: 'top'}

const styleNumberInfo = 'Each style number must be on a separate line!'

const infoStyle = {opacity: 0.5, fontSize: '50%', padding: '5px', borderRadius: '20px', position: 'relative', bottom: '3px', marginLeft: '5px'}

function InfoCircle(text) {
  return (
    <Popup content={text} trigger={<Button color='blue' icon='info' style={infoStyle}/>} />
  )
}

const textAreaStyle = {width: '50%', minWidth: '50%', maxWidth: '50%', height: '200px', minHeight: '200px', verticalAlign: 'top', padding: '10px', fontSize: '150%', marginLeft: '80px'}

const customerOrderNumberInfo = 'Important: PO # must be unique per contact!'

const holdNumberInfo = 'Important: HOLD # will be the same as REF #'

export {labelStyle, styleNumberInfo, infoStyle, InfoCircle, textAreaStyle, customerOrderNumberInfo, holdNumberInfo}
