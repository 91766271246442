import React, {Component} from 'react'
import {Loader} from 'semantic-ui-react'

class CadsLoadingBox extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    if (this.props.loadingCads || this.props.loadingSalesHistoryByStyleNumber || this.props.loadingSalesHistoryByAccount) {
      return (
        <div className="cads-loading-box">
          <div style={{paddingTop: '20px', paddingBottom: '20px', display: 'inline-block'}}>
            <Loader active inline size='medium'>Loading</Loader>
          </div>

          <ul>
            {this.props.loadingCads ? <li>Cads</li> : null}
            {this.props.loadingSalesHistoryByStyleNumber ? <li>Sales history by style number</li> : null}
            {this.props.loadingSalesHistoryByAccount ? <li>Sales history by account</li> : null}
            {this.props.loadingVpos ? <li>VPOs</li> : null}
            {this.props.loadingContainerNumbers ? <li>Container Numbers</li> : null}
          </ul>
        </div>
      )
    } else {
      return null
    }
  }
}

export default CadsLoadingBox
