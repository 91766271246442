import React, {Component} from 'react'
import {Popup, Label, Icon} from 'semantic-ui-react'
import {modal} from './modal'
import $ from 'jquery'
import {saveUpc} from './save'
import {getUpc, getUpcTotals} from './get'
import {cadCardRowstyle} from '../cad-card/row-style'

class UpcPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      numberOfUpcs: null,
      cadId: this.props.cad.id,
      upcText: ''
    }

    this.openUpc = this.openUpc.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.saveUpc = this.saveUpc.bind(this)
    this.addFilename = this.addFilename.bind(this)
    this.removeFilename = this.removeFilename.bind(this)
    this.isInViewport = this.isInViewport.bind(this)
    this.getNumberOfUpcs = this.getNumberOfUpcs.bind(this)
    this.updateFromWindowDot = this.updateFromWindowDot.bind(this)
    this.reloadUpcTotal = this.reloadUpcTotal.bind(this)
  }

  openUpc(cad) {
    this.setState({open: true})
    getUpc(cad.id, (err, res) => {
      if (err) {
        alert('There was an error when trying to get UPC info.')
      } else {
        $('#addUpc').val(this.getUpcText(res.data))
      }
    })
  }

  getUpcText(upc) {
    let text = ''
    if (upc != null) {
      let {data} = upc
      Object.keys(data).forEach(size => text += (size.toUpperCase()+'='+data[size]+"\n"))
    }
    return text
  }

  closeModal() {
    this.setState({open: false})
  }

  saveUpc() {
    let {cad} = this.props
    let text = $('#addUpc').val().trim()
    saveUpc(cad.id, text, (err, res) => {
      if (err) {
        alert('There was an error when trying to save UPC info.')
      } else {
        alert('UPC info saved')
      }
    })
  }

  addFilename(filename) {
    let {filenames} = this.state
    filenames.push(filename)
    this.setState({filenames})
  }

  removeFilename(filename) {
    let {filenames} = this.state
    filenames.filter(x => x != filename)
    this.setState({filenames})
  }

  isInViewport() {
    let {numberOfUpcs} = this.state
    let {cad} = this.props
    //get how much pixels left to scrolling our ReactElement
    const {top} = document.getElementById('upc-label-'+cad.id).getBoundingClientRect()
    //here we check if element top reference is on the top of viewport
    /*
    * If the value is positive then top of element is below the top of viewport
    * If the value is zero then top of element is on the top of viewport
    * If the value is negative then top of element is above the top of viewport
    * */
    if(top <= 1000 && numberOfUpcs == null) {
      document.removeEventListener("scroll", this.isInViewport)
      this.getNumberOfUpcs()
        //console.log("Element is in view or above the viewport")
    } else {
        // console.log("Element is outside view");
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", this.isInViewport)
    window.addEventListener('get-upc-totals-done', this.updateFromWindowDot)
  }

  //do not forget to remove it after destroyed
  componentWillUnmount() {
    document.removeEventListener("scroll", this.isInViewport)
  }

  getNumberOfUpcs() {
    let {cad} = this.props
    window.upcsTotals[cad.id] = '⏳' //loading
    this.updateFromWindowDot()

    clearTimeout(window.upcsTotalsSetTimeout)
    window.upcsTotalsSetTimeout = setTimeout(() => {
      getUpcTotals((err, res) => {
        if (err) {
          alert('There was an error when trying to get UPCs totals')
        } else {
          let {totals, upcs} = res.data
          Object.keys(totals).forEach(cadId => window.upcsTotals[cadId] = totals[cadId])
          upcs.forEach(upc => window.upcs[upc.cadId] = this.getUpcText(upc))
          //send out event to update from window to state
          window.dispatchEvent(new Event('get-upc-totals-done'))
        }
      })
    }, 1500)
  }

  updateFromWindowDot() {
    let {cad} = this.props
    this.setState({numberOfUpcs: window.upcsTotals[cad.id], upcText: window.upcs[cad.id]})
  }

  reloadUpcTotal() {
    let {cad} = this.props
    this.setState({numberOfUpcs: null, cadId: cad.id})
    document.addEventListener("scroll", this.isInViewport)
  }

  render() {
    let {cad, showUPC} = this.props
    let {open, cadId, numberOfUpcs, upcText} = this.state

    if (this.props.cad.id != cadId) {
      this.reloadUpcTotal()
      return ''
    }


    let loading = window.upcsTotals[cadId] == '⏳'

    return (
      <React.Fragment>
        {modal(open, this.closeModal, this.saveUpc, this.addFilename, this.removeFilename, cad)}
        <Popup content='Edit UPC' trigger={
          <Label id={'upc-label-'+cad.id} style={{marginTop: '2px', cursor: 'pointer'}} onClick={this.openUpc.bind(null, cad)}>
            <Icon name='barcode' /> {numberOfUpcs} UPC
          </Label>
        } />
        {
          showUPC
          ?
          <center style={cadCardRowstyle}>
            {
              loading
              ?
              'UPC loading...'
              :
              window.upcsTotals[cadId] == 0
              ?
              'No UPC found'
              :
              <pre style={{textAlign: 'left', paddingLeft: '5px', paddingRight: '5px', columnCount: 2}}>{upcText}</pre>
            }
          </center>
        :
        null
      }
      </React.Fragment>
    )
  }
}

export default UpcPopup
