import React from 'react'
import {Menu, Dropdown} from 'semantic-ui-react'
import {downloadPdf, downloadExcel} from './download'

let setLoadingExportLocal

const exportOptions = [
  { key: 'PDF', text: 'PDF', value: 'PDF' },
  // { key: 'Excel', text: 'Excel', value: 'Excel' }
]

const exportOnChange = (e, {value}) => {
  let keys = Object.keys(window.printIds)

  if (keys.length == 0) {
    alert('Select at least 1 PRINT')
  } else {
    if (value == 'PDF') {
      setLoadingExportLocal(true)
      downloadPdf(setLoadingExportLocal)
    } else if (value == 'Excel') {
      setLoadingExportLocal(true)
      downloadExcel(setLoadingExportLocal)
    } else {
      //nothing
    }
  }
}

const exportButton = (numSelected, loadingExport, setLoadingExport) => {
  setLoadingExportLocal = setLoadingExport
  return (
    <Menu compact style={{boxShadow: 'none'}}>
      <Dropdown id="export-dropdown" disabled={loadingExport} loading={loadingExport}
        text={numSelected == 0 ? 'Export' : 'Export (' + numSelected + ')' }
        options={exportOptions}
        simple item onChange={exportOnChange} value={null} />
    </Menu>
  )
}

export {exportButton}
