import React, {Component} from 'react'
import {Popup, Icon} from 'semantic-ui-react'

let longDashStyle = {
  color: 'rgb(89,182,85)',
  fontSize: '19px',
  display: 'inline-block',
  paddingRight: '3px',
  marginTop: '-10px',
  marginBottom: '-10px',
  cursor: 'default'
}

class DisplayStyleNumberStatus extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {loading, statuses} = this.props
    if (loading) {
      return null
    } else {
      return (
        <div style={{float: 'left', marginTop: '10px'}}>
        {statuses.map((status, index) => {
          switch(status) {
            case 'found':
              return (
                <React.Fragment key={index}>
                  {index+1} <Icon name='check' color='green'/><br/>
                </React.Fragment>
              )
            case 'duplicate':
              return (
                <React.Fragment key={index}>
                  {index+1} <Popup position='left center' content='Duplicate found' trigger={<span style={longDashStyle}>—</span>}/><br/>
                </React.Fragment>
              )
            case null:
              return <br key={index}/>
            case 'not found':
              return (
                <React.Fragment key={index}>
                  {index+1} <Popup position='left center' content='Not found' trigger={<Icon name='x' color='red'/>}/><br/>
                </React.Fragment>
              )
            default:
              return <br key={index}/>
          }
        })}
        </div>
      )
    }

  }
}

export default DisplayStyleNumberStatus
