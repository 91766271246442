const processText = (text) => {
  let rows = text.split("\n")
  let zwbs_regex = /\p{Space}/gi

  return rows.map(row => {
    return row.replace(zwbs_regex, ' ').trim().toUpperCase()
  })
}

export {processText}
