import $ from 'jquery'

const showScrollToTop = () => {
  $(window).scroll(function() {
    if ($(window).scrollTop() + $(window).height() < 1000 && $('#scroll-to-top').is(":visible") ) {
      $('#scroll-to-top').hide();
    }
    if ($(window).scrollTop() + $(window).height() >= 1000 && !$('#scroll-to-top').is(":visible") ) {
      $('#scroll-to-top').fadeIn();
    }
  })
}

export {showScrollToTop}
