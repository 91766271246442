import React              from 'react'
import {withRouter}       from 'react-router-dom'
import PurchaseOrderForm  from './../purchase-order-form'
import {LoadingParagraph} from './../../ui/loading-paragraph'

class NewPurchaseOrder extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render()
  {
    return (
      this.state.loading ?
      null
      :
      <div style={{padding: '60px', paddingTop: '75px'}}>
        <PurchaseOrderForm
          history={this.props.history}
          title='New sales order'
        />
      </div>
    )
  }
}

export default withRouter(NewPurchaseOrder)
