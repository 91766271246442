import React, { Component }from "react"
import AccountForm         from "../account-form"
import CompanyApi          from '../../../modules/api/company_api.js'
import UserAPI             from '../../../modules/api/user_api.js'
import {LoadingParagraph}  from '../../ui/loading-paragraph';

export class AccountView extends Component
{

  constructor(props)
  {
    super(props)
    this.state = {
      loadingCompanies : true,
      loadingRepUsers  : true,
      companies        : null,
      usersForReps     : null
    }
  }

  componentDidMount() {
    this.fetchCompanies();
    this.fetchReps()
  }

  fetchCompanies = () => {
    CompanyApi.getAll((error, response) =>
    {
      if (error)
      {
        console.log(error)
      }
      else
      {
        let {companies} = response
        this.setState({ companies: companies, loadingCompanies: false });
      }
    })
  };

  fetchReps = () =>
  {
    var {usersForReps} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        usersForReps = data.reps
        this.setState({ usersForReps, loadingRepUsers: false })
      }
    }

    UserAPI.getAllReps(callback)
  }


  render()
  {
      let { loadingCompanies, loadingRepUsers, companies, usersForReps } = this.state
      return loadingRepUsers || loadingCompanies ?
        null
        :
        <AccountForm
          companies={companies}
          usersForReps={usersForReps}
          history={this.props.history}
        />
  }
}

export default AccountView
