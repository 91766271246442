import React, {useState}       from "react"
import {NavLink, Link}         from "react-router-dom"
import {Menu, Dropdown, Image, Icon} from 'semantic-ui-react'
import {useAuth0}              from "./../../contexts/auth-context"
import {ROLE_PERMISSIONS}      from './../../modules/constants'
import CadDropdown from './cad-dropdown.js'
import VpoDropdown from './vpo-dropdown.js'
import AdminPinInput from '../admin/pin-input.js'
import UserDropdown from './user-dropdown.js'
import {printsLink} from './prints-link.js'
import ConferenceDropdown from './conference-dropdown'
import ArtistDropdown from './artist-dropdown.js'

const Nav = props => (
  <NavLink
    exact
    {...props}
    activeClassName="active"
  />
)

const NavBar = () =>
{
  const [cadMenu, setCadMenu] = useState(false)
  const [artistMenu, setArtistMenu] = useState(false)
  const [poMenu, setPOMenu] = useState(false)
  const [accountMenu, setAccountMenu] = useState(false)
  const [hrMenu, setHRMenu] = useState(false)
  const [vpoMenu, setVpoMenu] = useState(false)
  const [conferenceMenu, setConferenceMenu] = useState(false)
  const {isAuthenticated, loginWithRedirect, logout, user} = useAuth0()

  var userAttribution = ""
  var isHR = user && ROLE_PERMISSIONS.hr.includes(user.role)
  var isAdmin = user && ROLE_PERMISSIONS.admin.includes(user.role)
  var isWarehouse = user && ROLE_PERMISSIONS.warehouse.includes(user.role)
  var isSales = user && ROLE_PERMISSIONS.sales.includes(user.role)

  if (user && user.name)
  {
    let extraUserText = ''
    if (user.role === 'sales_assistant' && user.salesAdmin != null) {
      extraUserText = ' for ' + user.salesAdmin.name
    }
    if (user.role === 'warehouse') {
      extraUserText = ' at ' + user.warehouse
    }
    userAttribution = `${user.name} (${user.role.toUpperCase().replace(/_/g, ' ')}${extraUserText})`
  }

  const logoutSession = (e) =>
  {
    if (window.confirm('Are you sure you want to sign out?')) {
      sessionStorage.clear()
      localStorage.clear()
      logout({ returnTo: window.location.origin })
    }
  }

  let navbar =
    <div id="main-nav">
      <Menu style={{padding: '15px 40px'}}>
        <Menu.Item name='logo' className="brand-section">
          <a href="/#/"><Image src={process.env.REACT_APP_LOGO_IMG_SRC} /></a>
        </Menu.Item>

        {
          isAuthenticated && user && ROLE_PERMISSIONS.artist.includes(user.role)
          ?
          <ArtistDropdown
            isAuthenticated={isAuthenticated}
            user={user}
            artistMenu={artistMenu}
            setArtistMenu={setArtistMenu}
            Nav={Nav}
          />
          :
          <CadDropdown
            isAuthenticated={isAuthenticated}
            user={user}
            cadMenu={cadMenu}
            setCadMenu={setCadMenu}
            Nav={Nav}
          />
        }

        {
          isAuthenticated && user ? (
            false && isWarehouse ?
            <span
              onMouseOver={(e) => setPOMenu(true)}
              onMouseOut={(e) => setPOMenu(false)}
              style={user.role == 'warehouse' ? {marginLeft: '100px'} : null}
              >
              <Dropdown
                item
                as={Nav}
                open={poMenu}
                icon=''
                to="/sales-orders"
                name="sales-orders"
                text="Sales orders"
                style={{background: 'transparent'}}
                >
                <Dropdown.Menu>
                  {isWarehouse ?
                    <Dropdown.Item
                      as={Nav}
                      to="/bols/new"
                      content="Upload BOL"
                      onClick={(e) => setPOMenu(false)}
                    />
                  : null}
                </Dropdown.Menu>
              </Dropdown>
            </span>
            :
            isSales || isAdmin ? (
              <span
                onMouseOver={(e) => setPOMenu(true)}
                onMouseOut={(e) => setPOMenu(false)}
              >
                <Dropdown
                  item
                  as={Nav}
                  open={poMenu}
                  icon=''
                  to="/sales-orders"
                  name="sales-orders"
                  text="Sales orders"
                  style={{background: 'transparent'}}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Nav}
                      to="/sales-orders/new"
                      content="New sales order"
                      onClick={(e) => setPOMenu(false)}
                    />
                    <Dropdown.Item
                      as={Nav}
                      to="/mark_shipped"
                      content="Mark as shipped"
                      onClick={(e) => setPOMenu(false)}
                    />
                    <Dropdown.Item content="Upload actions" className="item header" id="main-nav-divider"/>
                    <Dropdown.Item
                      as={Nav}
                      to="/distros/new"
                      content="Upload distro"
                      onClick={(e) => setPOMenu(false)}
                    />
                    <Dropdown.Item
                      as={Nav}
                      to="/packing-slips/new"
                      content="Upload packing slip"
                      onClick={(e) => setPOMenu(false)}
                    />
                    <Dropdown.Item
                      as={Nav}
                      to="/bols/new"
                      content="Upload BOL"
                      onClick={(e) => setPOMenu(false)}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            ) : null
          ) : null
        }

        {
          isAuthenticated && (isAdmin || isSales)
          ?
          <span
            onMouseOver={(e) => setAccountMenu(true)}
            onMouseOut={(e) => setAccountMenu(false)}
            >
            <Dropdown
              item
              icon=''
              as={Nav}
              to="/contacts"
              name="contacts"
              text="Contacts"
              open={accountMenu}
              style={{background: 'transparent'}}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Nav}
                  to="/contacts/new"
                  content="New contact"
                  onClick={(e) => setAccountMenu(false)}
                />
                {isAdmin ?
                  <Dropdown.Item
                    as={Nav}
                    to="/accounts/reps"
                    content="Account reps"
                    onClick={(e) => setAccountMenu(false)}
                  />
                : null}
                {isAdmin ?
                  <Dropdown.Item
                    as={Nav}
                    to="/accounts/edit"
                    content="Accounts"
                    onClick={(e) => setAccountMenu(false)}
                  />
                : null}
              </Dropdown.Menu>
            </Dropdown>
          </span>
          :
          null
        }

        <VpoDropdown
          isAuthenticated={isAuthenticated}
          user={user}
          vpoMenu={vpoMenu}
          setVpoMenu={setVpoMenu}
          Nav={Nav}
        />

        {isAuthenticated ? printsLink(user) : null}

        {
          isAuthenticated && user
          ?
          ROLE_PERMISSIONS.artist.includes(user.role)
          ?
          null
          :
          <ConferenceDropdown
            isAuthenticated={isAuthenticated}
            user={user}
            conferenceMenu={conferenceMenu}
            setConferenceMenu={setConferenceMenu}
            Nav={Nav}
          />
          :
          null
        }

        {
          isAuthenticated && isHR &&
          <span
            onMouseOver={(e) => setHRMenu(true)}
            onMouseOut={(e) => setHRMenu(false)}
            >
            <Dropdown
              className="hr-menu"
              item
              icon='key'
              name="admin"
              text="Admin"
              open={hrMenu}
              style={{background: 'transparent'}}
              >
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Nav}
                  to="/users"
                  content={isAdmin ? "Users & organizations" : "Users"}
                  onClick={(e) => setHRMenu(false)}
                />
                <Dropdown.Item
                  as={Nav}
                  to="/reports"
                  content="Reports"
                  onClick={(e) => setHRMenu(false)}
                />
              </Dropdown.Menu>
            </Dropdown>
          </span>
        }

        {
          isAuthenticated && user
          ?
          <AdminPinInput />
          :
          null
        }

        <Menu.Menu position='right'>
        {
          isAuthenticated && user ?
          <span style={{display: 'flex'}}>
            <Menu.Item
              content={<small id="inventory-report-from"></small>}
              style={{background: 'transparent', marginRight: '40px', opacity: 0.5}}
            />

          </span>
          :
          null
        }


        {
          isAuthenticated && user ?
          <span style={{display: 'flex'}}>
            <UserDropdown userAttribution={userAttribution} logoutSession={logoutSession}/>
          </span>
          :
          window.location.href.indexOf('localhost') > -1 ?
          <span style={{display: 'flex'}}>
            <Menu.Item
              name="login"
              content="Login"
              onClick={() => loginWithRedirect({})}
              style={{background: 'transparent', opacity: 0}}
            />
            <Menu.Item
              onClick={logoutSession}
              content="Logout"
              style={{background: 'transparent'}}
            />
          </span>
          :
          <Menu.Item
            name="login"
            content="Login"
            onClick={() => loginWithRedirect({})}
            style={{background: 'transparent', opacity: 0}}
          />
        }
        </Menu.Menu>
      </Menu>
    </div>

  return navbar
}

export default NavBar;
