import {ADD_DAYS_TO_LOADING_DATE, ADD_DAYS_TO_EX_FACTORY_DATE} from '../../modules/constants'
import {getSalesOrdersFilteredByStyleNumber, getSalesOrdersFiltered} from './sales-orders/get'

const compareSalesOrderDatesByStyleNumber = (a, b) => {
    let sales_orders_a = getSalesOrdersFilteredByStyleNumber(a.style_number, a.id)
    let hold_dates_a = []
    if (!!sales_orders_a) {
      for (var i=0; i<sales_orders_a.length; i++) {
        var sales_order = sales_orders_a[i];
        if (!sales_order.hold && !sales_order.worksheet) {
          hold_dates_a.push(sales_order.date);
        }
      }
    }


    var hold_date_a = hold_dates_a.sort()[0];

    let sales_orders_b = getSalesOrdersFilteredByStyleNumber(b.style_number, b.id)
    let hold_dates_b = []
    if (!!sales_orders_b) {
      for (var j=0; j<sales_orders_b.length; j++) {
        var sales_order_b = sales_orders_b[j];
        if (!sales_order_b.hold && !sales_order_b.worksheet) {
          hold_dates_b.push(sales_order_b.date);
        }
      }
    }


    var hold_date_b = hold_dates_b.sort()[0];


    if (hold_date_a > hold_date_b) return 1;
    if (hold_date_b > hold_date_a) return -1;

    return 0;
  }

const compareSalesOrderDates = (a, b) => {
    let sales_orders_a = getSalesOrdersFiltered(a.style_number, a.id)
    let hold_dates_a = []
    if (!!sales_orders_a) {
      for (var i=0; i<sales_orders_a.length; i++) {
        var sales_order = sales_orders_a[i];
        if (!sales_order.hold && !sales_order.worksheet) {
          hold_dates_a.push(sales_order.date);
        }
      }
    }


    var hold_date_a = hold_dates_a.sort()[0];

    let sales_orders_b = getSalesOrdersFiltered(b.style_number, b.id)
    let hold_dates_b = []
    if (!!sales_orders_b) {
      for (var j=0; j<sales_orders_b.length; j++) {
        var sales_order_b = sales_orders_b[j];
        if (!sales_order_b.hold && !sales_order_b.worksheet) {
          hold_dates_b.push(sales_order_b.date);
        }
      }
    }


    var hold_date_b = hold_dates_b.sort()[0];


    if (hold_date_a > hold_date_b) return 1;
    if (hold_date_b > hold_date_a) return -1;

    return 0;
  }

const compareHoldSalesHistoryDatesByStyleNumber = (a, b) => {
    let sales_orders_a = getSalesOrdersFilteredByStyleNumber(a.style_number, a.id)
    let hold_dates_a = []
    if (!!sales_orders_a) {
      for (var i=0; i<sales_orders_a.length; i++) {
        var sales_order = sales_orders_a[i];
        if (sales_order.hold) {
          hold_dates_a.push(sales_order.date);
        }
      }
    }


    var hold_date_a = hold_dates_a.sort()[0];

    let sales_orders_b = getSalesOrdersFilteredByStyleNumber(b.style_number, b.id)
    let hold_dates_b = []
    if (!!sales_orders_b) {
      for (var j=0; j<sales_orders_b.length; j++) {
        var sales_order_b = sales_orders_b[j];
        if (sales_order_b.hold) {
          hold_dates_b.push(sales_order_b.date);
        }
      }
    }


    var hold_date_b = hold_dates_b.sort()[0];


    if (hold_date_a > hold_date_b) return 1;
    if (hold_date_b > hold_date_a) return -1;

    return 0;
  }

const compareHoldSalesHistoryDates = (a, b) => {

    // let sales_orders = window.salesOrderDataFiltered[a.style_number];
    // console.log(sales_orders);
    // let sales_order_dates = [];
    //
    // if (!!sales_orders) {
    //   for (var i=0;i<sales_orders.length;i++) {
    //     let sales_order = sales_orders[i];
    //     //console.log(sales_order);
    //     //{quantity: "648", sales_price: "2.85", date: "2019-10-11", account_name: "DD'S:DDS - ASHLEY"}
    //     if (!!sales_order.hold) {
    //
    //       sales_order_dates.push(sales_order.date)
    //
    //     }
    //   }
    // }

    let sales_orders_a = getSalesOrdersFiltered(a.style_number, a.id)
    let hold_dates_a = []
    if (!!sales_orders_a) {
      for (var i=0; i<sales_orders_a.length; i++) {
        var sales_order = sales_orders_a[i];
        if (sales_order.hold) {
          hold_dates_a.push(sales_order.date);
        }
      }
    }


    var hold_date_a = hold_dates_a.sort()[0];

    let sales_orders_b = getSalesOrdersFiltered(b.style_number, b.id)
    let hold_dates_b = []
    if (!!sales_orders_b) {
      for (var j=0; j<sales_orders_b.length; j++) {
        var sales_order_b = sales_orders_b[j];
        if (sales_order_b.hold) {
          hold_dates_b.push(sales_order_b.date);
        }
      }
    }


    var hold_date_b = hold_dates_b.sort()[0];


    if (hold_date_a > hold_date_b) return 1;
    if (hold_date_b > hold_date_a) return -1;

    return 0;
  }

const compareMultiSearchIndex = (a, b) => {
    if (window.multiSearchIndex == null) {return}

    var index_a = window.multiSearchIndex[a.id];
    var index_b = window.multiSearchIndex[b.id];

    if (index_a > index_b) return 1;
    if (index_b > index_a) return -1;
  }

const compareFutureAtsMonths = (a, b) => {

    var dateText_a = '';
    if (a.earliest_delivery_date != null) {
      dateText_a = a.earliest_delivery_date.split('T')[0];
    }

    if (a.loading_date != null) {
      dateText_a = a.loading_date;
    }

    var index_a =  "" + a.category + dateText_a;


    var dateText_b = '';
    if (b.earliest_delivery_date != null) {
      dateText_b = b.earliest_delivery_date.split('T')[0];
    }

    if (b.loading_date != null) {
      dateText_b = b.loading_date;
    }

    var index_b =  "" + b.category + dateText_b;

    if (index_a > index_b) return 1;
    if (index_b > index_a) return -1;
  }

const compareFutureAts = (a, b) => {
    var dateText_a = '';
    if (a.earliest_delivery_date != null) {
      dateText_a = a.earliest_delivery_date.split('T')[0];
    }

    if (a.loading_date != null) {
      dateText_a = a.loading_date;
    }


    var dateText_b = '';
    if (b.earliest_delivery_date != null) {
      dateText_b = b.earliest_delivery_date.split('T')[0];
    }

    if (b.loading_date != null) {
      dateText_b = b.loading_date;
    }


    if (dateText_a > dateText_b) return 1;
    if (dateText_b > dateText_a) return -1;
  }

const getFutureAtsPendingPIDate = (cad) => {
    let poDetail = cad.po_detail

    let newPoDetail = []

    if (poDetail != null) {
      let poDetailRows = poDetail.split(',')

      for(var k=0;k<poDetailRows.length;k++) {
        let parts = poDetailRows[k].split('—')
        let part2 = parts[1]
        let part5 = parts[4]

        let date = null

        if (part2.trim() != '') {
          let etaParts = part2.trim().split('/')
          date = new Date(etaParts[2], etaParts[0]-1, etaParts[1])
          date.setDate(date.getDate() + ADD_DAYS_TO_LOADING_DATE)
        }

        if (date == null && part5.trim() != '') {
          let dateParts = part5.trim().split('/')
          date = new Date(dateParts[2], dateParts[0]-1, dateParts[1]);
          date.setDate(date.getDate() + ADD_DAYS_TO_EX_FACTORY_DATE)
        }

        newPoDetail.push(date)
      }
    }

    newPoDetail.sort(function (a,b) {
      if (a > b) return 1
      if (b > a) return -1
    })

    let result = newPoDetail[0]

    if (result == null) {
      result = new Date(2000, 0, 1)
    }

    return result
  }

const compareFutureAtsPendingPI = (a, b) => {
    let date_a = this.getFutureAtsPendingPIDate(a)
    let date_b = this.getFutureAtsPendingPIDate(b)

    if (date_a > date_b) return 1
    if (date_b > date_a) return -1
  }

const compareBestSellerDates = (a, b) => {
    var bestSellerDates_a = window.salesAccountBestSellersDates[a.id][0];
    var bestSellerDates_b = window.salesAccountBestSellersDates[b.id][0];

    if (bestSellerDates_a > bestSellerDates_b) return -1;
    if (bestSellerDates_b > bestSellerDates_a) return 1;

    return 0;
  }

const comparePoorSellerDates = (a, b) => {
    var poorSellerDates_a = window.salesAccountPoorSellersDates[a.id][0];
    var poorSellerDates_b = window.salesAccountPoorSellersDates[b.id][0];

    if (poorSellerDates_a > poorSellerDates_b) return -1;
    if (poorSellerDates_b > poorSellerDates_a) return 1;

    return 0;
  }

const compareId = (a, b) => {
    if (a.id > b.id) return -1;
    if (b.id > a.id) return 1;

    return 0;
  }

  export default compareMultiSearchIndex
