import React, {Component} from 'react'
import {Dimmer, Loader, Table, Dropdown, Button, Icon, Input} from 'semantic-ui-react'
import $ from 'jquery'
import './ContactsPage.css'
import {statuses} from './components/contacts/options'
import {getContacts} from './components/contacts/get'
import {updateStatus} from './components/contacts/update_status'
import {alerts} from './components/contacts/alerts'
import {modal} from './components/contacts/modal'
import {saveNote} from './components/contacts/save_note'
import {filters} from './components/contacts/filters'
import {filter, isDefaultStatus} from './components/contacts/filter'
import {visibleFieldsModal} from './components/contacts/visible-fields-modal'
import {table} from './components/contacts/table'
import {contactSort} from './components/contacts/sort'
import {sortMapNameOptions, sortMapIdNameOptions} from './components/purchase-orders/sort-map-id-name-options.js'
import {Auth0Context} from './contexts/auth-context'
import {quickFixAllBoysAllGirls} from './reducers/quickFixAllBoysAllGirls'

class ContactsPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
      filteredContacts: [],
      selectedIds: {},
      showStatusAlert: false,
      showNoteAlert: false,
      showFilters: false,
      open: false,
      filenames: [],
      selectedId: null,
      status: ['Pending', 'Active'],
      title: [],
      distributionChannel: [],
      region: [],
      division: [],
      category: [],
      searchString: '',
      openVisibleFields: false,
      visibleFields: ['NAME', 'TITLE', 'PHONE', 'COMPANY', 'EMAIL', 'STATUS', 'CONTACT ID', 'LATEST ACTIVITY'],
      column: 'LATEST ACTIVITY',
      direction: 'ascending',
      repOptions: [],
      rep: [],
      divisionOptions: [],
      categoryOptions: []
    }

    this.selectAll = this.selectAll.bind(this)
    this.selectRow = this.selectRow.bind(this)
    this.statusOnChange = this.statusOnChange.bind(this)
    this.getContacts = this.getContacts.bind(this)
    this.selectNone = this.selectNone.bind(this)
    this.dismissAlerts = this.dismissAlerts.bind(this)
    this.singleAddNote = this.singleAddNote.bind(this)
    this.bulkAddNote = this.bulkAddNote.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.saveNote = this.saveNote.bind(this)
    this.addFilename = this.addFilename.bind(this)
    this.removeFilename = this.removeFilename.bind(this)
    this.editContact = this.editContact.bind(this)
    this.toggleShowFilters = this.toggleShowFilters.bind(this)
    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.searchOnClick = this.searchOnClick.bind(this)
    this.searchOnKeyDown = this.searchOnKeyDown.bind(this)
    this.openVisibleFieldsModal = this.openVisibleFieldsModal.bind(this)
    this.closeVisibleFields = this.closeVisibleFields.bind(this)
    this.toggleCheckbox = this.toggleCheckbox.bind(this)
    this.getVisibleFieldsFromSessionStorage = this.getVisibleFieldsFromSessionStorage.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.setOptions = this.setOptions.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getContacts()
    this.getVisibleFieldsFromSessionStorage()
    this.getSortingFieldsFromSessionStorage()
    this.setOptions()
  }

  setOptions() {
    let {customer} = this.context
    if (customer == null) {
      setTimeout(this.setOptions, 500)
    } else {
      let repOptions = sortMapIdNameOptions(customer.users.filter(u => u.role == 'sales'))
      let divisionOptions = sortMapNameOptions(customer.divisions)
      let categoryOptions = sortMapNameOptions(customer.categories)
      this.setState({repOptions, divisionOptions, categoryOptions})
    }
  }

  getVisibleFieldsFromSessionStorage() {
    let visibleFields = JSON.parse(window.sessionStorage.getItem('contactsVisibleFields'))
    if (visibleFields != null) {
      this.setState({visibleFields})
    }
  }

  getSortingFieldsFromSessionStorage() {
    let sortingFields = JSON.parse(window.sessionStorage.getItem('contactsSortingFields'))
    if (sortingFields != null) {
      this.setState(sortingFields)
    }
  }

  getContacts() {
    getContacts((contacts) => {
      this.setState({contacts})
      this.filterContacts()
    })
  }

  selectAll() {
    let {contacts, selectedIds} = this.state
    let allSelected = contacts.every(contact => selectedIds[contact.id])
    if (allSelected) {
      selectedIds = {}
    } else {
      contacts.forEach(contact => selectedIds[contact.id] = true)
    }
    this.setState({selectedIds})
  }

  selectNone() {
    this.setState({selectedIds: {}})
  }

  selectRow(contact) {
    let {selectedIds} = this.state
    if (selectedIds[contact.id]) {
      delete selectedIds[contact.id]
    } else {
      selectedIds[contact.id] = true
    }
    this.setState({selectedIds})
  }

  statusOnChange({currentTarget}) {
    let {selectedIds} = this.state
    updateStatus(currentTarget.value, Object.keys(selectedIds), () => {
      this.selectNone()
      this.getContacts()
      this.setState({showStatusAlert: true})
    })
  }

  dismissAlerts() {
    this.setState({showStatusAlert: false, showNoteAlert: false})
  }

  singleAddNote(contact) {
    this.setState({open: true, filenames: [], selectedId: contact.id})
  }

  bulkAddNote() {
    let {contacts, selectedIds} = this.state
    let anySelected = contacts.some(contact => selectedIds[contact.id])
    if (anySelected) {
      this.setState({open: true, filenames: [], selectedId: null})
    }
  }

  closeModal() {
    this.setState({open: false})
  }

  saveNote() {
    let {selectedIds, filenames, selectedId} = this.state
    let text = $('#addContactNote').val().trim()
    if (text != '') {
      let filename = filenames.join('@@@')
      saveNote((selectedId == null ? selectedIds : [selectedId]), text, filename, () => {
        this.closeModal()
        this.getContacts()
        this.setState({showNoteAlert: true})
      })
    }
  }

  addFilename(filename) {
    let {filenames} = this.state
    filenames.push(filename)
    this.setState({filenames})
  }

  removeFilename(filename) {
    let {filenames} = this.state
    filenames = filenames.filter(x => x != filename)
    this.setState({filenames})
  }

  editContact(contact) {
    window.open('/#/contacts2/' + contact.id + '/edit')
  }

  toggleShowFilters() {
    this.setState({showFilters: !this.state.showFilters})
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}
    if (name == 'division') {
      value = quickFixAllBoysAllGirls(value)
    }
    newState[name] = value
    this.setState(newState)
    setTimeout(() => this.filterContacts(), 0)
  }

  filterContacts() {
    let {contacts, status, title, distributionChannel, region, division, category, searchString, rep} = this.state
    let filteredContacts = []
    if (this.usingFilters()) {
      filteredContacts = filter(contacts, status, title, distributionChannel, region, division, category, searchString, rep)
    }
    this.setState({filteredContacts})
  }

  usingFilters() {
    let {status, title, distributionChannel, region, division, category, searchString, rep} = this.state
    return status.length > 0 || title.length > 0 || distributionChannel.length > 0 || region.length > 0 || division.length > 0 || category.length > 0 || searchString.length > 2 || rep.length > 0
  }

  showClearFilters() {
    let {status, title, distributionChannel, region, division, category, searchString, rep} = this.state
    return !isDefaultStatus(status) || title.length > 0 || distributionChannel.length > 0 || region.length > 0 || division.length > 0 || category.length > 0 || searchString.length > 2 || rep.length > 0
  }

  searchOnClick() {
    let searchString = $('#contactsSearchInput').val().trim()
    this.setState({searchString})
    setTimeout(() => this.filterContacts(), 0)
  }

  searchOnKeyDown(event) {
    if (event.key === 'Enter') {
      this.searchOnClick()
    }
  }

  openVisibleFieldsModal() {
    this.setState({openVisibleFields: true})
  }

  closeVisibleFields() {
    this.setState({openVisibleFields: false})
  }

  toggleCheckbox(label) {
    let {visibleFields} = this.state

    if (visibleFields.includes(label)) {
      visibleFields = visibleFields.filter( el => el != label)
    } else {
      visibleFields.push(label)
    }

    this.setState({visibleFields})
    window.sessionStorage.setItem('contactsVisibleFields', JSON.stringify(visibleFields))
  }

  handleSort(field) {
    let {direction, column} = this.state
    let newState
    let sameColumn = column == field

    if (sameColumn) {
      newState = {column: field, direction: direction === 'ascending' ? 'descending' : 'ascending'}
    } else {
      newState = {column: field, direction: 'ascending'}
    }

    this.setState(newState)
    window.sessionStorage.setItem('contactsSortingFields', JSON.stringify(newState))
  }

  render() {
    let {contacts, filteredContacts, selectedIds, showStatusAlert, showNoteAlert, showFilters, open, filenames, status, title, distributionChannel, region, division, category, openVisibleFields, visibleFields, column, direction, repOptions, rep, divisionOptions, categoryOptions} = this.state
    let anySelected = contacts.some(contact => selectedIds[contact.id])
    let allSelected = contacts.every(contact => selectedIds[contact.id])
    let results = this.usingFilters() ? filteredContacts : contacts

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>

        {alerts(this.dismissAlerts, showStatusAlert, showNoteAlert)}
        {modal(open, this.closeModal, this.saveNote, filenames, this.addFilename, this.removeFilename)}

        <Button icon basic id="bulkAddNote" onClick={this.bulkAddNote} className={!anySelected ? 'myDisabled' : '' }>
          <Icon name='sticky note outline' />
        </Button>

        <select id="contactStatusSelect" value="" disabled={!anySelected} onChange={this.statusOnChange} aria-label="Change Status">
            <option>Change Status</option>
            {statuses.map((status, index) =>
              <option value={status} key={index}>{status}</option>
            )}
        </select>

        {showFilters ?
          <Button icon primary id="showFilters" onClick={this.toggleShowFilters}><Icon name='filter' /></Button>
          :
          <Button icon basic id="showFilters" onClick={this.toggleShowFilters}><Icon name='filter' /></Button>
        }

        {this.showClearFilters() ?
          <a onClick={() => window.location.reload()} style={{textDecoration: 'none', float: 'right', margin: '7px 10px', cursor: 'pointer'}}>clear filters</a>
        : null}

        <div id="contactsSearch">
          <Input placeholder='Search...' action>
            <input id="contactsSearchInput" onKeyDown={this.searchOnKeyDown} />
            <Button onClick={this.searchOnClick}>Search</Button>
          </Input>
        </div>

        <h1 style={{marginBottom: '35px', marginTop: '-10px'}}>Contacts</h1>

        {filters(showFilters, this.fieldOnChange, status, title, distributionChannel, region, division, category, repOptions, rep, divisionOptions, categoryOptions)}

        <Button icon size='mini' onClick={this.openVisibleFieldsModal} style={{background: 'white', float: 'right', fontSize: '20px', padding: '3px 10px'}}>
          <Icon name='eye' />
        </Button>
        {visibleFieldsModal(openVisibleFields, this.closeVisibleFields, this.toggleCheckbox, visibleFields)}

        {table(contactSort(results, column, direction), visibleFields, allSelected, this.selectAll, selectedIds, this.selectRow, this.editContact, this.singleAddNote, column, direction, this.handleSort)}

        {results.length == 0 ?
          <center className="no-notes">No contacts found</center>
        : null}
      </div>
    )
  }
}

export default ContactsPage
