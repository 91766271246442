import React from 'react'
import {Table} from 'semantic-ui-react'
import Utils from './../../../modules/utils'

const getEarliestExFactory = (po) => {
  return (
    po.exFactoryDate == null || po.exFactoryDate.length == 0
    ?
    null
    :
    [...po.exFactoryDate].sort()[0]
  )
}

const getExFactoryDisplayCell = (po, i, showDetails) => {
  let style = (showDetails ? {whiteSpace: 'nowrap', verticalAlign: 'top'} : {whiteSpace: 'nowrap'})

  return (
    <Table.Cell key={`ex_factory-${i}`} style={style}>
      {
        po.exFactoryDate == null || po.exFactoryDate.length == 0
        ?
        '—'
        :
        po.exFactoryDate.map((row, j) => {
          let content = (row == null ? '—' : Utils.formatSimpleDate(row))
          return <div key={`ex_factory-${i}-${j}`}>{content}</div>
        })
      }
    </Table.Cell>
  )
}

const getVponumber = (po) => {
  return (
    po.vendorPonumber == null || po.vendorPonumber.length == 0
    ?
    null
    :
    [...po.vendorPonumber].sort()[0]
  )
}

const getVponumberDisplayCell = (po, i, showDetails) => {
  let style = (showDetails ? {whiteSpace: 'nowrap', verticalAlign: 'top'} : {whiteSpace: 'nowrap'})

  return (
    <Table.Cell key={`vponumber-${i}`} style={style}>
      {
        po.vendorPonumber == null || po.vendorPonumber.length == 0
        ?
        '—'
        :
        po.vendorPonumber.map((row, j) => {
          let content = (row == null ? '—' : row)
          return <div key={`vponumber-${i}-${j}`}>{content}</div>
        })
      }
    </Table.Cell>
  )
}

export {getEarliestExFactory, getExFactoryDisplayCell, getVponumber, getVponumberDisplayCell}
