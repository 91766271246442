import React, { Component } from 'react'
import { Message } from 'semantic-ui-react'
import Utils from './../../modules/utils'

class QbSyncMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let {qbSynced, qbSyncedDate} = this.props

    if (qbSynced) {
      return (
        <Message
          style={{maxWidth: '1200px', margin: 'auto', marginTop: '40px'}}
          icon='cloud'
          header={<h2>This hold was synced with Quickbooks on {Utils.formatSimpleDate(qbSyncedDate)}</h2>}
          color='yellow'
        />
      )
    } else {
      return null
    }
  }
}

export default QbSyncMessage
