import React, {Component} from 'react'
import {Icon, Popup} from 'semantic-ui-react'
import {displayDateText} from '../cads/container-number/display-date-text.js'

class FutureAts extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {poDetail, newPoDetail, cadFutureAts, dateText} = this.props

    if (poDetail == null) {
      return (
        <div className="ui grid" style={{marginTop: '-20px', marginBottom: '-10px', fontSize: '15px', color: 'black', fontWeight: 'bold'}}>
          <div className="eight wide column desktopNoWrapWhiteSpace">
            <span>
              <Icon name='truck' />
              {cadFutureAts} Future ATS
            </span>
          </div>
          <div className="eight wide column desktopNoWrapWhiteSpace" style={{textAlign: 'right'}}>
            {dateText}
          </div>
        </div>
      )
    } else {
      return (
        <div style={{marginBottom: '10px'}}>
          {newPoDetail.map((poDetailLine, k) => {
            let part1 = poDetailLine[0]
            let part2 = poDetailLine[1]
            if (part2.trim() == '') {
              part2 = poDetailLine[2]
            }

            return (
              <div key={k} className="ui grid" style={{marginTop: '-20px', marginBottom: '-20px', fontSize: '15px', color: 'black', fontWeight: 'bold'}}>
                <div className="eight wide column desktopNoWrapWhiteSpace">
                  <span>
                    <Icon name='truck' />
                    {part1} Future ATS
                  </span>
                </div>
                <div className="eight wide column desktopNoWrapWhiteSpace" style={{textAlign: 'right'}}>
                  {part2}
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }
}

export default FutureAts
