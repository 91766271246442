import React from 'react'
import {Table, Dropdown} from 'semantic-ui-react'
import {displayPhone} from './display_phone'
import {labels} from './options'

const table = (results, visibleFields, allSelected, selectAll, selectedIds, selectRow, editContact, singleAddNote, column, direction, handleSort) => {
  return (
    <Table basic='very' sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>
            {results.length > 1 ?
              <input className="contact-checkbox" id="selectAllCheckbox" type="checkbox" checked={allSelected} onClick={selectAll}/>
            : null}
          </Table.HeaderCell>
          {
            labels.map((field, index) => {
              return (
                visibleFields.includes(field)
                ?
                <Table.HeaderCell
                  key={index}
                  sorted={column === field ? direction : null}
                  onClick={handleSort.bind(null, field)}
                >
                  {field}
                </Table.HeaderCell>
                :
                null
              )
            })
          }
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {results.map((contact, index) =>
          <Table.Row key={index}>
            <Table.Cell>{index+1}</Table.Cell>
            <Table.Cell><input className="contact-checkbox" type="checkbox" onClick={selectRow.bind(null, contact)} checked={!!selectedIds[contact.id]} /></Table.Cell>
            <Table.Cell>{contact.name}</Table.Cell>
            {visibleFields.includes('TITLE') ? <Table.Cell>{contact.title}</Table.Cell> : null}
            {visibleFields.includes('PHONE') ? <Table.Cell style={{whiteSpace: 'nowrap'}}>{displayPhone(contact)}</Table.Cell> : null}
            {visibleFields.includes('COMPANY') ? <Table.Cell>{contact.company}</Table.Cell> : null}
            {visibleFields.includes('EMAIL') ? <Table.Cell><a href={'mailto: '+contact.email}>{contact.email}</a></Table.Cell> : null}
            {visibleFields.includes('STATUS') ? <Table.Cell>{contact.status}</Table.Cell> : null}
            {visibleFields.includes('CONTACT ID') ? <Table.Cell>{contact.contactId}</Table.Cell> : null}
            {visibleFields.includes('LATEST ACTIVITY') ? <Table.Cell>{contact.latestActivityStr}</Table.Cell> : null}

            {visibleFields.includes('REP') ? <Table.Cell>{contact.reps}</Table.Cell> : null}

            {visibleFields.includes('DISTRIBUTION CHANNEL') ? <Table.Cell>{contact.distributionChannel}</Table.Cell> : null}
            {visibleFields.includes('REGION') ? <Table.Cell>{contact.region}</Table.Cell> : null}

            {visibleFields.includes('DIVISION') ? <Table.Cell>{contact.division.join(', ')}</Table.Cell> : null}
            {visibleFields.includes('CATEGORY') ? <Table.Cell>{contact.category.join(', ')}</Table.Cell> : null}

            <Table.Cell>
              <Dropdown direction='left'>
                <Dropdown.Menu>
                  <Dropdown.Item text='Edit' onClick={editContact.bind(null, contact)}/>
                  <Dropdown.Item text='Add note' onClick={singleAddNote.bind(null, contact)}/>
                  <Dropdown.Item text='View change log' />
                </Dropdown.Menu>
              </Dropdown>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

export {table}
