import React from 'react'

const warningStyle = {display: 'inline-block', marginLeft: '20px', fontWeight: 'bold', color: 'red', background: 'yellow', padding: '5px 10px'}

const inputMessage = (value, options, category, division, packs, prefix) => {
  let option = options.find(x => x.value == value)
  if (option == null) return null

  let {category_char, category_char_not, division_char, division_char_not, packs_char, packs_char_not, prefix_char, prefix_char_not} = option

  if (category_char == null && category_char_not == null && division_char == null && division_char_not == null && prefix_char == null && prefix_char_not == null) return null

  return (
    <div style={warningStyle}>
      <div>
        {
          category_char == null
          ?
          null
          :
          "Category MUST be: " + category_char.sort().join(', ') + ' ' + (category_char.includes(category) ? '✓' : '✖')
        }
        {
          category_char_not == null
          ?
          null
          :
          "Category MUST NOT be: " + category_char_not.sort().join(', ') + ' ' + (!category_char_not.includes(category) ? '✓' : '✖')
        }
      </div>
      <div>
        {
          division_char == null
          ?
          null
          :
          "Division MUST be: " + division_char.sort().join(', ') + ' ' + (division_char.includes(division) ? '✓' : '✖')
        }
        {
          division_char_not == null
          ?
          null
          :
          "Division MUST NOT be: " + division_char_not.sort().join(', ') + ' ' + (!division_char_not.includes(division) ? '✓' : '✖')
        }
      </div>
      <div>
        {
          packs_char == null
          ?
          null
          :
          "Packs MUST be: " + packs_char.sort().join(', ') + ' ' + (packs_char.includes(packs) ? '✓' : '✖')
        }
        {
          packs_char_not == null
          ?
          null
          :
          "Packs MUST NOT be: " + packs_char_not.sort().join(', ') + ' ' + (!packs_char_not.includes(packs) ? '✓' : '✖')
        }
      </div>
      <div>
        {
          prefix_char == null
          ?
          null
          :
          "Prefix MUST be: " + prefix_char.sort().join(', ') + ' ' + (prefix_char.includes(prefix) ? '✓' : '✖')
        }
        {
          prefix_char_not == null
          ?
          null
          :
          "Prefix MUST NOT be: " + prefix_char_not.sort().join(', ') + ' ' + (!prefix_char_not.includes(prefix) ? '✓' : '✖')
        }
      </div>
    </div>
  )
}

export {inputMessage}
