import React, {Component} from 'react'
import {Popup, Icon, Table, Label} from 'semantic-ui-react'
import $ from 'jquery'
import {ADD_DAYS_TO_LOADING_DATE, ADD_DAYS_TO_EX_FACTORY_DATE} from '../../modules/constants'
import {formatDate} from './format-date.js'

class ProductionDataPopup extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      prodDataCount: 0,
    }

    this.updateProductionData = this.updateProductionData.bind(this)
  }

  updateProductionData(cad_id) {
    this.setState({prodDataCount: 0})
    $.get(process.env.REACT_APP_API_URL + '/api/production_data?cad_id=' + cad_id, function (result) {

      let data = result.data;
      if (data != 'TESTING_ENV') {
        $('#production-info-'+cad_id).empty();

        let vpoDates = [];

        for (let i=0;i<data.length;i++) {
          let exFactory = data[i].ex_factory
          let delivDate = null
          let loading_date = data[i].loading_date;


          let date = null


          let factory_id = data[i].factory_id
          let pending_pi = false
          if (factory_id != null) {
            pending_pi = data[i].factory_id.toUpperCase().indexOf('PENDING PI') > -1
          }
          if (factory_id == null || factory_id == 'null') {
            factory_id = '—'
          }


          let fob_rts = data[i].fob_rts

          if (loading_date != null) {
            //if have a loading date, need to set date to loading +40 and put ETA:

            let etaParts = loading_date.trim().split('/');

            date = new Date(etaParts[2], etaParts[0]-1, etaParts[1]);

            date.setDate(date.getDate() + ADD_DAYS_TO_LOADING_DATE); // Set now + 60 days as the new date

            delivDate = "ETA: " + (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear();
          } else {

            if (exFactory != null) {
              let dateParts = exFactory.trim().split('/');

              date = new Date(dateParts[2], dateParts[0]-1, dateParts[1]);

              date.setDate(date.getDate() + ADD_DAYS_TO_EX_FACTORY_DATE); // Set now + 30 days as the new date

              delivDate = "" + (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear();
            }
          }

          let qty = data[i].qty;

          let containerNumber = data[i].container_number
          if (containerNumber == null || containerNumber == 'null') {
            containerNumber = '—'
          }


          if (exFactory == null || exFactory == 'null') {
            exFactory = '—'
          } else {
            exFactory = formatDate(exFactory)
          }

          let {vponumber, status} = data[i]

          vpoDates.push([qty, delivDate, date, pending_pi, fob_rts, factory_id, containerNumber, exFactory, vponumber, status])
        }

        vpoDates.sort(function (a,b) {
          let date_a = a[2]
          let date_b = b[2]

          var d = new Date(2022,0,1)

          if (date_a == null) {date_a = d}
          if (date_b == null) {date_b = d}

          if (date_a > date_b) return 1
          if (date_b > date_a) return -1
        });

        for (let i=0;i<vpoDates.length;i++) {
          let afterDateContent = ''
          if (vpoDates[i][3]) {
            afterDateContent = '&nbsp;&nbsp;(Pending PI)'
          }

          let htmlToAppend = "<tr>"
          htmlToAppend += "<td>" + vpoDates[i][0] + "</td>"
          htmlToAppend += "<td>" + vpoDates[i][1] + afterDateContent + "</td>"
          htmlToAppend += "<td>" + vpoDates[i][5] + "</td>"
          htmlToAppend += "<td>" + vpoDates[i][6] + "</td>"
          htmlToAppend += "<td>" + vpoDates[i][7] + "</td>"
          htmlToAppend += "<td>" + vpoDates[i][8] + "</td>"

          //stay status ready to ship if has fob rts value
          if (vpoDates[i][4]) {
            htmlToAppend += "<td>Ready-To-Ship</td>"
          } else {
            htmlToAppend += "<td>" + vpoDates[i][9] + "</td>"
          }

          htmlToAppend += "</tr>"

          $('#production-info-'+cad_id).append(htmlToAppend)
        }
      }

      this.setState({prodDataCount: data.length})
    }.bind(this))
  }

  render() {
    let {cad} = this.props
    let {prodDataCount} = this.state

    return (
      <Popup style={{ minWidth: '700px', height: (( 100 + (cad.vpo_total) * 40 )+'px')}}
        onMount={this.updateProductionData.bind(null, cad.id)}
        offset={`0px ${prodDataCount*0.1}rem`}
        trigger={
          <Label style={{marginTop: '5px'}} >
            <Icon name='factory' /> {cad.vpo_total} VPO{cad.vpo_total > 1 ? 's' : ''}
          </Label>
        }
      >
        <Popup.Header>Production</Popup.Header>
        <Popup.Content>
        <Table compact style={{whiteSpace: 'nowrap', fontSize: '10px'}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>QTY</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>FACTORY</Table.HeaderCell>
                <Table.HeaderCell>CONTAINER #</Table.HeaderCell>
                <Table.HeaderCell>EX-FACTORY</Table.HeaderCell>
                <Table.HeaderCell>VENDOR PO#</Table.HeaderCell>
                <Table.HeaderCell>STATUS</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body id={"production-info-" + cad.id}>

            </Table.Body>
          </Table>
        </Popup.Content>
      </Popup>
    )
  }
}

export default ProductionDataPopup
