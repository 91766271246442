import React, {Component} from 'react'
import {Popup, Icon, Label} from 'semantic-ui-react'
import {getContainerNumbersFromPoDetail} from './get-container-numbers-from-po-detail'

class ContainerNumberLabel extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {cad} = this.props

    if (this.props.cadReducer.showContainerNumber && cad.po_detail != null && cad.po_detail != 'null') {
      let containerNumbers = getContainerNumbersFromPoDetail(cad)
      let hasUpdatedDates = containerNumbers.some(containerNumber => window.container_number_data[containerNumber] != null)

      return (
        <Popup content='Container #' trigger={
          <Label style={{marginTop: '5px'}} >
            <Icon name='ship' /> {containerNumbers.length == 0 ? 'NO CONT #' : ''}
            {
              hasUpdatedDates
              ?
              <table border="0">
                <tr>
                  <th>Container #</th>
                  <th>Updated ETA</th>
                  <th>Updated Deliv Date</th>
                </tr>
                {containerNumbers.map((containerNumber) => {
                  let data = window.container_number_data[containerNumber]

                  if (data == null) {
                    return (
                      <tr>
                        <td>{containerNumber}</td>
                        <td style={{textAlign: 'center'}}>—</td>
                        <td style={{textAlign: 'center'}}>—</td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr>
                        <td>{containerNumber}</td>
                        <td style={{textAlign: 'center'}}>{data.updated_eta == null ? '—' : data.updated_eta}</td>
                        <td style={{textAlign: 'center'}}>{data.updated_deliv_date == null ? '—' : data.updated_deliv_date}</td>
                      </tr>
                    )
                  }
                })}


              </table>
              :
              containerNumbers.join(', ')
            }
          </Label>
        } />
      )
    } else {
      return null
    }
  }
}

export default ContainerNumberLabel
