import React, {Component} from 'react'
import {Dropdown} from 'semantic-ui-react'

class ArtistDropdown extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {isAuthenticated, user, artistMenu, setArtistMenu, Nav} = this.props

    if (isAuthenticated && user) {
      return (
        <span
          onMouseOver={(e) => setArtistMenu(true)}
          onMouseOut={(e) => setArtistMenu(false)}
          style={{marginLeft: '100px'}}
        >
          <Dropdown
            item
            as={Nav}
            open={artistMenu}
            icon=''
            to="/artists"
            name="artists"
            text="Artists"
            style={{background: 'transparent'}}
          >
            <Dropdown.Menu>
            <Dropdown.Item
              as={Nav}
              to="/artists/new"
              content="New artist"
              onClick={(e) => setArtistMenu(false)}
            />
            </Dropdown.Menu>
          </Dropdown>
        </span>
      )
    } else {
      return null
    }
  }
}

export default ArtistDropdown
