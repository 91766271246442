import React, {Component} from 'react'
import {Table} from 'semantic-ui-react'

const dontSort = ['IMAGE']

class TableHeader extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {labels, visibleFields, column, direction, handleSort} = this.props
    return (
      <Table.Header fullWidth>
        {
          labels.map((field, index) => {
            if (dontSort.includes(field)) {
              return (
                <Table.HeaderCell style={{pointerEvents: 'none'}}
                  key={index}
                >
                  {field}
                </Table.HeaderCell>
              )
            } else {
              return (
                <Table.HeaderCell
                  key={index}
                  sorted={column === field ? direction : null}
                  onClick={handleSort.bind(null, field)}
                >
                  {field}
                </Table.HeaderCell>
              )
            }


          })
        }
      </Table.Header>
    )
  }
}

export default TableHeader
