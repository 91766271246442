import React, {Component} from "react"
import {Table, Pagination}from 'semantic-ui-react'
import {sortBy}           from 'lodash'
import sortRows from './sort-rows.js'
import './simple-table-style.css'

export default class SimpleTable extends Component
{
  constructor(props)
  {
    super(props)

    this.state =
    {
      column    : null,
      direction : null,
      activePage: 1
    }
  }

  componentDidMount(){
    this._ismounted = true;
    this.handleSort(this.props.currentSortColumn)()
    window.addEventListener('page1event', () => {
      this.setState({activePage: 1})
    })
  }

  componentWillUnmount(){
    this._ismounted = false
  }

  setMyState = (state = this.state, cb) => {
    if (this._ismounted){
      this.setState(state, cb)
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    let { rows } = this.props;
    if (column !== clickedColumn) {
      //rows = sortBy(rows, [`${clickedColumn}.value`])

      this.setMyState({
        column: clickedColumn,
        direction: 'ascending',
      })
      return
    }

    this.setMyState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state.activePage) {
      this.setState({ activePage: activePage })
      setTimeout(this.props.updateSelected, 500)
    }
  }

  render()
  {
    let markup   = null;
    let {column, direction, activePage} = this.state;
    let {headings, rows, pagination} = this.props;

    let {tableFooter} = this.props;
    let totalData = rows.length;

    let pageLimit = (this.props.pageLimit || 5)
    let totalPages = Math.ceil(this.props.rows.length / (this.props.pageLimit || 5))

    let sortedRows = sortRows(rows, column, direction)

    if (pagination)
    {
      let startIndex      = (activePage - 1) * pageLimit
      let limit           = pageLimit * activePage
      rows = rows.slice(startIndex, limit)
    }

    if (this.props.showImage) {
      let rowsWithImageRow = [];
      for (let i=0; i<rows.length; i++) {
        rowsWithImageRow.push(rows[i])
        rowsWithImageRow.push(rows[i])
      }
      rows = rowsWithImageRow;
    }

    markup =
    <div className={"simple-table " + (this.props.wrapperClassName || '')} >
      {
        pagination && totalData > pageLimit ?
        <Pagination
          totalPages={totalPages}
          activePage={activePage}
          onPageChange={this.onChangePage}
          style={{marginBottom: '30px'}}
        /> : null
      }

      <div className="simple-table-holder" style={{paddingBottom: 0}}>
        <Table sortable celled compact definition selectable={this.props.selectable}>
          <Table.Header fullWidth>
            <Table.Row>
            {
              headings.filter(x => x != null).map((heading, i) =>(
                <Table.HeaderCell
                  key={i}
                  sorted={column === heading.key ? direction : null}
                  onClick={heading.sortable ? this.handleSort(heading.key) : null}
                  className={i == 0 ? 'simple-table-first-column' : null}
                >
                  {heading.displayName}
                </Table.HeaderCell>
              ))
            }
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              rows.length > 0 ?
              rows.map((row, i) =>
              (
                this.props.showImage && i % 2
                ?
                (
                  row.image_urls == null || row.image_urls.length == 0
                  ?
                  null
                  :
                  <Table.Row key={`row-${i}`}>
                    <div className="simple-table-image-row">
                      <div>
                        {row.image_urls == null ? null : row.image_urls.map(function(row, j) {
                          return <img key={j} src={row}/>
                        })}
                      </div>
                    </div>
                  </Table.Row>
                )
                :
                <Table.Row key={`row-${i}`} onClick={row.rowOnClick || null} style={{transition: 'all 1s ease', opacity: (row.greyedOut ? 0.3 : 1)}}>
                  {headings.filter(x => x != null).map((heading, index) => {
                    let cell = row[heading.key];
                    return cell.displayCell;
                  })}
                </Table.Row>
              ))
              :
              this.props.noContentMessage ?
              <Table.Row>
                <Table.Cell colSpan={headings.length}>{this.props.noContentMessage || 'No data!'}</Table.Cell>
              </Table.Row>
              : null
            }
          </Table.Body>
        </Table>
      </div>

    </div>
    return markup
  }
}
