import React, {Component} from 'react'
import {Popup, Icon, Label} from 'semantic-ui-react'

class FactoryLabel extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {cad} = this.props

    if (this.props.cadReducer.showFactory && !!window.factory_ids_by_style[cad.style_number]) {
      let factories = window.factory_ids_by_style[cad.style_number]

      return (
        <Popup content='Factory' trigger={
          <Label style={{marginTop: '5px'}} >
            <Icon name='factory' />
            {factories.join(', ')}
          </Label>
        } />
      )
    } else {
      return null
    }
  }
}

export default FactoryLabel
