import React from 'react'
import {Button} from 'semantic-ui-react'
import Utils from "../../modules/utils"

const callApi = () => {
  const url = 'mega_brands_run_stock_report'

  const callbackFunc = (err, result) => {
    if (err) {
      alert('There was an error when trying to run stock report')
    } else {
      if (result.data.success) {
        alert('stock report complete')
      }
    }
  }

  Utils.post(url, {}, callbackFunc)
}

const megaBrandsRunStockReportButton = () => {
  return (
    <Button onClick={callApi}>Run Stock Report</Button>
  )
}

export {megaBrandsRunStockReportButton}
