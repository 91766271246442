import Utils from "../utils"

class CustomerAPI
{
  static create(data, cb)
  {
    Utils.post(`customers.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    })
  }

  static update(id, data, cb)
  {
    Utils.put(`customers/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static get(id, cb)
  {
    Utils.get(`customers/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static getAll(cb)
  {
    Utils.get(`customers.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static delete(id, cb)
  {
    Utils.delete(`customers/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static createCategory(data, cb, customerId)
  {
    Utils.post(`categories.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updateCategory(id, data, cb, customerId)
  {
    Utils.put(`categories/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroyCategory(id, cb, customerId)
  {
    Utils.delete(`categories/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static createSubCategory(data, cb, customerId)
  {
    Utils.post(`subcategories.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updateSubCategory(id, data, cb, customerId)
  {
    Utils.put(`subcategories/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroySubCategory(id, cb, customerId)
  {
    Utils.delete(`subcategories/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static createGrandSubCategory(data, cb, customerId)
  {
    Utils.post(`grand_subcategories.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updateGrandSubCategory(id, data, cb, customerId)
  {
    Utils.put(`grand_subcategories/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroyGrandSubCategory(id, cb, customerId)
  {
    Utils.delete(`grand_subcategories/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static createDivision(data, cb, customerId)
  {
    Utils.post(`divisions.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updateDivision(id, data, cb, customerId)
  {
    Utils.put(`divisions/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroyDivision(id, cb, customerId)
  {
    Utils.delete(`divisions/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static createBrand(data, cb, customerId)
  {
    Utils.post(`brands.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updateBrand(id, data, cb, customerId)
  {
    Utils.put(`brands/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroyBrand(id, cb, customerId)
  {
    Utils.delete(`brands/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static createPack(data, cb, customerId)
  {
    Utils.post(`packs.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updatePack(id, data, cb, customerId)
  {
    Utils.put(`packs/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroyPack(id, cb, customerId)
  {
    Utils.delete(`packs/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static createSeason(data, cb, customerId)
  {
    Utils.post(`seasons.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updateSeason(id, data, cb, customerId)
  {
    Utils.put(`seasons/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroySeason(id, cb, customerId)
  {
    Utils.delete(`seasons/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static createSize(data, cb, customerId)
  {
    Utils.post(`sizes.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updateSize(id, data, cb, customerId)
  {
    Utils.put(`sizes/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroySize(id, cb, customerId)
  {
    Utils.delete(`sizes/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static createColor(data, cb, customerId)
  {
    Utils.post(`colors.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updateColor(id, data, cb, customerId)
  {
    Utils.put(`colors/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroyColor(id, cb, customerId)
  {
    Utils.delete(`colors/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static createFabrication(data, cb, customerId)
  {
    Utils.post(`fabrications.json`, data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    }, customerId)
  }

  static updateFabrication(id, data, cb, customerId)
  {
    Utils.put(`fabrications/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static destroyFabrication(id, cb, customerId)
  {
    Utils.delete(`fabrications/${id}.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    }, customerId)
  }

  static integrateGoogle(data, cb)
  {
    Utils.post('authorize', data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    })
  }

  static getGoogleAccountInfo(cb)
  {
    Utils.get('google_account', (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    })
  }

  static updateBrandApprovals(data, cb)
  {
    Utils.post('customer_brands_approval', data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb (null, res.data)
    })
  }

}

export default CustomerAPI
