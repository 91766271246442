import React, {Component} from 'react'
import PrintCard from './card'
import $ from 'jquery'
import {cadViewer, arrowKeyboardClick} from '../cads/fullscreen-cadviewer'
import {pageLabel} from '../vendor-orders/table/page-label'
import {moreResults} from '../vendor-orders/table/more-results'

class PrintsCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      layout: 'desktop'
    }

    this.resize = this.resize.bind(this)
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
    $(document).unbind( "keyup", arrowKeyboardClick )
    $(document).keyup(arrowKeyboardClick)
  }

  resize() {
    let width = $(window).width()
    let layout = width < 1200 ? 'tablet' : 'desktop'
    this.setState({layout})
  }

  render() {
    let {prints, cadTotals, sliceNum, loadMore, numResults} = this.props
    let {layout} = this.state

    return (
      <React.Fragment>
        {cadViewer()}
        <div className={"ui stackable cards " + (layout == 'desktop' ? 'four' : 'three')} style={{marginTop: '3px'}}>
          {prints.map((print, index) => {
            let printCard = (
              <PrintCard key={index} index={index} print={print} cadTotal={cadTotals[print.id]}
                markPrintAsDeleted={this.props.markPrintAsDeleted} updateNumSelected={this.props.updateNumSelected}
              />
            )
            return pageLabel(index, printCard)
          })}
        </div>
        {moreResults(sliceNum, numResults, loadMore)}
      </React.Fragment>
    )
  }
}

export default PrintsCards
