import React, {Component} from 'react'
import {Dropdown} from 'semantic-ui-react'

class VpoDropdown extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {isAuthenticated, user, vpoMenu, setVpoMenu, Nav} = this.props

    if (isAuthenticated && user && ['admin','production','inventory'].includes(user.role)) {
      return (
        <span
          onMouseOver={(e) => setVpoMenu(true)}
          onMouseOut={(e) => setVpoMenu(false)}
        >
          <Dropdown
            item
            as={Nav}
            open={vpoMenu}
            icon=''
            to="/vendor-orders"
            name="vendor orders"
            text="Vendor orders"
            style={{background: 'transparent'}}
          >
            <Dropdown.Menu>
              {user.role == 'customer' ? null :
                <Dropdown.Item
                  as={Nav}
                  to="/vendor-orders/new"
                  content="New vendor order"
                  onClick={(e) => setVpoMenu(false)}
                />
              }
              {user.role == 'customer' ? null :
                <Dropdown.Item
                  as={Nav}
                  to="/vendor-orders/add-fabrication"
                  content="Add fabrication"
                  onClick={(e) => setVpoMenu(false)}
                />
              }
            </Dropdown.Menu>
          </Dropdown>
        </span>
      )
    } else {
      return null
    }
  }
}

export default VpoDropdown
