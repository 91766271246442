import React, { Component } from 'react'
import $ from 'jquery'
import { Label, TextArea, Input, Loader, Grid, Message, Icon } from 'semantic-ui-react'
import './ExtraOrderSection.css'
import Utils from "../../modules/utils"
import {labelStyle, styleNumberInfo, infoStyle, InfoCircle, textAreaStyle, customerOrderNumberInfo} from "./Elements"

const containerStyle = {
  borderTop: '1px solid rgb(222,222,222)',
  paddingTop: '60px',
  paddingBottom: '60px'
}

class ExtraOrderSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      styleNumbers: [],
      notFound: [],
      noAtsOrFutureAts: [],
      checkingUniqueCustomerPONumber: false,
      customerPONumberIcon: null,
      customerPONumberIconPosition: null,
      customerPONumberErrorMessage: null,
    }

    this.textAreaOnChange = this.textAreaOnChange.bind(this)
    this.customerPONumberOnChange = this.customerPONumberOnChange.bind(this)
    this.checkIfValidCustomerPONumber = this.checkIfValidCustomerPONumber.bind(this)
    this.checkStyleNumbersForErrors = this.checkStyleNumbersForErrors.bind(this)
  }

  componentDidMount() {
    window.addEventListener('checkCustomerPonumberEvent', () => {
      let customerPONumber = $('#customer-order-number-'+this.props.index).val().trimFlattenUpcase()
      if (customerPONumber.length > 2 && this.state.customerPONumberIcon == 'close') {
        this.setState({checkingUniqueCustomerPONumber: true})
        this.checkIfValidCustomerPONumber(customerPONumber, '-'+this.props.index)
      }
    })
  }

  componentDidUpdate() {}

  textAreaOnChange(e, { value }) {
    let timeoutName = 'textAreaTimeout' + this.props.index
    clearTimeout(window[timeoutName])
    window[timeoutName] = setTimeout(() => {
      let styleNumbers = value.split("\n").map(x => x.flatUpcase()).filter(x => x != '')
      styleNumbers = [...new Set(styleNumbers)]
      this.setState({styleNumbers})
      if (styleNumbers.length) {
        this.checkStyleNumbersForErrors(styleNumbers)
      } else {
        this.setState({notFound: [], noAtsOrFutureAts: []})
      }
    }, 1000)
  }

  customerPONumberOnChange = (e, {value}) => {
    this.setState({checkingUniqueCustomerPONumber: true})
    let timeoutName = 'styleOnChangeTimeout' + this.props.index
    clearTimeout(window[timeoutName])
    window[timeoutName] = setTimeout(() => {
      let customerPONumber = value.trimFlattenUpcase()
      if (customerPONumber.length > 2) {
        this.checkIfValidCustomerPONumber(customerPONumber, '-'+this.props.index)
      } else {
        this.setState({
          checkingUniqueCustomerPONumber: false,
          customerPONumberIcon: null,
          customerPONumberIconPosition: null,
          customerPONumberErrorMessage: null
        })
        $('#customer-order-number-'+this.props.index)[0].setCustomValidity("")
      }
    }, 500)
  }

  checkIfValidCustomerPONumber(customerPONumber, index = '') {
    let data = {
      customerOrderNumber: customerPONumber,
      contactId: this.props.quickbooksAccount,
      id: null
    }
    Utils.post('check_if_valid_customer_po_number', data, (err, result) =>
    {
      this.setState({checkingUniqueCustomerPONumber: false})
      if (err) {
        console.log('There was an error when trying to check if customer PO # is unique.')
      } else {
        let newState = {customerPONumberErrorMessage: result.data.errorMessage, customerPONumberIconPosition: 'left'}
        newState["customerPONumberIcon"] = (result.data.valid ? 'check' : 'close')
        this.setState(newState)

        let customValidity = ""
        if (result.data.errorMessage != null) {
          customValidity = result.data.errorMessage
        }
        $('#customer-order-number'+index)[0].setCustomValidity(customValidity)
      }
    })
  }

  checkStyleNumbersForErrors(styleNumbers) {
    Utils.post('check_style_numbers_for_errors', { styleNumbers }, (err, result) =>
    {
      if (err) {
        console.log('There was an error when trying to check style numbers for errors.')
      } else {
        let newState = result.data

        let cads = []
        let cadData = JSON.parse(result.data.cads).data

        for (var i=0; i<cadData.length; i++) {
          cads.push(cadData[i].attributes)
        }

        delete newState.cads

        this.setState(newState)

        cads.forEach(cad => {
          cad.orderIndex = this.props.index
        })

        this.props.addExtraCads(cads, this.props.index)

        setTimeout(() => {
          //this.setInitialQuantityAndDate()

          ///////MIGHT NEED THIS BRAND APPROVAL thing
          //if (!this.state.isEditPage && this.state.quickbooksAccount != null) {
          //  this.setRemoveStyles()
          //}
        },0)
      }
    })
  }

  render() {
    let {styleNumbers, notFound, noAtsOrFutureAts} = this.state
    let {index} = this.props
    return (
      <div style={containerStyle}>
        <div><Label style={{float: 'right'}}>Order #{index+2}</Label></div>
        <div>
          <h3 style={labelStyle}>
            Style numbers {styleNumbers.length > 0 ? '('+styleNumbers.length+')' : ''}
            {InfoCircle(styleNumberInfo)}
          </h3>
          <TextArea id={"style-numbers-textarea-"+index} onChange={this.textAreaOnChange} placeholder="Enter style numbers" style={textAreaStyle} />
          <div style={{marginTop: '50px', fontSize: '120%'}}>
            <Grid columns={2}>
              {notFound != null && notFound.length > 0 ?
                <Grid.Column>
                  <Message icon warning>
                    <Icon name='warning sign'/>
                    <Message.Content>
                      <Message.Header>Cads NOT found</Message.Header>
                      <p>
                        <ul>
                          {notFound.map(function(row, index) {
                            return (<li key={index}>
                              {row}
                            </li>)
                          })}
                        </ul>
                      </p>
                    </Message.Content>
                  </Message>
                </Grid.Column>
              : null}
              {noAtsOrFutureAts != null && noAtsOrFutureAts.length > 0 ?
                <Grid.Column>
                  <Message icon warning>
                    <Icon name='warning sign'/>
                    <Message.Content>
                      <Message.Header>NO ATS or Future ATS</Message.Header>
                      <p>
                        <ul>
                          {noAtsOrFutureAts.map(function(row, index) {
                            return (<li key={index}>
                              {row}
                            </li>)
                          })}
                        </ul>
                      </p>
                    </Message.Content>
                  </Message>
                </Grid.Column>
              : null}
            </Grid>
          </div>
        </div>
        <div style={{paddingTop: '60px'}}>
          <label style={labelStyle}>
            Customer PO #
            {InfoCircle(customerOrderNumberInfo)}
            <span style={{opacity: 0.5, fontStyle: 'italic', marginLeft: '20px'}}>(optional)</span>
          </label>
          <div style={{width: '400px', marginLeft: '78px', display: 'inline-block'}} className="customer-po-number-input-holder">
            <Input id={"customer-order-number-"+index} fluid placeholder='Customer PO #'
              icon={this.state.customerPONumberIcon}
              iconPosition={this.state.customerPONumberIconPosition}
              onChange={this.customerPONumberOnChange}
              form="new-sales-order"
              />
            {this.state.customerPONumberErrorMessage == null ? null :
              <small style={{color: 'red', fontWeight: 'bold', position: 'absolute'}} class="ui pointing above prompt label">{this.state.customerPONumberErrorMessage}</small>
            }
          </div>
          &nbsp;&nbsp;&nbsp;
          {this.state.checkingUniqueCustomerPONumber ? <Loader active inline size='small'/> : null}
        </div>
      </div>
    )
  }
}

export default ExtraOrderSection
