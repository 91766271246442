const cadSearchParams = (myProps) => {
  let options = window.currentUserCustomAccess
  let extra_params = ''

  if (myProps.cadReducer.division.length > 0) {
    extra_params += '&division=' + myProps.cadReducer.division.join(',').replace(/&/g,'%26')
  } else if (options != null && options.division != null) {
    extra_params += '&division=' + options.division.join(',').replace(/&/g,'%26')
  }

  if (myProps.cadReducer.color.length > 0) {
    extra_params += '&color=' + myProps.cadReducer.color.join(',').replace(/&/g,'%26')
  }

  if (myProps.cadReducer.season.length > 0) {
    extra_params += '&season=' + myProps.cadReducer.season.join(',').replace(/&/g,'%26')
  }

  if (myProps.cadReducer.subCategory.length > 0) {
    extra_params += '&subCategory=' + myProps.cadReducer.subCategory.join(',').replace(/&/g,'%26')
  } else if (options != null && options.subCategory != null) {
    extra_params += '&subCategory=' + options.subCategory.join(',').replace(/&/g,'%26')
  }

  if (myProps.cadReducer.sub2.length > 0) {
    extra_params += '&sub2=' + myProps.cadReducer.sub2.join(',').replace(/&/g,'%26')
  }

  if (myProps.cadReducer.brand.length > 0) {
    extra_params += '&brand=' + myProps.cadReducer.brand.join(',').replace(/&/g,'%26')
  } else if (options != null && options.brand != null) {
    extra_params += '&brand=' + options.brand.join(',').replace(/&/g,'%26')
  }

  if (myProps.cadReducer.fabrication.length > 0) {
    extra_params += '&fabrication=' + myProps.cadReducer.fabrication.join(',').replace(/&/g,'%26')
  }

  if (myProps.cadReducer.category.length > 0) {
    extra_params += '&category=' + myProps.cadReducer.category.join(',').replace(/&/g,'%26')
  } else if (options != null && options.category != null) {
    extra_params += '&category=' + options.category.join(',').replace(/&/g,'%26')
  }

  if (myProps.cadReducer.packs.length > 0) {
    extra_params += '&packs=' + myProps.cadReducer.packs.join(',').replace(/&/g,'%26')
  }

  if (myProps.cadReducer.currentPlaylistId != null) {
    extra_params += '&playlist_id=' + myProps.cadReducer.currentPlaylistId
  }

  return extra_params
}

export default cadSearchParams
