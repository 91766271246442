const stages = [
  'Licensee Approval',
  'PI',
  'Confirm VPO',
  'QB-Entered',
  'LD',
  'S/O',
  'FIT',
  'PP',
  'TOP'
]

const statuses = [
  'Pending Approval',
  'Received and Reviewed',
  'Rejected',
  'Approved',
  'Approved with Changes'
]

export {stages, statuses}
