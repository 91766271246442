import React, { Component } from 'react'
import SectionTitle from '../new-vpo-page/section-title'
import {dateLabel} from '../new-vpo-page/cad-details-section/date-label.js'
import {Input, TextArea, Dropdown, Message} from 'semantic-ui-react'
import {buildingTypeOptions, sourceOptions} from '../properties/options'
import ContinueButton from '../new-vpo-page/continue-button.js'
import {editLink} from './edit-link'
import {infoTable} from './info-table'
import AddDocumentButton from './add-document-button'
import {fileList} from '../share-property/file-list'
import ImageCropper from './image-cropper'

const subTitleStyle = {marginTop: '50px', marginBottom: '10px', fontWeight: 'bold'}

const isComplete = (property) => {
  if (property['address'].trim().length < 3) {
    return false
  }

  if (property['description'].trim().length < 3) {
    return false
  }

  if (property['buildingType'] == null) {
    return false
  }

  if (property['source'] == null) {
    return false
  }

  if (['', null].includes(property['buildingSize'])) {
    return false
  }

  if (['', null].includes(property['roomsNumber'])) {
    return false
  }

  if (property['documents'].some(x => x['description'] == '')) {
    return false
  }

  if (property['images'].some(x => x['description'] == '')) {
    return false
  }

  return true
}

class AssetOverview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      filename: null,
      filenames: [],
      showCancel: true
    }

    this.openImageCropper = this.openImageCropper.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.openImageCropperBulk = this.openImageCropperBulk.bind(this)
  }

  openImageCropper(filename) {
    this.setState({open: true, filename, showCancel: true})
  }

  openImageCropperBulk(filenames) {
    let firstElement = filenames.shift()
    this.setState({open: true, filename: firstElement, filenames, showCancel: false})
  }

  closeModal() {
    let {filenames} = this.state
    if (filenames.length > 0) {
      this.setState({open: false, filename: null})
      setTimeout(() => {this.openImageCropperBulk(filenames)},1000)
    } else {
      this.setState({open: false, filename: null})
    }
  }

  render() {
    let {open, filename, showCancel} = this.state
    let {isActive, titleNum, fieldOnChange, property, markAsComplete, editSection, getAssetId, propertyDocuments, documentDescriptionOnChange} = this.props
    let complete = isComplete(property)

    return (
      <div>
        {editLink(editSection, complete, isActive)}
        <SectionTitle num={titleNum} title='ASSET OVERVIEW' complete={complete}/>

        <div style={isActive ? {} : {display: 'none'}}>

          <div style={{width: '66%'}}>
            {dateLabel('Address', {}, property['address'] == null || property['address'] == '')}
            <Input placeholder='Address' fluid onChange={fieldOnChange} name='address' value={property['address']}/>
          </div>

          <div style={{width: '66%', marginTop: '10px'}}>
            {dateLabel('Description', {}, property['description'] == null || property['description'] == '')}
            <TextArea id="newPropertyDescription" placeholder='Enter description...' rows={5} onChange={fieldOnChange} name='description'
              value={property['description']}
            />
          </div>

          <Message positive style={{float: 'right', width: '200px'}}>
            Asset id:
            <Message.Header>{getAssetId()}</Message.Header>
          </Message>

          <div style={{marginTop: '10px', fontWeight: 'bold'}}>
            Classification Fields
          </div>

          <div style={{width: '295px', display: 'inline-block', marginTop: '10px'}}>
            {dateLabel('Type', {}, property['buildingType'] == null )}
            <Dropdown placeholder='Type' fluid selection search options={buildingTypeOptions} onChange={fieldOnChange} name='buildingType'
              value={property['buildingType']}
            />
          </div>

          <div style={{width: '295px', display: 'inline-block', marginLeft: '15px'}}>
            {dateLabel('Source', {}, property['source'] == null )}
            <Dropdown placeholder='Source' fluid selection search options={sourceOptions} onChange={fieldOnChange} name='source'
              value={property['source']}
            />
          </div>
          <br/>
          <div style={{width: '295px', display: 'inline-block', marginTop: '10px'}}>
            {dateLabel('Building Size (sqft)', {}, property['buildingSize'] == null || property['buildingSize'] == '')}
            <Input step="1" min="0" type="number" placeholder='Building Size (sqft)' fluid onChange={fieldOnChange} name='buildingSize' value={property['buildingSize']}/>
          </div>

          <div style={{width: '295px', display: 'inline-block', marginLeft: '15px'}}>
            {dateLabel('Rooms #', {}, property['roomsNumber'] == null || property['roomsNumber'] == '')}
            <Input step="1" min="0" type="number" placeholder='Rooms #' fluid onChange={fieldOnChange} name='roomsNumber' value={property['roomsNumber']}/>
          </div>

          <div style={subTitleStyle}>
            Images <AddDocumentButton property={property} fieldOnChange={fieldOnChange} name='images' openImageCropperBulk={this.openImageCropperBulk}/>
          </div>

          {fileList(property['images'], documentDescriptionOnChange, property, fieldOnChange, this.openImageCropper, 'images')}

          {
            open
            ?
            <ImageCropper open={true} closeModal={this.closeModal} filename={filename} showCancel={showCancel}/>
            :
            null
          }

          <div style={subTitleStyle}>
            Documents <AddDocumentButton property={property} fieldOnChange={fieldOnChange} name='documents'/>
          </div>

          {fileList(property['documents'], documentDescriptionOnChange, property, fieldOnChange, null, 'documents')}

          <div style={{height: '10px'}}/>
          <ContinueButton markAsComplete={markAsComplete} complete={complete} />
        </div>

        <p style={isActive ? {display: 'none'} : {fontSize: '13px', paddingBottom: '1px'}}>
          {infoTable(property)}

          <div style={subTitleStyle}>Images</div>
          {fileList(property['images'], null, null, null, 'images')}

          <div style={subTitleStyle}>Documents</div>
          {fileList(property['documents'])}
        </p>

      </div>
    )
  }
}

export default AssetOverview
