import React            from 'react';
import { Modal, Button }from 'semantic-ui-react';

export const ConfirmAlert = props => (
    <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className={props.className || 'ui-confirmation-modal'}
        size='mini'
        open={true}
        >
        <Modal.Header>{ props.header }</Modal.Header>
        <Modal.Content>
          <p>{ props.confirmationText }</p>
          <div>
            { props.children }
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={props.onReject}
            >
            {props.rejectBtnText || 'No'}
            </Button>
          <Button
            positive
            icon='checkmark'
            labelPosition='right'
            content={props.rejectBtnText || 'Yes'}
            onClick={props.onAccept}
          />
        </Modal.Actions>
      </Modal>
)
