import React, { Component } from 'react'
import {Popup, Button} from 'semantic-ui-react'
import {buttonStyle} from './button-style'
import {shareProperties} from './share'

class SharePropertyButton extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.createLink = this.createLink.bind(this)
  }

  createLink() {
    let {checked} = this.props

    let propertyIds = Object.keys(checked).filter(id => checked[id])

    if (propertyIds.length == 0) {
      alert('Please select at least 1 property!')
      return
    }

    shareProperties(propertyIds, (err, res) => {
      if (err) {
        alert('There was an error when trying to share the properties.')
      } else {
        let url = process.env.REACT_APP_URL + '/#/share-properties?token=' + res.data.token
        window.prompt("Press: Control + C", url)
      }
    })
  }

  render() {
    return (
      <Popup content='Share a link' position='bottom left'
        trigger={
          <Button basic icon='share alternate' onClick={this.createLink} style={buttonStyle}/>
        }
      />
    )
  }
}

export default SharePropertyButton
