import React      from 'react'
import {
          Form,
          Grid,
          Input,
          Button,
          Message,
          Label
       }          from 'semantic-ui-react'
import Constants  from './../../../modules/constants'
import Utils      from './../../../modules/utils'
import UserAPI    from './../../../modules/api/user_api'
import validator  from 'validator'
import {snakeCase} from 'lodash'

class UserForm extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading       : false,
      user          : this.props.user || null,
      companyId     : this.props.companyId || null,
      companyUsers  : this.props.companyUsers || null,
      displayMessage: false,
      error         : null,
      inputs :
      {
        name :
        {
          value   : "",
          valid   : null,
          required: true,
          message : ""
        },
        email :
        {
          value   : "",
          valid   : null,
          required: true,
          message : ""
        },
        role :
        {
          value   : "",
          valid   : null,
          required: true,
          message : ""
        },
        status :
        {
          value   : "active",
          valid   : true,
          required: true,
          message : ""
        },
        salesAdmin :
        {
          value   : "",
          valid   : null,
          required: true,
          message : ""
        }
      }
    }

    this.setInputsFromUser()
  }

  toggleMessageVisibility = () =>
  {
    this.setState({ displayMessage: !this.state.displayMessage })
  }

  setInputsFromUser = () =>
  {
    let {user, inputs} = this.state
    if (!user)
    {
      return;
    }

    Object.keys(inputs).forEach((key) =>
    {
      let input = inputs[key]

      switch (key)
      {
        case "name"  :
        case "email" :
        case "role"  :
        case "status":
        {
          input.valid = true
          input.value = user[key]
          break
        }
        case "salesAdmin":
        {
          input.valid = true
          input.value = user.salesAdminIds
          break
        }
        default :
        {
          break
        }
      }
    })

    this.setState({ inputs })
  }

  handleSubmit = () =>
  {
    this.setState({ loading: true })
    const { loading, inputs, user, companyId } = this.state
    let { data, valid } = this.getData()

    let callback = (error, data) =>
    {
      if (error)
      {
        this.setState({
          loading: false,
          displayMessage: true,
          error: { apiError: true, error: error }
        })
      }
      else
      {
        this.props.closeNewUserForm(null, data)
        this.setState({ loading: false })
      }
    }

    if (companyId) //valid &&
    {
      if (user)
      {
        UserAPI.update(user.id, {user: data}, callback, companyId)
      }
      else
      {
        UserAPI.create({user: data}, callback, companyId)
      }
    }
  }

  getData = () =>
  {
    let { inputs } = this.state
    let data       = {}

    data.name      = inputs.name.value
    data.email     = inputs.email.value
    data.role      = inputs.role.value
    data.status    = inputs.status.value
    data.salesAdminIds = inputs.salesAdmin.value

    return { data, valid: this.isInputsValid() }
  }

  isInputsValid = () =>
  {
    let {inputs} = this.state
    let valid    = Utils.isInputsValidated(inputs)
    return valid
  }

  handleChange = (e, data) =>
  {
    let state  = this.state
    let name, value;
    let inputs = state.inputs

    if (['role', 'salesAdmin'].includes(e.name))
    {
      value = e.value
      name  = e.name
    }
    else
    {
      value = e.target.value
      name  = e.target.name
    }

    let input   = inputs[name]
    input.value = value

    if (input.required && (input.value.length == 0))
    {
      input.message = 'Please enter the value'
    }
    else
    {
      input.message = ''
    }

    switch (name)
    {
      case "name"  :
      case "role"  :
      {
        input.valid = !input.message
        break
      }
      case "email" :
      {
        input.valid   = validator.isEmail(value)
        input.message = input.valid ? "" : "Please enter a valid email address!"
        break
      }
      case "salesAdmin":
      {
        input.valid = true
        break
      }
      default :
      {
        break
      }
    }

    this.setState({ inputs })
  }

  updateUserStatus = () =>
  {
    let inputs  = this.state.inputs
    let input   = inputs.status
    input.value = input.value == 'active' ? 'inactive' : 'active'

    this.setState({ inputs })
    this.handleSubmit()
  }

  render()
  {
    let { inputs, user } = this.state
    let isOldUser        = user && user.id
    let errorMessage     = ''

    if (
      this.state.error &&
      this.state.error.apiError &&
      this.state.error.error
    )
    {
      let errors   = this.state.error.error.response.data
      errorMessage = Object.keys(errors).map((key, i) =>
      {
        return <p key={i}><b>{key}: </b>{errors[key].join(', ')}</p>
      })
    }

    let salesAdminOptions = []
    if (this.state.companyUsers != null) {
      salesAdminOptions = this.state.companyUsers
        .filter((x) => {return ['admin','sales'].indexOf(x.role) > -1})
        .map((x) => {return {key: x.id, text: x.name, value: x.id} })
        .sort(function(a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
    }

    let markup =
      <Form onSubmit={this.handleSubmit} loading={this.state.loading}>
        {
          this.state.displayMessage &&
          <Message
            negative
            size='mini'
            onDismiss={this.toggleMessageVisibility}
          >
            <Message.Header>
              We're sorry some error occured!
            </Message.Header>
            {errorMessage}
          </Message>
        }
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <Form.Field
                required
                control={Input}
                placeholder='Name'
                name='name'
                label='Name'
                value={inputs.name.value}
                onChange={(e) => this.handleChange(e, inputs.name)}
                error={inputs.name.valid == false ? {
                  content: inputs.name.message,
                } : false }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field required>
                <label>Role</label>
                <Form.Select
                  onChange={(e, data) => this.handleChange(data, inputs.role)}
                  name='role'
                  value={inputs.role.value}
                  options={Utils.arrayToOptionsHash(Constants.USER_ROLES, {normalizeValue: snakeCase })}
                  placeholder='Select Role'
                  error={inputs.role.valid == false ? {
                    content: inputs.role.message,
                  } : false }
                  />

                  {inputs.role.value == 'customer' && user != null ?
                    <small style={{color: 'rgb(200, 200, 200)'}}><b>QB account name:</b> &nbsp;&nbsp; {user.quickbooksAccountName}</small>
                    :
                    null
                  }

                  {inputs.role.value == 'warehouse' && user != null ?
                    <small style={{color: 'rgb(200, 200, 200)'}}><b>Warehouse:</b> &nbsp;&nbsp; {user.warehouse}</small>
                    :
                    null
                  }
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          {inputs.role.value == 'sales_assistant' ?
            <Grid.Row>
              <Grid.Column>
              </Grid.Column>
              <Grid.Column>
              <Form.Field required>
                <label>Sales admin</label>
                <Form.Select
                  onChange={(e, data) => this.handleChange(data, inputs.salesAdmin)}
                  name='salesAdmin'
                  value={inputs.salesAdmin.value}
                  options={salesAdminOptions}
                  placeholder='Select sales admin'
                  multiple
                  error={inputs.salesAdmin.valid == false ? {
                    content: inputs.salesAdmin.message,
                  } : false }
                  />
              </Form.Field>
              </Grid.Column>
            </Grid.Row>
          : null}

          <Grid.Row>
            <Grid.Column>
              <Form.Field
                control={Input}
                placeholder='Email'
                name='email'
                label='Email'
                readOnly={isOldUser}
                required
                value={inputs.email.value}
                onChange={(e) => this.handleChange(e, inputs.email)}
                error={inputs.email.valid == false ? {
                  content: inputs.email.message
                } : false }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button
                floated='right'
                content="Save"
                labelPosition='right'
                icon='checkmark'
                positive
              />
              <Button
                floated='right'
                basic
                content='Cancel'
                onClick={(e) => this.props.closeNewUserForm(e)}
              />
              {
                isOldUser && user.status != 'invited' &&
                <Button
                  floated='left'
                  onClick={this.updateUserStatus}
                  disabled={!this.isInputsValid()}
                  color={user.status === 'active' ? 'red' : 'green'}
                  content={user.status === 'active' ? 'Block' : 'Activate'}
                />
              }
              {
                isOldUser && user.status == 'invited' &&
                <Label
                  style={{margin: 0}}
                  pointing
                  basic
                  size='large'
                  color='yellow'
                  content='Invited'
                />
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

    return markup;
  }
}

export default UserForm
