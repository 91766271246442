import React, { Component }  from "react"
import                            './index.css'
import {debounce}                             from 'lodash'
import {
  Divider,
  Segment,
  Header,
  Radio,
  Form,
  Grid,
  Tab,
  Input,
  Checkbox,
  Button,
  Message,
  Label,
  Icon,
  Popup,
  TextArea
}                        from 'semantic-ui-react'
import ContactPerson     from "./contact-person"
import MakeNote          from "./make-note"
import Emails            from "./emails"
import Tasks             from "./tasks"
import Events            from "./events"
import ActivitiesSection from "./activities"
import Constants, {ROLE_PERMISSIONS}         from '../../../modules/constants'
import Utils             from '../../../modules/utils'
import                        'react-phone-input-2/lib/style.css'
import PhoneInput        from 'react-phone-input-2'
import AccountAPI        from '../../../modules/api/account_api.js'
import LogMessages       from '../../shared/log-messages'
import {Auth0Context}    from "./../../../contexts/auth-context"
import _                 from 'lodash'
import QbAccountName from './qb-account-name.js'

export class AccountView extends Component
{

  static contextType = Auth0Context;

  constructor(props)
  {
    super(props)
    this.state =
    {
      loading                      : false,
      futureActivities             : [],
      loadingFutureActivities      : true,
      futureActivitiesPagination   : {},
      pastActivities               : [],
      loadingPastActivities        : true,
      pastActivitiesPagination     : {},
      account                      : this.props.account || null,
      displayMessage               : false,
      error                        : null,
      logsModal                    : false,
      useExistingCompany           : false,
      companyId                    : null,
      companies                    : this.setCompanyList(),
      usersForReps                 : this.setusersForRepsList(),
      activeIndex                  : 0,
      extendedForm                 : this.props.account ? false : true,
      inputs :
      {
        name :
        {
          value   : "",
          valid   : null,
          required : true,
          message : ""
        },

        status :
        {
          value   : "active",
          valid   : true,
          required : true,
          message : ""
        },

        channelDistribution :
        {
          value   : "",
          valid   : null,
          required : true,
          message : ""
        },

        region :
        {
          value   : "",
          valid   : null,
          required : true,
          message : ""
        },

        category :
        {
          value   : [],
          valid   : null,
          required : true,
          message : ""
        },

        subcategory :
        {
          value   : [],
          valid   : null,
          required : true,
          message : ""
        },

        brand :
        {
          value   : [],
          valid   : null,
          required : true,
          message : ""
        },

        division :
        {
          value   : [],
          valid   : null,
          required : true,
          message : ""
        },

        accountReps :
        {
          value   : [],
          valid   : this.props.account ? true : null,
          required : this.props.account ? false : true,
          message : ""
        },

        mobile :
        {
          value   : "",
          valid   : true,
          required : false,
          message : ""
        },

        mobileExtension :
        {
          value   : "",
          valid   : true,
          required : false,
          message : ""
        },

        contactPeopleAttributes :
        {
          value   : "",
          valid   : false,
          required : true,
          message : "Please fill Contact Person"
        },

        notesAttributes :
        {
          value   : [],
          valid   : true,
          required : false,
          message : ""
        }
      }
    }

    this.setInputsFromAccount();
    this.fetchFutureActivities = debounce(this.fetchFutureActivities, 800);
    this.fetchPastActivities = debounce(this.fetchPastActivities, 800);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  toggleLogs = () => {
    this.setState({logsModal: !this.state.logsModal})
  }

  handleCompanySelectChange = (e, data) =>
  {
    let { value } = data;
    let company = this.findCompany('key', value);
    let {inputs} = this.state;
    let nameInput = inputs.name;
    nameInput.value = company.text;
    nameInput.valid = true;

    this.setState({ companyId: value })
  }

  toggleUseExisting = () => {
    this.setState({useExistingCompany: !this.state.useExistingCompany})
  }

  toggleMessageVisibility = () => {
    this.setState({displayMessage: !this.state.displayMessage})
  }

  setusersForRepsList = () =>
  {
    if (!this.props.usersForReps)
    {
      return []
    }
    let result = Utils.objectsToOptionsHash([...this.props.usersForReps], 'id', 'name', (user) => {return `${user.name} (${user.role})`} )

    result = result.sort(function(a, b) {
      if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1
      }
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1
      }

      return 0
    })

    return result
  }


  setCompanyList = () =>
  {
    if (!this.props.companies)
    {
      return []
    }
    return Utils.objectsToOptionsHash([...this.props.companies], 'id', 'name')
  }

  setInputsFromAccount = () =>
  {
    if (!this.state.account)
    {
      return;
    }

    let {account, inputs} = this.state

    let inputKeys = Object.keys(inputs)
    inputKeys.forEach((key) => {
      let input = inputs[key]
      switch(key)
      {
        case "status" :
        case "channelDistribution" :
        case "region" :
        case "mobile" :
        case "mobileExtension" :
        {
          input.valid = true
          input.value = account[key]
          break;
        }
        case "name" :
        {
          input.valid = true
          input.value = account.company.name
          break;
        }
        case "category" :
        case "subcategory" :
        case "division" :
        case "brand" :
        {
          input.valid = true
          let value = account[key] || ''
          if (value) input.value = value.split(',').map(Number)
          break;
        }

        default :
        {

        }
      }
    })

    this.setState({inputs})
  }

  handleSubmit = () => {
    const { account } = this.state
    this.setState({ loading: true })
    let {data, valid} = this.getData()

    let callback = (error, data) =>
    {
      if (error)
      {
        this.setState({ loading: false, displayMessage: true, error: {apiError: true, error: error} })
      }
      else
      {
        const {setMessage} = this.context
        let action = account ? 'edited' : 'created a new'
        let flashMessage = `You have successfully ${action} contact: ${data.company.name} (${data.primaryContact.name})`
        let flashMessageObject = {displayValue: flashMessage, type: 'success'}
        this.props.history.push({pathname: '/contacts', state: {message: flashMessageObject}});
        this.setState({ loading: false })

        let {setCustomer , customer} = this.context
        let newCustomer = Object.assign({}, customer)
        data.mainContactPersonName = data.primaryContact.name
        newCustomer.distinctAccounts.push(data)

        setCustomer(newCustomer)
      }
    }
    if (valid)
    {
      if (account)
      {
        delete data.name
        delete data.accountReps
        AccountAPI.update(account.id, {account: data}, callback)
      }
      else
      {
        AccountAPI.create({account: data}, callback)
      }
    }
  }

  getData = () =>
  {
    let { inputs, useExistingCompany, companyId } = this.state
    let data = {}

    data.name                    = inputs.name.value
    data.status                  = inputs.status.value
    data.channelDistribution     = inputs.channelDistribution.value
    data.region                  = inputs.region.value
    data.category                = inputs.category.value
    data.subcategory             = inputs.subcategory.value
    data.division                = inputs.division.value
    data.brand                   = inputs.brand.value
    data.mobile                  = inputs.mobile.value
    data.mobileExtension         = inputs.mobileExtension.value
    data.contactPeopleAttributes = inputs.contactPeopleAttributes.value
    data.notesAttributes         = inputs.notesAttributes.value

    data.category                = Array.isArray(data.category) ? data.category.join(',') : data.category
    data.subcategory             = Array.isArray(data.subcategory) ? data.subcategory.join(',') : data.subcategory
    data.division                = Array.isArray(data.division) ? data.division.join(',') : data.division
    data.brand                   = Array.isArray(data.brand) ? data.brand.join(',') : data.brand


    data.accountRepsAttributes   = inputs.accountReps.value
    if (data.accountRepsAttributes.length > 0)
    {
       data.accountRepsAttributes = data.accountRepsAttributes.map((rep, i)=>{
         let data = {userId: rep, additional: false}
         if (i > 0)
         {
           data.additional = true;
         }
         return data;
       })
    }

    if (useExistingCompany && companyId)
    {
      data.companyId = companyId
    }
    else
    {
      data.companyId = null
    }

    return {data, valid: this.isInputsValid()}
  }

  isInputsValid = () =>
  {
    let {inputs} = this.state
    let valid = Utils.isInputsValidated(inputs)
    return valid
  }

  toggleWidths = () =>
  {
    let {extendedForm} = this.state;
    this.setState({extendedForm: !extendedForm})
  }

  handleChange = (e, data, fromLoad=false) =>
  {
    let state = this.state
    let {useExistingCompany, companyId} = state
    let value  = data.value
    let name   = data.name
    let inputs = state.inputs
    let userInteraction = true;
    if (fromLoad)
    {
      userInteraction = false;
    }

    let input = inputs[name]
    input.value = value

    switch(name)
    {
      case "name" :
      {
        input.valid   = !input.message

        let foundCompany = this.findCompany('text', value)
        if (foundCompany)
        {
          useExistingCompany = true
          companyId = foundCompany.key
        }
        else
        {
          useExistingCompany = false
          companyId = null
        }
        break;
      }
      case "status" :
      case "channelDistribution" :
      case "region" :
      case "category" :
      case "subcategory" :
      case "division" :
      case "brand" :
      case "mobile" :
      case "mobileExtension" :
      case "notesAttributes" :
      case "accountReps" :
      {
        input.message = ''
        if (input.required && (!input.value || input.value.length === 0))
        {
          input.message = 'Please enter the value.'
          input.valid = false
        }
        input.valid   = !input.message
        break;
      }

      case "contactPeopleAttributes" :
      {
        input.valid   = data.valid;
        input.message = input.valid ? "" : "Contact person are not valid please check values."
        break;
      }
      default :
      {

      }
    }

    this.setState({ inputs, userInteraction, useExistingCompany, companyId })
  }

  findCompany = (key, value) =>
  {
    let {companies}  = this.state;
    return _.find(companies, function(company) { return `${company[key]}`.toLowerCase().trim() === `${value}`.toLowerCase().trim() });
  }

  reloadActivities = () => {
    this.setState({
      pastActivities: [],
      futureActivities: [],
      loadingPastActivities: true,
      loadingFutureActivities: true,
    }, async () => {
      await this.fetchFutureActivities(1)
      await this.fetchPastActivities(1)
    })
  }

  fetchFutureActivities = (page=1) =>
  {
    var {account, futureActivities} = this.state;

    var callback = (error, response) =>
    {
      if (error)
      {
        this.setState({ loadingFutureActivities: false })
        console.error(error);
      }
      else
      {
        let futureActivitiesPagination = response.pagination;
        let data = futureActivitiesPagination.data;
        futureActivities = [...futureActivities, ...data]
        delete futureActivitiesPagination.data
        this.setState({ loadingFutureActivities: false, futureActivitiesPagination, futureActivities })
      }
    }
    let query = {page, activitiesOf: 'future'}
    AccountAPI.getAccountActivities(account.id, query, callback)
  }


    fetchPastActivities = (page=1) =>
    {
      var {account, pastActivities} = this.state;

      var callback = (error, response) =>
      {
        if (error)
        {
          this.setState({ loadingPastActivities: false })
          console.error(error);
        }
        else
        {
          let pastActivitiesPagination = response.pagination;
          let data = pastActivitiesPagination.data;
          pastActivities = [...pastActivities, ...data]
          delete pastActivitiesPagination.data
          this.setState({ loadingPastActivities: false, pastActivitiesPagination, pastActivities })
        }
      }
      let query = {page, activitiesOf: 'past'}
      AccountAPI.getAccountActivities(account.id, query, callback)
    }

  render()
  {
    let {inputs, account, useExistingCompany, companyId, companies, activeIndex, usersForReps, extendedForm} = this.state
    const {user, customer, setMessage} = this.context
    const categories = customer ? Utils.objectsToOptionsHash([...customer.categories], 'id', 'name') : []
    const subcategories = customer ? Utils.objectsToOptionsHash([...customer.subcategories], 'id', 'name') : []
    const divisions = customer ? Utils.objectsToOptionsHash([...customer.divisions], 'id', 'name') : []
    const brands = customer ? Utils.objectsToOptionsHash([...customer.brands], 'id', 'name') : []

    var contactPeopleLogs = []
    var log = {}
    if (account)
    {
      contactPeopleLogs = _.map(account.contactPeople, 'logs')
      log.titles = ["Contact Logs", "Contact Person Logs"]
      log.logs = [account.logs, ...contactPeopleLogs]
    }

    let isProduction = (!!user && user.role == 'production')

    const accountPanes = [
      {
        menuItem: 'Details',
        render: () => <Tab.Pane attached={false} key='accounts-tab'>

            <Grid columns='equal'>
                <Grid.Row>
                  <Grid.Column>
                  {account && this.state.logsModal &&(
                    <div style={{marginBottom: '30px'}}>
                      <LogMessages
                        titles={log.titles}
                        logs={log.logs}
                      />
                    </div>
                  )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                        <div className='status-radio'>
                          <Radio
                            id='active'
                            name='status'
                            value='active'
                            checked={inputs.status.value === 'active'}
                            onChange={this.handleChange}
                          />
                          <label htmlFor='active' >
                            <div className='status-item'>
                              <Icon name='users' />
                              <p>Active</p>
                            </div>
                           </label>
                          </div>
                        </Form.Field>
                    </Grid.Column>

                    <Grid.Column>
                        <Form.Field>
                        <div className='status-radio'>
                          <Radio
                            id='pending'
                            name='status'
                            value='pending'
                            checked={inputs.status.value === 'pending'}
                            onChange={this.handleChange}
                          />
                          <label htmlFor='pending' >
                            <div className='status-item'>
                              <Icon name='clock' />
                              <p>Pending</p>
                            </div>
                           </label>
                          </div>
                        </Form.Field>
                    </Grid.Column>

                    <Grid.Column>
                        <Form.Field>
                        <div className='status-radio'>
                          <Radio
                            id='review'
                            name='status'
                            value='in review'
                            checked={inputs.status.value === 'in review'}
                            onChange={this.handleChange}
                          />
                          <label htmlFor='review' >
                            <div className='status-item'>
                              <Icon name='user outline' />
                              <p>In Review</p>
                            </div>
                           </label>
                          </div>
                        </Form.Field>
                    </Grid.Column>

                    <Grid.Column>
                        <Form.Field style={!ROLE_PERMISSIONS.admin.includes(user.role) ? {opacity: 0.5, cursor: 'not-allowed'} : null}>
                        <div className='status-radio'>
                          <Radio
                            id='inactive'
                            name='status'
                            value='inactive'
                            disabled={!ROLE_PERMISSIONS.admin.includes(user.role)}
                            checked={inputs.status.value === 'inactive'}
                            onChange={ROLE_PERMISSIONS.admin.includes(user.role) ? this.handleChange : null}
                          />
                          <label htmlFor='inactive' >
                            <div className='status-item' style={!ROLE_PERMISSIONS.admin.includes(user.role) ? {cursor: 'not-allowed'} : null}>
                              <Icon name='close' />
                              <p>Inactive</p>
                            </div>
                           </label>
                          </div>
                        </Form.Field>
                    </Grid.Column>

                </Grid.Row>
                <Divider />

                <Grid.Row>
                  <Grid.Column>
                    <Form.Field
                      className="field-disabled"
                    >
                      <label>Account Rep</label>
                      {
                        this.props.account ?
                        <Input value={this.props.account.reps.map((rep)=> `${rep.name}`).join(', ')}/>
                        :
                        <Form.Select
                          search
                          multiple={true}
                          name='accountReps'
                          placeholder='Select Reps'
                          onChange={this.handleChange}
                          options={usersForReps}
                          value={inputs.accountReps.value}
                          error={inputs.accountReps.valid === false ? {
                              content: inputs.accountReps.message,
                              // pointing: 'below',
                          } : false }
                        />
                      }
                    </Form.Field>
                  </Grid.Column>

                     <Grid.Column>
                      {
                        useExistingCompany ?
                         <Form.Dropdown
                         placeholder={<span>Select <b>Account</b> from list</span>}
                         search
                         fluid
                         selection
                         label='Account Name'
                         name='companyId'
                         value={companyId}
                         onChange={this.handleCompanySelectChange}
                         options={companies} />
                         :

                        <Form.Field
                          control={Input}
                          placeholder='Add unique Account Name'
                          name='name'
                          label='Account Name'
                          required
                          className={account ? "field-disabled" : ''}
                          value={inputs.name.value}
                          onChange={account ? null : this.handleChange}
                          error={inputs.name.valid === false ? {
                            content: inputs.name.message,
                          } : false }
                        />
                      }
                      {
                        account ? null :
                        <Checkbox
                          label={<label>Add to Existing <b>Account</b></label>}
                          onChange={(e, { checked }) => {
                                      this.toggleUseExisting()
                                    }
                                  }
                          checked={useExistingCompany}
                        />
                      }

                  </Grid.Column>

                </Grid.Row>


                <Divider />

                <ContactPerson
                  inputs={inputs}
                  onChange={this.handleChange}
                  contactPeople={account ? account.contactPeople : null}
                  />

                  <Grid.Row>
                    <Grid.Column>
                        <Form.Field required>
                          <label>Distribution Channel</label>
                          <Form.Select
                            name='channelDistribution'
                            placeholder='Select Channel Distribution'
                            onChange={this.handleChange}
                            options={Utils.arrayToOptionsHash(Constants.CHANNEL_DISTRIBUTIONS)}
                            value={inputs.channelDistribution.value}
                            error={inputs.channelDistribution.valid === false ? {
                                content: inputs.channelDistribution.message,
                                // pointing: 'below',
                            } : false }
                          />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field required>
                          <label>Region</label>
                          <Form.Select
                            name='region'
                            placeholder='Select Account Region'
                            onChange={this.handleChange}
                            options={Utils.arrayToOptionsHash(Constants.REGIONS)}
                            value={inputs.region.value}
                            error={inputs.region.valid === false ? {
                                content: inputs.region.message,
                                // pointing: 'below',
                            } : false }
                          />
                        </Form.Field>
                      </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field required>
                          <label>Brand</label>
                          <Form.Select
                            multiple={true}
                            name='brand'
                            placeholder='Select Brand'
                            onChange={this.handleChange}
                            options={brands}
                            value={inputs.brand.value}
                            error={inputs.brand.valid === false ? {
                                content: inputs.brand.message,
                                // pointing: 'below',
                            } : false }
                          />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field required>
                          <label>Division</label>
                          <Form.Select
                            multiple={true}
                            name='division'
                            placeholder='Select Division'
                            onChange={this.handleChange}
                            value={inputs.division.value}
                            options={divisions}
                            error={inputs.division.valid === false ? {
                                content: inputs.division.message,
                                // pointing: 'below',
                            } : false }
                          />
                        </Form.Field>
                      </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field required>
                          <label>Category</label>
                          <Form.Select
                            multiple={true}
                            name='category'
                            placeholder='Select Categories'
                            onChange={this.handleChange}
                            options={categories}
                            value={inputs.category.value}
                            error={inputs.category.valid === false ? {
                                content: inputs.category.message,
                                // pointing: 'below',
                            } : false }
                          />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field required>
                          <label>Sub-Category</label>
                          <Form.Select
                            multiple={true}
                            name='subcategory'
                            placeholder='Select Sub-Category'
                            onChange={this.handleChange}
                            value={inputs.subcategory.value}
                            options={subcategories}
                            error={inputs.subcategory.valid === false ? {
                                content: inputs.subcategory.message,
                                // pointing: 'below',
                            } : false }
                          />
                        </Form.Field>
                      </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={6}>
                    <Form.Field
                        required
                        error={inputs.mobile.valid === false ? {
                          content: inputs.mobile.message
                        } : false }
                        >
                        <label>Extra phone <span className='optional'>(optional)</span></label>
                        <PhoneInput
                          inputProps={{
                              name: 'phone',
                              required: true,
                            }}
                          country={'us'}
                          disableAreaCodes={true}
                          value={inputs.mobile.value || ''}
                          placeholder=''
                          onChange={(phone) => this.handleChange(null, {name: 'mobile', value: phone})}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Form.Field
                        control={Input}
                        placeholder='10'
                        name='mobileExtension'
                        label='Ext'
                        value={inputs.mobileExtension.value}
                        onChange={this.handleChange}
                        error={inputs.mobileExtension.valid === false ? {
                          content: inputs.mobileExtension.message,
                        } : false }
                      />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Tab.Pane>
      }
    ]

    const sidePanes = [
    {
        menuItem: { onClick: () => {this.setState({activeIndex: 0})}, content: 'Make a Note' } ,
        render: () => <Tab.Pane key='note-tab'>
                        <MakeNote
                          reloadActivities={this.reloadActivities}
                          account={account}
                          onChange={this.handleChange}
                          setFlashMessage={setMessage}
                        />
                      </Tab.Pane>,
      },
      { menuItem: {onClick: (account ? () => {this.setState({activeIndex: 1})} : null), content: 'Email', className: account ? '' : 'disabled'} , render: () =>
          account ?
          <Tab.Pane style={{overflow: "hidden", minHeight: "23.5rem"}} key='email-tab'>
            <Emails
              reloadActivities={this.reloadActivities}
              account={account}
              setFlashMessage={setMessage}
              />
           </Tab.Pane>
           : null
      },
      { menuItem: {onClick: (account ? () => {this.setState({activeIndex: 2})} : null), content: 'Tasks', className: account ? '' : 'disabled'} , render: () =>
          account ?
          <Tab.Pane style={{overflow: "hidden", minHeight: "24rem"}} key='task-tab'>
            <Tasks
              reloadActivities={this.reloadActivities}
              account={account}
              setFlashMessage={setMessage}
              />
           </Tab.Pane>
           : null
      },
      { menuItem: {onClick: (account ? () => {this.setState({activeIndex: 3})} : null), content: 'Events', className: account ? '' : 'disabled'} , render: () =>
          account ?
          <Tab.Pane style={{overflow: "hidden", minHeight: "31.5rem"}} key='event-tab'>
            <Events
              reloadActivities={this.reloadActivities}
              account={account}
              setFlashMessage={setMessage}
              />
           </Tab.Pane>
           : null
      }//,
      //{ menuItem: {content: 'New PO', className: account ? '' : 'disabled'} , render: () =>
      //    <Tab.Pane key='new-po-tab'>Tab 3 Content</Tab.Pane>
      //},
    ]
    let errorMessage = ''
    if (this.state.error && this.state.error.apiError && this.state.error.error)
    {
        let errors = this.state.error.error.response.data
        let errorKeys = Object.keys(errors)
        errorMessage = errorKeys.map((key, i) => {
            return <p key={i}><b>{key}: </b>{errors[key].join(', ')}</p>
        })
    }

    let markup =
        <div style={{padding: '50px 60px'}} className="account-form">
          {
              this.state.displayMessage && (
              <Message
                negative
                size='mini'
                onDismiss={this.toggleMessageVisibility}
              >
                <Message.Header>We're sorry some error occured!</Message.Header>
                {errorMessage}
              </Message>
          )}
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={extendedForm ? 10 : 6} id='left-grid' className={extendedForm ? 'wide' : 'narrow'}>
                <Grid.Row>
                  <Grid.Column>
                    <div className='grid-heading' style={{display: 'flex', justifyContent: 'space-between', height: '30px'}}>
                      {
                        account == null
                        ?
                        <Header as='h3'>New Contact</Header>
                        :
                        <Header as='h3'>
                          Contact: &nbsp; {account.company.name} ({account.primaryContact == null ? 'Missing primary contact' : account.primaryContact.name})
                        </Header>
                      }

                      {
                        account && false &&
                          <Button size='mini' onClick={this.toggleLogs} basic style={{marginLeft: '20px'}}>{this.state.logsModal ? 'Hide edit logs' : 'Show edit logs'}</Button>
                      }
                    </div>

                    {account != null ? <QbAccountName account={account}/> : null}

                    <Divider />

                    <Segment>
                      {
                        account && account.needsUpdate &&
                        <Label
                          color='red'
                          ribbon
                          content="This account has an incomplete task for Account Needs Update"
                        />
                      }
                      {
                        !extendedForm &&
                          <Popup
                            content='Expand Contact Details'
                            position='top right'
                            trigger={<Icon name='arrow right' id='right-arrow' onClick={this.toggleWidths}/>}
                            />
                      }
                      <Form onSubmit={this.handleSubmit} loading={this.state.loading}>
                        <Tab menu={{ secondary: true, pointing: true }} panes={accountPanes} />
                        {
                          isProduction
                          ?
                          null
                          :
                          (account && this.state.userInteraction ) || (!account) ?
                          <Button
                            style={{marginTop: '20px'}}
                            disabled={!this.isInputsValid()}
                            primary
                            type='submit'
                          >
                            Save
                          </Button>
                          :
                          null
                        }
                        {
                          this.isInputsValid()
                          ?
                          null
                          :
                          <small style={{marginLeft: '20px', color: 'red',border: '1px solid red', padding: '5px 10px', paddingRight: 0, borderRadius: '11px', fontWeight: 'bold', background: 'rgb(255,220,220)'}}>
                            You need to finish completing all required fields<Icon name='warning' />
                          </small>
                        }
                      </Form>
                    </Segment>

                  </Grid.Column>
                </Grid.Row>

              </Grid.Column>

              <Grid.Column width={extendedForm ? 6 : 10} id='right-grid' className={extendedForm ? 'narrow' : 'wide'}>
                 <Grid.Row>
                  <Grid.Column>
                    <div style={{height: '32px'}} >
                      <Header as='h3'>Shortcuts</Header>
                    </div>
                    <Divider />

                    <Segment>
                      {
                        extendedForm &&
                          <Popup
                            content='Expand Contact Activity'
                            position='top right'
                            trigger={<Icon name='arrow left' id='left-arrow' onClick={this.toggleWidths}/>}
                            />

                      }
                      <Tab activeIndex={activeIndex} panes={sidePanes} className='right-side-tabs'/>
                    </Segment>

                  </Grid.Column>
                </Grid.Row>
                {
                  account &&
                  <Grid.Row>
                    <Grid.Column>
                      <Divider />
                      <div >
                        <Header as='h3'>Future Activities</Header>
                      </div>
                      <Segment className='activities-segment'>
                        <ActivitiesSection
                          activities={this.state.futureActivities}
                          loading={this.state.loadingFutureActivities}
                          pagination={this.state.futureActivitiesPagination}
                          fetchActivities={this.fetchFutureActivities}
                          reloadActivities={this.reloadActivities}
                          account={account}
                          activitiesOf='future'
                          />
                      </Segment>

                    </Grid.Column>
                  </Grid.Row>
                }
                {
                  account &&
                  <Grid.Row>
                    <Grid.Column>
                      <Divider />
                      <div >
                        <Header as='h3'>Past Activities</Header>
                      </div>
                      <Segment className='activities-segment'>
                        <ActivitiesSection
                          activities={this.state.pastActivities}
                          loading={this.state.loadingPastActivities}
                          pagination={this.state.pastActivitiesPagination}
                          fetchActivities={this.fetchPastActivities}
                          reloadActivities={this.reloadActivities}
                          account={account}
                          activitiesOf='past'
                          />
                      </Segment>

                    </Grid.Column>
                  </Grid.Row>
                }
              </Grid.Column>

            </Grid.Row>
          </Grid>
        </div>
    return markup
  }
}

export default AccountView
