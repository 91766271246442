import React from 'react'
import {Label, Popup} from 'semantic-ui-react'
import {labelStyle} from './label-style.js'

const getMarginLetter = (margin) => {
  if (margin == null) { return '?'}

  margin = Number(margin)

  if (margin >= 55) {
    return 'A'
  } else if (margin >= 45) {
    return 'B'
  } else if (margin >= 36) {
    return 'C'
  } else if (margin >= 25) {
    return 'D'
  } else {
    return 'E'
  }
}

const getMarginPopup = (order, index) => {
  let margin = order.margins[index]
  let marginLetter = getMarginLetter(margin)
  let popupContent = (margin == null ? 'Missing' : margin+'%')

  return (
    <Popup content={'Margin ' + popupContent} position='right center' trigger={
      <Label style={labelStyle}><small>{marginLetter}</small></Label>
    } />
  )
}

const anyMarginsMatch = (value, margins, price) => {
  return value.some((el) => {
    let anyMatch = false
    let firstLetter = el[0]

    switch(firstLetter) {
      case 'A':
        anyMatch = margins.some(margin => Number(margin) >= 55 )
        break
      case 'B':
        anyMatch = margins.some(margin => Number(margin) >= 45 && Number(margin) < 55)
        break
      case 'C':
        anyMatch = margins.some(margin => Number(margin) >= 36 && Number(margin) < 45)
        break
      case 'D':
        anyMatch = margins.some(margin => Number(margin) >= 25 && Number(margin) < 36)
        break
      case 'E':
        anyMatch = margins.some(margin => Number(margin) < 25)
        break
      case 'M':
        anyMatch = margins.some(margin => margin == null) || (margins.length == 0 && price.length != 0)
        break
      default:
        //console.log('invalid margin value')
    }

    return anyMatch
  })
}

const isMarginsMatch = (value, order, index) => {
  let margin = order.margins[index]

  return value.some((el) => {
    let isMatch = false
    let firstLetter = el[0]

    switch(firstLetter) {
      case 'A':
        isMatch = Number(margin) >= 55
        break
      case 'B':
        isMatch = Number(margin) >= 45 && Number(margin) < 55
        break
      case 'C':
        isMatch = Number(margin) >= 36 && Number(margin) < 45
        break
      case 'D':
        isMatch = Number(margin) >= 25 && Number(margin) < 36
        break
      case 'E':
        isMatch = Number(margin) < 25
        break
      default:
        //console.log('invalid margin value')
    }

    return isMatch
  })
}

const isMarginsMissing = (order, index) => {
  return order.margins[index] == null
}

export {getMarginPopup, anyMarginsMatch, isMarginsMatch, isMarginsMissing}
