import $ from 'jquery'

const updateMultiSearchMessage = (myState) => {
  let multiSearchMessage = 'All are found.';

  let notFoundList = [];

  if (myState.multiSearch.length > 2 && !!window.multiSearchIndexTotals) {

    for (var i=0; i<myState.multiSearchStyleNumbers.length; i++) {
      var styleNumber = myState.multiSearchStyleNumbers[i];
      var total = window.multiSearchIndexTotals[i];

      if (!total) {
        notFoundList.push(styleNumber);

      } else if (total > 1 && !myState.searchContainerNumber) {
        multiSearchMessage = "Some have multiple matches.";
      }
    }

    if (notFoundList.length > 0 && multiSearchMessage == 'All are found.') {
      multiSearchMessage = '';
    }
  }


  //update secondary Message
  $('#multiSearchMessage').text(multiSearchMessage)

  //update not found list
  if (notFoundList.length > 0) {
    $('#multi-search-not-found').show()
    $('#multi-search-not-found > ul > li').remove()

    for (var i=0; i<notFoundList.length; i++) {
      $( "#multi-search-not-found > ul" ).append( "<li>" + notFoundList[i].toUpperCase() + "</li>" );
    }
  } else {
    $('#multi-search-not-found').hide();
  }
}

export default updateMultiSearchMessage
