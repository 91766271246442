import React from 'react'
import {Dropdown} from 'semantic-ui-react'
import {arrayToOptions} from './array-to-options.js'

const inventoryTypeForSalesOptions = arrayToOptions([
  'ATS',
  'Future ATS',
  'All',
])

const inventoryTypeForSalesDropdown = (fieldOnChange, inventoryTypeForSales) => {

  return (
    <span style={{width: '150px', marginLeft: '15px', display: 'inline-block', position: 'relative', bottom: '10px', verticalAlign: 'top'}}>
      <Dropdown placeholder='Inventory Type' fluid selection search options={inventoryTypeForSalesOptions} onChange={fieldOnChange} name='inventoryTypeForSales' value={inventoryTypeForSales}/>
    </span>
  )

}

export {inventoryTypeForSalesDropdown}
