import React, { Component } from 'react'
import $ from 'jquery'
import './EditCadPage.css'
import { Table, Icon, Grid, Button, Input, Header, Form, Message, Image, Loader, Dimmer } from 'semantic-ui-react'
import { connect } from 'react-redux'
import * as cadActions from './actions/cadActions'
import { bindActionCreators } from 'redux'
import Dropzone from 'react-dropzone'
import Utils from "./modules/utils"
import {Auth0Context}    from "./contexts/auth-context"
import createThumb from './components/upload-cad/create-thumb'
import {getSizeRatioOptions} from './components/vendor-orders/options'

class EditCadPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props);
    this.state = {
      styleNumber: '',
      //styleNumber2: '',
      cadCreator: '',
      cadCreateDate: '',
      imageUploadDate: '',
      saveSuccessVisible: false,
      filename: '',
      thereHasBeenAChange: false,
      loading: false,
      changingCadImage: false,
      loadingCadImage: false,
      showEditStyleNumber: false
    };

    this.getCad = this.getCad.bind(this);
    this.updateCad = this.updateCad.bind(this);
    this.viewCad = this.viewCad.bind(this);
    this.changeCadImage = this.changeCadImage.bind(this);
    this.cancelChangeCadImage = this.cancelChangeCadImage.bind(this);

    this.saveSuccessHandleDismiss = this.saveSuccessHandleDismiss.bind(this);
    this.showSaveSuccess = this.showSaveSuccess.bind(this);

    this.divisionOnChange = this.divisionOnChange.bind(this);
    this.brandOnChange = this.brandOnChange.bind(this);
    this.categoryOnChange = this.categoryOnChange.bind(this);

    this.colorOnChange = this.colorOnChange.bind(this);
    this.fabricationOnChange = this.fabricationOnChange.bind(this);
    this.packsOnChange = this.packsOnChange.bind(this);
    this.sizesOnChange = this.sizesOnChange.bind(this);
    this.subCategoryOnChange = this.subCategoryOnChange.bind(this);
    this.sub2OnChange = this.sub2OnChange.bind(this);

    this.saveCad = this.saveCad.bind(this);
    this.showEditStyleNumber = this.showEditStyleNumber.bind(this);
    this.cancelEditStyleNumber = this.cancelEditStyleNumber.bind(this);
    this.saveEditStyleNumber = this.saveEditStyleNumber.bind(this);

    this.seasonOnChange = this.seasonOnChange.bind(this);
    // this.availableOnChange = this.availableOnChange.bind(this);
    // this.futureStockOnChange = this.futureStockOnChange.bind(this);
    // this.earliestDeliveryDateOnChange = this.earliestDeliveryDateOnChange.bind(this);

    this.nameOnChange = this.nameOnChange.bind(this);
    this.notUsing = this.notUsing.bind(this)
  }

  showEditStyleNumber() {
    this.setState({showEditStyleNumber: true});
    setTimeout(function() {
      $('#edit-style-number-input').val(this.state.styleNumber);
    }.bind(this), 100);
  }

  saveEditStyleNumber() {
    const {customer} = this.context
    let newStyleNumber = $('#edit-style-number-input').val().toUpperCase().trim().replace('    ',' ').replace('   ',' ').replace('  ',' ');

    let profile = JSON.parse(sessionStorage.getItem('user'));
    let user_name = (profile.name != null ? profile.name : profile.email)

    if (newStyleNumber.length < 5) {
      alert('New style number must be at least 5 characters!');
    } else {
      //TODO check if the style number is unique...

      $.get(process.env.REACT_APP_API_URL + '/api/check_if_cad_unique?style_number=' + newStyleNumber + '&cad_id=' + this.props.id, function (result) {
        if (!result.unique) {
          alert('Style number "' + newStyleNumber + '" is already taken ');
        } else {
          $('#save-style-number-button').addClass('loading disabled')
          $.get(process.env.REACT_APP_API_URL + '/api/save_new_style_number?style_number=' + newStyleNumber + '&cad_id='+this.props.id+'&user_name=' + user_name + '&org_id=' + customer.id, function (result2) {
            alert('The style number was successfully updated.')

            setTimeout(function() {
              window.location.reload();
            }.bind(this), 100);
          }.bind(this));
        }
      }.bind(this));
    }
    // if (confirm('Are you sure you want to change the style number to "' + newStyleNumber + '"')) {
    //
    // }
  }

  cancelEditStyleNumber() {
    this.setState({showEditStyleNumber: false});
  }

  saveSuccessHandleDismiss() {
    this.setState({saveSuccessVisible: false});
  }

  showSaveSuccess() {
    this.setState({saveSuccessVisible: true});
    setTimeout(function() {
      window.scrollTo(0,0);
    },0);

    setTimeout(function() {
      this.saveSuccessHandleDismiss();
    }.bind(this), 15000);

    $('#division-select').prop('style','');
    $('#brand-select').prop('style','');
    $('#category-select').prop('style','');
    $('#color-select').prop('style','');
    $('#fabrication-select').prop('style','');
    $('#packs-select').prop('style','');
    $('#sub-category-select').prop('style','');
    $('#sub2-select').prop('style','');
  }

  saveCad() {
    const {customer} = this.context

    if (window.confirm('Are you sure you want to save these changes?')) {
      this.setState({loading: true});

      let profile = JSON.parse(sessionStorage.getItem('user'));
      let user_name = (profile.name != null ? profile.name : profile.email)

      $.ajax({
        type: "POST",
        url: process.env.REACT_APP_API_URL + '/api/save_cad/' + this.props.id,
        data: {
          division: this.state.division,
          category: this.state.category,
          brand: this.state.brand,
          parent_category: this.state.parent_category,
          sub2: this.state.sub2,
          color: this.state.color,
          fabrication: this.state.fabrication,
          packs: this.state.packs,
          sizes: this.state.sizes,
          user_name: user_name,
          user_id: profile.auth0Uuid,
          season: this.state.season,
          name: this.state.name,
          // available: this.state.available, //only if david user
          // future_stock: this.state.futureStock, //only if david user
          // earliest_delivery_date: this.state.earliestDeliveryDate //only if david user
          org_id: customer.id
        },
        success: function (data) {
            // your callback here
            this.setState({loading: false, thereHasBeenAChange: false});
            this.showSaveSuccess();
        }.bind(this),
        error: function (error) {
            // handle error
            alert('There was an error when trying to save the cad.')
        },
      });


    }
  }

  componentDidMount() {

    //hide calendar widget
    setTimeout(function() {
      $('.calendly-badge-widget').hide();
    },0);

    this.getCad(this.props.id);
  }

  getCad(cad_id) {
    //this.setLoadingTrue();
    $.get(process.env.REACT_APP_API_URL + '/api/cads/' + cad_id, function (result) {
      this.updateCad(result);
    }.bind(this));
  }

  updateCad(result2) {
    var result = JSON.parse(result2.cad).data.attributes;


    let color = result.color;
    if (color == null) {
      color = [];
    } else if (color[0] == '[') {
      color = JSON.parse(color);
    } else {
      color = [color];
    }

    let earliestDeliveryDate = null;
    if (result.earliest_delivery_date != null) {
      earliestDeliveryDate = result.earliest_delivery_date.split('T')[0];
    }

    this.setState({
      styleNumber: result.style_number,
      //styleNumber2: result.style_number,
      cadCreator: result.user_name,
      cadCreateDate: result.date_created,
      imageUploadDate: result.image_uploaded,
      filename: result.filename,
      division: result.department,
      category: result.category,
      brand: result.brand,
      parent_category: result.parent_category,
      sub2: result.child_category,
      color: color,
      fabrication: result.fabrication,
      packs: result.packs,
      sizes: result.sizes,
      season: result.season,
      // available: result.available,
      // futureStock: result.future_stock,
      // earliestDeliveryDate: earliestDeliveryDate,
      name: result.name,
      old_s3_bucket: result.old_s3_bucket
    });
  }

  componentDidUpdate() {

  }

  viewCad() {
    window.location = '/#/view-cad/' + this.props.id
  }

  changeCadImage() {
    if (window.confirm('Are you sure you want to change the Cad image?')) {
      this.setState({changingCadImage: true});
    }
  }

  cancelChangeCadImage() {
    this.setState({changingCadImage: false});
  }

  divisionOnChange(event, {value}) {
    this.setState({division: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#division-select').prop('style','border: 1px solid rgb(41,135,205);');
  }

  brandOnChange(event, {value}) {
    this.setState({brand: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#brand-select').prop('style','border: 1px solid rgb(41,135,205);');
  }

  categoryOnChange(event, {value}) {
    this.setState({parent_category: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#category-select').prop('style','border: 1px solid rgb(41,135,205);');

    const {customer} = this.context
    let category = customer.categories.filter((x) => {return x.name == value})[0]

    if (category.subcategories.length > 0) {
      let applicableSubcategories = category.subcategories.map((x) => {return x.name})
      let value2 = this.state.category
      if (applicableSubcategories.indexOf(value2) == -1) {
        this.setState({category: null});
        $('#sub-category-select').prop('style','border: 1px solid rgb(41,135,205);');
      }
    }


  }

  colorOnChange(event, {value}) {
    this.setState({color: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#color-select').prop('style','border: 1px solid rgb(41,135,205);');
  }

  fabricationOnChange(event, {value}) {
    this.setState({fabrication: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#fabrication-select').prop('style','border: 1px solid rgb(41,135,205);');
  }

  packsOnChange(event, {value}) {
    this.setState({packs: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#packs-select').prop('style','border: 1px solid rgb(41,135,205);');
  }

  sizesOnChange(event, {value}) {
    this.setState({sizes: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#sizes-select').prop('style','border: 1px solid rgb(41,135,205);');
    $('#size-input').prop('style','border: 1px solid rgb(41,135,205);');
  }

  seasonOnChange(event, {value}) {
    this.setState({season: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#season-select').prop('style','border: 1px solid rgb(41,135,205);');
  }

  // availableOnChange(event, {value}) {
  //   this.setState({available: value, thereHasBeenAChange: true});
  //   $('#ats-input').prop('style','border: 1px solid rgb(41,135,205);');
  // }
  //
  // futureStockOnChange(event, {value}) {
  //   this.setState({futureStock: value, thereHasBeenAChange: true});
  //   $('#future-ats-input').prop('style','border: 1px solid rgb(41,135,205);');
  // }
  //
  // earliestDeliveryDateOnChange(event, {value}) {
  //   this.setState({earliestDeliveryDate: value, thereHasBeenAChange: true});
  //   $('#earliest-delivery-input').prop('style','border: 1px solid rgb(41,135,205);');
  // }

  subCategoryOnChange(event, {value}) {
    this.setState({category: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#sub-category-select').prop('style','border: 1px solid rgb(41,135,205);');
  }

  sub2OnChange(event, {value}) {
    this.setState({sub2: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#sub2-select').prop('style','border: 1px solid rgb(41,135,205);');
  }

  nameOnChange(event, {value}) {
    this.setState({name: value, thereHasBeenAChange: true});
    this.scrollToSaveButton();
    $('#name-input').prop('style','border: 1px solid rgb(41,135,205);');
  }

  scrollToSaveButton() {
    setTimeout(function() {
      $('#my-save-button')[0].scrollIntoView({behavior: "smooth"});
    }, 0);
  }


  updateCadImage(data) {
    this.setState({loadingCadImage: true});
    let profile = JSON.parse(sessionStorage.getItem('user'));
    let user_name = (profile.name != null ? profile.name : profile.email)

    let data2 = {
      cad_filename: data.cad_filename,
      user_name: user_name,
      user_id: profile.auth0Uuid,
      cad_id: this.props.id
    }

    Utils.post('update_cad_image', data2, (err, res) =>
    {
      if (err) {
        console.log('There was an error when trying to update the cad image.')
      } else {
        console.log('The cad image was successfully updated.');
        this.setNewCadImage(data.cad_filename);
      }
    })
  }

  setNewCadImage(cad_filename) {

    //this.cancelChangeCadImage();
    setTimeout(function() {
      window.location.reload();
    }.bind(this), 100);
  }


  handleAcceptedFiles(acceptedFiles) {
    if (this.state.loadingCadImage) { return; }
    if (acceptedFiles.length != 1) {
      alert('Only upload 1 file at a time.');
    } else {

      let file = acceptedFiles[0];
      let formData = new FormData();
      let filename = file.name;

      if (filename.toLowerCase().indexOf('.png') == -1 && filename.toLowerCase().indexOf('.jpg') == -1 && filename.toLowerCase().indexOf('.jpeg') == -1) {
        alert('Only allow .jpg and .png file types.')
      } else {
        formData.append("file", file, file.name);

        const thumbCallback = () => {
          $.ajax({
            type: "POST",
            url: process.env.REACT_APP_API_URL + '/file-upload-cad',
            success: function (data) {
                // your callback here
                if (data.success) {
                  this.updateCadImage(data);
                } else {
                  if (!!data.corrupt_file) {
                    alert('The file is corrupt.')
                  } else {
                    alert('There was an error when trying to upload the file.')
                  }
                }
            }.bind(this),
            error: function (error) {
                // handle error
                alert('There was an error when trying to upload the file.')
            },
              async: true,
              data: formData,
              cache: false,
              contentType: false,
              processData: false,
              timeout: 60000
          });
        }

        createThumb(file, formData, thumbCallback)
      }
    }
  }

  notUsing() {
    const {customer} = this.context

    if (window.confirm('Are you sure you want to mark as not using?')) {
      $.get(process.env.REACT_APP_API_URL + '/api/mark_cad_as_not_using?cad_id=' + this.props.id + '&org_id=' + customer.id, (result) => {
        alert('success')
      })
    }
  }

  render() {

    const {user, customer, setMessage} = this.context
    let categories = customer ? Utils.objectsToOptionsHash([...customer.categories], 'id', 'name') : []
    let subcategories = customer ? Utils.objectsToOptionsHash([...customer.subcategories], 'id', 'name') : []

    let divisions = customer ? Utils.objectsToOptionsHash([...customer.divisions], 'id', 'name') : []
    let brands = customer ? Utils.objectsToOptionsHash([...customer.brands], 'id', 'name') : []

    let colors = customer ? Utils.objectsToOptionsHash([...customer.colors], 'id', 'name') : []
    let fabrications = customer ? Utils.objectsToOptionsHash([...customer.fabrications], 'id', 'name') : []
    let packs = customer ? Utils.objectsToOptionsHash([...customer.packs], 'id', 'name') : []
    let seasons = customer ? Utils.objectsToOptionsHash([...customer.seasons], 'id', 'name') : []

    let sizes = customer ? Utils.objectsToOptionsHash([...customer.sizes], 'id', 'name') : []

    let sizeRatioOptions = getSizeRatioOptions()

    categories = categories.optionsHashTextAsKey()
    subcategories = subcategories.optionsHashTextAsKey()

    divisions = divisions.optionsHashTextAsKey()
    brands = brands.optionsHashTextAsKey()
    colors = colors.optionsHashTextAsKey()
    fabrications = fabrications.optionsHashTextAsKey()
    packs = packs.optionsHashTextAsKey()
    seasons = seasons.optionsHashTextAsKey()
    sizes = sizes.optionsHashTextAsKey()

    let sub2Options = []

    if (customer != null) {
      //get applicable subcategories
      if (this.state.parent_category != null) {
        let category = customer.categories.filter((x) => {return x.name == this.state.parent_category})[0]

        if (category != null && category.subcategories != null && category.subcategories.length > 0) {
          subcategories = category.subcategories
            .map(x => customer.subcategories.find(el => el.id == x))
            .map((x) => {return { key: x.name, text: x.name, value: x.name } })
        }
      }

      if (this.state.category != null) {
        let subcategory = customer.subcategories.filter((x) => {return x.name == this.state.category})[0]
        if (subcategory != null && subcategory.grandSubcategories != null && subcategory.grandSubcategories.length > 0) {
          sub2Options = subcategory.grandSubcategories
            .map(x => customer.grandSubcategories.find(el => el.id == x))
            .map((x) => {return { key: x.name, text: x.name, value: x.name } })
        }
      }
    }



    let role = sessionStorage.getItem('currentUserRole')
    let isAdmin = ['admin','superadmin','finance'].includes(role)

    let image_url
    let s3BucketName = this.state.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME

    if (this.state.filename == null || this.state.filename == '') {
      image_url = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new.png';
    } else {
      image_url = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + this.state.filename;
    }

    //let profile = JSON.parse(sessionStorage.getItem('user'));
    // let user_email = profile.email;
    // let isDavid = user_email == "david@megabrandsgroup.com";
    //
    // var applicableSubCategoryOptions;
    // if (isDavid) {
    //   applicableSubCategoryOptions = getApplicableSubCategoryOptions([], 'DavidNOTADMIN');
    // } else {
    //   applicableSubCategoryOptions = getApplicableSubCategoryOptions([this.state.parent_category], '');
    // }

    // if (this.state.category != '' && this.state.category != null && applicableSubCategoryOptions.map(object => object.key).indexOf(this.state.category) == -1) {
    //   this.setState({category: ''});
    // }

    return (
      <div style={{padding: '50px 60px'}}>
        <h1 style={{display: 'inline-block', marginTop: '25px', marginBottom: '30px'}}>Edit cad #{this.props.id}</h1>
        <Button basic style={{position: 'relative', bottom: '5px', marginLeft: '30px'}} onClick={this.viewCad}>View</Button>

        {
          isAdmin && this.state.styleNumber != ''
          ?
          <Button negative style={{position: 'relative', bottom: '5px', marginLeft: '30px'}} onClick={this.notUsing}>Mark as NOT USING</Button>
          :
          null
        }

        {
          this.state.saveSuccessVisible
          ?
          <Message
            id="success-alert"
            onDismiss={this.saveSuccessHandleDismiss}
            icon='save'
            header='You have successfully saved the CAD'
            color='blue'
            style={{marginBottom: '35px'}}
          />
          :
          null
        }

        <Table padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Style Number</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>Image Uploaded</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>

              {this.state.showEditStyleNumber ? (
                <Table.Cell>
                  <Input id="edit-style-number-input" style={{width: '350px'}}></Input>&nbsp;&nbsp;&nbsp;<Button id="save-style-number-button" primary size='mini' onClick={this.saveEditStyleNumber}>Save</Button>&nbsp;&nbsp;&nbsp;<Button size='mini' onClick={this.cancelEditStyleNumber}>Cancel</Button>
                </Table.Cell>
              ) : (
                <Table.Cell>
                  {this.state.styleNumber}&nbsp;&nbsp;&nbsp;<Button primary size='mini' onClick={this.showEditStyleNumber} style={{cursor: 'pointer'}}>Edit</Button>
                </Table.Cell>
              )}


              <Table.Cell><div dangerouslySetInnerHTML={{__html: this.state.cadCreateDate}}></div></Table.Cell>
              <Table.Cell>{this.state.cadCreator}</Table.Cell>
              <Table.Cell><div dangerouslySetInnerHTML={{__html: this.state.imageUploadDate}}></div></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <br/><br/>

        <Dimmer active={this.state.loadingCadImage}>
          <Loader />
        </Dimmer>

        {this.state.changingCadImage ? (
          <div>
            <h1>Upload new cad image</h1>
            <Dropzone onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()} id="my-dropzone" >
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        ) : (
          <Image src={image_url} size='big' centered bordered style={{padding: '20px', maxHeight: '600px', width: 'auto', border: '1px solid rgb(225,225,225)', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 10px -2px'}}  />
        )}

        <br/>
        <center>
          {
            this.state.changingCadImage
            ?
            <Button basic onClick={this.cancelChangeCadImage}>Cancel Change Cad Image</Button>
            :
            <Button basic onClick={this.changeCadImage}>Change Cad Image</Button>
          }
        </center>
        <br/>
        <Form onSubmit={this.saveCad}>
          <Form.Group widths='equal'>
            <Form.Select id="division-select" fluid label='Division' onChange={this.divisionOnChange} options={divisions} placeholder='Division' value={this.state.division} />

            <Form.Select id="brand-select" fluid label='Brand' onChange={this.brandOnChange} options={brands} placeholder='Brand' value={this.state.brand} />

            <Form.Select id="category-select" fluid label='Category' onChange={this.categoryOnChange} options={categories} placeholder='Category' value={this.state.parent_category} />

            <Form.Select id="sub-category-select" fluid label='Sub-category' onChange={this.subCategoryOnChange} options={subcategories} placeholder='Sub-category' value={this.state.category} />

          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Select id="color-select" fluid multiple label='Color' onChange={this.colorOnChange} options={colors} placeholder='Color' value={this.state.color} />

            <Form.Select id="fabrication-select" fluid label='Fabrication' onChange={this.fabricationOnChange} options={fabrications} placeholder='Fabrication' value={this.state.fabrication} />

            <Form.Select id="packs-select" fluid label={'Packs'} onChange={this.packsOnChange} options={packs} placeholder='Packs' value={this.state.packs} />

            <Form.Select id="season-select" fluid label='Season' onChange={this.seasonOnChange} options={seasons} placeholder='Season' value={this.state.season} />

          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Input type="type "id="name-input" fluid label='Description' onChange={this.nameOnChange} placeholder='Description' value={this.state.name} />

            <Form.Select id="sizes-select" fluid label='Sizes' onChange={this.sizesOnChange} options={sizeRatioOptions} placeholder='Sizes' value={this.state.sizes} />

            {sub2Options.length > 0 ?
              <Form.Select id="sub2-select" fluid label={this.state.category+'-Category'} onChange={this.sub2OnChange} options={sub2Options} placeholder={this.state.category+'-Category'} value={this.state.sub2} />
              : null
            }
          </Form.Group>

          {
            this.state.thereHasBeenAChange
            ?
            <Form.Button id="my-save-button" primary loading={this.state.loading}>Save Changes</Form.Button>
            :
            null
          }
        </Form>
        <br/><br/><br/><br/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
 ...state
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(cadActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCadPage);
