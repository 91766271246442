import React from 'react'
import {Grid, Form} from 'semantic-ui-react'

const asnInfo = (
  contactInput, factoryInput, costAndAtInput, qtyInput, sizeRatioInput,
  exFactoryDateInput, cancelDateInput, asnXfactoryDateInput, loadingDateInput, fobRtsDateInput
) => {
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Group widths='equal'>
            {contactInput}
          </Form.Group>

          <Form.Group widths='equal'>
            {factoryInput}
          </Form.Group>

          <Form.Group widths='equal' style={{marginTop: '20px'}}>
            {costAndAtInput}
          </Form.Group>

          <Form.Group widths='equal' style={{marginTop: '20px'}}>
            {qtyInput}
          </Form.Group>

          <Form.Group widths='equal' style={{marginTop: '20px'}}>
            {sizeRatioInput('100%')}
          </Form.Group>
        </Grid.Column>
        <Grid.Column>
          {exFactoryDateInput}
          <div style={{marginTop: '15px'}}/>

          {cancelDateInput}
          <div style={{marginTop: '15px'}}/>

          {asnXfactoryDateInput}
          <div style={{marginTop: '15px'}}/>

          {loadingDateInput}
          <div style={{marginTop: '15px'}}/>

          {fobRtsDateInput}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export {asnInfo}
