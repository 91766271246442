import React, {Component} from 'react'
import {Icon, Popup} from 'semantic-ui-react'
import Utils from './../../modules/utils'

let htmlStyle = {width: '350px', marginTop: '10px', paddingTop: '10px', borderTop: '1px solid rgb(220,220,220)'}
let stickyNoteStyle = {color: 'rgb(244,195,86)', marginLeft: '10px', fontSize: '18px'}

class CombinedPopup extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      childPonumbers: [],
      loading: true
    }

    this.handleOpen = this.handleOpen.bind(this)
  }

  handleOpen() {
    let {loading} = this.state
    let {po} = this.props

    if (loading) {
      Utils.get('combined_ponumbers/' + po.id, (err, res) =>
      {
        this.setState({
          childPonumbers: res.data,
          loading: false
        })
      })
    }
  }

  render() {
    let {header, po, isWarehouse} = this.props
    let {loading, childPonumbers} = this.state

    if (isWarehouse || !po.combinedOrder) {
      return null
    } else {

      let myHtml = '<small>The following PO #s are included in this order:</small>'

      if (loading) {
        myHtml += '<br/><center><br/><div class="ui active inline loader"></div><br/><br/></center>'
      } else {
        myHtml += '<br/>' + childPonumbers.join('<br/>')
      }


      return (
        ["<p><br></p>", null, ''].indexOf(myHtml) == -1
        ?
        <Popup
          header={header}
          content={
            <div dangerouslySetInnerHTML={{__html: myHtml}} style={htmlStyle} />
          }
          trigger={
            <Icon name="clone" style={stickyNoteStyle} />
          }
          onOpen={this.handleOpen}
        />
        :
        null
      )
    }
  }
}

export default CombinedPopup
