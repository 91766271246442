import {sortMapOptions} from '../purchase-orders/sort-map-id-name-options.js'

const currentYear = new Date().getFullYear()
const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
const currentMonth = months[new Date().getMonth()]
const futureAtsMonths = []
const diffs = [0, 1]

let el = 'Before ' + currentYear
futureAtsMonths.push(el)

diffs.forEach(addToYear => {
  months.forEach(month => {
    let year = currentYear + addToYear
    let el = month + ' ' + year
    futureAtsMonths.push(el)
  })
})

let year = currentYear + 2
el = months[0] + ' ' + year + '+'
futureAtsMonths.push(el)

const currentMonthYear = currentMonth + ' ' + currentYear
const currentMonthYearIndex = futureAtsMonths.indexOf(currentMonthYear)
const next4Months = futureAtsMonths.slice(currentMonthYearIndex, currentMonthYearIndex+4)

const futureAtsMonthsOptions = futureAtsMonths.map(x => {return ({ key: x, text: x, value: x })})

const checkFutureAtsMonths = (cad_month, cad_year, futureAtsMonth) => {
  let date_is_good
  let parts = futureAtsMonth.split(' ')
  let filter_month = months.findIndex(x => x == parts[0]) + 1

  if (parts[0] == 'Before') {
    let filter_year = Number(parts[1])
    date_is_good = (cad_year < filter_year)
  } else if (parts[1].includes('+')) {
    let filter_year = Number(parts[1].replace('+',''))
    date_is_good = (cad_year >= filter_year)
  } else {
    let filter_year = Number(parts[1])
    date_is_good = cad_month == filter_month && cad_year == filter_year
  }

  return date_is_good
}

export {futureAtsMonthsOptions, checkFutureAtsMonths, next4Months}
