const compareId = (a, b) => {
  if (a.id > b.id) return 1
  if (b.id > a.id) return -1
}

const sortRows = (rows, column, direction) => {
  let result = []
  if (column == 'start_ship_date') {
    result = rows.sort((a, b) => {
      if (a.start_ship_date.value > b.start_ship_date.value) return 1
      if (b.start_ship_date.value > a.start_ship_date.value) return -1

      if (a.start_ship_date.value != null && b.start_ship_date.value == null) return -1
      if (a.start_ship_date.value == null && b.start_ship_date.value != null) return 1

      return compareId(a, b)
    })
  } else if (column == 'cancel_date') {
    result = rows.sort((a, b) => {
      let a_val = a.cancel_date.value
      let b_val = b.cancel_date.value

      if (a_val == '2000-01-01') {a_val = null}
      if (b_val == '2000-01-01') {b_val = null}

      if (a_val > b_val) return 1
      if (b_val > a_val) return -1

      if (a_val != null && b_val == null) return -1
      if (a_val == null && b_val != null) return 1

      return compareId(a, b)
    })
  } else if (column == 'pick_up_date') {
    result = rows.sort((a, b) => {
      if (a.pick_up_date.value > b.pick_up_date.value) return 1
      if (b.pick_up_date.value > a.pick_up_date.value) return -1

      if (a.pick_up_date.value != null && b.pick_up_date.value == null) return -1
      if (a.pick_up_date.value == null && b.pick_up_date.value != null) return 1

      return compareId(a, b)
    })
  } else if (column == 'amount' || column == 'price') {
    result = rows.sort((a, b) => {
      if (Number(a.amount.value) > Number(b.amount.value)) return 1
      if (Number(b.amount.value) > Number(a.amount.value)) return -1

      return compareId(a, b)
    })
  } else if (column == 'ponumber') {
    result = rows.sort((a, b) => {
      if (a.ponumber.value > b.ponumber.value) return 1
      if (b.ponumber.value > a.ponumber.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'routing_number') {
    result = rows.sort((a, b) => {
      if (a.routing_number.value > b.routing_number.value) return 1
      if (b.routing_number.value > a.routing_number.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'customer_order_number') {
    result = rows.sort((a, b) => {
      if (a.customer_order_number.value > b.customer_order_number.value) return 1
      if (b.customer_order_number.value > a.customer_order_number.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'contact_name') {
    result = rows.sort((a, b) => {
      if (a.contact_name.value > b.contact_name.value) return 1
      if (b.contact_name.value > a.contact_name.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'user_name') {
    result = rows.sort((a, b) => {
      if (a.user_name.value > b.user_name.value) return 1
      if (b.user_name.value > a.user_name.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'quantity') {
    result = rows.sort((a, b) => {
      if (a.quantity.value > b.quantity.value) return 1
      if (b.quantity.value > a.quantity.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'status') {
    result = rows.sort((a, b) => {
      if (a.status.value > b.status.value) return 1
      if (b.status.value > a.status.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'created_date') {
    result = rows.sort((a, b) => {
      if (a.created_date.value > b.created_date.value) return 1
      if (b.created_date.value > a.created_date.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'updated_at') {
    result = rows.sort((a, b) => {
      if (a.updated_at.value > b.updated_at.value) return 1
      if (b.updated_at.value > a.updated_at.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'styles') {
    result = rows.sort((a, b) => {
      if (a.styles.value > b.styles.value) return 1
      if (b.styles.value > a.styles.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'account') {
    result = rows.sort((a, b) => {
      if (a.account.value > b.account.value) return 1
      if (b.account.value > a.account.value) return -1

      return compareId(a, b)
    })
  } else if (column == 'vponumber') {
    result = rows.sort((a, b) => {
      let a_val = a.vponumber.value
      let b_val = b.vponumber.value

      if (a_val > b_val) return 1
      if (b_val > a_val) return -1

      if (a_val != null && b_val == null) return -1
      if (a_val == null && b_val != null) return 1

      return compareId(a, b)
    })
  } else if (column == 'ex_factory_date') {
    result = rows.sort((a, b) => {
      let a_val = a.ex_factory_date.value
      let b_val = b.ex_factory_date.value

      if (a_val > b_val) return 1
      if (b_val > a_val) return -1

      if (a_val != null && b_val == null) return -1
      if (a_val == null && b_val != null) return 1

      return compareId(a, b)
    })
  } else if (column == 'shipping_class') {
    result = rows.sort((a, b) => {
      let a_val = a.shipping_class.value
      let b_val = b.shipping_class.value

      if (a_val > b_val) return 1
      if (b_val > a_val) return -1

      if (a_val != null && b_val == null) return -1
      if (a_val == null && b_val != null) return 1

      return compareId(a, b)
    })
  }

  if (direction === 'descending') {
    result = result.reverse()
  }

  window.sortedRows = result

  return result
}

export default sortRows
