import React, {Component} from 'react'
import {Message, Popup} from 'semantic-ui-react'
import ConfirmationPage from '../../ConfirmationPage'

class SuccessMessage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    const {successId} = this.props

    return (
      <Message>
        Your sales order has been uploaded successfully.  REF # &nbsp; {successId.split(',').join(', ')}
        {
          successId.split(',').map((x, i) => {
            return (
              x[0] == 'D'
              ?
              <ConfirmationPage id={x} />
              :
              null
            )
          })
        }
      </Message>
    )
  }
}

export default SuccessMessage
