import {stages, statuses} from './options.js'

const getActiveIndex = (state) => {
  let result = stages.findIndex((stage, index) => {

    if (!!state[stage] && state[stage].length) {
      return !state[stage].every(x => x['status'].includes('Approved'))
    } else {
      return !(!!state[stage] && !!state[stage]['status'] && state[stage]['status'].includes('Approved'))
    }
  })

  if (result == -1) result = stages.length - 1

  return result
}

export {getActiveIndex}
