import {getIsLocked} from '../new-vpo-page/get-is-locked'

const checkIfZeroOrPendingPiSelected = (results) => {
  return results.filter(x => !!window.vpoIds[x['id']]).some(x => x['factory_id'].includes('PENDING PI') || Number(x['price']) == 0)
}

const checkIfQbRowsSelected = () => {
  return !(window.vpoIds.index == null || Object.keys(window.vpoIds.index).length == 0)
}

const checkIfNonPendingRowsSelected = (results) => {
  let haveQbRowsSelected =  checkIfQbRowsSelected()
  if (haveQbRowsSelected) {
    return true
  } else {
    return results.filter(x => !!window.vpoIds[x['id']]).some(x => x['status'] != 'Pending')
  }
}

const checkIfHaveAlreadyPaidRowsSelected = (results) => {
  return results.filter(x => !!window.vpoIds[x['id']]).some(x => x['time_paid'] != null)
}

const checkIfAllPendingProcessedSelected = (results) => {
  return results.filter(x => !!window.vpoIds[x['id']]).every(x => ['Pending', 'Processed'].includes(x['status']))
}

const checkIfNonProcessedRowsSelected = (results) => {
  let haveQbRowsSelected =  checkIfQbRowsSelected()
  if (haveQbRowsSelected) {
    return true
  } else {
    return results.filter(x => !!window.vpoIds[x['id']]).some(x => x['status'] != 'Processed')
  }
}

const checkIfNonQbEnteredRowsSelected = (results) => {
  let haveQbRowsSelected =  checkIfQbRowsSelected()
  if (haveQbRowsSelected) {
    return true
  } else {
    return results.filter(x => !!window.vpoIds[x['id']]).some(x => !['QB-Entered', 'Ready-To-Ship'].includes(x['status']))
  }
}

const checkIfHaveMultipleFactorySelected = (results) => {
  let factories = results.filter(x => !!window.vpoIds[x['id']]).map(x => x['factory_id'])
  let uniqueFactories = [... new Set(factories)]
  return uniqueFactories.length > 1
}

const checkIfAnyLockedSelected = (results) => {
  return results.filter(x => !!window.vpoIds[x['id']]).some(x => getIsLocked(x['status'], x['ex_factory']))
}

export {
  checkIfZeroOrPendingPiSelected,
  checkIfQbRowsSelected, checkIfNonPendingRowsSelected, checkIfHaveAlreadyPaidRowsSelected, checkIfAllPendingProcessedSelected,
  checkIfNonProcessedRowsSelected, checkIfNonQbEnteredRowsSelected,
  checkIfHaveMultipleFactorySelected, checkIfAnyLockedSelected
}
