import $ from 'jquery'
import {orgText} from '../../cads/cache/get'
import {mergeData} from '../../cads/cache/get-shipped'

let openSalesOrderData, archivedSalesOrderData

const getSalesOrdersCache = (orgId, callbackFunc) => {
  let randomNum = Math.floor(Math.random()*99999999).toString()

  let url = 'https://' + process.env.REACT_APP_S3_BUCKET_NAME + '.s3.amazonaws.com/caches/open_sales_orders' + orgText(orgId) + '.json?t=' + randomNum
  let url2 = 'https://' + process.env.REACT_APP_S3_BUCKET_NAME + '.s3.amazonaws.com/caches/archived_sales_orders' + orgText(orgId) + '.json?t=' + randomNum

  $.get(url, function(res) {
    openSalesOrderData = JSON.parse(res).data
    callbackFuncLocal(callbackFunc)
  })

  $.get(url2, function(res) {
    archivedSalesOrderData = JSON.parse(res).data
    callbackFuncLocal(callbackFunc)
  })
}

const callbackFuncLocal = (callbackFunc) => {
  if (openSalesOrderData != null && archivedSalesOrderData != null) {
    callbackFunc(openSalesOrderData.concat(archivedSalesOrderData))
  }
}

export {getSalesOrdersCache}
