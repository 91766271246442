import Utils from '../../modules/utils'

const getPrints = (getPrintsSuccess) => {
  Utils.get('prints', (err, res) =>
  {
    if (err) {
      alert('There was an error when trying to get the prints.')
    } else {
      getPrintsSuccess(res.data)
    }
  })
}

export {getPrints}
