import React, {Component} from 'react'
import {Grid} from 'semantic-ui-react'
import $ from 'jquery'
import {getFamilyId} from './components/generate-style-number/get-family-id'
import {familyIdInput} from './components/generate-style-number/family-id-input'
import {colorInput} from './components/generate-style-number/color-input'
import {categoryInput} from './components/generate-style-number/category-input'
import {packsInput} from './components/generate-style-number/packs-input'
import {divisionInput} from './components/generate-style-number/division-input'
import {brandInput} from './components/generate-style-number/brand-input'
import {brandInputMessage} from './components/generate-style-number/brand-input-message'
import {styleNumberMessage} from './components/generate-style-number/style-number-message'
import {subcategoryInput} from './components/generate-style-number/subcategory-input'
import {applicableSubcategoriesToggle} from './components/generate-style-number/applicable-subcategories-toggle'
import {mexicoCustomer} from './components/generate-style-number/mexico-customer'
import {inputStyleNumberButton} from './components/generate-style-number/input-style-number-button'
import {saveStyleNumber, getSavedStyledNumbers} from './components/generate-style-number/save-style-number'
import {styleNumberTypeRadioSelect} from './components/generate-style-number/prints/style-number-type-radio-select'
import PrintsForm from './components/generate-style-number/prints/form'
import {externalLink} from './components/upload-cad/external-link'
import {formatDate} from './components/cads/format-date'
import GraphicsForm from './components/generate-style-number/graphics/form'

const br = <div><br/><br/><br/></div>

class GenerateStyleNumberPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      familyId: '00000',
      familyIdLoading: false,
      color: '303',
      category: 'D',
      packs: 'A',
      division: 'P = PETS',
      brand: '6 = JASON WU',
      subcategory: 'V = CAVE',
      showApplicableSubcategories: true,
      prefix: '',
      styleNumbers: [],
      styleNumberType: 'Cad'
    }

    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.getFamilyIdSuccess = this.getFamilyIdSuccess.bind(this)
    this.familyIdOnClick = this.familyIdOnClick.bind(this)
    this.toggleApplicable = this.toggleApplicable.bind(this)
    this.togglePrefix = this.togglePrefix.bind(this)
    this.updateStyleNumber = this.updateStyleNumber.bind(this)
    this.saveStyleNumber = this.saveStyleNumber.bind(this)
    this.saveStyleNumberSuccess = this.saveStyleNumberSuccess.bind(this)
    this.getSavedStyledNumbersSuccess = this.getSavedStyledNumbersSuccess.bind(this)
  }

  componentDidMount() {
    this.familyIdOnClick()
    window.scrollTo(0,0)
    getSavedStyledNumbers(this.getSavedStyledNumbersSuccess)
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}
    if (name == 'familyId') {
      value = value.substring(0, 5)
    }

    if (name == 'color') {
      value = value.substring(0, 3)
    }
    newState[name] = value
    this.setState(newState)
  }

  getFamilyIdSuccess(familyId) {
    this.setState({familyId, familyIdLoading: false})
  }

  familyIdOnClick() {
    this.setState({familyIdLoading: true})
    getFamilyId(this.getFamilyIdSuccess)
  }

  toggleApplicable() {
    this.setState({showApplicableSubcategories: !this.state.showApplicableSubcategories})
  }

  togglePrefix(name) {
    let {prefix} = this.state
    prefix = prefix == name ? '' : name
    this.setState({prefix})
  }

  updateStyleNumber(familyId, color, category, packs, division, brand, subcategory, prefix, showApplicableSubcategories) {
    this.setState({
      familyId,
      color,
      category,
      packs,
      division,
      brand,
      subcategory,
      prefix,
      showApplicableSubcategories
    })
  }

  saveStyleNumber() {
    let styleNumber = $('#new-style-number').text().trim()

    if (window.confirm('Are you sure you want to save: ' + styleNumber + ' ?')) {
      saveStyleNumber(styleNumber, this.saveStyleNumberSuccess)
    }

  }

  saveStyleNumberSuccess(styleNumber) {
    alert(styleNumber + ' successfully saved')
    getSavedStyledNumbers(this.getSavedStyledNumbersSuccess)
  }

  getSavedStyledNumbersSuccess(styleNumbers) {
    this.setState({styleNumbers})
  }

  render() {
    let {
      familyId, familyIdLoading, color, category, packs, division, brand, subcategory, showApplicableSubcategories,
      prefix, styleNumbers, styleNumberType
    } = this.state

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>

        {
          styleNumberType == 'Graphic'
          ?
          null
          :
          styleNumberType == 'Print'
          ?
          <div style={{float: 'right'}}>
            {externalLink('https://public.3.basecamp.com/p/SkfEEyXzN6me78eFegrPB6BX','','How to make Print style numbers?')}
          </div>
          :
          inputStyleNumberButton(this.updateStyleNumber, familyIdLoading)
        }

        <h1 style={{marginBottom: '100px'}}>Generate Style Number</h1>

        {styleNumberTypeRadioSelect(styleNumberType, this.fieldOnChange)}

        <PrintsForm show={styleNumberType == 'Print'}/>

        <GraphicsForm show={styleNumberType == 'Graphic'}/>

        {
          ['Print', 'Graphic'].includes(styleNumberType)
          ?
          null
          :
          <React.Fragment>
            {styleNumberMessage(division, familyId, color, brand, category, packs, subcategory, prefix, this.saveStyleNumber)}

            <Grid divided columns={2} style={{marginBottom: '-28px'}}>
              <Grid.Row>
                <Grid.Column style={{paddingLeft: '32px', paddingTop: '25px', paddingBottom: '25px'}}>
                  {mexicoCustomer(this.togglePrefix, prefix)}
                </Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid divided columns={2} style={{fontSize: '120%', marginTop: 0}}>
              <Grid.Row>
                <Grid.Column style={{paddingLeft: '32px', paddingTop: '25px'}}>
                  {familyIdInput(familyId, this.fieldOnChange, familyIdLoading, this.familyIdOnClick)}
                </Grid.Column>
                <Grid.Column style={{paddingBottom: '50px', paddingLeft: '32px', paddingTop: '25px'}}>
                  {colorInput(color, this.fieldOnChange)}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <div style={{borderTop: '1px solid rgb(240,240,240)'}}/>

            <Grid divided columns={2} style={{fontSize: '120%'}}>
              <Grid.Row>
                <Grid.Column style={{paddingTop: '25px', paddingLeft: '32px'}}>
                  {divisionInput(division, this.fieldOnChange)}
                  {br}
                  {subcategoryInput(subcategory, this.fieldOnChange, showApplicableSubcategories, category, division[0], packs)}
                  {applicableSubcategoriesToggle(showApplicableSubcategories, this.toggleApplicable)}
                </Grid.Column>
                <Grid.Column style={{paddingTop: '25px', paddingLeft: '32px', paddingBottom: '50px'}}>
                  {brandInput(brand, this.fieldOnChange)}
                  {brandInputMessage(brand, category, division[0], prefix)}
                  {br}
                  {categoryInput(category, this.fieldOnChange)}
                  {br}
                  {packsInput(packs, this.fieldOnChange)}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <h1>Saved Style Numbers</h1>
            <div style={{maxHeight: '200px', overflowY: 'scroll', display: 'inline-block', paddingRight: '50px'}}>
              {styleNumbers.map(x => <div>{x.name}  <span style={{marginLeft: '20px'}}>{x.userName}</span>   <span style={{marginLeft: '20px'}}>{formatDate(x.createdAt.split('T')[0])}</span></div>)}
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}

export default GenerateStyleNumberPage
