import React from 'react'
import {Popup} from 'semantic-ui-react'
import {removeLeadingZeros} from './remove-leading-zeros.js'

const displayDelivDate = (row) => {
  if (row.updated_eta != null && row.updated_deliv_date == null) {
    return (
      <Popup content={'Updated from container number.  Updated ETA: '+row.updated_eta} position='bottom left' size='mini'
        trigger={
          <div style={{textDecoration: 'line-through'}}>{row.deliv_date}</div>
        }
      />
    )
  } else {
    return (
      row.updated_deliv_date != null
      ?
      <div>
        {
          row.deliv_date != null
          ?
          <div style={{textDecoration: 'line-through'}}>{removeLeadingZeros(row.deliv_date)}</div>
          :
          null
        }
        <Popup content={'Updated from container number.  '+(row.updated_eta == null ? '' : 'Updated ETA: '+row.updated_eta)} position='bottom left' size='mini'
          trigger={
            <b style={{color: 'rgb(88,165,92)'}}>{row.updated_deliv_date}</b>
          }
        />

      </div>
      :
      row.deliv_date == null
      ?
      '—'
      :
      removeLeadingZeros(row.deliv_date)
    )
  }
}

export {displayDelivDate}
