import React, {Component} from 'react'
import {Transition, Grid, Checkbox} from 'semantic-ui-react'
import VendorOrdersDropdown from './vendor-orders-dropdown.js'
import {infoCircle} from '../cads/status-info-circle'
import {removeFlagBulk} from '../new-vpo-page/remove-flag'

const paymentsOptions = [
  {key: 'PAID', value: 'PAID', text: 'PAID'},
  {key: 'UNPAID', value: 'UNPAID', text: 'UNPAID'},
]

class VendorOrdersFilters extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}

    this.fromDateOnChange = this.fromDateOnChange.bind(this)
    this.toDateOnChange = this.toDateOnChange.bind(this)
    this.paymentsOnChange = this.paymentsOnChange.bind(this)
  }

  fromDateOnChange(e) {
    let {value} = e.target
    let name = 'fromDate'

    if (value.trim() == '') {
      value = null
    }

    this.props.fieldOnChange(null, {value, name})
  }

  toDateOnChange(e) {
    let {value} = e.target
    let name = 'toDate'

    if (value.trim() == '') {
      value = null
    }

    this.props.fieldOnChange(null, {value, name})
  }

  paymentsOnChange(event, {value, name}) {
    if (value.length == 2) {
      value = value.slice(-1)
    }

    this.props.fieldOnChange(null, {value, name})
  }

  removeFlag() {
    if (window.numSelected == null || window.numSelected == 0) {
      alert('Select at least 1 item')
    } else {
      removeFlagBulk()
    }
  }

  render() {
    let {
      showFilters, statusOptions, status, payments, factoryOptions, factory, buyerOptions, buyer, fieldOnChange,
      brandOptions, brand, categoryOptions, category, divisionOptions, division, cadLoading,
      toggleShowEditedOn, showEditedOn
    } = this.props

    return (
      <Transition visible={showFilters} animation='fade' duration={showFilters ? 1000 : 500}>
        <div>
          <Grid columns={7}>
            <VendorOrdersDropdown
              placeholder='Status'
              name='status'
              options={statusOptions}
              onChange={fieldOnChange}
              value={status}
              loading={false}
              style={{marginBottom: '23px'}}
            />

            <VendorOrdersDropdown
              placeholder='Factory'
              name='factory'
              options={factoryOptions}
              onChange={fieldOnChange}
              value={factory}
              loading={false}
            />

            <VendorOrdersDropdown
              placeholder='Buyer'
              name='buyer'
              options={buyerOptions}
              onChange={fieldOnChange}
              value={buyer}
              loading={false}
            />

            <VendorOrdersDropdown
              placeholder='Brand'
              name='brand'
              options={brandOptions}
              onChange={fieldOnChange}
              value={brand}
              loading={cadLoading}
            />

            <VendorOrdersDropdown
              placeholder='Division'
              name='division'
              options={divisionOptions}
              onChange={fieldOnChange}
              value={division}
              loading={cadLoading}
            />

            <VendorOrdersDropdown
              placeholder='Category'
              name='category'
              options={categoryOptions}
              onChange={fieldOnChange}
              value={category}
              loading={cadLoading}
            />

            <Grid.Column style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
              <div style={{paddingTop: '5px'}}>
                From <input type="date" onChange={this.fromDateOnChange} />
              </div>
              <div style={{paddingTop: '5px'}}>
                To <input type="date" onChange={this.toDateOnChange} />
              </div>
              {infoCircle('Date filters use Ex-Factory Date')}
            </Grid.Column>
          </Grid>
          <Grid columns={7} style={{marginTop: '-25px'}}>
            <VendorOrdersDropdown
              placeholder='Payments'
              name='payments'
              options={paymentsOptions}
              onChange={this.paymentsOnChange}
              value={payments}
              loading={false}
              style={{marginBottom: '23px'}}
            />
            <Grid.Column style={{whiteSpace: 'nowrap'}}>
              <Checkbox toggle label='Show Recently Edited' onChange={toggleShowEditedOn} checked={showEditedOn} />

              {
                showEditedOn
                ?
                <button style={{border: '1px solid red', fontSize: '10px', background: 'red', color: 'white', padding: '0px 3px', cursor: 'pointer', borderRadius: '5px', marginLeft: '5px'}} onClick={this.removeFlag}>remove flag</button>
                :
                null
              }
            </Grid.Column>
          </Grid>
        </div>
      </Transition>
    )
  }
}

export default VendorOrdersFilters
