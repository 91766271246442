import React, { Component } from 'react'
import $ from 'jquery'
import { Accordion, Form, Input, Popup, Button, Icon, Transition, Grid } from 'semantic-ui-react'
import './ExtraFields.css'
import ReactQuill from 'react-quill'
import { DateInput } from 'semantic-ui-calendar-react'
import Dropzone from 'react-dropzone'
import {ORDER_TERMS} from '../../modules/constants'

class ExtraFields extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: []
    }

    this.handleTitleClick = this.handleTitleClick.bind(this)
  }

  componentDidMount() {}

  componentDidUpdate() {}

  handleTitleClick = (e, itemProps) => {
    const { index } = itemProps
    let { activeIndex } = this.state

    let exists = activeIndex.indexOf(index) > -1
    if (exists) {
      activeIndex = activeIndex.filter((x) => {return x != index})
    } else {
      activeIndex.push(index)
    }

    this.setState({ activeIndex })
  }

  render() {
    let {activeIndex} = this.state
    const panels = []
    let currentIndex = 0


    panels.push({
      key: 'aged',
      title: 'Aged',
      content: {
        content: (
          <div>
            <Form>
              <Form.Group inline>
                <Form.Radio
                  name='aged'
                  label='No'
                  value='No'
                  checked={this.props.aged === 'No'}
                  onChange={this.props.agedOnChange}
                />
                <Form.Radio
                  name='aged'
                  label='Yes'
                  value='Yes'
                  checked={this.props.aged === 'Yes'}
                  onChange={this.props.agedOnChange}
                />
              </Form.Group>
              {
                !(['No', '', null].includes(this.props.aged)) &&
                <Form.Input
                  fluid
                  name='agedStyleNumber'
                  label='Aged style number'
                  placeholder='Aged style number'
                  value={this.props.agedStyleNumber}
                  onChange={this.props.agedStyleNumberOnChange}
                  required
                  form="new-sales-order"
                />
              }
            </Form>
          </div>
        ),
      },
    })
    if (this.props.aged === 'Yes' && activeIndex.indexOf(currentIndex) === -1) {
      activeIndex.push(currentIndex)
    }
    currentIndex += 1





    panels.push({
      key: 'shipping_class',
      title: 'Class',
      content: {
        content: (
          <div>
            <Form>
              <Form.Group inline>
                <Form.Radio
                  name='shipping_class'
                  label='No'
                  value='No'
                  checked={this.props.shippingClass === 'No'}
                  onChange={this.props.shippingClassOnChange}
                />
                <Form.Radio
                  name='shipping_class'
                  label='FOB China'
                  value='FOB China'
                  checked={this.props.shippingClass === 'FOB China'}
                  onChange={this.props.shippingClassOnChange}
                />
                <Form.Radio
                  name='shipping_class'
                  label='FOB NY'
                  value='FOB NY'
                  checked={this.props.shippingClass === 'FOB NY'}
                  onChange={this.props.shippingClassOnChange}
                />
                <Form.Radio
                  name='shipping_class'
                  label='POE'
                  value='POE'
                  checked={this.props.shippingClass === 'POE'}
                  onChange={this.props.shippingClassOnChange}
                />
              </Form.Group>
            </Form>
          </div>
        ),
      },
    })
    if (this.props.shippingClass != '' && this.props.shippingClass != null && activeIndex.indexOf(currentIndex) === -1) {
      activeIndex.push(currentIndex)
    }
    currentIndex += 1





    panels.push({
      key: 'department_number',
      title: 'Department number',
      content: {
        content: (
          <div>
            <Form>
              <Form.Input
                fluid
                name='department_number'
                label='Department number'
                placeholder='Department number'
                value={this.props.departmentNumber}
                onChange={this.props.departmentNumberOnChange}
                form="new-sales-order"
              />
            </Form>
          </div>
        ),
      },
    })
    if (this.props.departmentNumber != '' && this.props.departmentNumber != null && activeIndex.indexOf(currentIndex) === -1) {
      activeIndex.push(currentIndex)
    }
    currentIndex += 1



    if (this.props.addDistFile != null) {
      const dropzoneContent = (
        <div>
          <div style={{padding: '10px'}}><b>Accepted file types:</b> image, microsoft word, excel, pdf</div>
          <Dropzone onDrop={acceptedFiles => this.props.handleAcceptedFiles(acceptedFiles, true)}>
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} id="my-dropzone" >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop distro file here, or click to select file</p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      )

      panels.push({
        key: 'distribution_file',
        title: 'Distro file',
        content: {
          content: (
            <div>
              {
                this.props.distFilename == null ?
                dropzoneContent
                :
                <div>
                  <ul>
                    {this.props.distFilename.split('@@@').map((filename) => {
                      return (
                        <li style={{fontSize: '150%', marginBottom: '10px'}}>
                          {filename.substring(43)}
                          {this.props.distFilenameSaved.indexOf(filename) > -1 ?
                            <Popup content='Download file' trigger={<Button onClick={this.props.downloadDistFile.bind(null, filename)} color='green' basic icon='download' style={{marginLeft: '10px', padding: '5px'}}/>} />
                          : null}
                          <Popup content='Remove file' trigger={<Button onClick={this.props.removeDistFile.bind(null, filename)} color='red' basic icon='trash' style={{marginLeft: '10px', padding: '5px'}}/>} />
                        </li>
                      )
                    })}

                  </ul>
                  {
                    this.props.addingDistFile
                    ?
                    dropzoneContent
                    :
                    <div style={{paddingTop: '10px', paddingLeft: '12px'}}>
                      <Popup content='Add file' trigger={<Button onClick={this.props.addDistFile} basic size="mini" style={{paddingRight: '8px'}}><Icon name="plus"/></Button>} />
                    </div>
                  }
                </div>
              }
            </div>
          ),
        },
      })
      if (this.props.distFilename != null && activeIndex.indexOf(currentIndex) === -1) {
        activeIndex.push(currentIndex)
      }


      currentIndex += 1
    }



    panels.push({
      key: 'hangers',
      title: 'Hangers',
      content: {
        content: (
          <div>
            <Form>
              <Form.Group inline>
                <Form.Radio
                  name='hangers'
                  label='No'
                  value='No'
                  checked={this.props.hangers === 'No'}
                  onChange={this.props.hangersOnChange}
                />
                <Form.Radio
                  name='hangers'
                  label='Yes'
                  value='Yes'
                  checked={this.props.hangers === 'Yes'}
                  onChange={this.props.hangersOnChange}
                />
              </Form.Group>
            </Form>
          </div>
        ),
      },
    })
    if (this.props.hangers === 'Yes' && activeIndex.indexOf(currentIndex) === -1) {
      activeIndex.push(currentIndex)
    }


    currentIndex += 1


    // if (this.props.richTextMessageHtmlOnchange != null) {
    //   panels.push({
    //     key: 'message',
    //     title: 'Message',
    //     content: {
    //       content: (
    //         <div>
    //           <ReactQuill
    //             value={this.props.richTextMessageHtml}
    //             onChange={this.props.richTextMessageHtmlOnchange}
    //           />
    //         </div>
    //       ),
    //     },
    //   })
    //   if (['<p><br></p>', '', '<p></p>', null].indexOf(this.props.richTextMessageHtml) === -1 && activeIndex.indexOf(currentIndex) === -1) {
    //     activeIndex.push(currentIndex)
    //   }
    //
    //   currentIndex += 1
    // }



    panels.push({
      key: 'pp_samples_needed',
      title: 'PP samples needed',
      content: {
        content: (
          <div>
            <Form>
              <Form.Group inline>
                <Form.Radio
                  name='pp_samples_needed'
                  label='No'
                  value='No'
                  checked={this.props.ppSamplesNeeded === 'No'}
                  onChange={this.props.ppSamplesNeededOnChange}
                />
                <Form.Radio
                  name='pp_samples_needed'
                  label='Yes'
                  value='Yes'
                  checked={this.props.ppSamplesNeeded === 'Yes'}
                  onChange={this.props.ppSamplesNeededOnChange}
                />
              </Form.Group>
              {
                !(['No', '', null].includes(this.props.ppSamplesNeeded)) &&
                <DateInput
                    value={this.props.ppSamplesNeededDue}
                    onChange={this.props.ppSamplesNeededDueOnChange}
                    placeholder='PP samples due date'
                    iconPosition="left"
                    dateFormat="MM/DD/YYYY"
                    form="new-sales-order"
                    required
                    autocomplete="off"
                    style={{width: '340px'}}
                    duration={1}
                    animation={'none'}
                  />
              }
            </Form>
          </div>
        ),
      },
    })
    //minDate={initialStartShipDate[key]}
    //initialDate={initialStartShipDate[key]}
    if (this.props.ppSamplesNeeded === 'Yes' && activeIndex.indexOf(currentIndex) === -1) {
      activeIndex.push(currentIndex)
    }


    currentIndex += 1


    panels.push({
      key: 'reorder',
      title: 'Reorder',
      content: {
        content: (
          <div>
            <Form>
              <Form.Group inline>
                <Form.Radio
                  name='reorder'
                  label='No'
                  value='No'
                  checked={this.props.reorder === 'No'}
                  onChange={this.props.reorderOnChange}
                />
                <Form.Radio
                  name='reorder'
                  label='Yes'
                  value='Yes'
                  checked={this.props.reorder === 'Yes'}
                  onChange={this.props.reorderOnChange}
                />
              </Form.Group>
            </Form>
          </div>
        ),
      },
    })
    if (this.props.reorder === 'Yes' && activeIndex.indexOf(currentIndex) === -1) {
      activeIndex.push(currentIndex)
    }


    currentIndex += 1



    panels.push({
      key: 'terms',
      title: 'Terms',
      content: {
        content: (
          <div>
            <Form>
              <label for="extra-fields-terms" style={{fontWeight: 'bold'}}>
                Terms
              </label>
              <select
                id="extra-fields-terms"
                name="terms"
                placeholder='Terms'
                value={this.props.terms}
                onChange={this.props.termsOnChange}
                form="new-sales-order"
                style={{width: '70%', fontSize: '18px'}}
              >
                {
                  ORDER_TERMS.map(
                    element => <option value={element}>{element}</option>
                  )
                }
                {
                  ORDER_TERMS.includes(this.props.terms) || this.props.terms == ''
                  ?
                  null
                  :
                  <option value={this.props.terms}>{this.props.terms}</option>
                }
              </select>
              {
                ORDER_TERMS.includes(this.props.terms) || this.props.terms == ''
                ?
                null
                :
                <div style={{color: 'red', fontWeight: 'bold'}}>
                  <Icon name='warning sign'/> Value not found in list!
                </div>
              }
            </Form>
          </div>
        ),
      },
    })
    if (this.props.terms != '' && this.props.terms != null && activeIndex.indexOf(currentIndex) === -1) {
      activeIndex.push(currentIndex)
    }
    currentIndex += 1



    panels.push({
      key: 'top_samples_needed',
      title: 'Top samples needed',
      content: {
        content: (
          <div>
            <Form>
              <Form.Group inline>
                <Form.Radio
                  name='top_samples_needed'
                  label='No'
                  value='No'
                  checked={this.props.topSamplesNeeded === 'No'}
                  onChange={this.props.topSamplesNeededOnChange}
                />
                <Form.Radio
                  name='top_samples_needed'
                  label='Yes'
                  value='Yes'
                  checked={this.props.topSamplesNeeded === 'Yes'}
                  onChange={this.props.topSamplesNeededOnChange}
                />
              </Form.Group>
              {
                !(['No', '', null].includes(this.props.topSamplesNeeded)) &&
                <DateInput
                    value={this.props.topSamplesNeededDue}
                    onChange={this.props.topSamplesNeededDueOnChange}
                    placeholder='Top samples due date'
                    iconPosition="left"
                    dateFormat="MM/DD/YYYY"
                    form="new-sales-order"
                    required
                    autocomplete="off"
                    style={{width: '340px'}}
                    duration={1}
                    animation={'none'}
                  />
              }
            </Form>
          </div>
        ),
      },
    })
    //minDate={initialStartShipDate[key]}
    //initialDate={initialStartShipDate[key]}
    if (this.props.topSamplesNeeded === 'Yes' && activeIndex.indexOf(currentIndex) === -1) {
      activeIndex.push(currentIndex)
    }


    return (
      <div>
        <Accordion activeIndex={activeIndex} id="extra-fields-accordion" panels={panels} exclusive={false}
          onTitleClick={this.handleTitleClick} />
      </div>
    )
  }
}

export default ExtraFields
