import React from 'react'
import {Checkbox} from 'semantic-ui-react'

const mexicoCustomer = (togglePrefix, value) => {
  return (
    <React.Fragment>
      <Checkbox label='M = Mexico Customer' style={{zoom: '120%'}}
        onClick={() => {togglePrefix('M = Mexico Customer')}} checked={value == 'M = Mexico Customer'}
      />
      <br/><br/>
      <Checkbox label='Q = Gift with Purchase' style={{zoom: '120%'}}
        onClick={() => {togglePrefix('Q = Gift with Purchase')}} checked={value == 'Q = Gift with Purchase'}
      />
    </React.Fragment>
  )
}

export {mexicoCustomer}
