const pendingPiStyle = {
  color: 'rgb(242, 160, 128)',
  border: '1px solid rgb(212, 212, 212)',
  borderRadius: '8px',
  padding: '1px 4px',
  marginTop: '2px',
  display: 'inline-block',
  marginLeft: '2px'
}

export {pendingPiStyle}
