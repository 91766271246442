import {processText} from '../new-vpo-page/select-cads/process-text.js'

const getPrintType = (styleNumber) => {
  return styleNumber[0] == 'G' ? 'GRAPHICS' : 'PRINT'
}

const filterPrints = (myState, usingFilters) => {
  let {prints, searchString, color, pattern, season, printType} = myState
  let filteredPrints = [], searchStrings = []

  if (usingFilters) {
    filteredPrints = prints.slice()

    if (searchString != '') {
      searchStrings = processText(searchString)
      filteredPrints = filteredPrints.filter(x => searchStrings.includes(x.styleNumber))
    }

    if (color.length) {
      filteredPrints = filteredPrints.filter(x => color.includes(x.color))
    }

    if (pattern.length) {
      filteredPrints = filteredPrints.filter(x => pattern.includes(x.pattern))
    }

    if (season.length) {
      filteredPrints = filteredPrints.filter(x => season.includes(x.season))
    }

    if (printType.length) {
      filteredPrints = filteredPrints.filter(x => printType.includes(getPrintType(x.styleNumber)))
    }
  }

  let statuses = []
  let duplicates = {}
  searchStrings.forEach((element, index) => {
    if (element == '') {
      statuses.push(null)
    } else {
      if (duplicates[element]) {
        statuses.push('duplicate')
      } else {
        if (prints.some(x => element == x.styleNumber)) {
          statuses.push('found')
        } else {
          statuses.push('not found')
        }

        duplicates[element] = true
      }
    }
  })

  return {statuses, filteredPrints}
}

const getFilterTotals = (prints) => {
  let fields = ['color', 'pattern', 'season']
  let totals = {}

  fields.forEach((field) => {
    totals[field] = {}
    prints.forEach((print) => {
      if (totals[field][print[field]] == null) {
        totals[field][print[field]] = 1
      } else {
        totals[field][print[field]] += 1
      }
    })
  })

  return totals
}

export {filterPrints, getFilterTotals, getPrintType}
