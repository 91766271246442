import                           "./index.css"
import React, { Component }from "react"
import DataList            from './modules/DataList.jsx';
import Utils               from '../../../modules/utils'
import {LoadingParagraph}  from '../../ui/loading-paragraph';
import {Auth0Context}      from "./../../../contexts/auth-context"
import {filter, map}       from 'lodash'
import { Label, Header, Loader, Modal, Dimmer }           from 'semantic-ui-react';
import CustomDateString    from './../../shared/custom-date-string'
import MakeNote          from "../account-form/make-note"

export class AccountsList extends Component
{
  static contextType = Auth0Context;

  constructor(props)
  {
    super(props)
    this.state = {
      account: null,
      accounts: [],
      accountsList: [],
      contactPerosnTitles: [],
      headings: Utils.getStore('account-table-headings') || [
        {
          key: "account_name",
          visible: true,
          displayValue: "ACCOUNT"
        },
        {
          key: "primary_contact_name",
          visible: true,
          displayValue: "CONTACT"
        },
        {
          key: "status",
          visible: true,
          displayValue: "STATUS"
        },
        {
          key: "channelDistribution",
          visible: true,
          displayValue: "CHANNEL"
        },
        {
          key: "region",
          visible: true,
          displayValue: "REGION"
        },
        {
          key: "category",
          visible: true,
          displayValue: "CATEGORY"
        },
        {
          key: "subcategory",
          visible: true,
          displayValue: "SUB-CATEGORY"
        },
        {
          key: "mobile",
          visible: true,
          displayValue: "PHONE"
        },
        {
          key: "notes",
          visible: true,
          displayValue: "NOTES"
        },
        {
          key: "title",
          visible: false,
          displayValue: "Title"
        },
        {
          key: "email",
          visible: false,
          displayValue: "Email"
        },
        {
          key: "extra_contact_persons",
          visible: false,
          displayValue: "Extra Contact Persons"
        },
        {
          key: "last_activity_date",
          visible: false,
          displayValue: "Last Activity Date"
        },
        {
          key: "account_rep",
          visible: false,
          displayValue: "Account Rep"
        },
        {
          key: "brand",
          visible: false,
          displayValue: "Brand"
        },
        {
          key: "division",
          visible: false,
          displayValue: "Division"
        },
        {
          key: "created_at",
          visible: false,
          displayValue: "Created Date"
        },
      ]
    }
  }

  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0)
  }

  loadData = () => {
    this.setState({ loading: true });
    Utils.get("accounts", (error, response) =>
    {
      if (error)
      {
        console.log("error in account post ", error)
      }
      else
      {
        let {data} = response;
        let {accounts} = data;
        this.setState({ accounts, totalCount: accounts.length, loading: false });
      }
      this.setState({ loading: false })
    })
  };

  getStatusMarkup = (status) => {
    let color = 'grey';

    switch(status)
    {
      case "active" :
      {
        color = 'green';
        break;
      }
      case "review" :
      case "in review" :
      {
        color = 'black';
        break;
      }
      case "pending" :
      {
        color = 'blue';
        break;
      }
      case "inactive" :
      {
        color = 'red';
        break;
      }
    }

    return <Label color={color} content={status.toUpperCase()}/>
  };

  queryFilter = (data, queryString) => {
    let filterMethod = (_object) => {
      let valueFound = false;
      this.state.headings.forEach((item) => {
        if (_object[item.key].value && _object[item.key].value.toString().toLowerCase().includes(queryString.toLowerCase())){
          valueFound = true;
        }
      })
      return valueFound;
    }
    return filter(data, filterMethod);
  };


  filterRows = (data, columnFilters) => {
    let oneToOneFilters = {}
    let {account, title, region, channel, status, division, category, accountRep} = columnFilters

    if (account && account !== 'all')
    {
      oneToOneFilters.account_name = {value: account}
    }

    if (title && title !== 'all')
    {
      oneToOneFilters.title = {value: title}
    }

    if (region && region !== 'all')
    {
      oneToOneFilters.region = {value: region}
    }

    if (channel && channel !== 'all')
    {
      oneToOneFilters.channelDistribution = {value: channel}
    }

    data = filter(data, oneToOneFilters);

    var filterMethod = function(_object) {
      let valueFound = true;
      if (status && status !== 'all')
      {
        let _values = status.split("+").map((o)=>o.trim().toLowerCase())
        valueFound = valueFound && _values.includes(_object.status.value.toLowerCase());
      }
      if (division && division !== 'all')
      {
        let _values = _object.division.value.split(',').map((o)=>o.trim())
        valueFound = valueFound && _values.includes(division);
      }
      if (category && category !== 'all')
      {
        let _values = _object.category.value.split(',').map((o)=>o.trim())
        valueFound = valueFound && _values.includes(category);
      }
      if (accountRep && accountRep !== 'all')
      {
        let _values = _object.account_rep.value.split(',').map((o)=>o.trim())
        valueFound = valueFound && _values.includes(accountRep);
      }

      return valueFound;
    }

    data = filter(data, filterMethod);

    return data;
  }

  render() {
    let {accounts, loading} = this.state;
    const {customer} = this.context;
    const categories = customer ? customer.categories : []
    const subcategories = customer ? customer.subcategories : []
    const brands = customer ? customer.brands : []
    const divisions = customer ? customer.divisions : []
    const headings = this.state.headings;

    let rows = accounts.length > 0 ? accounts.map((result, i) =>
    {
      let categoryIds    = result.category.split(",")
      let category       = filter(categories, function(input) { return categoryIds.includes(String(input.id)) });
      category           = map(category, 'name').join(', ')

      let subcategoryIds = result.subcategory.split(",")
      let subcategory    = filter(subcategories, function(input) { return subcategoryIds.includes(String(input.id)) });
      subcategory        = map(subcategory, 'name').join(', ')

      let brandIds = result.brand.split(",")
      let brand    = filter(brands, function(input) { return brandIds.includes(String(input.id)) });
      brand        = map(brand, 'name').join(', ')

      let divisionIds = result.division.split(",")
      let division    = filter(divisions, function(input) { return divisionIds.includes(String(input.id)) });
      division        = map(division, 'name').join(', ')

      let cells =
      {
        "account_name" : {
          value: result.company.name,
          displayValue: [
                          <span key='c-name'>{result.company.name}</span>,
                          <div key={`unapproved`} className='unapproved-brands'>
                            {
                              result.unapprovedBrandNames.map((brand, brandIndex)=>(
                                <div style={{color: 'red', fontSize: '12px', fontWeight: 'bold'}} key={`brand-${brandIndex}`}>Not approved for {brand}</div>
                              ))
                            }
                          </div>
                         ]
        },
        "status" : {
          value: result.status,
          displayValue: this.getStatusMarkup(result.status)
        },
        "channelDistribution" : {
          value: result.channelDistribution,
          displayValue: result.channelDistribution
        },
        "region" : {
          value: result.region,
          displayValue: result.region
        },
        "category" : {
          value: category,
          displayValue: category
        },
        "subcategory" : {
          value: subcategory,
          displayValue: subcategory
        },
        "mobile" : {
          value: (result.primaryContact == null ? '—' : result.primaryContact.phone),
          displayValue: (result.primaryContact == null ? '—' : result.primaryContact.phone)
        },

        "notes" : {
          value: null,
          displayValue: <Label style={{cursor: 'pointer'}} color={'blue'} content={"Add Note"} onClick={()=>this.setState({account: accounts[i]})} />
        },

        "primary_contact_name" : {
          value: (result.primaryContact == null ? '—' : result.primaryContact.name),
          displayValue: (result.primaryContact == null ? '—' : result.primaryContact.name)
        },

        "title" : {
          value: (result.primaryContact == null ? '—' : result.primaryContact.title),
          displayValue: (result.primaryContact == null ? '—' : result.primaryContact.title)
        },

        "email" : {
          value: (result.primaryContact == null ? '—' : result.primaryContact.email),
          displayValue: (result.primaryContact == null ? '—' : result.primaryContact.email)
        },

        "extra_contact_persons" : {
          value: result.contactPeople.map((person)=> `${person.name}`).join(', '),
          displayValue: result.contactPeople.map((person)=> `${person.name}`).join(', ')
        },

        "last_activity_date" : {
          value: Utils.formatDate(result.lastActivityAt),
          displayValue: [
                          <CustomDateString
                            dateString={result.lastActivityAt}
                            key='date-string'
                          />,
                          <span key={`activity-mode-${i}`}> &mdash; {result.lastActivityModel}</span>
                         ]
        },

        "account_rep" : {
          value: result.reps.map((rep)=> `${rep.name}`).join(', '),
          displayValue: result.reps.map((rep)=> `${rep.name}`).join(', ')
        },

        "brand" : {
          value: brand,
          displayValue: brand
        },

        "division" : {
          value: division,
          displayValue: division
        },

        "created_at" : {
          value: Utils.formatDate(result.createdAt),
          displayValue: Utils.formatDate(result.createdAt)
        },

        _data: {
          id: result.id,
          needsUpdate: result.needsUpdate
        }

      }

      return cells

    }) : []

    return (
      loading
      ?
      <Dimmer active>
        <Loader size='massive'>Loading</Loader>
      </Dimmer>
      :
      <div className='accounts-index' style={{padding: '50px 60px'}}>
      <Modal
        closeIcon
        onClose={()=>this.setState({account: null})}
        open={this.state.account}
      >
        <Modal.Header>Add Note</Modal.Header>
        <div className="add-note-modal">
          <MakeNote
            account={this.state.account}
            onChange={()=>{}}
            setFlashMessage={this.context.setMessage}
            onClose={()=>this.setState({account: null})}
          />
        </div>
      </Modal>
        {
          loading
          ?
          <Header
          as='h1'
          content={<span>Loading...<Loader active style={{position: 'relative', display: 'inline-block', left: '100px', bottom: '-20px'}}/></span>}
          style={{marginTop: '25px', marginBottom: '40px'}}
          />
          :
          <DataList
          headings={headings}
          columns={rows}
          noContentMessage='No Contacts found.'
          sortColumn="last_activity_date"
          queryFilter={this.queryFilter}
          filterRows={this.filterRows}
          />
        }
      </div>
    )
  }
}

export default AccountsList
