import React, {Component} from 'react'
import {dateInputStyle} from '../edit-sales-order/date-inputs.js'
import {dateLabel} from '../new-vpo-page/cad-details-section/date-label.js'
import {arrayToOptions} from '../reports/array-to-options.js'
import {Dropdown} from 'semantic-ui-react'
import {getDates} from './date-inputs/range'

const mainDivStyle = {
  boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
  borderRadius: '3px',
  padding: '5px 10px',
  marginTop: '21px',
  paddingBottom: '10px',
  width: '211px',
  float: 'right'
}

const dateRanges = ['YTD', 'MTD', 'Q1', 'Q2', 'Q3', 'Q4', 'Last Year']

const clearText = 'CLEAR'

let defaultDateFrom = '2022-01-01'
let defaultDateTo = '2023-12-31'

class DateInputs extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      range: null,
      from: defaultDateFrom,
      to: defaultDateTo
    }

    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.dateFieldOnChange = this.dateFieldOnChange.bind(this)
  }

  fieldOnChange(event, {value, name}) {
    let newState = getDates(value)
    if (value == clearText) {
      value = null
    }
    newState[name] = value
    this.setState(newState)
  }

  dateFieldOnChange(event) {
    let {value, name} = event.currentTarget
    let newState = {range: null}
    newState[name] = value
    this.setState(newState)
  }

  render() {
    let {range, from, to} = this.state

    const dateRangeOptions = arrayToOptions(dateRanges)
    if (range != null) {
      dateRangeOptions.unshift({ key: clearText, text: clearText, value: clearText })
    }

    return (
      <div style={mainDivStyle}>
        <center>Select Dates</center>
        {dateLabel('Range', {}, range == null)}
        <div style={{width: '190px'}}>
          <Dropdown placeholder='Range' fluid selection search options={dateRangeOptions}
            onChange={this.fieldOnChange} name='range' value={range}
          />
        </div>
        {dateLabel('From')}
        <input type="date" onChange={this.dateFieldOnChange} name='from' style={dateInputStyle}
          value={from}
        />
        {dateLabel('To')}
        <input type="date" onChange={this.dateFieldOnChange} name='to' style={dateInputStyle}
          value={to}
        />
      </div>
    )
  }
}

export default DateInputs
