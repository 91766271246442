import {patternOptions, seasonOptions} from '../generate-style-number/prints/options'

const colors = [
  'BLACK',
  'GRAY',
  'HEATHERED GRAY',
  'WHITE',
  'IVORY',
  'BROWN',
  'BEIGE',
  'GREEN',
  'BLUE',
  'PURPLE',
  'RED',
  'PINK',
  'YELLOW',
  'ORANGE',
  'CORAL',
  'WHITE',
  'PINK'
]

const patterns = patternOptions.map(x => x.key.split(' = ')[1])
const seasons = seasonOptions.map(x => x.key.split(' = ')[1])

const printTypeOptions = [
  {key: 'PRINT', value: 'PRINT', text: 'PRINT'},
  {key: 'GRAPHICS', value: 'GRAPHICS', text: 'GRAPHICS'}
]

export {colors, patterns, seasons, printTypeOptions}
