import React, { Component }                                           from 'react'
import { Label, Form, Message, Loader, TextArea, Input, Button, Icon }from 'semantic-ui-react'
import AccountAPI                                                     from '../../../modules/api/account_api.js'
import UserAPI                                                        from '../../../modules/api/user_api'
import Utils                                                          from '../../../modules/utils'
import { ConfirmAlert }                                               from '../../ui/confirm-alert'
import { Auth0Context }                                               from "../../../contexts/auth-context.js"
import DatePicker                                                     from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Event extends Component
{
  static contextType = Auth0Context;

  state = {
    inputs               : {...this.getInputsInitialState()},
    account              : this.props.account || null,
    loading              : false,
    fetchLoading         : true,
    selectedEvent        : null,
    events               : [],
    reps                 : [],
    repOptions           : [],
    completeConfirmation : false,
    selectedEvent         : null,
  }

  getInputsInitialState(){
    return  {
      title :
      {
        value    : "",
        valid    : null,
        required : true,
        message  : ""
      },

      description :
      {
        value    : "",
        valid    : null,
        required : false,
        message  : ""
      },

      location :
      {
        value    : "",
        valid    : null,
        required : true,
        message  : ""
      },

      startTime :
      {
        value    : "",
        valid    : null,
        required : true,
        message  : ""
      },

      endTime :
      {
        value    : "",
        valid    : null,
        required : true,
        message  : ""
      },

      invitees :
      {
        value    : [],
        valid    : null,
        required : true,
        message  : ""
      },
    }
  }

  componentDidMount()
  {
    this.fetchEvents()
    this.fetchReps()
  }

  fetchReps = () =>
  {
    var {reps, repOptions, inputs} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        reps = data.reps;
        var keys       = {text: ['name'], value: 'id'}
        repOptions     =  Utils.toOptionsHashWithKeys(reps, keys)
        inputs.invitees.value = []
        inputs.invitees.valid = null
        this.setState({ reps, repOptions, inputs })
      }
    }

    UserAPI.getAllReps(callback)
  }

  fetchEvents = () =>
  {
    var {account} = this.state;

    var callback = (error, events) =>
    {
      if (error)
      {
        console.error(error);
      }
      else
      {
        this.setState({ fetchLoading: false, events })
      }
    }

    AccountAPI.getEvents(account.id, callback)
  }

  handleChange = (e, data) =>
  {
    let {name, value} = data;
    let {inputs}      = this.state;
    let input         = inputs[name]

    switch(name)
    {
      case "description" :
      case "title" :
      case "location" :
      {
        input.value = value;
        input.message = '';
        if (input.required && typeof(input.value) === 'string' && input.value.trim().length === 0)
        {
          input.message = "Please enter the value.";
        }
        input.valid = !input.message;
        break;
      }
      case "invitees" :
      {
        input.value = value;
        input.message = '';
        if (input.value.length === 0)
        {
          input.message = "Please select at least one invitee.";
        }
        input.valid = !input.message;
        break;
      }
      case "startTime" :
      {
        let startTimeInput     = input;
        startTimeInput.value   = value;
        startTimeInput.valid   = !!startTimeInput.value;
        startTimeInput.message = startTimeInput.valid ? '' : "Please select a valid date time."

        let endTimeInput       = inputs.endTime
        endTimeInput.value     = endTimeInput.value;
        endTimeInput.valid     = !!endTimeInput.value;
        endTimeInput.message   = endTimeInput.valid ? '' : "Please select a valid date time."

        break;
      }
      case "endTime" :
      {
        let endTimeInput       = input;
        endTimeInput.value     = value;
        endTimeInput.valid     = !!endTimeInput.value;
        endTimeInput.message   = endTimeInput.valid ? '' : "Please select a valid date time."

        let startTimeInput     = inputs.startTime
        startTimeInput.value   = startTimeInput.value;
        startTimeInput.valid   = !!startTimeInput.value;
        startTimeInput.message = startTimeInput.valid ? '' : "Please select a valid date time."

        break;
      }

      default :
      {

      }
    }

    if (name === 'startTime' || name === 'endTime')
    {
      let startTimeInput = inputs.startTime
      let endTimeInput   = inputs.endTime

      if (startTimeInput.value && endTimeInput.value)
      {
        if (endTimeInput.value < startTimeInput.value)
        {
          endTimeInput.valid     = false;
          endTimeInput.message   = "End date time cannot be less than start date time"
        }

        if (startTimeInput.value > endTimeInput.value)
        {
          startTimeInput.valid     = false;
          startTimeInput.message   = "Start date time cannot be greater than end date time"
        }
      }
    }
    this.setState({inputs})
  }

  getData = () =>
  {
    let { inputs }   = this.state;
    let data         = {};

    data.description = inputs.description.value;
    data.title       = inputs.title.value;
    data.location    = inputs.location.value;
    data.startTime   = inputs.startTime.value;
    data.endTime     = inputs.endTime.value;
    data.invitees    = inputs.invitees.value;

    return data;
  }

  handleSubmit = () => {
    let { events, account } = this.state;
    let eventData           = this.getData();

    this.setState({ loading: true })
    if (account)
    {
      let data = { event: {...eventData} }
      AccountAPI.createEvent(account.id, data, (error, event) =>
      {
        if (error)
        {
          console.log(error)
          this.setState({ loading: false }, () => this.props.setFlashMessage({apiError: true, displayValue: error.response.data, type: 'negative'}))
        }
        else
        {
          events.push(event);
          this.setState({ events, loading: false, inputs: {...this.getInputsInitialState()} }, () => {
            this.props.setFlashMessage({displayValue: 'Successfully created event', type: 'success'})
            this.props.reloadActivities()
          })
        }
      })
    }
  }

  showCompletionConfirmation = (event) => {
    let { selectedEvent, completeConfirmation } = this.state;
    selectedEvent = event;
    completeConfirmation = true;
    this.setState({ selectedEvent, completeConfirmation })
  }

  onModalReject = () => {
    this.setState({selectedEvent: null, completeConfirmation: false})
  }

  markEventAsComplete = () => {
    this.setState({ fetchLoading: true, completeConfirmation: false })
    let { account, selectedEvent } = this.state;
    if (account && selectedEvent.id)
    {
      AccountAPI.markEventComplete(account.id, selectedEvent.id, (error, data) =>
      {
          if (error)
          {
            this.setState({ selectedEvent: null, completeConfirmation: false }, () => this.props.setFlashMessage({apiError: true, displayValue: error.response.data, type: 'negative'}))
          }
          else
          {
            this.setState({ fetchLoading: true, selectedEvent: null, completeConfirmation: false}, () => {
              this.fetchEvents();
              this.props.setFlashMessage({displayValue: 'Successfully marked as complete', type: 'success'})
            })
          }
      })
    }
    else{
      this.setState({ loading: false })
    }
  }

  render()
  {
    const { events, loading, fetchLoading, inputs, repOptions, completeConfirmation, selectedEvent } = this.state;

    let haveAccess = (event) => {
      const {user} = this.context;
      return !!event.invitees.find( (f) => String(user.id) === String(f.id) )
    }


    const StartDateButtonInput = ({ value, onClick, inputClass, placeholder }) => (
      <button
        type='button'
        className={"ui basic button date-input-custom " + inputClass}
        onClick={onClick}>
        {value || <span className='placeholder'>{placeholder}</span>}
      </button>
    );


    const EndDateButtonInput = ({ value, onClick, inputClass, placeholder }) => (
      <button
        type='button'
        className={"ui basic button date-input-custom " + inputClass}
        onClick={onClick}>
        {value || <span className='placeholder'>{placeholder}</span>}
      </button>
    );

    return (
      <div>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Group>
            <Form.Field
              width={16}
              required
              control={Input}
              placeholder='Title'
              autoComplete="off"
              name='title'
              value={inputs.title.value}
              onChange={this.handleChange}
              error={inputs.title.valid === false ? {
                content: inputs.title.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              width={16}
              required
              control={Input}
              placeholder='Location'
              autoComplete="off"
              name='location'
              value={inputs.location.value}
              onChange={this.handleChange}
              error={inputs.location.valid === false ? {
                content: inputs.location.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              width={16}
              required
              control={TextArea}
              placeholder='Description'
              autoComplete="off"
              name='description'
              value={inputs.description.value}
              onChange={this.handleChange}
              error={inputs.description.valid === false ? {
                content: inputs.description.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              className='ui-date-picker'
              control={DatePicker}
              selected={inputs.startTime.value || null}
              onChange={(date, e) => this.handleChange(e, {name: 'startTime', value: date})}
              selectsStart
              minDate={new Date}
              startDate={inputs.startTime.value || null}
              endDate={inputs.endTime.value}
              timeFormat="HH:mm"
              showTimeSelect
              timeIntervals={15}
              timeCaption="Start Time"
              dateFormat="dd/MM/yyyy h:mm aa"
              showYearDropdown={true}
              showMonthDropdown={true}
              placeholderText="Event Start Time"
              customInput={
                <StartDateButtonInput
                  inputClass={inputs.startTime.valid === false ? 'error' : ''}
                  placeholder='Event Start Time'
                />
              }
              isClearable
              error={inputs.startTime.valid === false ? {
                content: inputs.startTime.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              className='ui-date-picker'
              control={DatePicker}
              selected={inputs.endTime.value}
              onChange={(date, e) => {
                  if (date > inputs.startTime.value)
                  {
                    this.handleChange(e, {name: 'endTime', value: date})
                  }
                  else {
                    alert('End Date Time cannot be empty or less than Start Date Time')
                  }
                }
              }
              selectsEnd
              startDate={inputs.startTime.value}
              endDate={inputs.endTime.value}
              minDate={inputs.startTime.value}
              showTimeSelect
              adjustDateOnChange={true}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="End Time"
              dateFormat="dd/MM/yyyy h:mm aa"
              showYearDropdown={true}
              showMonthDropdown={true}
              placeholderText="Event End Time"
              customInput={
                <EndDateButtonInput
                  inputClass={inputs.endTime.valid === false ? 'error' : ''}
                  placeholder='Event End Time'
                />
              }
              isClearable
              error={inputs.endTime.valid === false ? {
                content: inputs.endTime.message,
              } : false }
            />
          </Form.Group>

          <Form.Group widths='equal' style={{marginTop: '28px'}}>
            <Form.Select
              multiple={true}
              name="invitees"
              id="rep-select"
              placeholder='Select Invitees'
              onChange={this.handleChange}
              options={repOptions}
              value={inputs.invitees.value}
              error={inputs.invitees.valid === false ? {
                content: inputs.invitees.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Button
              disabled={!Utils.isInputsValidated(inputs)}
              style={{float: 'right'}}
              content='Create Event'
              width={16} />
          </Form.Group>

        </Form>

        <div>
          {
            !this.props.account ?
            <div>
            {
              fetchLoading ?
              <Loader active inline='centered'>
                Fetching Events...
              </Loader>
              :
              events.length > 0 ?
                events.map((event, i) => {
                  return (
                    <Message
                      size='mini'
                      key={`event-#{event.id}-${i}`}
                      >
                      <div>

                        <div>
                          <span>
                            <b>{event.creatorName || 'System'}</b> created an event on <b>{Utils.formatSimpleDate(event.createdAt)}</b>
                          </span>
                        </div>

                        <p><b>Title: </b>{event.title}</p>
                        <p><b>Description: </b>{event.description}</p>
                        <p><b>Start Time: </b>{Utils.formatSimpleDateTime(event.startTime)}</p>
                        <p><b>End Time: </b>{Utils.formatSimpleDateTime(event.endTime)}</p>
                        <p><b>Invitees: </b></p>
                        <ul>
                          {
                            event.invitees.map((user, i) => {
                               return <li>{user.name}</li>
                            })
                          }
                        </ul>
                      </div>

                    </Message>
                  )
              })
              :
              <Message
                size='mini'
                key='no-events'
                >
                <div>
                  No events present!
                </div>
              </Message>
            }
            </div>
            : null
          }
         </div>
      </div>
    )
  }
}

export default Event
