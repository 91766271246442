import React from 'react'
import {List, Popup, Input} from 'semantic-ui-react'

const googleDocFileTypes = ['.pdf', '.pptx', '.xlsx']

const openFile = (filename) => {
  if (googleDocFileTypes.some(x => filename.includes(x))) {
    window.open('http://docs.google.com/gview?url='+filename, "", "width=900,height=700")
  } else {
    window.open(filename)
  }
}

const deleteDocument = (filename, property, fieldOnChange, name) => {
  if (window.confirm('Are you sure you want to DELETE the ' + name.slice(0, -1) + '?')) {
    let documents = property[name]
    documents = documents.filter(x => x.filename != filename)

    fieldOnChange(null, {value: documents, name: name})
  }
}

const fileIcon = (filename) => {
  if (filename.toLowerCase().endsWith('.pdf')) {
    return 'file pdf outline'
  }

  if (['.png', '.jpg', 'jpeg'].some(x => filename.toLowerCase().endsWith(x))) {
    return 'file image outline'
  }

  if (filename.toLowerCase().endsWith('.pptx')) {
    return 'file powerpoint outline'
  }

  if (filename.toLowerCase().endsWith('.xlsx')) {
    return 'file excel outline'
  }

  return 'file'
}

const fileList = (documents, onChange, property, fieldOnChange, openImageCropper, name = 'documents') => {

  return (
    <React.Fragment>
      <List>
        {
          documents.map((document, index) => {
            return (
              <List.Item key={index} style={{marginBottom: '10px'}}>
                {
                  name == 'images'
                  ?
                  <React.Fragment>
                    <img src={document.filename} style={{height: '100px', float: 'right', marginRight: '100px'}} />
                    <Popup content={'Create cropped image (Used in powerpoint and All Page)'} position='top left'
                      trigger={
                        <List.Icon className="downloadPropertyDocument" name="crop" style={{cursor: 'pointer', paddingRight: '15px'}} onClick={() => openImageCropper(document.filename)}/>
                      }
                    />
                  </React.Fragment>
                  :
                  null
                }

                <Popup content={(name == 'images' ? 'Download ' : 'View ') + name.slice(0, -1)} position='top left'
                  trigger={
                    <List.Icon className="downloadPropertyDocument" name={fileIcon(document.filename)} style={{cursor: 'pointer'}} onClick={() => openFile(document.filename)}/>
                  }
                />

                <List.Content style={{width: '325px'}}>
                  <List.Header>{decodeURI(document.filename.split('/').at(-1))}</List.Header>
                  <List.Description>
                    {
                      onChange == null
                      ?
                      document.description
                      :
                      <Input size='mini' type="text" placeholder="Description" style={{width: '300px', marginTop: '5px'}} value={document.description}
                        onChange={onChange.bind(null, document.filename, name)}
                      />
                    }

                  </List.Description>
                </List.Content>

                {
                  onChange == null
                  ?
                  null
                  :
                  <Popup content={'Delete ' + name.slice(0, -1)} position='top left'
                    trigger={
                      <List.Icon className="deletePropertyDocument" name='trash alternate outline' onClick={() => deleteDocument(document.filename, property, fieldOnChange, name)}/>
                    }
                  />
                }

              </List.Item>
            )
          })
        }
      </List>
      {
        documents.length == 0
        ?
        'No '+name+' added yet.'
        :
        null
      }
    </React.Fragment>
  )
}

export {fileList}
