import React, {Component} from 'react'
import {Modal, Checkbox, Accordion, Button, Message} from 'semantic-ui-react'
import {isCustomer} from './is-role.js'

class ExportToExcelModal extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {multipleBrandsSelected} = this.props

    return (
      <Modal trigger={<Button id="show-excel-modal" style={{display: 'none'}}>Show Modal</Button>} closeIcon>
        <Modal.Header>Export CADs to Excel</Modal.Header>
        <Modal.Content>
          {this.props.excelExportError && !isCustomer() ?
            <Message warning>
              <Message.Header>You must change visible fields!</Message.Header>
              <p>You CANNOT export <b>Future units</b> with <b>orders / On Hand / FACTORY-RTS</b></p>
            </Message>
          : null}
          {!this.props.excelExportError && this.props.excelExportError2 ?
            <Message warning>
              <Message.Header>You must change visible fields!</Message.Header>
              <p>You CANNOT export <b>Future Stock</b> with <b>ATS / Future ATS</b></p>
            </Message>
          : null}

          {this.props.isAdmin ?
            <Checkbox label='Export margins'
              onChange={this.props.toggleExportMargins}
              checked={this.props.exportMargins}
              style={{marginRight: '40px'}} />
          : null}

          <Checkbox label='Export by Month' onChange={this.props.toggleExportByMonth} checked={this.props.exportByMonth} style={this.props.isGuest && !this.props.cadReducer.showFutureATS ? {display: 'none'} : {marginRight: '40px', marginTop: '20px'}}/>

          {
            multipleBrandsSelected
            ?
            <React.Fragment>
              &nbsp;
              <Checkbox label='Export by Brand' onChange={this.props.toggleExportByBrand} checked={this.props.exportByBrand} style={{marginRight: '40px', marginTop: '20px'}}/>
            </React.Fragment>
            :
            null
          }

          &nbsp;
          {
            this.props.salesHistoryAccount == ''
            ?
            <Checkbox label='Export with NO images' onChange={this.props.toggleExportNoImages}
              checked={this.props.exportNoImages || this.props.cadReducer.cadStatus == 'No Image w/ Future Stock'}
              disabled={this.props.cadReducer.cadStatus == 'No Image w/ Future Stock'} style={{marginRight: '40px', marginTop: '20px'}}
            />
            :
            null
          }
          &nbsp;
          <span style={this.props.cadReducer.showATS || this.props.cadReducer.showFutureATS || this.props.cadReducer.showFutureStock ? {display: 'inline-block', width: '150px', verticalAlign: 'top', marginTop: '20px'} : {display: 'none'}}>
            <Checkbox label='Hide units under' onChange={this.props.toggleHideUnitsUnder} checked={this.props.hideUnitsUnder} />
            {this.props.hideUnitsUnder ?
              <input id="hide-units-under" placeholder="Enter min units" type="number" min="5" max="999999" step="1" onChange={this.props.hideUnitsUnderOnChange} style={{width: '150px', marginTop: '5px'}}/>
            : null}
          </span>


          <br/><br/>
          <Accordion panels={this.props.hideShowPanels} exclusive={false}/>
          <br/><br/>
          <p className="show-hide-note" style={this.props.isGuest ? {display: 'none'} : {opacity: '0.7'}}>
            Note: If want to move or delete an image in export file, upload to <a href="https://sheets.google.com">sheets.google.com</a> and edit there.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.downloadExcel} primary disabled={this.props.excelExportError && !isCustomer()}>
            Download Excel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ExportToExcelModal
