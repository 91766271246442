import React, {Component} from 'react'
import {TextArea, Loader} from 'semantic-ui-react'
import {resizeTextarea} from './resize-textarea.js'
import {processText} from './process-text.js'
import Utils from '../../../modules/utils'
import DisplayStyleNumberStatus from './display-style-number-status.js'

class StyleNumbersTextarea extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      styleNumberText: '',
      loading: false,
      styleNumbers: [],
      numStyleNumbers: 0,
      styleNumberStatus: [],
      cadIds: [],
      imageData: {},
      found: 0,
      duplicate: 0,
      notFound: 0
    }

    this.textareaOnChange = this.textareaOnChange.bind(this)
    this.searchForCads = this.searchForCads.bind(this)
    this.getStyleNumberStatus = this.getStyleNumberStatus.bind(this)
  }

  componentDidMount() {
    window.addEventListener('load-edit-vpo-data', () => {
      setTimeout(() => {
        this.textareaOnChange(null, {
          value: window.editVpoPage.selectBuyer.styleNumbers.join("\n")
        })
      }, 100)
    })
  }

  componentDidUpdate() {
    window.newVpoPage.styleNumbersTextarea = JSON.parse(JSON.stringify(this.state))
  }

  textareaOnChange(e, value) {
    this.setState({styleNumberText: value.value, styleNumberStatus: [], cadIds: []})
    clearTimeout(window.searchForCadsTimeout)
    window.searchForCadsTimeout = setTimeout(this.searchForCads, 1000)
    this.setState({loading: true})
    this.props.setLoading(true)
    this.setNotComplete()
  }

  searchForCads() {
    resizeTextarea('style-number-textarea')
    let {styleNumberText} = this.state
    if (styleNumberText.length > 2) {
      let styleNumbers = processText(styleNumberText)
      let numStyleNumbers = styleNumbers.filter(x => x != '').length
      this.setState({styleNumbers, numStyleNumbers})
      this.getStyleNumberStatus(styleNumbers)
    } else {
      this.setState({loading: false, styleNumbers: [], numStyleNumbers: 0})
      this.props.setStyleNumbers([])
      this.props.setLoading(false)
    }
  }

  getStyleNumberStatus(styleNumbers) {
    Utils.post('get_style_number_status', {styleNumbers}, (err, res) =>
    {
      if (err) {
        alert('There was an error when trying to validate style numbers')
      } else {
        if (res.data.success) {
          let {styleNumberStatus, cadIds, brandData, categoryData, colorData, prefillOrders, imageData} = res.data

          let found = styleNumberStatus.filter(x => x == 'found').length
          let duplicate = styleNumberStatus.filter(x => x == 'duplicate').length
          let notFound = styleNumberStatus.filter(x => x == 'not found').length

          this.setState({styleNumberStatus, cadIds, brandData, categoryData, colorData, prefillOrders, imageData, loading: false, found, duplicate, notFound})
          this.calculateComplete(found, duplicate, notFound)

          this.props.setStyleNumbers(styleNumbers.filter(x => x != ''))
          this.props.setLoading(false)
          this.props.stopLoading()
        } else {
          alert('There was an error when trying to validate style numbers')
        }
      }
    })
  }

  setNotComplete() {
    this.props.setComplete(false)
  }

  calculateComplete(found, duplicate, notFound) {
    let complete = found > 0 && duplicate == 0 && notFound == 0
    this.props.setComplete(complete)
  }

  render() {
    let {isEditPage} = this.props
    let {loading, styleNumberStatus} = this.state

    return (
      <React.Fragment>
        <DisplayStyleNumberStatus statuses={styleNumberStatus} loading={loading}/>
        <TextArea id="style-number-textarea" style={{opacity: (isEditPage ? 0.2 : 1), width: '518px', minWidth: '518px', padding: '10px', height: '200px', minHeight: '200px', maxWidth: '870px', border: '1px solid rgb(212,212,212)', lineHeight: '1.4em'}}
          placeholder="Style Numbers" onChange={this.textareaOnChange} disabled={isEditPage}
        />
        <Loader active={loading} inline size='tiny'
          style={{verticalAlign: 'top', marginLeft: '10px'}}
        />
      </React.Fragment>
    )
  }
}

export default StyleNumbersTextarea
