import React from 'react'
import {Menu, Label, Tab} from 'semantic-ui-react'

const getFilterPOWarehouse = (status, myState) => {
  let {actualPOs, account, rep, tickets, showFlagged, brand, orderType} = myState

  let purchaseOrders = actualPOs.filter(x => x.status == status)
  if (account.length > 0) {
    purchaseOrders = purchaseOrders.filter(x => account.indexOf(x.warehouseAccountName) != -1)
  }
  if (rep.length > 0) {
    purchaseOrders = purchaseOrders.filter(x => rep.indexOf(x.userId) != -1)
  }
  if (orderType.length > 0) {
    purchaseOrders = purchaseOrders.filter(x => orderType.includes(x.orderType))
  }
  if (tickets.length > 0) {
    purchaseOrders = purchaseOrders.filter(x => {
      let anyMatch = false

      if (tickets.indexOf('NO') >= 0) {
        anyMatch = [null, 'NO', ''].includes(x.ticketed)
      }

      if (!anyMatch && tickets.indexOf('Ordered') >= 0) {
        anyMatch = x.ticketed != null && x.ticketed != 'NO' && x.ticketsOrdered === true
      }

      if (!anyMatch && tickets.indexOf('Not Ordered') >= 0) {
        anyMatch = x.ticketed != null && x.ticketed != 'NO' && !x.ticketsOrdered
      }

      return anyMatch
    })
  }
  if (showFlagged)  {
    purchaseOrders = purchaseOrders.filter(x => x.flaggedForUpdate)
  }
  if (brand.length > 0)  {
    purchaseOrders = purchaseOrders.filter(x => checkCadsForField(x, "brand", brand))
  }

  return purchaseOrders
}

const checkCadsForField = (po, attributeName, dropdownOptions) => {
  let anyCadsHaveMatch = false

  if (po.cads != null) {
    for (let i=0; i<po.cads.length; i++) {
      let cad = po.cads[i]
      if (cad != null && cad[attributeName] != null && dropdownOptions.indexOf(cad[attributeName]) > -1) {
        anyCadsHaveMatch = true
        break
      }
    }
  }

  let result = anyCadsHaveMatch

  if(!result && dropdownOptions.indexOf('Missing') > -1) {
    let anyCadsHaveMissing = false
    if (po.cads == null) {
      anyCadsHaveMissing = true
    } else {
      for (let i=0; i<po.cads.length; i++) {
        let cad = po.cads[i]
        if (cad == null || cad[attributeName] == null || cad[attributeName] == '') {
          anyCadsHaveMissing = true
          break
        }
      }
    }

    result = anyCadsHaveMissing
  }

  return result
}

const getStatuses = (isInventory) => {
  return (
    isInventory
    ?
    ['Pending', 'QB-Entered', 'Needs-Fulfillment', 'Ready-To-Ship', 'In-Progress', 'Routed', 'Pick-Up-Scheduled', 'Partially-Shipped', 'Shipped', 'Invoiced', 'Delayed', 'Cancelled']
    :
    ['Needs-Fulfillment', 'Ready-To-Ship', 'In-Progress', 'Routed', 'Pick-Up-Scheduled', 'Shipped']
  )
}

const getPanes = (isInventory, notUsingFilters, totals, accountTotals) => {
  return getStatuses(isInventory).map((status, index) => {
    return (
      {
        menuItem: (
          <Menu.Item key='Needs-Fulfillment' style={isInventory ? {fontSize: '80%', letterSpacing: '-0.4px'} : null}>
            {status}<Label style={isInventory ? {paddingLeft: '5px', paddingRight: '5px'} : null}>{notUsingFilters ? totals[index] : accountTotals[index]}</Label>
          </Menu.Item>
        ),
        render: () => <Tab.Pane>Tab {index} Content</Tab.Pane>
      }
    )
  })
}

export {getFilterPOWarehouse, checkCadsForField, getStatuses, getPanes}
