import React, { Component } from 'react'
import {Icon, Popup, Modal, Button} from 'semantic-ui-react'
import {files} from '../contacts/files'
import './style.css'

class AddDocumentButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      filenames: [],
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.addFilename = this.addFilename.bind(this)
    this.removeFilename = this.removeFilename.bind(this)
    this.saveDocuments = this.saveDocuments.bind(this)
    this.missingRequiredFields = this.missingRequiredFields.bind(this)
  }

  openModal() {
    this.setState({open: true, filenames: []})
  }

  closeModal() {
    this.setState({open: false})
  }

  addFilename(filename) {
    let {filenames} = this.state
    filenames.push(filename)
    this.setState({filenames})
  }

  removeFilename(filename) {
    let {filenames} = this.state
    filenames = filenames.filter(x => x != filename)
    this.setState({filenames})
  }

  missingRequiredFields() {
    let {filenames} = this.state

    if (filenames.length == 0) {
      alert('File is required!')
      return true
    }

    return false
  }

  saveDocuments() {
    if (this.missingRequiredFields()) {
      return
    }

    let {fieldOnChange, property, name} = this.props
    let {filenames} = this.state

    let documents = property[name]

    documents = documents.concat(filenames.map((filename) => {return {filename: filename, description: ''}}))

    fieldOnChange(null, {value: documents, name: name})
    this.closeModal()
    this.props.openImageCropperBulk(filenames)
  }

  render() {
    let {open, filenames} = this.state
    let {property, name} = this.props

    return (
      <React.Fragment>
        <Modal
          open={open}
        >
          <Modal.Header>Add {name}</Modal.Header>
          <Modal.Content id="propertyDocuments">
            {files('property_' + name, filenames, this.addFilename, this.removeFilename)}
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              content={'Save ' + name}
              labelPosition='right'
              icon='checkmark'
              onClick={this.saveDocuments}
              positive
            />
          </Modal.Actions>
        </Modal>
        <span style={{marginLeft: '55px'}}>
          <Popup content={'Add ' + name.slice(0, -1)} position='bottom left'
            trigger={
              <Button onClick={this.openModal} basic><Icon name='plus'/>add</Button>
            }
          />
        </span>
      </React.Fragment>
    )
  }
}

export default AddDocumentButton
