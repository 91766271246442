import React, { Component } from 'react'
import {getProperty} from './components/properties/get'
import {Loader, Message} from 'semantic-ui-react'
import AssetOverview from './components/edit-property/asset-overview'
import CashFlow from './components/edit-property/cash-flow'
import TransactionOverview from './components/edit-property/transaction-overview'
import {hr} from './components/new-vpo-page/hr'
import UpdateProperty from './components/edit-property/update-property'
import Logs from './components/edit-property/logs'
import {getAssetId} from './components/properties/get-asset-id'
import {start} from './components/properties/start'
import DeleteProperty from './components/edit-property/delete'

class EditPropertyPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      property: null,
      loading: true,
      activeSection: 0,
      isComplete: [false, false, false],
      logs: [],
      recentLog: false,
      orgId: null
    }

    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.markAsComplete = this.markAsComplete.bind(this)
    this.goToNextSection = this.goToNextSection.bind(this)
    this.editSection = this.editSection.bind(this)
    this.getAssetId = this.getAssetId.bind(this)
    this.documentDescriptionOnChange = this.documentDescriptionOnChange.bind(this)
    this.checkIfRecentLog = this.checkIfRecentLog.bind(this)
  }

  componentDidMount() {
    getProperty(null, this.props.id, (err, res) => {
      if (err) {
        alert('There was an error when trying to get the property.')
      } else {
        let {property, logs, documents, images, orgId} = res.data
        property['documents'] = documents
        property['images'] = images
        this.setState({property, logs, orgId, loading: false})
        setTimeout(this.checkIfRecentLog, 100)
        start(orgId, 'Edit Property '+this.props.id)
      }
    })
  }

  getAssetId() {
    let {buildingType, source, buildingSize, roomsNumber} = this.state.property
    return 'R' + this.props.id.toString().padStart(3, '0') + '-' + getAssetId(buildingType, source, buildingSize, roomsNumber)
  }

  fieldOnChange(event, {value, name}) {
    let {property} = this.state
    property[name] = value
    this.setState({property})
  }

  documentDescriptionOnChange(filename, name, event) {
    let {property} = this.state
    let {documents, images} = property
    let {value} = event.currentTarget

    if (name == 'documents') {
      let index = documents.findIndex(x => x.filename == filename)
      documents[index].description = value
      property.documents = documents
    } else if (name == 'images') {
      let index = images.findIndex(x => x.filename == filename)
      images[index].description = value
      property.images = images
    }

    this.setState({property})
  }

  markAsComplete(sectionIndex) {
    let {isComplete} = this.state
    isComplete[sectionIndex] = true
    this.setState(isComplete)
    this.goToNextSection()
  }

  goToNextSection() {
    let {activeSection} = this.state
    activeSection += 3
    this.setState({activeSection})
  }

  editSection(num) {
    this.setState({activeSection: num})
  }

  checkIfRecentLog() {
    let {logs} = this.state
    if (logs.length == 0) return

    let date = new Date(logs[0].createdAt)
    let now = Date.now()
    let elapsed = (now - date)/1000

    if (elapsed < 100) {
      this.setState({recentLog: true})
      setTimeout(() => {this.setState({recentLog: false})}, 15000)
    }
  }

  render() {
    let {loading, property, activeSection, logs, propertyDocuments, recentLog} = this.state

    return (
      <div style={{background: 'rgb(250,250,250)', minHeight: '100vh'}}>

        <div style={{width: '950px', margin: 'auto', paddingBottom: '200px'}}>

          <DeleteProperty id={property == null ? null : property.id}/>

          <h2 id="mainTitle" style={{paddingLeft: '20px'}}>Edit Property {this.props.id}</h2>

          {
            recentLog
            ?
            <Message
              positive
              icon='save outline'
              header='Save success'
              content='The changes were successfully saved to the property.'
            />
            :
            null
          }

          <Logs logs={logs}/>

          {
            loading
            ?
            <center style={{paddingTop: '20vh'}}><Loader active inline /></center>
            :
            property == null
            ?
            <center className="no-notes">No property found</center>
            :
            <div style={{border: '1px solid rgb(212,212,212)', padding: '30px 20px', background: 'white'}}>
              <AssetOverview
                markAsComplete={this.markAsComplete.bind(null, 0)}
                isActive={activeSection == 0}
                editSection={this.editSection.bind(null, 0)}
                titleNum='1'
                fieldOnChange={this.fieldOnChange}
                property={property}
                getAssetId={this.getAssetId}
                documentDescriptionOnChange={this.documentDescriptionOnChange}
              />
              {hr}
              <CashFlow
                markAsComplete={this.markAsComplete.bind(null, 1)}
                isActive={activeSection == 1}
                editSection={this.editSection.bind(null, 1)}
                titleNum='2'
              />
              {hr}
              <TransactionOverview
                markAsComplete={this.markAsComplete.bind(null, 2)}
                isActive={activeSection == 2}
                editSection={this.editSection.bind(null, 2)}
                titleNum='3'
              />
              {hr}
              <UpdateProperty
                isActive={activeSection == 3}
                titleNum='4'
                property={property}
                getAssetId={this.getAssetId}
              />
            </div>
          }
        </div>
      </div>
    )
  }
}

export default EditPropertyPage
