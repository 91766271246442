import React, {Component} from 'react'
import {Accordion, Icon} from 'semantic-ui-react'

class CadIdsAccordion extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {activeIndex: -1}
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const {activeIndex} = this.state
    const {cads} = this.props

    return (
      <Accordion style={{marginBottom: '14px'}}>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
          style={{display: 'inline-block'}}
        >
          <Icon name='dropdown' />
          Cad ids
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <div style={{fontSize: '10px', lineHeight: '13px', marginBottom: '33px'}}>
            <p style={{padding: '15px', border: '1px solid rgb(222,222,222)', borderRadius: '10px'}}>
              {
                cads.map(cad => Number(cad.id)).sort((a, b) => {return a - b}).join(', ')
              }
            </p>
          </div>
        </Accordion.Content>
      </Accordion>
    )
  }
}

export default CadIdsAccordion
