import {statusOptions} from '../cads/status-options.js'

const compareStatus = (a, b) => {
  const indexA = statusOptions.findIndex(x => x.key == a)
  const indexB = statusOptions.findIndex(x => x.key == b)
  if (indexA < indexB) {
    return -1;
  }
  if (indexA > indexB) {
    return 1;
  }

  // names must be equal
  return 0;
}

const sortStatus = (value) => {
  return value.sort(compareStatus)
}

export {sortStatus}
