import {brand_char_options, category_char_options, division_char_options, pattern_char_options, season_char_options} from './style-code-options'

const isNumber = (str) => {
  let nums = ['0','1','2','3','4','5','6','7','8','9']

  for (let i=0; i<str.length; i++) {
    let char = str[i]
    if (nums.indexOf(char) == -1) {
      return false
    }
  }

  return true
}

const isLetter = (char) => {
  let letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  return letters.includes(char)
}

const validColorCode = (str) => {
  //part2 must be TBD or 3 numbers
  if (str.length != 3) {
    console.log('part2 not 3 in length')
    return false
  }

  let isNumberOrTBD = str == 'TBD' || isNumber(str)
  if (!isNumberOrTBD) {
    console.log('part2 is not number or TBD')
    return false
  }

  return true
}

const checkValidGraphicStyleCode = (styleNumber) => {

  //


  return true
}

const checkValidPrintStyleCode = (styleNumber) => {
  //does it have 2 dashes in it?
  let parts = styleNumber.split('-')
  let part1 = parts[0]
  let part2 = parts[1]
  let part3 = parts[2]

  let firstChar = part1[0]

  if (firstChar == 'G') {
    return checkValidGraphicStyleCode(styleNumber)
  }

  if (parts.length != 3) {
    console.log('does not have 2 or 3 dashes')
    return false
  }

  //does part1 start with P then 4 or 5 number family id?
  let familyId = part1.substring(1, part1.length)
  if (['P','G'].includes(firstChar) && isNumber(familyId) && (familyId.length == 4 || familyId.length == 5) ) {
    //all good
  } else {
    console.log('part1 does not start with P or family id is not 4 or 5 number')
    return false
  }

  if (validColorCode(part2)) {
    //all good
  } else {
    console.log('part 2 is not a valid style code')
    return false
  }

  //part3 must be 3 in lengthIf
  let numLetters = part3.length
  if (numLetters != 3) {
    console.log('part3 not 3 in length')
    return false
  }

  let pattern_char = part3.slice(0,2)
  let season_char = part3[2]

  if (pattern_char_options.indexOf(pattern_char) == -1) {
    console.log('pattern char not a valid option')
    return false
  }

  if (season_char_options.indexOf(season_char) == -1) {
    console.log('season char not a valid option')
    return false
  }

  return true
}

export {checkValidPrintStyleCode, isNumber, isLetter, validColorCode}
