import React from 'react'
import {Message, Popup, Button, Icon} from 'semantic-ui-react'
import {copyToClipboard} from './copy-to-clipboard'
import StyleNumberError from './style-number-error'

const styleNumberMessage = (division, familyId, color, brand, category, packs, subcategory, prefix, saveStyleNumber) => {
  let styleNumber = (prefix == '' ? '' : prefix[0]) + division[0] + subcategory[0] + familyId.padStart(4,'0') + '-' + color.padStart(3,'0') + '-' + brand[0] + category + packs

  return (
    <center>
      <Message info style={{display: 'inline-block', fontSize: '200%'}}>
        <Message.Header id="new-style-number">
<Popup position='bottom center' content={prefix.split(' = ')[1]} trigger={<span style={{cursor: 'pointer'}}>{prefix[0]}</span>} />
<Popup position='bottom center' content='Division' trigger={<span style={{cursor: 'pointer'}}>{division[0]}</span>} />
<Popup position='bottom center' content='Sub-Category' trigger={<span style={{cursor: 'pointer'}}>{subcategory[0]}</span>} />
<Popup position='bottom center' content='Family Id' trigger={<span style={{cursor: 'pointer'}}>{familyId.padStart(4,'0')}</span>} />
{division == 'W = LADIES PLUS SIZE' ? 'X' : ''}
-<Popup position='bottom center' content='Color' trigger={<span style={{cursor: 'pointer'}}>{color.padStart(3,'0')}</span>} />-
<Popup position='bottom center' content='Brand' trigger={<span style={{cursor: 'pointer'}}>{brand[0]}</span>} />
<Popup position='bottom center' content='Category' trigger={<span style={{cursor: 'pointer'}}>{category}</span>} />
<Popup position='bottom center' content='Packs' trigger={<span style={{cursor: 'pointer'}}>{packs}</span>} />
        </Message.Header>
        <StyleNumberError styleNumber={styleNumber} type={'CAD'}/>
      </Message>
      <Button primary style={{marginLeft: '20px'}} onClick={saveStyleNumber}>Save &nbsp;<Icon name='save outline'/></Button>
      <Button basic style={{marginLeft: '20px'}} onClick={() => copyToClipboard(false)}>Copy to clipboard &nbsp;<Icon name='clipboard outline'/></Button>
    </center>
  )
}

export {styleNumberMessage}
