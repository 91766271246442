import React, {useEffect}from 'react'
import {Message}         from 'semantic-ui-react'
import {useAuth0}        from './../../contexts/auth-context'
import {useHistory}      from 'react-router-dom'

const HistoryMessage = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (history.location && history.location.state && history.location.state.message)
    {
      props.setMessage(history.location.state.message)
    }
    else props.setMessage(null)
  }, [history.location.pathname]);

  return null;
}

export const FlashMessage = props =>
{
  const {message, setMessage} = useAuth0();
  let displayValue = message ? message.displayValue : '';

  if (message && message.apiError)
  {
    let errorKeys = Object.keys(displayValue)
    displayValue = errorKeys.map((key, i) => {
      return <p key={i}><b>{key}: </b>{displayValue[key].join(', ')}</p>
    })
  }

  return <div>
      {
        message ?
        <Message
          style={{marginBottom: '10px'}}
          negative={message.type === 'negative'}
          success={message.type === 'success'}
          size='mini'
          onDismiss={() => setMessage(null)}
          >
          {displayValue}
        </Message>
        :
        null
      }
      <HistoryMessage setMessage={setMessage}/>
    </div>
}
