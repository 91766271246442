import {isLetter} from '../prints/check-valid-style-code'
import {divisionOptions, subcategoryOptions, categoryOptions, brandOptions} from './options'
import {filterApplicable} from './subcategory-input'

const numberChars = ['0','1','2','3','4','5','6','7','8','9']

const runStyleCodes = (styleNumber, updateStyleNumber) => {
  let familyId, color, category, packs, division, brand, subcategory, prefix,
  showApplicableSubcategories = true
  // familyId = '3865'
  // color = '222'
  // category = 'S'
  // packs = 'S'
  // division = 'G = GIRLS'
  //brand = 'T = TAHARI'
  //subcategory = 'P = LS/LL PJ SET'


  let sections = styleNumber.split('-').map(x => x.trim())
  let firstSection = sections[0]
  color = sections[1]
  let thirdSection = sections[2]

  //check for M for Mexico Customer
  prefix = ''
  if (firstSection[0] == 'M' && isLetter(firstSection[1]) && isLetter(firstSection[2])) {
    firstSection = firstSection.slice(1, firstSection.length)
    prefix = 'M = Mexico Customer'
  }

  if (firstSection[0] == 'Q') {
    firstSection = firstSection.slice(1, firstSection.length)
    prefix = 'Q = Gift with Purchase'
  }

  let divisionChar = firstSection[0]
  let subcategoryChar = firstSection[1]

  let brandChar = thirdSection[0]
  category = thirdSection[1]
  packs = thirdSection[2]


  //family id
  familyId = firstSection.split('')

  //remove X if have it
  if (familyId[familyId.length - 1] == "X") familyId.pop()

  //remove first two chars
  for (let i=0;i<2;i++) {
    if (!numberChars.includes(familyId[0])) familyId.shift()
  }
  familyId = familyId.join('')


  division = divisionOptions.find(x => x.value[0] == divisionChar).value
  //look for X before dash
  let isPlusSize = firstSection[firstSection.length - 1] == 'X'
  if (isPlusSize) {
    division = 'W = LADIES PLUS SIZE'
  }

  brand = filterApplicableBrands(brandOptions, category, division[0]).find(x => x.value[0] == brandChar).value

  let applicableSubcategoryOption = filterApplicable(subcategoryOptions, category, division[0], packs).find(x => x.value[0] == subcategoryChar)

  if (applicableSubcategoryOption == null && packs == 'A') {
    //is there any result SET in the name if I change packs from single to set?
    //example: WY7507-653-USA
    applicableSubcategoryOption = filterApplicable(subcategoryOptions, category, division[0], 'S').find(x => x.value[0] == subcategoryChar)
    showApplicableSubcategories = false
    
  }

  subcategory = applicableSubcategoryOption.value

  updateStyleNumber(familyId, color, category, packs, division, brand, subcategory, prefix, showApplicableSubcategories)
}

const filterApplicableBrands = (brandOptions, category, division) => {
  return brandOptions.filter((option) => {
    let {category_char, category_char_not, division_char, division_char_not} = option
    return (
      (category_char == null || category_char.includes(category))
      &&
      (category_char_not == null || !category_char_not.includes(category))
      &&
      (division_char == null || division_char.includes(division))
      &&
      (division_char_not == null || !division_char_not.includes(division))
    )
  })
}

export {runStyleCodes}
