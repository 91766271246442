const customerFields = [
  "ATS",
  "Future ATS",
  "Brand",
  "Category",
  "Color",
  "Description",
  "Fabrication",
  "Packs",
  "Season",
  "Size Breakdown",
  "Shipped",
  "PO",
  "Hold"
]

export {customerFields}
