import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import { Input } from 'semantic-ui-react'
import './CardSection.css'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection(props) {
  return (
    <div>
      <h4 style={{marginBottom: '10px'}}>Enter new card details</h4>

      <Input id="name-on-card" placeholder="Name on Card" style={{width: 'calc(50% - 5px)', marginBottom: '10px', fontSize: '16px'}} required />

      <Input id="email-for-card" placeholder="Email for Card (optional)" style={{width: 'calc(50% - 5px)', marginBottom: '10px', marginLeft: '10px', fontSize: '16px'}} />

      <CardElement options={CARD_ELEMENT_OPTIONS} />

      <div className="card-errors" role="alert" style={{color: 'red', marginTop: '10px'}}>{props.error}</div>
    </div>
  );
};

export default CardSection;
