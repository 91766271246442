import React, { Component, useState }                                    from 'react'
import { Label, Form, Message, Loader, TextArea, Input, Button, Icon }from 'semantic-ui-react'
import AccountAPI                                              from '../../../modules/api/account_api.js'
import UserAPI                                                 from '../../../modules/api/user_api'
import Utils                                                   from '../../../modules/utils'
import { ConfirmAlert }                                        from '../../ui/confirm-alert'
import { Auth0Context }                                        from "../../../contexts/auth-context"
import { DatePickerField }                                     from '../../ui/date-picker.js'

class Task extends Component {
  static contextType = Auth0Context;

  state = {
    inputs               : {...this.getInputsInitialState()},
    account              : this.props.account || null,
    loading              : false,
    fetchLoading         : true,
    selectedTask         : null,
    tasks                : [],
    fetchingReps         : true,
    reps                 : [],
    repOptions           : [],
    completeConfirmation : false,
    selectedTask         : null,
  }

  getInputsInitialState(){
    return  {
      title :
      {
        value    : "",
        valid    : null,
        required : true,
        message  : ""
      },

      description :
      {
        value    : "",
        valid    : null,
        required : false,
        message  : ""
      },

      dueDate :
      {
        value    : null,
        valid    : null,
        required : true,
        message  : ""
      },

      assignees :
      {
        value    : [],
        valid    : null,
        required : true,
        message  : ""
      },
    }
  }

  componentDidMount()
  {
    this.fetchTasks()
    this.fetchReps()
  }

  fetchReps = () =>
  {
    var {reps, repOptions, inputs} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        reps = data.reps;
        var keys       = {text: ['name'], value: 'id'}
        repOptions     =  Utils.toOptionsHashWithKeys(reps, keys)
        inputs.assignees.value = []
        inputs.assignees.valid = null
        this.setState({ fetchingReps: false, reps, repOptions, inputs })
      }
    }

    UserAPI.getAllReps(callback)
  }

  fetchTasks = () =>
  {
    var {account} = this.state;

    var callback = (error, tasks) =>
    {
      if (error)
      {
        console.error(error);
      }
      else
      {
        this.setState({ fetchLoading: false, tasks })
      }
    }

    AccountAPI.getTasks(account.id, callback)
  }

  handleChange = (e, data) =>
  {
    let {name, value} = data;
    let {inputs}      = this.state;
    let input         = inputs[name]
    switch(name)
    {
      case "description" :
      case "title" :
      {
        input.value = value;
        input.message = '';
        if (input.required && typeof(input.value) === 'string' && input.value.trim().length === 0)
        {
          input.message = "Please enter the value.";
        }
        input.valid = !input.message;
        break;
      }
      case "dueDate" :
      {
        input.value = value;
        input.valid = !!input.value;
        input.message = input.valid ? '' : "Please select a valid date."
        break;
      }
      case "assignees" :
      {
        input.value = value;
        input.message = '';
        if (input.value.length === 0)
        {
          input.message = "Please select at least one assignee.";
        }
        input.valid = !input.message;
        break;
      }

      default :
      {

      }

    }
    this.setState({inputs})
  }

  getData = () =>
  {
    let { inputs }   = this.state;
    let data         = {};

    data.description = inputs.description.value
    data.title       = inputs.title.value
    data.dueDate     = Utils.formatDateForSubmit(inputs.dueDate.value);
    data.assignees   = inputs.assignees.value

    return data;
  }

  handleSubmit = () => {
    let { tasks, account } = this.state
    let taskData           = this.getData()

    this.setState({ loading: true })
    if (account)
    {
      let data = { task: {...taskData} }
      AccountAPI.createTask(account.id, data, (error, task) =>
      {
        if (error)
        {
          console.log(error)
          this.setState({ loading: false }, () => this.props.setFlashMessage({apiError: true, displayValue: error.response.data, type: 'negative'}))
        }
        else
        {
          tasks.push(task);
          this.setState({ tasks, loading: false, inputs: {...this.getInputsInitialState()} }, () => {
            this.props.setFlashMessage({displayValue: 'Successfully created task', type: 'success'})
            this.props.reloadActivities()
          })
        }
      })
    }
  }

  showCompletionConfirmation = (task) => {
    let { selectedTask, completeConfirmation } = this.state;
    selectedTask = task;
    completeConfirmation = true;
    this.setState({ selectedTask, completeConfirmation })
  }

  onModalReject = () => {
    this.setState({selectedTask: null, completeConfirmation: false})
  }

  markTaskAsComplete = () => {
    this.setState({ fetchLoading: true, completeConfirmation: false })
    let { account, selectedTask } = this.state;
    if (account && selectedTask.id)
    {
      AccountAPI.markTaskComplete(account.id, selectedTask.id, (error, data) =>
      {
          if (error)
          {
            this.setState({ selectedTask: null, completeConfirmation: false }, () => this.props.setFlashMessage({apiError: true, displayValue: error.response.data, type: 'negative'}))
          }
          else
          {
            this.setState({ fetchLoading: true, selectedTask: null, completeConfirmation: false}, () => {
              this.fetchTasks();
              this.props.setFlashMessage({displayValue: 'Successfully marked as complete', type: 'success'})
            })
          }
      })
    }
    else{
      this.setState({ loading: false })
    }
  }

  render() {
    const { fetchingReps, tasks, loading, fetchLoading, inputs, repOptions, completeConfirmation, selectedTask } = this.state;

    let haveAccess = (task) => {
      const {user} = this.context;
      return !!task.assignees.find( (f) => String(user.id) === String(f.id) )
    }

    return (
      <div>
        {
            completeConfirmation &&
            <ConfirmAlert
              header='Mark Complete'
              confirmationText='Are you sure you want to mark complete your task?'
              onReject={this.onModalReject}
              onAccept={this.markTaskAsComplete}
              >
              <div>
                <p><b>Title: </b>{selectedTask.title}</p>
                <p><b>Description: </b>{selectedTask.description}</p>
                <p><b>Due Date: </b>{Utils.formatSimpleDate(selectedTask.dueDate)}</p>
                <p><b>Assignees: </b></p>
                <ul>
                  {
                    selectedTask.assignees.map((user, i) => {
                       return <li>{user.name}</li>
                    })
                  }
                </ul>
              </div>
            </ConfirmAlert>
        }
        <Form onSubmit={this.handleSubmit} loading={loading}>

          <Form.Group>
            <Form.Field
              width={16}
              required
              control={Input}
              placeholder='Title'
              autoComplete="off"
              name='title'
              value={inputs.title.value}
              onChange={this.handleChange}
              error={inputs.title.valid === false ? {
                content: inputs.title.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              width={16}
              required
              control={TextArea}
              placeholder='Description'
              autoComplete="off"
              name='description'
              value={inputs.description.value}
              onChange={this.handleChange}
              error={inputs.description.valid === false ? {
                content: inputs.description.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <DatePickerField
              minDate={new Date}
              placeholderText='Select Due Date'
              selected={inputs.dueDate.value || null}
              onChange={(data, e) => this.handleChange(e, {name: 'dueDate', value: data})}
              customInput={true}
              isClearable
              inputClass={inputs.dueDate.valid === false ? 'error' : ''}
              error={inputs.dueDate.valid === false ? {
                content: inputs.dueDate.message,
              } : false }
            />
          </Form.Group>

          <Form.Group widths='equal' style={{marginTop: '28px'}}>
            <Form.Select
              multiple={true}
              name="assignees"
              id="rep-select"
              placeholder='Select Assignees'
              onChange={this.handleChange}
              loading={fetchingReps}
              options={repOptions}
              value={inputs.assignees.value}
              error={inputs.assignees.valid === false ? {
                content: inputs.assignees.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Button
              disabled={!Utils.isInputsValidated(inputs)}
              style={{float: 'right'}}
              content='Create Task'
              width={16} />
          </Form.Group>

        </Form>

        {
          !this.props.account ?
          <div>
          {
            fetchLoading ?
              <Loader active inline='centered'>
                Fetching Tasks...
              </Loader>
              :
              tasks.length > 0 ?
                tasks.map((task, i) => {
                  return (
                    <Message
                      size='mini'
                      key={`task-#{task.id}-${i}`}
                      >
                      <div>

                        <div>
                          <span>
                            <b>{task.creatorName || 'System'}</b> added task on <b>{Utils.formatSimpleDate(task.createdAt)}</b>
                          </span>
                          <span style={{float: 'right'}}>
                            {
                              task.isComplete ?
                              <Label>
                                {`Completed on ${Utils.formatSimpleDate(task.completedOn)}`}
                              </Label>
                              :
                              haveAccess(task) ?
                              <Button
                                icon
                                primary
                                size='mini'
                                labelPosition='right'
                                onClick={ () => {this.showCompletionConfirmation(task)} }
                                >
                                Mark as complete
                                <Icon name='check' />
                              </Button>
                              : null
                            }
                          </span>
                        </div>

                        <p><b>Title: </b>{task.title}</p>
                        <p><b>Description: </b>{task.description}</p>
                        <p><b>Due Date: </b>{Utils.formatSimpleDate(task.dueDate)}</p>
                        <p><b>Assignees: </b></p>
                        <ul>
                          {
                            task.assignees.map((user, j) => {
                              return <li key={`rep-options-${i}-${j}`}>{user.name}</li>
                            })
                          }
                        </ul>
                      </div>

                    </Message>
                  )
              })
              :
              <Message
                size='mini'
                key='no-tasks'
                >
                <div>
                  No tasks present!
                </div>
              </Message>
          }
         </div>
         : null
       }
      </div>
    )
  }
}

export default Task
