import React from 'react'
import {Icon} from 'semantic-ui-react'

const externalLink = (url, fontSize, text) => {
  return (
    <a href={url} target="_blank" style={{fontSize: fontSize}}><b style={{textDecoration: 'underline'}}>{text}</b> <Icon name="external alternate" style={{fontSize: fontSize}}/></a>
  )
}

export {externalLink}
