import                              './assets/styles/App.css'
import React                   from "react"
import {HashRouter, Route, Switch} from 'react-router-dom'
import NavBar                  from './components/navbar'
import history                 from './utils/history'
import PrivateRoute            from './components/routers/private'
import AdminRoute              from './components/routers/admin'
import PurchaseOrders          from './components/purchase-orders'
import NewPurchaseOrder        from './components/purchase-orders/new'
import EditPurchaseOrder       from './components/purchase-orders/edit'
import EditCompanies           from './components/companies/edit'
import AccountsList            from './components/accounts/accounts-list'
import AccountView             from './components/accounts/view'
import AccountNew              from './components/accounts/new'
import AccountReps             from './components/accounts/account-reps'
import OrganizationsPage       from './components/organizations'
import BolPodPage              from './components/bol-pod-page'
import SwitchOrganization      from './components/superadmin/switch-organization'
import {PREVILIGES}            from './modules/constants'
import {FlashMessage}          from './components/ui/message'
import {useAuth0}              from './contexts/auth-context'
import CadsPage                from './CadsPage'
import UploadCadPage           from './UploadCadPage'
import LongStyleNumbersPage    from './long-style-numbers-page'
import ImportUpcPage           from './import-upc-page'
import ViewCadPage             from './ViewCadPage'
import EditCadPage             from './EditCadPage'
import $                       from 'jquery'
import SalesOrderPage from './NewSalesOrderPage'
import ConfirmationPage from './ConfirmationPage'
import HomePage from './HomePage'
import NewPackingSlipOrBolPage from './NewPackingSlipOrBol'
import ReportsPage from './ReportsPage'
import VendorOrdersPage from './VendorOrdersPage'
import NewVpoPage from './new-vpo-page'
import PrintsPage from './PrintsPage'
import ArtistsPage from './ArtistsPage'
import NewArtistPage from './NewArtistPage'
import ContactsPage from './ContactsPage'
import EditContactPage from './EditContactPage'
import GenerateStyleNumberPage from './GenerateStyleNumberPage'
import StyleCodesTablePage from './StyleCodesTablePage'
import AddFabricationPage from './AddFabricationPage'
import RealEstateNavbar from './components/real-estate/navbar'
import PropertiesPage from './PropertiesPage'
import EditPropertyPage from './EditPropertyPage'
import SharePropertyPage from './SharePropertyPage'

function App() {

  const {isAuthenticated, loginWithRedirect, logout, user, customer} = useAuth0()
  var isSuperadmin = isAuthenticated && user && user.role == "superadmin"

  window.addEventListener('goToHostedLoginPage', () => {
    loginWithRedirect({})
  })



  return (
    <div className="App">
      <HashRouter history={history} basename='/'>
        <header>
          {
            window.location.href.includes('/share-p')
            ?
            <RealEstateNavbar guest={true}/>
            :
            null
          }
          {
            customer == null
            ?
            null
            :
            customer.id == 3
            ?
            <RealEstateNavbar />
            :
            <NavBar />
          }
          { isSuperadmin && <SwitchOrganization /> }
        </header>
        <main id="app-wrapper">
          <FlashMessage />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/guest" component={CadsPage} />
            <Route path="/share-properties" component={PropertiesPage}/>
            <Route path="/share-property/:id" component={ShareProperty}/>
            <PrivateRoute path="/properties" component={PropertiesPage} permissions={['admin']}/>
            <PrivateRoute path="/property/:id" component={EditProperty} permissions={['admin']}/>
            <PrivateRoute path="/cads/long-style-numbers" component={LongStyleNumbersPage} permissions={PREVILIGES.CADS}/>
            <PrivateRoute path="/cads/upc" component={ImportUpcPage} permissions={PREVILIGES.CADS}/>
            <PrivateRoute path="/cads/generate-style-number" component={GenerateStyleNumberPage} permissions={PREVILIGES.CADS}/>
            <PrivateRoute path="/cads/style-codes-table" component={StyleCodesTablePage} permissions={PREVILIGES.CADS}/>
            <PrivateRoute path="/cads/new" component={UploadCadPage} permissions={PREVILIGES.CADS}/>
            <PrivateRoute path="/cads" component={CadsPage} permissions={PREVILIGES.CADS}/>
            <PrivateRoute path="/view-cad/:id" component={ViewCad} permissions={PREVILIGES.CADS}/>
            <PrivateRoute path="/edit-cad/:id" component={EditCad} permissions={PREVILIGES.CADS}/>
            <PrivateRoute path="/contacts" exact component={AccountsList} permissions={PREVILIGES.CONTACT}/>
            <PrivateRoute path="/contacts2" exact component={ContactsPage} permissions={PREVILIGES.CONTACT}/>
            <PrivateRoute path="/contacts2/:id/edit" component={EditContact} permissions={PREVILIGES.CONTACT}/>
            <PrivateRoute path="/contacts/new" exact component={AccountNew} permissions={PREVILIGES.CONTACT}/>
            <PrivateRoute path="/accounts/reps" exact component={AccountReps} permissions={PREVILIGES.ACCOUNTREP} />
            <PrivateRoute path="/accounts/edit" exact component={EditCompanies} permissions={PREVILIGES.ACCOUNT} />
            <PrivateRoute path="/contacts/:id" component={AccountView} permissions={PREVILIGES.CONTACT}/>
            <PrivateRoute path="/users" component={OrganizationsPage} permissions={PREVILIGES.ORGANIZATION}/>
            <PrivateRoute path="/reports" component={ReportsPage} permissions={PREVILIGES.ORGANIZATION}/>
            <PrivateRoute path="/sales-orders" exact component={PurchaseOrders} permissions={PREVILIGES.SALESORDER}/>
            <PrivateRoute path="/sales-orders/new" exact component={SalesOrderPage} permissions={PREVILIGES.SALESORDER} />
            <PrivateRoute path="/distros/new" exact component={NewDistroPage} permissions={PREVILIGES.SALESORDER} />
            <PrivateRoute path="/packing-slips/new" exact component={NewPackingSlipPage} permissions={PREVILIGES.SALESORDER} />
            <PrivateRoute path="/bols/new" exact component={NewBolPage} permissions={PREVILIGES.SALESORDER} />
            <PrivateRoute path="/mark_shipped" exact component={MarkShippedPage} permissions={PREVILIGES.SALESORDER} />
            <PrivateRoute path="/sales-orders/new2" exact component={NewPurchaseOrder} permissions={PREVILIGES.SALESORDER} />
            <PrivateRoute path="/sales-orders/:id/edit" component={EditSalesOrder} permissions={PREVILIGES.SALESORDER}/>
            <PrivateRoute path="/sales-orders/:id/view" component={EditSalesOrder} permissions={PREVILIGES.SALESORDER}/>
            <PrivateRoute path="/sales-orders/:poId/edit2" component={EditPurchaseOrder} permissions={PREVILIGES.SALESORDER}/>
            <PrivateRoute path="/bol-pod-entry" component={BolPodPage} permissions={PREVILIGES.BOLPOD}/>
            <Route path="/progress/:id" component={Confirmation} />
            <PrivateRoute path="/vendor-orders" exact component={VendorOrdersPage} permissions={PREVILIGES.PRODUCTION}/>
            <PrivateRoute path="/vendor-orders/new" exact component={NewVpoPage} permissions={PREVILIGES.PRODUCTION}/>
            <PrivateRoute path="/vendor-orders/:id/edit" component={EditVendorOrder} permissions={PREVILIGES.PRODUCTION}/>
            <PrivateRoute path="/vendor-orders/add-fabrication" component={AddFabricationPage} permissions={PREVILIGES.PRODUCTION}/>
            <PrivateRoute path="/prints" component={PrintsPage} permissions={PREVILIGES.CADS}/>
            <PrivateRoute path="/artists/new" component={NewArtistPage} permissions={PREVILIGES.ARTISTS}/>
            <PrivateRoute path="/artists" component={ArtistsPage} permissions={PREVILIGES.ARTISTS}/>
          </Switch>
        </main>
      </HashRouter>
    </div>
  );
}

function Confirmation({ match }) {
  return (
    <ConfirmationPage id={match.params.id}/>
  )
}

function EditSalesOrder({ match }) {
  return (
    <SalesOrderPage id={match.params.id}/>
  )
}

function EditVendorOrder({ match }) {
  return (
    <NewVpoPage id={match.params.id}/>
  )
}

function EditContact({ match }) {
  return (
    <EditContactPage id={match.params.id}/>
  )
}

function NewDistroPage() {
  return (
    <NewPackingSlipOrBolPage fileType='distro' title='distro'/>
  )
}

function NewPackingSlipPage() {
  return (
    <NewPackingSlipOrBolPage fileType='packing_slip' title='packing slip'/>
  )
}

function NewBolPage() {
  return (
    <NewPackingSlipOrBolPage fileType='bol' title='BOL'/>
  )
}

function MarkShippedPage() {
  return (
    <NewPackingSlipOrBolPage fileType='shipped' title='Mark as Shipped'/>
  )
}

function Login() {
  clearTimeout(window.guestTimeout)
  window.guestTimeout = setTimeout(() => {
    let token = window.location.href.split('?t=')[1]
    if (token != '' && token != null) {
      //call api login as guest
      //token must be less than 24 hrs and will return the filter data...
      let url = process.env.REACT_APP_API_URL + '/api/have_cad_link'
      $.post( url, {token},
        (data) => {
          if (data.already_used) {
            alert('Sorry the link has already been used')
          } else if (data.expired) {
            alert('Sorry the link has expired')
          } else {
            window.localStorage.setItem('guestMaxAts', data.max_ats)
            window.localStorage.setItem('guestMaxFutureAts', data.max_future_ats)
            window.localStorage.setItem('guestMinAts', data.min_ats)
            window.localStorage.setItem('guestMinFutureAts', data.min_future_ats)
            window.localStorage.setItem('guestSalesHistoryAccount', data.sales_history_account)

            window.guestFilters = data.filters
            window.localStorage.setItem('isGuest', 'TRUE')
            window.localStorage.setItem('user', JSON.stringify({email: 'Guest', token: token}))
            window.localStorage.setItem('currentUserRole', 'showroom')
            window.localStorage.setItem('currentUserEmail', 'Guest')

            window.location = '/guest'
          }
        }
      )
    }
  }, 3000)


  return (
    <div style={{height: 'calc(100vh - 70px)', background: 'url(' + process.env.REACT_APP_HOME_IMG_SRC + ')'}}>
      <h1 style={{fontWeight: 'lighter', textAlign: 'center', paddingTop: 'calc(50vh - 100px)'}} className="cursive">Checking for guest link...</h1>
    </div>
  )
}

function Home() {
  return (
    <HomePage />
  )
}

function ViewCad({ match }) {
  return (
    <ViewCadPage id={match.params.id}/>
  )
}

function EditCad({ match }) {
  return (
    <EditCadPage id={match.params.id}/>
  )
}

function ShareProperty({ match }) {
  return (
    <SharePropertyPage id={match.params.id}/>
  )
}

function EditProperty({ match }) {
  return (
    <EditPropertyPage id={match.params.id}/>
  )
}

Object.defineProperty(String.prototype, 'trimUpcase', {
    value() {
        return this.trim().toUpperCase();
    }
})

Object.defineProperty(String.prototype, 'trimFlattenUpcase', {
    value() {
        return this.toUpperCase().replace(/\p{Space}/g,' ').replace(/  +/g, ' ').trim()
    }
})

//equivalent to StockReport::normalize_style_number in ruby
Object.defineProperty(String.prototype, 'flatUpcase', {
    value() {
        return this.toUpperCase().replace(/:/g, '/').replace(/\p{Space}/g,' ').replace(/  +/g, ' ').replace(/_/g, '-').replace(/ - /g, '-').replace(/ -/g, '-').replace(/- /g, '-').replace(/--/g, '-').trim();
    }
})

Object.defineProperty(String.prototype, 'noSpaces', {
    value() {
        return this.toUpperCase().replace(/\p{Space}/g,'').replace(/ /g,'')
    }
})

Object.defineProperty(Number.prototype, 'totalFormat', {
    value() {
        return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
})

Object.defineProperty(Array.prototype, 'optionsHashTextAsKey', {
    value() {
        return this.map((x) => {return { key: x.text, text: x.text, value: x.text }})
    }
})

Object.defineProperty(Date.prototype, 'localeDateShortenYear', {
    value() {
        return (
            this.toLocaleDateString().replace('2020', '20').replace('2021', '21')
              .replace('2022', '22').replace('2023', '23').replace('2024', '24')
              .replace('2025', '25').replace('2026', '26').replace('2027', '27')
              .replace('2028', '28').replace('2029', '29')
        )
    }
})

export default App;
