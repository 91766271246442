import React, {Component} from 'react'
import {checkStyleNumber} from './save-style-number'
import {Loader} from 'semantic-ui-react'

class StyleNumberError extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      styleNumberLocal: this.props.styleNumber,
      loading: false,
      data: {cadAlreadyExists: false, alreadySaved: false}
    }

    this.callApi = this.callApi.bind(this)
    this.checkStyleNumberSuccess = this.checkStyleNumberSuccess.bind(this)
  }

  callApi() {
    let {styleNumber, type} = this.props
    this.setState({styleNumberLocal: styleNumber, loading: true})
    checkStyleNumber(styleNumber, type, this.checkStyleNumberSuccess)
  }

  checkStyleNumberSuccess(data) {
    this.setState({data, loading: false})
  }

  render() {
    let {styleNumber, type} = this.props
    let {styleNumberLocal, loading, data} = this.state
    let {cadAlreadyExists, alreadySaved, printAlreadyExists} = data

    if (styleNumber != styleNumberLocal) {
      clearTimeout(window.styleOnChangeTimeout)
      window.styleOnChangeTimeout = setTimeout(this.callApi, 1000)
    }

    if (loading) {
      return (
        <Loader active={loading} inline/>
      )
    } else if (cadAlreadyExists || alreadySaved || printAlreadyExists) {
      return (
        <p style={{fontSize: '60%', fontWeight: 'bold', color: 'red', background: 'yellow', display: 'inline-block'}}>
          {cadAlreadyExists ? 'Cad with Style Number already exists!' : ''}
          {alreadySaved ? 'Already saved Style Number!' : ''}
          {printAlreadyExists ? type + ' with Style Number already exists!' : ''}
        </p>
      )
    } else {
      return null
    }
  }
}

export default StyleNumberError
