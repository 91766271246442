const mapOptions = (array) => {
  return array.map((x) => {return {key: x, text: x, value: x} })
}

const upfrontOptions = mapOptions(
  [
    'Show All',
    'NO, From Stock',
    'YES, Upfront',
    'Missing',
  ]
)

const ticketedOptions = mapOptions(
  [
    'Show All',
    'NO',
    'Ordered',
    'Not Ordered',
  ]
)

const marginsOptions = mapOptions(
  [
    'Show All',
    'A 55%+',
    'B 45%-54%',
    'C 36%-44%',
    'D 25%-35%',
    'E -24%',
    'Missing'
  ]
)

const monthsOptions = mapOptions(
  [
    'Show All',
    'January 2024+',
    'December 2023',
    'November 2023',
    'September 2023',
    'October 2023',
    'August 2023',
    'July 2023',
    'June 2023',
    'May 2023',
    'April 2023',
    'March 2023',
    'February 2023',
    'January 2023',
    'December 2022',
    'November 2022',
    'September 2022',
    'October 2022',
    'August 2022',
    'July 2022',
    'June 2022',
    'May 2022',
    'April 2022',
    'March 2022',
    'February 2022',
    'January 2022',
  ]
)

export {upfrontOptions, ticketedOptions, marginsOptions, monthsOptions}
