import React from 'react'

const longDash = <span style={{opacity: 0.05}}>  —  </span>

const getPonumberContent = (order) => {
  return (
    <React.Fragment>{order.refnumber == null ? null : <span style={{whiteSpace: 'nowrap'}}>{longDash} REF#: <u>{order.refnumber}</u></span>} {!!order.hold && order.refnumber != null ? null : <span style={{whiteSpace: 'nowrap'}}>{longDash} PO#: <u>{order.ponumber}</u></span>}</React.Fragment>
  )
}

export {getPonumberContent, longDash}
