const orgName = (orgId) => {
  switch(orgId) {
    case 3:
      return 'Gotham Assets'
    case 4:
      return 'Chuck Norris Assets'
    default:
      // code block
  }
}

const missingImageUrl = 'https://erp-rails-usa-production.s3.amazonaws.com/real_estate/no_property_images.png'

const getFilename = (filename, cropped = false) => {
  if (filename == null) {
    return missingImageUrl
  } else if (cropped) {
    let parts = filename.split('/')
    parts.pop()
    parts.push('cropped.jpg')
    return parts.join('/')
  } else {
    return filename
  }
}

const orgNameCamel = (orgId) => {
  return camelize(orgName(orgId))
}

const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '')
}

const logoPath = (orgId) => {
  switch(orgId) {
    case 3:
      return 'https://erp-rails-usa-production.s3.amazonaws.com/real_estate/gotham_logo.jpg'
    case 4:
      return 'https://erp-rails-usa-production.s3.amazonaws.com/real_estate/chuck-norris-logo.jpg'
    default:
      // code block
  }
}

const minRoomsInitial = 1
const maxRoomsInitial = 200
const minBuildingSizeInitial = 1
const maxBuildingSizeInitial = 100000

const buildingTypes = [
  {text: 'R = Residential', icon: 'home'},
  {text: 'F = Office', icon: 'building outline'},
  {text: 'D = Industrial', icon: 'factory'},
  {text: 'H = Hotel', icon: 'hotel'},
  {text: 'T = Retail', icon: 'shopping bag'}
]

const sources = [
  {text: 'B = Broker', icon: 'user outline'},
  {text: 'R = Referred', icon: 'reply'},
  {text: 'K = Bankruptcy', icon: 'dollar sign'},
  {text: 'E = Estate', icon: 'tag'},
  {text: 'A = Auction', icon: 'legal'},
]

const getOptions = arr => arr.sort((a, b) => {
  if (a.text.slice(4) > b.text.slice(4)) return 1
  if (b.text.slice(4) > a.text.slice(4)) return -1
  return 0
}).map((x) => {return {key: x.text, text: x.text, value: x.text, icon: x.icon}})

const buildingTypeOptions = getOptions(buildingTypes)
const sourceOptions = getOptions(sources)

const getOptionsShort = (y) => {
  return y.map((x) => {return {key: x.key, text: x.text.slice(4), value: x.value, icon: x.icon}})
}

const buildingTypeOptionsShort = getOptionsShort(buildingTypeOptions)
const sourceOptionsShort = getOptionsShort(sourceOptions)

export {orgName, getFilename, logoPath, orgNameCamel, buildingTypes, buildingTypeOptions, sources, sourceOptions,
  buildingTypeOptionsShort, sourceOptionsShort, minRoomsInitial, maxRoomsInitial, minBuildingSizeInitial, maxBuildingSizeInitial
}
