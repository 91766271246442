import React, {Component} from 'react'
import {Accordion, Icon, Label} from 'semantic-ui-react'
import {roundToPenny} from './round-to-penny'
import {totalDollar, reducer} from './total-dollar'

class VpoTotals extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      active: false
    }

    this.toggleAccordion = this.toggleAccordion.bind(this)
  }

  toggleAccordion() {
    this.setState({active: !this.state.active})
  }

  render() {
    let {active} = this.state
    let {results} = this.props

    return (
      <div style={{marginTop: '20px'}}>
        {
          results.length > 0
          ?
          <Accordion>
            <Accordion.Title onClick={this.toggleAccordion} style={{width: '100px'}}>
              <Icon name='dropdown' />
              Totals
            </Accordion.Title>
            <Accordion.Content active={active} style={{marginBottom: '30px'}}>
              <Label># VPOs<Label.Detail>{results.length}</Label.Detail></Label>
              &nbsp;&nbsp;
              <Label>QTY
                <Label.Detail>
                  {results.map(x => Number(x.qty)).reduce(reducer).totalFormat()}
                </Label.Detail>
              </Label>
              &nbsp;&nbsp;
              <Label>$
                <Label.Detail>
                  {results.map(x => totalDollar(x)).reduce(reducer).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </Label.Detail>
              </Label>
            </Accordion.Content>
          </Accordion>
          :
          null
        }
      </div>
    )
  }
}

export default VpoTotals
