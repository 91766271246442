import Utils from "../utils"

class BolPodAPI
{
  static bulkCreate(data, cb)
  {
    Utils.post('bol_pods/bulk_create.json', data, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static getAll(cb, limit=null)
  {
    let url = limit ? `bol_pods.json?limit=${limit}` : "bol_pods.json"

    Utils.get(url, (err, res) =>
    {
      if(err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }
}

export default BolPodAPI
