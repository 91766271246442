import React       from 'react';
import { Dropdown }from 'semantic-ui-react';
import {find}      from 'lodash'

const sortingOptions =
[
  {key: 10, value: "__", text: "None"},
  {key: 1, value: "account_name__asc", text: "Account (ascending)"},
  {key: 2, value: "account_name__desc", text: "Account (descending)"},
  {key: 3, value: "created_at__asc", text: "Created Date (ascending)"},
  {key: 4, value: "created_at__desc", text: "Created Date (descending)"},
  {key: 5, value: "last_activity_date__asc", text: "Last Activity Date (ascending)"},
  {key: 6, value: "last_activity_date__desc", text: "Last Activity Date (descending)"},
]

export const DataSortSelect = props => {

  let handleChange = (e, { name, value }) =>
  {
    let _value = value.split("__")
    let selected = {_sort: _value[0], _order: _value[1]}
    props.handleSort(null, selected)
  }

  let getSelected = (selected) =>
  {
    let defaultValue = "__"
    let _selected = [selected._sort, selected._order].join('__')
    let selectedOption = find(sortingOptions, { 'value': _selected });
    if (selectedOption)
    {
      return _selected
    }
    else{
      return defaultValue
    }
  }

  return (
    <React.Fragment>
      <div style={{display: 'inline-block', marginBottom:'20px'}}>Sort by: &nbsp; {' '}</div>
      <Dropdown
        inline
        options={sortingOptions}
        value={getSelected(props.selected)}
        onChange={handleChange}
        direction='left'
      />
    </React.Fragment>
  )
};
