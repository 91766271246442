import React, {Component} from 'react'
import {Popup, Icon} from 'semantic-ui-react'

let infoCircle = (content) => {
  return (
    <Popup content={content} position='top right' trigger={
      <Icon name="info circle" style={{color: 'green', marginLeft: '5px', cursor: 'pointer'}}/>
    } />
  )
}

const immediateInfoCircle = infoCircle('Immediate status is at least 25 ATS or has Loading Date with Future ATS')
const futureAtsConfirmedInfoCircle = infoCircle('Future ATS Confirmed status is at least 25 Future ATS and IS NOT PENDING PI')
const oversoldInfoCircle = infoCircle('Oversold status is -25 Future ATS or LESS')
const doNotSellInfoCircle = infoCircle('Do Not Sell status has issue reported with Container # or Style #')

class StatusInfoCircle extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    const {cadStatus} = this.props

    switch(cadStatus) {
      case 'Immediate':
        return immediateInfoCircle

        break
      case 'Future ATS Confirmed':
        return futureAtsConfirmedInfoCircle

        break
      case 'Oversold':
        return oversoldInfoCircle

        break
      case 'Do Not Sell':
        return doNotSellInfoCircle

        break
      default:
        return null
    }
  }
}

export {StatusInfoCircle, infoCircle}
