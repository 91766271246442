import React from 'react'

const dateLabel = (text, styleAdd = {}, isNull = false) => {
  const style = Object.assign({}, {fontSize: '10px', marginBottom: '-4px', fontWeight: 'bold'}, styleAdd)

  if (isNull) {
    style['opacity'] = 0
  }

  return <div style={style}>{text}</div>
}

const optionalText = <span style={{opacity: 0.5, fontStyle: 'italic', textTransform: 'lowercase', marginLeft: '10px'}}>(optional)</span>


export {dateLabel, optionalText}
