import React from 'react'

const processAtsDetail = (atsDetail, warehouse) => {
  let newAtsDetail = []

  let atsDetailRows = atsDetail.split(',')

  atsDetailRows.forEach(row => {
    let parts = row.split('—')
    let qty = Number(parts[0])
    let site = parts[1].trim()
    let date = parts[2].trim()
    if (warehouse.length == 0 || warehouse.includes(site)) {
      newAtsDetail.push([qty, site, date])
    }
  })

  return newAtsDetail
}

const warehouseAvailable = (atsDetail, warehouse) => {
  let newAtsDetail = processAtsDetail(atsDetail, warehouse).map(x => x[0])

  if (newAtsDetail.length == 0) {
    return 0
  } else {
    let available = newAtsDetail.reduce((accumulator, currentValue) => accumulator + currentValue)

    return available
  }
}

const atsDetailText = (atsDetail) => {
  let style = {paddingRight: '20px', whiteSpace: 'nowrap', display: 'inline-block'}
  return processAtsDetail(atsDetail, []).map(x => <div style={style}>{x[0]} — {x[1]}</div>)
}

export {processAtsDetail, warehouseAvailable, atsDetailText}
