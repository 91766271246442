import Utils from "../utils"

class AccountAPI
{
    static create(data, cb)
    {
        Utils.post('accounts', data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static update(id, data, cb)
    {
        Utils.put(`accounts/${id}`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static updateReps(id, data, cb)
    {
        Utils.post(`accounts/${id}/change_reps`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getAllAccountsReps(cb)
    {
        Utils.get(`account_reps`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static get(id, cb)
    {
        Utils.get(`accounts/${id}`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getAll(cb)
    {
        Utils.get(`accounts`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static createNote(accountId, data, cb)
    {
        Utils.post(`accounts/${accountId}/notes`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static deleteNote(accountId, id, cb)
    {
        Utils.delete(`accounts/${accountId}/notes/${id}`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static downloadNoteFile(accountId, id, cb)
    {
        Utils.get(`accounts/${accountId}/notes/${id}/download_file`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getEmails(accountId, cb)
    {
        Utils.get(`accounts/${accountId}/emails`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static createEmail(accountId, data, cb)
    {
        Utils.post(`accounts/${accountId}/emails`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getTasks(accountId, cb)
    {
        Utils.get(`accounts/${accountId}/tasks`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static createTask(accountId, data, cb)
    {
        Utils.post(`accounts/${accountId}/tasks`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static toggleTaskCompleteness(accountId, id, cb)
    {
        Utils.post(`accounts/${accountId}/tasks/${id}/toggle_completeness`, null, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getEvents(accountId, cb)
    {
        Utils.get(`accounts/${accountId}/events`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static createEvent(accountId, data, cb)
    {
        Utils.post(`accounts/${accountId}/events`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getAccountActivities(accountId, data, cb)
    {
        Utils.post(`accounts/${accountId}/activities/search`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }
}

export default AccountAPI
