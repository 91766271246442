const cadHash = {}

const copyCadToVendorOrders = () => {
  for (let k=0; k<window.cads.length; k++) {
    let cad = window.cads[k];
    let cad_style_number = cad.style_number.flatUpcase();
    cadHash[cad_style_number] = cad;
  }

  window.vendorOrders = window.vendorOrders.map(putCadOnOrder)
  window.archivedVpos = window.archivedVpos.map(putCadOnOrder)
}

const putCadOnOrder = (order) => {
  let cad = cadHash[order.style_number]
  order.cad = (cad == null ? {} : cad)
  return order
}

export default copyCadToVendorOrders
