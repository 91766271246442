import {arrayToOptions} from '../reports/array-to-options.js'
import {sizeRatios} from './sizes'
import {uniqueFactories, factoryNames} from './factories'

const getOptions = (name, isCad = false, isFactory = false) => {
  let options = [...new Set(window.vendorOrders.map(row => (isCad ? row.cad[name] : row[name])))].filter(x => x != null)

  return options.sort(function(a, b) {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1
    }
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1
    }

    return 0
  }).map(x => {return (isFactory ? { key: x, text: putFactoryName(x), value: x } : { key: x, text: x, value: x })})
}

const getVendorOrdersOptions = () => {
  return {
    buyerOptions: getOptions('buyer_name'),
    factoryOptions: getOptions('factory_id', false, true),
    statusOptions: statusOptions
  }
}

const getCadsOptions = () => {
  return {
    brandOptions: getOptions('brand', true),
    categoryOptions: getOptions('parent_category', true),
    divisionOptions: getOptions('department', true)
  }
}

const putFactoryName = (text) => {
  if (text.length == 4) {
    return text+' ('+factoryNames[text]+')'
  } else {
    return text
  }
}

const arrayToOptionsFactory = (arr) => {
  return arr.map(x => {return ({ key: x, text: putFactoryName(x), value: x })})
}

const factoryOptions = arrayToOptionsFactory(uniqueFactories)

const shippingClasses = [
  'NO',
  'FOB CALIFORNIA',
  'FOB CAMBODIA',
  'FOB CHINA',
  'FOB NY',
  'POE'
]

const shippingClassOptions = arrayToOptions(shippingClasses)

const tickets = [
  'NO',
  'YES FACTORY',
  'YES-LA',
  'YES-CHINA/LA'
]

const ticketsOptions = arrayToOptions(tickets)

const buyers = [
  'Pending',
  'Famy',
  'Kelly',
  'Kitty',
  'Morde',
  'Patty',
  'Shirley',
  'Yulu'
]

const buyerOptions = arrayToOptions(buyers)


const reorderOptions = arrayToOptions([
  'YES',
  'NO'
])

const statuses = [
  'Pending',
  'Processed',
  'QB-Entered',
  'Ready-To-Ship',
  'Shipped',
  'Cancelled'
]

const statusOptions = arrayToOptions(statuses)

const getSizeRatioOptions = () => {
  let {prepackSizes} = window
  if (prepackSizes == null) {prepackSizes = []}
  return arrayToOptions(prepackSizes.concat(sizeRatios))
}

const at = [
  'LDP',
  'DDP',
  'FOB'
]

const atOptions = arrayToOptions(at.sort())

const currencies = [
  'USD',
  'RMB'
]

const currencyOptions = arrayToOptions(currencies.sort())

const countries = ['BANGALADESH', 'CAMBODIA', 'CHINA', 'EGYPT', 'INDIA', 'PAKISTAN', 'USA']
const countryOptions = arrayToOptions(countries)

const confirm = ['CONFIRM', 'CONFIRM & EMAIL', 'CONFIRM & EXCEL', 'CONFIRM & PDF', 'CONFIRM & PRINT']
const confirmOptions = arrayToOptions(confirm)

export {
  getVendorOrdersOptions, getCadsOptions, factoryOptions, buyerOptions, reorderOptions, getSizeRatioOptions,
  shippingClassOptions, ticketsOptions, atOptions, currencyOptions, countryOptions, confirmOptions, statusOptions,
  factoryNames
}
