import React, {Component} from 'react'
import {Dropdown} from 'semantic-ui-react'

class ConferenceDropdown extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {isAuthenticated, user, conferenceMenu, setConferenceMenu, Nav} = this.props

    if (isAuthenticated && user && ['design', 'admin', 'sales', 'production', 'inventory'].includes(user.role)) {
      return (
        <span
          onMouseOver={(e) => setConferenceMenu(true)}
          onMouseOut={(e) => setConferenceMenu(false)}
        >
        <Dropdown
          item
          as={Nav}
          open={conferenceMenu}
          icon=''
          to='_'
          name="cads"
          text="Conference Rooms"
          style={{background: 'transparent', cursor: 'default'}}
          onClick={(e) => {e.preventDefault();}}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              as={Nav}
              to="https://ihlgroup.us/conference"
              content="Reserve"
              onClick={(e) => {e.preventDefault();window.open('https://ihlgroup.us/conference')}}
            />
            <Dropdown.Item
              as={Nav}
              to="https://ihlgroup.us/calendar"
              content="Calendar"
              onClick={(e) => {e.preventDefault();window.open('https://ihlgroup.us/calendar')}}
            />
          </Dropdown.Menu>
        </Dropdown>
        </span>
      )
    } else {
      return null
    }
  }
}

export default ConferenceDropdown
