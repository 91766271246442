import React from 'react'
import {Label} from 'semantic-ui-react'
import {getIcon} from '../../edit-sales-order/order-summary/item-status-order-summary.js'

const getStatusMarkup = (status, style = {}) => {
  let color = getStatusColor(status)
  return <Label color={color} style={style}>{getIcon(status)} {status}</Label>
}

const getStatusColor = (status) => {
  let color = 'grey'

  switch(status)
  {
    case "Pending":
    {
      color = 'yellow'
      break
    }
    case "Processed":
    {
      color = 'blue'
      break
    }
    case "QB-Entered":
    {
      color = 'orange'
      break
    }
    case "Needs-Fulfillment":
    {
      color = 'pink'
      break
    }
    case "Routed":
    {
      color = 'olive'
      break
    }
    case 'Ready-To-Ship':
    {
      color = 'brown'
      break
    }
    case 'In-Progress':
    {
      color = 'purple'
      break
    }
    case "Partially-Shipped":
    {
      color = 'red'
      break
    }
    case "Shipped":
    {
      color = 'green'
      break
    }
    case "Invoiced":
    {
      color = 'teal'
      break
    }
    case "Delayed":
    {
      color = 'blue'
      break
    }
    case "Cancelled":
    {
      color = 'black'
      break
    }
  }
  return color
}

const getStatusColorRGB = (status) => {
  let color = getStatusColor(status)
  let result = [0,0,0]

  switch(color) {
    case 'green':
      result = [89,182,85]
      break
    case 'grey':
      result = [118,118,118]
      break
    case 'orange':
      result = [227,119,55]
      break
    case 'red':
      result = [202,59,51]
      break
    case 'purple':
      result = [150,66,193]
      break
    case 'brown':
      result = [156,105,70]
      break
    case 'yellow':
      result = [243,189,67]
      break
    default:
  }

  return 'rgb(' + result.join(',') + ')'
}

export {getStatusMarkup, getStatusColor, getStatusColorRGB}
