import React, {Component} from 'react'
import {Icon, Menu, Popup} from 'semantic-ui-react'

let userCircleStyle = {width: '50px', height: '50px', marginTop: '-13px', fontSize: '34px', marginBottom: '-33px', opacity: '0.25', cursor: 'pointer'}


class UserDropdown extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {userAttribution, logoutSession} = this.props

    return (
      <Popup wide trigger={
        <Menu.Item
          icon="user circle"
          style={userCircleStyle}
        />
      } on='click' position='bottom right'>
        <Icon name='user circle' size='big' style={{color: 'rgb(224,224,224)', zoom: '125%'}}/> {userAttribution}
        <div style={{textAlign: 'center', cursor: 'pointer', paddingTop: '10px'}}><a onClick={logoutSession}>Sign out</a></div>
      </Popup>
    )
  }
}

export default UserDropdown
