import $ from 'jquery'

const canApproveBrandsList = [
  'etty@ihlgroup.com',
  'tony@ihlgroup.com',
  'design@example.com'
]

const checkMissingFields = (cadIds) => {
  let {cads} = window
  let numberKeys = cadIds.map(Number)

  for (var i=0;i<cads.length;i++) {
    if (numberKeys.indexOf(cads[i].id) > -1) {
      let {fabrication} = cads[i]

      let missingFabrication = ['', null].includes(fabrication)

      if (missingFabrication) {
        return true
      }
    }
  }

  return false
}

const requestBrandApproval = (cadIds, clearCadCard) => {
  if (checkMissingFields(cadIds)) {
    alert('Missing fabrication on selected cads!')
  } else {
    if (window.confirm('Are you sure you want to Request Brand Approval for Cad' + (cadIds.length == 1 ? '' : 's') + '?') ) {
      $.ajax({
        type: 'POST',
        url: process.env.REACT_APP_API_URL + '/api/request_brand_approval',
        data: {
          cad_ids: cadIds
        },
        success: (data) => {
          alert('You have successfully Requested Brand Approval for Cad' + (cadIds.length == 1 ? '' : 's'))

          //update realtime!!!
          let newCads = window.cads
          let numberKeys = cadIds.map(Number)
          let today = new Date()
          let parts = today.toLocaleDateString().split('/')
          let dateNow = parts[2] + '-' + parts[0] + '-' +  parts[1]

          for (var i=0;i<newCads.length;i++) {
            if (numberKeys.indexOf(newCads[i].id) > -1) {
              if (newCads[i].is_branded_date == dateNow || newCads[i].is_branded_date_second_request == dateNow || newCads[i].is_branded_date_third_request == dateNow) {
                continue
              }

              if (newCads[i].is_branded_date == null) {
                newCads[i].is_branded_date = dateNow
              } else if (newCads[i].is_branded_date_second_request == null) {
                newCads[i].is_branded_date_second_request = dateNow
              } else if (newCads[i].is_branded_date_third_request == null) {
                newCads[i].is_branded_date_third_request = dateNow
              } else {
                //ran out of requests!
              }

              newCads[i].is_branded = true
              newCads[i].is_licensee_approved = null
            }
          }

          window.cads = newCads

          clearCadCard(true)
        },
        error: (error) => {
          alert('There was an error when trying to Request Brand Approval for Cads')
        },
      })
    }
  }
}

const approveCadLicense = (cadIds, approved, clearCadCard) => {
  if (window.confirm('Are you sure you want to ' + (approved ? 'Approve' : 'Reject') + ' Brand for Cad' + (cadIds.length == 1 ? '' : 's') + '?') ) {
    $.ajax({
      type: 'POST',
      url: process.env.REACT_APP_API_URL + '/api/approve_cad_license',
      data: {
        cad_ids: cadIds,
        approved: approved
      },
      success: (data) => {
        alert('You have successfully ' + (approved ? 'Approved' : 'Rejected') + ' Brand for Cad' + (cadIds.length == 1 ? '' : 's'))

        //update realtime!!!
        let newCads = window.cads
        let numberKeys = cadIds.map(Number)

        for (var i=0;i<newCads.length;i++) {
          if (numberKeys.indexOf(newCads[i].id) > -1) {
            newCads[i].is_licensee_approved = approved
            newCads[i].is_branded = false
            let today = new Date()
            newCads[i].is_licensee_approved_date = today.toISOString().split('T')[0]
          }
        }

        window.cads = newCads

        clearCadCard(true)
      },
      error: (error) => {
        alert('There was an error when trying to ' + (approved ? 'Approve' : 'Reject') + ' Brand for Cads')
      },
    })
  }
}

export {requestBrandApproval, approveCadLicense, canApproveBrandsList}
