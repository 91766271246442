import React from 'react'
import {Modal, Button, TextArea} from 'semantic-ui-react'
import {files} from './files.js'

const modal = (open, closeModal, saveNote, filenames, addFilename, removeFilename) => {
  return (
    <Modal
      onClose={closeModal}
      open={open}
    >
      <Modal.Header>Add Note</Modal.Header>
      <Modal.Content>
        <TextArea placeholder='Enter note...' rows={5} id="addContactNote"/>
        {files('notes', filenames, addFilename, removeFilename)}
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={closeModal}>
          Cancel
        </Button>
        <Button
          content="Save note"
          labelPosition='right'
          icon='checkmark'
          onClick={saveNote}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export {modal}
