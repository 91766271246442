import React, {useState, useEffect, useContext} from "react"
import createAuth0Client                        from "@auth0/auth0-spa-js"
import UserAPI                                  from "./../modules/api/user_api"
import http                                     from "./../modules/common/http"
import {mergeFabrications} from './merge-fabrications'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [customer, setCustomer] = useState();
  const [message, setMessage] = useState(null);
  const [purchaseOrderFilters, setPurchaseOrderFilters] = useState(null);
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      let sharePropertyGuest = window.location.href.includes('/share-p')
      if (sharePropertyGuest) {
        //do nothing
      } else if (isAuthenticated) {
        await setLocalUser(auth0FromHook);
      } else {
        let goToHostedLoginPageEvent = new Event('goToHostedLoginPage')
        window.dispatchEvent(goToHostedLoginPageEvent)
      }
      setLoading(false);
    };
    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    await setLocalUser(auth0Client);
    setIsAuthenticated(true);
  }

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    await setLocalUser(auth0Client);
    setLoading(false);
    setIsAuthenticated(true);
  }

  const setLocalUser = async (client) => {
    let user    = await client.getUser();
    const token = await client.getIdTokenClaims();
    user.role   = token['https://role.auth0'] || null

    // Set http headers in request object
    localStorage.setItem('id_token', token.__raw)
    http.defaults.headers.Authorization = 'Bearer '+ token.__raw
    var heads = JSON.parse(window.sessionStorage.getItem('headers'))
    for (var head in heads)
    {
      http.defaults.headers.common[head] = heads[head]
    }

    if (user)
    {
      UserAPI.getCurrentUser((error, data) => {
        if (error)
        {
          alert("alert in setting user")
        }
        else
        {
          user = {...user, ...data}
          sessionStorage.setItem('user', JSON.stringify(user))
          sessionStorage.setItem('currentUserRole', user.role)
          sessionStorage.setItem('currentUserEmail', user.email)
          setUser(user)

          let {customAccess} = user
          if (customAccess != null) {
            window.currentUserCustomAccess = customAccess.data
            sessionStorage.setItem('customAccess', JSON.stringify(customAccess.data))
          }

          window.gtag('config', 'G-811BTD6G7C', {
            'user_id': user.id
          });


          // if (window.location.href == "https://ihl.ihlerp.com/#/") {
          //   window.location = "https://ihl.ihlerp.com/#/cads"
          // }
        }
      })

      UserAPI.getCurrentCustomer((error, data) => {
        if (error)
        {
          alert("alert in setting customer")
        }
        else
        {
          window.prepackSizes = data.prepackSizes
          window.factories = data.factories
          setCustomer(mergeFabrications(data.customerData, data.fabrications))
        }
      })
    }
  }

  return (
    auth0Client ? (
      <Auth0Context.Provider
        value={{
          isAuthenticated,
          user,
          customer,
          message,
          purchaseOrderFilters,
          loading,
          popupOpen,
          loginWithPopup,
          handleRedirectCallback,
          setLocalUser,
          setUser,
          setCustomer,
          setMessage,
          setPurchaseOrderFilters,
          getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
          loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
          getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
          getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
          logout: (...p) => auth0Client.logout(...p)
        }}
      >
        {children}
      </Auth0Context.Provider>
    ) : null
  );
}
