import React, {Component} from 'react'
import {Statistic, List, Icon} from 'semantic-ui-react'
import Utils from '../../modules/utils'

class YourStats extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}

  }

  render() {



    return (
      <div style={{width: '80vw', margin: 'auto', background: 'rgba(255,255,255,0.7)', borderRadius: '2px', paddingBottom: '40px', paddingTop: '10px'}}>
        <h1 style={{padding: '5px 30px 20px'}}>Inventory stats</h1>
        <Statistic.Group widths='three'>
          <Statistic>
            <Statistic.Value><Icon name='cart plus' /> 2,000,000</Statistic.Value>
            <Statistic.Label>ATS (units)</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value><Icon name='truck' /> 3,200,000</Statistic.Value>
            <Statistic.Label>Future ATS (units)</Statistic.Label>
          </Statistic>
        </Statistic.Group>
        <br/><br/>
        <h1 style={{padding: '5px 30px 20px'}}>Your sales stats</h1>
        <Statistic.Group widths='three'>
          <Statistic>
            <Statistic.Value><Icon name='dollar sign' /> 220,000</Statistic.Value>
            <Statistic.Label>YTD On PO</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value><Icon name='dollar sign' /> 220,000</Statistic.Value>
            <Statistic.Label>MTD Shipped</Statistic.Label>
          </Statistic>
        </Statistic.Group>
        <br/><br/>
        <h1 style={{padding: '5px 30px 0px'}}>Your top accounts (by Total $ Shipped YTD)</h1>
        <List ordered style={{paddingLeft: '50px', fontSize: '16px'}}>
          <List.Item>TJX/MARSHALLS &nbsp; ($300,000)</List.Item>
          <List.Item>10 BELOW LLC &nbsp; ($200,000)</List.Item>
          <List.Item>BURLINGTON &nbsp; ($100,000)</List.Item>
          <List.Item>CITI TRENDS &nbsp; ($90,000)</List.Item>
          <List.Item>FASHION NOVA &nbsp; ($80,000)</List.Item>
        </List>
        <br/><br/>
        <h1 style={{padding: '5px 30px 0px'}}>Your sales orders created today</h1>
        <List bulleted style={{paddingLeft: '50px', fontSize: '16px'}}>
          <List.Item>1658155028 &nbsp; (REF# P0990)</List.Item>
          <List.Item>1658155056 &nbsp; (REF# P0991)</List.Item>
          <List.Item>1658155365 &nbsp; (REF# P0992)</List.Item>

        </List>

      </div>
    )
  }
}

export default YourStats
