import React, {Component} from 'react'
import {
          Form,
          Checkbox,
          Grid,
          Button,
          Message,
          Header,
          Icon,
          Dropdown,
          Segment
       }                  from 'semantic-ui-react'
import _                  from 'lodash'
import {withRouter}       from 'react-router-dom'
import {LoadingParagraph} from './../../ui/loading-paragraph'
import Utils              from './../../../modules/utils'
import AccountAPI         from './../../../modules/api/account_api'
import UserAPI            from './../../../modules/api/user_api'


class AccountReps extends Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading       : true,
      cLoading      : false,
      accounts      : [],
      accountOptions: [],
      reps          : [],
      repOptions    : [],
      recentChanges : [],
      current       : {},
      displayMessage: false,
      error         : null,
      inputs        : _.cloneDeep(this.INITIAL_INPUT_STATE)
    }

    this.fetchReps()
    this.fetchAccounts()
    this.fetchAccountRepsChanges()
  }

  INITIAL_INPUT_STATE =
  {
    repId :
    {
      value   : "",
      valid   : null,
      required: true,
      message : ""
    },
    additional :
    {
      value   : false,
      valid   : true,
      required: true,
      message : ""
    }
  }

  fetchAccounts = () =>
  {
    var {accountOptions, accounts} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        var keys       = {text: ['name', 'company.name'], value: 'id'}
        accountOptions = Utils.toOptionsHashWithKeys(data.accounts, keys, true)
        accounts       = data.accounts

        this.setState({ accountOptions, accounts, loading: false })
      }
    }

    AccountAPI.getAll(callback)
  }

  fetchReps = () =>
  {
    var {reps} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        reps = data.reps
        this.setState({ reps })
      }
    }

    UserAPI.getAllReps(callback)
  }

  fetchAccountRepsChanges = () =>
  {
    var {recentChanges} = this.state

    var callback = (error, data) =>
    {
      if (error)
      {
        console.error(error)
      }
      else
      {
        recentChanges = data.accountReps
        this.setState({ recentChanges })
      }
    }

    AccountAPI.getAllAccountsReps(callback)
  }

  onAccountSelection = (event, {value}) =>
  {
    let {accounts} = this.state
    let account = accounts.find((acc) => acc.id === value)
    this.setInputsForAccount(account)
  }

  setInputsForAccount = (account) =>
  {
    let {current, repOptions, reps, inputs} = this.state

    var repIds     = _.map(account.reps, 'id')
    let sortedReps = reps.filter(rep => !(repIds.includes(rep.id)))
    var keys       = {text: ['name'], value: 'id'}
    repOptions     =  Utils.toOptionsHashWithKeys(sortedReps, keys)

    inputs.repId.value = ''
    inputs.repId.valid = null

    this.setState({ current: account, repOptions, inputs })
  }

  handleChange = (e, data, fromLoad=false) =>
  {
    let state  = this.state
    let name, value;
    let inputs = state.inputs

    if (data && ['additional'].includes(data.name))
    {
      name  = data.name
      value = data.checked
    }
    else if (e && ['repId'].includes(e.name))
    {
      value = e.value
      name  = e.name
    }

    let userInteraction = !fromLoad
    let input   = inputs[name]
    input.value = value

    if (
      typeof input.value !== 'boolean' &&
      input.required &&
      input.value.length == 0
    )
    {
      input.message = 'Please select the rep.'
    }
    else
    {
      input.message = ''
    }

    switch(name)
    {
      case "repId" :
      {
        input.valid = !input.message
        break
      }
      default:
      {
        break
      }
    }

    this.setState(state)
  }

  handleSubmit = () =>
  {
    this.setState({ cLoading: true })
    const {cLoading, inputs, current, accountOptions, accounts} = this.state
    let {data, valid} = this.getData()

    let callback = (error, data) =>
    {
      if (error)
      {
        this.setState({
          cLoading: false,
          displayMessage: true,
          error: { apiError: true, error: error }
        })
      }
      else
      {
        var index       = accounts.findIndex((c) => c.id === data.account.id)
        accounts[index] = data.account
        this.fetchAccountRepsChanges()

        this.setState({
          cLoading: false,
          inputs: _.cloneDeep(this.INITIAL_INPUT_STATE),
          current: {},
          repOptions: [],
          accounts
        })
      }
    }

    if (valid && current && current.id)
    {
      AccountAPI.updateReps(current.id, {accountRep: data}, callback)
    }
  }

  getData = () =>
  {
    let {inputs, current} = this.state
    let data              = {}

    data.repId         = inputs.repId.value
    data.accountId     = current.id
    data.additional    = inputs.additional.value

    return { data, valid: this.isInputsValid() }
  }

  isInputsValid = () =>
  {
    let {inputs} = this.state
    let valid    = Utils.isInputsValidated(inputs)
    return valid
  }

  render()
  {
    var {inputs, current, recentChanges} = this.state

    let markup =
      <div id='account-reps-section'>
        {
          this.state.loading ?
          <LoadingParagraph /> :
          <div>
            <h1>Account Reps</h1>
            <Grid centered columns={2}>
              <Grid.Column width={11}>
                <Message style={{marginBottom: '30px'}} positive>
                  <Message.Content>
                    <Icon name='info' size='large' />
                    If you edit Account Rep <b>WITHOUT</b> "Add as Additional Account Rep", it will delete all current Account Reps <span className="text-brown">for the</span> <span className="text-orange">Contact!</span>
                  </Message.Content>
                </Message>
                <Segment className="segment-wrap">
                  <Header content='Edit Account Reps' />
                  <Form
                    onSubmit={this.handleSubmit}
                    loading={this.state.cLoading}
                    >
                    <Grid columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Group widths='equal'>
                            <Form.Select
                              name="repId"
                              id="rep-select"
                              label='Account Rep'
                              placeholder='Select Account Rep'
                              onChange={(e, data) => this.handleChange(data, inputs.repId)}
                              options={this.state.repOptions}
                              value={inputs.repId.value}
                            />
                          </Form.Group>
                          <Form.Group widths='equal'>
                            <Checkbox
                              style={{marginLeft: '7px'}}
                              label='Add as Additional Account Rep'
                              name='additional'
                              onChange={this.handleChange}
                              checked={inputs.additional.value}
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <label>Contact</label>
                              <Dropdown
                                id="account-select"
                                search
                                selection
                                placeholder='Select Contact'
                                value={current.id || ''}
                                onChange={this.onAccountSelection}
                                options={this.state.accountOptions}
                              />
                            </Form.Field>
                          </Form.Group>
                          {
                            current && current.id &&
                            <Header
                              as='h3'
                              subheader={`Current Account Rep: ${current.primaryRep ? current.primaryRep.name : 'N/A'}`}
                            />
                          }
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <Button
                      className="submit-btn"
                      primary
                      type='submit'
                      content='Submit'
                      disabled={!this.isInputsValid()}
                    />
                  </Form>
                </Segment>
                {
                  recentChanges.length > 0 &&
                  <Message style={{marginTop: '120px'}}>
                    <Message.Header>
                      Here are recent Account Rep changes:
                    </Message.Header>
                    <Message.List>
                      {
                        recentChanges.map((item, i) => {
                          return <Message.Item key={i}>
                            Make <b>{item.repName}</b> the <u>{item.additional ? 'addtional' : 'primary'}</u> rep on <b>{`${item.accountName} (${item.companyName})`}</b>
                            </Message.Item>
                        })
                      }
                    </Message.List>
                  </Message>
                }
              </Grid.Column>
            </Grid>
          </div>
        }
      </div>

    return markup;
  }
}

export default withRouter(AccountReps)
