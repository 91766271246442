import React from 'react'
import { Form, Radio, Message } from 'semantic-ui-react'

const styleNumberTypes = ['Cad', 'Print', 'Graphic']

const styleNumberTypeRadioSelect = (styleNumberType, fieldOnChange) => {
  return (
    <Message style={{display: 'inline-block'}}>
      <Form>
        <Form.Field style={{fontSize: '120%'}}>
          Style Number Type: <b>{styleNumberType}</b>
        </Form.Field>
        {
          styleNumberTypes.map((x) => {
            return (
              <Form.Field>
                <Radio
                  label={x}
                  name='styleNumberType'
                  value={x}
                  checked={styleNumberType === x}
                  onChange={fieldOnChange}
                />
              </Form.Field>
            )
          })
        }
      </Form>
    </Message>
  )
}

export {styleNumberTypeRadioSelect}
