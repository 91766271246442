import React, { Component }            from 'react'
import { Form, Message }               from 'semantic-ui-react'
import AccountAPI                      from '../../../modules/api/account_api.js'
import Utils                           from '../../../modules/utils'
import Constants                       from '../../../modules/constants'
import { Auth0Context }                from "../../../contexts/auth-context"
import { ConfirmAlert }                from '../../ui/confirm-alert'

class MakeNote extends Component {
  static contextType = Auth0Context;

  state = {
      inputValue: '',
      account: this.props.account || null,
      loading: false,
      deleteNote: null,
      deleteConfirmation: false,
      notes: this.props.account ? this.props.account.notes : [],
      fileName: '',
      fileUrl: '',
    }

  handleChange = (e, data) =>
  {
    let {name, value} = data
    let state = this.state
    state[name] = value
    this.setState(state)
  }

  handleSubmit = () => {
    let { inputValue, notes, account, fileName, fileUrl } = this.state
    inputValue = inputValue.trim()

    if (!inputValue) return

    this.setState({ notes, loading: true })
    const {user} = this.context;

    if (account)
    {
        let data = {note: {note: inputValue, fileName, fileUrl }}
        AccountAPI.createNote(account.id, data, (error, data) =>
        {
            if (error)
            {
              alert(error)
              this.setState({ loading: false, displayMessage: true, error: {apiError: true, error: error} })
            }
            else
            {
              notes.unshift({...data, _destroy: false});
              document.getElementById('note-file-input').value = ''
              this.setState({ inputValue: '', notes, loading: false, fileName: '', fileUrl: '' }, () => {
                this.props.setFlashMessage({displayValue: `Successfully created note: ${data.note}`, type: 'success'})
                if (this.props.reloadActivities != null) {
                  this.props.reloadActivities()
                }
                if (this.props.onClose != null) {
                  this.props.onClose()
                }
              })
            }
        })
    }
    else
    {
      //new page?

      let note = {
        id: null,
        _destroy: false,
        note: inputValue,
        fileName,
        fileUrl,
        userName: user.name,
        userId: user.id
      }
      notes.unshift({...note});
      this.setState({ inputValue: '', notes, loading: false }, this.afterSubmit)
    }
  }

  downloadFile = (noteId) => {
    let { account } = this.state;
    if (!noteId || !account ) return;

    this.setState({ loading: true })

    AccountAPI.downloadNoteFile(account.id, noteId, (error, data) =>
    {
      if (error)
      {
        alert(error)
        this.setState({ loading: false, displayMessage: true, error: {apiError: true, error: error} })
      }
      else
      {
        this.setState({ loading: false }, () => window.open(data.filePath))
      }
    })
  }


  afterSubmit = () => {
    const { notes } = this.state
    let payload = { name: 'notesAttributes', value: [...notes] }
    this.props.onChange(null, payload)
    this.setState({ loading: false, fileName: '', fileUrl: '' })
    document.getElementById('note-file-input').value = ''
  }

  showDeleteConfirmation = (note) => {
    let { deleteNote, deleteConfirmation } = this.state
    deleteNote = note
    deleteConfirmation = true
    this.setState({ deleteNote, deleteConfirmation })
  }

  onDeleteNote = () => {
    this.setState({ loading: true, deleteConfirmation: false })
    let { account, deleteNote } = this.state
    let note = deleteNote
    if (account && note.id)
    {
      AccountAPI.deleteNote(account.id, note.id, (error, data) =>
      {
          if (error)
          {
            alert(error)
            this.setState({ loading: false, displayMessage: true, error: {apiError: true, error: error} })
          }
          else
          {
            note._destroy = true

            this.setState({ loading: false }, () => {
              this.props.setFlashMessage({displayValue: `Successfully deleted note: ${note.note}`, type: 'success'})
              this.props.reloadActivities()
            })
          }
      })
    }
    else{
      note._destroy = true
      this.setState({ loading: false }, this.afterSubmit)
    }
  }

  onModalReject = () => {
    let { deleteNote, deleteConfirmation } = this.state
    deleteNote = null
    deleteConfirmation = false
    this.setState({ deleteNote, deleteConfirmation })
  }

  uploadFile = (e) => {
    let file = e.target.files[0]
    if (!file) return
    let formData = new FormData()
    let filename = file.name
    let size = file.size;

    if (size > 25000000)
    {
      alert("File size is more than 25 MB please select under that size")
      return;
    }
    formData.append("file", file, file.name)
    formData.append("from_notes", true)

    this.setState({loading: true})
    let url = Constants.API.apiEndPoint + 'upload-file?tempKey=' + Constants.API.tempApiKey;
    let callback = (error, data) =>
    {
        if (error)
        {
          console.log(error)
          alert("error in file uploading")
          this.setState({loading: false, fileName: filename, fileUrl: data.url})
        }
        else
        {
          this.setState({loading: false, fileName: filename, fileUrl: data.url})
        }
    }

    Utils.uploadFile(url, formData, callback)
  }

  render() {
    const { inputValue, notes, loading, deleteConfirmation, deleteNote } = this.state;
    const {user} = this.context;

    return (
      <div>

        {
          deleteConfirmation &&
          <ConfirmAlert
            header='Delete Your Note'
            confirmationText='Are you sure you want to delete your note?'
            onReject={this.onModalReject}
            onAccept={this.onDeleteNote}
          >
            <p>
              <b>Note: </b>
              {deleteNote.note}
            </p>
          </ConfirmAlert>
        }
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.TextArea
              placeholder='Type your note'
              name='inputValue'
              value={inputValue}
              onChange={this.handleChange}
              width={16}
            />
          </Form.Group>

          <Form.Group>
            <div className="thirteen wide field">
              <div content="Add" className="ui input">
              <input
                id="note-file-input"
                name="file"
                type="file"
                onChange={this.uploadFile}

                />
              </div>
            </div>

            <Form.Button
              // right
              disabled={!!!inputValue.trim()}
              style={{float: 'right'}}
              content='Add'
              primary
              loading={loading}
              disabled={loading}
              width={3} />
          </Form.Group>
          {!this.props.account ?
            <div>
              {
                notes.map((note, i) => {
                  if (note._destroy !== true)
                  {
                    return (
                      <Message
                      size='mini'
                      key={`#{note.id}-${i}`}
                      onDismiss={String(user.id) === String(note.userId) ? () => {this.showDeleteConfirmation(note)} : null }
                      >
                        <div>
                          <b>{note.userName}</b> added note on <b>{Utils.formatDate(note.createdAt)}</b>
                          <div dangerouslySetInnerHTML={{__html: note.note.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>')}} />
                        </div>

                        {
                          note.fileName &&
                          <b>File: <span className='link' onClick={() => this.downloadFile(note.id) }>{note.fileName}</span></b>
                        }

                      </Message>
                    )
                  }
                  else
                  {
                    return null;
                  }
                })
              }
            </div>
            : null
          }
        </Form>
      </div>
    )
  }
}

export default MakeNote
