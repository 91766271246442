import React              from 'react'
import { withRouter }     from 'react-router-dom'
import PurchaseOrderForm  from './../purchase-order-form'
import {LoadingParagraph} from './../../ui/loading-paragraph'
import PurchaseOrderAPI   from './../../../modules/api/purchase_order_api'

class EditPurchaseOrder extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      purchaseOrder: {},
      company      : null,
      loading      : true
    }
  }

  componentDidMount()
  {
    this.fetchPO()
  }

  fetchPO = () =>
  {
    let poId = this.props.match.params.poId
    var {purchaseOrder, company} = this.state

    let callback = (error, data) =>
    {
      if (error)
      {
        // noops
        console.error(error)
      }
      else
      {
        purchaseOrder = data.purchaseOrder
        company       = data.company
        this.setState({purchaseOrder, company, loading: false})
      }
    }

    PurchaseOrderAPI.get(poId, callback)
  }

  render()
  {
    return (
      this.state.loading ?
      <LoadingParagraph />
      :
      <PurchaseOrderForm
        history={this.props.history}
        purchaseOrder={this.state.purchaseOrder}
        company={this.state.company}
        title='Edit Purchase Order'
      />
    )
  }
}

export default withRouter(EditPurchaseOrder)
