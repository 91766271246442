import React from 'react'
import {sliceAdd} from '../slice-add'

const pageLabel = (index, tableRow) => {
  if (index % sliceAdd == 0 && index != 0) {
    let pageText = 'Page ' + ((index / sliceAdd) + 1)

    return (
      <React.Fragment>
        <div key={'page'+index} style={{padding: '10px', width: '100%'}}>{pageText}</div>
        {tableRow}
      </React.Fragment>
    )
  } else {
    return tableRow
  }
}

export {pageLabel}
