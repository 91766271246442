import                         './index.css'
import React, {Component} from 'react'
import {
          Form,
          Grid,
          Button,
          Icon,
          Divider,
          Label,
          Popup
       }                  from 'semantic-ui-react'
import ReactQuill         from 'react-quill'
import PurchaseOrderAPI   from './../../../../modules/api/purchase_order_api'
import Utils              from './../../../../modules/utils'
import {LoadingParagraph} from './../../../ui/loading-paragraph'
import Constants          from './../../../../modules/constants'


class FulfillmentForm extends Component
{
  constructor(props)
  {
    super(props)
    this.state =
    {
      loading            : true,
      cLoading           : false,
      purchaseOrder      : {},
      invoiceFiles       : [],
      packingSlipFiles   : [],
      files              : [],
      fulfillmentProgress:
      {
        id    : '',
        name  : 'Needs-Fulfillment',
        status: '',
        notes : ''
      }
    }
  }

  invoiceFile     = React.createRef()
  packingSlipFile = React.createRef()

  componentDidMount()
  {
    var {purchaseOrder} = this.props
    if (purchaseOrder && purchaseOrder.id)
    {
      var fulfillment = purchaseOrder.poProgresses.find(p => p.name == 'Needs-Fulfillment')
      this.setState({
        purchaseOrder,
        invoiceFiles       : purchaseOrder.invoiceFiles,
        packingSlipFiles   : purchaseOrder.packingSlipFiles,
        fulfillmentProgress: fulfillment,
        loading            : false
      })
    }
  }

  saveProgressStatus = (event, progressStatus='completed') =>
  {
    this.setState({ cLoading: true })
    var {purchaseOrder, fulfillmentProgress, files} = this.state

    fulfillmentProgress.status = progressStatus

    if (
      purchaseOrder && purchaseOrder.id &&
      fulfillmentProgress && fulfillmentProgress.id
    )
    {
      var updatedPO =
      {
        purchaseOrderProgressesAttributes : [{...fulfillmentProgress}]
      }

      if (files.length > 0)
      {
        updatedPO.purchaseOrderFilesAttributes = files
      }

      var callback = (error, data) =>
      {
        if (error)
        {
          console.log(error)
          this.setState({ cLoading: false })
        }
        else
        {
          this.props.closeModal(event, {value: false})
        }
      }

      PurchaseOrderAPI.update(purchaseOrder.id, {purchaseOrder: updatedPO}, callback)
    }
  }

  onChangeFulfillmentNotes = (value) =>
  {
    var {fulfillmentProgress} = this.state
    fulfillmentProgress.notes = value
    this.setState({fulfillmentProgress})
  }

  onChangeInvoice = (event) =>
  {
    this.handleAcceptedFiles(event.target.files, 'invoice')
  }

  onChangePackingSlip = (event) =>
  {
    this.handleAcceptedFiles(event.target.files, 'packing_slip')
  }

  addFile = (data, filename, fileType) =>
  {
    this.addLogMessage('The file "' + filename + '" has been attached as "'+ fileType +'" to the purchase order!')

    var state    = this.state
    let filedata =
    {
      filename: data.filename,
      url     : data.url,
      fileType: fileType
    }

    if (fileType === 'invoice')
    {
      state.invoiceFiles.push(filedata)
    }
    else
    {
      state.packingSlipFiles.push(filedata)
    }

    state.files.push(filedata)
    this.setState(state)
  }

  addLogMessage = (message) =>
  {
    console.log(message)
  }

  handleAcceptedFiles = (acceptedFiles, fileType) =>
  {
    this.setState({ cLoading: true })
    var numberOfFilesToUpload = acceptedFiles.length
    var numberOfFilesUploaded = 0
    let url = Constants.API.apiEndPoint + 'upload-file?tempKey=' + Constants.API.tempApiKey

    for (var i=0; i<acceptedFiles.length; i++)
    {
      let file     = acceptedFiles[i]
      let formData = new FormData()
      let filename = file.name

      if (Utils.badFileType(file))
      {
        this.addLogMessage('Only allow image, microsoft word, excel, pdf file types.  File "' + filename + '" cannot be uploaded!')
        numberOfFilesUploaded += 1
        if (numberOfFilesUploaded == numberOfFilesToUpload)
        {
          this.setState({ cLoading: false })
        }
      }
      else
      {
        formData.append("file", file, file.name)
        Utils.uploadFile(url, formData, (error, data) =>
        {
          if (error)
          {
            alert('There was an error when trying to upload the file.')
            numberOfFilesUploaded += 1
            if (numberOfFilesUploaded == numberOfFilesToUpload)
            {
              this.setState({ cLoading: false })
            }
          }
          else
          {
            if (data.success)
            {
              this.addFile(data, filename, fileType)
            }
            else
            {
              if (data.corrupt_file)
              {
                this.addLogMessage('The file "' + filename + '" is corrupt.')
              }
              else
              {
                alert('There was an error when trying to upload the file "' + filename + '"')
              }
            }

            numberOfFilesUploaded += 1
            if (numberOfFilesUploaded == numberOfFilesToUpload)
            {
              this.setState({ cLoading: false })
            }
          }
        })
      }
    }
  }

  gotoDownloadLink = (e, purchaseOrder, file) =>
  {
    if (file && file.id)
    {
      this.props.gotoDownloadLink(e, purchaseOrder, file)
    }
  }

  render()
  {
    var {purchaseOrder, invoiceFiles, packingSlipFiles, fulfillmentProgress} = this.state
    var readonly = fulfillmentProgress.status === 'completed'

    let markup =
      <div style={{padding: '20px'}}>
        {
          this.state.loading ?
          <LoadingParagraph />
          :
          <div>
            <Grid columns={2} className='form'>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    readOnly
                    type="text"
                    fluid
                    label='PO Number'
                    placeholder='xyz'
                    value={purchaseOrder.ponumber}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form loading={this.state.cLoading} style={{marginTop: '20px'}}>
              <Form.Field>
                <ReactQuill
                  modules={this.modules}
                  className="text-editor"
                  readOnly={readonly}
                  value={this.state.fulfillmentProgress.notes}
                  onChange={this.onChangeFulfillmentNotes}
                />
              </Form.Field>
              <br/>
              <Divider />

              <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
                <div className='invoice-files-tab'>
                  <div>
                    <label>Packing Slip</label>
                    <div>
                      {
                        packingSlipFiles.map((file, i) => {
                          return (
                            <Popup
                              position='bottom center'
                              key={i}
                              basic
                              content={file.filename}
                              trigger={<Icon name='file alternate' link onClick={(e) => this.gotoDownloadLink(e, purchaseOrder, file)} />}
                            />
                          )
                        })
                      }
                      <Button
                        size='tiny'
                        disabled={readonly}
                        basic
                        icon='archive'
                        onClick={() => this.packingSlipFile.current.click()}
                      />
                      <input
                        ref={this.packingSlipFile}
                        type="file"
                        multiple
                        hidden
                        onChange={this.onChangePackingSlip}
                      />
                    </div>
                  </div>
                  <div style={{marginLeft: '20px'}}>
                    <label>Invoice / Other</label>
                    <div>
                      {
                        invoiceFiles.map((file, i) => {
                          return (
                            <Popup
                              position='bottom center'
                              key={i}
                              basic
                              content={file.filename}
                              trigger={<Icon name='file alternate' link onClick={(e) => this.gotoDownloadLink(e, purchaseOrder, file)} />}
                            />
                          )
                        })
                      }
                      <Button
                        size='tiny'
                        disabled={readonly}
                        basic
                        icon='plus'
                        onClick={() => this.invoiceFile.current.click()}
                      />
                      <input
                        ref={this.invoiceFile}
                        type="file"
                        multiple
                        hidden
                        onChange={this.onChangeInvoice}
                      />
                    </div>
                  </div>
                </div>

                <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                  {
                    !readonly &&
                    <div>
                      <Button
                        basic
                        onClick={(e) => this.saveProgressStatus(e, 'draft')}
                        content="Save as Draft"
                      />
                      <Button
                        primary
                        onClick={(e) => this.saveProgressStatus(e, 'completed')}
                        content="Save"
                      />
                    </div>
                  }
                  <Button
                    onClick={(e) => this.props.closeModal(e, {value: false})}
                    content="Close"
                  />
                </div>
              </div>
            </Form>
          </div>
        }
      </div>

    return markup;
  }
}

export default FulfillmentForm
