import React, { Component } from 'react'
import {getAuto, getManual} from './get'
import {processAuto, processManual} from './process-data'

const divStyle = {border: '1px solid rgb(211,211,211)', background: 'rgba(144,238,144,0.5)', display: 'inline-block', padding: '20px', marginBottom: '70px'}
const divStyle2 = Object.assign({}, divStyle, {background: 'rgba(173, 216, 230,0.5)'})

class StockReportDebug extends Component {

  constructor(props) {
    super(props)
    this.state = {
      recentlyAutoQbentered: [],
      recentlyManuallyQbentered: []
    }

    this.loadAuto = this.loadAuto.bind(this)
    this.loadManual = this.loadManual.bind(this)
    this.hideAuto = this.hideAuto.bind(this)
    this.hideManually = this.hideManually.bind(this)
  }

  loadAuto() {
    getAuto((err, res) => {
      if (err) {
        alert('There was an error when trying to get recently Auto Qbentered.')
      } else {
        let recentlyAutoQbentered = processAuto(res.data.recentlyAutoQbentered)
        this.setState({recentlyAutoQbentered})
      }
    })
  }

  loadManual() {
    getManual((err, res) => {
      if (err) {
        alert('There was an error when trying to get recently Manually Qbentered.')
      } else {
        let recentlyManuallyQbentered = processManual(res.data.recentlyManuallyQbentered)
        this.setState({recentlyManuallyQbentered})
      }
    })
  }

  hideAuto(position) {
    this.setState({recentlyAutoQbentered: []})
    if (position == 'bottom') document.getElementById('auto-qbentered').scrollIntoView()
  }

  hideManually(position) {
    this.setState({recentlyManuallyQbentered: []})
    if (position == 'bottom') document.getElementById('manually-qbentered').scrollIntoView()
  }

  render() {
    let {recentlyAutoQbentered, recentlyManuallyQbentered} = this.state
    return (
      <div>
        <div style={divStyle}>
          Recently <u><b id="auto-qbentered">AUTO</b></u> QB-Entered: &nbsp;
          {
            recentlyAutoQbentered.length == 0
            ?
            <button onClick={this.loadAuto}>load</button>
            :
            <React.Fragment>
              <button onClick={this.hideAuto}>hide</button>
              <pre style={{fontSize: '60%', lineHeight: '120%'}}><code>{JSON.stringify(recentlyAutoQbentered, null, 4)}</code></pre>
              <button onClick={this.hideAuto.bind(null, 'bottom')}>hide</button>
            </React.Fragment>
          }
        </div>

        <br/>

        <div style={divStyle2}>
          Recently <u><b id="manually-qbentered">MANUALLY</b></u> QB-Entered: &nbsp;
          {
            recentlyManuallyQbentered.length == 0
            ?
            <button onClick={this.loadManual}>load</button>
            :
            <React.Fragment>
              <button onClick={this.hideManually}>hide</button>
              <pre style={{fontSize: '60%', lineHeight: '120%'}}><code>{JSON.stringify(recentlyManuallyQbentered, null, 4)}</code></pre>
              <button onClick={this.hideManually.bind(null, 'bottom')}>hide</button>
            </React.Fragment>
          }
        </div>
      </div>
    )
  }

}

export default StockReportDebug
