import React from 'react'
import {Message, Popup} from 'semantic-ui-react'
import ConfirmExcel from './confirm-excel.js'

const successMessage = (successId, action) => {
  let refnumbers = successId.split(',')

  return (
    <div style={{paddingTop: '35px', marginBottom: '-20px'}}>
      <Message>
        Your vendor order has been uploaded successfully.  REF # &nbsp;
        {
          refnumbers.map((refnumber, index) => editPageLink(refnumber, index, refnumbers.length))
        }
        {
          action != null
          ?
          <ConfirmExcel successId={successId} action={action}/>
          :
          null
        }
      </Message>
    </div>
  )
}

const editPageLink = (refnumber, index, len) => {
  let id = Number(refnumber.replace('V',''))
  return (
    <React.Fragment>
      <Popup content='Open edit page in new tab' trigger={
        <a style={{cursor: 'pointer'}} target="_blank" href={"/#/vendor-orders/" + id + '/edit'}>{refnumber}</a>
      } />
      {index == len - 1 ? '' : ', '}
    </React.Fragment>
  )
}

const splitUpMessage = (splitUpId) => {
  return (
    <div style={{paddingTop: '35px', marginBottom: '-20px'}}>
      <Message>
        Your vendor order was split up into REF # &nbsp;
        {
          splitUpId.split(',').join(', ')
        }
      </Message>
    </div>
  )
}

export {successMessage, splitUpMessage}
