import React from 'react'
import Utils from './../../modules/utils'

const exFactoryStyle = {
  position: 'absolute', padding: '4px 11px', lineHeight: '1rem', border: '1px solid rgb(222,222,222)',
  borderRadius: '5px', marginTop: '6px'
}

const displayExFactory = (date, vendorPonumber, loadingDate, isEditPage) => {
  if (date == null || !isEditPage) {
    return null
  } else {
    return (
      <div style={exFactoryStyle}>
        <u>Earliest Vendor Order Data</u><br/>
        <small><b>Ex-Factory date</b></small><br/>
        {Utils.formatSimpleDate(date)}<br/>
        <small><b>Vendor PO#</b></small><br/>
        {vendorPonumber}<br/>
        <small><b>Loading date</b></small><br/>
        {
          loadingDate == null
          ?
          '—'
          :
          Utils.formatSimpleDate(loadingDate)
        }
      </div>
    )
  }
}

export {displayExFactory}
