import React, {Component} from 'react'
import {Grid, Dropdown} from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import warehouseOptions from './warehouse-options'
import {isCustomer, isWarehouse} from './is-role'
import {factoryOptions} from '../vendor-orders/options'
import DateInputs from './date-inputs'
import {printsFilter, patternFilter} from './prints/filter'

let _warehouseOptions = warehouseOptions

if (isCustomer() || isWarehouse()) {
  _warehouseOptions = _warehouseOptions.filter(x => x['key'] != 'MISSING')
}

class CadFilterSection extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {
      isGuest, isSales, isAdmin, isDesign, isProduction, futureAtsMonths, marginsOptionsAdmin, repOptions,
      sub2Options, showDateInputs, hideableInfo
    } = this.props

    const warehouseDropdown = (
      hideableInfo.includes('Warehouse')
      ?
      <div>
        {
          this.props.cadReducer.warehouse.length == 0
          ?
          <label>&nbsp;</label>
          :
          <label>Warehouse</label>
        }
        <Dropdown placeholder='Warehouse' fluid multiple selection options={_warehouseOptions} onChange={this.props.warehouseOnChange} value={this.props.cadReducer.warehouse}/>
      </div>
      :
      null
    )

    return (
      <Grid.Row id="cads-page-grid-third-row">
        {isWarehouse() ? <Grid.Column>{warehouseDropdown}</Grid.Column> : null}
        <Grid.Column>
          {
            isWarehouse() || (isGuest && (!this.props.cadReducer.showFutureATS || this.props.cadReducer.futureAtsMonths.length == 0))
            ?
            null
            :
            <div>
              {this.props.cadReducer.futureAtsMonths.length == 0 ?
                <label>&nbsp;</label>
                :
                <label>Future Inventory By Month</label>
              }
              <Dropdown style={isGuest ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Future Inventory By Month' fluid multiple selection options={futureAtsMonths} onChange={this.props.futureAtsMonthsOnChange} value={this.props.cadReducer.futureAtsMonths}/>
            </div>
          }

          {
            isAdmin || isProduction || isSales
            ?
            <div>
              {
                this.props.cadReducer.factory.length == 0
                ?
                <div style={{marginTop: '9px'}}>&nbsp;</div>
                :
                <div style={{marginTop: '9px'}}>Factory</div>
              }
              <Dropdown placeholder='Factory' fluid multiple selection options={factoryOptions} onChange={this.props.factoryOnChange} value={this.props.cadReducer.factory}/>
            </div>
            :
            null
          }

        </Grid.Column>

        {
          isAdmin
          ?
          <Grid.Column>
            <div style={{marginBottom: '9px'}}>
              {this.props.cadReducer.margins.length == 0 ?
                <label>&nbsp;</label>
                :
                <label>Margins</label>
              }

              <Dropdown placeholder='Margins' search fluid multiple selection options={marginsOptionsAdmin} onChange={this.props.marginsOnChange} value={this.props.cadReducer.margins} />
            </div>

            {warehouseDropdown}
          </Grid.Column>
          :
          null
        }

        {
          isAdmin || isSales
          ?
          <Grid.Column>
            {
              this.props.reps.length == 0
              ?
              <label>&nbsp;</label>
              :
              <label>Rep</label>
            }
            <Dropdown placeholder='Rep' search fluid multiple selection options={repOptions} onChange={this.props.repOnChange} value={this.props.reps}/>

            <div style={{height: '9px'}}/>
            {printsFilter(this.props.printOnChange, this.props.cadReducer.print)}
            <div style={{height: '9px'}}/>
            {patternFilter(this.props.patternOnChange, this.props.cadReducer.pattern)}
          </Grid.Column>
          :
          null
        }

        {
          isAdmin || isWarehouse()
          ?
          null
          :
          <Grid.Column>{warehouseDropdown}</Grid.Column>
        }

        {
          isProduction
          ?
          <Grid.Column>
            {printsFilter(this.props.printOnChange, this.props.cadReducer.print)}
            <div style={{height: '9px'}}/>
            {patternFilter(this.props.patternOnChange, this.props.cadReducer.pattern)}
          </Grid.Column>
          :
          null
        }

        <Grid.Column>
          {sub2Options.length > 0 && this.props.cadReducer.subCategory.length == 1 ?
            <div>
              {this.props.cadReducer.margins.length == 0 ?
                <label>&nbsp;</label>
                :
                <label>{this.props.cadReducer.subCategory[0]}-Category</label>
              }

              <Dropdown placeholder={this.props.cadReducer.subCategory[0]+'-Category'} search fluid multiple selection options={sub2Options} onChange={this.props.sub2OnChange} value={this.props.cadReducer.sub2} />

            </div>
            :
            null
          }
        </Grid.Column>
        <Grid.Column>
          {showDateInputs ?
            <div className='date-filter'><span>From</span> &nbsp;
              <DateInput
                  name="date"
                  value={this.props.cadReducer.dateFrom}
                  placeholder="From Date"
                  iconPosition="left"
                  dateFormat="MM/DD/YYYY"
                  autocomplete="off"
                  onChange={this.props.dateFromOnChange}
                  duration={1}
                  animation={'none'}
                />
            </div>
          : null}

          {showDateInputs ?
            <div className='date-filter'><span>To</span> &nbsp;
              <DateInput
                  name="date"
                  value={this.props.cadReducer.dateTo}
                  placeholder="From Date"
                  iconPosition="left"
                  dateFormat="MM/DD/YYYY"
                  autocomplete="off"
                  onChange={this.props.dateToOnChange}
                  duration={1}
                  animation={'none'}
                />
            </div>
          : null}
        </Grid.Column>
        {
          isDesign
          ?
          <Grid.Column>
            {patternFilter(this.props.patternOnChange, this.props.cadReducer.pattern)}
          </Grid.Column>
          :
          null
        }
      </Grid.Row>
    )
  }
}

export default CadFilterSection
