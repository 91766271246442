import React, { Component } from 'react'
import NewPropertyButton from './components/properties/new-button'
import ExportPropertyButton from './components/properties/export-button'
import PropertyCards from './components/properties/cards'
import {getProperties} from './components/properties/get'
import {Loader} from 'semantic-ui-react'
import {selectAll} from './components/properties/select-all'
import SharePropertyButton from './components/properties/share-button'
import {start} from './components/properties/start'
import $ from 'jquery'
import PropertyFilters from './components/properties/filters'
import {filtersButton} from './components/prints/filters-button'
import {minRoomsInitial, maxRoomsInitial, minBuildingSizeInitial, maxBuildingSizeInitial} from './components/properties/options'
import {numberWithCommas} from './components/properties/number-with-commas'
import SearchInput from './components/vendor-orders/search-inputs.js'
import {processText} from './components/new-vpo-page/select-cads/process-text.js'
import {isMatch} from './components/properties/is-match.js'

class PropertiesPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      properties: [],
      filteredProperties: [],
      loading: true,
      loadingSliders: false,
      checked: {},
      guest: true,
      orgId: null,
      showFilters: false,
      buildingType: [],
      source: [],
      totals: {},
      minRooms: minRoomsInitial,
      maxRooms: maxRoomsInitial,
      minBuildingSize: minBuildingSizeInitial,
      maxBuildingSize: maxBuildingSizeInitial,
      currentMinRooms: minRoomsInitial,
      currentMaxRooms: maxRoomsInitial,
      currentMinBuildingSize: minBuildingSizeInitial,
      currentMaxBuildingSize: maxBuildingSizeInitial,
      statuses: [],
      searchString: ''
    }
    this.getProperties = this.getProperties.bind(this)
    this.setChecked = this.setChecked.bind(this)
    this.selectAllOnClick = this.selectAllOnClick.bind(this)
    this.toggleFilters = this.toggleFilters.bind(this)
    this.fieldOnChange = this.fieldOnChange.bind(this)
    this.filterProperties = this.filterProperties.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.getFilterTotals = this.getFilterTotals.bind(this)
    this.usingFilters = this.usingFilters.bind(this)
    this.mySetState = this.mySetState.bind(this)
    this.updateSearchString = this.updateSearchString.bind(this)
  }

  componentDidMount() {
    let guest = window.location.href.includes('/share-p')
    this.setState({guest})
    this.getProperties(guest)
  }

  getProperties(guest) {
    let token

    if (guest) {
      token = window.location.href.split('token=')[1]
    }

    getProperties(token, (err, res) => {
      if (err) {
        alert('There was an error when trying to get the properties.')
      } else {
        let {properties, orgId} = res.data
        this.setState({properties, orgId, loading: false})
        start(orgId, 'Properties')
        setTimeout(() => {
          this.getFilterTotals(properties)
        }, 0)
      }
    })
  }

  setChecked(id, x) {
    let {checked} = this.state
    checked[id] = x
    this.setState({checked})
  }

  selectAllOnClick() {
    let {properties, checked} = this.state
    let allChecked = properties.every(property => checked[property.id])

    properties.forEach((property) => {
      checked[property.id] = !allChecked
    })

    this.setState({checked})
  }

  toggleFilters() {
    this.setState({showFilters: !this.state.showFilters})
  }

  fieldOnChange(event, {value, name}) {
    let newState = {}
    newState[name] = value
    this.setState(newState)
    setTimeout(() => {
      this.filterProperties()
    }, 0)
  }

  usingFilters() {
    let {buildingType, source, minRooms, maxRooms, minBuildingSize, maxBuildingSize, searchString} = this.state

    return buildingType.length || source.length || searchString != ''
      || minRooms != minRoomsInitial
      || maxRooms != maxRoomsInitial
      || minBuildingSize != minBuildingSizeInitial
      || maxBuildingSize != maxBuildingSizeInitial
  }

  filterProperties() {
    let {properties, buildingType, source, minRooms, maxRooms, minBuildingSize, maxBuildingSize, searchString} = this.state

    let filteredProperties = [], searchStrings = []

    if (this.usingFilters()) {
      filteredProperties = properties.slice()

      if (searchString != '') {
        searchStrings = processText(searchString)
        filteredProperties = filteredProperties.filter(x => isMatch(searchStrings, x) )
      }

      if (buildingType.length) {
        filteredProperties = filteredProperties.filter(x => buildingType.includes(x.buildingType))
      }

      if (source.length) {
        filteredProperties = filteredProperties.filter(x => source.includes(x.source))
      }

      if (minRooms != maxRoomsInitial) {
        filteredProperties = filteredProperties.filter(x => x.roomsNumber >= minRooms)
      }

      if (maxRooms != maxRoomsInitial) {
        filteredProperties = filteredProperties.filter(x => x.roomsNumber <= maxRooms)
      }

      if (minBuildingSize != minBuildingSizeInitial) {
        filteredProperties = filteredProperties.filter(x => x.buildingSize >= minBuildingSize)
      }

      if (maxBuildingSize != maxBuildingSizeInitial) {
        filteredProperties = filteredProperties.filter(x => x.buildingSize <= maxBuildingSize)
      }
    }

    let statuses = [], duplicates = {}
    searchStrings.forEach((element, index) => {
      if (element == '') {
        statuses.push(null)
      } else {
        if (duplicates[element]) {
          statuses.push('duplicate')
        } else {
          if (filteredProperties.some(x => isMatch([element], x) )) {
            statuses.push('found')
          } else {
            statuses.push('not found')
          }

          duplicates[element] = true
        }
      }
    })

    this.setState({statuses, filteredProperties, loadingSliders: false})

    setTimeout(() => {
      this.getFilterTotals(filteredProperties)
    }, 0)

  }

  getFilterTotals(properties) {
    let fields = ['buildingType', 'source']
    let totals = {}

    fields.forEach((field) => {
      totals[field] = {}
      properties.forEach((property) => {
        if (totals[field][property[field]] == null) {
          totals[field][property[field]] = 1
        } else {
          totals[field][property[field]] += 1
        }
      })
    })
    this.setState({totals})
  }

  clearFilters() {
    this.setState({
      buildingType: [],
      source: [],
      minRooms: minRoomsInitial,
      maxRooms: maxRoomsInitial,
      minBuildingSize: minBuildingSizeInitial,
      maxBuildingSize: maxBuildingSizeInitial,
      currentMinRooms: minRoomsInitial,
      currentMaxRooms: maxRoomsInitial,
      currentMinBuildingSize: minBuildingSizeInitial,
      currentMaxBuildingSize: maxBuildingSizeInitial,
      statuses: [],
      searchString: ''
    })

    $('#sqft-min').text(numberWithCommas(minBuildingSizeInitial))
    $('#sqft-max').text(numberWithCommas(maxBuildingSizeInitial) + '+')

    $('#rooms-min').text(numberWithCommas(minRoomsInitial))
    $('#rooms-max').text(numberWithCommas(maxRoomsInitial) + '+')

    setTimeout(() => {
      this.getFilterTotals(this.state.properties)
    }, 0)
  }

  mySetState(newState) {
    newState['loadingSliders'] = true
    this.setState(newState)

    clearTimeout(window.filterPropertiesSetTimeout)
    window.filterPropertiesSetTimeout = setTimeout(() => {
      this.filterProperties()
    }, 1500)
  }

  updateSearchString(value) {
    this.setState({searchString: value})
    this.filterProperties()
  }

  render() {
    let {properties, filteredProperties, loading, loadingSliders, checked, guest, orgId, showFilters, buildingType, source, totals,
    currentMinRooms, currentMaxRooms, currentMinBuildingSize, currentMaxBuildingSize, statuses} = this.state

    let results = this.usingFilters() ? filteredProperties : properties
    let numResults = results.length

    return (
      <div style={{padding: '50px 60px', paddingTop: '0px'}}>
        <h2 id="mainTitle">{loading ? '' : numResults} {guest ? 'Shared' : ''} Propert{numResults == 1 ? 'y' : 'ies'}</h2>
        {filtersButton(showFilters, this.toggleFilters)}

        {
          guest
          ?
          null
          :
          <React.Fragment>
            <NewPropertyButton getProperties={this.getProperties}/>

            {selectAll(this.selectAllOnClick)}

            <ExportPropertyButton properties={properties} checked={checked} orgId={orgId}/>

            <SharePropertyButton properties={properties} checked={checked}/>
          </React.Fragment>
        }

        <PropertyFilters showFilters={showFilters} mySetState={this.mySetState} fieldOnChange={this.fieldOnChange}
          buildingType={buildingType} source={source} clearFilters={this.clearFilters}
          totals={totals} usingFilters={this.usingFilters}
          currentMinRooms={currentMinRooms}
          currentMaxRooms={currentMaxRooms}
          currentMinBuildingSize={currentMinBuildingSize}
          currentMaxBuildingSize={currentMaxBuildingSize}
        />

        <div style={{height: '20px'}}/>

        <SearchInput updateSearchString={this.updateSearchString}
          showBulk={true} statuses={statuses}
          placeholder="Search: Asset ID or Address"
          keepSpacesAndCommas={true}
        />

        {
          loading || loadingSliders
          ?
          <center style={{paddingTop: '20vh'}}><Loader active inline /></center>
          :
          numResults == 0
          ?
          <center className="no-notes">No results found</center>
          :
          null
        }

        {
          loadingSliders
          ?
          null
          :
          <PropertyCards guest={guest} setChecked={this.setChecked} checked={checked} properties={results} showProperties={!loading}/>
        }
      </div>
    )
  }
}

export default PropertiesPage
