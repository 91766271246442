import React, {Component} from 'react'
import {Icon, Popup} from 'semantic-ui-react'
import $ from 'jquery'
import {missingItemStyle} from './missing-item-style.js'

const iconStyle = {
  fontSize: '16px',
  cursor: 'pointer',
  color: 'rgb(100,100,100)'
}

const iconStyle2 = {
  cursor: 'pointer'
}

const timePonumberStyle = {position: 'relative', top: '12px', textAlign: 'left', lineHeight: '10px', display: 'inline-block', fontSize: '12px', color: 'rgb(200,200,200)', letterSpacing: '-0.4px'}
const fileLinkStyle = {cursor: 'pointer', color: 'rgb(200,200,255)', whiteSpace: 'nowrap', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block'}

class RefNumberItem extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
    this.goToEditPage = this.goToEditPage.bind(this)
    this.goToDocParserStatusPage = this.goToDocParserStatusPage.bind(this)
    this.downloadFile = this.downloadFile.bind(this)
  }

  goToEditPage() {
    let id = Number(this.props.item.substring(1))
    window.open('/#/sales-orders/' + id + '/edit')
  }

  goToDocParserStatusPage() {
    window.open('/#/confirmation/' + this.props.item)
  }

  downloadFile(filename) {
    let {item} = this.props
    let id = Number(item.substring(1))
    let foldername = filename.split('/')[0]
    let downloadUrl = process.env.REACT_APP_API_URL + '/api/download_order_file/' + id + '?file=' + foldername
    window.open(downloadUrl, '_blank', "height=700,width=700")
  }

  render() {
    let {item, time, ponumber, rep, filename} = this.props

    let filenames = (filename == null ? [] : filename.split('@@@'))

    return (
      <div style={{display: 'inline-block', whiteSpace: 'nowrap', minWidth: '370px', textAlign: 'left'}}>
        <div style={missingItemStyle}>{item}</div>
        <Popup content='Open edit page in new tab' trigger={<Icon name="pencil" onClick={this.goToEditPage} style={iconStyle}/>} />
        {item[0] == 'D' ?
          <Popup content='Open doc parser status page in new tab' trigger={<span onClick={this.goToDocParserStatusPage}  style={iconStyle2}>🤖</span>} />
        : null}&nbsp;

        <div style={timePonumberStyle}>
          {time}<br/>
          {ponumber}<br/>
          {rep}<br/>
          {filenames.map(i =>
            <React.Fragment>
              <a onClick={this.downloadFile.bind(null, i)} style={fileLinkStyle}>{i == null ? null : i.slice(43)}</a><br/>
            </React.Fragment>
          )}
        </div>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    )
  }
}

export default RefNumberItem
