import React      from 'react';
import { Form }   from 'semantic-ui-react';
import DatePlugin from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StartDateButtonInput = ({ value, onClick, inputClass, placeholder }) => (
      <button
        type='button'
        className={"ui basic button date-input-custom " + inputClass}
        onClick={onClick}>
        {value || <span className='placeholder'>{placeholder}</span>}
      </button>
    );

export const DatePickerField = props => (
      <Form.Field
        {...props}
        control={DatePlugin}
        dateFormat={props.timePicker ? '' : 'MM/dd/yyyy'}
        className='ui-date-picker'
        customInput={
           props.customInput ?
            <StartDateButtonInput
              inputClass={props.inputClass || ''}
              placeholder={props.placeholderText || 'Select Date'}
             />
             : false
          }
      />
);
