import XLSX from 'xlsx'
import Utils from "./../../../modules/utils"
import {getEarliestExFactory} from '../sales-view/ex-factory.js'

const getExcel = (myState, myContext) => {
  let {showShippingClass, showExFactory, showVponumber} = myState
  let firstRow = [ "REF #", "PO #", "CONTACT", "QTY", "$ AMOUNT"]

  if (showExFactory) {firstRow.push('EARLIEST EX-FACTORY')}
  if (showShippingClass) {firstRow.push('CLASS')}

  firstRow = firstRow.concat(["STATUS", "START SHIP DATE", "CANCEL DATE", "CREATED DATE", "STYLES"])

  if (showVponumber) {firstRow.push('VPO #')}

  let data = [firstRow]

  let pos = window.sortedRows

  let poIds = Object.keys(window.poIds)
  if (poIds.length > 0) {
    pos = pos.filter((po) => {return !!window.poIds[po.id]})
  }

  var {customer} = myContext
  let distinctAccounts = (customer == null ? [] : customer.distinctAccounts)

  for (let i=0; i<pos.length; i++) {
    let po = pos[i]
    let row = []

    let accountId = (po.accountId.value == null ? po.accountId : po.accountId.value)

    let account = distinctAccounts.find(x => x.id == accountId)

    let account_name = (account == null ? '' : `${account.name} (${account.mainContactPersonName})`)

    if (po.ponumber.value == null) {
      //download from admin view
      row.push(po.ponumber)
      row.push(po.customerOrderNumber)
      row.push(account_name)
      row.push(po.unitTotal)
      row.push(Number(po.grossAmount))

      if (showExFactory) {
        if (po.exFactoryDate != null) {
          row.push(Utils.formatSimpleDate(getEarliestExFactory(po)))
        } else {
          row.push('—')
        }
      }

      if (showShippingClass) {
        if (po.shippingClass != null && po.shippingClass != '') {
          row.push(po.shippingClass)
        } else {
          row.push('—')
        }
      }

      row.push(po.status)
      row.push(Utils.formatSimpleDate(po.startShippingDate))
      row.push(Utils.formatSimpleDate(po.cancelDate))
      row.push(Utils.formatSimpleDate(po.createdAt.split('T')[0]))

      row.push(po.cadStyleNumbers.join(", "))

      if (showVponumber) {
        row.push(po.vendorPonumber.join(", "))
      }

    } else {
      row.push(po.ponumber.value)
      row.push(po.customer_order_number.value)
      row.push(account_name)
      row.push(po.quantity.value)
      row.push(Number(po.price.value))


      if (showExFactory) {
        if (po.ex_factory_date.value != null) {
          row.push(Utils.formatSimpleDate(po.ex_factory_date.value))
        } else {
          row.push('—')
        }
      }

      if (showShippingClass) {
        if (po.shipping_class.value != null && po.shipping_class.value != '') {
          row.push(po.shipping_class.value)
        } else {
          row.push('—')
        }
      }

      row.push(po.status.value)
      row.push(Utils.formatSimpleDate(po.start_ship_date.value))
      row.push(Utils.formatSimpleDate(po.cancel_date.value))
      if (po.created_date.value != null) {
        row.push(Utils.formatSimpleDate(po.created_date.value.split('T')[0]))
      } else {
        row.push(null)
      }

      row.push(po.cadStyleNumbers.join(", "))

      if (showVponumber) {
        row.push(po.vendorPonumber.join(", "))
      }
    }


    data.push(row)
  }


  var worksheet = XLSX.utils.aoa_to_sheet(data);

  let wscols = [
    {wch:20},
    {wch:30},
    {wch:40},
    {wch:15},
    {wch:15},
  ]
  if (showExFactory) {
    wscols.push({wch:20})
  }
  if (showShippingClass) {
    wscols.push({wch:15})
  }
  wscols = wscols.concat(
    [
      {wch:20},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:80}
    ]
  )
  if (showVponumber) {
    wscols.push({wch:80})
  }

  worksheet['!cols'] = wscols;

  let num_rows = data.length

  var cell = {f: `sum(E2:E${num_rows})`};
  var cellRef = XLSX.utils.encode_cell({r: num_rows+3, c:3});

  var cell2 = { v: 'TOTAL $' };
  var cellRef2 = XLSX.utils.encode_cell({r: num_rows+3, c:2});

  var cell3 = { v: 'TOTAL QTY' };
  var cellRef3 = XLSX.utils.encode_cell({r: num_rows+2, c:2});

  var cell4 = {f: `sum(D2:D${num_rows})`};
  var cellRef4 = XLSX.utils.encode_cell({r: num_rows+2, c:3});

  var range = {s:{r: 0, c: 0},
              e: {r: num_rows+3, c: 13}};



  worksheet[cellRef] = cell;
  worksheet[cellRef2] = cell2;
  worksheet[cellRef3] = cell3;
  worksheet[cellRef4] = cell4;
  worksheet['!ref'] = XLSX.utils.encode_range(range);


  var wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, worksheet, "sales orders");


  let dateText = new Date().toISOString().split('T')[0]
  return XLSX.writeFile(wb, ('sales-orders-export_' + dateText + '.xlsx'))
}

export {getExcel}
