import React from 'react'
import {Input, Button} from 'semantic-ui-react'

const familyIdInput = (familyId, fieldOnChange, familyIdLoading, familyIdOnClick) => {
  return (
    <React.Fragment>
      <label>Family Id</label><br/>
      <Input name="familyId" placeholder='Family Id' disabled={familyIdLoading} value={familyId} onChange={fieldOnChange} />
      {
        familyIdOnClick == null
        ?
        null
        :
        <Button secondary style={{marginLeft: '10px'}} disabled={familyIdLoading} loading={familyIdLoading} onClick={familyIdOnClick}>Get Unique Family Id</Button>
      }  
    </React.Fragment>
  )
}

export {familyIdInput}
