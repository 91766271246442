import React, { Component } from 'react'
import {Popup, Button} from 'semantic-ui-react'
import {buttonStyle} from './button-style'
import pptxgen from 'pptxgenjs'
import $ from 'jquery'
import {logoPath, orgNameCamel, getFilename} from './options'

class ExportPropertyButton extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.createPowerpoint = this.createPowerpoint.bind(this)
  }

  createPowerpoint() {
    let {properties, checked, orgId} = this.props

    let selectedProperties = properties.filter(property => checked[property.id])

    if (selectedProperties.length == 0) {
      alert('Please select at least 1 property!')
      return
    }

    let pptx = new pptxgen()

    selectedProperties.forEach((property) => {
      let slide = pptx.addSlide()
      let randomNum = Math.floor(Math.random()*99999999).toString()
      let path = getFilename(property.filename, true) + "?t=" + randomNum
      slide.background = {path}
      slide.addText(property.address, {
      	x: 0,
      	y: 5,
      	w: "100%",
      	h: 0.5,
      	align: "center",
      	color: (property.filename == null ? "black" : "FFFFFF"),
      	fontSize: 24,
      })
      slide.addImage({path: logoPath(orgId), x: 8.5, y: 0, w: 1.5, h: 0.5})
    })
    let yourDate = new Date()
    pptx.writeFile({ fileName: orgNameCamel(orgId)+'_'+yourDate.toISOString().split('T')[0] })
  }

  render() {
    return (
      <Popup content='Export to Powerpoint' position='bottom left'
        trigger={
          <Button basic icon='file powerpoint outline' onClick={this.createPowerpoint} style={buttonStyle}/>
        }
      />
    )
  }
}

export default ExportPropertyButton
