import React, { Component }                      from 'react'
import { Form, Message, Loader, TextArea, Input }from 'semantic-ui-react'
import AccountAPI                                from '../../../modules/api/account_api.js'
import Utils                                     from '../../../modules/utils'
import validator                                 from 'validator';
import _                                         from 'lodash'

class Email extends Component {
  state = {
    inputs: {...this.getInputsInitialState()},

    account      : this.props.account || null,
    loading      : false,
    fetchLoading : true,
    deleteEmail  : null,
    emails       : [],
  }

  getInputsInitialState(){
    return  {
      mailFrom :
      {
        value    : "",
        valid    : null,
        required : true,
        message  : ""
      },

      mailTo :
      {
        value    : "",
        valid    : null,
        required : true,
        message  : ""
      },

      subject :
      {
        value    : "",
        valid    : null,
        required : true,
        message  : ""
      },

      body :
      {
        value    : "",
        valid    : null,
        required : true,
        message  : ""
      }
    }
  }

  componentDidMount()
  {
    this.fetchEmails()
  }

  fetchEmails = () =>
  {
    var {account} = this.state;

    var callback = (error, emails) =>
    {
      if (error)
      {
        console.error(error);
      }
      else
      {
        this.setState({ fetchLoading: false, emails })
      }
    }

    AccountAPI.getEmails(account.id, callback)
  }

  handleChange = (e, data) =>
  {
    let {name, value} = data;
    let {inputs}      = this.state;
    let input         = inputs[name]

    switch(name)
    {
      case "mailTo" :
      {
        input.valid = !input.message;
        input.value = `${value}`.toLowerCase();
        let _emails = _.compact(input.value.split(","))

        input.message = '';
        if (_emails.length > 0)
        {
          _emails.forEach( function(email, index) {
            email = email.trim();
            if (!validator.isEmail(email))
            {
              input.message += `${email} `
            }
          });
          if (input.message)
          {
            input.message = `Email not valid: ${input.message}`
          }
        }
        else
        {
          input.message = "Please enter the value."
        }

        input.valid = !input.message;
        break;
      }
      case "mailFrom" :
      {
        input.value   = `${value}`.toLowerCase().trim();
        input.valid   = validator.isEmail(input.value);
        input.message = input.valid ? "" : "Please enter a valid email address."
        break;
      }
      case "subject" :
      case "body" :
      {
        input.value = value;
        input.message = ''
        input.valid = !input.message;
        break;
      }

      default :
      {

      }

    }
    if (input.required && input.value.trim().length === 0)
    {
      input.message = "Please enter the value.";
      input.valid = !input.message;
    }
    this.setState({inputs})
  }

  getData = () =>
  {
    let { inputs } = this.state;
    let data = {};

    data.to      = inputs.mailTo.value
    data.from    = inputs.mailFrom.value
    data.subject = inputs.subject.value
    data.body    = inputs.body.value
    return data;
  }

  handleSubmit = () => {
    let { emails, account } = this.state
    let emailData = this.getData()

    this.setState({ loading: true })
    if (account)
    {
      let data = { email: {...emailData} }
      AccountAPI.createEmail(account.id, data, (error, email) =>
      {
        if (error)
        {
          console.log(error)
          this.setState({ loading: false }, () => this.props.setFlashMessage({apiError: true, displayValue: error.response.data, type: 'negative'}))
        }
        else
        {
          emails.push(email);
          this.setState({ emails, loading: false, inputs: {...this.getInputsInitialState()} }, () => {
            this.props.setFlashMessage({displayValue: 'Successfully created email', type: 'success'})
            this.props.reloadActivities()
          })
        }
      })
    }
  }

  render() {
    const { emails, loading, fetchLoading, inputs } = this.state;

    return (
      <div>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <Form.Group>
            <Form.Field
              width={16}
              required
              control={Input}
              placeholder='To'
              autoComplete="off"
              name='mailTo'
              value={inputs.mailTo.value}
              onChange={this.handleChange}
              error={inputs.mailTo.valid === false ? {
                content: inputs.mailTo.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              width={16}
              required
              control={Input}
              placeholder='From'
              autoComplete="off"
              name='mailFrom'
              value={inputs.mailFrom.value}
              onChange={this.handleChange}
              error={inputs.mailFrom.valid === false ? {
                content: inputs.mailFrom.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              width={16}
              required
              control={Input}
              placeholder='Subject of email'
              autoComplete="off"
              name='subject'
              value={inputs.subject.value}
              onChange={this.handleChange}
              error={inputs.subject.valid === false ? {
                content: inputs.subject.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              width={16}
              required
              control={TextArea}
              placeholder='Email body'
              autoComplete="off"
              name='body'
              value={inputs.body.value}
              onChange={this.handleChange}
              error={inputs.body.valid === false ? {
                content: inputs.body.message,
              } : false }
            />
          </Form.Group>

          <Form.Group>
            <Form.Button
              disabled={!Utils.isInputsValidated(inputs)}
              style={{float: 'right'}}
              content='Create Email'
              width={16} />
          </Form.Group>

        </Form>

        <div>
          {
            !this.props.account ?
            <div>
            {
            fetchLoading ?
              <Loader active inline='centered'>
                Fetching Emails...
              </Loader>
              :
              emails.length > 0 ?
                emails.map((email, i) => {
                  return (
                    <Message
                      size='mini'
                      key={`email-#{email.id}-${i}`}
                      >
                      <div>
                        <b>{email.creatorName}</b> added email on <b>{Utils.formatDate(email.createdAt)}</b>
                        <p><b>To: </b>{email.to}</p>
                        <p><b>From: </b>{email.from}</p>
                        <p><b>Subject: </b>{email.subject}</p>
                        <p><b>Body: </b>{email.body}</p>
                      </div>

                    </Message>
                  )
              })
              :
              <Message
                size='mini'
                key='no-emails'
                >
                <div>
                  No emails present!
                </div>

              </Message>
            }
            </div>
            : null
          }
         </div>
      </div>
    )
  }
}

export default Email
