import React from 'react'
import {checkboxHolderStyle} from './checkbox-holder-style'
import {Form} from 'semantic-ui-react'

const titleInput = (customAccessFieldOnChange, title) => {
  return (
    <div style={checkboxHolderStyle}>
      <b>Title:</b>
      <Form.Group widths='equal' style={{textAlign: 'center'}}>
        <Form.Input name='title' type="text" label='' onChange={customAccessFieldOnChange} placeholder='Title' value={title}
          style={{width: '500px'}} />
      </Form.Group>
    </div>
  )
}

export {titleInput}
