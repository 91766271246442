const getValueFromPrefillOrder = (orders, cadId, name) => {
  let order = getPrefillOrder(orders, cadId)

  if (order == null) {
    if (name == 'size_ratio') {
      return [null]
    } else {
      return null
    }
  }

  switch(name) {
    case 'size_ratio':
      return JSON.parse(order.sizeRatio[0])
    case 'at':
      return order.at[0]
    case 'currency':
      return order.currency
    case 'msrp':
      return order.msrp[0]
    case 'qty_per_polibag':
      return order.qtyPerPolibag[0]
    case 'case_pack':
      return order.casePack[0]
    case 'fabrication':
      return order.fabrication[0]
    case 'fabric_weight':
      return order.fabricWeight
    case 'fabric_content':
      return order.fabricContent
    case 'comment':
      return order.comments[0]
    default:
    //
  }
}

const getPrefillOrder = (orders, cadId) => {
  return orders.find(x => x != null && x.cadIds[0] == cadId)
}

export {getValueFromPrefillOrder}
