import React from 'react'
import {Popup, Icon} from 'semantic-ui-react'
import Utils from './../../../modules/utils'

const ticketsPopup = (po) => {
  return (
    [null, 'NO', ''].includes(po.ticketed)
    ?
    null
    :
    <Popup
      size='mini'
      header={"This order needs tickets"}
      content={
        <table style={{whiteSpace: 'nowrap'}}>
          {
            po.ticketsOrdered
            ?
            <tr><td style={{opacity: 0.5}}>Ordered on</td><td style={{fontWeight: 'bold'}}>{Utils.formatSimpleDate(po.ticketsOrderedDate)}</td></tr>
            :
            <div><u>NOT ordered yet!</u></div>
          }

          {po.ticketsConfirmationNumber != null && po.ticketsConfirmationNumber.replace(' ','') != '' ?
            <tr><td style={{opacity: 0.5}}>Confirmation # &nbsp;</td><td style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{po.ticketsConfirmationNumber}</td></tr>
          : null}

          {po.ticketsTrackingNumber != null && po.ticketsTrackingNumber.replace(' ','') != '' ?
            <tr><td style={{opacity: 0.5}}>Tracking # &nbsp;</td><td style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{po.ticketsTrackingNumber}</td></tr>
          : null}
        </table>
      }
      position='bottom left'
      trigger={
      <Icon name="ticket" style={{color: (po.ticketsOrdered  ? 'rgb(194,194,194)' : 'rgb(63,133,202)'), marginLeft: '10px', fontSize: '20px'}}/>
    } />
  )
}

export {ticketsPopup}
