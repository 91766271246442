import React             from 'react';
import { Table, Label }  from 'semantic-ui-react';
import PropTypes         from 'prop-types';
import { Link }          from "react-router-dom"

export const DataRow = props =>
{
    let firstVisible = null
    return <Table.Row>
    {
        props.headings.map((heading, i) =>
        {
            let column = props.column
            let cell = column[heading.key]
            if (firstVisible === null && heading.visible)
            {
                firstVisible = i
            }
            if (heading.visible)
            {
                return <Table.Cell key={'cell-'+i} selectable>
                    {
                        firstVisible === i && column._data.needsUpdate &&
                        <Label ribbon color='red' style={{left: '-15px', top: '5px'}} content='Needs Update' />
                    }
                    {column[heading.key] === column.notes ? <div style={{margin: '10px'}}>{cell.displayValue}</div> : <Link to={`contacts/${column._data.id}`}>{cell.displayValue}</Link>}
                </Table.Cell>
            }
            else{
              return null;
            }
        })
    }
    </Table.Row>
}

DataRow.propTypes = {
    headings: PropTypes.array.isRequired,
    column: PropTypes.object.isRequired
};
