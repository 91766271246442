import React from 'react'
import {Input, Checkbox} from 'semantic-ui-react'

const getHideShowPanels = (isGuest, hideableInfo, toggleHideableCheckbox, cadReducer, selectAllHideableCheckbox, onChangeHideableInput) => {
  const content = (
    <div>
      <p className="show-hide-note" style={isGuest ? {display: 'none'} : {opacity: '0.7'}}>Checked fields will be exported.  Note: Only 7 checked fields will be able to appear on PDF export of 6 Cads per page!</p>

      <div style={isGuest ? {cursor: 'not-allowed'} : {}}>
        <div style={isGuest ? {pointerEvents: 'none', opacity: 0.5, columnCount: '3'} : {columnCount: '3'}}>
          {
            hideableInfo.map((fieldName, z) => {
              if (isGuest && !cadReducer[('show'+fieldName).replace(/ /g, '').replace('-', '').replace('&', '')]) {
                return null;
              }
              let labelText = fieldName

              if (['Shipped','PO','Worksheet','Hold'].includes(fieldName)) {
                labelText += ' (orders)'
              }

              if (['FACTORY-RTS','On Hand','ATS','Future Stock','Future ATS'].includes(fieldName)) {
                labelText += ' (units)'
              }

              if (['Brand','Division','Category','Sub-Category','Color','Fabrication','Packs'].includes(fieldName)) {
                labelText += ' (classification)'
              }

              return (
                <div key={z}>
                  <Checkbox label={labelText} onChange={toggleHideableCheckbox.bind(null, fieldName)} checked={cadReducer[('show'+fieldName).replace(/ /g, '').replace('-', '').replace('&', '').replace('#', 'Number')]}/> &nbsp;
                  {
                    cadReducer[('show'+fieldName).replace(/ /g, '').replace('-', '').replace('&', '')]
                    ?
                    <Input type="number" min="1" max="15" className="hideable-input-index" style={{width: '40px'}} onChange={onChangeHideableInput.bind(null, fieldName)} value={cadReducer[('show'+fieldName+'Index').replace(/ /g, '').replace('-', '').replace('&', '')]}/>
                    :
                    null
                  }
                  <br/><br/>
                </div>
              )
            })
          }

          {
            isGuest
            ?
            null
            :
            <button className="mini ui basic button" onClick={selectAllHideableCheckbox.bind(null, hideableInfo)} style={{padding: '5px'}}>
              Select all
            </button>
          }
        </div>
      </div>
    </div>
  )

  return [
    {
      key: 'hide-show',
      title: 'Select visible fields',
      content: {
        content: content,
      },
    }
  ]
}

export default getHideShowPanels
