import React                              from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

export const LoadingParagraph = props => (
    <div>
      <Dimmer active inverted>
        <Loader size='medium'>Loading</Loader>
      </Dimmer>
    </div>
);
