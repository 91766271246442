const patternOptions = [
  "AB = ABSTRACT",
  "AN = ANIMAL",
  "CA = CAMO",
  "CO = CONVERSATIONAL",
  "FS = FLORAL - SMALL/DITZY",
  "FL = FLORAL",
  "GE = GEOMETRIC",
  "IN = INCLUSIVE",
  "JA = JACQUARD",
  "LO = LOGO",
  "PA = PAISLEY",
  "OR = ORNAMENTAL",
  "PT = PATCHWORK",
  "PD = PLAID",
  "PL = PLACEMENT",
  "SE = SEASONAL",
  "ST = STRIPES"
].sort().map((x) => {return { key: x, text: x, value: x.slice(0,2)}})

const seasonOptions = [
  "S = SPRING",
  "U = SUMMER",
  "F = FALL",
  "H = HOLIDAY"
].map((x) => {return { key: x, text: x, value: x[0]}})

export {patternOptions, seasonOptions}
