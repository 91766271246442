const formatDate = (dateString) => {
  //jan 1 2000 used as a dummy date if need
  if (dateString == null || dateString == '2000-01-01') {
    return ''
  }

  var dateData = dateString.split('-')
  let date = new Date(dateData[0],dateData[1]-1,dateData[2])
  return date.localeDateShortenYear()
}

export {formatDate}
