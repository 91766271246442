import React, {Component} from 'react'
import {Modal, Button} from 'semantic-ui-react'

class ShowHideModal extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
  }

  render() {
    let {open, onClose, isGuest, hideShowPanels} = this.props
    return (
      <Modal size={'small'} open={open} onClose={onClose} closeIcon style={isGuest ? {cursor: 'not-allowed'} : {}}>
        <Modal.Header>Select visible fields</Modal.Header>
        <Modal.Content id="show-hide-note-holder" style={isGuest ? {pointerEvents: 'none', opacity: 0.5} : {}}>
          {hideShowPanels[0].content.content}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} primary>
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ShowHideModal
