const getImageUrl = (imageData, cadId) => {
  if (cadId == 'BULK EDIT' || typeof cadId === 'string') return {}

  let myImageData = imageData[cadId]
  let {filename, oldS3Bucket, haveThumb} = myImageData
  let imageUrl, imageClass = ''
  let s3BucketName = oldS3Bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME

  if (filename == null) {
    imageUrl = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new.png'
    imageClass = 'missing'
  } else if (haveThumb) {
    imageUrl = 'https://s3.amazonaws.com/' + s3BucketName + '/cad_thumb/' + filename.split('/')[0] + '/thumb.jpg'
  } else {
    imageUrl = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + filename
  }

  return {imageUrl, imageClass}
}

export {getImageUrl}
