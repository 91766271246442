import React from 'react'
import {checkboxHolderStyle} from './checkbox-holder-style'
import {Form} from 'semantic-ui-react'

const needAdminPinInput = (user, customAccessFieldOnChange, forwardAdminPinEmail) => {
  return (
    <div style={checkboxHolderStyle}>
      <b>Need Admin Pin:</b>
      <Form.Group widths='equal' style={{textAlign: 'center'}}>
        <span>Forward Admin Pin Email</span>
        <Form.Input name='forwardAdminPinEmail' type="email" label='' onChange={customAccessFieldOnChange} placeholder={!!user ? user.email : ''} value={forwardAdminPinEmail}
          style={{width: '300px'}} />
      </Form.Group>
    </div>
  )
}

export {needAdminPinInput}
