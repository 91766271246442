import React, { Component }   from 'react'
import { Form,
         Grid,
         Input,
         Divider,
         Button,
         Checkbox }           from 'semantic-ui-react'
import Constants              from '../../../modules/constants'
import PhoneInput             from 'react-phone-input-2'

import Utils                  from '../../../modules/utils'
import validator              from 'validator';
import _                      from 'lodash'
import                             './contact-person.css'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result    = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? "#e0e1e2" : "none",
  borderRadius: isDragging ? "5px" : "",
  padding: isDragging ? "5px" : "",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey"
});

class ContactPerson extends Component {
  constructor(props)
  {
    super(props)

    this.state = {
      contactPeople: this.props.contactPeople || null,
      dragDropKey: Utils.generateUniqueId(),
      items: this.getItems(4),
      inputs: [
          Object.assign({}, {...this.getPersonState()})
      ]
    }

    this.setInputsFromAccount()
  }

  setInputsFromAccount = () => {
      let {contactPeople, inputs} = this.state

      if (!contactPeople)
      {
        return;
      }
      inputs = []
      contactPeople.forEach((contactPerson, i) => {

        inputs.push(this.getPersonState())
        let inputPerson = inputs[i]
        inputPerson.itemId = Utils.generateUniqueId();
        let inputKeys = Object.keys(inputPerson)

        inputKeys.forEach((key) => {
            let input = inputPerson[key]
            switch(key)
            {
              case "name" :
              case "email" :
              case "title" :
              case "phone" :
              case "phoneExtension" :
              {
                input.valid = true
                input.value = contactPerson[key]
                break;
              }
              case "haveNoPhone" :
              {
                input.valid = true
                inputPerson.phone.required = !contactPerson[key]
                input.value = contactPerson[key]
                break;
              }
              case "id" :
              {
                inputPerson.id = contactPerson.id
                break;
              }
              default :
              {

              }
            }
        })
      })
      this.state.inputs = inputs
      this.onChange(true)
    }

  getPersonState(){
    return {
          id: null,
          itemId: Utils.generateUniqueId(),
          errors: {},
          _destroy: false,
          name :
          {
              value   : "",
              valid   : null,
              required : true,
              message : ""
          },

          email :
          {
              value   : "",
              valid   : null,
              required : true,
              message : ""
          },

          title :
          {
              value   : "",
              valid   : null,
              required : true,
              message : ""
          },

          phone :
          {
              value   : "",
              valid   : null,
              required : true,
              message : ""
          },

          haveNoPhone :
          {
              value   : false,
              valid   : true,
              required : false,
              message : ""
          },

          phoneExtension :
          {
              value   : "",
              valid   : true,
              required : false,
              message : ""
          }
        }
  }

  addMore = (e) =>
  {
    let newPerson = Object.assign({}, {...this.getPersonState()})
    let inputs = this.state.inputs
    inputs.push(newPerson)
    this.setState({inputs}, this.onChange)
  }

  removePerson = (person) => {
    person._destroy = true;
    let inputs = this.state.inputs;
    this.setState({ inputs, dragDropKey: Utils.generateUniqueId() }, this.onChange);
  }

  handleChange = (e, input, index) =>
  {
    let state = this.state
    let value, name;
    let inputs = state.inputs

    if (e.name === 'title')
    {
      value = e.value;
      name = e.name;
    }
    else
    {
      let target = e.target;
      value = target.value;
      name = target.name;
    }

    input.value = value

    if (input.required && !input.value)
    {
      input.message = 'Please enter the value.'
    }
    else
    {
      input.message = ''
    }

    switch(name)
    {
      case "name" :
      case "title" :
      case "phone" :
      {
        input.valid   = !input.message
        break
      }
      case "haveNoPhone" :
      {
        let phoneInput = inputs[index].phone
        phoneInput.required = !input.value
        if (!input.value)
        {
          phoneInput.value = ''
          phoneInput.message = 'Please enter the value.'
          phoneInput.valid = false
        }
        else{
          phoneInput.value = ''
          phoneInput.message = ''
          phoneInput.valid = true
        }
        break
      }

      case "email" :
      {
        input.value   = `${value}`.toLowerCase().trim();
        input.valid   = validator.isEmail(input.value);

        if (input.valid)
        {
          let invalid = this.validateEmails(input.value);
          input.valid = !invalid;
          input.message = input.valid ? "" : "This email is already in use!"
        }
        else{
          input.message = input.valid ? "" : "Please enter a valid email address."
        }

        break
      }

      default :
      {

      }
    }

    this.setState({ inputs }, this.onChange)
  }

  validateEmails = (email) => {
    let {inputs} = this.state;
    let foundEmailItems = _.filter(inputs, function(input) { return (`${input.email.value}`.toLowerCase().trim() === email) });
    return foundEmailItems.length > 1
  }

  onChange = (fromLoad=false) =>
  {
      let { inputs } = this.state;
      let contactsToSave = this.contactsToSave(inputs)
      let primaryContact = contactsToSave[0];
      let contactPeople = []
      for (let input of inputs){
          let contactPerson = {}
          contactPerson.name           = input.name.value
          contactPerson.title          = input.title.value
          contactPerson.phone          = input.phone.value
          contactPerson.phoneExtension = input.phoneExtension.value
          contactPerson.haveNoPhone    = input.haveNoPhone.value
          contactPerson.email          = input.email.value
          contactPerson.id             = input.id
          contactPerson._destroy       = input._destroy
          contactPerson.isPrimary      = primaryContact.itemId === input.itemId
          contactPeople.push(contactPerson)
      }
      let payload = {name: 'contactPeopleAttributes', value: contactPeople, valid: this.inputsAreValid()}

      this.props.onChange(null, payload, fromLoad)
  }

  inputsAreValid = () =>
  {
    let {inputs} = this.state
    let inputsToValidate = inputs.map((input) => {
      if (input._destroy === false)
        return input
    })
    return Utils.isInputsValidated(inputsToValidate)
  }

  contactsToSave = (inputs) =>
  {
    let contactsToSave = []
    inputs.forEach((input) => {
      if (input._destroy === false)
        return contactsToSave.push(input)
    })
    return contactsToSave;
  }

  getItems = count =>
  {
    return Array.from({ length: count }, (v, k) => k).map(k => ({
      id: `item-${k}`,
      content: `item ${k}`
    }));
  }

  onDragEnd = result =>
  {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const inputs = reorder(
      this.state.inputs,
      result.source.index,
      result.destination.index
    );

    this.setState({inputs}, this.onChange);
  }

  displyIndex = personInput => {
    let {inputs} = this.state;
    let index = 0;
    let display = 0;

    inputs.forEach((input)=> {
      if (input._destroy === false)
      {
        index++;
        if (input.itemId === personInput.itemId)
        {
          display = index;
        }
      }
    })
    return display;
  }

  render() {
    let {inputs, dragDropKey}   = this.state
    return (

        <div key={dragDropKey}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => {
                return <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                >
                  {inputs.map((personInput, index) => {
                  if (false || personInput._destroy === false)
                  {
                    return <div key={personInput.itemId} >
                      <Draggable draggableId={personInput.itemId} index={index}>
                        {(provided, snapshot) => {
                          let counter = this.displyIndex(personInput);
                          return <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Grid columns='equal' key={index}>
                              <Grid.Row style={{paddingBottom: '0'}}>
                                  <Grid.Column floated='left' width={7}>
                                      <Form.Field required>
                                        <label {...provided.dragHandleProps}>Contact Person
                                        {
                                          counter === 1 ? ' #1 (Primary)' : ` #${counter}`
                                        }
                                        {personInput._destroy ? ' deleted' : ''}
                                        </label>
                                      </Form.Field>
                                  </Grid.Column>
                                  <Grid.Column floated='right'>
                                    {
                                      this.contactsToSave(inputs).length === 1 ?
                                      ''
                                      :
                                      <Button circular size='mini' type='button' icon='x' onClick={e => this.removePerson(personInput)}/>
                                    }
                                  </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                  <Grid.Column>
                                      <Form.Field
                                        required
                                        control={Input}
                                        placeholder='Name'
                                        name='name'
                                        label='Name'
                                        value={personInput.name.value}
                                        onChange={(e) => this.handleChange(e, personInput.name)}
                                        error={personInput.name.valid === false ? {
                                          content: personInput.name.message,
                                        } : false }
                                      />
                                  </Grid.Column>
                                  <Grid.Column>
                                      <Form.Field required>
                                        <label>Title</label>
                                        <Form.Select
                                          onChange={(e, data) => this.handleChange(data, personInput.title)}
                                          name='title'
                                          value={personInput.title.value}
                                          options={Utils.arrayToOptionsHash(Constants.CONTACT_PERSON_TITLES)}
                                          placeholder='Title'
                                          error={personInput.title.valid === false ? {
                                            content: personInput.title.message,
                                            // pointing: 'below',
                                          } : false }
                                          />
                                      </Form.Field>
                                    </Grid.Column>

                              </Grid.Row>


                              <Grid.Row columns={3}>
                                  <Grid.Column width={8}>
                                      <Form.Field
                                        control={Input}
                                        placeholder='Email'
                                        name='email'
                                        label='Email'
                                        required
                                        value={personInput.email.value}
                                        onChange={(e) => this.handleChange(e, personInput.email)}
                                        error={personInput.email.valid === false ? {
                                          content: personInput.email.message
                                        } : false }
                                      />
                                  </Grid.Column>

                                  <Grid.Column width={6}>
                                    <Form.Field
                                          required={personInput.phone.required}
                                          error={personInput.phone.valid === false ? {
                                              content: personInput.phone.message
                                          } : false }
                                          control={Input}
                                          label='Phone'
                                          className={personInput.haveNoPhone.value ? 'disabled' : ''}
                                          >
                                          <PhoneInput
                                            inputProps={{
                                                      name: 'phone',
                                                      required: personInput.phone.required,
                                                    }}
                                            country={'us'}
                                            disableAreaCodes={true}
                                            prefix={personInput.haveNoPhone.value ? '' : '+'}
                                            value={personInput.haveNoPhone.value ? '' : (personInput.phone.value || '') }
                                            placeholder=''
                                            onChange={(phone) => this.handleChange({target: {name: 'phone', value: phone}}, personInput.phone)}
                                          />
                                      </Form.Field>
                                      <Checkbox
                                          label="I don't have phone number right now"
                                          onChange={(e, { checked }) => {
                                            this.handleChange({target: {name: 'haveNoPhone', value: checked}}, personInput.haveNoPhone, index)}
                                          }
                                          checked={personInput.haveNoPhone.value}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                      <Form.Field
                                        control={Input}
                                        placeholder='10'
                                        name='phoneExtension'
                                        className={personInput.haveNoPhone.value ? 'disabled' : ''}
                                        label='Ext'
                                        value={personInput.phoneExtension.value}
                                        onChange={(e) => this.handleChange(e, personInput.phoneExtension)}
                                        error={personInput.phoneExtension.valid === false ? {
                                          content: personInput.phoneExtension.message,
                                          // pointing: 'below',
                                        } : false }
                                      />
                                  </Grid.Column>
                                </Grid.Row>

                              <Divider />
                            </Grid>
                          </div>
                        }}
                      </Draggable>
                    </div>
                  }
                  }
                  )}
                  {provided.placeholder}
                </div>
              }}
            </Droppable>
          </DragDropContext>
        <Button
          style={{marginTop: '20px'}}
          type='button'
          content='Add More Contact Person'
          icon='plus'
          onClick={this.addMore}
          labelPosition='right'
          />
        </div>
    )
  }
}

export default ContactPerson
