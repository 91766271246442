import React, {Component} from 'react'
import {Accordion, Icon} from 'semantic-ui-react'

class Logs extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      active: false
    }

    this.toggleAccordion = this.toggleAccordion.bind(this)
  }

  toggleAccordion() {
    this.setState({active: !this.state.active})
  }

  render() {
    let {active} = this.state
    let {logs} = this.props

    return (
      <div style={{marginBottom: '50px'}}>
        <Accordion>
          <Accordion.Title onClick={this.toggleAccordion} style={{fontSize: '16px', fontWeight: 'bold'}}>
            <Icon name='dropdown' style={active ? {transform: 'rotate(90deg)'} : {}}/>
            Log of Changes ({logs.length})
          </Accordion.Title>
          <Accordion.Content active={active}>
            {
              logs.length == 0
              ?
              <div>No changes made yet</div>
              :
              <ul>
                {
                  logs.map((log) => {
                    const date = new Date(log.createdAt)
                    const dateString = date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                    return (
                      <li>{dateString} &nbsp; — &nbsp; {decodeURI(log.message)}</li>
                    )
                  })
                }
              </ul>
            }
          </Accordion.Content>
        </Accordion>
      </div>
    )
  }
}

export default Logs
