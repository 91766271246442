const refreshButtonStyle = {
  fontSize: '10px',
  cursor: 'pointer',
  position: 'relative',
  bottom: '6px',
  background: 'white',
  border: '1px solid rgb(222,222,222)',
  borderRadius: '5px',
  float: 'right'
}

export {refreshButtonStyle}
