import React from 'react'
import {Card} from 'semantic-ui-react'
import $ from 'jquery'

const printCheckboxOnChange = (id, updateNumSelected) => {
  if ($('#print-checkbox-'+id).is(':checked')) {
    window.printIds[id] = true
  } else {
    delete window.printIds[id]
  }
  updateNumSelected()
}

const cardHeader = (styleNumber, id, updateNumSelected, is_deleted) => {
  return (
    <Card.Header>
      <div className="ui checkbox print-checkbox-holder" style={{width: '100%'}}>
        <input id={'print-checkbox-'+id} onChange={printCheckboxOnChange.bind(this, id, updateNumSelected)} type="checkbox"/>
        <label for={'print-checkbox-'+id} style={is_deleted ? {textDecoration: 'line-through'} : {cursor: 'pointer'}}>{styleNumber}</label>
      </div>
    </Card.Header>
  )
}

export {cardHeader}
