import {canSeePrice} from '../custom-access/can.js'

const checkCanSeePrice = (user, defaultAccount) => {
  return (
    user == null
    ?
    null
    :
    isAdmin() || isGuestWithDefaultAccount(defaultAccount) || canSeePrice()
  )
}

const isAdmin = () => {
  let role = sessionStorage.getItem('currentUserRole')
  return ['admin', 'superadmin', 'finance'].includes(role)
}

const isGuestWithDefaultAccount = (defaultAccount) => {
  return isGuest() && defaultAccount != null
}

const isGuest = () => {
  return sessionStorage.getItem('isGuest') == 'TRUE'
}

export {checkCanSeePrice, isAdmin, isGuestWithDefaultAccount, isGuest}
