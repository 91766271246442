import {displayPhone} from './display_phone'

const contactSort = (results, column, direction) => {
  results = results.sort((a, b) => {
    let a_val = getValue(a, column)
    let b_val = getValue(b, column)

    if (a_val == b_val) {
      a_val = getValue(a, 'CONTACT ID')
      b_val = getValue(b, 'CONTACT ID')
    }

    if (a_val > b_val) return 1
    if (b_val > a_val) return -1

    if (a_val != null && b_val == null) return -1
    if (a_val == null && b_val != null) return 1

    return 0
  })


  if (direction === 'descending') {
    results = results.reverse()
  }

  return results
}

const getValue = (x, column) => {
  switch(column) {
    case 'NAME':
      return x.name.toUpperCase()
    case 'TITLE':
      return x.title
    case 'PHONE':
      return displayPhone(x)
    case 'COMPANY':
      return x.company.toUpperCase()
    case 'EMAIL':
      return x.email.toUpperCase()
    case 'STATUS':
      return x.status
    case 'CONTACT ID':
      return x.contactId
    case 'LATEST ACTIVITY':
      return x.latestActivityI
    case 'REP':
      return x.reps.join(', ').toUpperCase()
    case 'DISTRIBUTION CHANNEL':
      return x.distributionChannel
    case 'REGION':
      return x.region
    case 'DIVISION':
      return x.division.join(', ')
    case 'CATEGORY':
      return x.category.join(', ')

    default:

  }
}

export {contactSort}
