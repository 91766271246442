// import                          'semantic-ui-css/semantic.min.css'
import                         './index.css'
import                         'react-quill/dist/quill.snow.css'
import React              from 'react'
import ReactDOM           from 'react-dom'
import App                from './App'
import * as serviceWorker from './modules/server'
import { Auth0Provider }  from "./contexts/auth-context"
import history            from "./utils/history"

import { Provider } from 'react-redux'
import configureStore from './store';

const onRedirectCallback = (appState) => {
  history.push(window.location.pathname)


  // history.push(
  //   appState && appState.targetUrl
  //     ? '/#'+appState.targetUrl
  //     : window.location.pathname
  // );


  //+"#/cads"
  // if (window.location.href.indexOf('localhost') == -1) {
  //   window.location.reload()
  // }
}

function getInternetExplorerVersion()
{
  var rv = -1;
  if (navigator.appName == 'Microsoft Internet Explorer')
  {
    var ua = navigator.userAgent;
    var re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat( RegExp.$1 );
  }
  else if (navigator.appName == 'Netscape')
  {
    var ua = navigator.userAgent;
    var re  = new RegExp("Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat( RegExp.$1 );
  }
  return rv;
}

let ieVersion = getInternetExplorerVersion()

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <Provider store={configureStore()}><App /></Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
