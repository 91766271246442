import React, {Component} from 'react'
import {Button, Modal} from 'semantic-ui-react'
import Utils from './../../modules/utils'
import $ from 'jquery'
import Constants from './../../modules/constants'

class PickUpScheduledModal extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {}
    this.submitPickUpScheduledForm = this.submitPickUpScheduledForm.bind(this)
    this.savePickUpScheduled = this.savePickUpScheduled.bind(this)
  }

  submitPickUpScheduledForm(e) {
    e.preventDefault()

    let pickUpScheduledDate = $('#pick-up-scheduled-date').val()
    let {onClose} = this.props

    let file = $('#pick-up-scheduled-file')[0].files[0]

    if (file != null) {
      let formData = new FormData()
      formData.append("file", file, file.name)

      let url = Constants.API.apiEndPoint + 'upload-file'

      Utils.uploadFile(url, formData, (error, data) =>
      {
        if (error)
        {
          alert('There was an error when trying to upload the file.')
        }
        else
        {
          if (data.success)
          {
            console.log('data success')
            this.savePickUpScheduled(data.url, pickUpScheduledDate)
          }
          else
          {
            if (data.corrupt_file)
            {
              alert('The file is corrupt.')
            }
            else
            {
              alert('There was an error when trying to upload the file.')
            }
          }
        }
      })
    } else {
      this.savePickUpScheduled(null, pickUpScheduledDate)
    }

    onClose()
  }

  savePickUpScheduled(url, pickUpScheduledDate) {
    let {id} = this.props

    //TODO
    //update react data! for date and file fields
    this.props.markStatus(id, 'Pick-Up-Scheduled', url, pickUpScheduledDate)
  }

  render() {
    let {open, onClose, id} = this.props

    return (
      <Modal size={'small'} open={open} onClose={onClose} closeIcon>
        <Modal.Header>{id == null ? 'Bulk mark' : 'Mark' } as Pick-Up-Scheduled</Modal.Header>
        <Modal.Content>
          <form id="pick-up-scheduled-form" onSubmit={this.submitPickUpScheduledForm}>
            <label style={{fontSize: '16px', fontWeight: 'bold', marginRight: '132px'}}>
              Pick-Up date
            </label>
            <input id="pick-up-scheduled-date" type="date" required/>
            <br/><br/>
            <label style={{fontSize: '16px', fontWeight: 'bold', marginRight: '95px'}}>
              File <span style={{opacity: 0.5, fontStyle: 'italic', marginLeft: '20px'}}>(optional)</span>
            </label>
            &nbsp;&nbsp;&nbsp;
            <input id="pick-up-scheduled-file" type="file"/>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary type="submit" form="pick-up-scheduled-form">
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default PickUpScheduledModal
