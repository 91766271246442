const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index
}

const multipleBrandsSelected = () => {
  let {cads, cadIds} = window

  let selectedCads = cads.filter(x => !!cadIds[x['id']])
  let selectedBrands = selectedCads.map(x => x['brand'])
  selectedBrands = selectedBrands.filter(onlyUnique)

  return selectedBrands.length > 1
}

export {multipleBrandsSelected}
