import React from 'react'
import {Dropdown} from 'semantic-ui-react'

const printsFilter = (printOnChange, value) => {
  let {printOptions} = window
  return (
    <div>
      {value.length == 0 ? <label>&nbsp;</label> : <label>Print</label>}
      <Dropdown placeholder='Print' search fluid multiple selection
        options={printOptions} onChange={printOnChange} value={value} />
    </div>
  )
}

const patternFilter = (patternOnChange, value) => {
  let {patternOptions} = window
  return (
    <div>
      {value.length == 0 ? <label>&nbsp;</label> : <label>Pattern</label>}
      <Dropdown placeholder='Pattern' fluid multiple selection
        options={patternOptions} onChange={patternOnChange} value={value} />
    </div>
  )
}

const setPrintOptions = () => {
  setPrintOptionsActive()
  setPrintOptionsAll()
  setPatternOptions()
}

const setPrintOptionsActive = () => {
  let {cadPrints, prints} = window
  let printsArray = Object.values(window.prints)

  let activePrintIds = Object.values(cadPrints)
  let styleNumbers = activePrintIds.map(id => prints[id].style_number).sort()
  let distinctStyleNumbers = [...new Set(styleNumbers)]

  window.printOptions = distinctStyleNumbers.map((styleNumber) => {
    let print = printsArray.find(y => y.style_number == styleNumber)

    return { key: print.id, text: styleNumber, value: print.id, image: { avatar: true, src: getImageUrl(print) }}
  })
}

const setPrintOptionsAll = () => {
  let {prints} = window
  let printsArray = Object.values(window.prints).sort((a, b) => {
    if (a.style_number.toLowerCase() > b.style_number.toLowerCase()) {
      return 1
    }
    if (a.style_number.toLowerCase() < b.style_number.toLowerCase()) {
      return -1
    }
    return 0
  })

  window.printOptionsAll = printsArray.map(print => {
    let pText = print.style_number[0] == 'G' ? 'GRAPHICS' : print.pattern
    return { key: print.id, text: print.style_number + ' (' + pText + ')', value: print.id, image: { avatar: true, src: getImageUrl(print) }}
  })
}

const getPattern = (print) => {
  if (print == null) return null
  return print.style_number[0] == 'G' ? 'GRAPHICS' : print.pattern
}

const setPatternOptions = () => {
  let {cadPrints, prints} = window
  let printsArray = Object.values(window.prints)

  let activePrintIds = Object.values(cadPrints)
  let patterns = activePrintIds.map(id => getPattern(prints[id])).sort()
  let distinctPatterns = [...new Set(patterns)]

  window.patternOptions = distinctPatterns.map(pattern => {return {key: pattern, text: pattern, value: pattern}})
}

const getImageUrl = (print) => {
  return 'https://s3.amazonaws.com/' + process.env.REACT_APP_S3_BUCKET_NAME + '/cad_thumb/' + print.filename.split('/')[0] + '/thumb.jpg'
}

const checkForPrint = (cad, value) => {
  let {cadPrints} = window
  return value.includes(cadPrints[cad.id])
}

const checkForPattern = (cad, value) => {
  let {cadPrints, prints} = window
  let print = prints[cadPrints[cad.id]]
  let pattern = getPattern(print)
  return !!print && value.includes(pattern)
}

export {printsFilter, patternFilter, setPrintOptions, checkForPrint, checkForPattern}
