import React, {Component} from 'react'
import {Popup, Icon, Button} from 'semantic-ui-react'
import {saveCost} from './save.js'
import $ from 'jquery'
import {successMessage} from '../vpo-stages/success-message.js'
import {orgIdsShowEditCost} from './options.js'

class CostPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {showSuccess: false}
    this.saveCost = this.saveCost.bind(this)
  }

  saveCost() {
    let {cadId} = this.props
    let cost = $('#cost-'+cadId).val()
    saveCost(cadId, cost, (err, res) => {
      if (err) {
        alert('There was an error when trying to save vpo stage.')
      } else {
        this.setState({showSuccess: true})
        setTimeout(() => {
          this.setState({showSuccess: false})
        }, 3000)
      }
    })
  }

  render() {
    let {showSuccess} = this.state
    let {orgId, cadId} = this.props
    if (!orgIdsShowEditCost.includes(orgId)) return null

    return (
      <Popup
        size='mini'
        trigger={
          <Icon name='pencil' style={{cursor: 'pointer', color: 'blue'}}/>
        }
        header={'Edit cost'}
        content={
          <div>
            <input id={'cost-'+cadId} type="number" min="0.00" max="10000.00" step="0.01" style={{padding: '6px', width: '140px', marginRight: '5px'}}/>
            <Button primary size='mini' onClick={this.saveCost}>Save</Button>
            <br/>
            {showSuccess ? successMessage : null}
          </div>
        }
        on='click'
        position='top left'
      />
    )
  }
}

export default CostPopup
