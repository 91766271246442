import Utils from "../utils"

class CompanyAPI
{
  static exists(data, cb)
  {
    Utils.post(`companies/exists`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static getAll(cb)
  {
    Utils.get(`companies.json`, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }

  static update(id, data, cb)
  {
    Utils.put(`companies/${id}.json`, data, (err, res) =>
    {
      if (err)
      {
        return cb(err)
      }

      cb(null, res.data)
    })
  }
}

export default CompanyAPI
