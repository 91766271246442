const getFilterOptions = (objects, totals) => {
  return objects.sort(function(a, b) {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1
    }

    return 0
  }).map(x => {
    let totalText = totals[x.name] == null ? '' : ' ('+totals[x.name]+')'
    return ({ key: x.name, text: x.name+totalText, value: x.name })
  })
}

const getFilterOptionsArray = (arr, totals) => {
  return arr.sort(function(a, b) {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1
    }
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1
    }

    return 0
  }).map(x => {
    let totalText = totals[x] == null ? '' : ' ('+totals[x]+')'
    return ({ key: x, text: x+totalText, value: x })
  })
}

export {getFilterOptions, getFilterOptionsArray}
