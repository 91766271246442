import Utils from '../../modules/utils'

const saveUpc = (cadId, text, callbackFunc) => {
  const url = 'upcs'
  const data = {cadId, text}
  Utils.post(url, data, callbackFunc)
}

const saveUpcFiles = (filenames, callbackFunc) => {
  const url = 'upc_files'
  const data = {filenames}
  Utils.post(url, data, callbackFunc)
}

export {saveUpc, saveUpcFiles}
